import { makeRequest, buildOptions } from '../../services/ServiceHelpers';

const endpointUrl = process.env.REACT_APP_PRICE_INTEGRATION_URL;

export const getPricingArchVersion = accountId => {
  const options = buildOptions();
  return makeRequest(
    endpointUrl,
    `/v1/accountPricingArchitecture/${accountId}`,
    options,
    'getting pricing version by account Id'
  );
};
