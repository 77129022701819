import { fetchTermsForHomePage } from '../../../services/PricingTerm';
import { cacheActions } from '../../cache/cache';
export const fetchTermsForHomePage_REQUEST = 'fetchTermsForHomePage_REQUEST';
export const fetchTermsForHomePage_SUCCESS = 'fetchTermsForHomePage_SUCCESS';
export const fetchTermsForHomePage_FAILURE = 'fetchTermsForHomePage_FAILURE';

const cachePrefix = 'homepage_terms';

// action creator
export const fetchTermsForHomePageV2 = () => {
  return {
    types: [fetchTermsForHomePage_REQUEST, fetchTermsForHomePage_SUCCESS, fetchTermsForHomePage_FAILURE],
    callAPI: fetchTermsForHomePage
  };
};

export const resetTermsCache = () => {
  cacheActions.removeCache({ cacheKey: cachePrefix });
};
