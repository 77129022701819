// - SELECTORS

import { createSelector } from 'reselect';

const getNotifications = state => state.notifications;

const getHasLoadingNotifications = createSelector(getNotifications, notifications =>
  notifications.some(notification => {
    return 'loading' === (notification.status && notification.status.toLowerCase());
  })
);

export const selectors = {
  getHasLoadingNotifications
};
