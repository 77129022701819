export default {
  common: {
    contractV1Src: {
      DoYouWantToCreateShippingContractV1: "Voulez-vous créer un contrat d'expédition?",
      EnabledShippingContractV1ErrorMessage:
        "Erreur lors de la récupération des identifiants de compte pour activer le contrat d'expédition.",
      NoAgreements:
        'Les contrats affichés sont créés au sein de la même entreprise. Aucun accord na donc été montré ici.',
      PleaseContact: 'Veuillez contacter {{emailid}} si votre entreprise requiert des accords.'
    },
    contractTypes: {
      listPrice: 'Prix catalogue',
      specialPrice: 'Prix privé',
      freeShipping: 'Livraison gratuite',
      shippingCost: "Frais d'expédition",
      shippingPrice: "Prix d'expédition",
      listPriceWithDiscount: '$t(common:contractTypes.listPrice) avec $t(common:commonWords.discount)',
      listPriceWithMarkup: '$t(common:contractTypes.listPrice) avec $t(common:commonWords.markup)',
      specialPriceWithMarkup: '$t(common:contractTypes.specialPrice) avec $t(common:commonWords.markup)',
      shippingCostWithDiscount: '$t(common:contractTypes.shippingCost) avec $t(common:commonWords.discount)',
      shippingCostWithMarkup: '$t(common:contractTypes.shippingCost) avec $t(common:commonWords.markup)',
      shippingPriceWithDiscount: '$t(common:contractTypes.shippingPrice) avec $t(common:commonWords.discount)',
      shippingPriceWithMarkup: '$t(common:contractTypes.shippingPrice) avec $t(common:commonWords.markup)',
      DoYouWantToCreateShippingContractV1: "Voulez-vous créer un contrat d'expédition?"
    },
    contractStatus: {
      approved: 'Approuvé(e)',
      pending: 'En attente',
      terminated: 'Terminé(e)',
      rejected: 'Rejeté(e)',
      draft: 'Brouillon',
      effective: 'En vigueur',
      visibleInCatalog: 'Visible dans le catalogue',
      usingDeletedModel: "Utilisation d'un modèle de prix supprimé",
      expiredAgo: '({{ duration, localizeDuration }} expirée)',
      isExpiring: '({{ duration, localizeDuration }} arrivant à expiration)',
      pending_yourResponse: 'Examen nécessaire',
      pending_otherParty: "En attente d'approbation",
      pending_priorTerm: 'Auparavant $t(common:contractStatus.pending)',
      approved_priorTerm: 'Auparavant $t(common:contractStatus.approved)',
      rejected_priorTerm: 'Auparavant $t(common:contractStatus.rejected)',
      terminated_priorTerm: 'Auparavant $t(common:contractStatus.terminated)',
      gracePeriodAboutToExpire: 'Seront automatiquement approuvées <1>{{ duration, localizeDuration }}</1>',
      needsApproval: "Besoin d'approbation"
    },
    contract: {
      lastUpdatedXAgo: '{{ duration, localizeDuration }}',
      privateButDiscoverableTip:
        'Les prix des articles indiqués dans ce contrat de prix privé sont publics et visibles dans le catalogue. Créez un contrat de prix catalogue pour remplacer ce contrat de prix privé.',
      deletedModelsTip:
        "Ce contrat utilise des modèles de prix supprimés, ce qui pourrait affecter les mises à jour des prix. Si vous avez besoin d'aide, veuillez contacter {{ email }}\n"
    },
    commonTerminology: {
      shippingPriceModel: "Modèle de prix d'expédition",
      priceModel: 'Modèle de prix',
      contractName: 'Nom du contrat',
      effectiveStartDate: 'Date de début effective',
      effectiveEndDate: 'Date de fin effective',
      discount: '{{ percentage }} % $t(common:commonWords.discount)',
      markup: '{{ percentage }} % $t(common:commonWords.markup)',
      name: 'Nom',
      indefinite: 'Indéterminé',
      revision: 'Révision',
      mcpSystem: 'le système MCP',
      gracePeriod: 'Période de grâce',
      effectiveDates: "Dates d'entrée en vigueur (temps universel coordonné)",
      lastUpdated: 'Dernière mise à jour',
      seller: 'Vendeur',
      seller_plural: 'Vendeurs',
      seller_oneOrMore: 'Vendeur(s)',
      buyer: 'Acheteur',
      buyer_plural: 'Acheteurs',
      buyer_oneOrMore: 'Acheteur(s)',
      search: 'Rechercher',
      status: 'Statut',
      status_plural: 'Statuts',
      status_oneOrMore: 'Statut(s)',
      type: 'Type',
      contractType: 'Type de contrat',
      contractType_plural: 'Types de contrat',
      contractType_oneOrMore: 'Type(s) de contrat',
      hasGracePeriod: 'A une période de grâce',
      ProductId: 'Identifiant du produit',
      currentVersion: 'Version actuelle'
    },
    commonWords: {
      discount: 'Remise',
      markup: 'Majoration',
      yesText: 'Oui',
      noText: 'Non',
      onText: 'Activé',
      offText: 'Désactivé',
      blank: '',
      next: 'Suivant',
      back: 'Retour',
      prev: 'Précédent',
      orText: 'ou',
      save: 'Enregistrer',
      cancel: 'Annuler',
      close: 'Fermer',
      countries: 'Pays',
      authorize: 'Autoriser',
      edit: 'Modifier',
      delete: 'Effacer'
    },
    unsavedChanges: {
      message: 'Une action est en cours. Voulez-vous vraiment quitter cette page?'
    },
    actions: {
      createNewContract: 'Créer un nouveau contrat',
      createButtonText: 'Créer'
    }
  },
  authorizationFlow: {
    errorTitle: "Erreur d'autorisation",
    title: 'Avant de commencer...',
    descriptionMessage1: 'Bienvenue dans le Gestionnaire des contrats!',
    descriptionMessage2:
      'Pour pouvoir accepter les conditions et recevoir les notifications, les utilisateurs qui se connectent pour la première fois doivent au préalable être ',
    descriptionMessage3: 'autorisés',
    descriptionMessage4: 'à bénéficier du service Notifications Hub.',
    descriptionMessage5: 'Vous serez redirigé(e) vers Marketplace Central dès que vous cliquerez sur',
    descriptionMessage6: 'le bouton ci-dessous.',
    authErrorMessage1: "Un problème est survenu lors de l'autorisation des notifications.",
    authErrorMessage2: "Pour obtenir de l'aide, contactez {{ FFS_EMAIL_ADDRESS }}."
  },
  investigateSku: {
    investigate: 'Rechercher des contrats par référence SKU',
    getDetails: 'Obtenir des détails',
    noContractsFound: "Contrat avec référence SKU '{{sku}}' introuvable",
    emptySearch: 'La recherche doit être renseignée.',
    contractsBySku: {
      header: 'Détails du contrat pour {{ sku }}',
      header2: 'Entreprises avec contrats pour {{sku}}',
      selectAContract: 'Sélectionnez un contrat pour afficher ses détails et les prix associés à cette référence SKU.',
      viewContract: 'Afficher le contrat'
    }
  },
  appShell: {
    loudspeakerModal: {
      title: 'Nouveautés du Gestionnaire des contrats'
    }
  },
  breadcrumbs: {
    home: 'Les contrats',
    contracts: 'Les contrats',
    settings: 'Paramètres',
    notFound: 'Introuvable',
    bulkUpdate: 'Mise à jour par lot',
    viewSku: '{{ sku }}',
    contractChanges: 'Modifications du contrat',
    createContracts: 'Créer des contrats',
    viewContractRevision: 'Afficher la révision du contrat',
    createProductAgreements: 'Créer des accords de produit',
    productAgreementDetails: 'Détails des accords sur les produits',
    notificationDetails: 'Détails de la notification'
  },
  landingPage: {
    nameGreeting: 'Bonjour {{ name }}, ',
    welcome: 'Bienvenue dans le Gestionnaire des contrats',
    setupContracts:
      'Créez et gérez des contrats avec les entreprises pour acheter et vendre des produits plus facilement.',
    todaysReport: 'Rapport du jour',
    statCardTotalLabel: 'Total',
    createContractsCard: {
      header: 'Créer et gérer des contrats',
      cardBody:
        'Créer et gérer des contrats avec vos acheteurs et vos vendeurs. Ajoutez des références SKU aux contrats et définissez les termes, les remises et les conditions supplémentaires.',
      numberOfBuyerAndSellers:
        "<0>{{ numberOfBuyers }}</0> contrats d'acheteurs, <2>{{ numberOfSellers }}</2> contrats de vendeurs",
      browseAll: 'Tout parcourir'
    },
    investigateSkusCard: {
      header: 'Enquêter sur des références SKU dans les contrats',
      cardBody:
        'Pour des références SKU spécifiques (par exemple : quels contrats ont des SKU, quels sont leurs termes et leur statut actuel), regardez le détail du contrat.'
    },
    settingsCard: {
      header: 'Abonnements et approbations',
      manageSettings: 'Gérer différents paramètres relatifs aux contrats :',
      manageSubscriptions: 'Gérer les abonnements aux notifications relatives aux contrats',
      manageAutoApproval: 'Définir une approbation automatique pour les acheteurs et les vendeurs',
      manageShippingContracts: "Activer ou désactiver les contrats d'expédition",
      manageSettingsButton: 'Gérer les paramètres',
      manageSubscriptionsAndApprovalsButton: 'Configurer les abonnements'
    },
    notificationsCard: {
      manageNotifications: 'Voir les notifications',
      manageSettings:
        "Vous avez des {} notifications non acquittées {} concernant les modifications de prix ou l'archivage des produits."
    },
    recentlyCreatedContractsCard: {
      header: 'Contrats créés récemment'
    },
    manageContractsFor: 'Gérer les contrats pour:',
    contracts: 'Contrats'
  },
  homePage: {
    header: {
      title: 'Mes contrats'
    },
    searchBarPlaceholder: 'Recherche par contrat ou par référence SKU de contrat',
    selectMultipleContracts: {
      label: 'Sélectionnez plusieurs contrats :',
      contractSelection: {
        contractTypeLabel: 'Type de contrat',
        selectSeller: 'Sélectionner un vendeur',
        addSKUsTo: 'Ajouter des SKU au contrat sélectionné',
        addSKUsTo_plural: 'Ajouter des SKU aux {{count}} contrats sélectionnés'
      }
    }
  },
  relationshipTable: {
    all: 'All',
    marketplace: 'B2B',
    fulfilmentNetwork: 'Fulfilment Network',
    searchTextFieldPlaceholder: 'Recherche par identifiant ou nom de compte'
  },
  contractsTable: {
    effectiveContractPopover:
      'Les conditions de prix en vigueur servent à calculer le moment où une commande est enregistrée dans le système MCP.',
    effectiveContractDetails:
      "Ce contrat est en vigueur pour <1>{{ sku }}</1> entre l'acheteur <3>{{ buyer }}</3> et le vendeur <5>{{ seller }}</5>.",
    effectiveContractAdditionalDetails:
      'Pour pouvoir entrer en vigueur, un contrat doit être approuvé et <1>prioritaire</1><2/>.',
    onlyOneEffectiveContractDetails:
      "Il ne peut exister qu'un seul contrat en vigueur à un moment donné pour une référence SKU, un acheteur et un vendeur."
  },
  tableCards: {
    allContracts: 'Tous les contrats',
    needsReview: 'Examen nécessaire',
    pendingApproval: "En attente d'approbation"
  },
  contractsFilter: {
    filterOffLabel: 'Filtres supplémentaires',
    filterOnLabel: 'Critère de filtrage',
    clearFilterLabel: 'Effacer les filtres'
  },
  contractInfo: {
    author: 'Auteur',
    possibleCurrencies: 'Devises possibles',
    infoSectionTitle: 'Informations sur le contrat',
    pricingSectionTitle: 'Prix',
    partiesSectionTitle: 'Parties impliquées',
    detailsSectionTitle: 'Détails',
    excludedCountriesTitle: 'Pays exclus',
    destinationCountriesTitle: 'Pays de destination',
    latestRevisionTitle: 'Dernière révision',
    noOfItemsTitle: "Nombre d'articles",
    remarksTitle: 'Remarques',
    allCountries: 'Tous les pays',
    noGracePeriod: 'Pas de période de grâce',
    viewChanges: 'Afficher les modifications',
    viewAllCountries: 'Afficher les {{ count }} pays',
    updatedBy: 'Mis à jour par',
    priceModelsOnContract: 'Modèle de prix associé à ce contrat',
    priceModelsOnContract_plural: 'Modèles de prix associés à ce contrat',
    defaultNameForPriceModel: 'Non défini',
    statusChangedOn: 'Statut modifié le <1>{{ dateTime, localDateTime }}</1>',
    notTheLatestRevisionText:
      "Cette révision est en lecture seule, car ce n'est pas la plus récente. <1>Cliquez ici pour afficher la dernière révision.</1>",
    readOnlyRevisionText:
      'Cette page est en lecture seule, car vous ne consultez pas la toute dernière révision. <1>Cliquez ici pour afficher la dernière révision.</1>',
    gracePeriod: '{{ duration, localizeDurationSimple }}',
    gracePeriodDescription:
      "La période de grâce définit le nombre de jours dont dispose l'accepteur pour examiner les modifications apportées au contrat, et les approuver ou les refuser. Lorsque la période de grâce arrive à expiration, le contrat est automatiquement approuvé. En l'absence de période de grâce, l'approbation du contrat n'est pas automatique."
  },
  contractActions: {
    viewPricing: 'Afficher les prix',
    viewPricingToolTip:
      "Pour plus d'informations sur ces conditions, par exemple sur les références SKU et les prix, cliquez ici.",
    publish: 'Publier vers {{ otherParty }} pour approbation',
    approve: 'Approuver',
    approving: 'Approuvant',
    terminate: 'Résilier',
    terminateFinal: 'Je comprends. Résilier',
    terminated: 'Terminé!',
    goToTerminatedRevision: 'Accéder à la révision terminée',
    sendForApproval: 'Envoyer pour examen et approbation',
    saveAndUpdate: 'Mettre à jour et enregistrer',
    validateUniqueMessage: 'Cette valeur doit être différente de la précédente.',
    requiredMessage: 'Cette valeur doit être renseignée.',
    editGracePeriodModal: {
      title: 'Modifier les jours de la période de grâce',
      validateRangeMessage: 'Ces valeurs doivent être comprises entre 1 et 999.',
      checkboxLabel: 'Pas de période de grâce'
    },
    editModifierModal: {
      title: 'Modifier le % du montant de {{modifierType}}'
    },
    editNameModal: {
      title: 'Modifier le nom'
    },
    editSkusModal: {
      successful: 'Opération réussie',
      bulkAddSkusMessage: '{{numberOfSkusAdded}} référence(s) SKU a(ont) été ajoutée(s).',
      bulkAddDuplicateMessage: '{{duplicateSkus}} ont été ignorées, car elles étaient déjà sélectionnées.',
      toolTipNoChangesMessage: 'Aucune modification',
      toolTipOneOrMoreChangesMessage: 'Un ou plusieurs éléments sont nécessaires.',
      toolTipNotCompatibleMessage: "Ce modèle de prix n'est pas compatible avec les références SKU sélectionnées.",
      newRevisionActionMessage:
        "Une nouvelle version du contrat révisé va être créée. L'autre partie sera alors avertie et invitée à prendre des mesures.",
      noProductsAddedMessage: "Aucun produit n'a été ajouté.",
      noProductsRemovedMessage: "Aucun produit n'a été retiré.",
      productsAddedMessage: 'produits ajoutés: {{ products}}',
      productsRemovedMessage: 'produits retirés: {{ products}}',
      noChangesToPricingMessage: "Les prix n'ont pas été modifiés.",
      priceModelChangedMessage: 'Le modèle de prix a été remplacé par {{ priceModel }}.',
      updating: 'Mise à jour en cours...',
      updated: 'Mis à jour!',
      checkboxLabel: 'Modifier le modèle de prix',
      title: 'Modifier des éléments'
    }
  },
  contractChanges: {
    metaData: {}
  },
  createContract: {
    messages: {
      missingFieldsMessage: 'Certains champs du formulaire sont manquants et/ou non valides.',
      contractNameMissingMessage: 'Le nom du contrat est requis.',
      modifierEmptyMessage: 'Le modificateur doit être renseigné.',
      endDateMissingMessage: 'La date de fin doit être définie.',
      createUnsuccessfulMessage: 'Êtes-vous sûr(e) de vouloir quitter la page?',
      onlyFulfillersAllowed: "Pour l'instant, seuls les fournisseurs sont autorisés à créer des contrats.",
      changingInitiatorWarning:
        "Si vous modifiez l'initiateur, votre progression sera réinitialisée. Pour continuer, appuyez sur OK.",
      markupPercentageNotGreaterThan0Message: 'Le pourcentage de majoration doit être supérieur à 0.',
      discountPercentageMustBeBetween0And100: 'Le pourcentage de remise doit être compris entre 0 et 100.',
      modifierAmountNotValidMessage: "Le montant modificateur n'est pas valide.",
      setModifierHeader_discount: 'Définir le pourcentage de remise',
      setModifierHeader_markup: 'Définir le pourcentage de majoration',
      modifierTextFieldLabel_discount: 'Pourcentage de remise (%)',
      modifierTextFieldLabel_markup: 'Pourcentage de majoration (%)',
      requestPermissionsMessage: 'Pour y accéder, veuillez contacter {{ email }}.'
    },
    chooseTypeModal: {
      modalTitle: 'Que souhaitez-vous faire ?',
      buyerTypeTitle: 'Je souhaite acheter',
      sellerTypeTitle: 'Je souhaite vendre',
      sellerTypeDescription: 'Je souhaite créer un contrat en tant que vendeur',
      buyerTypeDescription: "Je souhaite créer un contrat en tant qu'acheteur"
    },
    termEntryForm: {
      noEndDate: 'Pas de date de fin',
      notesToApproverLabel: "Envoyer des notes à l'approbateur",
      additionalInformationLabel: 'Informations ou remarques supplémentaires',
      contractNameLabel: 'Nommez votre contrat.',
      contractDurationLabel: 'Définir la durée de votre contrat',
      noCountrySelectedMessage: 'Sélectionnez au moins un pays.',
      chooseDestinationCountries: 'Choisissez les pays de destination dans lesquels votre contrat sera valide.',
      allDestinationCountries: 'Tous les pays de destination',
      allDestinationCountriesExcept: 'Tous les pays de destination, <1>sauf</1>',
      individualDestinationCountries: 'Pays de destination individuels'
    },
    validate: {
      buyerSelect: 'Veuillez sélectionner un acheteur',
      sellerSelect: 'Veuillez sélectionner un vendeur',
      skuSelect: 'Veuillez sélectionner au moins une référence SKU',
      contractName: 'Veuillez entrer le nom du contrat',
      pricingContractTypeSelect: 'Veuillez sélectionner un type de contrat de prix',
      creationTypeSelect: 'Veuillez sélectionner un contrat à mettre à jour.'
    },
    getStarted: {
      sellerTitle: 'Prise en main',
      buyerTitle: 'Sélectionnez les Parties',
      header: 'Sélectionner les parties impliquées dans vos contrats',
      selectSeller: 'Sélectionner le vendeur créant les contrats',
      selectBuyer: "Sélectionnez l'acheteur qui crée les contrats",
      selectBuyingFrom: 'Sélectionnez le vendeur auprès duquel acheter',
      missingSellerMessage: 'Choisissez le vendeur.',
      selectBuyers: 'Sélectionner le ou les acheteurs révisant les contrats',
      missingBuyerMessage: 'Choisir le ou les acheteurs',
      addGracePeriod: 'Ajouter une période de grâce?',
      invalidGracePeriodSelection: 'La période de grâce doit être supérieure à 0 jour.',
      gracePeriodLabel: 'Jours de période de grâce',
      gracePeriodDescription:
        "S'il existe une période de grâce, les mises à jour apportées à ce contrat sont automatiquement approuvées lorsque l'examinateur ne répond pas avant la fin de cette période."
    },
    selectExistingContract: {
      header: 'Sélectionnez un contrat existant auquel ajouter les produits'
    },
    selectCreationTypeStep: {
      title: 'Choisissez Nouveau ou Existant',
      existingContractsWarning:
        "Vous avez un ou plusieurs contrat(s) existant(s) avec {{ name }}. Vous pouvez choisir de mettre à jour l'un de ces contrats ou de créer un nouveau contrat.",
      header: 'Créer un nouveau contrat ou mettre à jour un contrat existant'
    },
    addProducts: {
      title: 'Ajouter des produits',
      header: 'Sélectionner les produits à ajouter à vos contrats',
      searchAndAddProducts: 'Rechercher et ajouter des produits',
      selectedProducts: 'Produits sélectionnés',
      mustSelectProductsMessage: 'Vous devez sélectionner les produits à ajouter à votre contrat.',
      disabledListPriceMessage: "Le sélecteur est désactivé car la référence SKU n'a pas de prix catalogue configuré",
      bulkListPriceErrorMessage:
        "Les références SKU ci-dessus n'ont pas pu être ajoutées car elles n'ont pas de prix catalogue ou n'existent pas sur la plateforme. Veuillez les réviser et réessayer."
    },
    chooseNewOrExisting: {
      existingButtonTitle: 'Mettre à jour un contrat existant',
      existingButtonDescription: 'Ajouter des références SKU au contrat existant avec {{name}}',
      newButtonDescription: 'Créer un nouveau contrat avec {{name}}'
    },
    addContract: {
      title: 'Configurer un contrat de prix catalogue pour les produits'
    },
    pricingContract: {
      title: 'Contrat de prix de produit',
      header: 'Configurer un contrat de prix pour vos produits',
      description:
        'Choisissez un prix catalogue ou privé pour les produits, et ajoutez éventuellement une remise ou une majoration.',
      selectedPriceModel: 'Modèle de prix sélectionné (un seul disponible)',
      selectedPriceModel_plural: 'Modèle de prix sélectionné',
      changePriceModel: 'Modifier le modèle de prix',
      noPriceModelFoundMessage:
        "Modèles de prix introuvables. Revenez en arrière et sélectionnez à nouveau vos produits ou essayez de relancer la recherche. Pour obtenir de l'aide, contactez {{ contactEmailAddress }}.",
      selectAPriceModel: 'Pour commencer, sélectionnez un modèle de prix à appliquer à vos références SKU.',
      mustSelectAPriceModel:
        'Vous devez sélectionner votre type de contrat et configurer votre contrat de prix de produit.',
      mustSelectShippingAPriceModel: `Vous devez sélectionner le type de contrat et configurer votre contrat d'expédition`
    },
    shippingContract: {
      title: "Contrat d'expédition",
      header: "Configurer un contrat de prix d'expédition pour vos produits",
      description:
        "Basez le prix d'expédition sur les frais de port, le prix d'expédition préconfiguré ou proposez la livraison gratuite. Ajoutez éventuellement une remise ou une majoration.",
      noShippingPriceModelsFoundMessage:
        "Modèle de prix d'expédition introuvable. Pour obtenir de l'aide, contactez {{ contactEmailAddress }}.",
      shippingPriceModelSelectionLabel: "Modèle de prix d'expédition sélectionné"
    },
    result: {
      step_0: 'Des notifications sont envoyées aux parties acceptantes répertoriées dans les conditions.',
      step_1: 'Votre contrat sera lu et approuvé par des approbateurs.',
      step_1_plural: 'Vos contrats seront lus et approuvés par des approbateurs.',
      step_2: 'Une fois approuvé, le contrat deviendra actif.',
      step_2_plural: 'Une fois approuvés, les contrats deviendront actifs.',
      description: 'Votre nouveau contrat a été créé et envoyé pour approbation.',
      description_plural: 'Vos nouveaux contrats ont été créés et envoyés pour approbation.',
      header: 'Opération réussie!',
      whatHappensNext: 'Que se passe-t-il ensuite?',
      backToHomePage: "Retour à la page d'accueil",
      createNewContract: 'Créer un nouveau contrat',
      viewContract: 'Afficher le contrat',
      createNewContract_plural: 'Créer de nouveaux contrats',
      duplicateContract: 'Dupliquer ce contrat',
      duplicateContract_plural: 'Dupliquer ces contrats',
      creatingContractsMessage: 'Vos contrats sont en cours de création.',
      pleaseDoNotRefresh: 'Veuillez ne pas actualiser ni fermer cette page.',
      notAllSKUsHaveCatalogPricing:
        'Les prix catalogue ne sont pas définis pour certaines références SKU. Définissez-les, puis réessayez.',
      notHaveCorrectPermissions: 'Vous ne disposez pas des autorisations requises pour créer ce contrat.',
      somethingWentWrong: 'Un problème est survenu.',
      contactInformation: "Si vous avez besoin d'aide, contactez {{ contactEmailAddress }}.",
      errorCreatingContractMessage:
        'Un problème est survenu lors de la création de votre contrat. Si le problème persiste, veuillez contacter {{ contactEmailAddress }}.'
    },
    review: {
      title: 'Examiner',
      general: 'Général',
      generalDetails: 'Détails généraux pour {{name}}',
      buyerHeader: 'Pour {{buyer}}',
      header: 'Vérifier et finaliser votre contrat',
      header_plural: 'Vérifier et finaliser vos contrats',
      description:
        'Vérifiez votre contrat et envoyez-le pour approbation. Une fois approuvé, votre contrat deviendra actif.',
      description_plural:
        'Vérifiez vos contrats et envoyez-les pour approbation. Une fois approuvés, vos contrats deviendront actifs.',
      contractDetailsLabel: 'Détails du contrat',
      contractDetailsLabel_plural: 'Détails des contrats',
      noContractBeingSetupMessage: 'Au moins un contrat doit être configuré.',
      productsInContract: 'Produits répertoriés dans le contrat',
      productsToAddContract: 'Produits à ajouter au contrat',
      productsInContract_plural: 'Produits répertoriés dans les contrats'
    }
  },
  terminationWarnings: {
    header: 'Si vous continuez...',
    warning_0: 'Une nouvelle révision sera créée avec le statut TERMINÉ.',
    warning_1: 'Ce contrat ne pourra plus être ni modifié ni révisé.',
    warning_1_0: '<0>Cela signifie que, pour modifier ce contrat, vous devez en créer un nouveau.</0>',
    warning_1_1:
      "<0>S'il s'agit d'un contrat de prix catalogue, les modifications des prix catalogue n'affecteront <1>pas</1> ce contrat.</0>",
    warning_2: 'Le contrat ne sera pas actif entre les deux parties.',
    warning_2_0:
      "<0>En cas d'achat effectué auprès du vendeur B par l'acheteur A, ce dernier <1>ne pourra plus</1> effectuer d'achat dans le cadre de ce contrat.</0>",
    warning_3: "Ce contrat ne s'affichera plus dans la page d'accueil.",
    warning_4: 'Cette action est irréversible.'
  },
  errors: {
    error_general: 'Erreur',
    error_notFound: 'Introuvable',
    error_create_permission: "Vous n'êtes pas autorisé(e) à créer des contrats",
    error_forbidden: "Vous n'êtes pas autorisé(e) à afficher à cette page.",
    error_unknown: 'Un problème est survenu lors du chargement des identités des utilisateurs.',
    withCommentAndStatusCode: 'Erreur: {{serviceComment}} ({{statusCode}})'
  },
  settings: {
    header: 'Paramètres',
    subscriptions: {
      header_contractNotification: 'Abonnements contractuels',
      header_productNotification: 'Abonnements produits',
      header2_contractNotification:
        "Gérez vos abonnements aux notifications par e-mail lorsque l'archivage de la version du produit ou les modifications de prix sont déclenchés.",
      header2_productNotification:
        "Gérez vos abonnements aux notifications par courrier électronique lorsqu'un produit est mis à jour. ",
      header3: 'Sélectionnez une identité et choisissez le parti auquel vous souhaitez vous abonner.',
      multipleRolesWarning_fulfillers:
        "Si vous avez accès à la fois aux vendeurs et aux acheteurs, seuls les abonnements effectués côté <1>vendeur</1> s'afficheront dans cette page.",
      multipleRolesWarning_merchants:
        "Si vous avez accès à la fois aux vendeurs et aux acheteurs, seuls les abonnements effectués côté <1>acheteur</1> s'afficheront dans cette page.",
      fetchingMessage: 'Récupérer vos abonnements ...',
      fetchinErrorMessage: 'Erreur lors de la récupération de vos abonnements actuels',
      searchBy_fulfillers: 'Rechercher par nom de vendeur',
      searchBy_merchants: "Rechercher par nom d'acheteur",
      manageSettingsFor: 'Gérer les paramètres pour',
      addSubscriptionButton_fulfillers: 'Configurer plusieurs vendeurs',
      addSubscriptionButton_merchants: 'Configurer plusieurs acheteurs',
      addSubscriptionTooltip_fulfillers: 'Configurer les abonnements de plusieurs vendeurs en une seule opération',
      addSubscriptionTooltip_merchants: 'Configurer les abonnements de plusieurs acheteurs en une seule opération',
      subscriptionNotAllowedForNonCimpressOwned:
        "Seul l'utilisateur du compte appartenant à cimpress peut s'abonner en tant qu'acheteur",
      subscriptionAccordion: {
        notifiedOnAll_fulfillers: 'Notifié à: <1>Tous les vendeurs</1>',
        notifiedOnAll_merchants: 'Notifié à: <1>Tous les acheteurs</1>',
        notifiedOn_fulfillers: 'Notifié à: <1>{{ count }} Vendeur</1>',
        notifiedOn_fulfillers_plural: 'Notifié à: <1>{{ count }} Vendeurs</1>',
        notifiedOn_merchants: 'Notifié à: <1>{{ count }} Acheteur</1>',
        notifiedOn_merchants_plural: 'Notifié à: <1>{{ count }} Acheteurs</1>',
        noActiveNotifications: 'Aucune notification active',
        noChangesWarning: "Vous n'avez pas modifié votre configuration actuelle.",
        noSelectionWarning_fulfillers: "Vous n'avez sélectionné aucun vendeur pour l'abonnement.",
        noSelectionWarning_merchants: "Vous n'avez sélectionné aucun acheteur pour l'abonnement."
      },
      actions: {
        subscribeToAll_sellers_contractNotification: 'Abonnez-vous à tous les vendeurs',
        unsubscribeToAll_sellers_contractNotification: 'Se désabonner de tous les vendeurs',
        subscribeToIndividual_sellers_contractNotification: 'Abonnez-vous à des vendeurs individuels',
        subscribeToAll_buyers_contractNotification: 'Abonnez-vous à tous les acheteurs',
        unsubscribeToAll_buyers_contractNotification: 'Se désabonner de tous les acheteurs',
        subscribeToIndividual_buyers_contractNotification: 'Abonnez-vous à des acheteurs individuels',
        subscribeToAll_fulfillers_contractNotification: 'Abonnez-vous à tous les vendeurs',
        unsubscribeToAll_fulfillers_contractNotification: 'Se désabonner de tous les vendeurs',
        subscribeToIndividual_fulfillers_contractNotification: 'Abonnez-vous à des vendeurs individuels',
        subscribeToAll_merchants_contractNotification: 'Abonnez-vous à tous les acheteurs',
        unsubscribeToAll_merchants_contractNotification: 'Se désabonner de tous les acheteurs',
        subscribeToIndividual_merchants_contractNotification: 'Abonnez-vous à des acheteurs individuels',
        subscribeToAll_fulfillers_productNotification: 'Abonnez-vous à tous les produits de tous les vendeurs',
        unsubscribeToAll_fulfillers_productNotification: 'Se désabonner de tous les produits de tous les vendeurs',
        subscribeToIndividual_fulfillers_productNotification: 'Abonnez-vous à tous les produits de vendeurs individuels'
      },
      selectLabel_fulfillers: 'Sélectionner un vendeur',
      selectLabel_merchants: 'Sélectionner un acheteur',
      subscriptionModal: {
        selectIdentities_fulfillers: 'Sélectionner les vendeurs à configurer',
        selectIdentities_merchants: 'Sélectionner les acheteurs à configurer',
        selectAllIdentities_fulfillers: 'Sélectionner tous les vendeurs',
        selectAllIdentities_merchants: 'Sélectionner tous les acheteurs',
        firstStepModalTitle_fulfillers: 'Configurer simultanément plusieurs de vos vendeurs',
        firstStepModalTitle_merchants: 'Configurer simultanément plusieurs de vos acheteurs',
        secondStepModalTitle_fulfillers: "Choisir le type d'abonnement des vendeurs sélectionnés",
        secondStepModalTitle_merchants: "Choisir le type d'abonnement des acheteurs sélectionnés",
        infoMessage_fulfillers: 'Vos modifications remplaceront les éventuels abonnements aux différents vendeurs.',
        infoMessage_merchants: 'Vos modifications remplaceront les éventuels abonnements aux différents acheteurs.'
      }
    },
    autoApprovals: {
      header: 'Approbations automatiques',
      header2: 'Approuver automatiquement les contrats lorsque',
      addButton: 'Ajouter une approbation automatique',
      filterInputLabel: 'Filtrer par nom',
      approvalNeeded: 'approbation nécessaire de',
      createdByLabel: 'créé ou modifié par',
      fetchingMessage: "Récupération des paramètres d'approbation automatique...",
      select_fulfillers: 'Sélectionner un vendeur',
      select_fulfillers_plural: 'Sélectionner un(des) vendeur(s)',
      select_merchants: 'Sélectionner un acheteur',
      select_merchants_plural: 'Sélectionner un(des) acheteur(s)'
    },
    features: {
      header: 'Fonctions',
      loading: {
        message: 'Récupérations des fonctions...'
      },
      shippingContracts: {
        label: "Activer les contrats d'expédition"
      },
      terminatedContracts: {
        label: 'Afficher les contrats résiliés'
      },
      languageSelection: {
        label: 'Langue'
      }
    }
  },
  createRelationships: {
    title: 'Vendeur et acheteur',
    selectBuyer: "Sélectionnez l'acheteur",
    selectSeller: 'Sélectionnez le vendeur',
    createRelationTitle: 'Créer des relations',
    enterSunsetPeriod: 'Archivage des produits',
    enterPriceLockin: 'Entrez la période de blocage des prix',
    sunsetPeriodLabel: 'journées',
    lockinPeriodLabel: 'journées',
    createRelationButtonText: 'Créer des relations',
    buyerAndSellerAccountIdSameErrorMessage:
      "L'acheteur et le vendeur ne peuvent pas être identiques dans une relation",
    agreementTitle: 'Vos accords',
    agreementSubTitle: 'Produits et prix',
    agreementNegotiationTitle: "Combien de jours à l'avance vous acceptez d'informer l'acheteur pour:",
    shippingAgreementTitle: 'livraison',
    shippingAgreementOption1: "L'acheteur paie directement le transporteur",
    shippingAgreementOption2:
      "L'acheteur paie au vendeur les frais d'expédition encourus lors de l'expédition comme prix d'expédition",
    shippingAgreementOption3: "L'acheteur paie au vendeur un prix d'expédition défini",
    shippingAgreementOption4: 'Livraison gratuite',
    notificationTitle: 'Notification',
    notificationText: "Faites-nous savoir quand l'inverse change le prix ou archive un produit",
    notificationEmailText: 'Fournissez un e-mail par ligne ou séparé par une virgule',
    helptext: 'Fournissez un e-mail par ligne ou séparé par une virgule'
  },
  viewRelationships: {
    messages: {
      noVersionsFound: 'Aucune version (s) présente ici.'
    }
  },
  productAgreementsPage: {
    mainHeading: '{{ businessOne }} et {{ businessTwo }}',
    productsHeading: 'Des produits',
    buyerSubHeading: 'acheter chez {{ businessTwo }}',
    sellerSubHeading: 'vendre à {{ businessTwo }}',
    terms: 'termes',
    productId: 'Id produit',
    priceLockinSectionOne: '{{seller}} accepte de donner à {{buyer}} au moins ',
    priceLockinSectionTwo: ' jours de préavis avant de modifier le prix.',
    sunsetSectionOne: '{{seller}} accepte de donner à {{buyer}} au moins ',
    sunsetSectionTwo: "jours avant l'archivage d'un produit que {{buyer}} achète",
    noRelationshipAsBuyer:
      "{{ businessOne }} n'a pas de relation établie pour acheter {{ businessTwo }}. S'il vous plaît contactez {{ businessTwo }} pour mettre cela en place.",
    noRelationshipAsSeller: "{{ businessOne }} n'a pas de relation établie pour vendre à {{ businessTwo }}.",
    save: 'Enregistrez les modifications',
    edit: 'Modifier la valeur',
    cancel: "Annuler l'édition",
    deletePriceLock: 'Supprimer le blocage de prix',
    deletePriceLockConfirmation: 'Voulez-vous vraiment supprimer le blocage de prix pour {{ productId }}?',
    addProducts: 'Ajouter des produits',
    search: 'Chercher',
    selectVersions: 'Sélectionnez les versions',
    view: 'Vue',
    columnNames: {
      productId: 'ID PRODUIT',
      productName: 'NOM DU PRODUIT',
      versions: 'VERSIONS',
      view: 'VUE',
      priceLock: `VERROUILLAGE DES PRIX JUSQU'À`
    },
    versionDeleteConfirmation1: 'Voulez-vous supprimer la version {{versions}}',
    versionDeleteConfirmation2: 'Ça signifie:',
    versionDeleteConfirmation3: "Vous n'achetez plus cette version.",
    versionDeleteConfirmation4: "Vous êtes d'accord pour ne pas être averti lorsque la version est archivée.",
    priceChangeTitle: 'Changement de prix',
    productArchivingTitle: 'Archivage des produits',
    shippingTitle: 'livraison',
    contactForShippingAgreement: `S'il vous plaît contactez {{ contactEmailAddress }} si vous avez besoin d'aide.`,
    productInformation: 'Information produit',
    buyerNotCimpressOwnedMessage: `{{accountName}} n'est pas activé en tant qu'acheteur sur la plate-forme!`,
    buyerNotCimpressOwnedMessageContactUs: `Veuillez contacter l'assistance Platform si {{accountName}} achète dans Platform.`
  },
  notificationDetails: {
    acknowledge: 'Reconnaître',
    contactSeller: 'Veuillez contacter le vendeur si vous avez des questions: Contact',
    isArchiving: `est l'archivage`,
    startingFrom: 'A partir de',
    noLongerAvailable: 'que vous achetez ne sera plus disponible.',
    decidedToArchive: `a décidé d'archiver`,
    unavailableToBuyFrom: 'et ne sera plus disponible pour vous à partir de',
    nonRevocableAction: `Il s'agit d'une action non révocable de`,
    ensureChangeBefore: 'par conséquent, veuillez vous assurer que vous avez effectué les modifications requises avant',
    noDisruption: `afin qu'il n'y ait aucune perturbation pour vos clients finaux.`,
    acknowledgedMessage: 'a reconnu ce message le',
    for: 'pour',
    ofThe: 'du',
    theVersion: 'la version',
    notificationAlreadyAcknowledged: 'La notification a déjà été reconnue par',
    priceUpdateEffectiveOn: 'La mise à jour des prix est effective à partir du',
    isUpdatingPricing: 'met à jour les prix pour',
    products: 'des produits',
    priceChangeAutomaticApply: `Cette modification de prix entrera automatiquement en vigueur le {{ date }}. Veuillez contacter le vendeur si vous avez des questions.`,
    asPerYourAgreement: `Conformément à votre accord avec {{ sellerName }}, de nouveaux tarifs s'appliqueront aux produits énumérés ci-dessous à partir du {{ date }}, après la période de notification obligatoire de {{ priceUpdateDays }} jour (s).`,
    acknowledgementMessage: `{{ acknowledgedBy }} a accusé réception de ce message le {{ acknowledgedAt }} pour {{ buyerName }}`,
    oldPrices: 'Anciens prix',
    newPrices: 'Nouveaux prix',
    versionDifferenceHeading: `Différence entre la version coucher du soleil {{sunsetVersion}} et la version actuelle / dernière`,
    versionDifferenceNote: `(les changements seront surlignés en bleu)`
  },
  notifications: {
    header: 'Notifications',
    searchByProductId: 'Identifiant du produit',
    startDate: 'Date de début',
    endDate: 'Date de fin',
    all: 'Toute',
    productArchiving: 'Archivage des produits',
    priceChange: 'Changement de prix',
    today: "Aujourd'hui",
    others: 'Autres',
    acknowledgedBy: 'Reconnu par:  ',
    fetchingMessage: 'Récupération de vos notifications ...',
    notificationsNotAvailable: 'Aucune notification disponible',
    notificationItems: {
      PRICECHANGE: 'Nouveau prix pour ',
      SUNSET: 'Archiver une version de '
    },
    notificationMessage: {
      PRICECHANGE: 'À partir de {} il y aura un nouveau prix pour {}',
      SUNSET: 'À partir de {} la version de {} que vous achetez ne sera plus disponible'
    }
  },
  errorRobot: {
    error: 'Veuillez actualiser la page pour réessayer. Si le problème persiste, veuillez contacter'
  }
};
