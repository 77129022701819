import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'react-emotion';
import ErrorBoundary from './ErrorBoundary';

const containerStyle = css`
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  padding-left: 50px;
  padding-right: 50px;
`;

/**
 * BasicLayout is a layout with breadcrumbs
 * and card component with white background.
 */
class BasicLayout extends React.Component {
  render() {
    const { noStyle, children, className, title } = this.props;
    return (
      <div>
        <div>
          {title && <h2>{title}</h2>}
          <ErrorBoundary>
            <div className={`${className + ' ' || ''}${noStyle ? '' : containerStyle}`}>{children}</div>
          </ErrorBoundary>
        </div>
      </div>
    );
  }
}

BasicLayout.propTypes = {
  children: PropTypes.node,
  fluid: PropTypes.bool
};

export default BasicLayout;
