import { buildQueryString } from '../utilities/queryHelper';
import { buildOptions, makeRequest } from './ServiceHelpers';
import { get } from 'lodash';

const maxInt32 = 2147483647;
const endpointUrl = process.env.REACT_APP_PRODUCT_AGREEMENT_URL;

export const getPriceLocks = async (sellerIds = [], buyerIds = [], productIds = [], offset = 0, limit = maxInt32) => {
  const options = buildOptions({ hal: true });
  const buyerQuery = buildQueryString('buyerId', buyerIds);
  const sellerQuery = buildQueryString('sellerId', sellerIds);
  const productIdQuery = buildQueryString('productId', productIds);
  const priceLockHalResponse = await makeRequest(
    endpointUrl,
    `/v1/pricelocks?offset=${offset}&limit=${limit}${buyerQuery}${sellerQuery}${productIdQuery}`,
    options,
    'getting price locks'
  );
  return {
    priceLocks: get(priceLockHalResponse, '_embedded.item'),
    totalPriceLocks: get(priceLockHalResponse, 'total')
  };
};

export const updatePriceLock = async (priceLockId, lockedUntilDate) => {
  const requestBody = {
    lockedUntil: lockedUntilDate
  };
  const options = buildOptions({ method: 'PUT', body: JSON.stringify(requestBody) });
  return await makeRequest(endpointUrl, `/v1/pricelocks/${priceLockId}`, options, 'Updating the price lock');
};

export const createPriceLock = async (sellerAccountId, buyerAccountId, productId, lockedUntilDate) => {
  const requestBody = {
    seller: {
      accountId: sellerAccountId
    },
    buyer: {
      accountId: buyerAccountId
    },
    product: {
      id: productId
    },
    lockedUntil: lockedUntilDate
  };
  const options = buildOptions({ method: 'POST', body: JSON.stringify(requestBody) });
  return await makeRequest(endpointUrl, `/v1/pricelocks`, options, 'Creating the price lock');
};

export const deletePriceLock = async priceLockId => {
  const options = buildOptions({ method: 'DELETE' });
  return await makeRequest(endpointUrl, `/v1/pricelocks/${[priceLockId]}`, options, 'Deleting the price lock');
};
