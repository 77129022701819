import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { css } from 'react-emotion';
import BasicTermInfoForm from '../shared/BasicTermInfoForm';
import { FFS_EMAIL_ADDRESS } from '../../../../constants/appConstants';
import { TextField } from '@cimpress/react-components';
import { connect } from 'react-redux';

import { UNAVAILABLE, AVAILABLE } from '../../../../constants/entityStatus';

import Spinner from '@cimpress/react-components/lib/shapes/Spinner';
import {
  selectors as shippingPriceSelectors,
  actions as shippingPriceActions
} from '../../../../reducers/contractV1/shippingprice';

const formSectionStyle = css`
  margin-bottom: 35px;
`;

const priceModelDisplayStyle = css`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`;

class ShippingPriceTermInfoForm extends PureComponent {
  state = {
    modifierError: undefined,
    loadingShippingPriceModel: true
  };

  static propTypes = {
    modifierAmount: PropTypes.string,
    modifierType: PropTypes.string,
    fetchShippingPriceModel: PropTypes.func.isRequired,
    shippingPriceModelStatus: PropTypes.string.isRequired,
    shippingPriceModel: PropTypes.object
  };

  componentDidMount() {
    const { seller, fetchShippingPriceModel } = this.props;

    fetchShippingPriceModel(seller);
  }

  onChangeModifierAmount = e => {
    const { onChangeModifierAmount, getValidationErrorFns, modifierType } = this.props;

    onChangeModifierAmount(e.target.value);
    this.setState({ modifierError: e && getValidationErrorFns.modifier(modifierType, e.target.value) });
  };

  renderShippingPriceModelDisplay() {
    const { t, shippingPriceModel, shippingPriceModelStatus } = this.props;

    switch (shippingPriceModelStatus) {
      case AVAILABLE:
        return <span>{shippingPriceModel.name}</span>;
      case UNAVAILABLE:
        return (
          <span>
            {t('createContract:shippingContract.noShippingPriceModelsFoundMessage', {
              contactEmailAddress: FFS_EMAIL_ADDRESS
            })}
          </span>
        );
      default:
        return <Spinner size={20} />;
    }
  }

  render() {
    const { t, modifierAmount, modifierType, shippingPriceModel, shippingPriceModelStatus, ...rest } = this.props;
    const { modifierError } = this.state;

    const shippingPriceModelDisplay = this.renderShippingPriceModelDisplay();

    return (
      <div>
        {modifierType && (
          <>
            <h5>{t('createContract:messages.setModifierHeader', { context: modifierType })}</h5>
            <TextField
              bsStyle={modifierError && 'error'}
              label={t('createContract:messages.modifierTextFieldLabel', { context: modifierType })}
              value={modifierAmount}
              required
              onChange={this.onChangeModifierAmount}
              onBlur={this.onChangeModifierAmount}
              type="number"
              min="0"
              helpText={modifierError}
            />
            <hr />
          </>
        )}
        <BasicTermInfoForm {...rest} />
        <div className={formSectionStyle}>
          <h5>{t('createContract:shippingContract.shippingPriceModelSelectionLabel')}</h5>
          <div className={priceModelDisplayStyle}>{shippingPriceModelDisplay}</div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  shippingPriceModelStatus: shippingPriceSelectors.getFetchShippingPriceModelStatus(state)
});

const mapDispatchToProps = {
  fetchShippingPriceModel: shippingPriceActions.fetchShippingPriceModel
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(ShippingPriceTermInfoForm));
