import { makeRequest, buildOptions } from '../../services/ServiceHelpers';

const endpointUrl = process.env.REACT_APP_IDENTITY_URL;

// export const getMerchants = async () => {
//   const options = buildOptions({ hal: true });
//   return await makeRequest(endpointUrl, `/v0/merchants`, options, 'getting configuration of all merchants.');
// };

export const getMerchants = async () => {
  const options = buildOptions();

  // NOTE: API Gateway does not seem to escape special characters so we need to pass it in as follows.
  // Might be worth investigating later
  const FILTER_VCS_REGEX = '%5E(%3F!vcs-).*%24';
  const url = `/v1/merchants?hasAll=false&verbose=false&active=true&merchantIdFilter=${FILTER_VCS_REGEX}`;

  return makeRequest(endpointUrl, url, options, 'getting all merchants list')
    .then(response => response.merchants)
    .catch(err => {
      if (err.statusCode === 403) {
        return [];
      } else {
        throw err;
      }
    });
};
