import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import Notification from './Notification';
import { css } from 'react-emotion';
import { TODAY_GROUPING_TYPE } from '../../../constants/notification';
import isEqual from 'lodash/isEqual';

const outerDiv = css({
  border: '1.8px solid #e1e6eb'
});

const border = css({
  margin: '0px',
  borderTop: '1px solid #d9e2ea'
});

function NotificationGroup(props) {
  const { t } = useTranslation();
  const { groupingKey, notifications, manageAccountId } = props;
  const notificationMessage = notifications.map(notification => {
    return (
      <Fragment key={notification.notificationId}>
        <Notification notification={notification} manageAccountId={manageAccountId} />
        <hr className={border} />
      </Fragment>
    );
  });

  return isEqual(TODAY_GROUPING_TYPE, groupingKey) ? (
    <div>
      <h4>{t(`notifications:${groupingKey}`)}</h4>
      <div className={outerDiv}>{notificationMessage}</div>
    </div>
  ) : (
    <div>
      <h4>{t(`notifications:${groupingKey}`)}</h4>
      <div className={outerDiv}>{notificationMessage}</div>
    </div>
  );
}

export default NotificationGroup;
