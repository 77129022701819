import { combineReducers } from 'redux';
import identities from './identities';
import { reducer as notificationsReducer } from 'reapop';
import configurations from './configurations';
import subscriptions from './subscriptions';
import account from './account';
import fulfillmentnetwork from './fulfillmentnetwork';
import productAgreements from './productagreements';
import { reducer as formReducer } from 'redux-form';
import cache from './cache/cache';
import home from './home';
import contractV1 from './contractV1';
import selectedManageAccount from './manageAccount';

export default combineReducers({
  identities,
  configurations,
  subscriptions,
  notifications: notificationsReducer(),
  account,
  fulfillmentnetwork,
  productAgreements,
  cache,
  home,
  form: formReducer,
  contractV1,
  selectedManageAccount
});
