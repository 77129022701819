import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Button from '@cimpress/react-components/lib/Button';
import PricingTermTable from '../../../contractV1_src/components/PricingTermTable';
import {
  actions as productAgreementActions,
  selectors as productAgreementSelectors
} from '../../../reducers/productagreements';
import { selectors as identitySelectors } from '../../../reducers/identities/index';
import CreateContract from '../../../contractV1_src/components/createContractPages/CreateContract';
import { FlexBox } from '@cimpress/react-components';

function V1Product(props) {
  const [isContractCreated, setIsContractCreated] = useState(false);
  const [isCreateContractEnable, setIsCreateContractEnable] = useState(false);
  const { buyer, seller, businessIds, tabIndex, relationships } = props;
  const [relationship, setRelationship] = useState({});
  const sellerAccountId = seller.accountId;
  const buyerAccountId = buyer.accountId;
  const accountIds = businessIds.split('|');

  useEffect(
    () => {
      let isMount = true;
      if (relationships && relationships[businessIds]) {
        const businessRelation = relationships[businessIds].relationships.find(
          relation => relation.buyer.accountId === buyerAccountId
        );
        if (businessRelation) {
          const { priceLockinDuration, relationshipId, sunsetDuration } = businessRelation;
          if (isMount) {
            setRelationship({ priceChangeDuration: priceLockinDuration, relationshipId, sunsetDuration });
          }
        }
      }
      return () => {
        isMount = false;
      };
    },
    [relationships, businessIds, buyerAccountId]
  );

  const onContractsLoaded = () => setIsContractCreated(false);
  const onCloseCreateContractModal = () => setIsCreateContractEnable(false);
  const onContractCreated = () => setIsContractCreated(true);
  const onClickCreateContract = () => setIsCreateContractEnable(true);

  const getReference = () => `${businessIds}&&!!${tabIndex}`;

  return (
    <div>
      <div className="row" style={{ float: 'right', paddingRight: '10px' }}>
        <FlexBox middle center>
          <button
            type="button"
            className="btn btn-primary"
            onClick={onClickCreateContract}
            style={props.isAdmin || !props.isReadOnly ? { display: 'block' } : { display: 'none' }}>
            Create Contract
          </button>
          <div style={accountIds[0] === sellerAccountId ? { display: 'block' } : { display: 'none' }}>
            <a href={`${process.env.REACT_APP_CONTRACT_MANAGER_V1_URL}/configurations/${getReference()}`}>
              <Button type="primary" style={{ marginLeft: '10px' }}>
                Configure Auto Approval
              </Button>
            </a>
          </div>
        </FlexBox>
      </div>
      <br />
      <div className="row" style={{ paddingTop: '30px', paddingRight: '10px' }}>
        <PricingTermTable
          sellerAccountId={sellerAccountId}
          buyerAccountId={buyerAccountId}
          businessIds={businessIds}
          currentTabIndex={tabIndex}
          isContractCreated={isContractCreated}
          onContractsLoaded={onContractsLoaded}
        />
      </div>
      {isCreateContractEnable && (
        <CreateContract
          sellerAccountId={sellerAccountId}
          buyerAccountId={buyerAccountId}
          sellerAccountName={seller.name}
          buyerAccountName={buyer.name}
          priceChangeDays={relationship.priceChangeDuration}
          isModalOpen={isCreateContractEnable}
          onCloseModal={onCloseCreateContractModal}
          onContractCreated={onContractCreated}
        />
      )}
    </div>
  );
}

const mapStateToProps = state => {
  return {
    relationships: productAgreementSelectors.getRelationships(state),
    isAdmin: identitySelectors.isUserAdmin(state)
  };
};

const mapDispatchToProps = {
  updateRelationship: productAgreementActions.updateRelationshipAction
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(V1Product);
