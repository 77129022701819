import React, { useEffect, useState } from 'react';
import memoizeOne from 'memoize-one';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  actions as productAgreementActions,
  selectors as productAgreementSelectors
} from '../../reducers/productagreements';
import { actions as manageAccountActions, selectors as manageAccountSelectors } from '../../reducers/manageAccount';
import {
  actions as fulfillmentNetworkActions,
  selectors as fulfillmentNetworkSelectors
} from '../../reducers/fulfillmentnetwork';
import { actions as accountActions, selectors as accountSelectors } from '../../reducers/account';
import { auth, getAccount } from '../../utils/auth';
import Table from '@cimpress/react-components/lib/Table';
import TextField from '@cimpress/react-components/lib/TextField';
import CimpressButton from '@cimpress/react-components/lib/Button';
import { NavTab, NavTabItem } from '@cimpress/react-components';
import Button from '../common/Button';
import { NOT_LOADED } from '../../constants/entityStatus';
import { useTranslation } from 'react-i18next';
import { history } from '../../utils/history';
import { createSearchFilters } from '../../utilities/searchUtils';
import CreateRelationships from '../contracts/createRelationships/CreateRelationships';
import { selectors as identitySelectors } from '../../reducers/identities/index';
import { actions as pricingtermsActionList } from '../../reducers/contractV1';
import { isManageContractPermission } from '../../utilities/checkPermission';
import MenuList from '../common/MenuList';
import { createFilter } from 'react-select';
import Select from 'react-select';

const CIMPRESS = 'CIMPRESS';
const THIRD_PARTY = 'OUT_NETWORK';
const ALL = 'ALL';
const CONTRACTV1_SELF = 'SELF_RELATIONSHIP';
const userAccountId = getAccount(auth.getProfile());

function getColumns(t) {
  return [
    {
      id: 'relationship',
      Header: 'Relationship',
      accessor: t => t.display,
      Cell: props => renderLink(props)
    }
  ];
}

function renderLink(cellInfo) {
  const { original } = cellInfo;

  return (
    <div className="relationship-link">
      <Link to={`productAgreements/${original.relationships[0].key}`}>{original.display}</Link>
    </div>
  );
}
const filterRelationships = memoizeOne(({ relationships, networkFilter }) => {
  if (!relationships) {
    return [];
  }
  const allRelationships = relationships.filter(relationship => relationship.hasPermission);
  const thirdPartyRelationships = allRelationships
    .filter(
      relationship =>
        relationship.relationships.filter(
          each => (each.seller && !each.seller.cimpressOwned) || (each.buyer && !each.buyer.cimpressOwned)
        ).length > 0
    )
    .filter(relationship => relationship.businessOne !== relationship.businessTwo);
  const cimpressRelationships = allRelationships
    .filter(
      relationship =>
        relationship.relationships.filter(
          each =>
            (!each.seller || (each.seller && each.seller.cimpressOwned)) &&
            (!each.buyer || (each.buyer && each.buyer.cimpressOwned))
        ).length > 0
    )
    .filter(relationship => relationship.businessOne !== relationship.businessTwo);
  switch (networkFilter) {
    case ALL:
      return allRelationships;
    case THIRD_PARTY:
      return thirdPartyRelationships;
    case CONTRACTV1_SELF:
      const selfRelationShip = allRelationships.filter(
        relationship => relationship.businessOne === relationship.businessTwo
      );
      return selfRelationShip;
    default:
      return cimpressRelationships;
  }
});

const filterRelationShipsData = createSearchFilters(['display', 'businessOne', 'businessTwo']);

function RelationshipTable(props) {
  const [isCreateRelationshipEnable, setIsCreateRelationshipEnable] = useState(false);
  const { t } = useTranslation();
  const [selectedAccount, setSelectedAccount] = useState({});
  const [networkFilter, setNetworkFilter] = useState(ALL);
  const [searchQuery, setSearchQuery] = useState('');
  const {
    networkStatus,
    relationshipsStatus,
    relationships,
    accounts,
    isAdmin,
    setManageAccountId,
    selectedManageAccountPayload
  } = props;
  const [doesUserHasManageContractPermission, setManageContractPermission] = useState(false);

  useEffect(() => {
    var manageAccountId =
      selectedManageAccountPayload && selectedManageAccountPayload.value
        ? selectedManageAccountPayload.value
        : userAccountId;
    props.fetchRelationships([manageAccountId], manageAccountId);
    props.fetchNetwork();
    props.fetchPricingTerms();
    props.fetchAllAccounts();
  }, []);

  useEffect(
    () => {
      if (accounts.length > 0) {
        accounts.forEach(account => {
          var manageAccountId =
            selectedManageAccountPayload && selectedManageAccountPayload.value
              ? selectedManageAccountPayload.value
              : userAccountId;
          if (account.value === manageAccountId) {
            props.setSelectedManageAccountAction(account);
            setManageAccountId(account.value);
            var isManageContractPermissionForUser = isManageContractPermission(account.value, props.userPermissions);
            setManageContractPermission(isManageContractPermissionForUser);
            setSelectedAccount(account);
          }
        });
      }
    },
    [accounts]
  );

  const isLoading = networkStatus === NOT_LOADED || relationshipsStatus === NOT_LOADED;

  const onSetAllFilter = () => setNetworkFilter(ALL);
  const onSetCimpressFilter = () => setNetworkFilter(CIMPRESS);
  const onSetThirdPartyFilter = () => setNetworkFilter(THIRD_PARTY);
  const onContractV1SelfRelationship = () => setNetworkFilter(CONTRACTV1_SELF);
  const onChangeSearch = e => setSearchQuery(e.target.value);

  const onChangeSelectedAccount = async selectedAccount => {
    if (selectedAccount !== null) {
      setSelectedAccount(selectedAccount);
      await props.fetchRelationships([selectedAccount.value], selectedAccount.value);
      props.setSelectedManageAccountAction(selectedAccount);
      setManageAccountId(selectedAccount.value);
    }
  };

  const data = filterRelationShipsData(searchQuery, filterRelationships({ relationships, networkFilter }));

  function onClickCreateRelationships() {
    setIsCreateRelationshipEnable(true);
  }

  const onCloseCreateRelationshipModal = () => setIsCreateRelationshipEnable(false);
  const updateBusinessRelationships = (buyerId, sellerId) => {
    onCloseCreateRelationshipModal();
    history.push(`productAgreements/${buyerId}|${sellerId}`);
  };

  return (
    <div>
      <div className="home-title-manage-for">
        <div className="contract-home-title">{t('landingPage:contracts')}</div>
        <div className="contract-manager-for">
          <Select
            components={{ MenuList }}
            filterOption={createFilter({ ignoreAccents: false })}
            placeholder={t('landingPage:manageContractsFor')}
            value={selectedAccount}
            options={accounts}
            onChange={onChangeSelectedAccount}
          />
        </div>
      </div>
      <div className="flexContainerWithSpace">
        <div className="search">
          <div className="form-group has-search">
            <span className="fa fa-search form-control-feedback" />
            <TextField
              id="SearchBar"
              onChange={onChangeSearch}
              value={searchQuery}
              type="search"
              placeholder={t('relationshipTable:searchTextFieldPlaceholder')}
            />
          </div>
        </div>
        <div className="create-contract" align="right">
          {(isAdmin || doesUserHasManageContractPermission) && (
            <CimpressButton
              className="create-contract-button"
              size="lg"
              type="primary"
              onClick={onClickCreateRelationships}>
              {t('createRelationships:createRelationButtonText')}
            </CimpressButton>
          )}
        </div>
      </div>
      <NavTab className="overrideNavTab">
        <NavTabItem active={networkFilter === ALL}>
          <Button onClick={onSetAllFilter}>{t('relationshipTable:all')}</Button>
        </NavTabItem>
        <NavTabItem active={networkFilter === CIMPRESS}>
          <Button onClick={onSetCimpressFilter}>{t('relationshipTable:marketplace')}</Button>
        </NavTabItem>
        <NavTabItem active={networkFilter === THIRD_PARTY}>
          <Button onClick={onSetThirdPartyFilter}>{t('relationshipTable:fulfilmentNetwork')}</Button>
        </NavTabItem>
        <NavTabItem active={networkFilter === CONTRACTV1_SELF}>
          <Button onClick={onContractV1SelfRelationship}>{t('relationshipTable:ContractV1:selfRelationship')}</Button>
        </NavTabItem>
      </NavTab>
      <div className="relationship-table">
        <Table TheadComponent={_ => null} columns={getColumns(t)} data={data} loading={isLoading} />
      </div>
      {isCreateRelationshipEnable && (
        <CreateRelationships
          onRelationshipCreate={updateBusinessRelationships}
          onCloseModal={onCloseCreateRelationshipModal}
          isOpenInModal={true}
          manageAccountId={selectedAccount.value}
        />
      )}
    </div>
  );
}

const mapStateToProps = state => ({
  relationships: productAgreementSelectors.getRelationshipsForHomePage(state),
  relationshipsStatus: productAgreementSelectors.getRelationshipStatus(state),
  network: fulfillmentNetworkSelectors.getNetwork(state),
  networkStatus: fulfillmentNetworkSelectors.getNetworkStatus(state),
  accounts: accountSelectors.getAccountsOptions(state),
  isAdmin: identitySelectors.isUserAdmin(state),
  userPermissions: identitySelectors.getPermissionsPayload(state),
  selectedManageAccountPayload: manageAccountSelectors.getSelectedManageAccountPayload(state)
});

const mapDispatchToProps = {
  fetchRelationships: productAgreementActions.fetchRelationshipsAction,
  fetchNetwork: fulfillmentNetworkActions.fetchNetworkAction,
  fetchPricingTerms: pricingtermsActionList.fetchPricingTermsAction,
  fetchAllAccounts: accountActions.fetchAllAccountsAction,
  setSelectedManageAccountAction: manageAccountActions.setSelectedManageAccountAction
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RelationshipTable);
