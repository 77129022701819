import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { AutoSizer, List } from 'react-virtualized';

class SkuCardVirtualizedList extends Component {
  static propTypes = {
    skuCardList: PropTypes.array.isRequired,
    skuCardRenderer: PropTypes.func.isRequired,
    height: PropTypes.number.isRequired
  };

  skuCardRenderer = ({ index, isScrolling, isVisible, key, parent, style }) => {
    const { skuCardRenderer, skuCardList } = this.props;

    const skuInfo = skuCardList ? skuCardList[index] : {};

    const content = skuCardRenderer(skuInfo);

    return (
      <div key={key} style={style}>
        {content}
      </div>
    );
  };

  render() {
    const { skuCardList, ...rest } = this.props;

    return (
      <div>
        <AutoSizer disableHeight>
          {({ width }) => (
            <div>
              <List
                rowRenderer={this.skuCardRenderer}
                width={width}
                rowCount={skuCardList ? skuCardList.length : 0}
                rowHeight={60}
                {...rest}
              />
            </div>
          )}
        </AutoSizer>
      </div>
    );
  }
}

export default SkuCardVirtualizedList;
