import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal } from '@cimpress/react-components';
import SelectFulfillerMerchantStep from './SelectFulFillerMerchantStep';
import AddProductsStep from './AddProductsStep';
import isEmpty from 'lodash/isEmpty';
import ContractType from './ContractType';
import ShippingContractType from './ShippingContractType';

import ReviewStep from './ReviewStep';
import { actions as pricingTermActions } from '../../../reducers/contractV1';
import { actions as terms } from '../../../reducers/contractV1/terms';
import { connect } from 'react-redux';
import _ from 'lodash';
const reveiwAndCreateContractStepNumber = 4;

const getInitialState = () => {
  return {
    initiator: {},
    acceptors: [],
    selectedSkus: {},
    productPricingTerm: {},
    shippingPricingTerm: {},
    createSuccessful: false
  };
};

function evaluateCondition(createContractData, step, enableShippingContractV1) {
  switch (step) {
    case 0:
      return isEmpty(createContractData.acceptors) || isEmpty(createContractData.initiator) ? false : true;
    case 1:
      return !isEmpty(createContractData.selectedSkus);
    case 2:
      return createContractData.isContractTypeStepValid;
    case 3:
      return enableShippingContractV1 ? createContractData.isShippingContractTypeStepValid : true;
    case 4:
      return true;
    default:
      return false;
  }
}

function CreateContract(props) {
  const { t } = useTranslation();
  const [step, setStep] = useState(0);
  const [createContractData, setCreateContractData] = useState(getInitialState);
  const [enableShippingContractV1, setEnableShippingContractV1] = useState(false);
  let currentStepAction = useRef(undefined);
  const isEnable = evaluateCondition(createContractData, step, enableShippingContractV1);

  const {
    sellerAccountId,
    buyerAccountId,
    isModalOpen,
    onCloseModal,
    onContractCreated,
    sellerAccountName,
    buyerAccountName,
    priceChangeDays,
    createPricingTerm,
    createShippingTerm
  } = props;

  const createTerm = async () => {
    const { initiator, acceptors, productPricingTerm, selectedSkus, shippingPricingTerm } = createContractData;

    const initiatorForTerm = {
      type: 'fulfillers',
      id: initiator.id
    };

    const acceptorMerchantIds = acceptors.map(({ label, value }) => value);
    const createTermCalls = _.map(acceptorMerchantIds, acceptorMerchantId => {
      const acceptor = {
        type: 'merchants',
        id: acceptorMerchantId
      };

      const buyer = { ...acceptor };
      const seller = { ...initiatorForTerm };

      let termCalls = [];

      if (productPricingTerm.termType) {
        termCalls.push(
          createPricingTerm({
            pricingTermInfo: productPricingTerm,
            selectedSkus,
            initiator: initiatorForTerm,
            acceptor,
            buyer,
            seller,
            toPendingStatus: true,
            priceChangeDays: priceChangeDays
          })
        );
      }

      if (enableShippingContractV1 && shippingPricingTerm && shippingPricingTerm.termType) {
        termCalls.push(
          createShippingTerm({
            shippingTermInfo: shippingPricingTerm,
            selectedSkus,
            initiator: initiatorForTerm,
            acceptor,
            buyer,
            seller,
            toPendingStatus: true,
            priceChangeDays: priceChangeDays
          })
        );
      }
      return termCalls;
    });

    await Promise.all(_.flatten(createTermCalls));
    onCloseModal();
    onContractCreated();
  };

  const nextStep = () => {
    currentStepAction.current = 'next';
    setStep(step + 1);
  };

  const prevStep = () => {
    currentStepAction.current = 'back';
    setStep(step - 1);
  };

  const onChangeFlow = newState => {
    setCreateContractData(prevState => ({ ...prevState, ...newState }));
  };

  const getModal = () => {
    return (
      <Modal
        style={{ width: '50%', height: '100%' }}
        show={isModalOpen}
        onRequestHide={onCloseModal}
        title="Create Contract"
        closeButton={true}
        className="create-contract-modal"
        footer={
          <div>
            {step === 0 ? null : (
              <div style={{ float: 'left' }}>
                <Button type="default" onClick={prevStep}>
                  Back
                </Button>
              </div>
            )}
            <div style={{ float: 'right' }}>
              <Button
                disabled={!isEnable}
                type="default"
                onClick={step === reveiwAndCreateContractStepNumber ? createTerm : nextStep}>
                {step === reveiwAndCreateContractStepNumber ? 'Create Contract' : 'Next'}
              </Button>
            </div>
          </div>
        }>
        <div>
          <div>{display}</div>
        </div>
      </Modal>
    );
  };

  let display;
  switch (step) {
    case 0:
      display = (
        <SelectFulfillerMerchantStep
          sellerAccountId={sellerAccountId}
          buyerAccountId={buyerAccountId}
          createContractData={createContractData}
          setCreateContractData={setCreateContractData}
          sellerAccountName={sellerAccountName}
          buyerAccountName={buyerAccountName}
          enableShippingContractV1={enableShippingContractV1}
          onChangeOfShippingContractV1={() => setEnableShippingContractV1(!enableShippingContractV1)}
        />
      );
      break;
    case 1:
      display = (
        <div>
          <AddProductsStep createContractData={createContractData} onChange={onChangeFlow} />
        </div>
      );
      break;
    case 2:
      display = (
        <div>
          <div>
            <ContractType
              createContractData={createContractData}
              setCreateContractData={setCreateContractData}
              onSubmit={nextStep}
            />
          </div>
        </div>
      );
      break;
    case 3:
      if (enableShippingContractV1) {
        display = (
          <div>
            <div>
              <ShippingContractType
                createContractData={createContractData}
                setCreateContractData={setCreateContractData}
                onSubmit={nextStep}
              />
            </div>
          </div>
        );
      } else {
        if (currentStepAction.current === 'next') nextStep();
        else prevStep();
      }
      break;
    case 4:
      display = (
        <ReviewStep
          createContractData={createContractData}
          setCreateContractData={setCreateContractData}
          priceChangeDays={priceChangeDays}
        />
      );
      break;
    default:
      display = <div>{t('errors:error_general')}</div>;
  }

  return <div>{getModal()}</div>;
}

const mapDispatchToProps = {
  createPricingTerm: pricingTermActions.createPricingTermAction,
  createShippingTerm: terms.createShippingTerm
};

export default connect(
  null,
  mapDispatchToProps
)(CreateContract);
