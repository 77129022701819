import { makeRequest, buildOptions } from '../../services/ServiceHelpers';

const endpointUrl = process.env.REACT_APP_DSS_URL;
const primaryId = process.env.REACT_APP_DSS_PRIMARY;
const REACT_APP_DSS_V2_PRIMARY = process.env.REACT_APP_DSS_V2_PRIMARY;

export const getAccountIdsEnabledForShippingContract = async () => {
  let options = buildOptions({ hal: true });
  const response = await makeRequest(
    endpointUrl,
    `?primaryId=${primaryId}&secondaryId=enabled_shippingcontractaccountids`,
    options,
    'getting shippingcontractaccountids.'
  );
  const headers = new Headers({
    Accept: 'application/json'
  });
  options = { headers };
  return await makeRequest(response.documentUrl, null, options, 'getting shippingcontractaccountids from S3.');
};

export const getAccountIdsEnabledForV2 = async () => {
  let options = buildOptions({ hal: true });
  const response = await makeRequest(
    endpointUrl,
    `?primaryId=${REACT_APP_DSS_V2_PRIMARY}&secondaryId=enabled_v2`,
    options,
    'getting enabled v2 ui forcefully.'
  );
  const headers = new Headers({
    Accept: 'application/json'
  });
  options = { headers };
  return await makeRequest(response.documentUrl, null, options, 'getting v2 ui information from s3.');
};
