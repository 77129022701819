import { makeRequest, buildOptions } from './ServiceHelpers';
import { buildQueryString } from '../utilities/queryHelper';

const endpointUrl = process.env.REACT_APP_PRODUCT_INTRODUCTION_SERVICE_URL;
const productEndpointUrl = process.env.REACT_APP_PRODUCT_SERVICE;

export const getProducts = async (productIds = []) => {
  const options = buildOptions({ hal: true });
  let buyerQueries = buildQueryString('referenceId', productIds);
  const halResponse = await makeRequest(endpointUrl, `/v1/products?${buyerQueries}`, options, 'Get products');
  return halResponse;
};

export const getProductByProductIdAndVersion = async (productId, version) => {
  const options = buildOptions();
  return await makeRequest(productEndpointUrl, `/v1/products/${productId}/versions/${version}`, options, 'Get product');
};
