import byKey from 'lodash/keyBy';
export const getSellerAccountMapOfUser = (relationships, accountId) => {
  const sellerAccounts = [];
  if (!relationships) {
    return sellerAccounts;
  }
  relationships.forEach(relation => {
    if (relation.relationships) {
      relation.relationships.forEach(rel => {
        if (rel.buyer) {
          if (rel.buyer.accountId === accountId) {
            if (rel.seller) {
              const { accountId, cimpressOwned, name } = rel.seller;
              sellerAccounts.push({ accountId, cimpressOwned, name });
            }
          }
        }
      });
    }
  });

  return byKey(sellerAccounts, 'accountId');
};

export const getRelationship = businessRelation => {
  const { buyer, seller, sunsetDuration, priceLockinDuration, relationshipId } = businessRelation;
  return {
    relationshipId: relationshipId,
    buyerAccountId: buyer.accountId,
    buyerName: buyer.name,
    sellerAccountId: seller.accountId,
    sellerName: seller.name,
    priceLockinDuration: priceLockinDuration,
    sunsetDuration: sunsetDuration
  };
};
