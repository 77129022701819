import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import memoizeOne from 'memoize-one';
import { connect } from 'react-redux';
import { Pagination, Breadcrumbs, BreadcrumbItem } from '@cimpress/react-components';
import { Link } from 'react-router-dom';
import Spinner from '@cimpress/react-components/lib/shapes/Spinner';
import BasicLayout from '../../layouts/BasicLayout';
import NotificationGroup from './NotificationGroup';
import NotificationTypeNav from './NotificationTypeNav';
import { actions as notificationAction, selectors as notificationSelector } from '../../../reducers/productagreements';
import { actions as subsActions, selectors as subsSelectors } from '../../../reducers/subscriptions/index';
import { actions as accountActions } from '../../../reducers/account';
import { AVAILABLE } from '../../../constants/entityStatus';
import { ALL, GROUPING_TYPE } from '../../../constants/notification';
import { createSearchFilters } from '../../../utilities/searchUtils';
import { Alert } from '@cimpress/react-components';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import filter from 'lodash/filter';
import groupBy from 'lodash/groupBy';
import NotificationsFilter from './NotificationsFilter';
import { BUYERS } from '../../../constants/transactorType';
import { CONTRACT_NOTIFICATION, AGREEMENT_RESOURCE_TYPE, INDIVIDUAL } from '../../../constants/subscriptionConstants';

const paginationCount = 5;

const filterNotifications = memoizeOne(({ notifications, notificationFilter }) => {
  if (isEqual(notificationFilter, ALL)) {
    return notifications;
  }
  const filterRecord = filter(notifications, ['notificationType', notificationFilter]);
  return filterRecord;
});

const filterNotificationsData = createSearchFilters([
  'buyerAccountId',
  'buyerName',
  'sellerAccountId',
  'sellerName',
  'productId',
  'version',
  'productName'
]);

const getFilteredNotificationsData = (searchQuery, notifications) => {
  if (!notifications || isEmpty(searchQuery)) {
    return notifications;
  }
  return filterNotificationsData(searchQuery, notifications);
};

const getSubscribedCounterParties = (payload, identityType, manageAccountId) => {
  if (payload && payload[identityType] && payload[identityType][manageAccountId]) {
    const { counterParties, subscriptionType } = payload[identityType][manageAccountId];
    if (subscriptionType === INDIVIDUAL) return counterParties.map(counterParty => counterParty.counterPartyId);
  }
  return [];
};

const getInitialState = () => {
  return {
    userAccount: undefined,
    loading: true,
    notificationFilter: ALL,
    currentPage: 0,
    searchQuery: '',
    counterParties: []
  };
};
const getDataGroupByType = notifications => {
  return groupBy(notifications, GROUPING_TYPE);
};

const isPaginationEnabled = (notifications, pageCount) => {
  return notifications ? notifications.length > pageCount : false;
};

const getNotificationsToRender = (notifications, paginationCount, currentPage, pageCount) => {
  if (!notifications) {
    return notifications;
  }
  const startIndex = paginationCount * currentPage;
  return notifications.slice(
    startIndex,
    currentPage === pageCount ? notifications.length : startIndex + paginationCount
  );
};

function Notifications(props) {
  const { t } = useTranslation();
  const [state, setState] = useState(getInitialState);
  const { notificationsStatus, notifications } = props;
  const { loading, notificationFilter, currentPage, searchQuery, counterParties } = state;

  const manageAccountId = props.match.params.manageAccountId;

  useEffect(
    () => {
      async function fetchSubscriptionAndNotifications() {
        const response = await props.fetchAllSubscriptions(AGREEMENT_RESOURCE_TYPE);
        const counterParties = getSubscribedCounterParties(response.payload, BUYERS, manageAccountId);
        const { payload } = await props.fetchAccountById(manageAccountId);
        setState(prevState => ({ ...prevState, userAccount: payload, loading: false, counterParties: counterParties }));
        props.fetchNotifications([manageAccountId], counterParties);
      }
      if (manageAccountId) {
        fetchSubscriptionAndNotifications();
      }
    },
    [manageAccountId]
  );

  const onChangeNotificationFilter = filterType => {
    setState(prevState => ({ ...prevState, notificationFilter: filterType, currentPage: 0 }));
  };
  const onPageChange = ({ selected }) => {
    setState(prevState => ({ ...prevState, currentPage: selected }));
  };

  const isLoading = loading || notificationsStatus !== AVAILABLE;

  // filter the notification on notification type or search text
  const filteredNotifications = getFilteredNotificationsData(
    searchQuery,
    filterNotifications({ notifications, notificationFilter })
  );
  const isPaginationEnable = isPaginationEnabled(filteredNotifications, paginationCount);

  const totalCount = filteredNotifications ? filteredNotifications.length : 0;

  // Calculate page count for pagination
  const pageCount = Math.floor(totalCount / paginationCount) + 1;

  // get notification to render
  const notificationsToRender = getNotificationsToRender(
    filteredNotifications,
    paginationCount,
    currentPage,
    pageCount
  );

  const data = getDataGroupByType(notificationsToRender);
  const pagination = (
    <div style={{ textAlign: 'center' }}>
      <Pagination initialPage={currentPage} pageCount={pageCount} forcePage={currentPage} onPageChange={onPageChange} />
    </div>
  );

  const onSearchNotifications = (sellerIds, productIds, startDate, endDate) => {
    props.fetchNotifications([manageAccountId], sellerIds, productIds, startDate, endDate);
  };

  const loadingComponent = (
    <div className={'loading-container'}>
      <Spinner />
      <h5>{t('notifications:fetchingMessage')}</h5>
    </div>
  );
  const body =
    data && Object.keys(data).length > 0 ? (
      Object.keys(data).map(key => (
        <NotificationGroup key={key} groupingKey={key} notifications={data[key]} manageAccountId={manageAccountId} />
      ))
    ) : (
      <Alert type="info" message={t('notifications:notificationsNotAvailable')} dismissible={true} />
    );
  return (
    <div>
      <div className="breadCrumbPadding">
        <Breadcrumbs>
          <BreadcrumbItem>
            <Link key={'/'} to={'/'}>
              {t('breadcrumbs:home')}
            </Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>{t('breadcrumbs:notifications')}</BreadcrumbItem>
        </Breadcrumbs>
      </div>
      <div className="titlePadding">
        <h1>{t('notifications:header')}</h1>
      </div>
      <BasicLayout>
        <NotificationsFilter
          onSearch={onSearchNotifications}
          contractSubscriptions={counterParties}
          identityType={BUYERS}
          notificationType={CONTRACT_NOTIFICATION}
          manageAccountId={manageAccountId}
        />

        <NotificationTypeNav
          notifications={notifications}
          onChange={onChangeNotificationFilter}
          notificationFilter={notificationFilter}
        />

        {isLoading ? loadingComponent : <div className="notificationBackground">{body}</div>}
        {isLoading || !isPaginationEnable ? '' : pagination}
      </BasicLayout>
    </div>
  );
}

const mapStateToProps = state => ({
  contractSubscriptions: subsSelectors.getAvailableSubscriptions(state, BUYERS),
  getAllSubscriptionsStatus: subsSelectors.getAllSubscriptionsStatus(state),
  notificationsStatus: notificationSelector.getNotificationsStatus(state),
  notifications: notificationSelector.getNotifications(state)
});

const mapDispatchToProps = {
  fetchAllSubscriptions: subsActions.fetchAllSubscriptions,
  fetchNotifications: notificationAction.fetchNotificationsAction,
  fetchAccountById: accountActions.fetchAccountAction
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Notifications);
