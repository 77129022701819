import * as productAgreementApi from '../../services/ProductAgreement';
import { createReducer } from '../utils';
import { AVAILABLE, SAVING, UNAVAILABLE } from '../../constants/entityStatus';

export const createShippingAgreements_REQUEST = 'createShippingAgreements_REQUEST';
export const createShippingAgreements_SUCCESS = 'createShippingAgreements_SUCCESS';
export const createShippingAgreements_FAILURE = 'createShippingAgreements_FAILURE';

const createShippingAgreementsHooks = {
  onRequest: 'Creating shipping agreements...',
  onSuccess: 'Shipping agreements created!',
  onFailure: 'Failed to create shipping agreement.'
};

// Action
export const createShippingAgreementsAction = (buyer, seller, shippingAgreementType) => {
  return {
    types: [createShippingAgreements_REQUEST, createShippingAgreements_SUCCESS, createShippingAgreements_FAILURE],
    callAPI: async () => {
      return await productAgreementApi.createShippingAgreements(buyer.value, seller.value, shippingAgreementType);
    },
    hooks: createShippingAgreementsHooks
  };
};

// reducer
export default createReducer(
  {},
  {
    [createShippingAgreements_REQUEST]: (state, action) => {
      return { ...state, status: SAVING };
    },
    [createShippingAgreements_SUCCESS]: (state, action) => {
      const { payload } = action;
      return { ...state, payload, status: AVAILABLE };
    },
    [createShippingAgreements_FAILURE]: (state, action) => {
      return { ...state, status: UNAVAILABLE };
    }
  }
);

// Selector
export const getCreatedShippingAgreements = state => {
  return state.productAgreements.createdShippingAgreements.payload;
};

export const getShippingAgreementsCreationStatus = state => {
  return state.productAgreements.createdShippingAgreements.status;
};
