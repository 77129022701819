import { createSelector } from 'reselect';
import { combineReducers } from 'redux';

import * as ENTITY_STATUS from '../../constants/entityStatus';
import * as merchantApi from '../../services/MerchantConfig';
import * as fulfillerApi from '../../services/FulfillerIdentity';
import * as coamApi from '../../services/Coam';
import { FULFILLERS, MERCHANTS } from '../../constants/transactorType';
import { createApiErrorNotificationAction } from '../utils';
import { mapToIdsAndByIds, hasAuthorizedFulfillerPermissions, hasAuthorizedMerchantPermissions } from './util';

// - ACTION TYPES
const FETCH_FULFILLERS_REQUEST = 'FETCH_FULFILLERS_REQUEST';
const FETCH_FULFILLERS_SUCCESS = 'FETCH_FULFILLERS_SUCCESS';
const FETCH_FULFILLERS_FAILURE = 'FETCH_FULFILLERS_FAILURE';
const FETCH_MERCHANTS_REQUEST = 'FETCH_MERCHANTS_REQUEST';
const FETCH_MERCHANTS_SUCCESS = 'FETCH_MERCHANTS_SUCCESS';
const FETCH_MERCHANTS_FAILURE = 'FETCH_MERCHANTS_FAILURE';
const FETCH_IDENTITY_PERMISSIONS_REQUEST = 'FETCH_IDENTITY_PERMISSIONS_REQUEST';
const FETCH_IDENTITY_PERMISSIONS_SUCCESS = 'FETCH_IDENTITY_PERMISSIONS_SUCCESS';
const FETCH_IDENTITY_PERMISSIONS_FAILURE = 'FETCH_IDENTITY_PERMISSIONS_FAILURE';

// - ACTION CREATORS
const fetchFulfillersRequest = () => ({ type: FETCH_FULFILLERS_REQUEST });
const fetchFulfillersSuccess = payload => ({ type: FETCH_FULFILLERS_SUCCESS, payload });
const fetchFulfillersFailure = payload => ({ type: FETCH_FULFILLERS_FAILURE, payload });
const fetchMerchantsRequest = () => ({ type: FETCH_MERCHANTS_REQUEST });
const fetchMerchantsSuccess = payload => ({ type: FETCH_MERCHANTS_SUCCESS, payload });
const fetchMerchantsFailure = payload => ({ type: FETCH_MERCHANTS_FAILURE, payload });
const fetchIdentityPermissionsRequest = () => ({ type: FETCH_IDENTITY_PERMISSIONS_REQUEST });
const fetchIdentityPermissionsSuccess = payload => ({ type: FETCH_IDENTITY_PERMISSIONS_SUCCESS, payload });
const fetchIdentityPermissionsFailure = payload => ({ type: FETCH_IDENTITY_PERMISSIONS_FAILURE, payload });

const fetchFulfillers = () => async dispatch => {
  dispatch(fetchFulfillersRequest());

  fulfillerApi
    .fetchAllFulfillers()
    .then(mapToIdsAndByIds)
    .then(o => dispatch(fetchFulfillersSuccess(o)))
    .catch(err => {
      dispatch(fetchFulfillersFailure(err));
      dispatch(createApiErrorNotificationAction(err));
    });
};

const fetchMerchants = () => async dispatch => {
  dispatch(fetchMerchantsRequest());

  merchantApi
    .fetchAllMerchants()
    .then(mapToIdsAndByIds)
    .then(o => dispatch(fetchMerchantsSuccess(o)))
    .catch(err => {
      dispatch(fetchMerchantsFailure(err));
      dispatch(createApiErrorNotificationAction(err));
    });
};

const fetchIdentityPermissions = () => async dispatch => {
  dispatch(fetchIdentityPermissionsRequest());

  coamApi
    .fetchIdentityPermissions()
    .then(res => dispatch(fetchIdentityPermissionsSuccess(res)))
    .catch(err => {
      dispatch(fetchIdentityPermissionsFailure(err));
      dispatch(createApiErrorNotificationAction(err));
    });
};

/**
 * Action creator used to load merchant/fulfiller identities and identities that the user has permission to.
 * @returns {Function}
 */
const loadUserIdentities = () => async dispatch => {
  const fetchFulfillersPromise = dispatch(fetchFulfillers());
  const fetchMerchantsPromise = dispatch(fetchMerchants());
  const fetchPermissionsPromise = dispatch(fetchIdentityPermissions());
  return Promise.all([fetchFulfillersPromise, fetchMerchantsPromise, fetchPermissionsPromise]);
};

// action creators to expose
export const actions = {
  loadUserIdentities
};

// - REDUCERS
const fulfillersInitialState = {
  ids: [],
  byId: {},
  fetchStatus: ENTITY_STATUS.NOT_LOADED,
  fetchPermissionStatus: ENTITY_STATUS.NOT_LOADED,
  permissionMap: {}
};

function fulfillers(state = fulfillersInitialState, action) {
  switch (action.type) {
    case FETCH_FULFILLERS_REQUEST:
      return {
        ...state,
        fetchStatus: ENTITY_STATUS.LOADING
      };

    case FETCH_FULFILLERS_FAILURE:
      return {
        ...state,
        fetchStatus: ENTITY_STATUS.UNAVAILABLE
      };

    case FETCH_FULFILLERS_SUCCESS:
      const { ids, byId } = action.payload;
      return {
        ...state,
        fetchStatus: ENTITY_STATUS.AVAILABLE,
        ids,
        byId
      };
    case FETCH_IDENTITY_PERMISSIONS_REQUEST:
      return {
        ...state,
        fetchPermissionStatus: ENTITY_STATUS.LOADING
      };

    case FETCH_IDENTITY_PERMISSIONS_FAILURE:
      return {
        ...state,
        fetchPermissionStatus: ENTITY_STATUS.UNAVAILABLE
      };
    case FETCH_IDENTITY_PERMISSIONS_SUCCESS:
      const { fulfillerPermissionMap: permissionMap } = action.payload;
      return {
        ...state,
        permissionMap,
        fetchPermissionStatus: ENTITY_STATUS.AVAILABLE
      };
    default:
      return state;
  }
}

const merchantsInitialState = {
  ids: [],
  byId: {},
  fetchStatus: ENTITY_STATUS.NOT_LOADED,
  fetchPermissionStatus: ENTITY_STATUS.NOT_LOADED,
  permissionMap: {}
};

function merchants(state = merchantsInitialState, action) {
  switch (action.type) {
    case FETCH_MERCHANTS_REQUEST:
      return {
        ...state,
        fetchStatus: ENTITY_STATUS.LOADING
      };

    case FETCH_MERCHANTS_FAILURE:
      return {
        ...state,
        fetchStatus: ENTITY_STATUS.UNAVAILABLE
      };

    case FETCH_MERCHANTS_SUCCESS:
      const { ids, byId } = action.payload;
      return {
        ...state,
        fetchStatus: ENTITY_STATUS.AVAILABLE,
        ids,
        byId
      };

    case FETCH_IDENTITY_PERMISSIONS_REQUEST:
      return {
        ...state,
        fetchPermissionStatus: ENTITY_STATUS.LOADING
      };

    case FETCH_IDENTITY_PERMISSIONS_FAILURE:
      return {
        ...state,
        fetchPermissionStatus: ENTITY_STATUS.UNAVAILABLE
      };
    case FETCH_IDENTITY_PERMISSIONS_SUCCESS:
      const { merchantPermissionMap: permissionMap } = action.payload;
      return {
        ...state,
        permissionMap,
        fetchPermissionStatus: ENTITY_STATUS.AVAILABLE
      };
    default:
      return state;
  }
}

const identitiesReducer = combineReducers({ fulfillers, merchants });
export default identitiesReducer;

// - SELECTORS

const identityNameComparer = (id1, id2, map) => {
  if (map[id1].name < map[id2].name) {
    return -1;
  }
  if (map[id1].name > map[id2].name) {
    return 1;
  }
  return 0;
};

const getFulfillerIds = state => {
  return state.contractV1.identities.fulfillers.ids;
};
const getFulfillersById = state => {
  return state.contractV1.identities.fulfillers.byId;
};
const getFulfillerPermissionMap = state => state.contractV1.identities.fulfillers.permissionMap;
const getFetchFulfillerStatus = state => state.contractV1.identities.fulfillers.fetchStatus;
const getFetchFulfillerPermissionStatus = state => state.contractV1.identities.fulfillers.fetchPermissionStatus;
const getFulfillersSortedByName = createSelector([getFulfillerIds, getFulfillersById], (ids, map) => {
  ids.sort((id1, id2) => identityNameComparer(id1, id2, map));
  return ids.map(id => map[id]);
});

const getMerchantIds = state => state.contractV1.identities.merchants.ids;
const getMerchantsById = state => state.contractV1.identities.merchants.byId;
const getMerchantPermissionMap = state => state.contractV1.identities.merchants.permissionMap;
const getFetchMerchantStatus = state => state.contractV1.identities.merchants.fetchStatus;
const getFetchMerchantPermissionStatus = state => state.contractV1.identities.merchants.fetchPermissionStatus;
const getMerchantsSortedByName = createSelector([getMerchantIds, getMerchantsById], (ids, map) => {
  ids.sort((id1, id2) => identityNameComparer(id1, id2, map));
  return ids.map(id => map[id]);
});

const getLoadUserIdentitiesStatus = createSelector(
  [
    getFetchFulfillerStatus,
    getFetchMerchantStatus,
    getFetchFulfillerPermissionStatus,
    getFetchMerchantPermissionStatus
  ],
  (fulfillerStatus, merchantStatus, fulfillerPermStatus, merchantPermStatus) => {
    const statuses = [fulfillerStatus, merchantStatus, fulfillerPermStatus, merchantPermStatus];

    if (statuses.some(status => status === ENTITY_STATUS.LOADING)) {
      return ENTITY_STATUS.LOADING;
    }

    if (statuses.some(status => status === ENTITY_STATUS.UNAVAILABLE)) {
      return ENTITY_STATUS.UNAVAILABLE;
    }

    if (statuses.every(status => status === ENTITY_STATUS.AVAILABLE)) {
      return ENTITY_STATUS.AVAILABLE;
    }
  }
);

const getUserIdentitiesLoading = createSelector(
  getLoadUserIdentitiesStatus,
  status => status === ENTITY_STATUS.LOADING
);
const getUserIdentitiesLoadFailed = createSelector(
  getLoadUserIdentitiesStatus,
  status => status === ENTITY_STATUS.UNAVAILABLE
);
const getUserIdentitiesLoaded = createSelector(
  getLoadUserIdentitiesStatus,
  status => status === ENTITY_STATUS.AVAILABLE
);

const shouldLoadUserIdentities = createSelector(
  [getUserIdentitiesLoading, getUserIdentitiesLoadFailed, getUserIdentitiesLoaded],
  (loading, failed, loaded) => {
    return !loading && !failed && !loaded;
  }
);

const getAuthorizedFulfillerIds = createSelector([getFulfillerIds, getFulfillerPermissionMap], (ids, permissionMap) => {
  // has * permission so return all available ids.
  if (permissionMap['*'] && hasAuthorizedFulfillerPermissions(permissionMap['*'])) {
    return ids;
  }

  return ids.filter(id => permissionMap[id] && hasAuthorizedFulfillerPermissions(permissionMap[id]));
});

const getAuthorizedFulfillersById = createSelector([getAuthorizedFulfillerIds, getFulfillersById], (ids, byId) => {
  return ids.reduce((map, id) => {
    map[id] = byId[id];
    return map;
  }, {});
});

const getAuthorizedFulfillersSortedByName = createSelector(
  [getAuthorizedFulfillerIds, getAuthorizedFulfillersById],
  (ids, map) => {
    ids.sort((id1, id2) => identityNameComparer(id1, id2, map));
    return ids.map(id => map[id]);
  }
);

const getAuthorizedFulfillerOptions = createSelector(getAuthorizedFulfillersSortedByName, identities =>
  identities.map(identity => ({ ...identity, type: FULFILLERS, value: identity.id, label: identity.name }))
);

const getAuthorizedMerchantIds = createSelector([getMerchantIds, getMerchantPermissionMap], (ids, permissionMap) => {
  // has * permission so return all available ids.
  if (permissionMap['*'] && hasAuthorizedMerchantPermissions(permissionMap['*'])) {
    return ids;
  }

  return ids.filter(id => permissionMap[id] && hasAuthorizedMerchantPermissions(permissionMap[id]));
});

const getAuthorizedMerchantsById = createSelector([getAuthorizedMerchantIds, getMerchantsById], (ids, byId) => {
  return ids.reduce((map, id) => {
    map[id] = byId[id];
    return map;
  }, {});
});

const getAuthorizedMerchantsSortedByName = createSelector(
  [getAuthorizedMerchantIds, getAuthorizedMerchantsById],
  (ids, map) => {
    ids.sort((id1, id2) => identityNameComparer(id1, id2, map));
    return ids.map(id => map[id]);
  }
);

const getAuthorizedMerchantOptions = createSelector(getAuthorizedMerchantsSortedByName, identities =>
  identities.map(identity => ({ ...identity, type: MERCHANTS, value: identity.id, label: identity.name }))
);

const getHasFulfillerIdentities = createSelector(
  getAuthorizedFulfillerIds,
  authorizedFulfillerIds => authorizedFulfillerIds && authorizedFulfillerIds.length > 0
);

const getHasMerchantIdentities = createSelector(
  getAuthorizedMerchantIds,
  authorizedMerchantIds => authorizedMerchantIds && authorizedMerchantIds.length > 0
);

const getAuthorizedIdentities = createSelector(
  getAuthorizedFulfillersSortedByName,
  getAuthorizedMerchantsSortedByName,
  (state, identityType) => ({ state, identityType }),
  (authorizedFulfillersSortedByName, authorizedMerchantsSortedByName, { state, identityType }) => {
    switch (identityType) {
      case FULFILLERS:
        return authorizedFulfillersSortedByName;
      case MERCHANTS:
        return authorizedMerchantsSortedByName;
      default:
        return [];
    }
  }
);

const getAvailableCounterParties = createSelector(
  getFulfillersSortedByName,
  getMerchantsSortedByName,
  (state, identityType) => ({ state, identityType }),
  (fulfillersSortedByName, merchantsSortedByName, { state, identityType }) => {
    switch (identityType) {
      case FULFILLERS:
        return merchantsSortedByName.map(({ id, name }) => ({ label: name, value: id, id, name, type: MERCHANTS }));
      case MERCHANTS:
        return fulfillersSortedByName.map(({ id, name }) => ({ label: name, value: id, id, name, type: FULFILLERS }));
      default:
        return [];
    }
  }
);

const getSelectedTypeHasOneIdentity = createSelector(
  getAuthorizedFulfillerIds,
  getAuthorizedMerchantIds,
  (state, identityType) => ({ state, identityType }),
  (authorizedFulfillerIds, authorizedMerchantIds, { state, identityType }) => {
    switch (identityType) {
      case FULFILLERS:
        return authorizedFulfillerIds.length === 1;
      case MERCHANTS:
        return authorizedMerchantIds.length === 1;
      default:
        return false;
    }
  }
);

const getHasOneIdentityType = createSelector(
  [getHasFulfillerIdentities, getHasMerchantIdentities],
  (hasFulfillers, hasMerchants) => {
    return (hasFulfillers && !hasMerchants) || (hasMerchants && !hasFulfillers);
  }
);

// selectors to expose
export const selectors = {
  shouldLoadUserIdentities,
  getUserIdentitiesLoaded,
  getUserIdentitiesLoadFailed,
  getFulfillersById,
  getMerchantsById,
  getAuthorizedFulfillerIds,
  getAuthorizedMerchantIds,
  getAuthorizedFulfillersById,
  getAuthorizedMerchantsById,
  getFulfillersSortedByName,
  getMerchantsSortedByName,
  getAuthorizedMerchantOptions,
  getAuthorizedFulfillersSortedByName,
  getAuthorizedFulfillerOptions,
  getAuthorizedMerchantsSortedByName,
  getHasFulfillerIdentities,
  getHasMerchantIdentities,
  getAuthorizedIdentities,
  getAvailableCounterParties,
  getSelectedTypeHasOneIdentity,
  getHasOneIdentityType
};
