import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Select, Button, TextField } from '@cimpress/react-components';
import DatePicker from '@cimpress/react-components/lib/DatePicker';
import { getCounterType } from '../../../reducers/identities/utils';
import { MDY_DATE_FORMAT } from '../../../constants/dateFormat';
import { toMoment, toDateString } from '../../../utilities/dateHelper';
import moment from 'moment';

const getInitialState = () => {
  return {
    selectedCounterParties: [],
    endDate: toMoment(moment()),
    startDate: toMoment(moment().subtract(2, 'months')),
    productId: undefined
  };
};

function FilterOptions(props) {
  const { t } = useTranslation();
  const [state, setState] = useState(getInitialState);

  const { subscribedParties, sellerAccountOptions, identityType } = props;
  const counterPartyType = getCounterType(identityType);

  useEffect(
    () => {
      setState(prevState => ({
        ...prevState,
        selectedCounterParties: subscribedParties
      }));
    },
    [subscribedParties]
  );

  const { selectedCounterParties, endDate, startDate, productId } = state;

  const onSelect = selectedCounterParties => {
    setState(prevState => ({ ...prevState, selectedCounterParties }));
  };

  const onChangeStartDate = startDate => {
    setState(prevState => ({ ...prevState, startDate }));
  };

  const onChangeEndDate = endDate => {
    setState(prevState => ({ ...prevState, endDate }));
  };

  const isValidStartDate = current => {
    if (current.isAfter(moment(), 'day')) {
      return false;
    } else if (endDate && current.isAfter(endDate, 'day')) {
      return false;
    }
    return true;
  };

  const isValidEndDate = current => {
    if (current.isAfter(moment(), 'day')) {
      return false;
    } else if (startDate && current.isBefore(startDate, 'day')) {
      return false;
    }
    return true;
  };

  const onChangeProductId = e => {
    const { value } = e.target;
    setState(prevState => ({ ...prevState, productId: value }));
  };

  const onClickFilter = () => {
    const sellerIds = selectedCounterParties ? selectedCounterParties.map(x => x.value) : [];
    props.onSearch(sellerIds, productId ? [productId] : [], toDateString(startDate), toDateString(endDate));
  };
  return (
    <Fragment>
      <div className="row">
        <div className="col-md-12">
          <div className="flex-filterNotification">
            <div className="notificationDate startDateFlexBox">
              <DatePicker
                inputProps={{
                  readOnly: true,
                  required: true
                }}
                onChange={onChangeStartDate}
                closeOnSelect={true}
                dateFormat={MDY_DATE_FORMAT}
                value={startDate}
                timeFormat={false}
                placeholder={t('notifications:startDate')}
                isValidDate={isValidStartDate}
              />
            </div>
            <div className="notificationDate endDateFlexBox">
              <DatePicker
                inputProps={{
                  readOnly: true,
                  required: true
                }}
                onChange={onChangeEndDate}
                closeOnSelect={true}
                dateFormat={MDY_DATE_FORMAT}
                value={endDate}
                timeFormat={false}
                placeholder={t('notifications:endDate')}
                isValidDate={isValidEndDate}
              />
            </div>
            <div className="productIdsFlexBox">
              <TextField
                id="SearchBar"
                onChange={onChangeProductId}
                value={productId}
                type="search"
                placeholder={t('notifications:searchByProductId')}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <Select
            label={t('settings:subscriptions.selectLabel', { context: counterPartyType })}
            value={selectedCounterParties}
            options={sellerAccountOptions}
            onChange={onSelect}
            isMulti
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12" align="right">
          <Button type="primary" style={{ minWidth: '80px' }} onClick={onClickFilter}>
            {t('productAgreementsPage:search')}
          </Button>
        </div>
      </div>
    </Fragment>
  );
}

export default FilterOptions;
