/**
 * Checks if a subset of two sets of props have reference equality or not.
 * @param {Object} prevProps The previous set of props.
 * @param {Object} nextProps The next set of props.
 * @param {Array<String>} props The list of props to check for reference equality.
 * @returns {Boolean} True if the props are equal, false otherwise.
 */
export function propsAreSame(prevProps, nextProps, props) {
  return props.reduce((prev, curr) => {
    return prevProps[curr] === nextProps[curr] && prev;
  }, true);
}
