import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Breadcrumbs, BreadcrumbItem } from '@cimpress/react-components';

import { selectors as identitySelectors } from '../../../reducers/identities/index';
import { actions as accountActions } from '../../../reducers/account';

import ErrorRobot from '../../common/ErrorRobot';
import Loading from '../../Loading';
import BusinessTab from './BusinessTab';
import { Fragment } from 'react';

function ProductAgreements(props) {
  const { t } = useTranslation();
  const [isRobotVisible, setIsRobotVisible] = useState(false);

  // Business one will be always manage account.
  const [businessOne, setBusinessOne] = useState({});
  const [businessTwo, setBusinessTwo] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const isSelf = useRef(false);

  const { userPermissions } = props;
  const relationshipDataKey = props.match.params.businessIds;
  const routeTabIndex = props.match.params.tabIndex;

  // Find the manage account.
  useEffect(
    () => {
      async function setBusinessData(businessIds) {
        const [businessOneAccount, businessTwoAccount] = await Promise.all([
          props.fetchAccount(businessIds[0]),
          props.fetchAccount(businessIds[1])
        ]);
        if (businessOneAccount.payload && businessTwoAccount.payload) {
          setBusinessOne(businessOneAccount.payload);
          setBusinessTwo(businessTwoAccount.payload);
          isSelf.current = businessIds[0] === businessIds[1];
          setIsLoading(false);
        } else {
          setIsLoading(false);
          setIsRobotVisible(true);
        }
      }
      if (relationshipDataKey && userPermissions) {
        const businessIds = relationshipDataKey.split('|');
        if (businessIds.length > 1) {
          setBusinessData(businessIds);
        }
      }
    },
    [relationshipDataKey, userPermissions]
  );
  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : isRobotVisible ? (
        <ErrorRobot />
      ) : (
        <Fragment>
          <div className="breadCrumbPadding">
            <Breadcrumbs>
              <BreadcrumbItem>
                <Link key={'/'} to={'/'}>
                  {t('breadcrumbs:home')}
                </Link>
              </BreadcrumbItem>
              <BreadcrumbItem active>
                {t('productAgreementsPage:mainHeading', {
                  businessOne: businessOne.name,
                  businessTwo: businessTwo.name
                })}
              </BreadcrumbItem>
            </Breadcrumbs>
          </div>
          <BusinessTab
            businessOne={businessOne}
            businessTwo={businessTwo}
            isSelf={isSelf.current}
            relationshipDataKey={relationshipDataKey}
            routeTabIndex={routeTabIndex}
          />
        </Fragment>
      )}
    </div>
  );
}

const mapStateToProps = state => ({
  userPermissions: identitySelectors.getPermissionsPayload(state)
});

const mapDispatchToProps = {
  fetchAccount: accountActions.fetchAccountAction
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductAgreements);
