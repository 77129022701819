import React from 'react';
import { css } from 'react-emotion';
import PropTypes from 'prop-types';
import Button from '../../common/Button';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { actions as termActions } from '../../../reducers/contractV1/terms';
import TermStatus from '../../../constants/termStatus';
import { cx } from 'emotion';
import { useAsyncCallback } from 'react-async-hook';

const buttonPadding = css`
  padding: 4px 0px !important;
`;

const buttonTextStyling = css`
  width: 115px;
  white-space: normal;
`;

function ActionsCell(props) {
  const { t, term, updateTermStatus } = props;
  const hasAcceptorPermission = term.userHasAuthorizationFor.acceptor;
  const approveTerm = () => {
    return updateTermStatus(term.termId, term.revisionId, TermStatus.APPROVED, true);
  };

  const { execute, loading } = useAsyncCallback(approveTerm);

  if (term.status === TermStatus.PENDING && hasAcceptorPermission) {
    return (
      <Button
        className={cx('btn btn-primary', buttonPadding)}
        onClick={async () => {
          props.updateStatus(true);
          await execute();
          props.updateStatus(false);
        }}
        disabled={loading}>
        {loading ? (
          <div className={buttonTextStyling}>{t('contractActions:approving')}...</div>
        ) : (
          <div className={buttonTextStyling}>{t('contractActions:approve')}</div>
        )}
      </Button>
    );
  }

  return (
    <a href={`${process.env.REACT_APP_CONTRACT_MANAGER_V1_URL}/${term.termId}/${term.revisionId}/${props.businessIds}`}>
      <Button className={cx('btn btn-default', buttonPadding)}>
        {<div className={buttonTextStyling}>{t('contractActions:viewPricing')}</div>}
      </Button>
    </a>
  );
}

ActionsCell.propTypes = {
  term: PropTypes.object.isRequired
};

const mapDispatchToProps = {
  updateTermStatus: termActions.updateTermStatus
};

export default connect(
  null,
  mapDispatchToProps
)(withTranslation()(ActionsCell));
