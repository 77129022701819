import subToEmailMapping from '../constants/subToEmailMapping';

// credit: http://emailregex.com
// eslint-disable-next-line no-useless-escape
const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const userSubToEmail = createdBy => {
  // check the static mapping for legacy createdBys; if it doesn't exist then use the createdBy prop.
  const email = subToEmailMapping[createdBy] || createdBy;

  // email test must pass, otherwise it's probably not human readable and we probably don't want to show it.
  if (EMAIL_REGEX.test(email)) {
    return email;
  }

  return null;
};

export default userSubToEmail;
