//@flow
import { auth } from '../utils/auth';
import urlJoin from 'url-join';

/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON, status from the response
 */
function parseJSON(response) {
  if (response && response.status === 204) {
    return new Promise(resolve => {
      resolve({
        status: response.status,
        ok: response.ok
      });
    });
  }

  return new Promise((resolve, reject) => {
    return response
      .json()
      .then(json => {
        resolve({
          status: response.status,
          ok: response.ok,
          json
        });
      })
      .catch(e => reject(response));
  });
}

/**
 * Requests a URL, returning a promise. BaseURL is required.
 * If URL is not passed in, the baseURL will be used. This was done because the URL may contain weird things (e.g. regex) that can behave unexpectedly by url-join library.
 *
 * @param  {string} baseUrl   The base URL (e.g. https://myservice.com)
 * @param  {string} url       The URL we want to request (e.g. /api/v0/myRoute)
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @param serviceComment
 * @return {Promise}           The request promise
 */
export function makeRequest(baseUrl, url, options, serviceComment) {
  if (!baseUrl) {
    return Promise.reject({
      serviceComment: 'Base URL was not set.',
      statusCode: 0
    });
  }

  let fullUrl;
  if (!url) {
    fullUrl = baseUrl;
  } else {
    fullUrl = urlJoin(baseUrl, url);
  }
  return new Promise((resolve, reject) => {
    fetch(fullUrl, options)
      .then(parseJSON)
      .then(response => {
        if (response.ok) {
          return resolve(response.json);
        }
        // extract the error from the server's json
        return reject({
          serviceComment,
          statusCode: response.status || 0,
          response: response.json || response
        });
      })
      .catch(error => {
        return reject({
          serviceComment,
          statusCode: error.status || 0,
          response: error.json || error
        });
      });
  });
}

export function buildOptions({ method, body, hal } = {}) {
  const headers = new Headers({
    Accept: hal ? ['application/hal+json', 'application/json'] : 'application/json',
    Authorization: `Bearer ${auth.getAccessToken()}`
  });

  if (!method) {
    method = 'GET';
  } else if (method === 'POST' || method === 'PUT' || method === 'PATCH') {
    headers.append('Content-Type', 'application/json');
  }

  return {
    method,
    mode: 'cors',
    headers,
    body
  };
}
