export const permissions = {
  READ_CONTRACT: 'read:contract',
  MANAGE_CONTRACT: 'manage:contract',
  READ_PRODUCT_AGREEMENT: 'read:product_agreement',
  MANAGE_PRODUCT_AGREEMENT: 'manage:product_agreement'
};

export const resource = {
  STAR: '*'
};
