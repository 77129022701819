import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavTab, NavTabItem } from '@cimpress/react-components';
import Button from '../../common/Button';
import { ALL, PRICECHANGE_NOTIFICATION_TYPE, SUNSET_NOTIFICATION_TYPE } from '../../../constants/notification';
import isEqual from 'lodash/isEqual';
import groupBy from 'lodash/groupBy';
import countBy from 'lodash/countBy';

const getDataGroupByType = notifications => {
  return groupBy(notifications, 'notificationType');
};

const getCount = notifications => {
  if (!notifications || notifications.length === 0) {
    return 0;
  }
  const unAckCount = countBy(notifications, 'isAck')['false'];
  return unAckCount ? unAckCount : 0;
};

const getAllCount = (sunsetCount, priceChangeCount) => {
  if (sunsetCount || priceChangeCount) {
    return (sunsetCount ? sunsetCount : 0) + (priceChangeCount ? priceChangeCount : 0);
  }

  return undefined;
};

function NotificationTypeNav(props) {
  const { t } = useTranslation();
  const { notificationFilter, onChange, notifications } = props;

  const groupByNotifications = getDataGroupByType(notifications);
  const sunsetCount = getCount(groupByNotifications[SUNSET_NOTIFICATION_TYPE]);
  const priceChangeCount = getCount(groupByNotifications[PRICECHANGE_NOTIFICATION_TYPE]);
  const allCount = getAllCount(sunsetCount, priceChangeCount);

  const onSetAllFilter = () => onChange(ALL);
  const onSetPriceChangeFilter = () => onChange(PRICECHANGE_NOTIFICATION_TYPE);
  const onSetProductArchivingFilter = () => onChange(SUNSET_NOTIFICATION_TYPE);

  return (
    <div>
      <NavTab className="overrideNotificationNavTab">
        <NavTabItem active={isEqual(notificationFilter, ALL)}>
          <Button onClick={onSetAllFilter}>
            <span>
              {t('notifications:all')}
              <sup>
                &nbsp;<b>{allCount}</b>
              </sup>
            </span>
          </Button>
        </NavTabItem>
        <NavTabItem active={isEqual(notificationFilter, SUNSET_NOTIFICATION_TYPE)}>
          <Button onClick={onSetProductArchivingFilter}>
            <span>
              {t('notifications:productArchiving')}
              <sup>
                &nbsp;<b>{sunsetCount}</b>
              </sup>
            </span>
          </Button>
        </NavTabItem>
        <NavTabItem active={isEqual(notificationFilter, PRICECHANGE_NOTIFICATION_TYPE)}>
          <Button onClick={onSetPriceChangeFilter}>
            <span>
              {t('notifications:priceChange')}
              <sup>
                &nbsp;<b>{priceChangeCount}</b>
              </sup>
            </span>
          </Button>
        </NavTabItem>
      </NavTab>
    </div>
  );
}

export default NotificationTypeNav;
