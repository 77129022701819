import { combineReducers } from 'redux';
import network, { fetchNetworkAction, getNetwork, getNetworkStatus } from './fetchNetwork';

// actions
export const actions = {
  fetchNetworkAction
};

// selectors
export const selectors = {
  getNetwork,
  getNetworkStatus
};

export default combineReducers({
  network
});
