import { combineReducers } from 'redux';

import { changeLanguage } from '../../i18n/languageselection';
import * as customizrApi from '../../services/Customizr';
import { createApiErrorNotificationAction } from '../utils';

// ACTION TYPES
// const FETCH_CONTRACT_CONFIG_REQUEST = 'FETCH_CONTRACT_CONFIG_REQUEST';
// const FETCH_CONTRACT_CONFIG_FAILURE = 'FETCH_CONTRACT_CONFIG_FAILURE';
// const FETCH_CONTRACT_CONFIG_SUCCESS = 'FETCH_CONTRACT_CONFIG_SUCCESS';

const FETCH_USER_CONFIG_REQUEST = 'FETCH_USER_CONFIG_REQUEST';
const FETCH_USER_CONFIG_FAILURE = 'FETCH_USER_CONFIG_FAILURE';
const FETCH_USER_CONFIG_SUCCESS = 'FETCH_USER_CONFIG_SUCCESS';

const UPDATE_USER_CONFIG_REQUEST = 'UPDATE_USER_CONFIG_REQUEST';
const UPDATE_USER_CONFIG_FAILURE = 'UPDATE_USER_CONFIG_FAILURE';
const UPDATE_USER_CONFIG_SUCCESS = 'UPDATE_USER_CONFIG_SUCCESS';

// const UPDATE_CONTRACT_CONFIG_REQUEST = 'UPDATE_CONTRACT_CONFIG_REQUEST';
// const UPDATE_CONTRACT_CONFIG_FAILURE = 'UPDATE_CONTRACT_CONFIG_FAILURE';
// const UPDATE_CONTRACT_CONFIG_SUCCESS = 'UPDATE_CONTRACT_CONFIG_SUCCESS';

const fetchUserConfigRequest = () => ({ type: FETCH_USER_CONFIG_REQUEST });
const fetchUserConfigFailure = payload => ({ type: FETCH_USER_CONFIG_FAILURE, payload });
const fetchUserConfigSuccess = payload => ({ type: FETCH_USER_CONFIG_SUCCESS, payload });

const updateUserConfigRequest = () => ({ type: UPDATE_USER_CONFIG_REQUEST });
const updateUserConfigFailure = payload => ({ type: UPDATE_USER_CONFIG_FAILURE, payload });
const updateUserConfigSuccess = payload => ({ type: UPDATE_USER_CONFIG_SUCCESS, payload });

const fetchUserConfig = () => dispatch => {
  dispatch(fetchUserConfigRequest());

  customizrApi
    .fetchUserConfig()
    .then(userSettings => {
      changeLanguage(userSettings);
      return dispatch(fetchUserConfigSuccess(userSettings));
    })
    .catch(err => {
      if (err.statusCode === 404) {
        // No settings were found, just return an empty settings object
        dispatch(fetchUserConfigSuccess({}));
        return;
      }

      dispatch(fetchUserConfigFailure(err));
      dispatch(createApiErrorNotificationAction('Unable to retrieve user settings.'));
    });
};

const updateUserConfig = userConfigSetings => dispatch => {
  dispatch(updateUserConfigRequest());

  customizrApi
    .updateUserConfig(userConfigSetings)
    .then(userSettings => {
      return dispatch(updateUserConfigSuccess(userSettings));
    })
    .catch(err => {
      dispatch(updateUserConfigFailure(err));
      dispatch(createApiErrorNotificationAction('Unable to update user settings.'));
    });
};

export const actions = {
  fetchUserConfig,
  updateUserConfig
};

// REDUCERS
// function byFulfillerId(state = {}, action) {
//   switch (action.type) {
//     case FETCH_CONTRACT_CONFIG_SUCCESS:
//       if (typeIsFulfiller(action.payload.identityType)) {
//         const { identityId, autoApprovalSettings } = action.payload;
//         const filteredSettings = autoApprovalSettings.reduce((arr, { otherEntity, autoApprove }) => {
//           if (autoApprove) {
//             arr.push({ id: otherEntity.id, autoApprove });
//           }
//           return arr;
//         }, []);
//         return { ...state, [identityId]: filteredSettings };
//       }
//       return state;
//     case UPDATE_CONTRACT_CONFIG_SUCCESS:
//       if (typeIsFulfiller(action.payload.identityType)) {
//         const { identityId, autoApprovalSettings } = action.payload;
//         const settings = autoApprovalSettings.map(({ otherEntity, autoApprove }) => ({
//           id: otherEntity.id,
//           autoApprove
//         }));
//         return { ...state, [identityId]: settings };
//       }
//       return state;
//     default:
//       return state;
//   }
// }

// function byMerchantId(state = {}, action) {
//   switch (action.type) {
//     case FETCH_CONTRACT_CONFIG_SUCCESS:
//       if (typeIsMerchant(action.payload.identityType)) {
//         const { identityId, autoApprovalSettings } = action.payload;
//         const filteredSettings = autoApprovalSettings.reduce((arr, { otherEntity, autoApprove }) => {
//           if (autoApprove) {
//             arr.push({ id: otherEntity.id, autoApprove });
//           }
//           return arr;
//         }, []);
//         return { ...state, [identityId]: filteredSettings };
//       }
//       return state;
//     case UPDATE_CONTRACT_CONFIG_SUCCESS:
//       if (typeIsMerchant(action.payload.identityType)) {
//         const { identityId, autoApprovalSettings } = action.payload;
//         const settings = autoApprovalSettings.map(({ otherEntity, autoApprove }) => ({
//           id: otherEntity.id,
//           autoApprove
//         }));
//         return { ...state, [identityId]: settings };
//       }
//       return state;
//     default:
//       return state;
//   }
// }

function userConfigs(state = {}, action) {
  switch (action.type) {
    case FETCH_USER_CONFIG_SUCCESS:
    case UPDATE_USER_CONFIG_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}

function isLoading(state = {}, action) {
  switch (action.type) {
    case FETCH_USER_CONFIG_REQUEST:
      return { ...state, FETCH_USER_CONFIG: true };
    case FETCH_USER_CONFIG_FAILURE:
    case FETCH_USER_CONFIG_SUCCESS:
      return { ...state, FETCH_USER_CONFIG: false };
    case UPDATE_USER_CONFIG_REQUEST:
      return { ...state, UPDATE_USER_CONFIG: true };
    case UPDATE_USER_CONFIG_FAILURE:
    case UPDATE_USER_CONFIG_SUCCESS:
      return { ...state, UPDATE_USER_CONFIG: false };
    default:
      return state;
  }
}

function isLoaded(state = {}, action) {
  switch (action.type) {
    case FETCH_USER_CONFIG_REQUEST:
    case FETCH_USER_CONFIG_FAILURE:
      return { ...state, FETCH_USER_CONFIG: false };
    case FETCH_USER_CONFIG_SUCCESS:
      return { ...state, FETCH_USER_CONFIG: true };
    case UPDATE_USER_CONFIG_REQUEST:
    case UPDATE_USER_CONFIG_FAILURE:
      return { ...state, UPDATE_USER_CONFIG: false };
    case UPDATE_USER_CONFIG_SUCCESS:
      return { ...state, UPDATE_USER_CONFIG: true };
    default:
      return state;
  }
}

function hasError(state = {}, action) {
  switch (action.type) {
    case FETCH_USER_CONFIG_REQUEST:
    case FETCH_USER_CONFIG_SUCCESS:
      return { ...state, FETCH_USER_CONFIG: false };
    case FETCH_USER_CONFIG_FAILURE:
      return { ...state, FETCH_USER_CONFIG: true };
    case UPDATE_USER_CONFIG_REQUEST:
    case UPDATE_USER_CONFIG_SUCCESS:
      return { ...state, UPDATE_USER_CONFIG: false };
    case UPDATE_USER_CONFIG_FAILURE:
      return { ...state, UPDATE_USER_CONFIG: true };
    default:
      return state;
  }
}

const configurationsReducer = combineReducers({
  userConfigs,
  isLoading,
  isLoaded,
  hasError
});

export default configurationsReducer;

const getUserConfigSettings = state => state.configurations.userConfigs;
const getIsFetchingUserConfigurations = state => state.configurations.isLoading.FETCH_USER_CONFIG;

export const selectors = {
  // getConfigByFulfillerId,
  // getConfigByMerchantId,
  // getisFetchingConfigurations,
  // getIsUpdatingConfigurations,
  // getCurrentConfigurations,
  // getSelectionOptions,
  getUserConfigSettings,
  // getIsShippingCostsEnabled,
  getIsFetchingUserConfigurations
  // getShowTerminated
};
