import Header from '@cimpress-technology/react-platform-header';
import React from 'react';
import autoBind from 'react-autobind';

import { auth, login, logout } from '../../utils/auth';
import { Link } from 'react-router-dom';
import LayoutSettings from './LayoutSettings';

class AppHeader extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  render() {
    // Only render settings if user has logged in
    const appsLink = auth.isLoggedIn() && <LayoutSettings />;
    let settingsLink = {
      id: 'contractV2AppLinks',
      content: appsLink
    };
    return (
      <Header
        responsive
        appTitle={
          <Link to={'/'}>
            <h2>Contract Manager</h2>
          </Link>
        }
        appLinks={[settingsLink]}
        profile={auth.getProfile()}
        onLogInClicked={login}
        onLogOutClicked={logout}
        isLoggedIn={auth.isLoggedIn()}
        accessToken={auth.getAccessToken()}
        documentationUrl="https://cimpress-support.atlassian.net/wiki/spaces/CI/pages/536379580"
      />
    );
  }
}

export default AppHeader;
