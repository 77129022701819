import React, { Component } from 'react';
import NotificationsSystem from 'reapop';
import cimpressTheme from './theme';

// TODO: Extract this out into its own component.
export default class MyNotificationsSystem extends Component {
  render() {
    return <NotificationsSystem theme={cimpressTheme} />;
  }
}
