export default {
  common: {
    contractV1Src: {
      DoYouWantToCreateShippingContractV1: 'Wilt u een verzendcontract maken?',
      EnabledShippingContractV1ErrorMessage:
        "Fout bij het ophalen van account-ID's om het verzendcontract in te schakelen.",
      NoAgreements:
        'De weergegeven contracten zijn gemaakt binnen hetzelfde bedrijf. Er zijn hier dus geen afspraken getoond.',
      PleaseContact: 'Neem contact op met {{emailid}} als uw bedrijf overeenkomsten vereist.'
    },
    contractTypes: {
      listPrice: 'Catalogusprijs',
      specialPrice: 'Particuliere prijs',
      freeShipping: 'Gratis verzenden',
      shippingCost: 'Verzendkosten',
      shippingPrice: 'Prijs voor verzending',
      listPriceWithDiscount: '$t(common:contractTypes.listPrice) met $t(common:commonWords.discount)',
      listPriceWithMarkup: '$t(common:contractTypes.listPrice) met $t(common:commonWords.markup)',
      specialPriceWithMarkup: '$t(common:contractTypes.specialPrice) met $t(common:commonWords.markup)',
      shippingCostWithDiscount: '$t(common:contractTypes.shippingCost) met $t(common:commonWords.discount)',
      shippingCostWithMarkup: '$t(common:contractTypes.shippingCost) met $t(common:commonWords.markup)',
      shippingPriceWithDiscount: '$t(common:contractTypes.shippingPrice) met $t(common:commonWords.discount)',
      shippingPriceWithMarkup: '$t(common:contractTypes.shippingPrice) met $t(common:commonWords.markup)',
      DoYouWantToCreateShippingContractV1: 'Wilt u een verzendcontract maken?'
    },
    contractStatus: {
      approved: 'Goedgekeurd',
      pending: 'In afwachting van',
      terminated: 'Beëindigd',
      rejected: 'Afgewezen',
      draft: 'Concept',
      effective: 'Effectief',
      visibleInCatalog: 'Zichtbaar in catalogus',
      usingDeletedModel: 'Gebruikmakend van een verwijderd prijsmodel',
      expiredAgo: '(Expired {{ duration, localizeDuration }})',
      isExpiring: '(Expiring {{ duration, localizeDuration }})',
      pending_yourResponse: 'Beoordeling nodig',
      pending_otherParty: 'In afwachting van goedkeuring',
      pending_priorTerm: 'Voorheen $t(common:contractStatus.pending)',
      approved_priorTerm: 'Voorheen $t(common:contractStatus.approved)',
      rejected_priorTerm: 'Voorheen $t(common:contractStatus.rejected)',
      terminated_priorTerm: 'Voorheen $t(common:contractStatus.terminated)',
      gracePeriodAboutToExpire: 'Wordt automatisch goedgekeurd <1>{{ duration, localizeDuration }}</1>',
      needsApproval: 'Goedkeuring benodigd'
    },
    contract: {
      lastUpdatedXAgo: '{{ duration, localizeDuration }}',
      privateButDiscoverableTip:
        'De artikelprijzen in deze particuliere prijsovereenkomst zijn openbaar en vindbaar in de catalogus. Een catalogusprijsovereenkomst aanmaken in plaats van deze particuliere prijsovereenkomst.',
      deletedModelsTip:
        'Dit contract gebruikt verwijderde prijsmodellen, wat invloed kan hebben op prijswijzigingen. Neem contact op met {{ email }} voor hulp'
    },
    commonTerminology: {
      shippingPriceModel: 'Prijsmodel voor verzending',
      priceModel: 'Prijsmodel',
      contractName: 'Naam overeenkomst',
      effectiveStartDate: 'Effectieve begindatum',
      effectiveEndDate: 'Effectieve einddatum',
      discount: '{{ percentage }}% $t(common:commonWords.discount)',
      markup: '{{ percentage }}% $t(common:commonWords.markup)',
      name: 'Naam',
      indefinite: 'Onbepaald',
      revision: 'Revisie',
      mcpSystem: 'het MCP-systeem',
      gracePeriod: 'Respijtperiode',
      effectiveDates: 'effectieve datums (in UTC)',
      lastUpdated: 'Laatst bijgewerkt',
      seller: 'Verkoper',
      seller_plural: 'Verkopers',
      seller_oneOrMore: 'Verkoper(s)',
      buyer: 'Koper',
      buyer_plural: 'Kopers',
      buyer_oneOrMore: 'Koper(s)',
      search: 'Zoeken',
      status: 'Status',
      status_plural: 'Statussen',
      status_oneOrMore: 'Status(sen)',
      type: 'Type',
      contractType: 'Soort overeenkomst',
      contractType_plural: 'Soorten overeenkomsten',
      contractType_oneOrMore: 'Soort(en) overeenkomsten',
      hasGracePeriod: 'Heeft respijtperiode',
      ProductId: 'Product-ID',
      currentVersion: 'Huidige versie'
    },
    commonWords: {
      discount: 'Korting',
      markup: 'Prijsstijging',
      yesText: 'Ja',
      noText: 'Nee',
      onText: 'Aan',
      offText: 'Uit',
      blank: '',
      next: 'Volgende',
      back: 'Terug',
      prev: 'Vorige',
      orText: 'of',
      save: 'Opslaan',
      cancel: 'Annuleren',
      close: 'Afsluiten',
      countries: 'Landen',
      authorize: 'Machtigen',
      edit: 'Bewerken',
      delete: 'Verwijderen'
    },
    unsavedChanges: {
      message: 'Er is een lopende actie. Weet u zeker dat u weg wilt navigeren?'
    },
    actions: {
      createNewContract: 'Nieuwe overeenkomst aanmaken',
      createButtonText: 'Creëer'
    }
  },
  authorizationFlow: {
    errorTitle: 'Autorisatiefout',
    title: 'Voordat we beginnen...',
    descriptionMessage1: 'Welkom bij Contractmanager!',
    descriptionMessage2: 'Als nieuwe gebruiker moet u om een abonnement te nemen en meldingen te ontvangen, eerst ',
    descriptionMessage3: 'geautoriseerd zijn',
    descriptionMessage4: 'bij de service meldingshubs.',
    descriptionMessage5: 'U wordt omgeleid naar Marketplace Central als u klikt',
    descriptionMessage6: 'op de onderstaande knop.',
    authErrorMessage1: 'Er is iets misgegaan met de autorisatie voor meldingen.',
    authErrorMessage2: 'Neem voor hulp contact op via {{ FFS_EMAIL_ADDRESS }}.'
  },
  investigateSku: {
    investigate: 'Zoek overeenkomsten met SKU',
    getDetails: 'Details krijgen',
    noContractsFound: "Geen overeenkomsten gevonden met SKU '{{sku}}'",
    emptySearch: 'Zoekopdracht mag niet leeg zijn',
    contractsBySku: {
      header: 'Overeenkomstgegevens voor {{ sku }}',
      header2: 'Bedrijven met overeenkomsten voor {{sku}}',
      selectAContract: 'Een overeenkomst selecteren om gerelateerde details en prijzen van deze SKU te bekijken',
      viewContract: 'Overeenkomst bekijken'
    }
  },
  appShell: {
    loudspeakerModal: {
      title: 'Wat is er nieuw in de Contractmanager?'
    }
  },
  breadcrumbs: {
    home: 'Contracten',
    contracts: 'Contracten',
    settings: 'Instellingen',
    notFound: 'Niet gevonden',
    bulkUpdate: 'Bulk bijwerken',
    viewSku: '{{ sku }}',
    contractChanges: 'Wijzigingen van overeenkomst',
    createContracts: 'Overeenkomsten aanmaken',
    viewContractRevision: 'Revisie van overeenkomst bekijken',
    createProductAgreements: 'Maak productovereenkomsten',
    productAgreementDetails: 'Productovereenkomsten Details',
    notificationDetails: 'Meldingsdetails'
  },
  landingPage: {
    nameGreeting: 'Hallo {{ name }}, ',
    welcome: 'Welkom bij Contractmanager',
    setupContracts:
      'Contracten voor bedrijven opstellen en beheren om u bij het kopen en verkopen van producten te helpen.',
    todaysReport: 'Rapport van vandaag',
    statCardTotalLabel: 'Totaal',
    createContractsCard: {
      header: 'Contracten aanmaken en beheren',
      cardBody:
        "Contracten voor kopers en verkopers opstellen en beheren. SKU's toevoegen aan contracten, voorwaarden, kortingen en andere regels bepalen.",
      numberOfBuyerAndSellers:
        '<0>{{ numberOfBuyers }}</0> overeenkomsten van koper, <2>{{ numberOfSellers }}</2> overeenkomsten van verkopers',
      browseAll: 'Alles doorbladeren'
    },
    investigateSkusCard: {
      header: "SKU's met contracten onderzoeken",
      cardBody:
        "Zie contractdetails voor specifieke SKU's, zoals welke contracten de SKU's hebben, wat hun voorwaarden zijn en de huidige status."
    },
    settingsCard: {
      header: 'Abonnementen en goedkeuringen',
      manageSettings: 'Verschillende instellingen met betrekking tot contracten beheren',
      manageSubscriptions: 'Abonnementen op contractmelding beheren',
      manageAutoApproval: 'Automatische goedkeuring voor kopers en verkopers bepalen',
      manageShippingContracts: 'Overeenkomsten voor verzending in- of uitschakelen',
      manageSettingsButton: 'Instellingen beheren',
      manageSubscriptionsAndApprovalsButton: 'Configureer abonnementen'
    },
    notificationsCard: {
      manageNotifications: 'Guarda le notifiche',
      manageSettings:
        'Hai {} notifiche non riconosciute {} relative a variazioni di prezzo o archiviazione dei prodotti.'
    },
    recentlyCreatedContractsCard: {
      header: 'Recent aangemaakte overeenkomsten'
    },
    manageContractsFor: 'Beheer contracten voor:',
    contracts: 'Contracten'
  },
  homePage: {
    header: {
      title: 'Mijn Overeenkomsten'
    },
    searchBarPlaceholder: 'Zoeken via overeenkomst of via sku van overeenkomst',
    selectMultipleContracts: {
      label: 'Meerdere overeenkomsten selecteren:',
      contractSelection: {
        contractTypeLabel: 'Soort overeenkomst',
        selectSeller: 'Verkoper selecteren',
        addSKUsTo: "SKU's aan geselecteerde overeenkomst toevoegen",
        addSKUsTo_plural: "SKU's aan {{count}} geselecteerde overeenkomsten toevoegen"
      }
    }
  },
  relationshipTable: {
    all: 'All',
    marketplace: 'B2B',
    fulfilmentNetwork: 'Fulfilment Network',
    searchTextFieldPlaceholder: 'Zoeken op account-ID of naam'
  },
  contractsTable: {
    effectiveContractPopover:
      'Een effectieve prijsterm is de term die wordt gebruikt om te berekenen wanneer een bestelling op de MCP wordt geplaatst.',
    effectiveContractDetails:
      'Deze overeenkomst is effectief voor <1>{{ sku }}</1> tussen koper <3>{{ buyer }}</3> en verkoper <5>{{ seller }}</5>',
    effectiveContractAdditionalDetails:
      'Om een overeenkomst effectief te laten zijn, dient deze te worden goedgekeurd en de <1>hoogste prioriteit</1> te krijgen <2/>.',
    onlyOneEffectiveContractDetails:
      'Er kan slechts één effectief contract voor een bepaalde SKU, koper en verkoper zijn op een bepaald moment.\n'
  },
  tableCards: {
    allContracts: 'Alle overeenkomsten',
    needsReview: 'Beoordeling nodig',
    pendingApproval: 'In afwachting van goedkeuring'
  },
  contractsFilter: {
    filterOffLabel: 'Extra filters',
    filterOnLabel: 'Filteren op',
    clearFilterLabel: 'filter verwijderen'
  },
  contractInfo: {
    author: 'Auteur',
    possibleCurrencies: 'Mogelijke valuta',
    infoSectionTitle: 'Gegevens overeenkomst',
    pricingSectionTitle: 'Prijsstelling',
    partiesSectionTitle: 'Betrokken partijen',
    detailsSectionTitle: 'Details',
    excludedCountriesTitle: 'Uitgesloten landen',
    destinationCountriesTitle: 'Landen van bestemming',
    latestRevisionTitle: 'Laatste revisie',
    noOfItemsTitle: 'Aantal items',
    remarksTitle: 'Opmerkingen',
    allCountries: 'Alle landen',
    noGracePeriod: 'Geen respijtperiode',
    viewChanges: 'Wijzigingen bekijken',
    viewAllCountries: 'Alle {{ count }} landen bekijken',
    updatedBy: 'Bijgewerkt op',
    priceModelsOnContract: 'Prijsmodel bij deze overeenkomst',
    priceModelsOnContract_plural: 'Prijsmodellen bij deze overeenkomst',
    defaultNameForPriceModel: 'Onbepaald',
    statusChangedOn: 'Status gewijzigd op <1>{{ dateTime, localDateTime }}</1>',
    notTheLatestRevisionText:
      'Deze revisie is alleen-lezen aangezien dit niet de laatste revisie is. <1>Klik hier om de laatste revisie te bekijken</1>',
    readOnlyRevisionText:
      'Deze revisie is alleen-lezen aangezien u niet de laatste revisie is aan het bekijken bent. <1>Klik hier om de laatste revisie te bekijken</1>',
    gracePeriod: '{{ duration, localizeDurationSimple }}',
    gracePeriodDescription:
      'De respijtperiode bepaalt het aantal dagen dat de acceptant heeft om wijzigingen die in een contract zijn aangebracht te beoordelen voordat zij de wijzigingen goedkeuren of afwijzen. Wanneer de respijtperiode verloopt, wordt de overeenkomst automatisch goedgekeurd. Wanneer de respijtperiode niet bestaat, wordt de overeenkomst niet automatisch goedgekeurd.'
  },
  contractActions: {
    viewPricing: 'Prijsstelling bekijken',
    viewPricingToolTip: "Klik hier voor meer informatie over deze term zoals sku's en prijsstelling",
    publish: 'Naar {{ otherParty }} publiceren voor goedkeuring',
    approve: 'Goedkeuren',
    approving: 'Goedkeuren',
    terminate: 'Beëindigen',
    terminateFinal: 'Ik begrijp het. Beëindigen',
    terminated: 'Beëindigd!',
    goToTerminatedRevision: 'Ga naar beëindigde revisie',
    cancel: 'Annuleren',
    sendForApproval: 'Ter controle en goedkeuring verzenden',
    saveAndUpdate: 'Opslaan en bijwerken',
    validateUniqueMessage: 'De waarde moet verschillen van de voorgaande waarde.',
    requiredMessage: 'De waarde is vereist',
    editGracePeriodModal: {
      title: 'Dagen van respijtperiode bijwerken',
      validateRangeMessage: 'De waarden moet tussen 1 tot 999 liggen.',
      checkboxLabel: 'Geen respijtperiode'
    },
    editModifierModal: {
      title: '{{modifierType}} % bedrag bewerken'
    },
    editNameModal: {
      title: 'Naam bewerken'
    },
    editSkusModal: {
      successful: 'Geslaagd',
      bulkAddSkusMessage: "{{numberOfSkusAdded}} sku('s) is (zijn) toegevoegd.",
      bulkAddDuplicateMessage: '{{duplicateSkus}} zijn overgeslagen omdat deze al zijn geslecteerd.',
      toolTipNoChangesMessage: 'Er zijn geen wijzigingen.',
      toolTipOneOrMoreChangesMessage: 'U heeft 1 of meer items nodig.',
      toolTipNotCompatibleMessage: "Het prijsmodel komt niet overeen met de geselecteerde sku's.",
      newRevisionActionMessage:
        'Er wordt een nieuwe herziening van het contract aangemaakt en de andere partij wordt op de hoogte gebracht om actie te ondernemen.',
      noProductsAddedMessage: 'Er zijn geen producten toegevoegd',
      noProductsRemovedMessage: 'Er zijn geen producten verwijderd',
      productsAddedMessage: 'toegevoegde producten: {{ products}}',
      productsRemovedMessage: 'verwijderde producten: {{ products}}',
      noChangesToPricingMessage: 'Er zijn geen prijswijzigingen',
      priceModelChangedMessage: 'Prijsmodel gewijzigd in "{{ priceModel }}',
      updating: 'Bijwerken...',
      updated: 'Bijgewerkt!',
      checkboxLabel: 'Prijsmodel wijzigen',
      title: 'Items bijwerken'
    }
  },
  contractChanges: {
    metaData: {}
  },
  createContract: {
    messages: {
      missingFieldsMessage: 'Er zijn ontbrekende en/of ongeldige velden in het formulier.',
      contractNameMissingMessage: 'Naam overeenkomst is vereist.',
      modifierEmptyMessage: 'Modifier mag niet leeg zijn.',
      endDateMissingMessage: 'Einddatum mot worden ingesteld.',
      createUnsuccessfulMessage: 'Weet u zeker dat u de pagina wilt verlaten?',
      onlyFulfillersAllowed: 'Momenteel mogen alleen producenten overeenkomsten maken.',
      changingInitiatorWarning:
        'Het wijzigen van de initiator zorgt voor het resetten van uw voortgang. Druk op OK om door te gaan.',
      markupPercentageNotGreaterThan0Message: 'Het prijsverhogingspercentage dient hoger dan 0 te zijn.',
      discountPercentageMustBeBetween0And100: 'Het kortingspercentage dient tussen de 0 en 100 te zijn.',
      modifierAmountNotValidMessage: 'Het modifierbedrag is ongeldig.',
      setModifierHeader_discount: 'Kortingspercentage instellen',
      setModifierHeader_markup: 'Prijsverhogingspercentage instellen',
      modifierTextFieldLabel_discount: 'Kortingspercentage (%)',
      modifierTextFieldLabel_markup: 'Prijsverhogingspercentage (%)\n',
      requestPermissionsMessage: 'Neem contact op met {{ email }} voor het verkrijgen van toegang.'
    },
    chooseTypeModal: {
      modalTitle: 'Wat wilt u doen?',
      buyerTypeTitle: 'Ik wil kopen',
      sellerTypeTitle: 'Ik wil verkopen',
      sellerTypeDescription: 'Ik wil een contract aanmaken als verkoper',
      buyerTypeDescription: 'Ik wil een contract aanmaken als koper'
    },
    termEntryForm: {
      noEndDate: 'Geen einddatum',
      notesToApproverLabel: 'Opmerkingen naar de goedkeurder verzenden',
      additionalInformationLabel: 'Extra informatie of opmerkingen',
      contractNameLabel: 'Overeenkomst naam geven',
      contractDurationLabel: 'Stel de duur van uw contract in',
      noCountrySelectedMessage: 'Selecteer ten minste één land.',
      chooseDestinationCountries: 'De landen van bestemming waar uw overeenkomst geldig is selecteren.',
      allDestinationCountries: 'Alle landen van bestemming',
      allDestinationCountriesExcept: 'Alle landen van bestemming <1>behalve</1>',
      individualDestinationCountries: 'Afzonderlijke landen van bestemming'
    },
    validate: {
      buyerSelect: 'Selecteer een koper',
      sellerSelect: 'Selecteer een verkoper',
      skuSelect: 'Selecteer tenminste één sku',
      contractName: 'Voer een contractnaam in',
      pricingContractTypeSelect: 'Selecteer een prijsstellingsovereenkomsttype',
      creationTypeSelect: 'Selecteer een contract om te bewerken.'
    },
    getStarted: {
      sellerTitle: 'Aan de slag',
      buyerTitle: 'Partijen selecteren',
      header: 'Partijen voor uw overeenkomsten selecteren',
      selectSeller: 'De verkoper selecteren voor het aanmaken van de overeenkomsten',
      selectBuyer: 'De koper selecteren die de contracten creëert',
      selectBuyingFrom: 'De verkoper selecteren om van te kopen',
      missingSellerMessage: 'Selecteer de verkoper.',
      selectBuyers: 'De koper(s) selecteren voor het controleren van de overeenkomsten',
      missingBuyerMessage: 'Selecteer de koper(s)',
      addGracePeriod: 'Respijtperiode toevoegen?',
      invalidGracePeriodSelection: 'De respijtperiode dient langer te zijn 0 dagen.',
      gracePeriodLabel: 'Dagen in de respijtperiode',
      gracePeriodDescription:
        'Als de respijtperiode bestaat, worden bijwerkingen van de overeenkomst automatisch goedgekeurd als de beoordeler niet binnen de respijtperiode reageert.'
    },
    selectExistingContract: {
      header: 'Een bestaand contract selecteren om de producten aan toe te voegen'
    },
    selectCreationTypeStep: {
      title: 'Nieuw of bestaand selecteren',
      existingContractsWarning:
        'U heeft een bestaand contract/bestaande contracten met {{ name }}. U kunt kiezen om één van deze contracten bij te werken of een nieuw contract aan te maken.',
      header: 'Een nieuw contract aanmaken of een bestaand contract bijwerken'
    },
    addProducts: {
      title: 'Producten toevoegen',
      header: 'Producten selecteren om toe te voegen aan uw overeenkomsten',
      searchAndAddProducts: 'Producten zoeken en toevoegen',
      selectedProducts: 'Geselecteerde producten',
      mustSelectProductsMessage: 'U moet producten selecteren om aan uw overeenkomst toe te voegen',
      disabledListPriceMessage: 'De selector is uitgeschakeld omdat er geen catalogusprijs is ingesteld voor de SKU',
      bulkListPriceErrorMessage:
        'De bovenstaande SKU´s konden niet worden toegevoegd omdat zij geen catalogusprijs hebben of niet bestaan op het platform. Herzie deze en probeer het opnieuw.'
    },
    chooseNewOrExisting: {
      existingButtonTitle: 'Bestaand contract bijwerken',
      existingButtonDescription: 'SKU´s aan bestaand contract toevoegen met {{name}}',
      newButtonDescription: 'Een nieuw contract aanmaken met {{name}}'
    },
    addContract: {
      title: 'Een catalogusprijsstellingsovereenkomst instellen voor producten'
    },
    pricingContract: {
      title: 'Productprijsstellingsovereenkomst',
      header: 'Een productprijstellingsovereenkomst voor producten instellen',
      description:
        'Catalogusprijs of particuliere prijs voor producten selecteren en optionele korting of prijsverhoging toevoegen.',
      selectedPriceModel: 'Geselecteerde prijsmodel (slechts één beschikbaar)',
      selectedPriceModel_plural: 'Geselecteerde prijsmodel',
      changePriceModel: 'Prijsmodel wijzigen',
      noPriceModelFoundMessage:
        'Geen prijsmodel gevonden. Ga terug en selecteer opnieuw uw producten of probeer opnieuw te zoeken. Voor hulp neem contact op met {{ contactEmailAddress }}',
      selectAPriceModel: "Selecteer eerst een prijsmodel om uw sku's toe te passen",
      mustSelectAPriceModel: 'U moet uw soort overeenkomst selecteren en uw productprijsstellingovereenkomst instellen',
      mustSelectShippingAPriceModel: 'U moet het contracttype selecteren en uw verzendingscontract instellen'
    },
    shippingContract: {
      title: 'Verzendovereenkomst',
      header: 'Een verzendprijsovereenkomst voor producten instellen',
      description:
        'Basistarief op verzendkosten, vooraf geconfigureerde verzendkosten of gratis verzending. Optioneel kunt u korting of prijsverhoging toevoegen.',
      noShippingPriceModelsFoundMessage:
        'Geen prijsmodel voor verzending gevonden. Voor hulp neem contact op met {{ contactEmailAddress }}',
      shippingPriceModelSelectionLabel: 'Geselecteerde prijsmodel voor verzending'
    },
    result: {
      step_0: 'Kennisgevingen worden verzonden naar de in de voorwaarden genoemde partijen.',
      step_1: 'Goedkeurders zullen uw overeenkomst lezen en goedkeuren.',
      step_1_plural: 'Goedkeurders zullen uw overeenkomsten lezen en goedkeuren.',
      step_2: 'Eenmaal goedgekeurd wordt de overeenkomst actief.',
      step_2_plural: 'Eenmaal goedgekeurd worden de overeenkomsten actief.',
      description: 'Uw nieuwe overeenkomst is aangemaakt en verzonden ter goedkeuring',
      description_plural: 'Uw nieuwe overeenkomsten worden aangemaakt en verzoden ter goedkeuring',
      header: 'Gelukt!',
      whatHappensNext: 'Wat gebeurt er hierna?',
      backToHomePage: 'Terug naar het overzicht',
      createNewContract: 'Nieuwe overeenkomst aanmaken',
      viewContract: 'Overeenkomst bekijken',
      createNewContract_plural: 'Nieuwe overeenkomsten aanmaken',
      duplicateContract: 'Deze overeenkomst dupliceren',
      duplicateContract_plural: 'Deze overeenkomsten dupliceren',
      creatingContractsMessage: 'Wij maken de overeenkomsten voor u',
      pleaseDoNotRefresh: 'Vernieuw of sluit deze pagina niet.\n',
      notAllSKUsHaveCatalogPricing:
        "Niet alle sku's hebben instellingen voor catalogusprijzen. Stel ze in en probeer het opnieuw.",
      notHaveCorrectPermissions: 'U hebt niet de juiste bevoegdheden pm deze overeenkomst aan te maken.',
      somethingWentWrong: 'Er ging iets fout.',
      contactInformation: 'Neem contact op met {{ contactEmailAddress }} indien u hulp nodig hebt.',
      errorCreatingContractMessage:
        'Er is iets misgegaan bij het aanmaken van uw contract. Indien het probleem aanhoudt, gelieve contact op te nemen met {{ contactEmailAddress }}'
    },
    review: {
      title: 'Beoordelen',
      general: 'Algemeen',
      generalDetails: 'Algemene gegevens voor {{name}}',
      buyerHeader: 'Voor {{buyer}}',
      header: 'Uw overeenkomst controleren en afronden',
      header_plural: 'Uw overeenkomsten controleren en afronden',
      description:
        'Uw overeenkomst controleren en ter goedkeuring verzenden. Indien goedgekeurd, wordt uw overeenkomst actief.\n',
      description_plural:
        'Uw overeenkomsten controleren en ter goedkeuring verzenden. Indien goedgekeurd, wordt uw overeenkomsten actief.',
      contractDetailsLabel: 'Contractgegevens',
      contractDetailsLabel_plural: 'Contractgegevens',
      noContractBeingSetupMessage: 'U moet ten minste één overeenkomst hebben ingesteld.',
      productsInContract: 'Producten in overeenkomst',
      productsToAddContract: 'Producten om toe te voegen aan het contract',
      productsInContract_plural: 'Producten in overeenkomsten'
    }
  },
  terminationWarnings: {
    header: 'Indien u verder gaat...',
    warning_0: 'Een nieuwe revisie wordt aangemaakt met AFGESLOTEN status',
    warning_1: 'Er kunnen geen verdere wijzigingen en revisies voor deze overeenkomst worden gemaakt.',
    warning_1_0:
      '<0>Dit betekent dat er een nieuwe overeenkomst moet worden aangemaakt om deze overeenkomst opnieuw aan te maken.</0>',
    warning_1_1:
      '<0>Als dit een catalogusprijsovereenkomst is, zullen bijwerkingen van catalogusprijzen deze overeenkomt<1>niet</1> beïnvloeden.</0>',
    warning_2: 'De overeenkomst zal niet actief zijn tussen de twee partijen.',
    warning_2_0:
      '<0>Indien koper A aan het inkopen was bij verkoper B, kan koper A <1>niet langer</1> inkopen met deze overeenkomst.</0>',
    warning_3: 'Deze overeenkomst zal niet meer in het overzicht verschijnen.',
    warning_4: 'Deze actie kan niet worden teruggedraaid.'
  },
  errors: {
    error_general: 'Fout',
    error_notFound: 'Niet gevonden',
    error_create_permission: 'U bent niet gemachtigd om contracten aan te maken',
    error_forbidden: 'U bent niet gemachtigd om deze pagina te bezoeken.',
    error_unknown: 'Er deed zich een fout voor tijdens het laden van de gebruikersidentiteiten.',
    withCommentAndStatusCode: 'Fout: {{serviceComment}} ({{statusCode}})'
  },
  settings: {
    header: 'Instellingen',
    subscriptions: {
      header2_contractNotification:
        'Beheer uw abonnementen op e-mailmeldingen wanneer productversie-archivering of prijswijzigingen worden geactiveerd.',
      header: 'Mijn abonnementen',
      header2: 'Uw abonnementen met e-mailmeldingen beheren wanneer een contract wordt aangemaakt of bijgewerkt. ',
      header3: 'Een identeit selecteren en kiezen op welke voorwaarden u wilt abonneren.',
      multipleRolesWarning_fulfillers:
        "Indien u toegang hebt tot zowel verkopers als kopers, zullen alleen de abonnementen die gemaakt zijn vanaf de <1>verkoper's</1> kant, worden op deze pagina getoond.",
      multipleRolesWarning_merchants:
        "Indien u toegang hebt tot zowel verkopers als kopers, zullen alleen de abonnementen die gemaakt zijn vanaf de <1>koper's</1> kant, worden op deze pagina getoond.",
      fetchingMessage: 'Uw abonnementen aan het ophalen...',
      fetchinErrorMessage: 'Er deed zich een fout voor tijdens het ophalen van uw huidige abonnementen',
      searchBy_fulfillers: 'Zoeken op naam van de verkoper',
      searchBy_merchants: 'Zoeken op naam van de koper',
      manageSettingsFor: 'Instellingen beheren voor',
      addSubscriptionButton_fulfillers: 'Meerdere verkopers instellen',
      addSubscriptionButton_merchants: 'Meerdere kopers instellen',
      addSubscriptionTooltip_fulfillers: 'Abonnementen voor meerdere verkopers tegelijk instellen',
      addSubscriptionTooltip_merchants: 'Abonnementen voor meerdere kopers tegelijk instellen',
      subscriptionNotAllowedForNonCimpressOwned:
        'Alleen gebruiker van een eigen account van Cimpress kan zich als koper aanmelden',
      subscriptionAccordion: {
        notifiedOnAll_fulfillers: 'Aangemeld op: <1>Alle verkopers</1>',
        notifiedOnAll_merchants: 'Aangemeld op: <1>Alle kopers</1>',
        notifiedOn_fulfillers: 'Aangemeld op: <1>{{ count }} Verkoper</1>',
        notifiedOn_fulfillers_plural: 'Aangemeld op: <1>{{ count }} Verkopers</1>',
        notifiedOn_merchants: 'Aangemeld op: <1>{{ count }} Koper</1>',
        notifiedOn_merchants_plural: 'Aangemeld op: <1>{{ count }} Kopers</1>',
        noActiveNotifications: 'Geen meldingen actief',
        noChangesWarning: 'U hebt geen wijzigingen aangebracht in uw huidige configuratie\n',
        noSelectionWarning_fulfillers: 'U hebt geen afzonderlijke verkoper geselecteerd om op te abonneren',
        noSelectionWarning_merchants: 'U hebt geen afzonderlijke koper geselecteerd om op te abonneren'
      },
      actions: {
        subscribeToAll_sellers_contractNotification: 'Abonneer u op alle verkopers',
        unsubscribeToAll_sellers_contractNotification: 'Afmelden bij alle verkopers',
        subscribeToIndividual_sellers_contractNotification: 'Abonneer u op individuele verkopers',
        subscribeToAll_buyers_contractNotification: 'Abonneer u op alle kopers',
        unsubscribeToAll_buyers_contractNotification: 'Afmelden voor alle kopers',
        subscribeToIndividual_buyers_contractNotification: 'Abonneer u op individuele kopers',

        subscribeToAll_fulfillers: 'Op alle verkopers abonneren',
        unsubscribeToAll_fulfillers: 'Van alle verkopers afmelden\n',
        subscribeToIndividual_fulfillers: 'Op afzonderlijke verkopers abonneren',
        subscribeToAll_merchants: 'Op alle kopers abonneren',
        unsubscribeToAll_merchants: 'Van alle kopers afmelden',
        subscribeToIndividual_merchants: 'Op afzonderlijke kopers abonneren'
      },
      selectLabel_fulfillers: 'Verkoper selecteren',
      selectLabel_merchants: 'Koper selecteren',
      subscriptionModal: {
        selectIdentities_fulfillers: 'Verkopers selecteren om in te stellen',
        selectIdentities_merchants: 'Kopers selecteren om in te stellen',
        selectAllIdentities_fulfillers: 'Alle verkopers selecteren',
        selectAllIdentities_merchants: 'Alle kopers selecteren',
        firstStepModalTitle_fulfillers: 'Meerdere van uw verkopers tegelijk instellen',
        firstStepModalTitle_merchants: 'Meerdere van uw kopers tegelijk instellen',
        secondStepModalTitle_fulfillers: 'Type abonnement van geselecteerde verkopers selecteren',
        secondStepModalTitle_merchants: 'Type abonnement van geselecteerde kopers selecteren',
        infoMessage_fulfillers:
          'Uw huidige abonnementen voor afzonderlijke verkopers worden, indien aanwezig, door deze wijziging opgeheven',
        infoMessage_merchants:
          'Uw huidige abonnementen voor afzonderlijke kopers worden, indien aanwezig, door deze wijziging opgeheven'
      }
    },
    autoApprovals: {
      header: 'Mijn automatische goedkeuringen',
      header2: 'Automatisch overeenkomsten goedkeuren wanneer',
      addButton: 'Automatisch goedkeuren toevoegen',
      filterInputLabel: 'Filteren op naam',
      approvalNeeded: 'goedkeuring nodig van',
      createdByLabel: 'aangemaakt of aangepast door',
      fetchingMessage: 'Automatische goedkeuringsinstellingen aan het ophalen...',
      select_fulfillers: 'Verkoper selecteren',
      select_fulfillers_plural: 'Verkoper(s) selecteren',
      select_merchants: 'Koper selecteren',
      select_merchants_plural: 'Koper(s) selecteren'
    },
    features: {
      header: 'Kenmerken',
      loading: {
        message: 'Kenmerken aan het ophalen...'
      },
      shippingContracts: {
        label: 'Overeenkomsten voor verzending inschakelen'
      },
      terminatedContracts: {
        label: 'Beëindigde overeenkomsten bekijken'
      },
      languageSelection: {
        label: 'Taal'
      }
    }
  },
  createRelationships: {
    title: 'Verkoper koper',
    selectBuyer: 'Selecteer de koper',
    selectSeller: 'Selecteer de verkoper',
    createRelationTitle: 'Creëer relaties',
    enterSunsetPeriod: 'Productarchivering',
    enterPriceLockin: 'Voer de prijsvergrendelingsperiode in',
    sunsetPeriodLabel: 'dagen',
    lockinPeriodLabel: 'dagen',
    createRelationButtonText: 'Creëer relaties',
    buyerAndSellerAccountIdSameErrorMessage: 'Koper en verkoper kunnen niet hetzelfde zijn in een relatie.',
    agreementTitle: 'Uw overeenkomsten',
    agreementSubTitle: 'Producten en prijzen',
    agreementNegotiationTitle:
      'Voor hoeveel dagen van tevoren gaat u ermee akkoord dat u de koper hiervan op de hoogte stelt:',
    shippingAgreementTitle: 'Verzenden',
    shippingAgreementOption1: 'Koper betaalt de vervoerder rechtstreeks',
    shippingAgreementOption2: 'Koper betaalt de verzendkosten van de verkoper tijdens verzending als verzendkosten',
    shippingAgreementOption3: 'De koper betaalt de verkoper een vastgestelde verzendkosten',
    shippingAgreementOption4: 'Gratis verzendingg',
    notificationTitle: 'Kennisgeving',
    notificationText:
      'Laat het ons weten wanneer het tegenovergestelde de prijs verandert of een product met pensioen gaat',
    notificationEmailText: 'Geef een e-mail op per regel of gescheiden door een komma',
    helptext: 'Geef een e-mail op per regel of gescheiden door een komma'
  },
  viewRelationships: {
    messages: {
      noVersionsFound: 'Geen versie (s) hier aanwezig.'
    }
  },
  productAgreementsPage: {
    mainHeading: '{{ businessOne }} en {{ businessTwo }}',
    productsHeading: 'Producten',
    buyerSubHeading: 'kopen van {{ businessTwo }}',
    sellerSubHeading: 'verkopen aan {{ businessTwo }}',
    productId: 'Produkt ID',
    terms: 'Voorwaarden',
    priceLockinSectionOne: '{{seller}} gaat ermee akkoord {{koper}} ten minste te geven ',
    priceLockinSectionTwo: ' dagen van tevoren op de hoogte voordat de prijs wordt gewijzigd.',
    sunsetSectionOne: '{{seller}} gaat ermee akkoord {{buyer}} ten minste te geven ',
    sunsetSectionTwo: ' dagen van tevoren op de hoogte voordat een product wordt gearchiveerd dat {{buyer}} koopt',
    noRelationshipAsBuyer:
      '{{ businessOne }} heeft geen relatie om van te kopen {{ businessTwo }}. Neem contact op {{ businessTwo }} om dit in te stellen.',
    noRelationshipAsSeller: '{{ businessOne }} heeft geen relatie om aan te verkopen {{ businessTwo }}.',
    save: 'Sla de wijzigingen op',
    edit: 'Bewerk de waarde',
    cancel: 'Bewerken annuleren',
    deletePriceLock: 'Prijsvergrendeling verwijderen',
    deletePriceLockConfirmation: 'Weet u zeker dat u de prijsvergrendeling voor {{ productId }} wilt verwijderen?',
    addProducts: 'Voeg producten toe',
    search: 'Zoeken',
    selectVersions: 'Selecteer versies',
    view: 'Visie',
    columnNames: {
      productId: 'PRODUKT ID',
      productName: 'PRODUKTNAME',
      versions: 'VERSIES',
      view: 'VISIE',
      priceLock: 'PRIJSSLOT TOT'
    },
    versionDeleteConfirmation1: 'Wilt u de versie {{versions}} verwijderen',
    versionDeleteConfirmation2: 'Dit betekent:',
    versionDeleteConfirmation3: 'U koopt deze versie niet meer',
    versionDeleteConfirmation4: 'U mag geen melding ontvangen wanneer de versie wordt gearchiveerd.',
    priceChangeTitle: 'Prijs verandering',
    productArchivingTitle: 'Productarchivering',
    shippingTitle: 'Verzending',
    contactForShippingAgreement: `Neem contact op {{ contactEmailAddress }} als u hulp nodig heeft.`,
    productInformation: 'Productinformatie',
    buyerNotCimpressOwnedMessage: `{{accountName}} is niet ingeschakeld als koper op het platform!`,
    buyerNotCimpressOwnedMessageContactUs: `Neem contact op met platformondersteuning als {{accountName}} een platform koopt.`
  },
  notificationDetails: {
    acknowledge: 'Erkennen',
    contactSeller: 'Neem bij vragen contact op met de verkoper: Contact',
    isArchiving: 'archiveert',
    startingFrom: 'Beginnend vanaf',
    noLongerAvailable: 'die u koopt, is niet langer beschikbaar.',
    decidedToArchive: 'heeft besloten te archiveren',
    unavailableToBuyFrom: 'en zal niet langer beschikbaar zijn om vanaf te kopen',
    nonRevocableAction: 'Dit is een niet-herroepbare actie van',
    ensureChangeBefore: 'Zorg er daarom voor dat u de vereiste wijzigingen eerder hebt aangebracht',
    noDisruption: 'zodat er geen overlast is voor uw eindklanten.',
    acknowledgedMessage: 'bevestigde dit bericht op',
    for: 'voor',
    ofThe: 'van de',
    theVersion: 'de versie',
    notificationAlreadyAcknowledged: 'Melding werd al bevestigd door',
    priceUpdateEffectiveOn: 'Prijsupdate is van kracht vanaf',
    isUpdatingPricing: 'actualiseert prijzen voor',
    products: 'producten',
    priceChangeAutomaticApply: `Deze prijswijziging wordt automatisch van kracht op {{ date }}. Neem bij vragen contact op met de verkoper.`,
    asPerYourAgreement: `Volgens uw overeenkomst met {{ sellerName }} zijn er nieuwe prijzen van toepassing op de onderstaande producten vanaf {{ date }}, na de verplichte meldingsperiode van {{ priceUpdateDays }} dag (en).`,
    acknowledgementMessage: `{{ acknowledgedBy }} heeft dit bericht bevestigd op {{ acknowledgedAt }} voor {{ buyerName }}`,
    oldPrices: 'Oude prijzen',
    newPrices: 'Nieuwe prijzen',
    versionDifferenceHeading: `Verschil tussen sunset-versie {{sunsetVersion}} en huidige / laatste versie`,
    versionDifferenceNote: `(wijzigingen worden blauw gemarkeerd)`
  },
  notifications: {
    header: 'Meldingen',
    searchByProductId: 'Product-ID',
    startDate: 'Startdatum',
    endDate: 'Einddatum',
    all: 'Allemaal',
    productArchiving: 'Productarchivering',
    priceChange: 'Prijs verandering',
    today: 'Vandaag',
    others: 'Anderen',
    acknowledgedBy: 'Erkend door: ',
    fetchingMessage: 'Uw meldingen ophalen ...',
    notificationsNotAvailable: 'Geen meldingen beschikbaar',
    notificationItems: {
      PRICECHANGE: 'Nieuwe prijs voor ',
      SUNSET: 'Archiveren van een versie van '
    },
    notificationMessage: {
      PRICECHANGE: 'Vanaf {} komt er een nieuwe prijs voor {} ',
      SUNSET: 'Vanaf {} is de versie van {} die u koopt niet meer beschikbaar'
    }
  },
  errorRobot: {
    error: 'Vernieuw de pagina om het opnieuw te proberen. Als het probleem aanhoudt, neem dan contact op met'
  }
};
