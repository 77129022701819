import { DISCOUNT, MARKUP } from './modifierType';

export const TermTypeLabel = {
  LIST_PRICE: 'common:contractTypes.listPrice',
  LIST_PRICE_DISCOUNT: 'common:contractTypes.listPriceWithDiscount',
  LIST_PRICE_MARKUP: 'common:contractTypes.listPriceWithMarkup',
  SPECIAL_PRICE: 'common:contractTypes.specialPrice',
  SPECIAL_PRICE_MARKUP: 'common:contractTypes.specialPriceWithMarkup',
  FREE_SHIPPING: 'common:contractTypes.freeShipping',
  SHIPPING_COST: 'common:contractTypes.shippingCost',
  SHIPPING_COST_DISCOUNT: 'common:contractTypes.shippingCostWithDiscount',
  SHIPPING_COST_MARKUP: 'common:contractTypes.shippingCostWithMarkup',
  SHIPPING_PRICE: 'common:contractTypes.shippingPrice',
  SHIPPING_PRICE_DISCOUNT: 'common:contractTypes.shippingPriceWithDiscount',
  SHIPPING_PRICE_MARKUP: 'common:contractTypes.shippingPriceWithMarkup'
};

export const createFlowTermTypeHasModifier = createFlowTermType => {
  return (
    createFlowTermType === TermTypeLabel.LIST_PRICE_DISCOUNT ||
    createFlowTermType === TermTypeLabel.LIST_PRICE_MARKUP ||
    createFlowTermType === TermTypeLabel.SPECIAL_PRICE_MARKUP ||
    createFlowTermType === TermTypeLabel.SHIPPING_COST_MARKUP ||
    createFlowTermType === TermTypeLabel.SHIPPING_PRICE_MARKUP ||
    createFlowTermType === TermTypeLabel.SHIPPING_PRICE_DISCOUNT
  );
};

export const createFlowIsShippingPriceTermType = createFlowTermType => {
  return (
    createFlowTermType === TermTypeLabel.SHIPPING_PRICE ||
    createFlowTermType === TermTypeLabel.SHIPPING_PRICE_MARKUP ||
    createFlowTermType === TermTypeLabel.SHIPPING_PRICE_DISCOUNT
  );
};

export const getTermModifierType = termType => {
  switch (termType) {
    case TermTypeLabel.LIST_PRICE_DISCOUNT:
    case TermTypeLabel.SHIPPING_COST_DISCOUNT:
    case TermTypeLabel.SHIPPING_PRICE_DISCOUNT:
      return DISCOUNT;
    case TermTypeLabel.LIST_PRICE_MARKUP:
    case TermTypeLabel.SPECIAL_PRICE_MARKUP:
    case TermTypeLabel.SHIPPING_COST_MARKUP:
    case TermTypeLabel.SHIPPING_PRICE_MARKUP:
      return MARKUP;
    default:
      return null;
  }
};

export const getCreateFlowAgreementTypeDisplay = ({ termType, modifierAmount }) => {
  return getTermModifierType(termType) ? `(${modifierAmount}%)` : '';
};

export const getTermType = termInfo => {
  switch (termInfo) {
    case TermTypeLabel.LIST_PRICE:
    case TermTypeLabel.LIST_PRICE_DISCOUNT:
    case TermTypeLabel.LIST_PRICE_MARKUP:
      return TermType.LIST_PRICE;
    case TermTypeLabel.SPECIAL_PRICE:
    case TermTypeLabel.SPECIAL_PRICE_MARKUP:
      return TermType.SPECIAL_PRICE;
    case TermTypeLabel.FREE_SHIPPING:
      return TermType.FREE_SHIPPING;
    case TermTypeLabel.SHIPPING_COST:
    case TermTypeLabel.SHIPPING_COST_DISCOUNT:
    case TermTypeLabel.SHIPPING_COST_MARKUP:
      return TermType.SHIPPING_COST;
    case TermTypeLabel.SHIPPING_PRICE:
      return TermType.SHIPPING_PRICE;
    case TermTypeLabel.SHIPPING_PRICE_DISCOUNT:
      return TermType.SHIPPING_PRICE;
    case TermTypeLabel.SHIPPING_PRICE_MARKUP:
      return TermType.SHIPPING_PRICE;
    default:
      return null;
  }
};

export const TermType = {
  // product
  LIST_PRICE: 'list',
  SPECIAL_PRICE: 'special',

  // shipping
  SHIPPING_COST: 'shippingcost',
  FREE_SHIPPING: 'freeshipping',
  SHIPPING_PRICE: 'shippingprice'
};

export const IsProductTermType = type => {
  return type === TermType.LIST_PRICE || type === TermType.SPECIAL_PRICE;
};

export const IsCreateFlowShippingType = type => {
  return (
    type === TermTypeLabel.SHIPPING_COST ||
    type === TermTypeLabel.FREE_SHIPPING ||
    type === TermTypeLabel.SHIPPING_COST_DISCOUNT ||
    type === TermTypeLabel.SHIPPING_COST_MARKUP ||
    type === TermTypeLabel.SHIPPING_PRICE_MARKUP ||
    type === TermTypeLabel.SHIPPING_PRICE_DISCOUNT ||
    type === TermTypeLabel.SHIPPING_PRICE
  );
};

export const IsShippingTermType = type => {
  return type === TermType.SHIPPING_COST || type === TermType.FREE_SHIPPING || type === TermType.SHIPPING_PRICE;
};

export const LIST_PRICE = 'Catalog Price';
export const SPECIAL_PRICE = 'Private Price';
