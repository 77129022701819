import i18n from './i18n';
import { SupportedLanguages, DEFAULT_LANGUAGE } from '../constants/appConstants';

export const computeCurrentAppLanguage = ({ language }) => {
  if (language && language.length > 0) {
    const selectedLanguage = language[0];
    const isLanguageSupported = SupportedLanguages.some(language => language === selectedLanguage);
    if (isLanguageSupported) {
      return selectedLanguage;
    }
  }
  return DEFAULT_LANGUAGE;
};

export const changeLanguage = userSettings => {
  const currentAppLanguage = computeCurrentAppLanguage(userSettings);
  i18n.changeLanguage(currentAppLanguage);
};
