import * as shippingPriceApi from '../../../services/ShippingPrice';
import { createApiErrorNotificationAction } from '../../utils';
import { LOADING, UNAVAILABLE, AVAILABLE } from '../../../constants/entityStatus';

const FETCH_SHIPPING_PRICE_MODEL_REQUEST = 'FETCH_SHIPPING_PRICE_MODEL_REQUEST';
const FETCH_SHIPPING_PRICE_MODEL_FAILURE = 'FETCH_SHIPPING_PRICE_MODEL_FAILURE';
const FETCH_SHIPPING_PRICE_MODEL_SUCCESS = 'FETCH_SHIPPING_PRICE_MODEL_SUCCESS';
const FETCH_SHIPPING_PRICE_MODEL_NOT_FOUND = 'FETCH_SHIPPING_PRICE_MODEL_NOT_FOUND';

const fetchShippingPriceModelRequest = () => ({ type: FETCH_SHIPPING_PRICE_MODEL_REQUEST });
const fetchShippingPriceModelFailure = () => ({ type: FETCH_SHIPPING_PRICE_MODEL_FAILURE });
const fetchShippingPriceModelNotFound = () => ({ type: FETCH_SHIPPING_PRICE_MODEL_NOT_FOUND });
const fetchShippingPriceModelSuccess = payload => ({ type: FETCH_SHIPPING_PRICE_MODEL_SUCCESS, payload });

const fetchShippingPriceModel = seller => dispatch => {
  dispatch(fetchShippingPriceModelRequest());

  shippingPriceApi
    .getShippingPriceModel(seller)
    .then(shippingPriceModel => {
      if (!shippingPriceModel) {
        return dispatch(fetchShippingPriceModelNotFound(shippingPriceModel));
      }
      dispatch(fetchShippingPriceModelSuccess(shippingPriceModel));
    })
    .catch(err => {
      dispatch(fetchShippingPriceModelFailure());
      dispatch(createApiErrorNotificationAction(err));
    });
};

const defaultState = {
  shippingPriceModelStatus: LOADING,
  shippingPriceModel: null
};

export function shippingPrice(state = defaultState, action) {
  switch (action.type) {
    case FETCH_SHIPPING_PRICE_MODEL_REQUEST:
      return { ...state, shippingPriceModelStatus: LOADING };
    case FETCH_SHIPPING_PRICE_MODEL_FAILURE:
    case FETCH_SHIPPING_PRICE_MODEL_NOT_FOUND:
      return { ...state, shippingPriceModelStatus: UNAVAILABLE };
    case FETCH_SHIPPING_PRICE_MODEL_SUCCESS:
      return { ...state, shippingPriceModelStatus: AVAILABLE, shippingPriceModel: action.payload };
    default:
      return state;
  }
}

export const actions = {
  fetchShippingPriceModel
};

export const selectors = {
  getFetchShippingPriceModelStatus: state => state.contractV1.shippingPrice.shippingPriceModelStatus,
  getShippingPriceModel: state => state.contractV1.shippingPrice.shippingPriceModel
};

export default shippingPrice;
