import React, { useState, Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { css } from 'emotion';
import Loading from '../../Loading';
import BasicLayout from '../../layouts/BasicLayout';
import SelectRelation from '../SelectRelation';
import ShippingContract from '../ShippingContract';
import { TextField, Button, Modal } from '@cimpress/react-components';
import { actions, selectors } from '../../../reducers/productagreements';
import { createRelationships_SUCCESS } from '../../../reducers/productagreements/createRelationships';
import { createShippingAgreements_SUCCESS } from '../../../reducers/productagreements/createShippingAgreements';
import { selectors as identitySelectors } from '../../../reducers/identities/index';
import { actions as fetchAllAccountsAction, selectors as accountSelectors } from '../../../reducers/account';
import {
  actions as fulfillmentNetworkActions,
  selectors as fulfillmentNetworkSelectors
} from '../../../reducers/fulfillmentnetwork';
import { SAVING } from '../../../constants/entityStatus';
import { SnackbarType, withSnackbar } from '../../snackbar/Snackbar';
import BreadcrumbsEntry from '../../common/breadcrumbs/BreadcrumbsEntry';
import keyBy from 'lodash/keyBy';
import isEmpty from 'lodash/isEmpty';
import { isManageContractPermission } from '../../../utilities/checkPermission';

const textFieldStyle = css({
  maxWidth: '200px'
});

const initialRelation = { buyer: undefined, seller: undefined };
function CreateRelationships(props) {
  const [relation, setRelation] = useState(initialRelation);
  const [gracePeriod, setGracePeriod] = useState(0);
  const [lockinPeriod, setLockinPeriod] = useState(0);
  const [selectedShippingType, SetSelectedShippingType] = useState();
  const [loading, setLoading] = useState(true);
  const [doesUserHasManageContractPermission, setManageContractPermission] = useState(false);

  const { t } = useTranslation();

  const {
    createSnackbar,
    relationshipCreationStatus,
    isOpenInModal,
    onCloseModal,
    relationship,
    onRelationshipCreate,
    isAdmin,
    accountsOptions,
    network,
    manageAccountId
  } = props;

  const isSaving = relationshipCreationStatus === SAVING;
  const isDisableRelationshipSelection = !isEmpty(relationship);

  useEffect(
    () => {
      if (relationship) {
        const { buyerAccountId, buyerName, sellerAccountId, sellerName } = relationship;
        setRelation({
          buyer: { label: buyerName, value: buyerAccountId },
          seller: { label: sellerName, value: sellerAccountId }
        });
      }
    },
    [relationship]
  );

  useEffect(() => {
    var isManageContractPermissionForUser = isManageContractPermission(manageAccountId, props.userPermissions);
    setManageContractPermission(isManageContractPermissionForUser);
    props.fetchAllAccounts();
    if (!props.network && !isDisableRelationshipSelection) {
      props.fetchNetwork();
    }
  }, []);

  useEffect(
    () => {
      if (isDisableRelationshipSelection) {
        setLoading(false);
        return;
      }
      if (accountsOptions && accountsOptions.length > 0 && network) {
        const accountMap = keyBy(accountsOptions, 'value');
        if (accountMap.hasOwnProperty(manageAccountId)) {
          onChangeRelation({ seller: { ...accountMap[manageAccountId] } });
        }
        setLoading(false);
      }
    },
    [accountsOptions, network]
  );

  const onGracePeriodChange = e => setGracePeriod(e.target.value);
  const onPriceLockinPeriodChange = e => setLockinPeriod(e.target.value);

  const isSellerOrAdminCreatingRelation =
    (doesUserHasManageContractPermission && relation.seller && relation.seller.value === manageAccountId) ||
    (relation.seller && isAdmin);

  const isSelectionInvalid = !relation.seller || !relation.buyer || lockinPeriod === '' || gracePeriod === '';
  const onChangeRelation = updateRelation => {
    const { buyer, seller } = updateRelation;
    if (buyer && relation.seller && buyer.value === relation.seller.value) {
      createSnackbar('Error displaying', SnackbarType.error, 8);
      return;
    }

    if (seller && relation.buyer && seller.value === relation.buyer.value) {
      createSnackbar('Error displaying', SnackbarType.error, 8);
      return;
    }
    setRelation(relation => ({ ...relation, ...updateRelation }));
  };

  const createRelationships = async () => {
    const { buyer, seller } = relation;
    const response = await props.createRelationship(buyer, seller, gracePeriod, lockinPeriod);
    if (response.type === createRelationships_SUCCESS) {
      if (selectedShippingType) {
        const shippingResponse = await props.createShippingAgreements(buyer, seller, selectedShippingType);
        if (shippingResponse.type !== createShippingAgreements_SUCCESS) {
          return;
        }
      }
      onRelationshipCreate(buyer.value, seller.value);
    }
  };

  const openInModal = () => {
    return (
      <Modal
        show={isOpenInModal}
        title={t('createRelationships:createRelationButtonText')}
        footer={
          <div>
            <Button type="default" onClick={onCloseModal}>
              {t('common:commonWords.close')}
            </Button>
            {getButton()}
          </div>
        }>
        {loading ? <Loading /> : getRelationshipCreationPage()}
      </Modal>
    );
  };

  const getButton = () => {
    return (
      <Button
        type="primary"
        disabled={!isSellerOrAdminCreatingRelation || isSelectionInvalid}
        onClick={createRelationships}>
        {t('createRelationships:createRelationButtonText')}
      </Button>
    );
  };
  const getRelationshipCreationPage = () => {
    return (
      <Fragment>
        <div>
          {isSaving && <Loading fullPage />}
          <SelectRelation
            isAdmin={isAdmin}
            {...relation}
            isDisableRelationshipSelection={isDisableRelationshipSelection}
            onChange={onChangeRelation}
          />
          <h4>{t('createRelationships:agreementTitle')}</h4>
          <h5 style={{ marginTop: '15px' }}>{t('createRelationships:agreementSubTitle')}</h5>
          <div style={{ marginBottom: '5px' }}>{t('createRelationships:agreementNegotiationTitle')}</div>
          <div className="flexContainer">
            <div>
              <div className={textFieldStyle}>
                <TextField
                  label={t('createRelationships:enterPriceLockin')}
                  type="number"
                  min="1"
                  required
                  value={lockinPeriod}
                  disabled={!isSellerOrAdminCreatingRelation}
                  onChange={onPriceLockinPeriodChange}
                />
              </div>
            </div>
            <div className="center-content"> &nbsp;{t('createRelationships:lockinPeriodLabel')}</div>
          </div>
          <div className="flexContainer">
            <div>
              <div className={textFieldStyle}>
                <TextField
                  label={t('createRelationships:enterSunsetPeriod')}
                  type="number"
                  min="1"
                  disabled={!isSellerOrAdminCreatingRelation}
                  value={gracePeriod}
                  required
                  onChange={onGracePeriodChange}
                />
              </div>
            </div>
            <div className="center-content"> &nbsp;{t('createRelationships:sunsetPeriodLabel')}</div>
          </div>
          <h5>{t('createRelationships:shippingAgreementTitle')}</h5>
          <ShippingContract
            {...relation}
            selectedShippingType={selectedShippingType}
            onChange={SetSelectedShippingType}
            allowToEdit={true}
          />
        </div>
      </Fragment>
    );
  };

  return (
    <div>
      {isOpenInModal ? (
        openInModal()
      ) : (
        <BreadcrumbsEntry title={{ translationKey: t('createRelationships:createRelationTitle') }}>
          <BasicLayout className="contract-home">
            {getRelationshipCreationPage()}
            <div align="right">{getButton()}</div>
          </BasicLayout>
        </BreadcrumbsEntry>
      )}
    </div>
  );
}

const mapStateToProps = state => ({
  createdRelationship: selectors.getCreatedRelationship(state),
  relationshipCreationStatus: selectors.getRelationshipCreationStatus(state),
  getAccountPermission: identitySelectors.getAccountPermission(state),
  accountsOptions: accountSelectors.getAccountsOptions(state),
  accountStatus: accountSelectors.getAccountStatus(state),
  network: fulfillmentNetworkSelectors.getNetwork(state),
  networkStatus: fulfillmentNetworkSelectors.getNetworkStatus(state),
  isAdmin: identitySelectors.isUserAdmin(state),
  userPermissions: identitySelectors.getPermissionsPayload(state)
});

const mapDispatchToProps = {
  createRelationship: actions.createRelationshipsAction,
  createShippingAgreements: actions.createShippingAgreementsAction,
  fetchNetwork: fulfillmentNetworkActions.fetchNetworkAction,
  fetchAllAccounts: fetchAllAccountsAction.fetchAllAccountsAction
};

export default withSnackbar(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CreateRelationships)
);
