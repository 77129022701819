import { combineReducers } from 'redux';
import accounts, {
  fetchAccountsAction,
  fetchAccountAction,
  getAccounts,
  fetchAllAccountsAction,
  getAccountsOptions,
  getCimpressOwnedAccountOptions,
  getAccountStatus,
  getAccountsSortedByName,
  getAccountById
} from './fetchAccounts';

// actions
export const actions = {
  fetchAccountsAction,
  fetchAllAccountsAction,
  fetchAccountAction
};

// selectors
export const selectors = {
  getAccounts,
  getAccountsOptions,
  getCimpressOwnedAccountOptions,
  getAccountStatus,
  getAccountsSortedByName,
  getAccountById
};

export default combineReducers({
  accounts
});
