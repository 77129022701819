import { createReducer } from '../utils';

import { AVAILABLE } from '../../constants/entityStatus';
export const selectedManageAccount_REQUEST = 'selectedManageAccount_REQUEST';
export const selectedManageAccount_SUCCESS = 'selectedManageAccount_SUCCESS';
export const selectedManageAccount_FAILURE = 'selectedManageAccount_FAILURE';

// Action
export const setSelectedManageAccountAction = selectedManageAccount => {
  return {
    types: [selectedManageAccount_REQUEST, selectedManageAccount_SUCCESS, selectedManageAccount_FAILURE],
    callAPI: () => {
      return Promise.resolve(selectedManageAccount);
    }
  };
};

// reducer
export default createReducer(
  {},
  {
    [selectedManageAccount_SUCCESS]: (state, action) => {
      const { payload } = action;
      return { ...state, payload, status: AVAILABLE };
    }
  }
);

// Selector
export const getSelectedManageAccountPayload = state => {
  return state.selectedManageAccount.selectedManageAccount.payload;
};

export const getSelectedManageAccountStatus = state => {
  return state.selectedManageAccount.selectedManageAccount.status;
};
