// @flow
import { buildOptions, makeRequest } from './ServiceHelpers';
import type { FulfillerModel } from '../models/fulfillerModel';

const endpointUrl = process.env.REACT_APP_IDENTITY_URL;

export const fetchAllFulfillers = (): Promise<Array<FulfillerModel>> => {
  const options = buildOptions();

  return makeRequest(endpointUrl, '/v1/fulfillers?showArchived=false', options, 'getting fulfillers list')
    .then(response => {
      return response.fulfillers.map(fulfiller => ({
        id: fulfiller.fulfillerId,
        name: fulfiller.fulfillerName
      }));
    })
    .catch(err => {
      if (err.statusCode === 403) {
        return [];
      } else {
        throw err;
      }
    });
};
