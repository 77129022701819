export default {
  common: {
    contractV1Src: {
      DoYouWantToCreateShippingContractV1: 'Möchten Sie einen Versandvertrag erstellen?',
      EnabledShippingContractV1ErrorMessage: 'Fehler beim Abrufen der Konto-IDs zum Aktivieren des Versandvertrags.',
      NoAgreements:
        'De weergegeven contracten zijn gemaakt binnen hetzelfde bedrijf. Er zijn hier dus geen afspraken getoond.',
      PleaseContact: 'Neem contact op met {{emailid}} als uw bedrijf overeenkomsten vereist.'
    },
    contractTypes: {
      listPrice: 'Katalogpreis',
      specialPrice: 'Privatpreis',
      freeShipping: 'Kostenloser Versand',
      shippingCost: 'Versandkosten',
      shippingPrice: 'Versandpreis',
      listPriceWithDiscount: '$t(common:contractTypes.listPrice) mit $t(common:commonWords.discount)',
      listPriceWithMarkup: '$t(common:contractTypes.listPrice) mit $t(common:commonWords.markup)',
      specialPriceWithMarkup: '$t(common:contractTypes.specialPrice) mit $t(common:commonWords.markup)',
      shippingCostWithDiscount: '$t(common:contractTypes.shippingCost) mit $t(common:commonWords.discount)',
      shippingCostWithMarkup: '$t(common:contractTypes.shippingCost) mit $t(common:commonWords.markup)',
      shippingPriceWithDiscount: '$t(common:contractTypes.shippingPrice) mit $t(common:commonWords.discount)',
      shippingPriceWithMarkup: '$t(common:contractTypes.shippingPrice) mit $t(common:commonWords.markup)',
      DoYouWantToCreateShippingContractV1: 'Möchten Sie einen Versandvertrag erstellen?'
    },
    contractStatus: {
      approved: 'Genehmigt',
      pending: 'Ausstehend',
      terminated: 'Gekündigt',
      rejected: 'Abgelehnt',
      draft: 'Entwurf',
      effective: 'Gültig ab',
      visibleInCatalog: 'Im Katalog sichtbar',
      usingDeletedModel: 'Gelöschtes Preismodell wird verwendet',
      expiredAgo: '(Abgelaufen {{ duration, localizeDuration }})',
      isExpiring: '(Gültig bis {{ duration, localizeDuration }})',
      pending_yourResponse: 'Muss überprüft werden',
      pending_otherParty: 'Genehmigung ausstehend',
      pending_priorTerm: 'Vorher $t(common:contractStatus.pending)',
      approved_priorTerm: 'Vorher $t(common:contractStatus.approved)',
      rejected_priorTerm: 'Vorher $t(common:contractStatus.rejected)',
      terminated_priorTerm: 'Vorher $t(common:contractStatus.terminated)',
      gracePeriodAboutToExpire: 'Wird automatisch genehmigt <1>{{ duration, localizeDuration }}</1>',
      needsApproval: 'Erfordert Genehmigung'
    },
    contract: {
      lastUpdatedXAgo: '{{ duration, localizeDuration }}',
      privateButDiscoverableTip:
        'Die Artikelpreise in diesem Privatpreisvertrag sind öffentlich und im Katalog erkennbar. Erstellen Sie einen Katalogpreisvertrag anstelle dieses Privatpreisvertrags.',
      deletedModelsTip:
        'In diesem Vertrag werden gelöschte Preismodelle verwendet. Dies könnte sich auf Preisaktualisierungen auswirken. Weitere Informationen erhalten Sie von {{ email }}'
    },
    commonTerminology: {
      shippingPriceModel: 'Versandpreismodell',
      priceModel: 'Preismodell',
      contractName: 'Vertragsname',
      effectiveStartDate: 'Datum des Inkrafttretens',
      effectiveEndDate: 'Enddatum',
      discount: '{{ percentage }}% $t(common:commonWords.discount)',
      markup: '{{ percentage }}% $t(common:commonWords.markup)',
      name: 'Name',
      indefinite: 'Unbefristet',
      revision: 'Version',
      mcpSystem: 'das MCP-System',
      gracePeriod: 'Kulanzfrist',
      effectiveDates: 'Wirksamkeitsdaten (in UTC)',
      lastUpdated: 'Letzte Aktualisierung',
      seller: 'Verkäufer',
      seller_plural: 'Verkäufer',
      seller_oneOrMore: 'Verkäufer',
      buyer: 'Käufer',
      buyer_plural: 'Käufer',
      buyer_oneOrMore: 'Käufer',
      search: 'Suche',
      status: 'Status',
      status_plural: 'Status',
      status_oneOrMore: 'Status',
      type: 'Art',
      contractType: 'Vertragsart',
      contractType_plural: 'Vertragsarten',
      contractType_oneOrMore: 'Vertragsart(en)',
      hasGracePeriod: 'Kulanzfrist vorhanden',
      ProductId: 'Product-ID',
      currentVersion: 'Aktuelle Version'
    },
    commonWords: {
      authorize: 'Genehmigen',
      edit: 'Bearbeiten',
      discount: 'Ermäßigung',
      markup: 'Aufschlag',
      yesText: 'Ja',
      noText: 'Nein',
      onText: 'An',
      offText: 'Aus',
      blank: '',
      next: 'Weiter',
      back: 'Zurück',
      prev: 'Zurück',
      orText: 'oder',
      save: 'Speichern',
      cancel: 'Abbrechen',
      close: 'Schließen',
      countries: 'Länder',
      delete: 'Löschen'
    },
    unsavedChanges: {
      message: 'Bei Ihnen läuft gerade eine Maßnahme. Möchten Sie wirklich wegnavigieren?'
    },
    actions: {
      createNewContract: 'Neuen Vertrag erstellen',
      createButtonText: 'Erstellen'
    }
  },
  authorizationFlow: {
    errorTitle: "Erreur d'autorisation",
    title: 'Avant de commencer...',
    descriptionMessage1: 'Bienvenue dans le Gestionnaire des contrats!',
    descriptionMessage2:
      'Pour pouvoir accepter les conditions et recevoir les notifications, les utilisateurs qui se connectent pour la première fois doivent au préalable être ',
    descriptionMessage3: 'autorisés',
    descriptionMessage4: 'à bénéficier du service Notifications Hub.',
    descriptionMessage5: 'Vous serez redirigé(e) vers Marketplace Central dès que vous cliquerez sur',
    descriptionMessage6: 'le bouton ci-dessous.',
    authErrorMessage1: "Un problème est survenu lors de l'autorisation des notifications.",
    authErrorMessage2: "Pour obtenir de l'aide, contactez {{ FFS_EMAIL_ADDRESS }}."
  },
  investigateSku: {
    investigate: 'Verträge mit SKU suchen',
    getDetails: 'Beschaffe die Einzelheiten',
    noContractsFound: "Keine Verträge mit SKU '{{sku}}' gefunden",
    emptySearch: 'Suche darf nicht leer sein',
    contractsBySku: {
      header: 'Vertragsdetails für {{ sku }}',
      header2: 'Unternehmen mit Verträgen für {{sku}}',
      selectAContract: 'Wählen Sie einen Vertrag aus, um dessen Details und Preise zu dieser SKU anzuzeigen',
      viewContract: 'Vertrag anzeigen'
    }
  },
  appShell: {
    loudspeakerModal: {
      title: 'Neues im Vertragsmanager'
    }
  },
  breadcrumbs: {
    home: 'Verträge',
    contracts: 'Verträge',
    settings: 'Einstellungen',
    notFound: 'Nicht gefunden',
    bulkUpdate: 'Massenaktualisierung',
    viewSku: '{{ sku }}',
    contractChanges: 'Vertragsänderungen',
    createContracts: 'Verträge erstellen',
    viewContractRevision: 'Vertragsversion anzeigen',
    createProductAgreements: 'Erstellen Sie Produktvereinbarungen',
    productAgreementDetails: 'Details zu Produktvereinbarungen',
    notificationDetails: 'Benachrichtigungsdetails'
  },
  landingPage: {
    nameGreeting: 'Hallo {{ name }}, ',
    welcome: 'Willkommen bei Vertragsmanager',
    setupContracts:
      'Richten Sie Verträge mit Unternehmen ein und verwalten Sie sie, damit Sie leichter Produkte kaufen und verkaufen können.',
    todaysReport: 'Heutiger Bericht',
    statCardTotalLabel: 'Insgesamt',
    createContractsCard: {
      header: 'Verträge erstellen und verwalten',
      cardBody:
        'Richten Sie Verträge mit Käufern und Verkäufern ein und verwalten Sie sie. Fügen Sie SKUs zu Verträgen hinzu, definieren Sie Nutzungsbedingungen, Rabatte und andere Bedingungen.',
      numberOfBuyerAndSellers:
        '<0>{{ numberOfBuyers }}</0> Käuferverträge <2>{{ numberOfSellers }}</2> Verkäuferverträge',
      browseAll: 'Alle durchsuchen'
    },
    investigateSkusCard: {
      header: 'SKUs mit Verträgen untersuchen',
      cardBody:
        'Rufen Sie Vertragsdetails zu bestimmten SKUs auf, z. B. zur Art der Verträge, zu ihren Bedingungen und zum aktuellen Status.'
    },
    settingsCard: {
      header: 'Abonnements und Genehmigungen',
      manageSettings: 'Verwalten Sie verschiedene Einstellungen bezüglich Verträgen:',
      manageSubscriptions: 'Vertragsbenachrichtigungs-Abonnements verwalten',
      manageAutoApproval: 'Automatische Genehmigung für Käufer und Verkäufer festlegen',
      manageShippingContracts: 'Versandverträge aktivieren oder deaktiveren',
      manageSettingsButton: 'Einstellungen verwalten',
      manageSubscriptionsAndApprovalsButton: 'Abonnements konfigurieren'
    },
    notificationsCard: {
      manageNotifications: 'Siehe die Benachrichtigungen',
      manageSettings: 'Sie haben {} unbestätigte Benachrichtigungen {} über Preisänderungen oder Produktarchivierung.'
    },
    recentlyCreatedContractsCard: {
      header: 'Kürzlich erstellte Verträge'
    },
    manageContractsFor: 'Verträge verwalten für:',
    contracts: 'Verträge'
  },
  homePage: {
    header: {
      title: 'Meine Verträge'
    },
    searchBarPlaceholder: 'Suche nach Vertrag oder nach SKU im Vertrag',
    selectMultipleContracts: {
      label: 'Mehrere Verträge auswählen:',
      contractSelection: {
        contractTypeLabel: 'Vertragsart',
        selectSeller: 'Verkäufer auswählen',
        addSKUsTo: 'Dem ausgewählten Vertrag SKUs hinzufügen',
        addSKUsTo_plural: 'Den {{count}} ausgewählten Verträgen SKUs hinzufügen'
      }
    }
  },
  relationshipTable: {
    all: 'All',
    marketplace: 'B2B',
    fulfilmentNetwork: 'Fulfilment Network',
    searchTextFieldPlaceholder: 'Suche nach Konto-ID oder Name'
  },
  contractsTable: {
    effectiveContractPopover:
      'Eine effektive Preisstellung wird verwendet, um zu berechnen, wann ein Auftrag im MCP erteilt wird.',
    effectiveContractDetails:
      'Dieser Vertrag gilt für <1>{{ sku }}</1> zwischen Käufer <3>{{ buyer }}</3> und Verkäufer <5>{{ seller }}</5>',
    effectiveContractAdditionalDetails:
      'Damit ein Vertrag wirksam wird, muss er genehmigt werden und <1>höchste Priorität</1> <2/> haben.',
    onlyOneEffectiveContractDetails: 'Pro gültiger SKU, Käufer und Verkäufer kann immer nur ein Vertrag gelten.'
  },
  tableCards: {
    allContracts: 'Alle Verträge',
    needsReview: 'Muss überprüft werden',
    pendingApproval: 'Genehmigung ausstehend'
  },
  contractsFilter: {
    filterOffLabel: 'Zusätzliche Filter',
    filterOnLabel: 'Filtert nach',
    clearFilterLabel: 'Filter löschen'
  },
  contractInfo: {
    author: 'Autor',
    possibleCurrencies: 'Mögliche Währungen',
    infoSectionTitle: 'Vertragsdaten',
    pricingSectionTitle: 'Preisgestaltung',
    partiesSectionTitle: 'Beteiligte Parteien',
    detailsSectionTitle: 'Details',
    excludedCountriesTitle: 'Ausgeschlossene Länder',
    destinationCountriesTitle: 'Zielländer',
    latestRevisionTitle: 'Aktuellste Version',
    noOfItemsTitle: 'Anzahl der Artikel',
    remarksTitle: 'Anmerkungen',
    allCountries: 'Alle Länder',
    noGracePeriod: 'Keine Kulanzfrist',
    viewChanges: 'Änderungen anzeigen',
    viewAllCountries: 'Alle {{ count }} Länder anzeigen',
    updatedBy: 'Aktualisiert durch',
    priceModelsOnContract: 'Preismodell in diesem Vertrag',
    priceModelsOnContract_plural: 'Preismodelle in diesem Vertrag',
    defaultNameForPriceModel: 'Nicht definiert',
    statusChangedOn: 'Status geändert am <1>{{ dateTime, localDateTime }}</1>',
    notTheLatestRevisionText:
      'Diese Version ist schreibgeschützt, da es sich nicht um die aktuellste Version handelt. <1>Klicken Sie hier für die aktuellste Version</1>',
    readOnlyRevisionText:
      'Diese Seite ist schreibgeschützt, da es sich nicht um die aktuelle Version handelt. <1>Klicken Sie hier für die aktuellste Version</1>',
    gracePeriod: '{{ duration, localizeDurationSimple }}',
    gracePeriodDescription:
      'Die Kulanzfrist bezeichnet die Anzahl der Tage, die dem Empfänger bleiben, um Änderungen an einem Vertrag zu überprüfen, bevor er die Aktualisierungen genehmigt oder ablehnt. Nach Ablauf der Kulanzfrist wird der Vertrag automatisch genehmigt. Gibt es keine Kulanzfrist, wird der Vertrag nicht automatisch genehmigt.'
  },
  contractActions: {
    viewPricing: 'Preisgestaltung anzeigen',
    viewPricingToolTip:
      'Klicken Sie hier für weitere Informationen zu dieser Preisstellung sowie zu SKUs und Preisgestaltung',
    publish: 'An {{ otherParty }} zur Genehmigung veröffentlichen',
    approve: 'Genehmigen',
    approving: 'Genehmigen',
    terminate: 'Kündigen',
    terminateFinal: 'Ich verstehe. Kündigen',
    terminated: 'Gekündigt!',
    goToTerminatedRevision: 'Zu gekündigter Version wechseln',
    sendForApproval: 'Zur Prüfung und Genehmigung senden',
    saveAndUpdate: 'Speichern und aktualisieren',
    validateUniqueMessage: 'Der Wert muss sich vom vorigen Wert unterscheiden.',
    requiredMessage: 'Der Wert ist erforderlich',
    editGracePeriodModal: {
      title: 'Tage der Kulanzfrist bearbeiten',
      validateRangeMessage: 'Die Werte müssen zwischen 1 und 999 liegen.',
      checkboxLabel: 'Keine Kulanzfrist'
    },
    editModifierModal: {
      title: '{{modifierType}} % Betrag bearbeiten'
    },
    editNameModal: {
      title: 'Namen bearbeiten'
    },
    editSkusModal: {
      successful: 'Erfolgreich',
      bulkAddSkusMessage: '{{numberOfSkusAdded}} SKUs wurden hinzugefügt.',
      bulkAddDuplicateMessage: '{{duplicateSkus}} wurden übersprungen, da sie bereits ausgewählt sind.',
      toolTipNoChangesMessage: 'Es gibt keine Änderungen.',
      toolTipOneOrMoreChangesMessage: 'Sie benötigen 1 oder mehr Elemente.',
      toolTipNotCompatibleMessage: 'Das Preismodell ist mit den ausgewählten SKUs nicht kompatibel.',
      newRevisionActionMessage:
        'Eine neue Version des Vertrags wird erstellt und die andere Partei wird in Kenntnis gesetzt, um Maßnahmen zu ergreifen.',
      noProductsAddedMessage: 'Keine Produkte hinzugefügt',
      noProductsRemovedMessage: 'Keine Produkte entfernt',
      productsAddedMessage: 'Hinzugefügte Produkte: {{ products}}',
      productsRemovedMessage: 'Entfernte Produkte: {{ products}}',
      noChangesToPricingMessage: 'Keine Preisänderungen',
      priceModelChangedMessage: 'Preismodell geändert zu {{ priceModel }}',
      updating: 'Wird aktualisiert...',
      updated: 'Aktualisiert!',
      checkboxLabel: 'Preismodell ändern',
      title: 'Elemente bearbeiten'
    }
  },
  contractChanges: {
    metaData: {}
  },
  createContract: {
    messages: {
      missingFieldsMessage: 'Im Formular sind fehlende und/oder ungültige Felder vorhanden',
      contractNameMissingMessage: 'Vertragsname ist erforderlich.',
      modifierEmptyMessage: 'Modifier darf nicht leer sein.',
      endDateMissingMessage: 'Enddatum muss festgelegt werden.',
      createUnsuccessfulMessage: 'Sind Sie sicher, dass Sie diese Seite verlassen möchten?',
      onlyFulfillersAllowed: 'Aktuell können nur Fulfiller Verträge erstellen.',
      changingInitiatorWarning:
        'Wenn Sie den Initiator ändern, wird der Prozess zurückgesetzt. Klicken Sie zum Fortfahren auf OK.',
      markupPercentageNotGreaterThan0Message: 'Der Prozentsatz des Aufschlags muss größer als 0 sein.',
      discountPercentageMustBeBetween0And100: 'Der Prozentsatz des Nachlasses muss zwischen 0 und 100 liegen.',
      modifierAmountNotValidMessage: 'Der Modifier-Betrag ist ungültig.',
      setModifierHeader_discount: 'Nachlassprozentsatz festlegen',
      setModifierHeader_markup: 'Aufschlagprozentsatz festlegen',
      modifierTextFieldLabel_discount: 'Nachlassprozentsatz (%)',
      modifierTextFieldLabel_markup: 'Aufschlagprozentsatz (%)',
      requestPermissionsMessage: 'Wenden Sie sich an {{ email }}, um Zugriff zu erhalten.'
    },
    chooseTypeModal: {
      modalTitle: 'Was möchten Sie tun?',
      buyerTypeTitle: 'Ich möchte kaufen',
      sellerTypeTitle: 'Ich möchte verkaufen',
      sellerTypeDescription: 'Ich möchte einen Vertrag als Verkäufer erstellen',
      buyerTypeDescription: 'Ich möchte einen Vertrag als Käufer erstellen'
    },
    termEntryForm: {
      noEndDate: 'Kein Enddatum',
      notesToApproverLabel: 'Anmerkungen an den Genehmiger senden',
      additionalInformationLabel: 'Zusätzliche Informationen oder Kommentare',
      contractNameLabel: 'Vertrag benennen',
      contractDurationLabel: 'Legen Sie die Vertragsdauer fest',
      noCountrySelectedMessage: 'Wählen Sie mindestens ein Land aus.',
      chooseDestinationCountries: 'Wählen Sie Zielländer, in denen Ihr Vertrag gültig ist',
      allDestinationCountries: 'Alle Zielländer',
      allDestinationCountriesExcept: 'Alle Zielländer <1>außer</1>',
      individualDestinationCountries: 'Individuelle Zielländer'
    },
    validate: {
      buyerSelect: 'Wählen Sie einen Käufer aus\n',
      sellerSelect: 'Wählen Sie einen Verkäufer aus',
      skuSelect: 'Wählen Sie mindestens eine SKU aus',
      contractName: 'Geben Sie einen Vertragsnamen ein',
      pricingContractTypeSelect: 'Wählen Sie einen Preisvertragstyp aus',
      creationTypeSelect: 'Wählen Sie einen Vertrag zur Aktualisierung aus.'
    },
    getStarted: {
      sellerTitle: 'Einstieg',
      buyerTitle: 'Parteien auswählen',
      header: 'Parteien für Ihre Verträge auswählen',
      selectSeller: 'Den Verkäufer, der die Verträge erstellt, auswählen',
      selectBuyer: 'Den Käufer, der die Verträge erstellt, auswählen',
      selectBuyingFrom: 'Den Verkäufer auswählen, von dem gekauft wird',
      missingSellerMessage: 'Bitte wählen Sie den Verkäufer aus.',
      selectBuyers: 'Die Käufer, die die Verträge prüfen, auswählen',
      missingBuyerMessage: 'Bitte wählen Sie die Käufer aus',
      addGracePeriod: 'Kulanzfrist hinzufügen?',
      invalidGracePeriodSelection: 'Die Kulanzfrist sollte mehr als 0 Tage betragen.',
      gracePeriodLabel: 'Tage in der Kulanzfrist',
      gracePeriodDescription:
        'Gibt es eine Kulanzfrist, werden Aktualisierungen am Vertrag automatisch genehmigt, wenn der Prüfer nicht innerhalb dieser Frist antwortet.'
    },
    selectExistingContract: {
      header: 'Wählen Sie einen vorhandenen Vertrag aus, zu dem die Produkte hinzugefügt werden sollen'
    },
    selectCreationTypeStep: {
      title: 'Neuen oder vorhandenen Vertrag auswählen',
      existingContractsWarning:
        'Es gibt bereits einen oder mehrere Verträge mit {{ name }}. Sie können diese Verträge aktualisieren oder einen neuen Vertrag erstellen.',
      header: 'Neuen Vertrag erstellen oder vorhandenen Vertrag aktualisieren'
    },
    addProducts: {
      title: 'Produkte hinzufügen',
      header: 'Wählen Sie die Produkte aus, die Sie Ihren Verträgen hinzufügen möchten',
      searchAndAddProducts: 'Produkte suchen und hinzufügen',
      selectedProducts: 'Ausgewählte Produkte',
      mustSelectProductsMessage: 'Sie müssen Produkte auswählen, um sie Ihrem Vertrag hinzuzufügen',
      disabledListPriceMessage:
        'Die Auswahloption ist deaktiviert, weil für die SKU kein Katalogpreis eingerichtet ist.',
      bulkListPriceErrorMessage:
        'Die oben genannten SKUs konnten nicht hinzugefügt werden, weil für sie kein Katalogpreis definiert wurde oder es keine Entsprechung auf der Plattform gibt. Bitte prüfen Sie die Angaben und versuchen Sie es erneut.'
    },
    chooseNewOrExisting: {
      existingButtonTitle: 'Vorhandenen Vertrag aktualisieren',
      existingButtonDescription: 'SKUs zu einem vorhandenen Vertrag mit {{name}} hinzufügen',
      newButtonDescription: 'Neuen Vertrag mit {{name}} erstellen'
    },
    addContract: {
      title: 'Einen Katalogpreisvertrag für Produkte konfigurieren'
    },
    pricingContract: {
      title: 'Produktpreisvertrag',
      header: 'Einen Produktpreisvertrag für Produkte konfigurieren',
      description:
        'Legen Sie Katalogpreise oder Privatpreise für Produkte fest und fügen Sie einen optionalen Nachlass oder Aufschlag hinzu.',
      selectedPriceModel: 'Ausgewähltes Preismodell (nur eines verfügbar)',
      selectedPriceModel_plural: 'Ausgewähltes Preismodell',
      changePriceModel: 'Preismodell ändern',
      noPriceModelFoundMessage:
        'Keine Preismodelle gefunden. Kehren Sie zum vorherigen Schritt zurück und wählen Sie Ihre Produkte erneut aus oder versuchen Sie es mit einer neuen Suche. Wenden Sie sich bei Fragen an {{ contactEmailAddress }}',
      selectAPriceModel: 'Wählen Sie zuerst ein Preismodell für Ihre SKUs aus',
      mustSelectAPriceModel: 'Sie müssen Ihre Vertragsart auswählen und Ihren Produktpreisvertrag festlegen',
      mustSelectShippingAPriceModel: 'Sie müssen die Vertragsart auswählen und Ihren Versandvertrag einrichten'
    },
    shippingContract: {
      title: 'Versandvertrag',
      header: 'Einen Versandpreisvertrag für Produkte konfigurieren',
      description:
        'Versandpreis auf Versandkosten basierend festlegen, vorkonfigurierter Versandpreis oder kostenfreien Versand anbieten. Sie können nach Bedarf auch einen Nachlass oder Aufschlag hinzufügen.',
      noShippingPriceModelsFoundMessage:
        'Kein Versandpreismodell gefunden. Wenden Sie sich bei Fragen an {{ contactEmailAddress }}',
      shippingPriceModelSelectionLabel: 'Ausgewähltes Versandpreismodell'
    },
    result: {
      step_0: 'Benachrichtigungen werden an die in den Bedingungen aufgeführten Empfängerparteien gesendet.',
      step_1: 'Genehmiger werden Ihren Vertrag prüfen und genehmigen.',
      step_1_plural: 'Genehmiger werden Ihre Verträge prüfen und genehmigen.',
      step_2: 'Nach Genehmigung tritt der Vertrag in Kraft.',
      step_2_plural: 'Nach Genehmigung treten die Verträge in Kraft.',
      description: 'Ihr neuer Vertrag wurde erstellt und zur Genehmigung eingesendet',
      description_plural: 'Ihre neuen Verträge wurden erstellt und zur Genehmigung eingesendet',
      header: 'Erfolgreich!',
      whatHappensNext: 'Die nächsten Schritte',
      backToHomePage: 'Zurück zur Homepage',
      createNewContract: 'Neuen Vertrag erstellen',
      viewContract: 'Vertrag anzeigen',
      createNewContract_plural: 'Neue Verträge erstellen',
      duplicateContract: 'Diesen Vertrag duplizieren',
      duplicateContract_plural: 'Diese Verträge duplizieren',
      creatingContractsMessage: 'Wir erstellen diese Verträge für Sie',
      pleaseDoNotRefresh: 'Aktualisieren oder schließen Sie diese Seite nicht.',
      notAllSKUsHaveCatalogPricing:
        'Nicht für alle SKUs wurden Katalogpreise festgelegt. Legen Sie sie fest und versuchen Sie es erneut.',
      notHaveCorrectPermissions: 'Sie haben nicht die richtigen Berechtigungen, um diesen Vertrag zu erstellen.',
      somethingWentWrong: 'Es ist ein Problem aufgetreten.',
      contactInformation: 'Kontaktieren Sie {{ contactEmailAddress }}, wenn Sie Hilfe benötigen.',
      errorCreatingContractMessage:
        'Bei der Erstellung des Vertrags ist ein Fehler aufgetreten. Bleibt das Problem bestehen, wenden Sie sich bitte an {{ contactEmailAddress }}'
    },
    review: {
      title: 'Überprüfen',
      general: 'Allgemein',
      generalDetails: 'Allgemeine Details zu {{name}}',
      buyerHeader: 'Für {{buyer}}',
      header: 'Prüfen und schließen Sie Ihren Vertrag ab',
      header_plural: 'Prüfen und schließen Sie Ihre Verträge ab',
      description:
        'Prüfen Sie Ihren Vertrag und senden Sie ihn zur Genehmigung ein. Nach Genehmigung tritt Ihr Vertrag in Kraft.',
      description_plural:
        'Prüfen Sie Ihre Verträge und senden Sie sie zur Genehmigung ein. Nach Genehmigung treten Ihre Verträge in Kraft.',
      contractDetailsLabel: 'Vertragsdetails',
      contractDetailsLabel_plural: 'Vertragsdetails',
      noContractBeingSetupMessage: 'Sie müssen mindestens einen Vertrag erstellt haben.',
      productsInContract: 'Produkte im Vertrag',
      productsToAddContract: 'Produkte, die zum Vertrag hinzugefügt werden sollen',
      productsInContract_plural: 'Produkte in Verträgen'
    }
  },
  terminationWarnings: {
    header: 'Wenn Sie fortfahren …',
    warning_0: 'Eine neue Version mit dem Status GEKÜNDIGT wird erstellt',
    warning_1: 'Für diesen Vertrag können keine weiteren Änderungen oder Versionen durchgeführt werden.',
    warning_1_0: '<0>Daher muss ein neuer Vertrag erstellt werden, um diesen Vertrag wieder zu erstellen.</0>',
    warning_1_1:
      '<0>Wenn es sich hier um einen Katalogpreisvertrag handelt, werden sich Aktualisierungen am Katalogpreis <1>nicht</1> auf diesen Vertrag auswirken.</0>',
    warning_2: 'Der Vertrag tritt zwischen den beiden Parteien nicht in Kraft.',
    warning_2_0:
      '<0>Wenn Käufer A von Verkäufer B kauft, ist Käufer A <1>nicht mehr in der Lage</1>, mit diesem Vertrag zu kaufen.</0>',
    warning_3: 'Dieser Vertrag wird nicht mehr auf der Homepage angezeigt.',
    warning_4: 'Diese Maßnahme kann nicht rückgängig gemacht werden.'
  },
  errors: {
    error_general: 'Fehler',
    error_notFound: 'Nicht gefunden',
    error_create_permission: 'Sie haben keine Erlaubnis zum Erstellen von Verträgen',
    error_forbidden: 'Sie haben keine Berechtigung, diese Seite zu öffnen.',
    error_unknown: 'Beim Laden der Nutzeridentitäten ist ein Fehler aufgetreten.',
    withCommentAndStatusCode: 'Fehler: {{serviceComment}} ({{statusCode}})'
  },
  settings: {
    header: 'Einstellungen',
    subscriptions: {
      header2_contractNotification:
        'Verwalten Sie Ihre Abonnements für E-Mail-Benachrichtigungen, wenn die Archivierung der Produktversion oder Preisänderungen ausgelöst werden.',
      header: 'Meine Abonnements',
      header2:
        'Verwalten Sie Ihre Abonnements mit E-Mail-Benachrichtigungen, wenn ein Vertrag erstellt oder aktualisiert wird. ',
      header3: 'Wählen Sie eine Identität und die Bedingungen, die Sie abonnieren möchten.',
      multipleRolesWarning_fulfillers:
        'Wenn Sie Zugang sowohl zu Verkäufern als auch Käufern haben, werden nur die Abonnements auf dieser Seite angezeigt, die Sie beim <1>Verkäufer</1> haben.',
      multipleRolesWarning_merchants:
        'Wenn Sie Zugang sowohl zu Verkäufern als auch Käufern haben, werden nur die Abonnements auf dieser Seite angezeigt, die Sie beim <1>Käufer</1> haben.',
      fetchingMessage: 'Ihre Abonnements werden abgerufen …',
      fetchinErrorMessage: 'Beim Abrufen Ihrer aktuellen Abonnements ist ein Fehler aufgetreten',
      searchBy_fulfillers: 'Nach Verkäufernamen suchen',
      searchBy_merchants: 'Nach Käufernamen suchen',
      manageSettingsFor: 'Einstellungen verwalten für',
      addSubscriptionButton_fulfillers: 'Mehrere Verkäufer konfigurieren',
      addSubscriptionButton_merchants: 'Mehrere Käufer konfigurieren',
      addSubscriptionTooltip_fulfillers: 'Abonnements für mehrere Verkäufer gleichzeitig konfigurieren',
      addSubscriptionTooltip_merchants: 'Abonnements für mehrere Käufer gleichzeitig konfigurieren',
      subscriptionNotAllowedForNonCimpressOwned: 'Nur Benutzer eines Cimpress-Kontos können sich als Käufer anmelden',
      subscriptionAccordion: {
        notifiedOnAll_fulfillers: 'Benachrichtigt am: <1>Alle Verkäufer</1>',
        notifiedOnAll_merchants: 'Benachrichtigt am: <1>Alle Käufer</1>',
        notifiedOn_fulfillers: 'Benachrichtigt am: <1>{{ count }} Verkäufer</1>',
        notifiedOn_fulfillers_plural: 'Benachrichtigt am: <1>{{ count }} Verkäufer</1>',
        notifiedOn_merchants: 'Benachrichtigt am: <1>{{ count }} Käufer</1>',
        notifiedOn_merchants_plural: 'Benachrichtigt am: <1>{{ count }} Käufer</1>',
        noActiveNotifications: 'Keine aktiven Benachrichtigungen',
        noChangesWarning: 'Sie haben an Ihrer aktuellen Konfiguration keine Änderungen vorgenommen',
        noSelectionWarning_fulfillers: 'Sie haben keinen Einzelverkäufer ausgewählt, den Sie abonnieren möchten',
        noSelectionWarning_merchants: 'Sie haben keinen Einzelkäufer ausgewählt, den Sie abonnieren möchten'
      },
      actions: {
        subscribeToAll_sellers_contractNotification: 'Abonnieren Sie alle Verkäufer',
        unsubscribeToAll_sellers_contractNotification: 'Von allen Verkäufern abbestellen',
        subscribeToIndividual_sellers_contractNotification: 'Abonnieren Sie einzelne Verkäufer',
        subscribeToAll_buyers_contractNotification: 'Abonnieren Sie alle Käufer',
        unsubscribeToAll_buyers_contractNotification: 'Von allen Käufern abbestellen',
        subscribeToIndividual_buyers_contractNotification: 'Abonnieren Sie einzelne Käufer',

        subscribeToAll_fulfillers: 'Alle Verkäufer abonnieren',
        unsubscribeToAll_fulfillers: 'Abonnements von allen Verkäufern kündigen',
        subscribeToIndividual_fulfillers: 'Einzelverkäufer abonnieren',
        subscribeToAll_merchants: 'Alle Käufer abonnieren',
        unsubscribeToAll_merchants: 'Abonnements von allen Käufern kündigen',
        subscribeToIndividual_merchants: 'Einzelkäufer abonnieren'
      },
      selectLabel_fulfillers: 'Verkäufer auswählen',
      selectLabel_merchants: 'Käufer auswählen',
      subscriptionModal: {
        selectIdentities_fulfillers: 'Verkäufer zum Konfigurieren auswählen',
        selectIdentities_merchants: 'Käufer zum Konfigurieren auswählen',
        selectAllIdentities_fulfillers: 'Alle Verkäufer auswählen',
        selectAllIdentities_merchants: 'Alle Käufer auswählen',
        firstStepModalTitle_fulfillers: 'Mehrere Ihrer Verkäufer gleichzeitig konfigurieren',
        firstStepModalTitle_merchants: 'Mehrere Ihrer Käufer gleichzeitig konfigurieren',
        secondStepModalTitle_fulfillers: 'Abonnementart der ausgewählten Verkäufer auswählen',
        secondStepModalTitle_merchants: 'Abonnementart der ausgewählten Käufer auswählen',
        infoMessage_fulfillers:
          'Ihre aktuellen Abonnements für Einzelverkäufer (wenn vorhanden) werden durch diese Änderung überschrieben',
        infoMessage_merchants:
          'Ihre aktuellen Abonnements für Einzelkäufer (wenn vorhanden) werden durch diese Änderung überschrieben'
      }
    },
    autoApprovals: {
      header: 'Meine automatischen Genehmigungen',
      header2: 'Genehmigt Verträge automatisch, wenn',
      addButton: 'Automatische Genehmigung hinzufügen',
      filterInputLabel: 'Nach Namen filtern',
      approvalNeeded: 'Genehmigung erforderlich von',
      createdByLabel: 'erstellt oder geändert durch',
      fetchingMessage: 'Einstellungen zur automatischen Genehmigung werden abgerufen …',
      select_fulfillers: 'Verkäufer auswählen',
      select_fulfillers_plural: 'Verkäufer auswählen',
      select_merchants: 'Käufer auswählen',
      select_merchants_plural: 'Käufer auswählen'
    },
    features: {
      header: 'Funktionen',
      loading: {
        message: 'Funktionen werden abgerufen …'
      },
      shippingContracts: {
        label: 'Versandverträge aktivieren'
      },
      terminatedContracts: {
        label: 'Gekündigte Verträge anzeigen'
      },
      languageSelection: {
        label: 'Sprache'
      }
    }
  },
  createRelationships: {
    title: 'Verkäufer Käufer',
    selectBuyer: 'Wählen Sie den Käufer aus',
    selectSeller: 'Wählen Sie den Verkäufer',
    createRelationTitle: 'Creëer relaties',
    enterSunsetPeriod: 'Produktarchivierung',
    enterPriceLockin: 'Geben Sie die Preissperrfrist ein',
    sunsetPeriodLabel: 'Tage',
    lockinPeriodLabel: 'Tage',
    createRelationButtonText: 'Creëer relaties',
    buyerAndSellerAccountIdSameErrorMessage: 'Käufer und Verkäufer können in einer Beziehung nicht gleich sein',
    agreementTitle: 'Verzendovereenkomst',
    agreementSubTitle: 'Produkte und Preise',
    agreementNegotiationTitle:
      'Wie viele Tage im Voraus erklären Sie sich damit einverstanden, dass Sie den Käufer benachrichtigen:',
    shippingAgreementTitle: 'Versand',
    shippingAgreementOption1: 'Der Käufer bezahlt den Spediteur direkt',
    shippingAgreementOption2:
      'Der Käufer zahlt dem Verkäufer die Versandkosten, die während des Versands anfallen, als Versandpreis',
    shippingAgreementOption3: 'Der Käufer zahlt dem Verkäufer einen definierten Versandpreis',
    shippingAgreementOption4: 'Kostenloser Versand',
    notificationTitle: 'Benachrichtigung',
    notificationText: 'Lassen Sie uns wissen, wenn das Gegenteil den Preis ändert oder ein Produkt archiviert',
    notificationEmailText: 'Geben Sie eine E-Mail pro Zeile oder durch Komma getrennt an',
    helptext: 'Geben Sie eine E-Mail pro Zeile oder durch Komma getrennt an'
  },
  viewRelationships: {
    messages: {
      noVersionsFound: 'Hier sind keine Versionen vorhanden.'
    }
  },
  productAgreementsPage: {
    mainHeading: '{{ businessOne }} und {{ businessTwo }}',
    productsHeading: 'Produkte',
    buyerSubHeading: 'Kauf von {{ businessTwo }}',
    sellerSubHeading: 'Verkauf an {{ businessTwo }}',
    terms: 'Bedingungen',
    productId: 'Produkt-ID',
    priceLockinSectionOne: '{{seller}} gaat ermee akkoord {{buyer}} ten minste te geven',
    priceLockinSectionTwo: ' Tage vor Ankündigung des Preises.',
    sunsetSectionOne: '{{seller}} gaat ermee akkoord {{buyer}} ten minste te geven',
    sunsetSectionTwo: ' Tage vor der Archivierung eines Produkts, das {{buyer}} kauft.',
    noRelationshipAsBuyer:
      '{{ businessOne }} hat keine Beziehung hergestellt, von der man kaufen kann {{ businessTwo }}. Wenden Sie sich bitte {{ businessTwo }} um dies einzurichten.',
    noRelationshipAsSeller: '{{ businessOne }} hat keine Beziehung zum Verkauf hergestellt {{ businessTwo }}.',
    save: 'Speichern Sie die Änderungen',
    edit: 'Bearbeiten Sie den Wert',
    cancel: 'Bearbeitung abbrechen',
    deletePriceLock: 'Preissperre löschen',
    deletePriceLockConfirmation: 'Möchten Sie die Preissperre für {{ productId }} wirklich löschen?',
    addProducts: 'Produkte hinzufügen',
    search: 'Suche',
    selectVersions: 'Versionen auswählen',
    view: 'Aussicht',
    columnNames: {
      productId: 'PRODUKT ID',
      productName: 'PRODUKTNAME',
      versions: 'VERSIONEN',
      view: 'AUSSICHT',
      priceLock: 'Preissperre bis'
    },
    versionDeleteConfirmation1: 'Möchten Sie die Version {{versions}} entfernen?',
    versionDeleteConfirmation2: 'Das heisst:',
    versionDeleteConfirmation3: 'Sie kaufen diese Version nicht mehr.',
    versionDeleteConfirmation4: 'Sie können nicht benachrichtigt werden, wenn die Version archiviert wird.',
    priceChangeTitle: 'Preisänderung',
    productArchivingTitle: 'Produktarchivierung',
    shippingTitle: 'Versand',
    contactForShippingAgreement: `Wenden Sie sich bitte {{ contactEmailAddress }} wenn Sie Hilfe benötigen.`,
    productInformation: 'Produktinformation',
    buyerNotCimpressOwnedMessage: `{{accountName}} ist auf der Plattform nicht als Käufer aktiviert!`,
    buyerNotCimpressOwnedMessageContactUs: `Bitte wenden Sie sich an den Plattform-Support, wenn {{accountName}} in Platform kauft.`
  },
  notificationDetails: {
    acknowledge: 'Bestätigen',
    contactSeller: 'Bitte kontaktieren Sie den Verkäufer, wenn Sie Fragen haben: Kontakt',
    isArchiving: 'archiviert',
    startingFrom: 'Ab',
    noLongerAvailable: 'Sie kaufen wird nicht mehr verfügbar sein.',
    decidedToArchive: 'hat beschlossen, zu archivieren',
    unavailableToBuyFrom: 'und steht Ihnen ab dem Kauf nicht mehr zur Verfügung',
    nonRevocableAction: 'Dies ist eine nicht widerrufliche Aktion von',
    ensureChangeBefore:
      'Stellen Sie daher bitte sicher, dass Sie zuvor die erforderlichen Änderungen vorgenommen haben',
    noDisruption: 'Damit Ihre Endkunden nicht gestört werden.',
    acknowledgedMessage: 'bestätigte diese Nachricht am',
    for: 'zum',
    ofThe: 'des',
    theVersion: 'die Version',
    notificationAlreadyAcknowledged: 'Die Benachrichtigung wurde bereits von bestätigt',
    priceUpdateEffectiveOn: 'Preisaktualisierung ist ab wirksam',
    isUpdatingPricing: 'aktualisiert die Preise für',
    products: 'Produkte',
    priceChangeAutomaticApply: `Diese Preisänderung wird automatisch am {{ date }} wirksam. Bitte kontaktieren Sie den Verkäufer, wenn Sie Fragen haben.`,
    asPerYourAgreement: `Gemäß Ihrer Vereinbarung mit {{ sellerName }} gelten ab dem {{ date }} nach der obligatorischen {{ priceUpdateDays }} Tag (en) der Benachrichtigungsfrist neue Preise für die unten aufgeführten Produkte.`,
    acknowledgementMessage: `{{ acknowledgedBy }} hat diese Nachricht am {{ acknowledgedAt }} für {{ buyerName }} bestätigt`,
    oldPrices: 'Alte Preise',
    newPrices: 'Neue Preise',
    versionDifferenceHeading: `Unterschied zwischen der Sunset-Version {{sunsetVersion}} und der aktuellen / neuesten Version`,
    versionDifferenceNote: `(Änderungen werden blau hervorgehoben)`
  },
  notifications: {
    header: 'Benachrichtigungen',
    searchByProductId: 'Produkt ID',
    startDate: 'Anfangsdatum',
    endDate: 'Endtermin',
    all: 'Alles',
    productArchiving: 'Produktarchivierung',
    priceChange: 'Preisänderung',
    today: 'Heute',
    others: 'Andere',
    acknowledgedBy: 'Acknowledged By:  ',
    fetchingMessage: 'Abrufen Ihrer Benachrichtigungen ...',
    notificationsNotAvailable: 'Keine Benachrichtigungen verfügbar',
    notificationItems: {
      PRICECHANGE: 'Neuer Preis für ',
      SUNSET: 'Archivierung einer Version von '
    },
    notificationMessage: {
      PRICECHANGE: 'Ab {} gibt es einen neuen Preis für {}',
      SUNSET: 'Ab {} ist die Version von {}, die Sie kaufen, nicht mehr verfügbar'
    }
  },
  errorRobot: {
    error:
      'Bitte aktualisieren Sie die Seite, um es erneut zu versuchen. Wenn das Problem weiterhin besteht, wenden Sie sich bitte an'
  }
};
