import { get } from 'lodash';
import { makeRequest, buildOptions } from './ServiceHelpers';

const endpointUrl = process.env.REACT_APP_ACCOUNT_URL;

export const getAccount = accountId => {
  const options = buildOptions();
  return makeRequest(endpointUrl, `/api/v1/accounts/${accountId}`, options, 'getting accounts');
};

export const getAccounts = () => {
  const options = buildOptions({ hal: true });
  return makeRequest(endpointUrl, `/api/v1/accounts`, options, 'getting accounts').then(halResponse => {
    return get(halResponse, '_embedded.item').map(account => ({
      id: account.accountId,
      name: account.name,
      cimpressOwned: account.cimpressOwned
    }));
  });
};
