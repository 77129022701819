import { makeRequest, buildOptions } from './ServiceHelpers';

const endpointUrl = process.env.REACT_APP_SHIPPING_PRICE_URL;

export const getListPriceShippingModels = ({ type, id }) => {
  const options = buildOptions();

  return makeRequest(
    endpointUrl,
    `/api/v1/listPrices?sellerType=${type}&sellerId=${id}`,
    options,
    'getting shipping catalog price'
  );
};

export const getPriceModel = id => {
  const options = buildOptions();

  return makeRequest(endpointUrl, `/api/v1/priceModels/${id}`, options, `getting shipping price model for ${id}`);
};

export const getShippingPriceModel = ({ type, id }) => {
  return getListPriceShippingModels({ type, id })
    .then(listPrices => {
      if (listPrices.length === 0) {
        return;
      }

      const pmId = listPrices[0].priceModelId;
      return getPriceModel(pmId);
    })
    .catch(err => {
      throw err;
    });
};
