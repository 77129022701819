import ShippingTermCard from '../../../components/contracts/createContract/SetupShippingPricingTermStep/ShippingTermCard';
import React, { useEffect, useState } from 'react';
import { css } from 'react-emotion';
import { useTranslation } from 'react-i18next';

const singleCard = css`
  margin: 0 auto;
  max-width: 600px;
`;

function ShippingContractType(props) {
  const [termTypeSelected, setTermTypeSelected] = useState();
  const [termHasValidationError, setValidationError] = useState(true);
  const [createContractDataLocal, setCreateContractDataLocal] = useState();
  const { setCreateContractData, createContractData } = props;
  const { t } = useTranslation();

  const checkTypeSelected = termTypeSelected => {
    setTermTypeSelected(termTypeSelected);
  };

  const validateForm = termHasValidationError => {
    setValidationError(termHasValidationError);
  };

  useEffect(
    () => {
      setCreateContractDataLocal(props.createContractData);
    },
    [createContractData]
  );

  const createTerm = termInfo => {
    setCreateContractData({
      ...createContractData,
      shippingPricingTerm: termInfo,
      isShippingContractTypeStepValid: !termHasValidationError
    });
  };

  const getErrorMessage = () => {
    if (termHasValidationError) {
      const reason = termTypeSelected
        ? t('createContract:messages.missingFieldsMessage')
        : t('createContract:pricingContract.mustSelectShippingAPriceModel');
      return <span style={{ color: '#f3a654' }}>{reason}</span>;
    } else {
      return null;
    }
  };

  const renderContractTypes = () => {
    return createContractDataLocal ? (
      <div>
        <ShippingTermCard
          className={{ singleCard }}
          shippingPricingTerm={createContractDataLocal.shippingPricingTerm}
          flow={createContractDataLocal}
          onCreateTerm={createTerm}
          skus={createContractDataLocal.selectedSkus}
          validateForm={validateForm}
          checkTypeSelected={checkTypeSelected}
        />
      </div>
    ) : (
      <div />
    );
  };
  return (
    <div>
      {getErrorMessage()}
      {renderContractTypes()}
    </div>
  );
}

export default ShippingContractType;
