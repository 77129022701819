import React, { useState, useEffect } from 'react';
import { Button } from '@cimpress/react-components';
import { connect } from 'react-redux';
import Loading from '../../Loading';
import { useTranslation } from 'react-i18next';
import { toMonthDayYearDisplay, toDateTimeString } from '../../../utilities/dateHelper';
import { selectors as identitySelectors } from '../../../reducers/identities/index';
import '../../../styles/NotificationDetailsPage.css';
import { isManageContractPermission } from '../../../utilities/checkPermission';
import { VersionsDiffChecker } from '@cimpress-technology/react-product-experience-rules';
import { auth } from '../../../utils/auth';

function SunsetNotificationDetails(props) {
  const { t } = useTranslation();
  const { notificationDetails, isLoading, onAcknowledge, isAdmin, manageAccountId } = props;
  const onAcknowledgeSunsetNotification = async () => onAcknowledge();
  const [doesUserHasManageContractPermission, setManageContractPermission] = useState(false);

  useEffect(() => {
    var isManageContractPermissionForUser = isManageContractPermission(manageAccountId, props.userPermissions);
    setManageContractPermission(isManageContractPermissionForUser);
  }, []);

  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="notificationDetailBackground">
          <div className="row">
            <div className="col-md-10">
              <h2>
                <b>
                  {notificationDetails.sellerName} {t('notificationDetails:isArchiving')}&nbsp;
                  {t('notificationDetails:theVersion')} {notificationDetails.sunset.version}&nbsp;
                  {t('notificationDetails:ofThe')} {notificationDetails.sunset.productId}
                </b>
              </h2>
            </div>
            <div className="col-md-2">
              <div
                style={
                  (isAdmin || doesUserHasManageContractPermission) && !notificationDetails.isAck
                    ? { display: 'block', paddingLeft: '30px', paddingTop: '15px' }
                    : { display: 'none' }
                }>
                <Button type="primary" onClick={onAcknowledgeSunsetNotification}>
                  {t('notificationDetails:acknowledge')}
                </Button>
              </div>
            </div>
          </div>
          <br />
          <div>
            {t('notificationDetails:startingFrom')} {toMonthDayYearDisplay(notificationDetails.sunset.sunsetEndsOn)},{' '}
            {notificationDetails.sunset.version
              ? `${t('notificationDetails:theVersion')} ${notificationDetails.sunset.version} ${t(
                  'notificationDetails:ofThe'
                )} ${notificationDetails.sunset.productId}`
              : `${notificationDetails.sunset.productId}`}{' '}
            {t('notificationDetails:noLongerAvailable')}
          </div>
          <hr className="hrNotificationDetail" />
          <div>
            {notificationDetails.sellerName} {t('notificationDetails:decidedToArchive')}{' '}
            {notificationDetails.sunset.version
              ? `${t('notificationDetails:theVersion')} ${notificationDetails.sunset.version} ${t(
                  'notificationDetails:ofThe'
                )} ${notificationDetails.sunset.productId}`
              : `${notificationDetails.sunset.productId}`}{' '}
            {t('notificationDetails:unavailableToBuyFrom')}{' '}
            {toMonthDayYearDisplay(notificationDetails.sunset.sunsetEndsOn)}.
          </div>
          <br />
          <div>
            {t('notificationDetails:nonRevocableAction')} {notificationDetails.sellerName},{' '}
            {t('notificationDetails:ensureChangeBefore')}{' '}
            {toMonthDayYearDisplay(notificationDetails.sunset.sunsetEndsOn)}, {t('notificationDetails:noDisruption')}
          </div>
          <br />
          <div>
            {t('notificationDetails:contactSeller')} {notificationDetails.sellerName}
          </div>
          <br />
          <div>
            {notificationDetails.isAck
              ? `${notificationDetails.acknowledgedBy} ${t(
                  'notificationDetails:acknowledgedMessage'
                )} ${toDateTimeString(notificationDetails.acknowledgedAt)} ${t('notificationDetails:for')} ${
                  notificationDetails.buyerName
                }`
              : ''}
          </div>
          <br />
          <br />
          <div style={{ display: 'flex' }}>
            <h5>
              {t('notificationDetails:versionDifferenceHeading', {
                sunsetVersion: notificationDetails.sunset.version
              })}
            </h5>
            <i style={{ fontSize: '12px', margin: '11px 0px 0px 5px' }}>
              {t('notificationDetails:versionDifferenceNote')}
            </i>
          </div>
          <hr className="hrNotificationDetailPriceChange" />
          <div className="row">
            <VersionsDiffChecker
              version1={notificationDetails.sunset.version}
              version2={'current'}
              accessToken={auth.getAccessToken()}
              productID={notificationDetails.sunset.productId}
            />
          </div>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = state => ({
  isAdmin: identitySelectors.isUserAdmin(state),
  userPermissions: identitySelectors.getPermissionsPayload(state)
});

export default connect(mapStateToProps)(SunsetNotificationDetails);
