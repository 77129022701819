import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { CONTRACT_RESOURCE_TYPE } from '../../../contractV1_src/constants/subscriptionConstants';
import {
  ALL,
  getResourceTypes,
  INDIVIDUAL,
  NONE,
  PRODUCT_NOTIFICATION
} from '../../../constants/subscriptionConstants';

import { MERCHANTS } from '../../../constants/transactorType';
import delay from 'lodash/delay';
import Spinner from '@cimpress/react-components/lib/shapes/Spinner';
import Subscription from './Subscription';
import {
  actions as productAgreementActions,
  selectors as productAgreementSelectors
} from '../../../reducers/productagreements';
import { actions as subsActions, selectors as subsSelectors } from '../../../reducers/subscriptions/index';
import { actions as merchantAction, selectors as merchantSelector } from '../../../reducers/contractV1';
import {
  actions as subsV1Actions,
  selectors as subsV1Selectors
} from '../../../reducers/contractV1/subscriptions/index';
import { actions as accountActions } from '../../../reducers/account';
import AuthorizationFlow from './AuthorizationFlow';
import { getSellerAccountMapOfUser } from '../../../utilities/commonHelper';
import {
  getSubscriptionIds,
  getMerchantsByAccountId,
  getMerchantSubscription
} from '../../../contractV1_src/CommonUtil/subscription';
const getSellerAccountOptions = sellerAccountsMap => {
  function accountNameComparer(a, b) {
    if (a.label < b.label) {
      return -1;
    }
    if (a.label > b.label) {
      return 1;
    }
    return 0;
  }
  return Object.keys(sellerAccountsMap)
    .map(key => {
      const { accountId, name } = sellerAccountsMap[key];
      return { label: name, value: accountId };
    })
    .sort(accountNameComparer);
};
const getSubscribedParties = (sellerAccountsMap, subscriptions) => {
  if (!subscriptions || !sellerAccountsMap) return [];
  const { subscriptionType, counterParties } = subscriptions;
  if (subscriptionType === INDIVIDUAL) {
    return counterParties.map(({ counterPartyId }) => {
      return {
        label: sellerAccountsMap[counterPartyId] ? sellerAccountsMap[counterPartyId].name : null,
        value: counterPartyId
      };
    });
  }
  return [];
};
const getInitialState = () => {
  return {
    userAccount: undefined,
    loading: true,
    sellerAccountsMap: undefined,
    sellerAccountOptions: [],
    isFetchingRelations: false,
    isFetchingSubscription: true,
    merchantSubscriptions: {}
  };
};
function SubscriptionSettings(props) {
  const { t } = useTranslation();
  const {
    identityType,
    notificationType,
    relationships,
    contractSubscriptions,
    productSubscriptions,
    manageAccountId,
    merchantsMap,
    contractV1Subscriptions
  } = props;
  const [state, setState] = useState(getInitialState);
  useEffect(() => {
    async function fetAccount() {
      const { payload } = await props.fetchAccountById(manageAccountId);
      setState(prevState => ({ ...prevState, userAccount: payload, loading: false }));
    }
    // props.fetchRelationships();
    if (manageAccountId) {
      props.fetchMerchants();
      fetAccount();
    }
  }, []);
  useEffect(
    () => {
      if (relationships) {
        const relations = Object.values(relationships);
        const sellerAccountsMap = getSellerAccountMapOfUser(relations, manageAccountId);
        const sellerAccountOptions = getSellerAccountOptions(sellerAccountsMap);
        setState(prevState => {
          return { ...prevState, sellerAccountsMap, sellerAccountOptions, isFetchingRelations: false };
        });
      }
    },
    [relationships]
  );
  useEffect(
    () => {
      // Fetch V1 subscription.
      props.fetchAllV1Subscriptions(CONTRACT_RESOURCE_TYPE);
      const resourceTypes = getResourceTypes(notificationType);
      resourceTypes.forEach(resourceType => {
        props.fetchAllSubscriptions(resourceType);
      });
      delay(() => {
        setState(prevState => {
          return { ...prevState, isFetchingSubscription: false };
        });
      }, 3000);
    },
    [notificationType]
  );
  useEffect(
    () => {
      if (merchantsMap && contractV1Subscriptions) {
        const merchants = getMerchantsByAccountId(merchantsMap, manageAccountId);
        const merchantSubscriptions = getMerchantSubscription(contractV1Subscriptions, merchants);

        setState(prevState => ({ ...prevState, merchantSubscriptions }));
      }
    },
    [manageAccountId, merchantsMap, contractV1Subscriptions]
  );
  const {
    loading,
    userAccount,
    sellerAccountsMap,
    sellerAccountOptions,
    isFetchingRelations,
    isFetchingSubscription,
    merchantSubscriptions
  } = state;

  const getCurrentSubscriptionIds = id => {
    const previousSubs =
      notificationType === PRODUCT_NOTIFICATION ? productSubscriptions[id] : contractSubscriptions[id];
    const subscriptionType = previousSubs && previousSubs.subscriptionType;

    switch (subscriptionType) {
      case ALL:
        return notificationType === PRODUCT_NOTIFICATION
          ? previousSubs.counterParties.reduce(
              (allSubs, counterParty) => allSubs.concat(counterParty.subscriptionIds),
              []
            )
          : [previousSubs.counterParties.subscriptionId];
      case INDIVIDUAL:
        return notificationType === PRODUCT_NOTIFICATION
          ? previousSubs.counterParties.reduce(
              (allSubs, counterParty) => allSubs.concat(counterParty.subscriptionIds),
              []
            )
          : previousSubs.counterParties.map(({ subscriptionId }) => subscriptionId);
      case NONE:
      default:
        return [];
    }
  };
  const onSubscribeByRelationship = (id, type, counterPartyIds) => {
    const previousSubs = getCurrentSubscriptionIds(id);
    const { subscribe } = props;
    subscribe(previousSubs, INDIVIDUAL, id, type, notificationType, counterPartyIds);
  };
  const onSubscribeByIdentity = (id, type) => {
    const previousSubs = getCurrentSubscriptionIds(id);
    const { subscribe, subscribeV1Notification } = props;
    // Subscribe v1 notification.
    const subscriptionIds = getSubscriptionIds(merchantSubscriptions);
    const merchants = getMerchantsByAccountId(merchantsMap, manageAccountId);
    const merchantIds = merchants.map(({ merchantId }) => merchantId);
    subscribeV1Notification(subscriptionIds, ALL, merchantIds, MERCHANTS, notificationType);
    // Subscribe V2 notification.
    subscribe(previousSubs, ALL, id, type, notificationType);
  };

  const onUnsubscribeByIdentity = (id, type) => {
    const previousSubs = getCurrentSubscriptionIds(id);
    const { unsubscribe, unsubscribeV1Notification } = props;
    //Unsubscribe v1 notification.
    const subscriptionIds = getSubscriptionIds(merchantSubscriptions);
    subscriptionIds.length && unsubscribeV1Notification(subscriptionIds, notificationType);
    // Unsubscribe in v12
    unsubscribe(previousSubs, id, type, notificationType);
  };
  const onConfigureIndividual = (id, type, selectedNotification, counterPartyIds) => {
    switch (selectedNotification) {
      case NONE:
        onUnsubscribeByIdentity(id, type);
        break;
      case ALL:
        onSubscribeByIdentity(id, type);
        break;
      default:
        onSubscribeByRelationship(id, type, counterPartyIds);
    }
  };
  const subscriptions =
    notificationType === PRODUCT_NOTIFICATION
      ? productSubscriptions && productSubscriptions[manageAccountId]
      : contractSubscriptions && contractSubscriptions[manageAccountId];
  const subscriptionType = (subscriptions && subscriptions.subscriptionType) || NONE;
  const subscribedParties = getSubscribedParties(sellerAccountsMap, subscriptions);
  const loadingComponent = (
    <div className={'loading-container'}>
      <Spinner />
      <h5>{t('settings:subscriptions.fetchingMessage')}</h5>
    </div>
  );
  const body = (
    <Subscription
      defaultSelectedNotification={subscriptionType}
      subscribedParties={subscribedParties}
      identityType={identityType}
      identityId={manageAccountId}
      counterParties={sellerAccountOptions}
      notificationType={notificationType}
      onSave={onConfigureIndividual}
      userAccount={userAccount}
      manageAccountId={manageAccountId}
    />
  );
  const configurationPage = (
    <div>
      <p className="lead" style={{ fontSize: 15 }}>
        {t('settings:subscriptions.header2', { context: notificationType })}
      </p>
    </div>
  );
  return (
    <div style={{ padding: '10px' }}>
      <AuthorizationFlow redirectUri={window.location.href} />
      {configurationPage}
      {isFetchingRelations || loading || isFetchingSubscription ? loadingComponent : body}
    </div>
  );
}
const mapStateToProps = (state, ownProps) => {
  const { identityType } = ownProps;
  return {
    contractSubscriptions: subsSelectors.getAvailableSubscriptions(state, identityType),
    relationships: productAgreementSelectors.getRelationships(state),
    relationshipsStatus: productAgreementSelectors.getRelationshipStatus(state),
    // V1 subscription selector.
    contractV1Subscriptions: subsV1Selectors.getMerchantSubscriptions(state),
    merchantsMap: merchantSelector.getMerchantConfigurationData(state)
  };
};
const mapDispatchToProps = {
  fetchAllSubscriptions: subsActions.fetchAllSubscriptions,
  subscribe: subsActions.subscribeToNotificationsAction,
  unsubscribe: subsActions.unsubscribeFromNotificationsAction,
  fetchRelationships: productAgreementActions.fetchRelationshipsAction,
  fetchAccountById: accountActions.fetchAccountAction,
  // V1 subscription actions
  fetchAllV1Subscriptions: subsV1Actions.fetchAllSubscriptions,
  unsubscribeV1Notification: subsV1Actions.unsubscribeFromNotificationsAction,
  subscribeV1Notification: subsV1Actions.subscribeToNotificationsAction,
  fetchMerchants: merchantAction.fetchMerchantConfigurationData
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(SubscriptionSettings));
