import * as pricingTermApi from '../../../services/PricingTerm';
import { createReducer, toCK } from '../../utils';

const fetchTermRevisionItemBySku_REQUEST = 'fetchTermRevisionItemBySku_REQUEST';
const fetchTermRevisionItemBySku_SUCCESS = 'fetchTermRevisionItemBySku_SUCCESS';
const fetchTermRevisionItemBySku_FAILURE = 'fetchTermRevisionItemBySku_FAILURE';

// action creator
export const fetchTermRevisionItemBySku = (termId, revisionId, sku) => {
  return {
    types: [fetchTermRevisionItemBySku_REQUEST, fetchTermRevisionItemBySku_SUCCESS, fetchTermRevisionItemBySku_FAILURE],
    cacheKey: toCK(termId, revisionId, sku),
    callAPI: () => pricingTermApi.fetchTermRevisionItemBySku(termId, revisionId, sku),
    hooks: null,
    extraPayload: {
      termId,
      revisionId,
      sku
    }
  };
};

// reducer
export default createReducer(
  {},
  {
    [fetchTermRevisionItemBySku_SUCCESS]: (state, action) => {
      const {
        payload,
        extraPayload: { termId, revisionId, sku }
      } = action;
      return { ...state, [toCK(termId, revisionId, sku)]: payload };
    }
  }
);

// selector
export const getTermRevisionItemBySku = (state, termId, revisionId, sku) => {
  return state.contractV1.terms.termRevisionItemBySku[toCK(termId, revisionId, sku)];
};
