import React from 'react';
import { useTranslation } from 'react-i18next';
import { SkuSelector } from '@cimpress-technology/ff-react-components';
import { auth } from '../../../utils/auth';
import omit from 'lodash/omit';

function AddProductsStep(props) {
  const { t, i18n } = useTranslation();
  const { onChange, createContractData } = props;
  const { initiator, selectedSkus } = createContractData;

  const onAddSku = skuInfo => {
    onChange({ selectedSkus: { ...selectedSkus, [skuInfo.sku]: skuInfo } });
  };

  const onBulkAddSkus = skusToAdd => {
    onChange({ selectedSkus: { ...selectedSkus, ...skusToAdd } });
  };

  const onBulkRemoveSkus = skusToRemove => {
    const newSkus = omit(selectedSkus, skusToRemove);
    onChange({ selectedSkus: newSkus });
  };

  const onRemoveSku = skuInfo => {
    onChange({ selectedSkus: omit(selectedSkus, skuInfo.sku) });
  };

  return (
    <SkuSelector
      addNonExistentSkus
      locale={i18n.language}
      skuSearchSectionTitle={t('createContract:addProducts.searchAndAddProducts')}
      skuBinSectionTitle={t('createContract:addProducts.selectedProducts')}
      sellerFulfillerId={initiator.value}
      selectedSkus={selectedSkus}
      onAddSku={onAddSku}
      onRemoveSku={onRemoveSku}
      onBulkAddSkus={onBulkAddSkus}
      onBulkRemoveSkus={onBulkRemoveSkus}
      // we need this props in order to allow bulk removals
      unremoveableSkuList={[]}
      onGetAccessToken={auth.getAccessToken}
      marketable={false}
      skipLoadDescriptionsForBulkAddSkus={true}
    />
  );
}

export default AddProductsStep;
