export const getSubscriptionIds = merchantSubscriptions => {
  return Object.keys(merchantSubscriptions).map(merchantId => {
    const {
      counterParties: { subscriptionId }
    } = merchantSubscriptions[merchantId];
    return subscriptionId;
  });
};

export const getMerchantsByAccountId = (merchantsMap, accountId) => {
  if (merchantsMap && merchantsMap[accountId]) {
    return merchantsMap[accountId];
  }
  return [];
};

export const getMerchantSubscription = (contractV1Subscriptions, merchants) => {
  const merchantSet = new Set();
  merchants.forEach(({ merchantId }) => {
    merchantSet.add(merchantId);
  });
  const merchantSubscriptions = {};
  Object.keys(contractV1Subscriptions).forEach(merchantId => {
    if (merchantSet.has(merchantId)) {
      merchantSubscriptions[merchantId] = contractV1Subscriptions[merchantId];
    }
  });
  return merchantSubscriptions;
};
