import moment from 'moment';
import { MDY_DATE_FORMAT, MDY_HH_MM_DATE_FORMAT, MM_DD_YY_DISPLAY } from '../constants/dateFormat';

export function toMoment(date) {
  return moment(date);
}

export function toDateString(date) {
  if (date === null || date === undefined) {
    return null;
  }

  if (!date.format) {
    date = toMoment(date);
  }

  return date.format(MDY_DATE_FORMAT);
}

export function toMonthDayYearDisplay(date) {
  if (date === null || date === undefined) {
    return null;
  }

  if (!date.format) {
    date = toMoment(date);
  }

  return date.format(MM_DD_YY_DISPLAY);
}

export function toDateTimeString(date) {
  if (date === null || date === undefined) {
    return null;
  }

  if (!date.format) {
    date = toMoment(date);
  }

  return date.format(MDY_HH_MM_DATE_FORMAT);
}
