import * as productAgreementApi from '../../services/ProductAgreement';
import { createReducer } from '../utils';
import { fetchProductIntroductionDetailAction } from './fetchProductIntroductionDetail';
import get from 'lodash/get';

import { AVAILABLE, LOADING, UNAVAILABLE } from '../../constants/entityStatus';
export const fetchProductAgreements_REQUEST = 'fetchProductAgreements_REQUEST';
export const fetchProductAgreements_SUCCESS = 'fetchProductAgreements_SUCCESS';
export const fetchProductAgreements_FAILURE = 'fetchProductAgreements_FAILURE';
export const updateProductVersion_REQUEST = 'updateProductVersion_REQUEST';
export const updateProductVersion_SUCCESS = 'updateProductVersion_SUCCESS';
export const updateProductVersion_FAILURE = 'updateProductVersion_FAILURE';

export const fetchProductAgreement_REQUEST = 'fetchProductAgreement_REQUEST';
export const fetchProductAgreement_SUCCESS = 'fetchProductAgreement_SUCCESS';
export const fetchProductAgreement_FAILURE = 'fetchProductAgreement_FAILURE';

const fetchProductAgreementsHooks = {
  onFailure: 'Failed to fetch product agreements.'
};

const updateProductAgreementsHooks = {
  onRequest: 'Updating product versions...',
  onSuccess: 'Successfully updated the product versions',
  onFailure: 'Failed to update the product versions'
};

export const maxInt32 = 2147483647;

// Action
export const fetchProductAgreementsAction = (
  sellerAccountId,
  buyerAccountId,
  productIds = [],
  productVersions = [],
  offset = 0,
  limit = maxInt32
) => {
  return {
    types: [fetchProductAgreements_REQUEST, fetchProductAgreements_SUCCESS, fetchProductAgreements_FAILURE],
    callAPI: async dispatch => {
      try {
        const { productAgreements, totalCount } = await productAgreementApi.getProductAgreements(
          [sellerAccountId],
          [buyerAccountId],
          productIds,
          productVersions,
          offset,
          limit
        );
        const productIntroductionDataMap = await getProductInformation(productAgreements, dispatch);
        return createProductList(
          productAgreements,
          productIntroductionDataMap,
          sellerAccountId,
          buyerAccountId,
          totalCount
        );
      } catch (e) {
        handleFetchProductAgreementsError(e);
      }
    },
    hooks: fetchProductAgreementsHooks
  };
};

export const fetchProductAgreementAction = productAgreementId => {
  return {
    types: [fetchProductAgreement_REQUEST, fetchProductAgreement_SUCCESS, fetchProductAgreement_FAILURE],
    callAPI: async dispatch => {
      try {
        const response = await productAgreementApi.getProductAgreementDetails(productAgreementId);
        return await getKeyAndProductAgreement(response, dispatch);
      } catch (e) {
        handleFetchProductAgreementsError(e);
      }
    },
    hooks: fetchProductAgreementsHooks
  };
};

// Action
export const updateProductVersionAction = (productData, updatedVersions, sellerAccountId, buyerAccountId) => {
  return {
    types: [updateProductVersion_REQUEST, updateProductVersion_SUCCESS, updateProductVersion_FAILURE],
    callAPI: async () => {
      try {
        await productAgreementApi.putProductAgreements(
          productData.productAgreementId,
          productData.productId,
          updatedVersions
        );

        let key = `${buyerAccountId}|${sellerAccountId}`;
        let updatedProductData = { ...productData };
        updatedProductData.productVersions = updatedVersions;
        return {
          key: key,
          value: updatedProductData
        };
      } catch (e) {
        handleUpdateProductAgreementsError(e);
      }
    },
    hooks: updateProductAgreementsHooks
  };
};

const getKeyAndProductAgreement = async (productAgreement, dispatch) => {
  const { id, buyer, seller, product } = productAgreement;
  const key = `${buyer.accountId}|${seller.accountId}`;
  const responses = await dispatch(fetchProductIntroductionDetailAction([product.id]));
  const productInformation = responses.payload.map(response => response);

  const productVersions = [];
  if (product.versions !== null || product.versions === []) {
    product.versions.forEach(versionDetail => {
      productVersions.push(versionDetail.version);
    });
  }

  const ProductData = {
    lockedUntilDate: product.lockedUntil,
    productAgreementId: id,
    productId: product.id,
    productName: productInformation[0].name,
    productServiceVersion: productInformation[0].productServiceVersion,
    productVersions: productVersions
  };
  return {
    key: key,
    value: ProductData
  };
};

async function getProductInformation(productAgreements, dispatch) {
  const productIds = new Set();
  let productIntroductionDataMap = {};

  productAgreements.forEach(productAgreement => {
    productIntroductionDataMap[productAgreement.product.id] = '';
    productIds.add(productAgreement.product.id);
  });

  const responses = await dispatch(fetchProductIntroductionDetailAction(productIds));
  let productInformation = responses.payload.map(response => response);

  productInformation.forEach(productDetail => {
    productIntroductionDataMap[productDetail.referenceId] = {
      productName: productDetail.name,
      productServiceVersion: productDetail.productServiceVersion
    };
  });

  return productIntroductionDataMap;
}

function createProductList(productAgreements, productIntroductionDataMap, sellerAccountId, buyerAccountId, totalCount) {
  let key = `${buyerAccountId}|${sellerAccountId}`;

  let productList = productAgreements.map(productAgreement => {
    let productVersions = [];
    if (productAgreement.product.versions !== null || productAgreement.product.versions === []) {
      productAgreement.product.versions.forEach(versionDetail => {
        productVersions.push(versionDetail.version);
      });
    }
    return {
      productAgreementId: productAgreement.id,
      productId: productAgreement.product.id,
      productName: productIntroductionDataMap[productAgreement.product.id].productName,
      productVersions: productVersions,
      productServiceVersion: productIntroductionDataMap[productAgreement.product.id].productServiceVersion,
      lockedUntilDate: productAgreement.product.lockedUntil
    };
  });

  return {
    key: key,
    value: { productList: productList, totalCount: totalCount }
  };
}

// reducer
export default createReducer(
  {},
  {
    [updateProductVersion_REQUEST]: (state, action) => {
      return { ...state, status: LOADING };
    },
    [updateProductVersion_SUCCESS]: (state, action) => {
      const { payload } = action;
      let updatedPayload = state.payload;
      let updatedProductData = updatedPayload[payload.key];
      let productData = payload.value;
      let productId = productData.productId;
      updatedProductData.productList.forEach(element => {
        if (element.productId === productId) {
          element.productVersions = productData.productVersions;
        }
      });
      updatedPayload[payload.key] = updatedProductData;
      return { ...state, payload: updatedPayload, status: AVAILABLE };
    },
    [updateProductVersion_FAILURE]: (state, action) => {
      return { ...state, status: UNAVAILABLE };
    },

    [fetchProductAgreement_REQUEST]: (state, action) => {
      return { ...state, status: LOADING };
    },
    [fetchProductAgreement_SUCCESS]: (state, action) => {
      const { payload } = action;
      let updatedPayload = state.payload;
      let updatedProductData = updatedPayload[payload.key];
      if (updatedProductData) {
        let productData = payload.value;
        let productId = productData.productId;
        const product = updatedProductData.productList.find(element => element.productId === productId);
        if (product) {
          product.productVersions = productData.productVersions;
          product.lockedUntilDate = productData.lockedUntilDate;
        } else {
          updatedProductData.productList
            ? updatedProductData.productList.push(productData)
            : (updatedProductData.productList = [productData]);
        }
        updatedPayload[payload.key] = updatedProductData;
      }
      return { ...state, payload: updatedPayload, status: AVAILABLE };
    },
    [fetchProductAgreement_FAILURE]: (state, action) => {
      return { ...state, status: UNAVAILABLE };
    },

    [fetchProductAgreements_REQUEST]: (state, action) => {
      return { ...state, status: LOADING };
    },
    [fetchProductAgreements_SUCCESS]: (state, action) => {
      const { payload } = action;
      let updatedPayload = state.payload ? state.payload : {};
      if (payload.value.productList.length > 0) {
        updatedPayload[payload.key] = payload.value;
      }
      return { ...state, payload: updatedPayload, status: AVAILABLE };
    },
    [fetchProductAgreements_FAILURE]: (state, action) => {
      return { ...state, status: UNAVAILABLE };
    }
  }
);

// Selector
export const getProductList = state => {
  return state.productAgreements.productList.payload;
};

export const getProductListByKey = (state, key) => {
  if (state.productAgreements.productList.payload && state.productAgreements.productList.payload[key]) {
    return state.productAgreements.productList.payload[key];
  }
  return { productList: [], totalCount: 0 };
};

export const getProductListStatus = state => {
  return state.productAgreements.productList.status;
};

const handleFetchProductAgreementsError = err => {
  let errMsg = '';

  switch (err.statusCode) {
    case 403:
      errMsg = 'You do not have the correct permissions to get the product agreements.';
      break;
    case 422:
    case 400:
      errMsg = get(err, 'response.detail', 'Error in getting the product agreements');
      break;
    default:
      errMsg = 'Getting the product agreements was not successful.';
  }

  throw new Error(errMsg);
};

const handleUpdateProductAgreementsError = err => {
  let errMsg = '';

  switch (err.statusCode) {
    case 403:
      errMsg = 'You do not have the correct permissions to update the product agreements.';
      break;
    case 422:
    case 400:
      errMsg = get(err, 'response.detail', 'Error in updating the product agreements');
      break;
    default:
      errMsg = 'Updating the product agreements was not successful.';
  }

  throw new Error(errMsg);
};
