export default {
  common: {
    contractV1Src: {
      DoYouWantToCreateShippingContractV1: 'Vuoi creare un contratto di spedizione?',
      EnabledShippingContractV1ErrorMessage:
        'Errore durante il recupero degli ID account per abilitare il contratto di spedizione.',
      NoAgreements:
        'I contratti visualizzati vengono creati all interno della stessa azienda. Quindi nessun accordo è stato mostrato qui.',
      PleaseContact: 'Contatta {{emailid}} se la tua azienda richiede accordi.'
    },
    contractTypes: {
      listPrice: 'Prezzo del catalogo',
      specialPrice: 'Prezzo privato',
      freeShipping: 'Spedizione gratuita',
      shippingCost: 'Costi di spedizione',
      shippingPrice: 'Prezzo della spedizione',
      listPriceWithDiscount: '$t(common:contractTypes.listPrice) con $t(common:commonWords.discount)',
      listPriceWithMarkup: '$t(common:contractTypes.listPrice) con $t(common:commonWords.markup)',
      specialPriceWithMarkup: '$t(common:contractTypes.specialPrice) con $t(common:commonWords.markup)',
      shippingCostWithDiscount: '$t(common:contractTypes.shippingCost) con $t(common:commonWords.discount)',
      shippingCostWithMarkup: '$t(common:contractTypes.shippingCost) con $t(common:commonWords.markup)',
      shippingPriceWithDiscount: '$t(common:contractTypes.shippingPrice) con $t(common:commonWords.discount)',
      shippingPriceWithMarkup: '$t(common:contractTypes.shippingPrice) con $t(common:commonWords.markup)',
      DoYouWantToCreateShippingContractV1: 'Vuoi creare un contratto di spedizione?'
    },
    contractStatus: {
      approved: 'Approvato',
      pending: 'In sospeso',
      terminated: 'Terminato',
      rejected: 'Rifiutato',
      draft: 'Bozza',
      effective: 'Effettivo',
      visibleInCatalog: 'Visibile nel Catalogo',
      usingDeletedModel: 'Modello di prezzo eliminato in uso',
      expiredAgo: '(Scaduto {{ duration, localizeDuration }})',
      isExpiring: '(Scadenza {{ duration, localizeDuration }})',
      pending_yourResponse: 'Revisione necessaria',
      pending_otherParty: 'Approvazione in sospeso',
      pending_priorTerm: 'Precedentemente $t(common:contractStatus.pending)',
      approved_priorTerm: 'Precedentemente $t(common:contractStatus.approved)',
      rejected_priorTerm: 'Precedentemente $t(common:contractStatus.rejected)',
      terminated_priorTerm: 'Precedentemente $t(common:contractStatus.terminated)',
      gracePeriodAboutToExpire: 'Sarà approvato automaticamente <1>{{ duration, localizeDuration }}</1>',
      needsApproval: 'Approvazione necessaria'
    },
    contract: {
      lastUpdatedXAgo: '{{ duration, localizeDuration }}',
      privateButDiscoverableTip:
        "I prezzi dell'articolo in questo contratto di prezzo privato sono pubblici e possono essere consultati nel catalogo. Crea un contratto di prezzo del catalogo al posto di questo contratto di prezzo privato.",
      deletedModelsTip:
        'Questo contratto utilizza i modelli di prezzo eliminati, che potrebbero comportare delle conseguenze negli aggiornamenti di prezzo.\n Contattare \n{{ email }} per assistenza'
    },
    commonTerminology: {
      shippingPriceModel: 'Modello di prezzo della spedizione',
      priceModel: 'Modello di prezzo',
      contractName: 'Nome contratto',
      effectiveStartDate: "Data d'inizio effettiva",
      effectiveEndDate: 'Data di fine effettiva',
      discount: '{{ percentage }}% $t(common:commonWords.discount)',
      markup: '{{ percentage }}% $t(common:commonWords.markup)',
      name: 'Nome',
      indefinite: 'Non definito',
      revision: 'Revisione',
      mcpSystem: 'il sistema MCP',
      gracePeriod: 'Grace Period',
      effectiveDates: 'Date effettive (in UTC)',
      lastUpdated: 'Ultimo aggiornamento',
      seller: 'Venditore',
      seller_plural: 'Venditori',
      seller_oneOrMore: 'Venditore/i',
      buyer: 'Acquirente',
      buyer_plural: 'Acquirenti',
      buyer_oneOrMore: 'Acquirente/i',
      search: 'Cerca',
      status: 'Stato',
      status_plural: 'Stati',
      status_oneOrMore: 'Stato/i',
      type: 'Tipo',
      contractType: 'Tipo di contratto',
      contractType_plural: 'Tipi di contratto',
      contractType_oneOrMore: 'Tipo/i di contratto',
      hasGracePeriod: 'Ha un Grace Period',
      ProductId: 'Numero identificativo del prodotto',
      currentVersion: 'Versione corrente'
    },
    commonWords: {
      discount: 'Sconto',
      markup: 'Markup',
      yesText: 'Sì',
      noText: 'No',
      onText: 'Attivo',
      offText: 'Disattivo',
      blank: '',
      next: 'Successivo',
      back: 'Indietro',
      prev: 'Prec',
      orText: 'o',
      save: 'Salva',
      cancel: 'Annulla',
      close: 'Chiudi',
      countries: 'Paesi',
      authorize: 'Autorizza',
      edit: 'Modifica',
      delete: 'Elimina'
    },
    unsavedChanges: {
      message: "È in corso un'azione. Abbandonare la pagina?"
    },
    actions: {
      createNewContract: 'Crea nuovo contratto',
      createButtonText: 'Creare'
    }
  },
  authorizationFlow: {
    errorTitle: 'Errore di autorizzazione',
    title: 'Prima di iniziare...',
    descriptionMessage1: 'Benvenuto in Contract Manager!',
    descriptionMessage2:
      'Per i nuovi utenti, al fine di effettuare la sottoscrizione ai termini e ricevere le notifiche, occorre prima essere ',
    descriptionMessage3: 'autorizzati',
    descriptionMessage4: 'mediante il servizio Hub Notifiche.',
    descriptionMessage5: 'Si viene reindirizzati al Marketplace Central quando si clicca ',
    descriptionMessage6: 'sul pulsante in basso.',
    authErrorMessage1: 'Qualcosa è andato storto durante l’autorizzazione delle notifiche.',
    authErrorMessage2: 'Per assistenza, contattare {{ FFS_EMAIL_ADDRESS }}.'
  },
  investigateSku: {
    investigate: 'Trova contratti con SKU',
    getDetails: 'Ottieni dettagli',
    noContractsFound: "Nessun contratto trovato con la SKU '{{sku}}'",
    emptySearch: 'La ricerca non deve essere vuota',
    contractsBySku: {
      header: 'Dettagli del contratto per {{ sku }}',
      header2: 'Aziende con i contratti per {{sku}}',
      selectAContract: 'Selezionare un contratto per visualizzarne i dettagli e i prezzi relativi a questa SKU',
      viewContract: 'Visualizza contratto'
    }
  },
  appShell: {
    loudspeakerModal: {
      title: 'Quali sono le novità in Contract Manager?'
    }
  },
  breadcrumbs: {
    home: 'Contratti',
    contracts: 'Contratti',
    settings: 'Impostazioni',
    notFound: 'Non trovato',
    bulkUpdate: 'Aggiornamento in blocco',
    viewSku: '{{ sku }}',
    contractChanges: 'Modifiche del contratto',
    createContracts: 'Crea contratti',
    viewContractRevision: 'Visualizza revisione del contratto',
    createProductAgreements: 'Creare accordi sul prodotto',
    productAgreementDetails: 'Dettagli sugli accordi di prodotto',
    notificationDetails: 'Dettagli notifica'
  },
  landingPage: {
    nameGreeting: 'Ciao {{ name }}, ',
    welcome: 'Benvenuto in Contract Manager',
    setupContracts: 'Configura e gestisci i contratti con le aziende per aiutarti a comprare e vendere prodotti.',
    todaysReport: 'Rapporto di oggi',
    statCardTotalLabel: 'Totale',
    createContractsCard: {
      header: 'Crea e Gestisci Contratti',
      cardBody:
        'Configura e gestisci i contratti con acquirenti e venditori. Aggiungi le SKU ai contratti, stabilisci i termini, gli sconti e altre condizioni.',
      numberOfBuyerAndSellers:
        '<0>{{ numberOfBuyers }}</0> contratti di acquirenti, <2>{{ numberOfSellers }}</2> contratti di venditori',
      browseAll: 'Sfoglia tutti'
    },
    investigateSkusCard: {
      header: 'Esamina le SKU con contratti',
      cardBody:
        'Visualizza i dettagli del contratto per SKU specifiche come, ad esempio, quali contratti hanno le SKU, quali sono i termini e qual è lo stato corrente.'
    },
    settingsCard: {
      header: 'Sottoscrizioni e Approvazioni',
      manageSettings: 'Gestisci le varie impostazioni relative ai contratti:',
      manageSubscriptions: 'Gestisci le sottoscrizioni alle notifiche dei contratti',
      manageAutoApproval: "Stabilisci l'approvazione automatica per acquirenti e venditori",
      manageShippingContracts: 'Abilita o disabilita contratti di spedizione',
      manageSettingsButton: 'Gestisci Impostazioni',
      manageSubscriptionsAndApprovalsButton: 'Configurare gli abbonamenti'
    },
    notificationsCard: {
      manageNotifications: 'Guarda le notifiche',
      manageSettings:
        'Hai {} notifiche non riconosciute {} relative a variazioni di prezzo o archiviazione dei prodotti.'
    },
    recentlyCreatedContractsCard: {
      header: 'Contratti creati di recente'
    },
    manageContractsFor: 'Gestisci i contratti per:',
    contracts: 'Contratti'
  },
  homePage: {
    header: {
      title: 'I miei contratti'
    },
    searchBarPlaceholder: 'Cerca per contratto o per sku presente nel contratto',
    selectMultipleContracts: {
      label: 'Seleziona più contratti:',
      contractSelection: {
        contractTypeLabel: 'Tipo di contratto',
        selectSeller: 'Seleziona venditore',
        addSKUsTo: 'Aggiungi le SKU al contratto selezionato',
        addSKUsTo_plural: 'Aggiungi le SKU ai {{count}} contratti selezionati'
      }
    }
  },
  relationshipTable: {
    all: 'All',
    marketplace: 'B2B',
    fulfilmentNetwork: 'Fulfilment Network',
    searchTextFieldPlaceholder: 'Cerca per ID account o nome'
  },
  contractsTable: {
    effectiveContractPopover:
      'Un termine relativo ai prezzi effettivi è il termine che viene utilizzato per calcolare quando un ordine viene effettuato sul sistema MCP.',
    effectiveContractDetails:
      "Il contratto avrà efficacia per <1>{{ sku }}</1> tra l'acquirente <3>{{ buyer }}</3> e il venditore <5>{{ seller }}</5>",
    effectiveContractAdditionalDetails:
      'Affinché un contratto abbia efficacia, è necessario che venga approvato e abbia <1>la massima priorità\n</1> <2/>.',
    onlyOneEffectiveContractDetails:
      'È possibile avere solo un contratto per una data sku, un dato acquirente e un dato venditore in un dato momento.'
  },
  tableCards: {
    allContracts: 'Tutti i contratti',
    needsReview: 'Revisione necessaria',
    pendingApproval: 'Approvazione in sospeso'
  },
  contractsFilter: {
    filterOffLabel: 'Filtri aggiuntivi',
    filterOnLabel: 'Filtra per',
    clearFilterLabel: 'cancella filtro'
  },
  contractInfo: {
    author: 'Autore',
    possibleCurrencies: 'Valute possibili',
    infoSectionTitle: 'Informazioni contratto',
    pricingSectionTitle: 'Prezzi',
    partiesSectionTitle: 'Parti interessate',
    detailsSectionTitle: 'Dettagli',
    excludedCountriesTitle: 'Paesi esclusi',
    destinationCountriesTitle: 'Paesi di destinazione',
    latestRevisionTitle: 'Ultima revisione',
    noOfItemsTitle: 'Numero di articoli',
    remarksTitle: 'Commenti',
    allCountries: 'Tutti i Paesi',
    noGracePeriod: 'Non ha un Grace Period',
    viewChanges: 'Visualizza modifiche',
    viewAllCountries: 'Visualizza tutti i {{ count }} Paesi',
    updatedBy: 'Aggiornato da',
    priceModelsOnContract: 'Modello di prezzo su questo contratto',
    priceModelsOnContract_plural: 'Modelli di prezzo su questo contratto',
    defaultNameForPriceModel: 'Non definito',
    statusChangedOn: 'Stato modificato il <1>{{ dateTime, localDateTime }}</1>',
    notTheLatestRevisionText:
      "Questa revisione è di sola lettura dal momento che non è l'ultima revisione. <1>Cliccare qui per visualizzare l'ultima revisione</1>",
    readOnlyRevisionText:
      "Questa pagina è di sola lettura dal momento che non si sta visualizzando l'ultima revisione. <1>Cliccare qui per visualizzare l'ultima revisione</1>",
    gracePeriod: '{{ duration, localizeDurationSimple }}',
    gracePeriodDescription:
      "Il grace period stabilisce il numero di giorni che l'accettante ha a disposizione per rivedere le modifiche apportate a un contratto prima che si accettino o rifiutino gli aggiornamenti. Nel momento in cui il grace period scade, il contratto sarà approvato automaticamente. Nel caso in cui non vi sia un grace period, il contratto verrà approvato automaticamente."
  },
  contractActions: {
    viewPricing: 'Visualizza Prezzi',
    viewPricingToolTip:
      'Cliccare qui per ricevere ulteriori informazioni su questo termine come, ad esempio, sulle sku e sui prezzi',
    publish: "Pubblica su {{ otherParty }} per l'approvazione",
    approve: 'Approva',
    approving: 'Approvazione',
    terminate: 'Termina',
    terminateFinal: 'Ho capito. Termina',
    terminated: 'Terminato!',
    goToTerminatedRevision: 'Vai alla revisione terminata',
    cancel: 'Annulla',
    sendForApproval: "Invia per la revisione e l'approvazione",
    saveAndUpdate: 'Salva e aggiorna',
    validateUniqueMessage: 'Il valore deve essere diverso dal valore precedente.',
    requiredMessage: 'È richiesto il valore',
    editGracePeriodModal: {
      title: 'Modifica i giorni del Grace Period',
      validateRangeMessage: 'Il valore deve essere compreso tra 1 e 999.',
      checkboxLabel: 'Nessun grace period'
    },
    editModifierModal: {
      title: 'Modifica {{modifierType}} % quantità'
    },
    editNameModal: {
      title: 'Modifica nome'
    },
    editSkusModal: {
      successful: 'Riuscito',
      bulkAddSkusMessage: '{{numberOfSkusAdded}} sku sono state aggiunte.',
      bulkAddDuplicateMessage: '{{duplicateSkus}} sono state saltate poiché sono state già selezionate.',
      toolTipNoChangesMessage: 'Non sono presenti modifiche.',
      toolTipOneOrMoreChangesMessage: 'Occorrono 1 o più articoli.',
      toolTipNotCompatibleMessage: 'Il modello di prezzo non è compatibile con le sku selezionate.',
      newRevisionActionMessage:
        "Verrà creata una nuova revisione del contratto e l'altra parte riceverà una notifica per proseguire con un'azione.",
      noProductsAddedMessage: 'Nessun prodotto aggiunto',
      noProductsRemovedMessage: 'Nessun prodotto rimosso',
      productsAddedMessage: 'prodotti aggiunti: {{ products}}',
      productsRemovedMessage: 'prodotti rimossi: {{ products}}',
      noChangesToPricingMessage: 'Nessuna modifica per il prezzo',
      priceModelChangedMessage: 'Il modello di prezzo è stato modificato in "{{ priceModel }}',
      updating: 'Aggiornamento in corso...',
      updated: 'Aggiornato!',
      checkboxLabel: 'Modifica modello di prezzo',
      title: 'Modifica articoli'
    }
  },
  contractChanges: {
    metaData: {}
  },
  createContract: {
    messages: {
      missingFieldsMessage: 'Sono presenti campi non validi e/o mancanti nel modulo',
      contractNameMissingMessage: 'È richiesto il nome del contratto.',
      modifierEmptyMessage: 'Il modificatore non deve essere vuoto.',
      endDateMissingMessage: 'È necessario impostare la data di fine.',
      createUnsuccessfulMessage: 'Abbandonare la pagina?',
      onlyFulfillersAllowed: "Al momento solo agli addetti all'evasione è consentito creare i contratti.",
      changingInitiatorWarning:
        "Se si modifica l'iniziatore, i progressi verranno azzerati. Premere OK per continuare.",
      markupPercentageNotGreaterThan0Message: 'La percentuale di markup deve essere maggiore di 0.',
      discountPercentageMustBeBetween0And100: 'La percentuale di sconto deve essere compresa tra 0 e 100.',
      modifierAmountNotValidMessage: 'La quantità del modificatore non è valida.',
      setModifierHeader_discount: 'Imposta percentuale di sconto',
      setModifierHeader_markup: 'Imposta percentuale di markup',
      modifierTextFieldLabel_discount: 'Percentuale di sconto (%)',
      modifierTextFieldLabel_markup: 'Percentuale di markup (%)',
      requestPermissionsMessage: "Contattare {{ email }} per ottenere l'accesso."
    },
    chooseTypeModal: {
      modalTitle: 'Cosa si desidera fare?',
      buyerTypeTitle: 'Voglio effettuare un acquisto',
      sellerTypeTitle: 'Voglio effettuare una vendita',
      sellerTypeDescription: 'Voglio creare un contratto in qualità di venditore',
      buyerTypeDescription: 'Voglio creare un contratto in qualità di acquirente'
    },
    termEntryForm: {
      noEndDate: 'Nessuna data di fine',
      notesToApproverLabel: 'Invia note al responsabile delle approvazioni',
      additionalInformationLabel: 'Informazioni aggiuntive o commenti',
      contractNameLabel: 'Assegnare un nome al contratto',
      contractDurationLabel: 'Imposta la durata del contratto',
      noCountrySelectedMessage: 'Selezionare almeno un Paese.',
      chooseDestinationCountries: 'Scegliere i Paesi di destinazione in cui il contratto è valido',
      allDestinationCountries: 'Tutti i Paesi di destinazione',
      allDestinationCountriesExcept: 'Tutti i Paesi di destinazione tranne <1>\n</1>',
      individualDestinationCountries: 'Singoli Paesi di destinazione'
    },
    validate: {
      buyerSelect: 'Selezionare un acquirente',
      sellerSelect: 'Selezionare un venditore',
      skuSelect: 'Selezionare almeno una sku',
      contractName: 'Inserire un nome contratto',
      pricingContractTypeSelect: 'Selezionare un tipo di contratto di pricing',
      creationTypeSelect: 'Selezionare un contratto da aggiornare.'
    },
    getStarted: {
      sellerTitle: 'Per iniziare',
      buyerTitle: 'Seleziona Parti',
      header: 'Selezionare le parti per il contratto',
      selectSeller: 'Selezionare il venditore addetto alla creazione dei contratti',
      selectBuyer: "Selezionare l'acquirente responsabile della creazione dei contratti",
      selectBuyingFrom: 'Selezionare il venditore da cui acquistare',
      missingSellerMessage: 'Scegliere il venditore.',
      selectBuyers: "Selezionare l'acquirente/gli acquirenti addetto/i alla revisione dei contratti",
      missingBuyerMessage: "Scegliere l'acquirente/gli acquirenti",
      addGracePeriod: 'Aggiungere un grace period?',
      invalidGracePeriodSelection: 'Il grace period dovrebbe essere superiore a 0 giorni.',
      gracePeriodLabel: 'Giorni nel grace period',
      gracePeriodDescription:
        'Se vi è un grace period e se il revisore non fornisce una risposta entro la fine del grace period, qualsiasi aggiornamento al contratto verrà approvato automaticamente.'
    },
    selectExistingContract: {
      header: 'Selezionare un contratto esistente a cui aggiungere i prodotti'
    },
    selectCreationTypeStep: {
      title: 'Scegliere Nuovo o Esistente',
      existingContractsWarning:
        "Contratto/i già esistente/i con {{ name }}. È possibile scegliere l'aggiornamento di uno di questi contratti o crearne uno nuovo.",
      header: 'Creare un nuovo contratto o aggiornare il contratto esistente'
    },
    addProducts: {
      title: 'Aggiungi prodotti',
      header: 'Selezionare i prodotti da aggiungere ai contratti',
      searchAndAddProducts: 'Cerca e aggiungi prodotti',
      selectedProducts: 'Prodotti selezionati',
      mustSelectProductsMessage: 'È necessario selezionare i prodotti da aggiungere al contratto',
      disabledListPriceMessage:
        'Il selettore è stato disabilitato perché la SKU non ha un prezzo del catalogo impostato',
      bulkListPriceErrorMessage:
        'Non è stato possibile aggiungere le SKU presenti in alto poiché non hanno un prezzo del catalogo o non esistono nella piattaforma. Rivederle e riprovare.'
    },
    chooseNewOrExisting: {
      existingButtonTitle: 'Aggiorna contratto esistente',
      existingButtonDescription: 'Aggiungere le sku al contratto esistente con \n{{name}}',
      newButtonDescription: 'Creare nuovo contratto con {{name}}'
    },
    addContract: {
      title: 'Impostare un contratto di prezzo del catalogo per i prodotti'
    },
    pricingContract: {
      title: 'Contratto di prezzo del prodotto',
      header: 'Configurare un contratto di prezzi del prodotto per i prodotti',
      description:
        'Scegliere un prezzo del catalogo o privato per i prodotti e aggiungere uno sconto o un markup facoltativo.',
      selectedPriceModel: 'Modello di prezzo selezionato (Solo uno disponibile)',
      selectedPriceModel_plural: 'Modello di prezzo selezionato',
      changePriceModel: 'Modifica modello di prezzo',
      noPriceModelFoundMessage:
        'Nessun modello di prezzo trovato. Tornare indietro e riselezionare i prodotti o effettuare nuovamente la ricerca. Per assistenza, contattare {{ contactEmailAddress }}',
      selectAPriceModel: 'Prima, selezionare un modello di prezzo da applicare alle sku',
      mustSelectAPriceModel:
        'È necessario selezionare il tipo di contratto e impostare il contratto di prezzi del prodotto',
      mustSelectShippingAPriceModel:
        'È necessario selezionare il tipo di contratto e impostare il contratto di spedizione'
    },
    shippingContract: {
      title: 'Contratto di spedizione',
      header: 'Configurare un contratto di prezzi della spedizione per i prodotti',
      description:
        'Creare il prezzo di spedizione in base ai costi di spedizione, al prezzo di spedizione predefinito o offrire la spedizione gratuita. A propria discrezione, è possibile aggiungere uno sconto o un markup.',
      noShippingPriceModelsFoundMessage:
        'Nessun modello di prezzo della spedizione trovato. Per assistenza, contattare {{ contactEmailAddress }}',
      shippingPriceModelSelectionLabel: 'Modello di prezzo della spedizione selezionato'
    },
    result: {
      step_0: 'Le notifiche vengono inviate alle parti accettanti elencate nei termini.',
      step_1: 'I responsabili delle approvazioni leggeranno e approveranno il contratto.',
      step_1_plural: 'I responsabili delle approvazioni leggeranno e approveranno i contratti.',
      step_2: 'Una volta approvato, il contratto sarà attivo.',
      step_2_plural: 'Una volta approvati, i contratti saranno attivi.',
      description: "Il nuovo contratto è stato creato e inviato per l'approvazione",
      description_plural: "I nuovi contratti sono stati creati e inviati per l'approvazione",
      header: 'Successo!',
      whatHappensNext: 'Che succede ora?',
      backToHomePage: "Torna all'home page",
      createNewContract: 'Crea nuovo contratto',
      viewContract: 'Visualizza contratto',
      createNewContract_plural: 'Crea nuovi contratti',
      duplicateContract: 'Duplica questo contratto',
      duplicateContract_plural: 'Duplica questi contratti',
      creatingContractsMessage: 'Stiamo creando i nuovi contratti per te',
      pleaseDoNotRefresh: 'Non ricaricare o chiudere la pagina.',
      notAllSKUsHaveCatalogPricing: 'Non tutte le SKU hanno i prezzi del catalogo impostati. Impostarli e riprovare.',
      notHaveCorrectPermissions: 'Non si dispone delle giuste autorizzazioni per creare questo contratto.',
      somethingWentWrong: 'Si è verificato qualche errore.',
      contactInformation: 'Contattare {{ contactEmailAddress }} per assistenza.',
      errorCreatingContractMessage:
        'Qualcosa è andato storto durante la creazione del contratto. Se il problema persiste, contattare\n{{ contactEmailAddress }}.'
    },
    review: {
      title: 'Revisiona',
      general: 'Generale',
      generalDetails: 'Dettagli generali per {{name}}',
      buyerHeader: 'Per {{buyer}}',
      header: 'Rivedi e termina il contratto',
      header_plural: 'Rivedi e termina i contratti',
      description:
        "Rivedere il contratto e inviarlo per l'approvazione. Una volta approvato, il contratto sarà attivo.",
      description_plural:
        "Rivedere i contratti e inviarli per l'approvazione. Una volta approvati, i contratti saranno attivi.",
      contractDetailsLabel: 'Dettagli del contratto',
      contractDetailsLabel_plural: 'Dettagli dei contratti',
      noContractBeingSetupMessage: 'È necessario avere almeno un contratto impostato.',
      productsInContract: 'Prodotti presenti nel contratto',
      productsToAddContract: 'Prodotti da aggiungere al contratto',
      productsInContract_plural: 'Prodotti presenti nei contratti'
    }
  },
  terminationWarnings: {
    header: 'Se si continua...',
    warning_0: 'Verrà creata una nuova revisione con lo stato TERMINATO',
    warning_1: 'Per questo contratto, non è possibile apportare ulteriori modifiche e revisioni.',
    warning_1_0:
      '<0>Pertanto, deve essere creato un nuovo contratto affinché si possa creare nuovamente questo contratto.</0>',
    warning_1_1:
      '<0>Se questo è un contratto di prezzo del catalogo, gli aggiornamenti ai prezzi del catalogo  <1>non</1> avranno alcuna conseguenza sul contratto.</0>',
    warning_2: 'Il contratto non sarà attivo tra le due parti.',
    warning_2_0:
      "<0>Se l'acquirente A comprasse dal venditore B, l'acquirente A <1>non potrebbe più\n</1> acquisire tale contratto.</0>",
    warning_3: 'Questo contratto non apparirà più sulla home page.',
    warning_4: 'Questa azione è irreversibile.'
  },
  errors: {
    error_general: 'Errore',
    error_notFound: 'Non trovato',
    error_create_permission: 'Non si dispone delle autorizzazioni necessarie per creare i contratti',
    error_forbidden: 'Non si dispone delle autorizzazioni necessarie per accedere a questa pagina.',
    error_unknown: "Qualcosa è andato storto durante il caricamento delle identità dell'utente.",
    withCommentAndStatusCode: 'Errore: {{serviceComment}} ({{statusCode}})'
  },
  settings: {
    header: 'Impostazioni',
    subscriptions: {
      header2_contractNotification:
        "Gestisci i tuoi abbonamenti alle notifiche e-mail quando vengono attivate l'archiviazione della versione del prodotto o le modifiche dei prezzi.",
      header: 'Le mie sottoscrizioni',
      header2: 'Gestisci le sottoscrizioni alle notifiche e-mail quando si crea o si aggiorna un contratto. ',
      header3: "Seleziona un'identità e scegli quale di questi termini vorresti sottoscrivere.",
      multipleRolesWarning_fulfillers:
        'Se si ha accesso sia ai venditori sia agli acquirenti, solo le sottoscrizioni effettuate al <1>venditore\n</1> appariranno su questa pagina.',
      multipleRolesWarning_merchants:
        "Se si ha accesso sia ai venditori sia agli acquirenti, solo le sottoscrizioni effettuate all' <1>acquirente\n</1> appariranno su questa pagina.",
      fetchingMessage: 'Recupero delle sottoscrizioni in corso...',
      fetchinErrorMessage: 'Errore durante il recupero delle sottoscrizioni correnti',
      searchBy_fulfillers: 'Cerca per nome venditore',
      searchBy_merchants: 'Cerca per nome acquirente',
      manageSettingsFor: 'Gestisci impostazioni per',
      addSubscriptionButton_fulfillers: 'Configura più venditori',
      addSubscriptionButton_merchants: 'Configura più acquirenti',
      addSubscriptionTooltip_fulfillers: 'Configura le sottoscrizioni per più venditori in una volta',
      addSubscriptionTooltip_merchants: 'Configura le sottoscrizioni per più acquirenti in una volta',
      subscriptionNotAllowedForNonCimpressOwned:
        "Solo l'utente dell'account di proprietà di cimpress può iscriversi come acquirente",
      subscriptionAccordion: {
        notifiedOnAll_fulfillers: 'Notificato il: <1>Tutti i venditori\n</1>',
        notifiedOnAll_merchants: 'Notificato il: <1>Tutti gli acquirenti</1>',
        notifiedOn_fulfillers: 'Notificato il: <1>{{ count }} Venditore</1>',
        notifiedOn_fulfillers_plural: 'Notificato il: <1>{{ count }} Venditori</1>',
        notifiedOn_merchants: 'Notificato il: <1>{{ count }} Acquirente</1>',
        notifiedOn_merchants_plural: 'Notificato il: <1>{{ count }} Acquirenti</1>',
        noActiveNotifications: 'Nessuna notifica attiva',
        noChangesWarning: 'Non è stata apportata alcuna modifica dalla configurazione corrente',
        noSelectionWarning_fulfillers:
          'Non è stato selezionato alcun venditore singolo a cui effettuare la sottoscrizione',
        noSelectionWarning_merchants:
          'Non è stato selezionato alcun acquirente singolo a cui effettuare la sottoscrizione'
      },
      actions: {
        subscribeToAll_sellers_contractNotification: 'Iscriviti a tutti i venditori',
        unsubscribeToAll_sellers_contractNotification: "Annulla l'iscrizione a tutti i venditori",
        subscribeToIndividual_sellers_contractNotification: 'Iscriviti a singoli venditori',
        subscribeToAll_buyers_contractNotification: 'Iscriviti a tutti gli acquirenti',
        unsubscribeToAll_buyers_contractNotification: "Annulla l'iscrizione a tutti gli acquirenti",
        subscribeToIndividual_buyers_contractNotification: 'Iscriviti a singoli acquirenti',

        subscribeToAll_fulfillers: 'Effettua la sottoscrizione a tutti i venditori',
        unsubscribeToAll_fulfillers: 'Annulla la sottoscrizione a tutti i venditori',
        subscribeToIndividual_fulfillers: 'Effettua la sottoscrizione ai venditori singoli',
        subscribeToAll_merchants: 'Effettua la sottoscrizione a tutti gli acquirenti',
        unsubscribeToAll_merchants: 'Annulla la sottoscrizione a tutti gli acquirenti',
        subscribeToIndividual_merchants: 'Effettua la sottoscrizione agli acquirenti singoli'
      },
      selectLabel_fulfillers: 'Seleziona venditore',
      selectLabel_merchants: 'Seleziona acquirente',
      subscriptionModal: {
        selectIdentities_fulfillers: 'Seleziona venditori da configurare',
        selectIdentities_merchants: 'Seleziona acquirenti da configurare',
        selectAllIdentities_fulfillers: 'Seleziona tutti i venditori',
        selectAllIdentities_merchants: 'Seleziona tutti gli acquirenti',
        firstStepModalTitle_fulfillers: 'Configura più venditori in una volta',
        firstStepModalTitle_merchants: 'Configura più acquirenti in una volta',
        secondStepModalTitle_fulfillers: 'Seleziona il tipo di sottoscrizione dei venditori selezionati',
        secondStepModalTitle_merchants: 'Seleziona il tipo di sottoscrizione degli acquirenti selezionati',
        infoMessage_fulfillers:
          'Le sottoscrizioni correnti ai venditori singoli, qualora ce ne siano, saranno annullate da questa modifica',
        infoMessage_merchants:
          'Le sottoscrizioni correnti agli acquirenti singoli, qualora ce ne siano, saranno annullate da questa modifica'
      }
    },
    autoApprovals: {
      header: 'Le mie approvazioni automatiche',
      header2: 'Approvare automaticamente i contratti quando',
      addButton: 'Aggiungi approvazione automatica',
      filterInputLabel: 'Filtra per nome',
      approvalNeeded: 'approvazione necessaria da',
      createdByLabel: 'creato o modificato da',
      fetchingMessage: 'Recupero delle impostazioni di approvazione automatica in corso...',
      select_fulfillers: 'Seleziona venditore',
      select_fulfillers_plural: 'Seleziona venditore/i',
      select_merchants: 'Seleziona acquirente',
      select_merchants_plural: 'Seleziona acquirente/i'
    },
    features: {
      header: 'Caratteristiche',
      loading: {
        message: 'Recupero delle caratteristiche in corso...'
      },
      shippingContracts: {
        label: 'Abilita contratti di spedizione'
      },
      terminatedContracts: {
        label: 'Visualizza contratti terminati'
      },
      languageSelection: {
        label: 'Lingua'
      }
    }
  },
  createRelationships: {
    title: 'Venditore compratore',
    selectBuyer: "Sélectionnez l'acheteur",
    selectSeller: 'Sélectionnez le vendeur',
    createRelationTitle: 'Crea relazioni',
    enterSunsetPeriod: 'Archiviazione del prodotto',
    enterPriceLockin: 'Inserisci il periodo di blocco dei prezzi',
    sunsetPeriodLabel: 'giorni',
    lockinPeriodLabel: 'giorni',
    createRelationButtonText: 'Crea relazioni',
    buyerAndSellerAccountIdSameErrorMessage: 'Acquirente e Venditore non possono essere gli stessi in una relazione',
    agreementTitle: 'I tuoi accordi',
    agreementSubTitle: 'Prodotti e prezzi',
    agreementNegotiationTitle: "Con quanti giorni di anticipo accetti di avvisare l'acquirente:",
    shippingAgreementTitle: 'spedizione',
    shippingAgreementOption1: "L'acquirente paga direttamente il corriere",
    shippingAgreementOption2:
      "L'acquirente paga le spese di spedizione sostenute dal venditore durante la spedizione come prezzo di spedizione",
    shippingAgreementOption3: "L'acquirente paga al venditore un prezzo di spedizione definito",
    shippingAgreementOption4: 'Spedizione gratuita',
    notificationTitle: 'Notifica',
    notificationText: 'Facci sapere quando il contrario cambia il prezzo o archivia un prodotto',
    notificationEmailText: 'Fornisci email una per riga o separate da virgola',
    helptext: 'Fornisci email una per riga o separate da virgola'
  },
  viewRelationships: {
    messages: {
      noVersionsFound: 'Nessuna versione presente qui.'
    }
  },
  productAgreementsPage: {
    mainHeading: '{{ businessOne }} e {{ businessTwo }}',
    productsHeading: 'Prodotti',
    buyerSubHeading: 'acquisto da {{ businessTwo }}',
    sellerSubHeading: 'vendita a {{ businessTwo }}',
    terms: 'condizioni',
    productId: 'Codice prodotto',
    priceLockinSectionOne: '{{seller}} si impegna a dare {{buyer}} almeno ',
    priceLockinSectionTwo: ' giorni prima della modifica del prezzo.',
    sunsetSectionOne: '{{seller}} si impegna a dare {{buyer}} almeno',
    sunsetSectionTwo: ' giorni di preavviso prima di archiviare un prodotto che {{buyer}} sta acquistando.',
    noRelationshipAsBuyer:
      "{{ businessOne }} non ha alcuna relazione stabilita per l'acquisto da {{ businessTwo }}. Si prega di contattare {{ businessTwo }} per impostare questo.",
    noRelationshipAsSeller: '{{ businessOne }} non ha alcuna relazione stabilita per la vendita {{ businessTwo }}.',
    save: 'Salva le modifiche',
    edit: 'Modifica il valore',
    cancel: 'Annulla la modifica',
    deletePriceLock: 'Elimina blocco prezzo',
    deletePriceLockConfirmation: 'Sei sicuro di voler eliminare il blocco del prezzo per {{ productId }}?',
    addProducts: 'Aggiungi prodotti',
    search: 'Ricerca',
    selectVersions: 'Seleziona le versioni',
    view: 'Visualizza',
    columnNames: {
      productId: 'CODICE PRODOTTO',
      productName: 'NOME DEL PRODOTTO',
      versions: 'VERSIONI',
      view: 'VISUALIZZA',
      priceLock: 'PREZZO BLOCCO FINO A'
    },
    versionDeleteConfirmation1: 'Vuoi rimuovere la versione {{version}}',
    versionDeleteConfirmation2: 'Questo significa:',
    versionDeleteConfirmation3: 'Non stai più acquistando questa versione',
    versionDeleteConfirmation4: 'Stai bene non essere avvisato quando la versione è archiviata.',
    priceChangeTitle: 'Cambio di prezzo',
    productArchivingTitle: 'Archiviazione del prodotto',
    shippingTitle: 'spedizione',
    contactForShippingAgreement: `Si prega di contattare {{ contactEmailAddress }} se hai bisogno di assistenza.`,
    productInformation: 'Informazioni sul prodotto',
    buyerNotCimpressOwnedMessage: `{{accountName}} non è abilitato come acquirente sulla piattaforma!`,
    buyerNotCimpressOwnedMessageContactUs: `Contatta l'assistenza della piattaforma se {{accountName}} sta acquistando in piattaforma.`
  },
  notificationDetails: {
    acknowledge: 'Riconoscere',
    contactSeller: 'Si prega di contattare il venditore in caso di domande: Contatto',
    isArchiving: 'sta archiviando',
    startingFrom: 'Partendo da',
    noLongerAvailable: 'che stai acquistando non sarà più disponibile.',
    decidedToArchive: 'ha deciso di archiviare',
    unavailableToBuyFrom: `e non sarà più disponibile per l'acquisto a partire da`,
    nonRevocableAction: `Questa è un'azione non revocabile da`,
    ensureChangeBefore: 'quindi assicurati di aver apportato le modifiche richieste, prima',
    noDisruption: 'in modo che non ci siano interruzioni per i tuoi clienti finali.',
    acknowledgedMessage: 'ha riconosciuto questo messaggio il',
    for: 'per',
    ofThe: 'del',
    theVersion: 'la versione',
    notificationAlreadyAcknowledged: 'La notifica è stata già confermata da',
    priceUpdateEffectiveOn: `L'aggiornamento del prezzo è in vigore dal`,
    isUpdatingPricing: 'sta aggiornando i prezzi per',
    products: 'prodotti',
    priceChangeAutomaticApply: `Questa variazione di prezzo entrerà automaticamente in vigore il {{ date }}. Si prega di contattare il venditore in caso di domande.`,
    asPerYourAgreement: `In base al tuo contratto con {{ sellerName }}, verrà applicato un nuovo prezzo per i prodotti elencati di seguito a partire dal {{ date }}, dopo il periodo di notifica obbligatorio di {{ priceUpdateDays }} giorni.`,
    acknowledgementMessage: `{{ acknowledgedBy }} ha riconosciuto questo messaggio in data {{ acknowledgedAt }} per {{ buyerName }}`,
    oldPrices: 'Vecchi prezzi',
    newPrices: 'Nuovi prezzi',
    versionDifferenceHeading: `Differenza tra la versione Sunset {{sunsetVersion}} e la versione corrente / più recente`,
    versionDifferenceNote: `(le modifiche verranno evidenziate in blu)`
  },
  notifications: {
    header: 'Notifiche',
    searchByProductId: 'Numero identificativo del prodotto',
    startDate: "Data d'inizio",
    endDate: 'Data di fine',
    all: 'Tutte',
    productArchiving: 'Archiviazione del prodotto',
    priceChange: 'Cambio di prezzo',
    today: 'Oggi',
    others: 'Altre',
    acknowledgedBy: 'Riconosciuto da: ',
    fetchingMessage: 'Recupero delle tue notifiche ...',
    notificationsNotAvailable: 'Nessuna notifica disponibile',
    notificationItems: {
      PRICECHANGE: 'Nuovo prezzo per ',
      SUNSET: 'Archiviazione di una versione di '
    },
    notificationMessage: {
      PRICECHANGE: 'Da {} ci sarà un nuovo prezzo per {}',
      SUNSET: 'A partire da {} la versione di {} che stai acquistando non sarà più disponibile'
    }
  },
  errorRobot: {
    error: 'Aggiorna la pagina per riprovare. Se il problema persiste, contattare'
  }
};
