export const AGREEMENT_RESOURCE_TYPE = 'productAgreement';
export const CONTRACT_RESOURCE_TYPE = 'pricingTerm';
export const INTRODUCTION_RESOURCE_TYPE = 'mcpProductV3';
export const SURFACE_RESOURCE_TYPE = 'surfaceSpecification';
export const RULE_RESOURCE_TYPE = 'productRules';

export const ALL = 'All';
export const INDIVIDUAL = 'Individual';
export const NONE = 'None';

export const CONTRACT_SELLER_ID_PATH = '/resource/seller/accountId';
export const CONTRACT_SELLER_TYPE_PATH = '/resource/seller/type';
export const CONTRACT_BUYER_ID_PATH = '/resource/buyer/accountId';
export const CONTRACT_BUYER_TYPE_PATH = '/resource/buyer/type';

export const PRODUCT_NESTED_PATH = '/resource/_embedded/contracts/activeTerms';
export const PRODUCT_SELLER_ID_PATH = `${PRODUCT_NESTED_PATH}/activeTerm/seller/id`;
export const PRODUCT_SELLER_TYPE_PATH = `${PRODUCT_NESTED_PATH}/activeTerm/seller/type`;
export const PRODUCT_BUYER_ID_PATH = `${PRODUCT_NESTED_PATH}/activeTerm/buyer/id`;
export const PRODUCT_BUYER_TYPE_PATH = `${PRODUCT_NESTED_PATH}/activeTerm/buyer/type`;

export const CONTRACT_NOTIFICATION = 'contractNotification';
export const PRODUCT_NOTIFICATION = 'productNotification';

export const getResourceTypes = subscriptionType => {
  switch (subscriptionType) {
    case CONTRACT_NOTIFICATION:
      return [AGREEMENT_RESOURCE_TYPE];
    case PRODUCT_NOTIFICATION:
      return [INTRODUCTION_RESOURCE_TYPE, SURFACE_RESOURCE_TYPE, RULE_RESOURCE_TYPE];
    default:
      return null;
  }
};

export const getNotificationType = resourceType => {
  switch (resourceType) {
    case AGREEMENT_RESOURCE_TYPE:
      return CONTRACT_NOTIFICATION;
    case INTRODUCTION_RESOURCE_TYPE:
    case SURFACE_RESOURCE_TYPE:
    case RULE_RESOURCE_TYPE:
      return PRODUCT_NOTIFICATION;
    default:
      return null;
  }
};
