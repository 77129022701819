import { createReducer } from '../utils';
import * as PricingTermApi from '../../contractV1_src/service/PricingTermService';
import { AVAILABLE, NOT_LOADED, UNAVAILABLE } from '../../constants/entityStatus';
import { getTermType, TermType } from '../../contractV1_src/utilities/termTypes';
export const createPricingTerm_REQUEST = 'createPricingTerm_REQUEST';
export const createPricingTerm_SUCCESS = 'createPricingTerm_SUCCESS';
export const createPricingTerm_FAILURE = 'createPricingTerm_FAILURE';

const createPricingTermHooks = {
  onRequest: 'Creating pricing term...',
  onSuccess: 'Successfully created pricing term.',
  onFailure: 'Unable to create pricing term.'
};

// Action
export const createPricingTermAction = ({
  pricingTermInfo,
  selectedSkus,
  initiator,
  acceptor,
  buyer,
  seller,
  toPendingStatus,
  priceChangeDays
}) => {
  return {
    types: [createPricingTerm_REQUEST, createPricingTerm_SUCCESS, createPricingTerm_FAILURE],
    callAPI: async () => {
      const type = getTermType(pricingTermInfo.termType);
      const createPricingTerm =
        type === TermType.LIST_PRICE
          ? PricingTermApi.createListPriceTerm
          : type === TermType.SPECIAL_PRICE
            ? PricingTermApi.createSpecialPriceTerm
            : null;

      if (!createPricingTerm) {
        return;
      }

      return await createPricingTerm({
        pricingTermInfo,
        selectedSkus,
        initiator,
        acceptor,
        buyer,
        seller,
        toPendingStatus,
        gracePeriodApprovalDays: priceChangeDays
      });
    },
    hooks: createPricingTermHooks
  };
};

// Reducer
export default createReducer(
  {},
  {
    [createPricingTerm_SUCCESS]: (state, action) => {
      return { ...state, status: AVAILABLE };
    },
    [createPricingTerm_REQUEST]: (state, action) => {
      return { ...state, status: NOT_LOADED };
    },
    [createPricingTerm_FAILURE]: (state, action) => {
      return { ...state, status: UNAVAILABLE };
    }
  }
);
