// TODO: Temporarily copy pasting the functions here from ff-react-components because its broken.

import fp from 'lodash/fp';
import escapeElastic from 'elasticsearch-sanitize';
import get from 'lodash/get';
import chunk from 'lodash/chunk';
import pAll from 'p-all';

function checkResponse(serviceComment) {
  return res => {
    if (res.ok) {
      if (res.status === 204) {
        return res;
      }
      return res.json();
    }

    throw new Error(`Error while ${serviceComment}. (Response Code: ${res.status}`);
  };
}

function makeRequestHeader(onGetAccessToken) {
  return {
    Accept: 'application/json',
    Authorization: `Bearer ${onGetAccessToken()}`,
    'Content-Type': 'application/json'
  };
}

export const generateSkuListQuery = skuList =>
  skuList.map(sku => ({
    type: 'match',
    fieldName: 'mcpSku',
    value: sku
  }));

export const searchSku = (
  searchTerm,
  fulfillerIds,
  pageSize,
  page,
  onGetAccessToken,
  marketable,
  customQuery,
  formatMessage
) => {
  const endpointUrl = 'https://mcpsku-search.products.cimpress.io';

  const fulfillerIdQuery = fulfillerIds
    ? fulfillerIds.map(id => ({
        type: 'match',
        fieldName: 'Fulfiller Id',
        value: id
      }))
    : [];

  const marketableQuery = marketable
    ? [
        {
          type: 'match',
          fieldName: 'status',
          value: 'marketable'
        }
      ]
    : [];

  // "black shirt +" => ['black', 'shirt', '\+'] => ['(black|*black|black*|*black*)', '(shirt|shirt*|*shirt*|*shirt)', '(\+|\+*|*\+*|*\+)'] =>
  // '(black|*black|black*|*black*)+(shirt|shirt*|*shirt*|*shirt)+(\+|\+*|*\+*|*\+)'

  searchTerm = searchTerm || '';

  const elasticSearchSearchTerm = searchTerm
    .trim()
    .split(/\s+/)
    .map(word => escapeElastic(word))
    .map(word => word.toUpperCase())
    .map(word => `(${word}|*${word}|${word}*|*${word}*)`)
    .join('+');

  const searchTermQuery = {
    type: 'compound',
    operator: 'or',
    compoundQuery: customQuery || [
      {
        type: 'querySearch',
        fieldNames: ['mcpSku', 'name', 'referenceId'],
        value: elasticSearchSearchTerm
      }
    ]
  };

  const query = {
    type: 'compound',
    operator: 'and',
    compoundQuery: [...marketableQuery, ...fulfillerIdQuery, searchTermQuery]
  };

  const body = {
    page,
    pageSize: pageSize || 50,
    query
  };

  const request = new Request(`${endpointUrl}/v1/mcpskus/search`, {
    headers: makeRequestHeader(onGetAccessToken),
    method: 'POST',
    mode: 'cors',
    body: JSON.stringify(body)
  });

  return fetch(request)
    .then(checkResponse('searching for skus'))
    .then(({ count, mcpSku }) => {
      const skuMap = fp.flow(
        fp.map(({ referenceId, scenes, ...restSkuInfo }) => [
          referenceId,
          {
            sku: referenceId,
            sceneUrl: get(scenes, '[0].underlaySceneUri'),
            ...restSkuInfo
          }
        ]),
        fp.fromPairs
      )(mcpSku);

      return { count, skuMap };
    });
};

/**
 * Returns a sku information map given a list of skus.
 * Sku information contains name, description, and sceneUrl.
 * @param skuList
 * @param onGetAccessToken
 * @param marketable
 * @param addNonExistentSkus  if this is set to false, the sku will be excluded from the map if the description does not exist on the server.
 * @param skuBatchSize
 * @param rateLimit
 * @param formatMessage
 * @param onFinishedBatchFn function to call after a batch is finished. The result of skuSearch call will be passed in as an argument.
 * @returns {Promise<{ { skuMap, nonExistentSkuList, error } }>}
 */
export const getSkuInfoMapWithSkuList = (
  skuList,
  onGetAccessToken,
  marketable,
  addNonExistentSkus,
  skuBatchSize = 500,
  rateLimit = 5,
  formatMessage = () => 'searching for skus',
  onFinishedBatchFn = null
) => {
  const nonExistentSkuList = [];

  const batchedSkuList = chunk(skuList, skuBatchSize);
  const batchedPromiseActions = batchedSkuList.map(skuQuery => () => {
    const customSkuQuery = generateSkuListQuery(skuQuery);
    return searchSku(null, null, skuQuery.length, 1, onGetAccessToken, marketable, customSkuQuery, formatMessage).then(
      searchResult => {
        // pass the result onto onFinishedBatchFn if it is not null.
        onFinishedBatchFn && onFinishedBatchFn(searchResult);
        return searchResult;
      }
    );
  });

  return pAll(batchedPromiseActions, { concurrency: rateLimit })
    .then(results => {
      const skuInfoMap = {};
      results.forEach((result, index) => {
        const query = batchedSkuList[index];
        const { skuMap } = result;
        query.forEach(sku => {
          // if sku was not found in the search
          if (!skuMap[sku]) {
            // if sku was not found from the search, but the flag is on, add it.
            if (addNonExistentSkus) {
              skuInfoMap[sku] = { sku };
            } else {
              nonExistentSkuList.push(sku);
            }
          } else {
            skuInfoMap[sku] = skuMap[sku];
          }
        });
      });
      return { skuMap: skuInfoMap, nonExistentSkuList };
    })
    .catch(e => {
      if (addNonExistentSkus) {
        const skuInfoMap = skuList.reduce((skuMap, sku) => {
          skuMap[sku] = { sku };
          return skuMap;
        }, {});
        return { skuMap: skuInfoMap, nonExistentSkuList: [], error: null };
      }

      return { skuMap: {}, nonExistentSkuList: [], error: e };
    });
};

/**
 * Returns a skuMap with sku information (name, description, sceneUrl) properties added for all skus.
 * Existing properties will be persisted (but the sku information field will be overwritten if it exists).
 * If the data doesn't exist for the sku, it will not be modified.
 * @param skuInfoListToPopulate
 * @param onGetAccessToken
 * @param marketable
 * @param skuBatchSize
 * @param rateLimit
 * @param formatMessage
 * @param onFinishedBatchFn function to call after a batch is finished. The result of skuSearch call will be passed in as an argument.
 * @returns {Promise<{ { skuMap, error } }>}
 */
export const getSkuInfoMapWithSkuInfoList = (
  skuInfoListToPopulate,
  onGetAccessToken,
  marketable,
  skuBatchSize = 500,
  rateLimit = 5,
  formatMessage = () => 'searching for skus',
  onFinishedBatchFn = null
) => {
  const skus = skuInfoListToPopulate.map(skuInfo => skuInfo.sku);

  return getSkuInfoMapWithSkuList(
    skus,
    onGetAccessToken,
    marketable,
    true,
    skuBatchSize,
    rateLimit,
    formatMessage,
    onFinishedBatchFn
  )
    .then(({ skuMap, error }) => {
      const newSkuMap = skus.reduce((map, sku, index) => {
        map[sku] = { ...skuInfoListToPopulate[index], ...skuMap[sku] };
        return map;
      }, {});

      return { skuMap: newSkuMap, error };
    })
    .catch(e => ({ skuMap: {}, error: e }));
};
/**
 * Returns a new skuMap with sku information (name, description, sceneUrl) properties added for all skus.
 * Existing properties will be persisted (but the sku information field will be overwritten if it exists).
 * If the data doesn't exist for the sku, it will not be modified.
 * @param skuMapToPopulate
 * @param onGetAccessToken
 * @param marketable
 * @param skuBatchSize
 * @param rateLimit
 * @param formatMessage
 * @param onFinishedBatchFn function to call after a batch is finished. The result of skuSearch call will be passed in as an argument.

 * @returns {Promise<{ { skuMap, error } }>}
 */
export const getSkuInfoMapWithSkuMap = (
  skuMapToPopulate,
  onGetAccessToken,
  marketable,
  skuBatchSize = 500,
  rateLimit = 5,
  formatMessage = () => 'searching for skus',
  onFinishedBatchFn = null
) => {
  const skuList = Object.keys(skuMapToPopulate);
  return getSkuInfoMapWithSkuList(
    skuList,
    onGetAccessToken,
    marketable,
    true,
    skuBatchSize,
    rateLimit,
    formatMessage,
    onFinishedBatchFn
  )
    .then(({ skuMap, error }) => {
      const newSkuMap = skuList.reduce((map, sku) => {
        map[sku] = { ...skuMapToPopulate[sku], ...skuMap[sku] };
        return map;
      }, {});

      return { skuMap: newSkuMap, error };
    })
    .catch(e => ({ skuMap: {}, error: e }));
};
