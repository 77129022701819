import i18n from 'i18next';
import moment from 'moment';
import 'moment/locale/fr';
import 'moment/locale/it';
import 'moment/locale/de';
import 'moment/locale/nl';
import { initReactI18next } from 'react-i18next';
import resources from './resources';
import { DEFAULT_LANGUAGE } from '../constants/appConstants';

// Set locale globally first
// Without this we are seeing unexpected behavior and moment is trying to figure out the locale on its own
moment.locale(DEFAULT_LANGUAGE);

i18n.use(initReactI18next).init({
  lng: DEFAULT_LANGUAGE,
  fallbackLng: DEFAULT_LANGUAGE,
  resources,
  interpolation: {
    escapeValue: false,
    format: (value, format, lng) => {
      if (format === 'localDateTime') {
        return moment
          .utc(value)
          .local()
          .locale(lng)
          .format('lll');
      }
      if (format === 'localizeDuration') {
        const duration = moment.isDuration(value) ? value : moment.duration(value);
        return duration.locale(lng).humanize(true);
      }
      if (format === 'localizeDurationSimple') {
        const duration = moment.isDuration(value) ? value : moment.duration(value);
        return duration.locale(lng).humanize();
      }
      return value;
    }
    // if the format is 'localizeDuration', then take its __value__ transfom it to a moment duration object,
    // translate it to the current language and show it in humanize format
  }
});

i18n.on('languageChanged', lng => {
  moment.locale(lng);
});

export default i18n;
