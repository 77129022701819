import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Modal } from '@cimpress/react-components';

import { FFS_EMAIL_ADDRESS } from '../../../constants/appConstants';
import { actions as subsActions, selectors as subsSelectors } from '../../../reducers/subscriptions';
import { withSnackbar } from '../../snackbar/Snackbar';
import { withTranslation } from 'react-i18next';
import { useState } from 'react';
import { useEffect } from 'react';

function AuthorizationFlow(props) {
  const [closeModal, setCloseModal] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    props.checkAuthorization();
  }, []);

  const { isLoading, isAuthorized, hasError, redirectUri } = props;

  const onCloseModal = () => setCloseModal(false);
  const onAuthorize = () => props.authorize(redirectUri);

  const authErrorMsg = (
    <p>
      {t('authorizationFlow:authErrorMessage1')}
      <br />
      {t('authorizationFlow:authErrorMessage2', { FFS_EMAIL_ADDRESS })}
    </p>
  );

  const authDescription = (
    <p>
      {t('authorizationFlow:descriptionMessage1')}
      <br />
      <br />
      {t('authorizationFlow:descriptionMessage2')}
      <strong>{t('authorizationFlow:descriptionMessage3')}</strong>
      {t('authorizationFlow:descriptionMessage4')}
      <br />
      <br />
      {t('authorizationFlow:descriptionMessage5')}
      <strong> {t('common:commonWords:authorize')}</strong> {t('authorizationFlow:descriptionMessage6')}
    </p>
  );

  const authFooter = (
    <div>
      {hasError ? (
        <button onClick={onCloseModal} className="btn btn-default">
          {t('common:commonWords:close')}
        </button>
      ) : null}
      <button className="btn btn-primary" disabled={hasError} onClick={onAuthorize}>
        {t('common:commonWords:authorize')}
      </button>
    </div>
  );
  return (
    <Modal
      show={!isLoading && !isAuthorized && !closeModal}
      bsStyle={hasError ? 'danger' : 'info'}
      closeButton={hasError}
      onRequestHide={onCloseModal}
      closeOnOutsideClick={hasError}
      footer={authFooter}
      title={
        <h3 className="modal-title">{hasError ? t('authorizationFlow:errorTitle') : t('authorizationFlow:title')}</h3>
      }>
      {hasError ? authErrorMsg : authDescription}
    </Modal>
  );
}

const mapStateToProps = state => ({
  isLoading: subsSelectors.getIsAuthLoading(state),
  isAuthorized: subsSelectors.getIsAuthorized(state),
  hasError: subsSelectors.getHasAuthError(state)
});

const mapDispatchToProps = {
  checkAuthorization: subsActions.checkNotificationAuthorizationAction,
  authorize: subsActions.authorizeNotificationAction
};

export default withSnackbar(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withTranslation()(AuthorizationFlow))
);
