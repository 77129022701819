import * as productAgreementApi from '../../services/ProductAgreement';
import { createReducer } from '../utils';
import get from 'lodash/get';
import { AVAILABLE, SAVING, UNAVAILABLE } from '../../constants/entityStatus';

export const createProductAgreements_REQUEST = 'createProductAgreements_REQUEST';
export const createProductAgreements_SUCCESS = 'createProductAgreements_SUCCESS';
export const createProductAgreements_FAILURE = 'createProductAgreements_FAILURE';

const createProductAgreementsHooks = {
  onRequest: 'Creating product agreement...',
  onSuccess: 'Successfully created product agreement!',
  onFailure: 'Failed to create product agreement.'
};

// Action
export const createProductAgreementsAction = (buyer, seller, selectedProductIds) => {
  return {
    types: [createProductAgreements_REQUEST, createProductAgreements_SUCCESS, createProductAgreements_FAILURE],
    callAPI: async dispatch => {
      try {
        return await productAgreementApi.createProductAgreements(
          buyer.id,
          seller.id,
          getProductsToCreateAgreements(selectedProductIds)
        );
      } catch (e) {
        handleProductAgreementsCreationError(e);
      }
    },
    hooks: createProductAgreementsHooks
  };
};

// reducer
export default createReducer(
  {},
  {
    [createProductAgreements_SUCCESS]: (state, action) => {
      const { payload } = action;
      return { ...state, payload, status: AVAILABLE };
    },
    [createProductAgreements_REQUEST]: (state, action) => {
      return { ...state, status: SAVING };
    },
    [createProductAgreements_FAILURE]: (state, action) => {
      return { ...state, status: UNAVAILABLE };
    }
  }
);

// Selector
export const getCreatedProductAgreements = state => {
  return state.productAgreements.createdProductAgreements.payload;
};

export const getProductAgreementsCreationStatus = state => {
  return state.productAgreements.createdProductAgreements.status;
};

// handler error
const handleProductAgreementsCreationError = err => {
  let errMsg = '';
  switch (err.statusCode) {
    case 403:
      errMsg = 'You do not have the correct permissions to create this product agreement.';
      break;
    case 409:
      errMsg = 'The product agreement has already been created and therefore cannot be created.';
      break;
    case 422:
    case 400:
      errMsg = get(err, 'response.detail', 'Error in creating product agreements');
      break;
    default:
      errMsg = 'Creating product agreements was not successful.';
  }

  throw new Error(errMsg);
};

const getProductsToCreateAgreements = selectedProductIds => {
  return Object.keys(selectedProductIds).map(productId => {
    const { version } = selectedProductIds[productId];
    const product = { id: productId };
    if (version) {
      product.versions = [
        {
          version: version
        }
      ];
    }
    return product;
  });
};
