import get from 'lodash/get';

import * as productAgreementApi from '../../services/ProductAgreement';
import { createReducer } from '../utils';
import { AVAILABLE, UNAVAILABLE, LOADING } from '../../constants/entityStatus';

export const fetchShippingAgreements_Success = 'fetchShippingAgreements_Success';
export const fetchShippingAgreements_Failure = 'fetchShippingAgreements_Failure';
export const fetchShippingAgreements_Request = 'fetchShippingAgreements_Request';

export const updateShippingAgreements_Success = 'updateShippingAgreements_Success';
export const updateShippingAgreements_Failure = 'updateShippingAgreements_Failure';
export const updateShippingAgreements_Request = 'updateShippingAgreements_Request';

const fetchShippingAgreementHooks = {
  onFailure: 'Unable to fetch shipping agreement.'
};

const updateShippingAgreementHooks = {
  onFailure: 'Unable to update shipping agreement.'
};

// Action
export const fetchShippingAgreementsAction = (buyerAccountId, sellerAccountId) => {
  return {
    types: [fetchShippingAgreements_Request, fetchShippingAgreements_Success, fetchShippingAgreements_Failure],
    callAPI: async () => {
      try {
        let shippingAgreementsResponse = await productAgreementApi.getShippingAgreements(
          buyerAccountId,
          sellerAccountId
        );
        return formatShippingAgreementsResponse(shippingAgreementsResponse);
      } catch (e) {
        handleFetchShippingAgreementsError(e);
      }
    },
    hooks: fetchShippingAgreementHooks
  };
};

// Action
export const updateShippingAgreementAction = (buyerAccountId, sellerAccountId, shippingAgreementType) => {
  return {
    types: [updateShippingAgreements_Request, updateShippingAgreements_Success, updateShippingAgreements_Failure],
    callAPI: async () => {
      try {
        let shippingAgreementsResponse = await productAgreementApi.createShippingAgreements(
          buyerAccountId,
          sellerAccountId,
          shippingAgreementType
        );
        return formatShippingAgreementsResponse(shippingAgreementsResponse);
      } catch (e) {
        handleUpdateShippingAgreementsError(e);
      }
    },
    hooks: updateShippingAgreementHooks
  };
};

function formatShippingAgreementsResponse(shippingAgreementsResponse) {
  const identities = [shippingAgreementsResponse.buyer.accountId, shippingAgreementsResponse.seller.accountId];
  const key = identities.join('|');

  return {
    key: key,
    value: shippingAgreementsResponse.shippingAgreements
  };
}

// reducer
export default createReducer(
  {},
  {
    [fetchShippingAgreements_Success]: (state, action) => {
      let { payload } = action;
      let updatedPayload = { ...state.payload };
      if (payload) {
        if (updatedPayload) {
          updatedPayload[payload.key] = payload.value;
        } else {
          updatedPayload = {
            [payload.key]: payload.value
          };
        }
      }
      return { ...state, payload: updatedPayload, status: AVAILABLE };
    },
    [fetchShippingAgreements_Failure]: (state, action) => {
      return { ...state, status: UNAVAILABLE };
    },
    [fetchShippingAgreements_Request]: (state, action) => {
      return { ...state, status: LOADING };
    },
    [updateShippingAgreements_Success]: (state, action) => {
      let { payload } = action;
      let updatedPayload = { ...state.payload };
      if (payload) {
        if (updatedPayload) {
          updatedPayload[payload.key] = payload.value;
        } else {
          updatedPayload = {
            [payload.key]: payload.value
          };
        }
      }
      return { ...state, payload: updatedPayload, status: AVAILABLE };
    },
    [updateShippingAgreements_Failure]: (state, action) => {
      return { ...state, status: UNAVAILABLE };
    },
    [updateShippingAgreements_Request]: (state, action) => {
      return { ...state, status: LOADING };
    }
  }
);

export const handleFetchShippingAgreementsError = error => {
  let errorMessage = '';

  switch (error.statusCode) {
    case 403:
      errorMessage = 'You do not have the correct permissions to get the shipping agreements.';
      break;
    case 422:
    case 404:
      return null;
    case 400:
      errorMessage = get(error, 'response.detail', 'Error in getting the shipping agreements');
      break;
    default:
      errorMessage = 'Getting the shipping agreements was not successful.';
  }

  throw new Error(errorMessage);
};

export const handleUpdateShippingAgreementsError = error => {
  let errorMessage = '';

  switch (error.statusCode) {
    case 403:
      errorMessage = 'You do not have the correct permissions to update the shipping agreements.';
      break;
    case 422:
    case 400:
      errorMessage = get(error, 'response.detail', 'Error in updating the shipping agreements');
      break;
    default:
      errorMessage = 'Updating the shipping agreements was not successful.';
  }

  throw new Error(errorMessage);
};

// Selectors
export const getShippingAgreementsPayload = state => {
  return state.productAgreements.shippingAgreements.payload;
};

export const getShippingAgreementsStatus = state => {
  return state.productAgreements.shippingAgreements.status;
};
