import * as productV2SearchApi from '../../services/ProductV2Search';
import { createReducer } from '../utils';
import get from 'lodash/get';

import { AVAILABLE, NOT_LOADED, UNAVAILABLE } from '../../constants/entityStatus';
export const fetchProductVersions_REQUEST = 'fetchProductVersions_REQUEST';
export const fetchProductVersions_SUCCESS = 'fetchProductVersions_SUCCESS';
export const fetchProductVersions_FAILURE = 'fetchProductVersions_FAILURE';

const fetchProductVersionsHooks = {
  onFailure: 'Failed to fetch product versions.'
};

// Action
export const fetchProductVersionsAction = productId => {
  return {
    types: [fetchProductVersions_REQUEST, fetchProductVersions_SUCCESS, fetchProductVersions_FAILURE],
    callAPI: async () => {
      try {
        let versionSearchResponse = await productV2SearchApi.searchVersions(productId);
        return createProductVersionPayload(versionSearchResponse);
      } catch (e) {
        const errorResponse = get(e, 'response.detail', 'Error from product v2 search service');
        throw new Error(errorResponse);
      }
    },
    hooks: fetchProductVersionsHooks
  };
};

function createProductVersionPayload(productV2SearchResponse) {
  let productVersions = [];
  productV2SearchResponse.result.forEach(element => {
    productVersions.push(element.version);
  });
  return {
    productId: productV2SearchResponse.result[0].productId,
    versions: productVersions
  };
}

// Reducer
export default createReducer(
  {},
  {
    [fetchProductVersions_SUCCESS]: (state, action) => {
      const { payload } = action;
      let updatedPayload = { ...state.payload };
      updatedPayload[payload.productId] = payload.versions;
      return { ...state, payload: updatedPayload, status: AVAILABLE };
    },
    [fetchProductVersions_REQUEST]: (state, action) => {
      return { ...state, status: NOT_LOADED };
    },
    [fetchProductVersions_FAILURE]: (state, action) => {
      return { ...state, status: UNAVAILABLE };
    }
  }
);

// Selector
export const getProductVersionsDetail = state => {
  return state.productAgreements.productVersionsDetail.payload;
};

export const getProductVersionsStatus = state => {
  return state.productAgreements.productVersionsDetail.status;
};
