import { centralizedAuth as CentralizedAuth } from '@cimpress/simple-auth-wrapper';
import NotificationsHubAuthorizer from '@cimpress-technology/notifications-hub-authorizer';
import { BASENAME, isProduction } from '../utilities/processFunctions';

export const CLIENT_ID = 'iO86tEFVLEA9jGaHiTKmUJfdJ6Ygcj0a';
const accountIdClaimType = 'https://claims.cimpress.io/account';

const auth = new CentralizedAuth({
  clientID: CLIENT_ID,
  redirectRoute: BASENAME
});

const login = () => auth.login(BASENAME);

const logout = () => auth.logout(BASENAME);

const notificationsHubAuthorizer = new NotificationsHubAuthorizer({
  env: isProduction ? 'prd' : 'int',
  centralizedAuth: auth
});

const getAccount = profile => {
  return profile && profile[accountIdClaimType];
};

const getUserEmail = profile => {
  if (profile && profile.email) {
    return profile.email;
  } else {
    return '';
  }
};

export { auth, notificationsHubAuthorizer, login, logout, getAccount, getUserEmail };
