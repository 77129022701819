import * as productPriceApi from '../../services/ProductPrice';
import { createReducer, toCK } from '../utils';
import { AVAILABLE, NOT_LOADED, UNAVAILABLE } from '../../constants/entityStatus';

export const FETCH_PRICE_MODEL_REQUEST = 'FETCH_PRICE_MODEL_REQUEST';
export const FETCH_PRICE_MODEL_REQUEST_SUCCESS = 'FETCH_PRICE_MODEL_REQUEST_SUCCESS';
export const FETCH_PRICE_MODEL_REQUEST_FAILURE = 'FETCH_PRICE_MODEL_REQUEST_FAILURE';

const cachePrefix = 'term-pricemodels:';

// action creator
export const getPriceModelAction = ({ id, revisionId }) => {
  return {
    types: [FETCH_PRICE_MODEL_REQUEST, FETCH_PRICE_MODEL_REQUEST_SUCCESS, FETCH_PRICE_MODEL_REQUEST_FAILURE],
    cacheKey: `${cachePrefix}${toCK(id, revisionId)}`,
    callAPI: () => productPriceApi.getPriceModelRevisionV3(id, revisionId),
    hooks: null,
    extraPayload: {
      id,
      revisionId
    }
  };
};

// reducer
export default createReducer(
  {},
  {
    [FETCH_PRICE_MODEL_REQUEST_SUCCESS]: (state, action) => {
      let { payload } = action;
      return { ...state, payload, status: AVAILABLE };
    },
    [FETCH_PRICE_MODEL_REQUEST]: state => {
      return { ...state, status: NOT_LOADED };
    },
    [FETCH_PRICE_MODEL_REQUEST_FAILURE]: state => {
      return { ...state, status: UNAVAILABLE };
    }
  }
);

// selector
export const getPriceModelSelector = (state, Id, revisionId) => {
  return state.contractV1.priceModel[toCK(Id, revisionId)];
};
