import { combineReducers } from 'redux';

const SET_FILTERS = 'SET_FILTERS';

// ACTIONS
export const applyFilters = (filters, filterLogicMapping) => ({
  type: SET_FILTERS,
  payload: { filters, filterLogicMapping }
});

export const clearFilters = () => ({
  type: SET_FILTERS,
  payload: {}
});

// REDUCERS
const applied = (state = {}, action) => {
  switch (action.type) {
    case SET_FILTERS:
      return { ...action.payload.filters };
    default:
      return state;
  }
};
const logicMapping = (state = {}, action) => {
  switch (action.type) {
    case SET_FILTERS:
      return { ...action.payload.filterLogicMapping };
    default:
      return state;
  }
};

export default combineReducers({ applied, logicMapping });

// SELECTORS
export const getAppliedFilters = state => state.contractV1.home.filters.applied;
export const getFilterLogicMapping = state => state.contractV1.home.filters.logicMapping;
