import { AVAILABLE, SAVING, UNAVAILABLE } from '../../../constants/entityStatus';
import { getResourceTypes } from '../../../contractV1_src/constants/subscriptionConstants';
import * as subscriptionsApi from '../../../contractV1_src/service/Subscriptions';
import { createReducer } from '../../utils';
import { fetchAllSubscriptions } from './fetchAllSubscriptions';

const subscribeNotificationHooks = {
  onFailure: 'Something went wrong while subscribing to notifications.'
};

const NOTIFICATIONS_SUBSCRIBE_REQUEST = 'NOTIFICATIONS_SUBSCRIBE_REQUEST';
const NOTIFICATIONS_SUBSCRIBE_FAILURE = 'NOTIFICATIONS_SUBSCRIBE_FAILURE';
const NOTIFICATIONS_SUBSCRIBE_SUCCESS = 'NOTIFICATIONS_SUBSCRIBE_SUCCESS';

export const subscribeToNotificationsAction = (
  previousSubscriptionIds,
  subscriptionType,
  subscriberIds,
  subscriberType,
  notificationType,
  counterParties = null
) => {
  return {
    types: [NOTIFICATIONS_SUBSCRIBE_REQUEST, NOTIFICATIONS_SUBSCRIBE_SUCCESS, NOTIFICATIONS_SUBSCRIBE_FAILURE],
    callAPI: async dispatch => {
      await subscriptionsApi.unsubscribe(previousSubscriptionIds, notificationType);
      const resourceTypes = getResourceTypes(notificationType);
      const response = await Promise.all(
        [...resourceTypes].map(resourceType =>
          subscriptionsApi.subscribe(subscriptionType, subscriberIds, subscriberType, counterParties, resourceType)
        )
      );
      resourceTypes.forEach(resourceType => {
        dispatch(fetchAllSubscriptions(resourceType));
      });
      return response;
    },
    hooks: subscribeNotificationHooks
  };
};

// reducer
export default createReducer(
  {},
  {
    [NOTIFICATIONS_SUBSCRIBE_REQUEST]: (state, action) => {
      return { ...state, status: SAVING };
    },
    [NOTIFICATIONS_SUBSCRIBE_SUCCESS]: (state, action) => {
      return { ...state, status: AVAILABLE };
    },
    [NOTIFICATIONS_SUBSCRIBE_FAILURE]: (state, action) => {
      return { ...state, status: UNAVAILABLE };
    }
  }
);

export const getSubscribeToNotificationsStatus = state => {
  return state.subscriptions.subscribeToNotifications.status;
};
