import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@cimpress/react-components';
import { connect } from 'react-redux';
import CreateRelationships from '../createRelationships/CreateRelationships';
import { actions as productAgreementActions } from '../../../reducers/productagreements';
function NoRelationSection({ buyer, seller, relationshipDataKey, fetchRelationships }) {
  const { t } = useTranslation();
  const [relationship, setRelationship] = useState(undefined);
  const [isCreateRelationship, setIsCreateRelationship] = useState(false);
  const [manageAccountId, setManageAccountId] = useState();

  useEffect(
    () => {
      let isMount = true;
      if (isMount && relationshipDataKey) {
        const businessIds = relationshipDataKey.split('|');
        setManageAccountId(businessIds[0]);
      }
      return () => {
        isMount = false;
      };
    },
    [relationshipDataKey]
  );
  const onCloseCreateRelationshipModal = () => {
    setIsCreateRelationship(false);
  };

  const onCreateRelationship = () => {
    setRelationship({
      sellerAccountId: seller.accountId,
      sellerName: seller.name,
      buyerAccountId: buyer.accountId,
      buyerName: buyer.name
    });
    setIsCreateRelationship(true);
  };

  const fetchRelationship = () => {
    const businessIds = relationshipDataKey.split('|');
    fetchRelationships(businessIds, manageAccountId);
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-12 content-style-product-agreements">
          {t('productAgreementsPage:noRelationshipAsBuyer', {
            businessOne: buyer.name,
            businessTwo: seller.name
          })}
          <div align="right">
            <Button onClick={onCreateRelationship} type="primary">
              {t('createRelationships:createRelationButtonText')}
            </Button>
          </div>
        </div>
      </div>
      {isCreateRelationship && (
        <CreateRelationships
          onRelationshipCreate={fetchRelationship}
          onCloseModal={onCloseCreateRelationshipModal}
          isOpenInModal={true}
          relationship={relationship}
          manageAccountId={manageAccountId}
        />
      )}
    </div>
  );
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  fetchRelationships: productAgreementActions.fetchRelationshipsAction
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NoRelationSection);
