import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  actions as productAgreementActions,
  selectors as productAgreementSelectors
} from '../../../reducers/productagreements';
import ShippingContract from '../ShippingContract';
import { Button, Tooltip } from '@cimpress/react-components';
import Loading from '../../Loading';
import { AVAILABLE } from '../../../constants/entityStatus';
import { auth, getUserEmail } from '../../../utils/auth';
import { PHOENIX_SQUAD_EMAILS } from '../../../constants/accounts';

const userEmailId = getUserEmail(auth.getProfile());
const allowToEdit = PHOENIX_SQUAD_EMAILS.includes(userEmailId);
const tooltipStyle = {
  width: '250px'
};

function Shipping({
  buyer,
  seller,
  allShippingAgreements,
  allShippingAgreementsStatus,
  fetchShippingAgreements,
  updateShippingAgreement
}) {
  const { t } = useTranslation();
  const [selectedShippingType, setSelectedShippingType] = useState();
  const [hideShippingAgreementTypeOptions, setHideShippingAgreementTypeOptions] = useState(false);

  const buyerAccountId = buyer.accountId;
  const sellerAccountId = seller.accountId;
  const key = `${buyerAccountId}|${sellerAccountId}`;

  useEffect(
    () => {
      if (!allShippingAgreements || !allShippingAgreements[key]) {
        fetchShippingAgreements(buyerAccountId, sellerAccountId);
      }
    },
    [sellerAccountId, buyerAccountId]
  );

  useEffect(
    () => {
      if (allShippingAgreements && allShippingAgreements[key]) {
        let shippingAgreement = allShippingAgreements[key][0];
        if (
          allShippingAgreements[key].length > 1 ||
          (shippingAgreement.countries && shippingAgreement.countries.length > 0) ||
          (shippingAgreement.productIds && shippingAgreement.productIds.length > 0) ||
          (shippingAgreement.carriers && shippingAgreement.carriers.length > 0)
        ) {
          setHideShippingAgreementTypeOptions(true);
        } else {
          setSelectedShippingType(shippingAgreement.shippingAgreementTypeId);
        }
      }
    },
    [allShippingAgreements, buyerAccountId, sellerAccountId]
  );

  const isLoading = allShippingAgreementsStatus !== AVAILABLE;

  const onClickSave = () => {
    updateShippingAgreement(buyerAccountId, sellerAccountId, selectedShippingType);
  };

  const saveOption = () => {
    if (allowToEdit && !hideShippingAgreementTypeOptions) {
      return (
        <Button type="primary" onClick={onClickSave} style={{ minWidth: '100px', alignSelf: 'right' }}>
          {t('common:commonWords.save')}
        </Button>
      );
    } else {
      return '';
    }
  };

  const shippingAgreementSelection = () => {
    if (hideShippingAgreementTypeOptions) {
      return '';
    } else {
      return (
        <ShippingContract
          selectedShippingType={selectedShippingType}
          onChange={setSelectedShippingType}
          allowToEdit={allowToEdit}
        />
      );
    }
  };

  return (
    <Fragment>
      <b>{t('productAgreementsPage:shippingTitle')}</b>
      <br />
      {isLoading ? (
        <Loading size="medium" />
      ) : (
        <div className="box">
          {shippingAgreementSelection()}
          <br />
          <Tooltip direction={'top'} contents={'PhoenixSquad@cimpresscom .onmicrosoft.com'} tooltipStyle={tooltipStyle}>
            {t('productAgreementsPage:contactForShippingAgreement', { contactEmailAddress: 'Phoenix Squad' })}
          </Tooltip>
          <br />
          <br />
          {saveOption()}
        </div>
      )}
    </Fragment>
  );
}

const mapStateToProps = state => ({
  allShippingAgreements: productAgreementSelectors.getShippingAgreementsPayload(state),
  allShippingAgreementsStatus: productAgreementSelectors.getShippingAgreementsStatus(state)
});

const mapDispatchToProps = {
  fetchShippingAgreements: productAgreementActions.fetchShippingAgreementsAction,
  updateShippingAgreement: productAgreementActions.updateShippingAgreementAction
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Shipping);
