import React from 'react';
import { withTranslation } from 'react-i18next';

import BreadcrumbsEntry from '../common/breadcrumbs/BreadcrumbsEntry';
import BasicLayout from '../layouts/BasicLayout';

export class NotFound extends React.Component {
  render() {
    return (
      <BreadcrumbsEntry title={{ translationKey: 'breadcrumbs:notFound' }}>
        <BasicLayout>
          <h1>{this.props.t('errors:error_notFound')}</h1>
        </BasicLayout>
      </BreadcrumbsEntry>
    );
  }
}

export default withTranslation()(NotFound);
