// @flow
import '@babel/polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'react-redux';

import { store, persistor } from './stores/store';
import { PersistGate } from 'redux-persist/integration/react';

import './styles/shared.css';
import './styles/HomePage.css';
import './styles/ViewTermsPage.css';
import './styles/SettingsPage.css';
import './styles/Toggle.css';
import './styles/NotificationPage.css';
import './styles/card.css';
import './styles/productDetails.css';
import './styles/ProductListing.css';

import './components/contracts/createContract/createContract.css';
import { CssLoader } from '@cimpress/react-components/lib/index';
import './i18n/i18n';
import { history } from './utils/history';
import { Route, Router, Switch } from 'react-router-dom';

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <CssLoader>
        <Router history={history}>
          <Switch>
            <Route path="/" component={App} />
          </Switch>
        </Router>
      </CssLoader>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);
