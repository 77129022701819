import find from 'lodash/find';
import get from 'lodash/get';
import reduce from 'lodash/reduce';

import {
  ALL,
  CONTRACT_BUYER_ID_PATH,
  CONTRACT_BUYER_TYPE_PATH,
  INDIVIDUAL,
  CONTRACT_SELLER_ID_PATH,
  CONTRACT_SELLER_TYPE_PATH,
  PRODUCT_SELLER_TYPE_PATH,
  PRODUCT_SELLER_ID_PATH,
  PRODUCT_BUYER_TYPE_PATH,
  PRODUCT_BUYER_ID_PATH
} from '../../../contractV1_src/constants/subscriptionConstants';
import { FULFILLERS, MERCHANTS } from '../../../constants/transactorType';
import { getCounterType } from '../util';

export const parseSubscriptions = subscriptions => {
  const parsedSubs = subscriptions.map(sub => parseContractSubscription(sub));
  const { fulfillers, merchants } = createSubscriptionMapping(parsedSubs);
  return {
    [FULFILLERS]: mapSubscriptions(fulfillers),
    [MERCHANTS]: mapSubscriptions(merchants)
  };
};

export const subscribeNotificationMsg = {
  loading: {
    title: 'Subscribing',
    message: 'Subscribing to notifications...'
  },
  success: {
    title: 'Subscribed',
    message: 'Successfully subscribed to notifications.'
  },
  failure: {
    title: 'Failed to Subscribe',
    message: 'Something went wrong while subscribing to notifications.'
  }
};

export const unsubscribeNotificationMsg = {
  loading: {
    title: 'Unsubscribing',
    message: 'Unsubscribing from notifications...'
  },
  success: {
    title: 'Unsubscribed',
    message: 'Successfully unsubscribed from notifications.'
  },
  failure: {
    title: 'Failed to Unsubscribe',
    message: 'Something went wrong while unsubscribing from notifications.'
  }
};

const mapSubscriptions = identityMap => {
  return reduce(
    identityMap,
    (map, counterParties, id) => {
      const subscribedToAll = find(counterParties, ({ counterPartyId }) => counterPartyId === ALL);
      map[id] = subscribedToAll
        ? { subscriptionType: ALL, counterParties: subscribedToAll }
        : { subscriptionType: INDIVIDUAL, counterParties };
      return map;
    },
    {}
  );
};

const createSubscriptionMapping = parsedSubs => {
  return parsedSubs.reduce(
    (map, parsedSub) => {
      const { subscriptionId, sellerType, sellerId, buyerType, buyerId } = parsedSub;
      const sellerExists = sellerType && sellerId;
      const buyerExists = buyerType && buyerId;

      if (sellerExists) {
        const counterPartyId = buyerExists ? buyerId : ALL;
        const counterPartyObject = { counterPartyType: getCounterType(sellerType), counterPartyId, subscriptionId };
        (map[FULFILLERS][sellerId] || (map[FULFILLERS][sellerId] = [])).push(counterPartyObject);
      }

      if (buyerExists) {
        const counterPartyId = sellerExists ? sellerId : ALL;
        const counterPartyObject = { counterPartyType: getCounterType(buyerType), counterPartyId, subscriptionId };
        (map[MERCHANTS][buyerId] || (map[MERCHANTS][buyerId] = [])).push(counterPartyObject);
      }

      return map;
    },
    { [FULFILLERS]: {}, [MERCHANTS]: {} }
  );
};

const parseContractSubscription = subscription => {
  const { id, resourceFilters } = subscription;

  const sellerType = parseFilter(resourceFilters, CONTRACT_SELLER_TYPE_PATH);
  const sellerId = parseFilter(resourceFilters, CONTRACT_SELLER_ID_PATH);
  const buyerType = parseFilter(resourceFilters, CONTRACT_BUYER_TYPE_PATH);
  const buyerId = parseFilter(resourceFilters, CONTRACT_BUYER_ID_PATH);
  return { subscriptionId: id, sellerType, sellerId, buyerType, buyerId };
};

export const parseProductSubscription = subscription => {
  const { id, resourceFilters } = subscription;

  const sellerType = parseFilter(resourceFilters, PRODUCT_SELLER_TYPE_PATH, 'contains');
  const sellerId = parseFilter(resourceFilters, PRODUCT_SELLER_ID_PATH, 'contains');
  const buyerType = parseFilter(resourceFilters, PRODUCT_BUYER_TYPE_PATH, 'contains');
  const buyerId = parseFilter(resourceFilters, PRODUCT_BUYER_ID_PATH, 'contains');
  return { subscriptionId: id, sellerType, sellerId, buyerType, buyerId };
};

const parseFilter = (filters, path, operator = 'equals') => {
  const doesResourceMatch = resource => resource.path === path && resource.operator === operator;
  return get(find(filters, resource => doesResourceMatch(resource, path)), 'value');
};
