import * as productAgreementApi from '../../services/ProductAgreement';
import { createReducer } from '../utils';
import { fetchAccountsAction } from '../account/fetchAccounts';
import {
  fetchProductIntroductionDetailAction,
  fetchProductsByProductIdAndVersionAction
} from './fetchProductIntroductionDetail';
import { SUNSET_NOTIFICATION_TYPE } from '../../constants/notification';
import { AVAILABLE, LOADING, UNAVAILABLE } from '../../constants/entityStatus';
import { TODAY_GROUPING_TYPE, OTHERS_GROUPING_TYPE, GROUPING_TYPE } from '../../constants/notification';
import keyBy from 'lodash/keyBy';
import isEqual from 'lodash/isEqual';
import orderBy from 'lodash/orderBy';
import get from 'lodash/get';
import moment from 'moment';
import { toDateString } from '../../utilities/dateHelper';

export const fetchNotifications_REQUEST = 'fetchNotifications_REQUEST';
export const fetchNotifications_SUCCESS = 'fetchNotifications_SUCCESS';
export const fetchNotifications_FAILURE = 'fetchNotifications_FAILURE';

export const fetchNotificationDetails_REQUEST = 'fetchNotificationDetails_REQUEST';
export const fetchNotificationDetails_SUCCESS = 'fetchNotificationDetails_SUCCESS';
export const fetchNotificationDetails_FAILURE = 'fetchNotificationDetails_FAILURE';

export const updateNotification_REQUEST = 'updateNotification_REQUEST';
export const updateNotification_SUCCESS = 'updateNotification_SUCCESS';
export const updateNotification_FAILURE = 'updateNotification_FAILURE';

const fetchNotificationsHooks = {
  onFailure: 'Failed to fetch notifications.'
};

const fetchNotificationDetailsHooks = {
  onFailure: 'Failed to fetch notification details.'
};

const updateNotificationHooks = {
  onRequest: 'Acknowledging the notification...',
  onSuccess: 'Successfully acknowledged the notification!',
  onFailure: 'Failed to acknowledge notification.'
};

// Action
export const fetchNotificationsAction = (
  buyerIds = [],
  sellerIds = [],
  productIds = [],
  startDate = toDateString(moment().subtract(2, 'months')),
  endDate = toDateString(moment())
) => {
  return {
    types: [fetchNotifications_REQUEST, fetchNotifications_SUCCESS, fetchNotifications_FAILURE],
    callAPI: async dispatch => {
      let notifications = await productAgreementApi.getNotifications(
        buyerIds,
        sellerIds,
        productIds,
        startDate,
        endDate
      );
      const accountMap = await getAccountMap(notifications, dispatch);
      const productMap = await getProductMap(notifications, dispatch);
      notifications = getUpdatedNotificationsInfo(notifications, accountMap, productMap);
      return notifications;
    },
    hooks: fetchNotificationsHooks
  };
};

// Action
export const fetchNotificationDetailsAction = notificationId => {
  return {
    types: [fetchNotificationDetails_REQUEST, fetchNotificationDetails_SUCCESS, fetchNotificationDetails_FAILURE],
    callAPI: async dispatch => {
      try {
        let notificationDetails = await productAgreementApi.getNotificationDetails(notificationId);
        let notifications = [notificationDetails];
        const accountMap = await getAccountMap(notifications, dispatch);
        const productMap = await getProductMap(notifications, dispatch);
        notifications = getUpdatedNotificationsInfo(notifications, accountMap, productMap);
        return notifications[0];
      } catch (e) {
        handleFetchNotificationDetailsError(e);
      }
    },
    hooks: fetchNotificationDetailsHooks
  };
};

// Action
export const updateNotification = notificationId => {
  return {
    types: [updateNotification_REQUEST, updateNotification_SUCCESS, updateNotification_FAILURE],
    callAPI: async () => {
      try {
        return await productAgreementApi.acknowledgeNotification(notificationId);
      } catch (e) {
        handleUpdateNotificationError(e);
      }
    },
    hooks: updateNotificationHooks
  };
};

const getAccountMap = async (notifications, dispatch) => {
  const accountIds = new Set();

  notifications.forEach(({ sellerAccountId, buyerAccountId }) => {
    accountIds.add(sellerAccountId);
    accountIds.add(buyerAccountId);
  });
  const responses = await dispatch(fetchAccountsAction(accountIds));
  const accounts = responses.payload.map(response => response.payload).filter(Boolean);
  return keyBy(accounts, 'accountId');
};

const getProductMap = async (notifications, dispatch) => {
  const productIds = new Set();
  const products = new Set();

  notifications.forEach(notification => {
    if (notification.notificationType === SUNSET_NOTIFICATION_TYPE) {
      if (notification.sunset.version) {
        products.add(`${notification.sunset.productId}|${notification.sunset.version}`);
      } else {
        productIds.add(notification.sunset.productId);
      }
    }
  });

  const responses = await dispatch(fetchProductIntroductionDetailAction(productIds));
  const productInformation = responses.payload.map(response => response);
  const productRequest = [...products].map(product => {
    const [productId, version] = product.split('|');
    return { productId, version };
  });
  const productResponses = await dispatch(fetchProductsByProductIdAndVersionAction(productRequest));
  const v2ProductInfos = productResponses.payload.map(response => response.payload);
  v2ProductInfos.forEach(v2ProductInfo => {
    if (v2ProductInfo) {
      const { productId, version } = v2ProductInfo;
      v2ProductInfo.referenceId = `${productId}|${version}`;
    }
  });
  return keyBy(productInformation.concat(v2ProductInfos), 'referenceId');
};

const getUpdatedNotificationsInfo = (notifications, accountMap, productMap) => {
  const sellerName = 'sellerName';
  const buyerName = 'buyerName';
  const productName = 'productName';
  function getName(map, key) {
    return map[key] ? map[key].name : null;
  }
  function getProductName(map, key, productId) {
    return map[key] && map[key].name ? map[key].name : productId;
  }
  const todayDate = toDateString(new Date());
  notifications.forEach(notification => {
    const { buyerAccountId, sellerAccountId, createdAt } = notification;
    const dmyCreatedAt = toDateString(createdAt);
    let key;
    if (notification.notificationType === SUNSET_NOTIFICATION_TYPE) {
      if (notification.sunset.version) {
        key = `${notification.sunset.productId}|${notification.sunset.version}`;
      } else {
        key = notification.sunset.productId;
      }
      notification.sunset[productName] = getProductName(productMap, key, notification.sunset.productId);
    }
    notification[sellerName] = getName(accountMap, sellerAccountId);
    notification[buyerName] = getName(accountMap, buyerAccountId);
    notification[GROUPING_TYPE] = isEqual(todayDate, dmyCreatedAt) ? TODAY_GROUPING_TYPE : OTHERS_GROUPING_TYPE;
  });

  return orderBy(
    orderBy(
      notifications,
      notification => {
        return moment(notification.createdAt);
      },
      'desc'
    ),
    'isAck'
  );
};

// Reducer
export default createReducer(
  {},
  {
    [fetchNotifications_REQUEST]: (state, action) => {
      return { ...state, status: LOADING };
    },
    [fetchNotifications_SUCCESS]: (state, action) => {
      const { payload } = action;
      return { ...state, payload: payload, status: AVAILABLE };
    },
    [fetchNotifications_FAILURE]: (state, action) => {
      return { ...state, status: UNAVAILABLE };
    },
    [fetchNotificationDetails_REQUEST]: (state, action) => {
      return { ...state, status: LOADING };
    },
    [fetchNotificationDetails_SUCCESS]: (state, action) => {
      const { payload } = action;
      let updatedPayload = state.payload === undefined ? [] : state.payload;
      updatedPayload.push(payload);
      return { ...state, payload: updatedPayload, status: AVAILABLE };
    },
    [fetchNotificationDetails_FAILURE]: (state, action) => {
      return { ...state, status: UNAVAILABLE };
    },
    [updateNotification_REQUEST]: (state, action) => {
      return { ...state, status: LOADING };
    },
    [updateNotification_SUCCESS]: (state, action) => {
      const { payload } = action;
      let updatedPayload = state.payload;
      if (updatedPayload !== undefined) {
        updatedPayload.forEach(notification => {
          if (notification.notificationId === payload.notificationId) {
            notification.isAck = payload.isAck;
            notification.acknowledgedBy = payload.acknowledgedBy;
            notification.acknowledgedAt = payload.acknowledgedAt;
          }
        });
      }

      return { ...state, payload: updatedPayload, status: AVAILABLE };
    },
    [updateNotification_FAILURE]: (state, action) => {
      return { ...state, status: UNAVAILABLE };
    }
  }
);

// Selector
export const getNotifications = state => {
  return state.productAgreements.notifications.payload;
};

export const getNotificationsStatus = state => {
  return state.productAgreements.notifications.status;
};

const handleFetchNotificationDetailsError = err => {
  let errMsg = '';

  switch (err.statusCode) {
    case 403:
      errMsg = 'You do not have the correct permissions to fetch the notification.';
      break;
    case 400:
      errMsg = get(err, 'response.detail', 'Error in fetching the notification.');
      break;
    default:
      errMsg = 'Fetching notification details was not successful.';
  }

  throw new Error(errMsg);
};

const handleUpdateNotificationError = err => {
  let errMsg = '';

  switch (err.statusCode) {
    case 403:
      errMsg = 'You do not have the correct permissions to update the notification.';
      break;
    case 400:
      errMsg = get(err, 'response.detail', 'Error in updating the notification.');
      break;
    default:
      errMsg = 'Updating notification details was not successful.';
  }

  throw new Error(errMsg);
};
