import { notify, updateNotification } from 'reapop';
export const createNotificationButtons = (name1, fn1, name2, fn2) => {
  const buttons = [];
  name1 && fn1 && buttons.push({ name: name1, onClick: fn1 });
  name2 && fn2 && buttons.push({ name: name2, onClick: fn2 });
  return buttons;
};

export const createReducer = (initialState, handlers) => (state = initialState, action) => {
  if (handlers.hasOwnProperty(action.type)) {
    return handlers[action.type](state, action);
  } else {
    return state;
  }
};

// CK means Composite Key (e.g. composed of termid + revisionid). Try not to export this and keep public selectors agnostic of CKs.
export const toCK = (...keys) => keys.join('@');

export const createApiLoadNotificationAction = (title, message) => {
  const notifyRequest = {
    title,
    message,
    status: 'loading',
    dismissible: false,
    dismissAfter: 0
  };

  return notify(notifyRequest);
};

export const createFinishApiLoadNotificationAction = ({
  notificationHandle,
  title,
  message,
  isSuccess,
  successButtons,
  notSuccessButtons,
  errObject
}) => {
  const { errorMessage, mailTo } = errObject ? constructReportableError(errObject) : {};

  let errorButtons = [];

  if (notSuccessButtons && notSuccessButtons.length) {
    errorButtons = notSuccessButtons;
  }

  if (errObject) {
    const contactUsButton = { name: 'Contact Team', onClick: () => window.open(mailTo) };
    errorButtons.push(contactUsButton);
  }

  const notifyUpdateRequest = {
    ...notificationHandle,
    title: title || notificationHandle.title,
    message: message || errorMessage || notificationHandle.message,
    status: isSuccess ? 'success' : 'error',
    dismissible: true,
    dismissAfter: isSuccess ? 8000 : 20000,
    buttons: isSuccess ? successButtons : errorButtons
  };

  if (notificationHandle) {
    return updateNotification(notifyUpdateRequest);
  }

  return notify(notifyUpdateRequest);
};

/**
 * Creates a dispatchable action object with an API error object/string with the reapop notification action type.
 * @param err object/string
 * @returns an action
 */

export const createApiErrorNotificationAction = err => {
  const { title, errorMessage, mailTo } = constructReportableError(err);

  const notifyRequest = {
    title: title,
    message: errorMessage,
    status: 'error',
    dismissAfter: 20000,
    buttons: [{ name: 'Contact Team', onClick: () => window.open(mailTo) }]
  };

  return notify(notifyRequest);
};

const constructReportableError = err => {
  const createMailToContent = (title, errorMessage, errorObject) => {
    // must escape &
    const supportEmail = 'FinancialFlowsSupport@cimpress.com';
    const serviceName = 'Contract Manager';
    const currentLocation = encodeURI(window.location.href);
    const encodedTitle = encodeURI(title);
    const encodedErrorMessage = encodeURI(errorMessage);
    const encodedErrorObject = encodeURI(stringifyError(errorObject));
    return `mailto: ${supportEmail}?subject=${serviceName}: ${encodedTitle}&body=Location: ${currentLocation}%0A${encodedErrorMessage}%0A%0AErrorObject:${encodedErrorObject}`;
  };

  // default values
  let title = 'Something went wrong';
  let errorMessage = 'Something went wrong during an API call.';

  if (typeof err === 'object') {
    // extract the keys from the error object. These keys will exist if the request was made using the custom `makeRequest` method.
    // the message will exist if it was the generic javascript Error. This means the error didn't come directly from the API.
    const { serviceComment, response, statusCode, message } = err;

    if (typeof serviceComment === 'string') {
      title = `Something went wrong while ${serviceComment}`;
    }

    if (typeof response === 'string') {
      errorMessage = `\nDetail: ${response}`;
    }

    if (typeof response === 'object') {
      // response can also be a generic javascript Error so if `message` field exists, use that as the error.
      if (response.message) {
        errorMessage = `\nDetail: ${JSON.stringify(response.message)}`;
      } else {
        errorMessage = `\nDetail: ${JSON.stringify(response)}`;
      }
    }

    if (typeof message === 'string') {
      errorMessage = `\nDetail: ${JSON.stringify(message)}`;
    }

    if (typeof statusCode === 'string' || typeof statusCode === 'number') {
      errorMessage += `\nStatus Code: ${statusCode}`;
    }
  } else if (typeof err === 'string') {
    errorMessage = err;
  }

  return { title, errorMessage, mailTo: createMailToContent(title, errorMessage, err) };
};

const stringifyError = function(err, filter, space) {
  if (typeof err !== 'object') {
    return '';
  }

  const plainObject = {};
  Object.getOwnPropertyNames(err).forEach(function(key) {
    plainObject[key] = err[key];
  });
  return JSON.stringify(plainObject, filter, space);
};
