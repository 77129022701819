import { createReducer } from '../utils';
import * as merchantConfigurationApi from '../../contractV1_src/service/MerchantConfiguration';
import { AVAILABLE, NOT_LOADED, UNAVAILABLE } from '../../constants/entityStatus';

export const fetchMerchantConfiguration_REQUEST = 'fetchMerchantConfiguration_REQUEST';
export const fetchMerchantConfiguration_SUCCESS = 'fetchMerchantConfiguration_SUCCESS';
export const fetchMerchantConfiguration_FAILURE = 'fetchMerchantConfiguration_FAILURE';

const cacheKey = 'merchants';
const cacheInMs = 3600000; // 1 hour

const fetchMerchantConfigurationDataHooks = {
  onFailure: 'Unable to fetch merchant configuration data.'
};

// Action
export const fetchMerchantConfigurationData = () => {
  return {
    types: [fetchMerchantConfiguration_REQUEST, fetchMerchantConfiguration_SUCCESS, fetchMerchantConfiguration_FAILURE],
    cacheTtlMs: cacheInMs,
    cacheKey: cacheKey,
    callAPI: async () => {
      const merchantConfigurationData = await merchantConfigurationApi.getMerchants();
      return {
        create: createDataMap(merchantConfigurationData),
        filter: createMerchantsMap(merchantConfigurationData)
      };
    },
    hooks: fetchMerchantConfigurationDataHooks
  };
};

const createDataMap = merchantConfigurationData => {
  const merchantConfigurationDataMap = {};

  merchantConfigurationData.forEach(merchant => {
    const { accountId, merchantId, merchantName } = merchant;
    if (accountId) {
      if (accountId in merchantConfigurationDataMap) {
        const merchantsForAccount = merchantConfigurationDataMap[accountId];
        merchantsForAccount.push({ merchantId: merchantId, displayName: merchantName, accountId: accountId });
        merchantConfigurationDataMap[accountId] = merchantsForAccount;
      } else {
        merchantConfigurationDataMap[accountId] = [
          { merchantId: merchantId, displayName: merchantName, accountId: accountId }
        ];
      }
    }
  });
  return merchantConfigurationDataMap;
};

const createMerchantsMap = merchantConfigurationData => {
  return merchantConfigurationData.map(each => {
    return {
      accountId: each.accountId ? each.accountId : undefined,
      id: each.merchantId,
      label: each.merchantName + ' (merchants)',
      name: each.merchantName,
      type: 'merchants',
      value: each.accountId ? each.accountId : ' @merchants'
    };
  });
};

// Reducer
export default createReducer(
  {},
  {
    [fetchMerchantConfiguration_SUCCESS]: (state, action) => {
      let { payload } = action;
      return { ...state, payload, status: AVAILABLE };
    },
    [fetchMerchantConfiguration_REQUEST]: (state, action) => {
      return { ...state, status: NOT_LOADED };
    },
    [fetchMerchantConfiguration_FAILURE]: (state, action) => {
      return { ...state, status: UNAVAILABLE };
    }
  }
);

// Selectors
export const getMerchantConfigurationData = (state, type = 'create') => {
  if (type === 'filter') {
    return state.contractV1.merchants.payload ? state.contractV1.merchants.payload.filter : undefined;
  } else {
    return state.contractV1.merchants.payload ? state.contractV1.merchants.payload.create : undefined;
  }
};

export const getMerchantConfigurationStatus = state => {
  return state.contractV1.merchants.status;
};
