export const TermStatus = {
  DRAFT: 'draft',
  PENDING: 'pending',
  APPROVED: 'approved',
  REJECTED: 'rejected',
  TERMINATED: 'terminated'
};

export const DisplayTermStatus = {
  AWAITING_YOUR_RESPONSE: 'Needs Review',
  PENDING_APPROVAL: 'Pending Approval',
  DRAFT: 'Draft',
  APPROVED: 'Approved',
  REJECTED: 'Rejected',
  TERMINATED: 'Terminated'
};

export default TermStatus;
