import { createApiLoadNotificationAction, createFinishApiLoadNotificationAction } from '../../reducers/utils';
import { cacheSelectors, cacheActions } from '../../reducers/cache/cache';

export default function callAPIMiddleware({ dispatch, getState }) {
  return next => action => {
    const {
      types,
      callAPI,
      // cache will be provided only when cacheKey is provided.
      cacheKey,
      cacheTtlMs,
      extraPayload = {}
    } = action;

    const { onRequest, onSuccess, onFailure } = constructHooks(action.hooks || {});

    if (!types) {
      // Normal action: pass it on
      return next(action);
    }

    if (!Array.isArray(types) || types.length !== 3 || !types.every(type => typeof type === 'string')) {
      throw new Error('Expected an array of three string types.');
    }

    if (typeof callAPI !== 'function') {
      throw new Error('Expected callAPI to be a function.');
    }

    if (typeof cacheKey !== 'undefined' && typeof cacheKey !== 'string') {
      throw new Error('Expected cacheKey to be undefined or a string.');
    }

    if (typeof cacheKey === 'undefined' && typeof cacheTtlMs !== 'undefined') {
      console.warn('cacheKey must be defined to use cacheTtlMs. Ignoring cacheTtlMs.');
    }

    if (typeof cacheTtlMs !== 'undefined' && typeof cacheTtlMs !== 'number') {
      throw new Error('Expected ttlMs to be undefined or a number.');
    }

    const [requestType, successType, failureType] = types;

    const metadata = { time: Date.now() };
    dispatch({ type: requestType, extraPayload, __meta__: metadata });
    const onRequestHandle = onRequest({ dispatch });

    const useCache = !!cacheKey;
    let promise = callAPI;
    let cacheHit = false;

    if (useCache) {
      const cache = cacheSelectors.getNonExpiredCache(getState(), cacheKey);

      // if cache is found, replace the callAPI with the cache.
      if (cache) {
        cacheHit = true;
        promise = () => Promise.resolve(cache);
      }
    }

    return promise(dispatch).then(
      response => {
        const cacheMetadata = { useCache, cacheHit, cacheKey };
        const metadata = { cache: { ...cacheMetadata }, time: Date.now() };
        const responsePayload = { payload: response, type: successType, extraPayload, __meta__: metadata };

        if (useCache && !cacheHit) {
          dispatch(cacheActions.addCache({ thingToCache: response, cacheKey, ttlMs: cacheTtlMs }));
        }

        onSuccess({ ...responsePayload, onRequestHandle, dispatch });
        return dispatch(responsePayload);
      },
      error => {
        const metadata = { time: Date.now() };
        onFailure({ error, onRequestHandle, dispatch });
        throw dispatch({ payload: error, type: failureType, extraPayload, __meta__: metadata });
      }
    );
  };
}

// these hook functions can be either a string or a function. If string is passed, that will be used as the notification message.
const constructHooks = ({ onRequest = () => {}, onSuccess = () => {}, onFailure = () => {} } = {}) => {
  return {
    onRequest:
      typeof onRequest === 'string'
        ? ({ dispatch }) => dispatch(createApiLoadNotificationAction(onRequest))
        : onRequest,
    onSuccess:
      typeof onSuccess === 'string'
        ? ({ response, onRequestHandle, dispatch }) =>
            dispatch(
              createFinishApiLoadNotificationAction({
                notificationHandle: onRequestHandle,
                title: onSuccess,
                isSuccess: true
              })
            )
        : onSuccess,
    onFailure:
      typeof onFailure === 'string'
        ? ({ error, onRequestHandle, dispatch }) =>
            dispatch(
              createFinishApiLoadNotificationAction({
                notificationHandle: onRequestHandle,
                title: onFailure,
                isSuccess: false,
                errObject: error
              })
            )
        : onFailure
  };
};
