import React, { PureComponent } from 'react';
import { withTranslation, Trans } from 'react-i18next';
import PropTypes from 'prop-types';
import moment from 'moment/moment';
import { css } from 'react-emotion';
import Datetime from 'react-datetime';
import { TextField, Checkbox } from '@cimpress/react-components';
import CountrySelector from '../SetupShippingPricingTermStep/CountrySelector';
import { Radio, RadioGroup } from '@cimpress/react-components';
import { ALL, INDIVIDUAL, EXCLUDE } from '../SetupShippingPricingTermStep/ShippingTermCard';
import * as colors from '@cimpress/react-components/lib/colors';
import { IsCreateFlowShippingType } from '../../../../constants/termTypes';
import { getLongDateFormat } from '../../../../utilities/displayFunctions';

const datePickerContainerStyle = css`
  display: flex;
`;

const datePickerStyle = css`
  width: 100%;
`;

const checkboxTextStyle = css`
  margin-right: 6px;
`;

const formSectionStyle = css`
  margin-bottom: 35px;
`;

const errorTextStyle = css({
  color: colors.danger.base
});

const formStyle = css({
  '.form-group': {
    marginBottom: '5px !important'
  }
});

const errorSelectStyle = css({
  '.Select-control': {
    borderColor: colors.danger.base
  }
});

class BasicTermInfoForm extends PureComponent {
  static defaultProps = {};

  static propTypes = {
    onChangeStartDate: PropTypes.func.isRequired,
    onChangeEndDate: PropTypes.func.isRequired,
    onToggleNoEndDateChecked: PropTypes.func.isRequired,
    onToggleNoDestinationCountriesChecked: PropTypes.func,
    onChangeTermName: PropTypes.func.isRequired,
    onChangeComment: PropTypes.func.isRequired,
    getValidationErrorFns: PropTypes.object.isRequired,
    startDate: PropTypes.object,
    endDate: PropTypes.object,
    noEndDateChecked: PropTypes.bool,
    noDestinationCountriesChecked: PropTypes.bool,
    termName: PropTypes.string,
    termType: PropTypes.string,
    comment: PropTypes.string,
    selectedCountryType: PropTypes.string,
    selectedCountries: PropTypes.arrayOf(PropTypes.string),
    onChangeSelectedCountries: PropTypes.func,
    onChangeDestinationCountryType: PropTypes.func,
    invalidCountryInput: PropTypes.bool
  };

  state = {
    termNameError: null,
    endDateError: null
  };

  onChangeTermName = e => {
    const { onChangeTermName, getValidationErrorFns } = this.props;
    onChangeTermName(e);
    this.setState({ termNameError: e && getValidationErrorFns.termName(e.target.value) });
  };

  onChangeEndDate = endDate => {
    const { onChangeEndDate, getValidationErrorFns } = this.props;
    onChangeEndDate(endDate);
    this.setState({ endDateError: getValidationErrorFns.endDate(endDate) });
  };

  onToggleNoEndDateChecked = e => {
    const { noEndDateChecked, endDate, onToggleNoEndDateChecked, getValidationErrorFns } = this.props;

    onToggleNoEndDateChecked(e);
    this.setState({ endDateError: getValidationErrorFns.endDate(endDate, !noEndDateChecked) });
  };

  renderDestinationCountrySelect = () => {
    const {
      t,
      selectedCountries,
      selectedCountryType,
      onChangeSelectedCountries,
      onChangeDestinationCountryType,
      invalidCountryInput
    } = this.props;

    const selectDestinationStyle = invalidCountryInput ? errorSelectStyle : null;

    const errorLabel = invalidCountryInput ? (
      <small className={errorTextStyle}>{t('createContract:termEntryForm.noCountrySelectedMessage')}</small>
    ) : null;

    const disableMultiselect = selectedCountryType === ALL;

    return (
      <div>
        <h5>{t('createContract:termEntryForm.chooseDestinationCountries')}</h5>
        <div className={formSectionStyle}>
          <React.Fragment>
            <RadioGroup
              onChange={onChangeDestinationCountryType}
              defaultSelected={selectedCountryType}
              name="countries">
              <Radio label={t('createContract:termEntryForm.allDestinationCountries')} value={ALL} />
              <Radio
                label={
                  <span>
                    <Trans i18nKey="createContract:termEntryForm.allDestinationCountriesExcept">
                      All destination countries <b>except</b>
                    </Trans>
                  </span>
                }
                value={EXCLUDE}
              />
              <Radio label={t('createContract:termEntryForm.individualDestinationCountries')} value={INDIVIDUAL} />
            </RadioGroup>
            <div className={formStyle}>
              <CountrySelector
                className={selectDestinationStyle}
                value={selectedCountries}
                onChange={onChangeSelectedCountries}
                disabled={disableMultiselect}
              />
              {errorLabel}
            </div>
          </React.Fragment>
        </div>
      </div>
    );
  };

  render() {
    const {
      t,
      i18n,
      onChangeStartDate,
      onChangeComment,
      startDate,
      endDate,
      noEndDateChecked,
      termName,
      termType,
      comment
    } = this.props;

    const { termNameError, endDateError } = this.state;

    const destinationCountrySelect = IsCreateFlowShippingType(termType) ? this.renderDestinationCountrySelect() : null;

    return (
      <div>
        <div className={formSectionStyle}>
          <h5>{t('createContract:termEntryForm.contractNameLabel')}</h5>
          <TextField
            bsStyle={termNameError && 'error'}
            required
            name={t('common:commonTerminology.contractName')}
            label={t('common:commonTerminology.contractName')}
            onChange={this.onChangeTermName}
            onBlur={this.onChangeTermName}
            value={termName}
            helpText={termNameError}
          />
        </div>
        <div className={formSectionStyle}>
          <h5>{t('createContract:termEntryForm.contractDurationLabel')}</h5>
          <div className={datePickerContainerStyle}>
            <Datetime
              locale={i18n.language}
              utc
              className={datePickerStyle}
              dateFormat={getLongDateFormat()}
              timeFormat={false}
              inputProps={{
                readOnly: true,
                required: true,
                placeholder: t('common:commonTerminology.effectiveStartDate')
              }}
              onChange={onChangeStartDate}
              closeOnSelect={true}
              isValidDate={current =>
                moment.utc(current).isSameOrAfter(moment.utc(), 'day') &&
                (noEndDateChecked || moment.utc(current).isSameOrBefore(moment.utc(endDate), 'day'))
              }
              value={startDate}
            />
            {noEndDateChecked || (
              <Datetime
                locale={i18n.language}
                utc
                className={`${datePickerStyle} ${endDateError ? 'has-error' : ''}`}
                dateFormat={getLongDateFormat()}
                timeFormat={false}
                inputProps={{
                  readOnly: true,
                  required: true,
                  placeholder: t('common:commonTerminology.effectiveEndDate'),
                  disabled: noEndDateChecked
                }}
                onChange={this.onChangeEndDate}
                closeOnSelect={true}
                isValidDate={current => moment.utc(current).isSameOrAfter(moment.utc(startDate), 'day')}
                value={endDate}
              />
            )}
          </div>
          <div className={endDateError && 'has-error'}>
            <small className="help-block">{endDateError}</small>
          </div>
          <Checkbox
            label={<span className={checkboxTextStyle}>{t('createContract:termEntryForm.noEndDate')}</span>}
            checked={noEndDateChecked}
            onChange={this.onToggleNoEndDateChecked}
          />
        </div>

        {destinationCountrySelect}
        <div className={formSectionStyle}>
          <h5>
            {t('createContract:termEntryForm.notesToApproverLabel')}
            <br />
          </h5>
          <TextField
            inputStyle={{ resize: 'vertical', minHeight: '150px', maxHeight: '300px' }}
            name="textarea"
            label={t('createContract:termEntryForm.additionalInformationLabel')}
            value={comment}
            onChange={onChangeComment}
            type="textarea"
          />
        </div>
      </div>
    );
  }
}

export default withTranslation()(BasicTermInfoForm);
