import * as productAgreementApi from '../../services/ProductAgreement';
import { createReducer } from '../utils';
import get from 'lodash/get';
import { AVAILABLE, SAVING, UNAVAILABLE } from '../../constants/entityStatus';

export const createRelationships_REQUEST = 'createRelationshipsForHomePage_REQUEST';
export const createRelationships_SUCCESS = 'createRelationshipsForHomePage_SUCCESS';
export const createRelationships_FAILURE = 'createRelationshipsForHomePage_FAILURE';

const createContractHooks = {
  onRequest: 'Creating contract...',
  onSuccess: 'Successfully created contract!',
  onFailure: 'Failed to create contract.'
};

// Action
export const createRelationshipsAction = (buyer, seller, gracePeriod, lockinPeriod) => {
  return {
    types: [createRelationships_REQUEST, createRelationships_SUCCESS, createRelationships_FAILURE],
    callAPI: async () => {
      try {
        return await productAgreementApi.createRelationships(buyer.value, seller.value, gracePeriod, lockinPeriod);
      } catch (e) {
        handleRelationshipCreationError(e);
      }
    },
    hooks: createContractHooks
  };
};

// reducer
export default createReducer(
  {},
  {
    [createRelationships_SUCCESS]: (state, action) => {
      const { payload } = action;
      return { ...state, payload, status: AVAILABLE };
    },
    [createRelationships_REQUEST]: (state, action) => {
      return { ...state, status: SAVING };
    },
    [createRelationships_FAILURE]: (state, action) => {
      return { ...state, status: UNAVAILABLE };
    }
  }
);

// Selector
export const getCreatedRelationship = state => {
  return state.productAgreements.createdRelationShip.payload;
};

export const getRelationshipCreationStatus = state => {
  return state.productAgreements.createdRelationShip.status;
};

const handleRelationshipCreationError = err => {
  let errMsg = '';

  switch (err.statusCode) {
    case 403:
      errMsg = 'You do not have the correct permissions to create this relation.';
      break;
    case 409:
      errMsg = 'The relation has already been created and therefore cannot be created.';
      break;
    case 422:
    case 400:
      errMsg = get(err, 'response.detail', 'Error in creating relationships');
      break;
    default:
      errMsg = 'Creating relationship was not successful.';
  }

  throw new Error(errMsg);
};
