import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { css } from 'react-emotion';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Tooltip } from '@cimpress/react-components';
import { actions as princingTerm } from '../../reducers/contractV1';
import { TermType, IsProductTermType } from '../../constants/termTypes';
import * as productPriceApi from '../../services/ProductPrice';
import { FFS_EMAIL_ADDRESS, MAX_INT } from '../../constants/appConstants';
import { fetchTermRevisionItems } from '../../services/PricingTerm';

const listPriceStyle = css({
  color: '#B73721',
  verticalAlign: 'sub'
});

class AgreementTypeLabel extends PureComponent {
  static propTypes = {
    term: PropTypes.object.isRequired
  };

  state = {
    isConflicted: false,
    usesDeletedModels: false
  };

  componentDidMount() {
    this.checkConflict(this.props.term);
    this.checkDeletedModels(this.props.term);
  }

  componentDidUpdate(prevProps) {
    const currentTerm = this.props.term;

    if (
      prevProps.term.termId !== currentTerm.termId ||
      prevProps.term.revisionId !== currentTerm.revisionId ||
      prevProps.term.type !== currentTerm.type
    ) {
      this.setState({ isConflicted: false, usesDeletedModels: false }, () => {
        // Because term can change before promise is resolved, we must pass in current term to check if we are adding warnings to the correct agreement label
        this.checkConflict(currentTerm);
        this.checkDeletedModels(currentTerm);
      });
    }
  }

  checkConflict = async term => {
    const { userHasAuthorizationFor, type } = term;

    if (type === TermType.SPECIAL_PRICE && userHasAuthorizationFor.seller) {
      const isConflicted = await this.fetchSpecialPriceStatus(term);
      const currentTerm = this.props.term;

      // Confirm we are checking the correct term
      if (currentTerm.termId === term.termId && currentTerm.revisionId === term.revisionId) {
        this.setState({ isConflicted });
      }
    }
  };

  checkDeletedModels = async term => {
    const { userHasAuthorizationFor, type } = term;

    if (IsProductTermType(type) && userHasAuthorizationFor.seller) {
      const { payload } = await this.props.fetchTermRevisionPriceModels(term);
      const { priceModels } = payload;

      const usesDeletedModels = await this.props
        .fetchPriceModels(priceModels)
        .then(() => false)
        .catch(e => !!(e && e.statusCode === 404));

      const currentTerm = this.props.term;

      // Confirm we are checking the correct term
      if (currentTerm.termId === term.termId && currentTerm.revisionId === term.revisionId) {
        this.setState({ usesDeletedModels });
      }
    }
  };

  fetchSpecialPriceStatus(term) {
    // TODO: Fetch only up to 500 items because of potential lag. This means we could potentially not capture
    //  the catalog/private price model conflict 100% of the time. This will eventually need a more long term solution.
    //  using the direct call here to prevent the items cache race condition in redux from happening.
    return fetchTermRevisionItems(term.termId, term.revisionId, 0, MAX_INT)
      .then(({ items }) => this.isListPrice(items, term))
      .catch(() => {
        return false;
      });
  }

  isListPrice(revisionItems, term) {
    if (revisionItems.length === 0) {
      return false;
    }

    const skus = revisionItems.map(item => item.sku);

    return productPriceApi
      .getListPriceModels(term.seller, skus, revisionItems[0].priceModel.id, revisionItems[0].priceModel.revisionId)
      .then(listPriceResponse => listPriceResponse.length !== 0);
  }

  render() {
    const { t, term } = this.props;
    const { isConflicted, usesDeletedModels } = this.state;

    const specialPriceWarning = isConflicted ? (
      <div>
        <Tooltip contents={t('common:contract.privateButDiscoverableTip')}>
          <small className={listPriceStyle}>
            <b>{t('common:contractStatus.visibleInCatalog')}</b> &nbsp;
          </small>
          {/* <Icon name="report-problem-triangle-l" color={colors.danger.darker} /> */}
        </Tooltip>
      </div>
    ) : null;

    const deletedModelsWarning = usesDeletedModels ? (
      <div>
        <Tooltip contents={t('common:contract.deletedModelsTip', { email: FFS_EMAIL_ADDRESS })}>
          <small className={listPriceStyle}>
            <b>{t('common:contractStatus.usingDeletedModel')}</b> &nbsp;
          </small>
          {/* <Icon name="report-problem-triangle-l" color={colors.danger.darker} /> */}
        </Tooltip>
      </div>
    ) : null;

    return (
      <span>
        <span>{term.displayAgreementType}</span>
        {specialPriceWarning}
        {deletedModelsWarning}
      </span>
    );
  }
}

const mapDispatchToProps = {
  fetchPriceModels: princingTerm.getPriceModelAction,
  fetchTermRevisionPriceModels: princingTerm.fetchTermRevisionPriceModels
};

export default connect(
  null,
  mapDispatchToProps
)(withTranslation()(AgreementTypeLabel));
