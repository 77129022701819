import React from 'react';
import { connect } from 'react-redux';
import Editable from '../../popOverEditor/Editable';
import {
  actions as productAgreementActions,
  selectors as productAgreementSelectors
} from '../../../reducers/productagreements';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

function PriceChange({ buyer, seller, isAllowEdit, relationship, updateRelationship, relationshipDataKey }) {
  const { t } = useTranslation();
  const { relationshipId } = relationship;
  const onSavePriceLockIn = async edit => {
    const { value } = edit;
    await updateRelationship(relationshipDataKey, relationshipId, undefined, value);
  };

  return (
    <Fragment>
      {isAllowEdit ? (
        <div>
          <b> {t('productAgreementsPage:priceChangeTitle')}</b>
          <br />
          <br />
          <div className="box">
            <div>
              <Editable
                emptyValueText="0"
                beforeContent={t('productAgreementsPage:priceLockinSectionOne', {
                  seller: seller.name,
                  buyer: buyer.name
                })}
                afterContent={t('productAgreementsPage:priceLockinSectionTwo')}
                name="pricechange"
                mode="popup"
                dataType="text"
                bootstrap4={true}
                title="Enter duration"
                value={relationship.priceLockinDuration}
                placement="left"
                showButtons={true}
                handleSubmit={onSavePriceLockIn}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="box">
          <b> {t('productAgreementsPage:priceChangeTitle')}</b>
          <br />
          <br />
          {t('productAgreementsPage:priceLockinSectionOne', {
            seller: seller.sellerName,
            buyer: buyer.buyerName
          })}
          <b>{relationship.priceLockinDuration}</b>
          {t('productAgreementsPage:priceLockinSectionTwo')}
        </div>
      )}
    </Fragment>
  );
}

const mapStateToProps = (state, ownProps) => {
  const { buyer, relationshipDataKey } = ownProps;
  return {
    relationship: productAgreementSelectors.getRelationshipForBusiness(state, buyer.accountId, relationshipDataKey)
  };
};

const mapDispatchToProps = {
  updateRelationship: productAgreementActions.updateRelationshipAction
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PriceChange);
