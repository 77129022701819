import React from 'react';
import Spinner from '@cimpress/react-components/lib/shapes/Spinner';

const spinnerStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
};

export default function Loading(props) {
  const { fullPage, size } = props;
  return fullPage ? (
    <Spinner size={size} fullPage />
  ) : (
    <div style={spinnerStyle}>
      <Spinner size={size} />
    </div>
  );
}
