import { connect } from 'react-redux';
import { displaySnackbar } from './SnackbarAction';
import { bindActionCreators } from 'redux';

export const SNACKBAR_ACTION = {
  WARNING: 'SNACKBAR_WARNING',
  ERROR: 'SNACKBAR_ERROR',
  SUCCESS: 'SNACKBAR_SUCCESS',
  INFO: 'SNACKBAR_INFO',
  SNACKBAR: 'SNACKBAR_SNACKBAR'
};

export const SnackbarType = {
  warning: 'SNACKBAR_WARNING',
  error: 'SNACKBAR_ERROR',
  success: 'SNACKBAR_SUCCESS',
  info: 'SNACKBAR_INFO'
};

const mapDispatchToProps = dispatch => {
  return {
    createSnackbar: bindActionCreators(displaySnackbar, dispatch)
  };
};

export const withSnackbar = component => {
  return connect(
    null,
    mapDispatchToProps
  )(component);
};
