/*
 * Fancy ASCII diagram for how these states work:
 *
 *  −→ NOT_LOADED
 *         ↓
 *      LOADING ←→ UNAVAILABLE
 *         ↓           ↑
 *     AVAILABLE ←−→ SAVING
 */

// Stable states
export const NOT_LOADED = 'NOT_LOADED';
export const AVAILABLE = 'AVAILABLE';
export const UNAVAILABLE = 'UNAVAILABLE';

// Transition states
export const LOADING = 'LOADING';
export const UPDATING = 'UPDATING';
export const SAVING = 'SAVING';
