import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import OptionCard from '../shared/OptionCard/OptionCard';
import {
  TermTypeLabel,
  createFlowIsShippingPriceTermType,
  createFlowTermTypeHasModifier,
  getTermModifierType
} from '../../../../constants/termTypes';
import BasicTermInfoForm from '../shared/BasicTermInfoForm';
import ModifiersInfoForm from '../shared/ModifiersInfoForm';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import { filterOutExcludedCountries, getAllCountryCodes } from '../../../../utilities/countryHelpers';
import ShippingPriceTermInfoForm from './ShippingPriceTermInfoForm';
import { DISCOUNT, MARKUP } from '../../../../constants/modifierType';
import { connect } from 'react-redux';
import { selectors as shippingPriceSelectors } from '../../../../reducers/contractV1/shippingprice';

export const ALL = 'All';
export const INDIVIDUAL = 'Individual';
export const EXCLUDE = 'Exclude';

const emptyShippingTerm = {
  termType: undefined,
  startDate: moment.utc(),
  endDate: undefined,
  noEndDateChecked: true,
  selectedCountries: [],
  countriesToShowInSelect: [],
  selectedCountryType: ALL,
  termName: undefined,
  comment: undefined,
  modifierAmount: undefined,
  countries: []
};

class ShippingTermCard extends PureComponent {
  static defaultProps = {
    isEditing: false
  };

  static propTypes = {
    onCreateTerm: PropTypes.func.isRequired,
    validateForm: PropTypes.func.isRequired,
    shippingPricingTerm: PropTypes.object,
    isEditing: PropTypes.bool
  };

  constructor(props) {
    super(props);

    this.state = isEmpty(props.shippingPricingTerm)
      ? emptyShippingTerm
      : {
          ...props.shippingPricingTerm
        };
  }

  componentDidMount() {
    const termSelected = this.state.termType !== undefined;

    termSelected && this.props.validateForm(this.hasValidationErrors());
  }

  getTermInfo = () => {
    const { onCreateTerm, validateForm, shippingPriceModel } = this.props;
    const {
      termType,
      startDate,
      endDate,
      noEndDateChecked,
      termName,
      comment,
      modifierAmount,
      countries,
      selectedCountries,
      selectedCountryType
    } = this.state;

    if (!termType) {
      validateForm(false);
    } else {
      validateForm(this.hasValidationErrors());
    }

    const termObject = !termType
      ? emptyShippingTerm
      : {
          termType,
          startDate,
          endDate,
          noEndDateChecked,
          termName,
          comment,
          modifierAmount,
          countries,
          selectedCountries,
          selectedCountryType,
          shippingPriceModel
        };

    onCreateTerm(termObject);
  };

  onChangeTermType = selectedTermType => {
    this.setState({ termType: selectedTermType ? selectedTermType.value : null }, () => this.getTermInfo());
  };

  onChangeEffectiveStartDate = startDate => {
    this.setState({ startDate }, () => this.getTermInfo());
  };

  onChangeEffectiveEndDate = endDate => {
    this.setState({ endDate }, () => this.getTermInfo());
  };

  onToggleNoEndDateChecked = () => {
    this.setState({ noEndDateChecked: !this.state.noEndDateChecked }, () => this.getTermInfo());
  };

  onToggleNoDestinationCountriesChecked = () => {
    this.setState({ noDestinationCountriesChecked: !this.state.noDestinationCountriesChecked }, () =>
      this.getTermInfo()
    );
  };

  onChangeTermName = termName => {
    this.setState({ termName: termName && termName.target.value }, () => this.getTermInfo());
  };

  onChangeComment = comment => {
    this.setState({ comment: comment && comment.target.value }, () => this.getTermInfo());
  };

  onChangeModifierAmount = modifierAmount => {
    this.setState({ modifierAmount }, () => this.getTermInfo());
  };

  onChangeDestinationCountryType = (e, selectedCountryType) => {
    const { selectedCountries } = this.state;

    this.setState({ selectedCountryType }, () => this.getTermInfo());
    this.setCountriesOnTerm(selectedCountries, selectedCountryType);
  };

  onChangeSelectedCountries = value => {
    const { selectedCountryType } = this.state;
    const selectedCountries = value.map(({ value }) => value);
    this.setState({ selectedCountries, countriesToShowInSelect: value }, () => this.getTermInfo());
    this.setCountriesOnTerm(selectedCountries, selectedCountryType);
  };

  setCountriesOnTerm = (selectedCountries, selectedCountryType) => {
    let countries;

    switch (selectedCountryType) {
      case INDIVIDUAL:
        countries = selectedCountries;
        break;
      case EXCLUDE:
        countries = filterOutExcludedCountries(selectedCountries);
        break;
      default:
        countries = getAllCountryCodes();
    }

    this.setState({ countries: countries }, () => this.getTermInfo());
  };

  getTermNameValidationError = termName => {
    let error;

    if (!termName || termName.trim() === '') {
      error = this.props.t('createContract:messages.contractNameMissingMessage');
    }

    return error;
  };

  // NOTE: we currently only support markups for shipping contracts, but
  // leaving it flexible in case we support discounts in the future and to share the
  // ModifiersInfoForm component with pricing term card
  getModifierValidationError = (modifierType, modifierAmount) => {
    let error;

    const modifierAmountSet = modifierAmount && modifierAmount !== '';

    const modifierNumber = parseFloat(modifierAmount);

    // amount must be greater than 0 for both discount and markup
    // if the modifier type is a discount, the amount must be less than 100
    const isValidAmount = modifierNumber > 0 && (modifierType === MARKUP || modifierNumber < 100);

    const { t } = this.props;

    if (!modifierAmountSet) {
      error = t('createContract:messages.modifierEmptyMessage');
    }

    if (!isValidAmount) {
      error =
        modifierType === MARKUP
          ? t('createContract:messages.markupPercentageNotGreaterThan0Message')
          : modifierType === DISCOUNT
            ? t('createContract:messages.discountPercentageMustBeBetween0And100')
            : t('createContract:messages.modifierAmountNotValidMessage');
    }

    return error;
  };

  getEndDateValidationError = (endDate, noEndDateChecked) => {
    let error;

    if (!noEndDateChecked && !endDate) {
      error = this.props.t('createContract:messages.endDateMissingMessage');
    }

    return error;
  };

  getValidationErrorFns = {
    modifier: this.getModifierValidationError,
    termName: this.getTermNameValidationError,
    endDate: this.getEndDateValidationError
  };

  hasValidationErrors = () => {
    const { modifierAmount, termName, termType, noEndDateChecked, endDate } = this.state;
    const { shippingPriceModel } = this.props;

    const hasTermNameErrors = this.getValidationErrorFns.termName(termName) !== undefined;
    const hasModifierErrors =
      createFlowTermTypeHasModifier(termType) &&
      this.getValidationErrorFns.modifier(getTermModifierType(termType), modifierAmount) !== undefined;
    const hasEndDateErrors = this.getValidationErrorFns.endDate(endDate, noEndDateChecked) !== undefined;
    const hasShippingPriceModel = createFlowIsShippingPriceTermType(termType) && !shippingPriceModel;
    const invalidCountryInput = this.invalidCountryInput();

    return hasTermNameErrors || hasModifierErrors || hasEndDateErrors || hasShippingPriceModel || invalidCountryInput;
  };

  invalidCountryInput = () => {
    const { selectedCountries, selectedCountryType } = this.state;

    return selectedCountries.length === 0 && selectedCountryType !== ALL;
  };

  render() {
    const { t, className, flow, fetchShippingPriceModel, shippingPriceModel, ...rest } = this.props;
    const {
      termType,
      startDate,
      endDate,
      noEndDateChecked,
      noDestinationCountriesChecked,
      termName,
      comment,
      selectedCountryType,
      modifierAmount,
      countriesToShowInSelect
    } = this.state;
    const termShippingTypeOptionsMap = {};

    const countriesToShow = selectedCountryType === ALL ? [] : countriesToShowInSelect;

    const invalidCountryInput = this.invalidCountryInput();

    const basicForm = (
      <BasicTermInfoForm
        onChangeStartDate={this.onChangeEffectiveStartDate}
        startDate={startDate}
        onChangeEndDate={this.onChangeEffectiveEndDate}
        endDate={endDate}
        onToggleNoEndDateChecked={this.onToggleNoEndDateChecked}
        onToggleNoDestinationCountriesChecked={this.onToggleNoDestinationCountriesChecked}
        noEndDateChecked={noEndDateChecked}
        onChangeTermName={this.onChangeTermName}
        termName={termName}
        termType={termType}
        onChangeComment={this.onChangeComment}
        comment={comment}
        getValidationErrorFns={this.getValidationErrorFns}
        onChangeSelectedCountries={this.onChangeSelectedCountries}
        onChangeDestinationCountryType={this.onChangeDestinationCountryType}
        selectedCountries={countriesToShow}
        selectedCountryType={selectedCountryType}
        invalidCountryInput={invalidCountryInput}
        shippingPriceModel={shippingPriceModel}
        fetchShippingPriceModel={fetchShippingPriceModel}
      />
    );

    termShippingTypeOptionsMap[TermTypeLabel.FREE_SHIPPING] = () => basicForm;

    termShippingTypeOptionsMap[TermTypeLabel.SHIPPING_COST] = () => basicForm;

    termShippingTypeOptionsMap[TermTypeLabel.SHIPPING_COST_MARKUP] = () => (
      <ModifiersInfoForm
        onChangeStartDate={this.onChangeEffectiveStartDate}
        startDate={startDate}
        onChangeEndDate={this.onChangeEffectiveEndDate}
        endDate={endDate}
        onToggleNoEndDateChecked={this.onToggleNoEndDateChecked}
        onToggleNoDestinationCountriesChecked={this.onToggleNoDestinationCountriesChecked}
        noEndDateChecked={noEndDateChecked}
        noDestinationCountriesChecked={noDestinationCountriesChecked}
        onChangeTermName={this.onChangeTermName}
        termName={termName}
        termType={termType}
        onChangeComment={this.onChangeComment}
        comment={comment}
        onChangeModifierAmount={this.onChangeModifierAmount}
        modifierAmount={modifierAmount}
        getValidationErrorFns={this.getValidationErrorFns}
        onChangeSelectedCountries={this.onChangeSelectedCountries}
        onChangeDestinationCountryType={this.onChangeDestinationCountryType}
        selectedCountries={countriesToShow}
        selectedCountryType={selectedCountryType}
        invalidCountryInput={invalidCountryInput}
        modifierType={MARKUP}
        shippingPriceModel={shippingPriceModel}
        fetchShippingPriceModel={fetchShippingPriceModel}
      />
    );

    termShippingTypeOptionsMap[TermTypeLabel.SHIPPING_PRICE] = () => (
      <ShippingPriceTermInfoForm
        onChangeStartDate={this.onChangeEffectiveStartDate}
        startDate={startDate}
        onChangeEndDate={this.onChangeEffectiveEndDate}
        endDate={endDate}
        onToggleNoEndDateChecked={this.onToggleNoEndDateChecked}
        onToggleNoDestinationCountriesChecked={this.onToggleNoDestinationCountriesChecked}
        noEndDateChecked={noEndDateChecked}
        noDestinationCountriesChecked={noDestinationCountriesChecked}
        onChangeTermName={this.onChangeTermName}
        termName={termName}
        termType={termType}
        onChangeComment={this.onChangeComment}
        comment={comment}
        getValidationErrorFns={this.getValidationErrorFns}
        onChangeSelectedCountries={this.onChangeSelectedCountries}
        onChangeDestinationCountryType={this.onChangeDestinationCountryType}
        selectedCountries={countriesToShow}
        selectedCountryType={selectedCountryType}
        invalidCountryInput={invalidCountryInput}
        seller={flow.initiator}
        onGetShippingPriceModel={this.onGetShippingPriceModel}
        shippingPriceModel={shippingPriceModel}
        fetchShippingPriceModel={fetchShippingPriceModel}
      />
    );

    termShippingTypeOptionsMap[TermTypeLabel.SHIPPING_PRICE_DISCOUNT] = () => (
      <ShippingPriceTermInfoForm
        onChangeStartDate={this.onChangeEffectiveStartDate}
        startDate={startDate}
        onChangeEndDate={this.onChangeEffectiveEndDate}
        endDate={endDate}
        onToggleNoEndDateChecked={this.onToggleNoEndDateChecked}
        onToggleNoDestinationCountriesChecked={this.onToggleNoDestinationCountriesChecked}
        noEndDateChecked={noEndDateChecked}
        noDestinationCountriesChecked={noDestinationCountriesChecked}
        onChangeTermName={this.onChangeTermName}
        termName={termName}
        termType={termType}
        onChangeComment={this.onChangeComment}
        comment={comment}
        getValidationErrorFns={this.getValidationErrorFns}
        onChangeSelectedCountries={this.onChangeSelectedCountries}
        onChangeDestinationCountryType={this.onChangeDestinationCountryType}
        selectedCountries={countriesToShow}
        selectedCountryType={selectedCountryType}
        invalidCountryInput={invalidCountryInput}
        seller={flow.initiator}
        onChangeModifierAmount={this.onChangeModifierAmount}
        modifierAmount={modifierAmount}
        modifierType={DISCOUNT}
        onGetShippingPriceModel={this.onGetShippingPriceModel}
        shippingPriceModel={shippingPriceModel}
        fetchShippingPriceModel={fetchShippingPriceModel}
      />
    );

    termShippingTypeOptionsMap[TermTypeLabel.SHIPPING_PRICE_MARKUP] = () => (
      <ShippingPriceTermInfoForm
        onChangeStartDate={this.onChangeEffectiveStartDate}
        startDate={startDate}
        onChangeEndDate={this.onChangeEffectiveEndDate}
        endDate={endDate}
        onToggleNoEndDateChecked={this.onToggleNoEndDateChecked}
        onToggleNoDestinationCountriesChecked={this.onToggleNoDestinationCountriesChecked}
        noEndDateChecked={noEndDateChecked}
        noDestinationCountriesChecked={noDestinationCountriesChecked}
        onChangeTermName={this.onChangeTermName}
        termName={termName}
        termType={termType}
        onChangeComment={this.onChangeComment}
        comment={comment}
        getValidationErrorFns={this.getValidationErrorFns}
        onChangeSelectedCountries={this.onChangeSelectedCountries}
        onChangeDestinationCountryType={this.onChangeDestinationCountryType}
        selectedCountries={countriesToShow}
        selectedCountryType={selectedCountryType}
        invalidCountryInput={invalidCountryInput}
        seller={flow.initiator}
        onChangeModifierAmount={this.onChangeModifierAmount}
        modifierAmount={modifierAmount}
        modifierType={MARKUP}
        shippingPriceModel={shippingPriceModel}
        fetchShippingPriceModel={fetchShippingPriceModel}
      />
    );

    termShippingTypeOptionsMap[TermTypeLabel.SHIPPING_PRICE] = () => (
      <ShippingPriceTermInfoForm
        onChangeStartDate={this.onChangeEffectiveStartDate}
        startDate={startDate}
        onChangeEndDate={this.onChangeEffectiveEndDate}
        endDate={endDate}
        onToggleNoEndDateChecked={this.onToggleNoEndDateChecked}
        onToggleNoDestinationCountriesChecked={this.onToggleNoDestinationCountriesChecked}
        noEndDateChecked={noEndDateChecked}
        noDestinationCountriesChecked={noDestinationCountriesChecked}
        onChangeTermName={this.onChangeTermName}
        termName={termName}
        termType={termType}
        onChangeComment={this.onChangeComment}
        comment={comment}
        getValidationErrorFns={this.getValidationErrorFns}
        onChangeSelectedCountries={this.onChangeSelectedCountries}
        onChangeDestinationCountryType={this.onChangeDestinationCountryType}
        selectedCountries={countriesToShow}
        selectedCountryType={selectedCountryType}
        invalidCountryInput={invalidCountryInput}
        seller={flow.initiator}
        shippingPriceModel={shippingPriceModel}
        fetchShippingPriceModel={fetchShippingPriceModel}
      />
    );

    termShippingTypeOptionsMap[TermTypeLabel.SHIPPING_PRICE_DISCOUNT] = () => (
      <ShippingPriceTermInfoForm
        onChangeStartDate={this.onChangeEffectiveStartDate}
        startDate={startDate}
        onChangeEndDate={this.onChangeEffectiveEndDate}
        endDate={endDate}
        onToggleNoEndDateChecked={this.onToggleNoEndDateChecked}
        onToggleNoDestinationCountriesChecked={this.onToggleNoDestinationCountriesChecked}
        noEndDateChecked={noEndDateChecked}
        noDestinationCountriesChecked={noDestinationCountriesChecked}
        onChangeTermName={this.onChangeTermName}
        termName={termName}
        termType={termType}
        onChangeComment={this.onChangeComment}
        comment={comment}
        getValidationErrorFns={this.getValidationErrorFns}
        onChangeSelectedCountries={this.onChangeSelectedCountries}
        onChangeDestinationCountryType={this.onChangeDestinationCountryType}
        selectedCountries={countriesToShow}
        selectedCountryType={selectedCountryType}
        invalidCountryInput={invalidCountryInput}
        seller={flow.initiator}
        onChangeModifierAmount={this.onChangeModifierAmount}
        modifierAmount={modifierAmount}
        modifierType={DISCOUNT}
        shippingPriceModel={shippingPriceModel}
        fetchShippingPriceModel={fetchShippingPriceModel}
      />
    );

    termShippingTypeOptionsMap[TermTypeLabel.SHIPPING_PRICE_MARKUP] = () => (
      <ShippingPriceTermInfoForm
        onChangeStartDate={this.onChangeEffectiveStartDate}
        startDate={startDate}
        onChangeEndDate={this.onChangeEffectiveEndDate}
        endDate={endDate}
        onToggleNoEndDateChecked={this.onToggleNoEndDateChecked}
        onToggleNoDestinationCountriesChecked={this.onToggleNoDestinationCountriesChecked}
        noEndDateChecked={noEndDateChecked}
        noDestinationCountriesChecked={noDestinationCountriesChecked}
        onChangeTermName={this.onChangeTermName}
        termName={termName}
        termType={termType}
        onChangeComment={this.onChangeComment}
        comment={comment}
        getValidationErrorFns={this.getValidationErrorFns}
        onChangeSelectedCountries={this.onChangeSelectedCountries}
        onChangeDestinationCountryType={this.onChangeDestinationCountryType}
        selectedCountries={countriesToShow}
        selectedCountryType={selectedCountryType}
        invalidCountryInput={invalidCountryInput}
        seller={flow.initiator}
        onChangeModifierAmount={this.onChangeModifierAmount}
        modifierAmount={modifierAmount}
        modifierType={MARKUP}
        shippingPriceModel={shippingPriceModel}
        fetchShippingPriceModel={fetchShippingPriceModel}
      />
    );

    const termShippingTypeLabels = [
      TermTypeLabel.FREE_SHIPPING,
      TermTypeLabel.SHIPPING_COST,
      TermTypeLabel.SHIPPING_PRICE
    ].map(type => ({ value: type, label: t(type) }));

    return (
      <OptionCard
        className={className}
        options={termShippingTypeLabels}
        optionsLabel={t('common:commonTerminology.contractType')}
        optionsActionMap={termShippingTypeOptionsMap}
        selectedOption={termType}
        onChangeOption={this.onChangeTermType}
        {...rest}
      />
    );
  }
}

const mapStateToProps = state => ({
  shippingPriceModel: shippingPriceSelectors.getShippingPriceModel(state),
  fetchShippingPriceModel: shippingPriceSelectors.getFetchShippingPriceModelStatus(state)
});

export default connect(
  mapStateToProps,
  null
)(withTranslation()(ShippingTermCard));
