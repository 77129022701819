import React, { useState, useEffect } from 'react';
import { getProductAgreementDetails, getProductAgreementVersionDetails } from '../../../services/ProductAgreement';
import { searchVersions } from '../../../services/ProductV2Search';
import { Table, NavTab, NavTabItem, Snackbar } from '@cimpress/react-components';
import { useTranslation } from 'react-i18next';
import Loading from '../../Loading';
import IconPictureSun from '@cimpress-technology/react-streamline-icons/lib/IconPictureSun';
import { CURRENTLY_BUYING, WERE_BUYING, NOT_BUYING } from '../../../constants/productDetail';

function ProductDetails(props) {
  const [versionsDetails, setVersionsDetails] = useState([]);
  const [notBuyingVersionDetails, setNotBuyingVersionDetails] = useState([]);
  const [wereBuyingVersionDetails, setWereBuyingVersionDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [snackbar, setSnackbar] = useState({ showSnackbar: false, bsStyle: 'danger', message: '' });
  const { t } = useTranslation();

  let { id, productName, currentVersion, productId } = props;

  const columns = [
    { Header: 'Version', accessor: 'version' },
    { Header: 'Name', accessor: 'name' },
    { Header: 'Last Ordered Date', accessor: 'lastOrdered' },
    { Header: 'Sunset End Date', accessor: 'sunsentEndsOn' }
  ];

  const [selectedTabIndex, setSelectedTabIndex] = useState(CURRENTLY_BUYING);

  useEffect(
    () => {
      let mount = true;
      const loadData = async () => {
        setLoading(true);
        if (id) {
          try {
            const productAgreementDetails = await getProductAgreementDetails(id);
            const productAgreementVersionDetails = await getProductAgreementVersionDetails(id);
            const { versionDetails, notBuyingVersions, wereBuyingVersions } = await getAgreementVersionsDetails(
              productAgreementDetails,
              productId,
              productAgreementVersionDetails
            );
            if (mount) {
              setVersionsDetails(versionDetails);
              setNotBuyingVersionDetails(notBuyingVersions);
              setWereBuyingVersionDetails(wereBuyingVersions);
              setLoading(false);
            }
          } catch (exception) {
            let errorMessage = handleError(exception);
            if (mount) {
              setLoading(false);
              setVersionsDetails([]);
              setNotBuyingVersionDetails([]);
              setWereBuyingVersionDetails([]);
              setSnackbar({ showSnackbar: true, bsStyle: 'danger', message: errorMessage });
            }
          }
        }
      };
      loadData();
      return function cleanup() {
        mount = false;
      };
    },
    [id]
  );

  const updateSelectedTab = index => {
    setSelectedTabIndex(index);
  };

  const renderNavTab = () => (
    <div>
      <NavTab>
        <NavTabItem active={selectedTabIndex === CURRENTLY_BUYING}>
          <button onClick={() => updateSelectedTab(CURRENTLY_BUYING)}>Currently buying</button>
        </NavTabItem>
        <NavTabItem active={selectedTabIndex === WERE_BUYING}>
          <button onClick={() => updateSelectedTab(WERE_BUYING)}>Were buying</button>
        </NavTabItem>
        <NavTabItem active={selectedTabIndex === NOT_BUYING}>
          <button onClick={() => updateSelectedTab(NOT_BUYING)}>Not buying</button>
        </NavTabItem>
      </NavTab>
    </div>
  );

  const currentlyBuyingTab = (
    <div>
      {
        <Table
          columns={columns}
          data={versionsDetails}
          condensed={false}
          loading={loading}
          noDataText={t('viewRelationships:messages:noVersionsFound')}
        />
      }
    </div>
  );

  const notBuyingTab = (
    <div>
      {
        <Table
          columns={columns}
          data={notBuyingVersionDetails}
          condensed={false}
          loading={loading}
          noDataText={t('viewRelationships:messages:noVersionsFound')}
        />
      }
    </div>
  );

  const wereBuyingTab = (
    <div>
      {
        <Table
          columns={columns}
          data={wereBuyingVersionDetails}
          condensed={false}
          loading={loading}
          noDataText={t('viewRelationships:messages:noVersionsFound')}
        />
      }
    </div>
  );

  let tabContent;
  switch (selectedTabIndex) {
    case CURRENTLY_BUYING:
      tabContent = currentlyBuyingTab;
      break;
    case NOT_BUYING:
      tabContent = notBuyingTab;
      break;
    case WERE_BUYING:
      tabContent = wereBuyingTab;
      break;
    default:
      break;
  }

  const hideSnackbar = () => {
    setSnackbar({ showSnackbar: false });
  };

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <div>
          <div className="cardStyle">
            <div className="messageBox">
              <div className="innerFlexBox">
                <div>
                  <h4>
                    <b>{productName}</b>
                  </h4>
                </div>
                <br />
                <div>
                  <b>{t('common:commonTerminology:ProductId')}:</b> {productId}
                </div>
                <br />
                <div>
                  <b>{t('common:commonTerminology:currentVersion')}:</b> {currentVersion}
                </div>
              </div>
            </div>
            <div className="imageBox">
              <div className="card flex-containerImage" style={{ height: '100%' }}>
                <IconPictureSun color="grey" size="3x" />
              </div>
            </div>
          </div>
          <br />
          <br />
          {versionsDetails &&
          versionsDetails.length === 0 &&
          notBuyingVersionDetails &&
          notBuyingVersionDetails.length === 0 &&
          wereBuyingVersionDetails &&
          wereBuyingVersionDetails.length === 0 ? (
            ''
          ) : (
            <div>
              <div>{renderNavTab()}</div>
              <div>{tabContent}</div>
            </div>
          )}
        </div>
      )}
      {
        <Snackbar show={snackbar.showSnackbar} bsStyle={snackbar.bsStyle} onHideSnackbar={hideSnackbar}>
          {snackbar.message}
        </Snackbar>
      }
    </div>
  );
}

const getAgreementVersionsDetails = async (productAgreementDetails, productId, productAgreementVersionDetails) => {
  const isVersionPresent =
    productAgreementDetails.product.versions && productAgreementDetails.product.versions.length > 0;

  if (
    !isVersionPresent &&
    productAgreementVersionDetails.wereBuying &&
    productAgreementVersionDetails.wereBuying.length === 0 &&
    productAgreementVersionDetails.currentlyBuying &&
    productAgreementVersionDetails.currentlyBuying.length === 0
  ) {
    return {
      versionDetails: [],
      notBuyingVersions: [],
      wereBuyingVersions: []
    };
  }

  const wereBuyingVersions = productAgreementVersionDetails.wereBuying;
  const currentlyBuyingVersions = productAgreementVersionDetails.currentlyBuying;
  let notBuyingVersions = [];
  const productVersionDetails = await searchVersions(productId);
  if (productVersionDetails && productVersionDetails.result) {
    let versionsInProductAgreement = [];
    currentlyBuyingVersions.forEach(versionDetail => {
      versionsInProductAgreement.push(versionDetail.version);
    });
    wereBuyingVersions.forEach(versionDetail => {
      versionsInProductAgreement.push(versionDetail.version);
    });
    productVersionDetails.result.forEach(eachVersion => {
      currentlyBuyingVersions.forEach(currentlyBuyingVersion => {
        if (currentlyBuyingVersion.version === eachVersion.version) {
          currentlyBuyingVersion['name'] = eachVersion.productName;
          if (eachVersion.isCurrent) {
            currentlyBuyingVersion['status'] = 'current';
          } else {
            currentlyBuyingVersion['status'] = eachVersion.status;
          }
        }
      });

      wereBuyingVersions.forEach(wereBuyingVersion => {
        if (wereBuyingVersion.version === eachVersion.version) {
          wereBuyingVersion['name'] = eachVersion.productName;
          if (eachVersion.isCurrent) {
            wereBuyingVersion['status'] = 'current';
          } else {
            wereBuyingVersion['status'] = eachVersion.status;
          }
        }
      });

      if (!versionsInProductAgreement.includes(eachVersion.version)) {
        notBuyingVersions.push({
          version: eachVersion.version,
          name: eachVersion.productName,
          lastOrdered: null,
          sunsetEndsOn: null,
          sunsetStartsOn: null,
          status: eachVersion.isCurrent ? 'current' : eachVersion.status
        });
      }
    });
    const sortVersion = (first, second) => {
      return parseInt(first.version) - parseInt(second.version);
    };
    currentlyBuyingVersions.sort(sortVersion);
    wereBuyingVersions.sort(sortVersion);
    notBuyingVersions.sort(sortVersion);
  }
  return {
    versionDetails: currentlyBuyingVersions,
    notBuyingVersions: notBuyingVersions,
    wereBuyingVersions: wereBuyingVersions
  };
};

const handleError = err => {
  let errMsg = '';

  switch (err.statusCode) {
    case 403:
      errMsg = 'You do not have the correct permissions.';
      break;
    case 404:
      errMsg = 'Not found - action was not successful.';
      break;
    case 400:
      errMsg = 'Bad request - action was not successful.';
      break;
    default:
      errMsg = 'Action was not successful.';
  }

  return errMsg;
};

export default ProductDetails;
