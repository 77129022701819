import { BUYER, FULFILLERS, MERCHANTS, SELLER } from '../../constants/transactorType';

/**
 * takes in [{id: "abc", name: "name"}... ] and converts it into { ids: ["abc", ...], byIds: { "abc": { name: "name" } ...}
 * @param arr of {id, name}
 * @returns {{ids: Array, byId}}
 */
export const mapToIdsAndByIds = arr => {
  const ids = [];
  const byId = {};
  arr.forEach(({ id, ...rest }) => {
    ids.push(id);
    byId[id] = { id, ...rest };
  });

  return { ids, byId };
};

export const getIdentityNameFromId = (fulfillersById, merchantsById, identityId, identityType) => {
  switch (identityType) {
    case FULFILLERS:
      return (fulfillersById[identityId] && fulfillersById[identityId].name) || 'Unknown';
    case MERCHANTS:
      return (merchantsById[identityId] && merchantsById[identityId].name) || 'Unknown';
    default:
      return 'Unknown';
  }
};

export const typeIsFulfiller = identityType => {
  return identityType === FULFILLERS;
};

export const typeIsMerchant = identityType => {
  return identityType === MERCHANTS;
};

export const parseIdentity = identityType => {
  switch (identityType) {
    case FULFILLERS:
      return SELLER;
    case MERCHANTS:
      return BUYER;
    default:
      return undefined;
  }
};

export const getCounterType = identityType => {
  switch (identityType) {
    case FULFILLERS:
      return MERCHANTS;
    case MERCHANTS:
      return FULFILLERS;
    default:
      return undefined;
  }
};

const allowedFulfillerPermissions = [
  'publish:pricing-term',
  'approve:pricing-term',
  'create:pricing-term',
  'decline:pricing-term',
  'read:pricing-term',
  'update:pricing-term'
];

const allowedMerchantPermissions = [
  'approve:pricing-term',
  'create:pricing-term',
  'decline:pricing-term',
  'read:pricing-term',
  'update:pricing-term'
];

export function hasAuthorizedFulfillerPermissions(permissions: []) {
  return permissions.some(permission => allowedFulfillerPermissions.includes(permission));
}

export function hasAuthorizedMerchantPermissions(permissions: []) {
  return permissions.some(permission => allowedMerchantPermissions.includes(permission));
}
