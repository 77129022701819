import { makeRequest, buildOptions } from '../../services/ServiceHelpers';

const endpointUrl = process.env.REACT_APP_CONTRACTV1_FULFILLER_IDENTITY_URL;

export const getFulfillers = async () => {
  const options = buildOptions({ hal: true });
  return await makeRequest(
    endpointUrl,
    `/v1/fulfillers?showArchived=true`,
    options,
    'getting fulfiller identity data.'
  );
};
