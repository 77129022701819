import { makeRequest, buildOptions } from './ServiceHelpers';

const maxLimitForProductV2ServiceSearch = 10000;

const endpointUrl = process.env.REACT_APP_PRODUCT_V2_SEARCH_URL;

export const searchVersions = async productId => {
  const body = {
    page: 1,
    pageSize: maxLimitForProductV2ServiceSearch,
    query: {
      type: 'terms',
      fieldName: 'parentId',
      values: [productId],
      operator: 'and'
    }
  };
  const options = buildOptions({ method: 'POST', body: JSON.stringify(body) });
  let response = await makeRequest(
    endpointUrl,
    `/v1/products/${productId}/versions:search`,
    options,
    'Search versions for a specific product id.'
  );
  return response;
};
