import { get } from 'lodash';

import { makeRequest, buildOptions } from './ServiceHelpers';
import { maxInt32, buildQueryString } from '../utilities/queryHelper';

const endpointUrl = process.env.REACT_APP_PRODUCT_AGREEMENT_URL;

///******Start: Relationship API calls */
export const getRelationships = async (businesses = []) => {
  const options = buildOptions({ hal: true });
  let businessQueries = buildQueryString('business', businesses);

  return makeRequest(
    endpointUrl,
    `/v1/relationships?offset=0&limit=${maxInt32}${businessQueries}`,
    options,
    'getting relationships'
  ).then(halResponse => {
    return get(halResponse, '_embedded.item');
  });
};

export const createRelationships = (buyerAccountId, sellerAccountId, gracePeriod, lockinPeriod) => {
  const body = {
    buyer: {
      accountId: buyerAccountId
    },
    seller: {
      accountId: sellerAccountId
    },
    sunsetDuration: gracePeriod,
    priceLockinDuration: lockinPeriod
  };

  const options = buildOptions({ method: 'POST', body: JSON.stringify(body) });
  return makeRequest(endpointUrl, `/v1/relationships`, options, 'creating relationships').then(response => {
    return response;
  });
};

export const putRelationship = async (relationshipId, sunsetDuration, priceLockinDuration) => {
  const body = {
    sunsetDuration: sunsetDuration,
    priceLockinDuration: priceLockinDuration
  };
  const options = buildOptions({ method: 'PUT', body: JSON.stringify(body) });
  return makeRequest(endpointUrl, `/v1/relationships/${relationshipId}`, options, 'Updating relationship.').then(
    response => {
      return response;
    }
  );
};
///******End: Relationship API calls */

///******Start: Product Agreement API calls */
export const getProductAgreementDetails = async productagreementId => {
  const options = buildOptions();

  return makeRequest(
    endpointUrl,
    `v1/productagreements/${productagreementId}`,
    options,
    'getting product agreement details for the given product id'
  )
    .then(okResponse => {
      return okResponse;
    })
    .catch(ex => {
      throw ex;
    });
};

export const getProductAgreements = async (
  sellerIds = [],
  buyerIds = [],
  productIds = [],
  productVersions = [],
  offset = 0,
  limit = maxInt32
) => {
  const options = buildOptions({ hal: true });
  let buyerQueries = buildQueryString('buyerId', buyerIds);
  let sellerQueries = buildQueryString('sellerId', sellerIds);
  let productIdQueries = buildQueryString('productId', productIds);
  let productVersionQueries = buildQueryString('productVersion', productVersions);

  const halResponse = await makeRequest(
    endpointUrl,
    `/v1/productagreements?offset=${offset}&limit=${limit}${buyerQueries}${sellerQueries}${productIdQueries}${productVersionQueries}`,
    options,
    'getting product agreements'
  );
  return { productAgreements: get(halResponse, '_embedded.item'), totalCount: get(halResponse, 'total') };
};

export const putProductAgreements = async (productAgreementId, productId, productVersions) => {
  let versions = [];
  productVersions.forEach(productVersion => {
    versions.push({
      version: productVersion
    });
  });
  const body = {
    id: productId,
    versions: versions
  };
  const options = buildOptions({ method: 'PUT', body: JSON.stringify(body) });
  return makeRequest(
    endpointUrl,
    `/v1/productagreements/${productAgreementId}`,
    options,
    'Updating product agreement.'
  ).then(response => {
    return response;
  });
};

export const createProductAgreements = (sellerAccountId, buyerAccountId, products) => {
  const body = {
    buyer: {
      accountId: buyerAccountId
    },
    seller: {
      accountId: sellerAccountId
    },
    product: products
  };

  const options = buildOptions({ method: 'POST', body: JSON.stringify(body) });
  return makeRequest(endpointUrl, `/v1/productagreements`, options, 'creating product agreements').then(response => {
    return response;
  });
};

export const getProductAgreementVersionDetails = async productId => {
  const options = buildOptions();

  return await makeRequest(
    endpointUrl,
    `v1/productagreements/${productId}:versiondetails`,
    options,
    'Get product agreement version details of given product id.'
  );
};
///******End: Product Agreement API calls */

///******Start: Shipping Agreement API calls */
export const createShippingAgreements = (buyerAccountId, sellerAccountId, shippingAgreementType) => {
  const body = {
    shippingAgreements: [
      {
        shippingAgreementType: shippingAgreementType
      }
    ]
  };

  const options = buildOptions({ method: 'PUT', body: JSON.stringify(body) });
  return makeRequest(
    endpointUrl,
    `/v1/shippingAgreements/${buyerAccountId}/${sellerAccountId}`,
    options,
    'creating shipping agreements'
  ).then(response => {
    return response;
  });
};

export const getShippingAgreementTypes = async () => {
  const options = buildOptions();
  return await makeRequest(endpointUrl, `/v1/shippingagreements/types`, options, 'getting shipping agreement types');
};

export const getShippingAgreements = async (buyerAccountId, sellerAccountId) => {
  const options = buildOptions();
  return await makeRequest(
    endpointUrl,
    `/v1/shippingagreements/${buyerAccountId}/${sellerAccountId}`,
    options,
    'Getting shipping agreements'
  );
};
///******End: Shipping Agreement API calls */

///******Start: Notification API calls */
export const getNotificationDetails = notificationId => {
  const options = buildOptions();
  return makeRequest(
    endpointUrl,
    `v1/notification/details/${notificationId}`,
    options,
    'Getting notification details for the given notification id'
  )
    .then(response => {
      return response;
    })
    .catch(e => {
      throw e;
    });
};

export const getNotifications = async (buyerIds = [], sellerIds = [], productIds = [], startDate, endDate) => {
  const options = buildOptions({ hal: true });
  const buyerQueries = buildQueryString('buyerIds', buyerIds);
  const sellerQueries = buildQueryString('sellerIds', sellerIds);
  const productIdQueries = buildQueryString('productIds', productIds);
  return makeRequest(
    endpointUrl,
    `/v1/notifications?offset=0&limit=${maxInt32}&startDate=${startDate}&endDate=${endDate}${buyerQueries}${sellerQueries}${productIdQueries}`,
    options,
    'getting notifications'
  ).then(halResponse => {
    return get(halResponse, '_embedded.item');
  });
};

export const acknowledgeNotification = async notificationId => {
  const options = buildOptions({ method: 'PUT' });
  return makeRequest(
    endpointUrl,
    `/v1/notification/${notificationId}:saveack`,
    options,
    'Acknowledging notification.'
  ).then(response => {
    return response;
  });
};
///******End: Notification API calls */
