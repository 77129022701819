import every from 'lodash/every';
import keyBy from 'lodash/keyBy';
import map from 'lodash/map';
import set from 'lodash/set';
import uniq from 'lodash/uniq';
import pAll from 'p-all';
import React from 'react';
import { CSVLink } from 'react-csv';
import { createApiLoadNotificationAction, createFinishApiLoadNotificationAction } from '../../utils';
import { fetchTermRevisionItems } from '../../../services/PricingTerm';
import { canSkusBeAddedToTerm } from '../../../services/TermSkuUpdate';
import { actions } from '../terms';
import { TermType } from '../../../constants/termTypes';
import { MAX_INT } from '../../../constants/appConstants';

export const ADD_CONTRACT_TERM = 'ADD_CONTRACT_TERM';
export const REMOVE_CONTRACT_TERM = 'REMOVE_CONTRACT_TERM';
export const ADD_MULTIPLE_CONTRACT_TERMS = 'ADD_MULTIPLE_CONTRACT_TERMS';
export const REMOVE_MULTIPLE_CONTRACT_TERMS = 'REMOVE_MULTIPLE_CONTRACT_TERMS';
export const RESET_CONTRACT_TERMS = 'RESET_CONTRACT_TERMS';
export const UPDATE_CONTRACT_TYPE = 'UPDATE_CONTRACT_TYPE';
export const UPDATE_CONTRACT_SKUS = 'UPDATE_SKUS';
export const RESET_CONTRACT_SKUS = 'RESET_SKUS';
export const UPDATE_REQUEST_STATUS = 'UPDATE_REQUEST_STATUS';
export const UPDATE_CONTRACT_TERMS = 'UPDATE_CONTRACT_TERMS';
export const UPDATE_SELLER_ID = 'UPDATE_SELLER_ID';
export const RESET_STATE = 'RESET_STATE';
export const TOGGLE_MULTISELECT = 'TOGGLE_MULTISELECT';

export const PENDING = 'PENDING';
export const COMPLETE = 'COMPLETE';
export const NOT_MADE = 'NOT_MADE';

export const SUCCESS = 'SUCCESS';
export const FAILURE = 'FAILURE';

const allSkusPresentMessage = 'All selected skus are already present on this contract.';

const createErrorMessage = e => {
  if (e.response) {
    const { status, title, type, detail } = e.response;
    return `${status} ${title}: ${detail} ${type}`;
  }

  if (e.message) {
    return e.message;
  }

  return 'An unknown error occurred.';
};

export const selectTerm = term => ({
  type: ADD_CONTRACT_TERM,
  payload: term
});

export const removeTerm = term => ({
  type: REMOVE_CONTRACT_TERM,
  payload: term
});

export const resetTerms = () => ({
  type: RESET_CONTRACT_TERMS,
  payload: {}
});

export const toggleMultiselect = () => ({
  type: TOGGLE_MULTISELECT
});

export const updateType = termType => ({
  type: UPDATE_CONTRACT_TYPE,
  payload: termType
});

export const updateSkus = skus => ({
  type: UPDATE_CONTRACT_SKUS,
  payload: skus
});

export const resetSkus = () => ({
  type: RESET_CONTRACT_SKUS,
  payload: {}
});

export const updateRequestStatus = status => ({
  type: UPDATE_REQUEST_STATUS,
  payload: status
});

export const updateContractTerms = terms => ({
  type: UPDATE_CONTRACT_TERMS,
  payload: terms
});

export const updateSellerId = id => ({
  type: UPDATE_SELLER_ID,
  payload: id
});

export const resetState = () => ({
  type: RESET_STATE,
  payload: {}
});

export const selectMultipleTerms = terms => ({
  type: ADD_MULTIPLE_CONTRACT_TERMS,
  payload: terms
});

export const removeMultipleTerms = terms => ({
  type: REMOVE_MULTIPLE_CONTRACT_TERMS,
  payload: terms
});

export const validateListPriceSkus = async (selectedSkus, selectedTerms) => {
  const skus = map(selectedSkus, selectedSku => selectedSku && selectedSku.sku);
  const termList = map(selectedTerms);
  const term = termList[0];

  const validations = await canSkusBeAddedToTerm(term, {}, skus);

  if (validations) {
    return validations;
  }

  return null;
};

export const validateSpecialPriceSkus = (selectedSkus, selectedTerms) => {
  const skus = map(selectedSkus, selectedSku => selectedSku && selectedSku.sku);
  // retrieve term items for every term for validation purposes
  const specialPriceValidationPromise = map(selectedTerms, term => () =>
    fetchTermRevisionItems(term.termId, term.revisionId, 0, MAX_INT).then(response => ({ ...response, term }))
  );

  return pAll(specialPriceValidationPromise, { concurrency: 5 }).then(results => {
    const validationResults = map(results, result => () => {
      const { items, term } = result;
      const termItems = keyBy(items, item => item && item.sku);
      return canSkusBeAddedToTerm(term, termItems, skus).then(result => {
        return { ...result, items, term };
      });
    });

    return pAll(validationResults, { concurrency: 5 }).then(specialPriceValidations =>
      specialPriceValidations.reduce((validations, validation) => {
        const { term, items, problem } = validation;
        if (validation && validation.allowed) {
          set(validations, ['valid', term.termId], { term, items });
          return validations;
        }
        set(validations, ['invalid', term.termId], { term, problem });
        return validations;
      }, {})
    );
  });
};

export const updateTerms = (selectedTerms, selectedSkus, termType) => dispatch => {
  dispatch(updateRequestStatus(PENDING));
  const updatingNotification = dispatch(
    createApiLoadNotificationAction('Updating Contracts', 'Your contracts are being updated...')
  );
  const skuList = Object.keys(selectedSkus);
  const validationResults =
    termType === TermType.SPECIAL_PRICE
      ? validateSpecialPriceSkus(selectedSkus, selectedTerms)
      : validateListPriceSkus(selectedSkus, selectedTerms);
  return validationResults
    .then(completedValidations => {
      if (termType === TermType.SPECIAL_PRICE) {
        const { valid, invalid } = completedValidations;
        const specialPriceTermPatchPromise = map(valid, validTerm => () => {
          const { items, term } = validTerm;
          const { priceModel } = items && items[0];
          const { termId, revisionId } = term;
          // If all items are already on the term, mark as a failure and do not create a new revision
          const existingItemList = items.map(item => item.sku);
          const existingItemSet = new Set(existingItemList);
          const skusAlreadyOnTerm = every(skuList, sku => existingItemSet.has(sku));
          if (skusAlreadyOnTerm) {
            return { term, status: FAILURE, details: allSkusPresentMessage };
          }
          // Otherwise add the new items to the old, remove duplicates, and patch the term
          const specialPricePatchItems = uniq(existingItemList.concat(skuList));
          return dispatch(actions.updateTermItems(termId, revisionId, specialPricePatchItems, priceModel))
            .then(() => {
              const { revisionId, ...updatedTerm } = term;
              const updatedRevisionId = revisionId + 1;
              return { term: { revisionId: updatedRevisionId, ...updatedTerm }, status: SUCCESS };
            })
            .catch(e => ({ term, status: FAILURE, details: createErrorMessage(e) }));
        });
        const invalidTerms = map(invalid, invalidTerm => {
          const { term, problem } = invalidTerm;
          return {
            term,
            status: FAILURE,
            details: problem && problem.message
          };
        });
        return pAll(specialPriceTermPatchPromise, { concurrency: 5 })
          .then(responses => {
            const updateStatusList = responses.concat(invalidTerms);
            dispatch(updateContractTerms(updateStatusList));
            const updateFailures = updateStatusList.filter(updatedTerm => updatedTerm.status === FAILURE);
            if (updateFailures && updateFailures.length) {
              return dispatch(
                createFinishApiLoadNotificationAction({
                  title: 'Contracts Not Updated',
                  notificationHandle: updatingNotification,
                  message: `${
                    updateFailures.length
                  } of your terms failed to update. Check the summaries below for more details.`,
                  isSuccess: false
                })
              );
            }
            return dispatch(
              createFinishApiLoadNotificationAction({
                title: 'Contracts Updated',
                notificationHandle: updatingNotification,
                message: 'Your contracts have finished updating.',
                isSuccess: true
              })
            );
          })
          .catch(e => {
            // mark all term updates as failures
            const termFailures = map(selectedTerms, term => {
              return {
                term,
                status: FAILURE,
                details: createErrorMessage(e)
              };
            });
            dispatch(updateContractTerms(termFailures));
            return dispatch(
              createFinishApiLoadNotificationAction({
                title: 'Contracts Not Updated',
                notificationHandle: updatingNotification,
                message: createErrorMessage(e),
                isSuccess: false
              })
            );
            // display error message
          });
      }
      if (completedValidations && completedValidations.allowed) {
        const listPriceTermPatchPromise = map(selectedTerms, term => async () => {
          const { termId, revisionId } = term;
          const itemResponse = await fetchTermRevisionItems(termId, revisionId, 0, MAX_INT);

          if (!itemResponse) throw new Error(`Failed to retrieve products for term ${termId}`);
          const { items } = itemResponse;
          const existingItemList = items.map(item => item.sku);
          // If all items are already on the term, mark as a failure and do not create a new revision
          const existingItemSet = new Set(existingItemList);
          const skusAlreadyOnTerm = every(skuList, sku => existingItemSet.has(sku));
          if (skusAlreadyOnTerm) {
            return { term, status: FAILURE, details: allSkusPresentMessage };
          }
          // Otherwise add the new items to the old, remove duplicates, and patch the term
          const listPricePatchItems = uniq(existingItemList.concat(skuList));
          return dispatch(actions.updateTermItems(termId, revisionId, listPricePatchItems))
            .then(() => {
              const { revisionId, ...updatedTerm } = term;
              const updatedRevisionId = revisionId + 1;
              return { term: { revisionId: updatedRevisionId, ...updatedTerm }, status: SUCCESS };
            })
            .catch(e => ({ term, status: FAILURE, details: createErrorMessage(e) }));
        });

        return pAll(listPriceTermPatchPromise, { concurrency: 5 })
          .then(updateStatusList => {
            dispatch(updateContractTerms(updateStatusList));
            const updateFailures = updateStatusList.filter(updatedTerm => updatedTerm.status === FAILURE);
            if (updateFailures && updateFailures.length) {
              return dispatch(
                createFinishApiLoadNotificationAction({
                  title: 'Contracts Not Updated',
                  notificationHandle: updatingNotification,
                  message: `${
                    updateFailures.length
                  } of your terms failed to update. Check the summaries below for more details.`,
                  isSuccess: false
                })
              );
            }
            return dispatch(
              createFinishApiLoadNotificationAction({
                title: 'Contracts Updated',
                notificationHandle: updatingNotification,
                message: 'Your contracts have finished updating.',
                isSuccess: true
              })
            );
          })
          .catch(e => {
            // mark all term updates as failures
            const termFailures = map(selectedTerms, term => {
              return {
                term,
                status: FAILURE,
                details: createErrorMessage(e)
              };
            });
            dispatch(updateContractTerms(termFailures));
            return dispatch(
              createFinishApiLoadNotificationAction({
                title: 'Contracts Not Updated',
                notificationHandle: updatingNotification,
                message: createErrorMessage(e),
                isSuccess: false
              })
            );
            // display error message
          });
      } else {
        const { problem } = completedValidations;
        const skusWithoutListPrice = problem.details;
        const csvData = skusWithoutListPrice.map(sku => [sku]);
        const downloadCsvLink = (
          <CSVLink data={csvData} filename={`SKUs_missing_list_price_${new Date().toISOString()}.csv`}>
            click here
          </CSVLink>
        );

        const errorMsg = (
          <span>
            {problem.message} Please {downloadCsvLink} to review the SKUs that are missing catalog price.
          </span>
        );

        const termFailures = map(selectedTerms, term => {
          return {
            term,
            status: FAILURE,
            details: 'Not all skus have catalog prices.'
          };
        });

        dispatch(updateContractTerms(termFailures));

        return dispatch(
          createFinishApiLoadNotificationAction({
            title: 'Contracts Not Updated',
            notificationHandle: updatingNotification,
            message: errorMsg,
            isSuccess: false
          })
        );
      }
    })
    .catch(e => {
      // Mark all term updates as failures
      const termFailures = map(selectedTerms, term => {
        return {
          term,
          status: FAILURE,
          details: createErrorMessage(e)
        };
      });
      dispatch(updateContractTerms(termFailures));
      return dispatch(
        createFinishApiLoadNotificationAction({
          title: 'Contracts Not Updated',
          notificationHandle: updatingNotification,
          message: createErrorMessage(e),
          isSuccess: false
        })
      );
    });
};

export const updateSellerIdDispatch = id => dispatch => dispatch(updateSellerId(id));

export const updateTypeDispatch = termType => dispatch => dispatch(updateType(termType));

export const resetTermsDispatch = () => dispatch => dispatch(resetTerms());

export const resetSkusDispatch = () => dispatch => dispatch(resetSkus());

export const resetStateDispatch = () => dispatch => dispatch(resetState());

export const toggleMultiselectDispatch = () => dispatch => dispatch(toggleMultiselect());
