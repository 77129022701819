import { combineReducers } from 'redux';
import { createSelector } from 'reselect';

import { changeLanguage } from '../../i18n/languageselection';
import * as contractConfigApi from '../../services/ContractConfig';
import * as customizrApi from '../../services/Customizr';
import { selectors as identitySelectors } from './identities1';
import { typeIsFulfiller, typeIsMerchant } from './util';
import { createApiErrorNotificationAction } from '../utils';

// ACTION TYPES
const FETCH_CONTRACT_CONFIG_REQUEST = 'FETCH_CONTRACT_CONFIG_REQUEST';
const FETCH_CONTRACT_CONFIG_FAILURE = 'FETCH_CONTRACT_CONFIG_FAILURE';
const FETCH_CONTRACT_CONFIG_SUCCESS = 'FETCH_CONTRACT_CONFIG_SUCCESS';

const FETCH_USER_CONFIG_REQUEST = 'FETCH_USER_CONFIG_REQUEST';
const FETCH_USER_CONFIG_FAILURE = 'FETCH_USER_CONFIG_FAILURE';
const FETCH_USER_CONFIG_SUCCESS = 'FETCH_USER_CONFIG_SUCCESS';

const UPDATE_USER_CONFIG_REQUEST = 'UPDATE_USER_CONFIG_REQUEST';
const UPDATE_USER_CONFIG_FAILURE = 'UPDATE_USER_CONFIG_FAILURE';
const UPDATE_USER_CONFIG_SUCCESS = 'UPDATE_USER_CONFIG_SUCCESS';

const UPDATE_CONTRACT_CONFIG_REQUEST = 'UPDATE_CONTRACT_CONFIG_REQUEST';
const UPDATE_CONTRACT_CONFIG_FAILURE = 'UPDATE_CONTRACT_CONFIG_FAILURE';
const UPDATE_CONTRACT_CONFIG_SUCCESS = 'UPDATE_CONTRACT_CONFIG_SUCCESS';

// ACTION CREATORS
const fetchContractConfigRequest = () => ({ type: FETCH_CONTRACT_CONFIG_REQUEST });
const fetchContractConfigFailure = payload => ({ type: FETCH_CONTRACT_CONFIG_FAILURE, payload });
const fetchContractConfigSuccess = payload => ({ type: FETCH_CONTRACT_CONFIG_SUCCESS, payload });

const fetchUserConfigRequest = () => ({ type: FETCH_USER_CONFIG_REQUEST });
const fetchUserConfigFailure = payload => ({ type: FETCH_USER_CONFIG_FAILURE, payload });
const fetchUserConfigSuccess = payload => ({ type: FETCH_USER_CONFIG_SUCCESS, payload });

const updateContractConfigRequest = () => ({ type: UPDATE_CONTRACT_CONFIG_REQUEST });
const updateContractConfigFailure = payload => ({ type: UPDATE_CONTRACT_CONFIG_FAILURE, payload });
const updateContractConfigSuccess = payload => ({ type: UPDATE_CONTRACT_CONFIG_SUCCESS, payload });

const updateUserConfigRequest = () => ({ type: UPDATE_USER_CONFIG_REQUEST });
const updateUserConfigFailure = payload => ({ type: UPDATE_USER_CONFIG_FAILURE, payload });
const updateUserConfigSuccess = payload => ({ type: UPDATE_USER_CONFIG_SUCCESS, payload });

const renderErrorMsg = err => {
  const statusCode = err && ((err.response && err.response.status) || err.statusCode);
  switch (statusCode) {
    case 403:
      return 'You do not have the correct permissions to view and/or edit auto approval settings for this business.';
    default:
      return 'Unable to fetch and/or update auto approval settings for this business.';
  }
};

const fetchAutoApproval = (identityId, identityType) => dispatch => {
  dispatch(fetchContractConfigRequest());

  contractConfigApi
    .fetchCurrentConfigV2(identityId, identityType)
    .then(({ autoApprovalSettings }) =>
      dispatch(fetchContractConfigSuccess({ identityId, identityType, autoApprovalSettings }))
    )
    .catch(err => {
      dispatch(fetchContractConfigFailure(err));
      dispatch(createApiErrorNotificationAction(renderErrorMsg(err)));
    });
};

const updateAutoApproval = (identityId, identityType, approvalSettings) => dispatch => {
  dispatch(updateContractConfigRequest());

  contractConfigApi
    .updateContractConfigV2(identityId, identityType, approvalSettings)
    .then(({ autoApprovalSettings }) => {
      dispatch(updateContractConfigSuccess({ identityId, identityType, autoApprovalSettings }));
    })
    .catch(err => {
      dispatch(updateContractConfigFailure(err));
      dispatch(createApiErrorNotificationAction(renderErrorMsg(err)));
    });
};

const fetchUserConfig = () => dispatch => {
  dispatch(fetchUserConfigRequest());

  customizrApi
    .fetchUserConfig()
    .then(userSettings => {
      changeLanguage(userSettings);
      return dispatch(fetchUserConfigSuccess(userSettings));
    })
    .catch(err => {
      if (err.statusCode === 404) {
        // No settings were found, just return an empty settings object
        dispatch(fetchUserConfigSuccess({}));
        return;
      }

      dispatch(fetchUserConfigFailure(err));
      dispatch(createApiErrorNotificationAction('Unable to retrieve user settings.'));
    });
};

const updateUserConfig = userConfigSetings => dispatch => {
  dispatch(updateUserConfigRequest());

  customizrApi
    .updateUserConfig(userConfigSetings)
    .then(userSettings => {
      return dispatch(updateUserConfigSuccess(userSettings));
    })
    .catch(err => {
      dispatch(updateUserConfigFailure(err));
      dispatch(createApiErrorNotificationAction('Unable to update user settings.'));
    });
};

export const actions = {
  fetchAutoApproval,
  updateAutoApproval,
  fetchUserConfig,
  updateUserConfig
};

// REDUCERS
function byFulfillerId(state = {}, action) {
  switch (action.type) {
    case FETCH_CONTRACT_CONFIG_SUCCESS:
      if (typeIsFulfiller(action.payload.identityType)) {
        const { identityId, autoApprovalSettings } = action.payload;
        const filteredSettings = autoApprovalSettings.reduce((arr, { otherEntity, autoApprove }) => {
          if (autoApprove) {
            arr.push({ id: otherEntity.id, autoApprove });
          }
          return arr;
        }, []);
        return { ...state, [identityId]: filteredSettings };
      }
      return state;
    case UPDATE_CONTRACT_CONFIG_SUCCESS:
      if (typeIsFulfiller(action.payload.identityType)) {
        const { identityId, autoApprovalSettings } = action.payload;
        const settings = autoApprovalSettings.map(({ otherEntity, autoApprove }) => ({
          id: otherEntity.id,
          autoApprove
        }));
        return { ...state, [identityId]: settings };
      }
      return state;
    default:
      return state;
  }
}

function byMerchantId(state = {}, action) {
  switch (action.type) {
    case FETCH_CONTRACT_CONFIG_SUCCESS:
      if (typeIsMerchant(action.payload.identityType)) {
        const { identityId, autoApprovalSettings } = action.payload;
        const filteredSettings = autoApprovalSettings.reduce((arr, { otherEntity, autoApprove }) => {
          if (autoApprove) {
            arr.push({ id: otherEntity.id, autoApprove });
          }
          return arr;
        }, []);
        return { ...state, [identityId]: filteredSettings };
      }
      return state;
    case UPDATE_CONTRACT_CONFIG_SUCCESS:
      if (typeIsMerchant(action.payload.identityType)) {
        const { identityId, autoApprovalSettings } = action.payload;
        const settings = autoApprovalSettings.map(({ otherEntity, autoApprove }) => ({
          id: otherEntity.id,
          autoApprove
        }));
        return { ...state, [identityId]: settings };
      }
      return state;
    default:
      return state;
  }
}

function userConfigs(state = {}, action) {
  switch (action.type) {
    case FETCH_USER_CONFIG_SUCCESS:
    case UPDATE_USER_CONFIG_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}

function isLoading(state = {}, action) {
  switch (action.type) {
    case FETCH_CONTRACT_CONFIG_REQUEST:
      return { ...state, FETCH_CONTRACT_CONFIG: true };
    case FETCH_CONTRACT_CONFIG_FAILURE:
    case FETCH_CONTRACT_CONFIG_SUCCESS:
      return { ...state, FETCH_CONTRACT_CONFIG: false };
    case UPDATE_CONTRACT_CONFIG_REQUEST:
      return { ...state, UPDATE_CONTRACT_CONFIG: true };
    case UPDATE_CONTRACT_CONFIG_FAILURE:
    case UPDATE_CONTRACT_CONFIG_SUCCESS:
      return { ...state, UPDATE_CONTRACT_CONFIG: false };
    case FETCH_USER_CONFIG_REQUEST:
      return { ...state, FETCH_USER_CONFIG: true };
    case FETCH_USER_CONFIG_FAILURE:
    case FETCH_USER_CONFIG_SUCCESS:
      return { ...state, FETCH_USER_CONFIG: false };
    case UPDATE_USER_CONFIG_REQUEST:
      return { ...state, UPDATE_USER_CONFIG: true };
    case UPDATE_USER_CONFIG_FAILURE:
    case UPDATE_USER_CONFIG_SUCCESS:
      return { ...state, UPDATE_USER_CONFIG: false };
    default:
      return state;
  }
}

function isLoaded(state = {}, action) {
  switch (action.type) {
    case FETCH_CONTRACT_CONFIG_REQUEST:
    case FETCH_CONTRACT_CONFIG_FAILURE:
      return { ...state, FETCH_CONTRACT_CONFIG: false };
    case FETCH_CONTRACT_CONFIG_SUCCESS:
      return { ...state, FETCH_CONTRACT_CONFIG: true };
    case UPDATE_CONTRACT_CONFIG_REQUEST:
    case UPDATE_CONTRACT_CONFIG_FAILURE:
      return { ...state, UPDATE_CONTRACT_CONFIG: false };
    case UPDATE_CONTRACT_CONFIG_SUCCESS:
      return { ...state, UPDATE_CONTRACT_CONFIG: true };
    case FETCH_USER_CONFIG_REQUEST:
    case FETCH_USER_CONFIG_FAILURE:
      return { ...state, FETCH_USER_CONFIG: false };
    case FETCH_USER_CONFIG_SUCCESS:
      return { ...state, FETCH_USER_CONFIG: true };
    case UPDATE_USER_CONFIG_REQUEST:
    case UPDATE_USER_CONFIG_FAILURE:
      return { ...state, UPDATE_USER_CONFIG: false };
    case UPDATE_USER_CONFIG_SUCCESS:
      return { ...state, UPDATE_USER_CONFIG: true };
    default:
      return state;
  }
}

function hasError(state = {}, action) {
  switch (action.type) {
    case FETCH_CONTRACT_CONFIG_REQUEST:
    case FETCH_CONTRACT_CONFIG_SUCCESS:
      return { ...state, FETCH_CONTRACT_CONFIG: false };
    case FETCH_CONTRACT_CONFIG_FAILURE:
      return { ...state, FETCH_CONTRACT_CONFIG: true };
    case UPDATE_CONTRACT_CONFIG_REQUEST:
    case UPDATE_CONTRACT_CONFIG_SUCCESS:
      return { ...state, UPDATE_CONTRACT_CONFIG: false };
    case UPDATE_CONTRACT_CONFIG_FAILURE:
      return { ...state, UPDATE_CONTRACT_CONFIG: true };
    case FETCH_USER_CONFIG_REQUEST:
    case FETCH_USER_CONFIG_SUCCESS:
      return { ...state, FETCH_USER_CONFIG: false };
    case FETCH_USER_CONFIG_FAILURE:
      return { ...state, FETCH_USER_CONFIG: true };
    case UPDATE_USER_CONFIG_REQUEST:
    case UPDATE_USER_CONFIG_SUCCESS:
      return { ...state, UPDATE_USER_CONFIG: false };
    case UPDATE_USER_CONFIG_FAILURE:
      return { ...state, UPDATE_USER_CONFIG: true };
    default:
      return state;
  }
}

const configurationsReducer = combineReducers({
  byFulfillerId,
  byMerchantId,
  userConfigs,
  isLoading,
  isLoaded,
  hasError
});

export default configurationsReducer;

// SELECTORS
const getConfigByFulfillerId = state => state.contractV1.configurations.byFulfillerId;
const getConfigByMerchantId = state => state.contractV1.configurations.byMerchantId;
const getUserConfigSettings = state => state.contractV1.configurations.userConfigs;
const getIsShippingCostsEnabled = state => state.contractV1.configurations.userConfigs.enableShippingCosts;
const getShowTerminated = state => state.contractV1.configurations.userConfigs.showTerminated;

const getisFetchingConfigurations = state => state.contractV1.configurations.isLoading.FETCH_CONTRACT_CONFIG;
const getIsUpdatingConfigurations = state => state.contractV1.configurations.isLoading.UPDATE_CONTRACT_CONFIG;
const getIsFetchingUserConfigurations = state => state.contractV1.configurations.isLoading.FETCH_USER_CONFIG;

const getCurrentConfigurations = createSelector(
  identitySelectors.getFulfillersById,
  identitySelectors.getMerchantsById,
  getConfigByFulfillerId,
  getConfigByMerchantId,
  (state, identityId, identityType) => ({ identityId, identityType }),
  (fulfillersById, merchantsById, configByFulfillerId, configByMerchantId, { identityId, identityType }) => {
    let currentConfigs = [];

    if (typeIsFulfiller(identityType)) {
      const fulfillerConfigs = configByFulfillerId[identityId] || [];
      currentConfigs = fulfillerConfigs.map(o => ({
        ...o,
        name: (merchantsById[o.id] && merchantsById[o.id].name) || 'Unknown'
      }));
    }

    if (typeIsMerchant(identityType)) {
      const merchantConfigs = configByMerchantId[identityId] || [];
      currentConfigs = merchantConfigs.map(o => ({
        ...o,
        name: (fulfillersById[o.id] && fulfillersById[o.id].name) || 'Unknown'
      }));
    }

    return currentConfigs.sort((a, b) => (a.name > b.name ? 1 : -1));
  }
);

const getSelectionOptions = createSelector(
  identitySelectors.getMerchantsSortedByName,
  identitySelectors.getFulfillersSortedByName,
  (state, identityId, identityType) => ({ state, identityId, identityType }),
  (merchantsSortedByName, fulfillersSortedByName, { state, identityId, identityType }) => {
    const currentConfigurations = getCurrentConfigurations(state, identityId, identityType) || [];
    const currentlyAutoapprovedIds = currentConfigurations.reduce((arr, { id, autoApprove }) => {
      if (autoApprove) {
        arr.push(id);
      }
      return arr;
    }, []);

    if (typeIsFulfiller(identityType)) {
      return merchantsSortedByName
        .filter(({ id }) => !currentlyAutoapprovedIds.includes(id))
        .map(({ name, id }) => ({ label: name, value: id }));
    }

    if (typeIsMerchant(identityType)) {
      return fulfillersSortedByName
        .filter(({ id }) => !currentlyAutoapprovedIds.includes(id))
        .map(({ name, id }) => ({ label: name, value: id }));
    }

    return [];
  }
);

export const selectors = {
  getConfigByFulfillerId,
  getConfigByMerchantId,
  getisFetchingConfigurations,
  getIsUpdatingConfigurations,
  getCurrentConfigurations,
  getSelectionOptions,
  getUserConfigSettings,
  getIsShippingCostsEnabled,
  getIsFetchingUserConfigurations,
  getShowTerminated
};
