import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import moment from 'moment';
import { Portal } from 'react-overlays';
import { Tooltip, colors, Modal } from '@cimpress/react-components';
import { toDateString } from '../../../utilities/dateHelper';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import IconCheckCircleAlt from '@cimpress-technology/react-streamline-icons/lib/IconCheckCircleAlt';
import IconRemoveCircle from '@cimpress-technology/react-streamline-icons/lib/IconRemoveCircle';
import IconBin from '@cimpress-technology/react-streamline-icons/lib/IconBin';
import { actions as productAgreementActions } from '../../../reducers/productagreements';
import { selectors as identitySelectors } from '../../../reducers/identities/index';

const CalendarContainer = ({ children }) => {
  const el = document.getElementById('calendar-portal');
  return <Portal container={el}>{children}</Portal>;
};
const isValidLockedUntilDate = current => {
  if (current.isSameOrBefore(moment(), 'day')) {
    return false;
  }
  return true;
};
const getFormattedDate = date => {
  let formattedDate = date;
  if (date) {
    formattedDate = toDateString(date);
  }

  return formattedDate;
};
function PriceLock(props) {
  const { isAdmin, hasUserManageProductAgreementPermission, agreement, sellerAccountId, buyerAccountId } = props;
  const { productId, lockedUntilDate, productAgreementId } = agreement;

  const [isEdit, setIsEdit] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [priceLockId, setPriceLockId] = useState(undefined);
  const [lockedInDate, setLockedInDate] = useState(() => getFormattedDate(lockedUntilDate));
  const isMount = useRef(true);
  const { t } = useTranslation();

  useEffect(
    () => {
      if (isMount.current) {
        setLockedInDate(getFormattedDate(lockedUntilDate));
      }
      return () => {
        isMount.current = false;
      };
    },
    [lockedUntilDate]
  );

  useEffect(
    () => {
      async function fetchPriceLocks() {
        const response = await props.fetchPriceLocks(sellerAccountId, buyerAccountId, [productId]);
        if (response.payload && response.payload.value && response.payload.value.length > 0) {
          const priceLockId = response.payload.value[0].priceLockId;
          if (isMount.current) {
            setPriceLockId(priceLockId);
          }
        }
      }
      if (lockedUntilDate) {
        fetchPriceLocks();
      }
      return () => {
        isMount.current = false;
      };
    },
    [sellerAccountId, buyerAccountId, productId, lockedUntilDate]
  );

  const updateLockedInDate = updatedDate => {
    let formattedDate = updatedDate;
    if (updatedDate) {
      formattedDate = toDateString(updatedDate);
    }
    setLockedInDate(formattedDate);
  };

  const onChangeLockedInDate = updatedDate => updateLockedInDate(updatedDate);

  const onEditPriceLockedIn = () => setIsEdit(true);

  const onSavePriceLockedIn = async () => {
    if (lockedInDate) {
      if (priceLockId) {
        await props.updatePriceLock(priceLockId, lockedInDate, productId, sellerAccountId, buyerAccountId);
      } else {
        await props.createPriceLock(sellerAccountId, buyerAccountId, productId, lockedInDate);
      }
      props.fetchProductAgreement(productAgreementId);
      if (isMount.current) setIsEdit(false);
    }
  };
  const onDeletePriceLockedIn = () => {
    setShowModal(true);
  };
  const onCancelPriceLockedIn = () => {
    updateLockedInDate(lockedUntilDate);
    setIsEdit(false);
  };

  const onDeletePriceLockModal = async () => {
    if (!priceLockId) {
      updateLockedInDate(lockedUntilDate);
      setIsEdit(false);
      setShowModal(false);
    } else {
      setShowModal(false);
      await props.deletePriceLock(priceLockId, productId, sellerAccountId, buyerAccountId);
      props.fetchProductAgreement(productAgreementId);
      if (isMount.current) setIsEdit(false);
    }
  };
  const closePriceLockModal = () => setShowModal(false);
  const today = new Date();
  const tomorrow = new Date(today);

  let bodyContent = '';
  if (isEdit) {
    bodyContent = (
      <div className="row">
        <div className="col-md-12">
          <div className="flex-priceLockCell">
            <div className="priceLockDate">
              <DatePicker
                popperContainer={CalendarContainer}
                id={'fromdate'}
                minDate={tomorrow.setDate(tomorrow.getDate() + 1)}
                inputProps={{
                  readOnly: true,
                  required: true
                }}
                onChange={onChangeLockedInDate}
                closeOnSelect={true}
                dateFormat="YYYY-MM-DD"
                value={lockedInDate}
                timeFormat={false}
                isValidDate={isValidLockedUntilDate}
              />
            </div>
            <div className="priceLockSaveCancel">
              <span onClick={onSavePriceLockedIn} style={{ cursor: 'pointer' }}>
                <Tooltip direction="top" contents={t('productAgreementsPage:save')}>
                  <IconCheckCircleAlt color={colors.teal.base} />
                </Tooltip>{' '}
              </span>
              <span onClick={onCancelPriceLockedIn} style={{ cursor: 'pointer' }}>
                <Tooltip direction="bottom" contents={t('productAgreementsPage:cancel')}>
                  <IconRemoveCircle size={'sm'} color={colors.teal.base} />
                </Tooltip>{' '}
              </span>
            </div>
            {lockedInDate && (
              <div className="priceLockDelete">
                <span onClick={onDeletePriceLockedIn} style={{ cursor: 'pointer' }}>
                  <Tooltip direction="bottom" contents={t('productAgreementsPage:deletePriceLock')}>
                    <IconBin size={'sm'} color={colors.teal.base} />
                  </Tooltip>{' '}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  } else {
    bodyContent = (
      <div className="row">
        <div className="col-md-10">{lockedInDate}</div>
        {isAdmin || hasUserManageProductAgreementPermission ? (
          <div className="col-md-2" align="right">
            <span onClick={onEditPriceLockedIn} style={{ cursor: 'pointer' }}>
              <Tooltip direction="bottom" contents={t('productAgreementsPage:edit')}>
                <i className="fa fa-pencil" aria-hidden="true" style={{ color: 'teal' }} />
              </Tooltip>{' '}
            </span>
          </div>
        ) : (
          ''
        )}
      </div>
    );
  }

  return (
    <div>
      {bodyContent}
      <Modal
        bsStyle="danger"
        show={showModal}
        onRequestHide={() => closePriceLockModal()}
        closeButton={false}
        footer={
          <div>
            <button className="btn btn-default" onClick={onDeletePriceLockModal}>
              {t('common:commonWords.delete')}
            </button>
            <button className="btn btn-default" onClick={() => closePriceLockModal()}>
              {t('common:commonWords.cancel')}
            </button>
          </div>
        }>
        {t('productAgreementsPage:deletePriceLockConfirmation', { productId: productId })}
      </Modal>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    isAdmin: identitySelectors.isUserAdmin(state)
  };
};

const mapDispatchToProps = {
  fetchProductAgreements: productAgreementActions.fetchProductAgreementsAction,
  fetchProductAgreement: productAgreementActions.fetchProductAgreementAction,
  fetchPriceLocks: productAgreementActions.fetchPriceLocksAction,
  updatePriceLock: productAgreementActions.updatePriceLockAction,
  createPriceLock: productAgreementActions.createPriceLockAction,
  deletePriceLock: productAgreementActions.deletePriceLockAction
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PriceLock);
