import React, { useState, Fragment, useEffect } from 'react';
import {
  actions as productAgreementActions,
  selectors as productAgreementSelectors
} from '../../../reducers/productagreements';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Tooltip, colors, Modal } from '@cimpress/react-components';
import Select from 'react-select';
import 'react-datepicker/dist/react-datepicker.css';
import IconCheckCircleAlt from '@cimpress-technology/react-streamline-icons/lib/IconCheckCircleAlt';
import IconRemoveCircle from '@cimpress-technology/react-streamline-icons/lib/IconRemoveCircle';
import { selectors as identitySelectors } from '../../../reducers/identities/index';
const sortVersionFnc = (a, b) => a - b;
const getVersionOption = versions => {
  const productVersionOptions = [];
  versions.forEach(version => {
    productVersionOptions.push({
      label: version,
      value: version
    });
  });

  return productVersionOptions;
};
function ProductVersion(props) {
  const { t } = useTranslation();
  const [isEdit, setIsEdit] = useState(false);
  const [versionOptions, setVersionOptions] = useState([]);
  const [selectedVersions, setSelectedVersions] = useState(undefined);
  const [showModal, setShowModal] = useState(false);
  const [deleteVersions, setDeleteVersions] = useState([]);

  const {
    isAdmin,
    hasUserManageProductAgreementPermission,
    agreement,
    productVersions,
    sellerAccountId,
    buyerAccountId
  } = props;
  const { productId } = agreement;
  const agreementVersions = agreement.productVersions;

  useEffect(
    () => {
      props.fetchProductVersions(productId);
    },
    [productId]
  );

  useEffect(
    () => {
      let isMount = true;
      if (productVersions && productVersions[productId]) {
        const versions = productVersions[productId];
        versions.sort(sortVersionFnc);
        isMount && setVersionOptions(getVersionOption(versions));
      }
      return () => {
        isMount = false;
      };
    },
    [productVersions, productId]
  );

  useEffect(
    () => {
      setSelectedVersions(getVersionOption(agreementVersions));
    },
    [agreementVersions]
  );

  const onSelectVersion = selectedValues => setSelectedVersions(selectedValues);
  const onVersionChangeSave = async () => {
    const productData = { ...agreement };
    const updatedVersions = selectedVersions ? selectedVersions.map(x => x.value) : [];
    const deletedVersions = [];
    agreementVersions.forEach(originalVersion => {
      if (updatedVersions && !updatedVersions.includes(originalVersion)) {
        deletedVersions.push(originalVersion);
      }
    });
    if (deletedVersions.length === 0) {
      if (JSON.stringify(updatedVersions) !== JSON.stringify(agreementVersions)) {
        props.updateProductVersion(productData, updatedVersions, sellerAccountId, buyerAccountId);
      }
    } else {
      setDeleteVersions(deletedVersions);
      setShowModal(true);
    }
  };
  const onVersionChangeSaveModal = () => {
    const productData = { ...agreement };
    const updatedVersions = selectedVersions ? selectedVersions.map(x => x.value) : [];
    props.updateProductVersion(productData, updatedVersions, sellerAccountId, buyerAccountId);
  };
  const onVersionChangeCancel = () => {
    setSelectedVersions(getVersionOption(agreementVersions));
    setIsEdit(false);
  };
  const onVersionEdit = () => setIsEdit(true);
  const closeVersionUpdateModal = () => setShowModal(false);
  return (
    <Fragment>
      {isEdit ? (
        <div className="row">
          <div className="col-md-10">
            <Select
              required={'required'}
              isMulti
              className="basic-multi-select"
              classNamePrefix="select"
              options={versionOptions}
              onChange={onSelectVersion}
              value={selectedVersions}
              placeholder={t('productAgreementsPage:selectVersions')}
              // noOptionsMessage={() => undefined}
            />
          </div>
          <div className="col-md-2" align="right">
            <span onClick={onVersionChangeSave} style={{ cursor: 'pointer' }}>
              <Tooltip direction="top" contents={t('productAgreementsPage:save')}>
                <IconCheckCircleAlt color={colors.teal.base} />
              </Tooltip>{' '}
            </span>
            <span onClick={onVersionChangeCancel} style={{ cursor: 'pointer' }}>
              <Tooltip direction="bottom" contents={t('productAgreementsPage:cancel')}>
                <IconRemoveCircle size={'sm'} color={colors.teal.base} />
              </Tooltip>{' '}
            </span>
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col-md-10">{agreementVersions.join(', ')}</div>
          {isAdmin || hasUserManageProductAgreementPermission ? (
            <div className="col-md-2" align="right">
              <span onClick={onVersionEdit} style={{ cursor: 'pointer' }}>
                <Tooltip direction="bottom" contents={t('productAgreementsPage:edit')}>
                  <i className="fa fa-pencil" aria-hidden="true" style={{ color: 'teal' }} />
                </Tooltip>{' '}
              </span>
            </div>
          ) : (
            ''
          )}
        </div>
      )}
      <Modal
        bsStyle="danger"
        show={showModal}
        onRequestHide={closeVersionUpdateModal}
        closeButton={false}
        footer={
          <div>
            <button className="btn btn-default" onClick={onVersionChangeSaveModal}>
              {t('common:commonWords.save')}
            </button>
            <button className="btn btn-default" onClick={closeVersionUpdateModal}>
              {t('common:commonWords.cancel')}
            </button>
          </div>
        }>
        {t('productAgreementsPage:versionDeleteConfirmation1', { versions: deleteVersions.join(', ') })}
        <br />
        {t('productAgreementsPage:versionDeleteConfirmation2')}
        <ul>
          <li> {t('productAgreementsPage:versionDeleteConfirmation3')}</li>
          <li> {t('productAgreementsPage:versionDeleteConfirmation4')}</li>
        </ul>
      </Modal>
    </Fragment>
  );
}

const mapStateToProps = state => {
  return {
    isAdmin: identitySelectors.isUserAdmin(state),
    productVersions: productAgreementSelectors.getProductVersionsDetail(state)
  };
};

const mapDispatchToProps = {
  fetchProductVersions: productAgreementActions.fetchProductVersionsAction,
  updateProductVersion: productAgreementActions.updateProductVersionAction
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductVersion);
