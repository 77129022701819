import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const DateTimeCell = ({ timestamp }) => {
  const { t } = useTranslation();
  return <span>{t('common:contract.lastUpdatedXAgo', { duration: moment.utc(timestamp).diff(moment.utc()) })}</span>;
};

DateTimeCell.propTypes = {
  timestamp: PropTypes.string
};

export default DateTimeCell;
