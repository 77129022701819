// @flow
import {
  ALL,
  getNotificationType,
  NONE,
  PRODUCT_NESTED_PATH,
  PRODUCT_NOTIFICATION
} from '../constants/subscriptionConstants';
import { parseIdentity } from '../../reducers/contractV1/util';
import { buildOptions, makeRequest } from '../../services/ServiceHelpers';

const subscriptionsUrl = process.env.REACT_APP_SUBSCRIPTIONS_URL;
const notificationsAggregatorUrl = process.env.REACT_APP_NOTIFICATIONS_AGGREGATOR_URL;

/*
  Fetches all current pricing term subscriptions for user
 */
export const fetchAllSubscriptions = resourceType => {
  const options = buildOptions();
  return makeRequest(
    subscriptionsUrl,
    `/v1/subscriptions?resourceType=${resourceType}`,
    options,
    'fetching all subscriptions'
  );
};

/*
  Unsubscribing by subscription ids
 */
export const unsubscribe = (subscriptionIds, notificationType) => {
  const options = buildOptions({ method: 'DELETE' });
  const requests = subscriptionIds.map(id => {
    if (notificationType === PRODUCT_NOTIFICATION) {
      return makeRequest(notificationsAggregatorUrl, `/v0/subscriptions/${id}`, options, 'unsubscribing');
    }
    return makeRequest(subscriptionsUrl, `/v1/subscriptions/${id}`, options, 'unsubscribing');
  });

  return Promise.all(requests);
};

/*
  Subscribing to either all counterparties or an individual counterparty
  SubscriptionRequest is a request object with fields { subscriber, counterParty, subscriptionType }
  subscriptionType is either ALL or INDIVIDUAL
 */
export const subscribe = (subscriptionType, subscriberIds, subscriberType, counterParties, resourceType) => {
  switch (subscriptionType) {
    case ALL:
      const makeIndividualSubscription = subscriberIds.map(subscriberId => {
        const subscriberFilters = makeIdentityFilter(subscriberId, subscriberType, resourceType);
        return makeSubscription(subscriberFilters, subscriberId, subscriberType, resourceType);
      });
      return Promise.all(makeIndividualSubscription);
    case NONE:
    default:
      return;
  }
};

/*
  Create filters for specific identity
  Fulfiller maps to Seller, Merchant maps to Buyer
 */
const makeIdentityFilter = (id, type, resourceType) => {
  const role = parseIdentity(type);

  if (getNotificationType(resourceType) === PRODUCT_NOTIFICATION) {
    return [
      {
        path: `${PRODUCT_NESTED_PATH}/activeTerm/${role}/type`,
        nestedPath: PRODUCT_NESTED_PATH,
        value: type,
        operator: 'contains'
      },
      {
        path: `${PRODUCT_NESTED_PATH}/activeTerm/${role}/id`,
        nestedPath: PRODUCT_NESTED_PATH,
        value: id,
        operator: 'contains'
      }
    ];
  }

  return [
    { path: `/resource/${role}/type`, value: type },
    { path: `/resource/${role}/id`, value: id },
    { path: `/resourceChange/metaData/autoApproval`, value: false }
  ];
};

/*
  Creates a subscription with the given filters
 */
const makeSubscription = (filters, subscriberId, subscriberType, resourceType) => {
  const body = {
    resourceFilters: filters,
    resourceType: resourceType,
    alertInfo: {
      channel: 'email'
    },
    metadata: {
      subscriber: {
        id: subscriberId,
        type: subscriberType
      }
    }
  };

  const options = buildOptions({ method: 'POST', body: JSON.stringify(body) });

  if (getNotificationType(resourceType) === PRODUCT_NOTIFICATION) {
    return makeRequest(notificationsAggregatorUrl, `/v0/subscriptions`, options, 'creating a subscription');
  }

  return makeRequest(subscriptionsUrl, `/v1/subscriptions`, options, 'creating a subscription');
};
