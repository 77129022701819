import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Spinner from '@cimpress/react-components/lib/shapes/Spinner';
import { withSnackbar } from '../../snackbar/Snackbar';
import '../../../styles/ProductListing.css';
import V1Product from './V1Product';
import V2Product from './V2Product';

import {
  getPricingVersionInfo,
  pricingArchOldVersion
} from '../../../contractV1_src/CommonUtil/PricingVersionInfoUtil';

function ProductListing(props) {
  const { buyer, seller, businessIds, tabIndex } = props;
  const [sellerPricingArchVersion, setSellerPricingArchVersion] = useState(null);
  const sellerAccountId = seller.accountId;
  useEffect(
    () => {
      let isMount = true;
      setSellerPricingArchVersion(null);
      getPricingVersionInfo(props.createSnackbar, sellerAccountId).then(version => {
        if (isMount) {
          setSellerPricingArchVersion(version);
        }
      });
      return () => {
        isMount = false;
      };
    },
    [sellerAccountId]
  );

  const isSellerOnV1Arch = sellerPricingArchVersion === pricingArchOldVersion;
  return (
    <div>
      {!sellerPricingArchVersion ? (
        <div className="loading-container">
          <Spinner />
        </div>
      ) : isSellerOnV1Arch ? (
        <V1Product buyer={buyer} seller={seller} businessIds={businessIds} tabIndex={tabIndex} />
      ) : (
        <V2Product buyer={buyer} seller={seller} businessIds={businessIds} tabIndex={tabIndex} />
      )}
    </div>
  );
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {};

export default withSnackbar(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ProductListing)
);
