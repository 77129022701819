import get from 'lodash/get';
import { TermType } from '../../constants/termTypes';
import { getLabelForContractType } from '../../utilities/displayFunctions';
import TermStatus from '../../constants/termStatus';
import { compare } from '../CommonUtil/ContractV1Util';

export const SELECT_ALL = { value: 'All', label: 'All' };
export function ApplyFilter(searchCriteria, eachPriceTerm) {
  const keys = Object.keys(searchCriteria);
  for (let index = 0; index <= keys.length - 1; index += 1) {
    const key = keys[index];
    const filter = searchCriteria[key];
    if (!eachPriceTerm._visible) return false;
    if (filter.value !== SELECT_ALL.value) {
      if (key === 'buyer' || key === 'seller') {
        eachPriceTerm._visible = get(eachPriceTerm, key).id === filter.value;
      } else if (key === 'type' || key === 'status') {
        eachPriceTerm._visible = get(eachPriceTerm, key).toLowerCase() === filter.value.toLowerCase();
      }
    }
  }
  return eachPriceTerm._visible;
}
export function getContractTypeOptions() {
  return [
    { value: TermType.LIST_PRICE, label: getLabelForContractType(TermType.LIST_PRICE) },
    { value: TermType.SPECIAL_PRICE, label: getLabelForContractType(TermType.SPECIAL_PRICE) },
    { value: TermType.FREE_SHIPPING, label: getLabelForContractType(TermType.FREE_SHIPPING) },
    { value: TermType.SHIPPING_COST, label: getLabelForContractType(TermType.SHIPPING_COST) },
    { value: TermType.SHIPPING_PRICE, label: getLabelForContractType(TermType.SHIPPING_PRICE) }
  ];
}

export function getContractStatusTypeOptions(t) {
  return [
    { value: TermStatus.APPROVED, label: t('common:contractStatus.approved') },
    { value: TermStatus.PENDING, label: t('common:contractStatus.pending') },
    { value: TermStatus.TERMINATED, label: t('common:contractStatus.terminated') }
  ];
}

export function sortCaseInsensitive(row, column) {
  return row[column.value] && row[column.value].toLowerCase();
}

export function getUniqueSellers(priceTerms) {
  const uniqueSellersLocal = {};
  priceTerms.forEach(each => {
    uniqueSellersLocal[each.seller.id] = { label: each.seller.name, value: each.seller.id };
  });
  const sellers = Object.keys(uniqueSellersLocal).map(key => uniqueSellersLocal[key]);
  return sellers.sort((a, b) => compare(a.label, b.label));
}
export function getUniqueBuyers(priceTerms) {
  const uniqueBuyersLocal = {};
  priceTerms.forEach(each => (uniqueBuyersLocal[each.buyer.id] = { label: each.buyer.name, value: each.buyer.id }));
  const buyers = Object.keys(uniqueBuyersLocal).map(key => uniqueBuyersLocal[key]);
  return buyers.sort((a, b) => compare(a.label, b.label));
}
