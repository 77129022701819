import React, { useEffect, useState, Fragment } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isManageContractPermission } from '../../../utilities/checkPermission';
import { CIMTECH_ACCOUNT } from '../../../constants/accounts';
import { selectors as identitySelectors } from '../../../reducers/identities/index';
import PriceChange from './PriceChange';
import Sunset from './Sunset';
import Shipping from './Shipping';
import { PHOENIX_SQUAD_EMAIL_ID } from '../../../constants/accounts';

function Agreements({ buyer, seller, relationshipDataKey, isAdmin, userPermissions }) {
  const { t } = useTranslation();
  const [isAllowEdit, setIsAllowEdit] = useState(true);
  useEffect(
    () => {
      let isMount = true;
      const businessIds = relationshipDataKey.split('|');
      if (isMount && businessIds.length > 0) {
        const manageAccountId = businessIds[0];
        const isManageContractPermissionForUser = isManageContractPermission(manageAccountId, userPermissions);
        let isAllowEdit = false;
        if (isAdmin) {
          isAllowEdit = true;
        } else {
          // TODO: Didn't understand below two condition.
          if (manageAccountId === seller.accountId && isManageContractPermissionForUser) {
            isAllowEdit = true;
          }

          if (
            manageAccountId === CIMTECH_ACCOUNT &&
            buyer.accountId !== CIMTECH_ACCOUNT &&
            seller.accountId !== CIMTECH_ACCOUNT
          ) {
            isAllowEdit = false;
          }
        }
        setIsAllowEdit(isAllowEdit);
      }
      return () => {
        isMount = false;
      };
    },
    [relationshipDataKey]
  );

  const selfSection = () => {
    return (
      <div>
        <p>{t('common:contractV1Src.NoAgreements')}</p>
        <p>{t('common:contractV1Src.PleaseContact', { emailid: PHOENIX_SQUAD_EMAIL_ID })}</p>
      </div>
    );
  };

  const isSelf = buyer.accountId === seller.accountId;
  return (
    <div>
      {isSelf ? (
        selfSection()
      ) : (
        <Fragment>
          {' '}
          <PriceChange
            buyer={buyer}
            seller={seller}
            relationshipDataKey={relationshipDataKey}
            isAllowEdit={isAllowEdit}
          />
          <br />
          <Sunset buyer={buyer} seller={seller} relationshipDataKey={relationshipDataKey} isAllowEdit={isAllowEdit} />
          <br />
          <Shipping buyer={buyer} seller={seller} />
        </Fragment>
      )}
    </div>
  );
}

const mapStateToProps = state => {
  return {
    isAdmin: identitySelectors.isUserAdmin(state),
    userPermissions: identitySelectors.getPermissionsPayload(state)
  };
};

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Agreements);
