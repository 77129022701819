import React from 'react';
import { Card } from '@cimpress/react-components';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { css } from 'emotion';
import IconCog from '@cimpress-technology/react-streamline-icons/lib/IconCog';

const listStyle = css({
  marginTop: '10px'
});

function SettingsCard(props) {
  const { t } = useTranslation();

  const carHeader = (
    <span className="card-landing-header">
      <IconCog color="grey" />
      <div className="card-header-title-with-icon">
        <h4>{t('landingPage:settingsCard:header')}</h4>
      </div>
    </span>
  );
  return (
    <Card header={carHeader} className="card-landing-body">
      <div className="card-text">
        {t('landingPage:settingsCard:manageSettings')}
        <ul className={listStyle}>
          <li>{t('landingPage:settingsCard:manageSubscriptions')}</li>
        </ul>
      </div>
      <div align="center">
        <Link to={`/configurations/${props.manageAccountId}`}>
          {t('landingPage:settingsCard:manageSubscriptionsAndApprovalsButton')}
        </Link>
      </div>
    </Card>
  );
}

export default SettingsCard;
