import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { css } from 'react-emotion';
import SkuCardVirtualizedList from '../../shared/SkuCardVirtualizedList';
import SkuCard from '../../shared/SkuCard';
import { TermTypeLabel, getCreateFlowAgreementTypeDisplay } from '../../utilities/termTypes';
import isEmpty from 'lodash/isEmpty';
import { Accordion } from '@cimpress/react-components';

const section = css`
  margin-bottom: 20px;
`;

const sectionHeaderStyle = css({
  marginBottom: '30px',
  textAlign: 'center',
  fontSize: '20px'
});

const tableHeaderStyle = css({
  borderBottom: '1px solid #E8E8E8',
  margin: '0 0 10px'
});

const dlStyle = css({
  marginBottom: '0'
});

const tableSpacing = css({
  marginBottom: '20px'
});

function ReviewStep(props) {
  const { t } = useTranslation();
  const { createContractData, priceChangeDays } = props;

  const skuCardRenderer = skuInfo => <SkuCard skuInfo={skuInfo} />;

  const selectedSkuCards = (
    <SkuCardVirtualizedList
      skuCardRenderer={skuCardRenderer}
      skuCardList={Object.values(createContractData.selectedSkus)}
      height={400}
    />
  );

  const pricingTermsTable = !isEmpty(createContractData.productPricingTerm) &&
    createContractData.productPricingTerm.termType && (
      <div className={tableSpacing}>
        <header className={tableHeaderStyle}>
          <h5>{t('createContract:pricingContract.title')}</h5>
        </header>
        <div>
          <dt>{t('common:commonTerminology.name')}</dt>
          <dd>{createContractData.productPricingTerm.termName}</dd>
        </div>
        <div>
          <dt>{t('common:commonTerminology.type')}</dt>
          <dd>{`${t(createContractData.productPricingTerm.termType)} ${getCreateFlowAgreementTypeDisplay(
            createContractData.productPricingTerm
          )}`}</dd>
        </div>
        {createContractData.productPricingTerm.termType === TermTypeLabel.SPECIAL_PRICE && (
          <div>
            <dt>{t('common:commonTerminology.priceModel')}</dt>
            <dd>{createContractData.productPricingTerm.priceModel.name}</dd>
          </div>
        )}
      </div>
    );

  const shippingTermTable = !isEmpty(createContractData.shippingPricingTerm) &&
    createContractData.shippingPricingTerm.termType && (
      <div className={tableSpacing}>
        <header className={tableHeaderStyle}>
          <h5>{t('createContract:shippingContract.title')}</h5>
        </header>
        <div>
          <dt>{t('common:commonTerminology.name')}</dt>
          <dd>{createContractData.shippingPricingTerm.termName}</dd>
        </div>
        <div>
          <dt>{t('common:commonTerminology.type')}</dt>
          <dd>{`${t(createContractData.shippingPricingTerm.termType)} ${getCreateFlowAgreementTypeDisplay(
            createContractData.shippingPricingTerm
          )}`}</dd>
        </div>
      </div>
    );

  const getReviewPage = () => {
    return (
      <Fragment>
        <div className="row">
          <div className={`col-sm-12 col-md-6 ${section}`}>
            <h4 className={sectionHeaderStyle}>{t('createContract:review.contractDetailsLabel')}</h4>
            {createContractData.acceptors.map((o, idx) => (
              <Accordion
                key={idx}
                title={<span>{t('createContract:review.buyerHeader', { buyer: o.label })}</span>}
                children={
                  <dl className={`dl-horizontal ${dlStyle}`}>
                    <div className={tableSpacing}>
                      <header className={tableHeaderStyle}>
                        <h5>{t('createContract:review.general')}</h5>
                      </header>
                      <dt>{t('common:commonTerminology.seller')}</dt>
                      <dd>{createContractData.initiator.label}</dd>
                      <dt>{t('common:commonTerminology.buyer')}</dt>
                      <dd>{o.label}</dd>
                      <dt>{t('common:commonTerminology.hasGracePeriod')}</dt>
                      <dd>{t('common:commonWords.yesText')}</dd>
                      <dt>{t('common:commonTerminology.gracePeriod')}</dt>
                      <dd>{priceChangeDays} Day(s)</dd>
                    </div>
                    {pricingTermsTable}
                    {shippingTermTable}
                  </dl>
                }
                defaultOpen={idx === 0}
              />
            ))}
          </div>
          <div className={`col-sm-12 col-md-6 ${section}`}>
            <h4 className={sectionHeaderStyle}>{t('createContract:review.productsInContract')}</h4>
            <div>{selectedSkuCards}</div>
          </div>
        </div>
      </Fragment>
    );
  };

  return <div>{getReviewPage()}</div>;
}

export default ReviewStep;
