import * as accountApi from '../../services/Account';
import { createSelector } from 'reselect';
import get from 'lodash/get';
import { createReducer } from '../utils';
import { UNAVAILABLE, AVAILABLE, NOT_LOADED } from '../../constants/entityStatus';

export const fetchAccounts_REQUEST = 'fetchAccounts_REQUEST';
export const fetchAccounts_SUCCESS = 'fetchAccounts_SUCCESS';
export const fetchAccounts_FAILURE = 'fetchAccounts_FAILURE';

export const fetchAccount_REQUEST = 'fetchAccount_REQUEST';
export const fetchAccount_SUCCESS = 'fetchAccount_SUCCESS';
export const fetchAccount_FAILURE = 'fetchAccount_FAILURE';

export const fetchAllAccounts_REQUEST = 'fetchAllAccounts_REQUEST';
export const fetchAllAccounts_SUCCESS = 'fetchAllAccounts_SUCCESS';
export const fetchAllAccounts_FAILURE = 'fetchAllAccounts_FAILURE';

const cacheKey = 'accounts';
const cacheInMs = 1800000; // 30 minutes

const fetchAllAccountHooks = {
  onFailure: 'Unable to fetch accounts.'
};

// Action
export const fetchAllAccountsAction = () => {
  return {
    types: [fetchAllAccounts_REQUEST, fetchAllAccounts_SUCCESS, fetchAllAccounts_FAILURE],
    cacheTtlMs: cacheInMs,
    cacheKey: cacheKey,
    callAPI: async () => await accountApi.getAccounts(),
    hooks: fetchAllAccountHooks
  };
};

// Action
export const fetchAccountAction = accountId => {
  return {
    types: [fetchAccount_REQUEST, fetchAccount_SUCCESS, fetchAccount_FAILURE],
    cacheKey: accountId,
    cacheTtlMs: cacheInMs,
    callAPI: async () => {
      try {
        return await accountApi.getAccount(accountId);
      } catch (e) {
        const errorResponse = get(e, 'response.detail', 'Error getting account');
        console.error(errorResponse);
        return null;
      }
    }
  };
};

// Action
export const fetchAccountsAction = accountIds => {
  return {
    types: [fetchAccounts_REQUEST, fetchAccounts_SUCCESS, fetchAccounts_FAILURE],
    callAPI: async dispatch =>
      await Promise.all([...accountIds].map(accountId => dispatch(fetchAccountAction(accountId))))
  };
};

// reducer
export default createReducer(
  {},
  {
    [fetchAccount_SUCCESS]: (state, action) => {
      const { payload } = action;

      if (!payload) {
        return state;
      }

      return { ...state, [payload.accountId]: payload };
    },
    [fetchAllAccounts_SUCCESS]: (state, action) => {
      let { payload } = action;
      return { ...state, payload: payload, status: AVAILABLE };
    },
    [fetchAllAccounts_REQUEST]: (state, action) => {
      return { ...state, status: NOT_LOADED };
    },
    [fetchAllAccounts_FAILURE]: (state, action) => {
      return { ...state, status: UNAVAILABLE };
    }
  }
);

// Selector
export const getAccounts = state => {
  const { payload, status } = state.account.accounts;
  if (status === AVAILABLE) {
    return payload;
  }
  return [];
};

export const getAccountById = (state, accountId) => {
  if (state.account.accounts) {
    return state.account.accounts[accountId];
  }
  return undefined;
};

export const getAccountStatus = state => {
  return state.account.accounts.status;
};

const accountNameComparer = (a, b) => {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
};

export const getAccountsSortedByName = createSelector([getAccounts], accounts => {
  accounts.sort(accountNameComparer);
  return accounts;
});

export const getAccountsOptions = createSelector(getAccountsSortedByName, accounts =>
  accounts.map(account => ({ value: account.id, label: account.name }))
);

export const getCimpressOwnedAccountOptions = createSelector(getAccountsSortedByName, accounts => {
  let cimpressOwnedAccountOptions = [];
  accounts.forEach(account => {
    if (account.cimpressOwned) {
      cimpressOwnedAccountOptions.push({ value: account.id, label: account.name });
    }
  });
  return cimpressOwnedAccountOptions;
});
