import { makeRequest, buildOptions } from './ServiceHelpers';

const endpointUrl = process.env.REACT_APP_PRODUCT_PRICE_URL;

export const getListPriceModels = ({ type, id }, skus = [], priceModelId = null, revisionId = null) => {
  const body = JSON.stringify({
    skus,
    seller: {
      sellerType: type,
      sellerId: id
    },
    priceModelId,
    revisionId
  });

  const options = buildOptions({ method: 'POST', body });
  return makeRequest(endpointUrl, '/api/v1/listPrice', options, 'getting all catalog prices');
};

export const getPriceModelRevisionV3 = (id, revisionId) => {
  const options = buildOptions();

  return makeRequest(
    endpointUrl,
    `/api/v3/pricemodels/${id}/revisions/${revisionId}`,
    options,
    `getting price model v3 for ${id} (revision ${revisionId})`
  );
};

export const getPriceModelV3 = id => {
  const options = buildOptions();

  return makeRequest(endpointUrl, `/api/v3/pricemodels/${id}`, options, `getting price model v3 for ${id}`);
};

export const getCompatiblePriceModels = ({ type, id }, skus = []) => {
  const body = JSON.stringify(skus);

  const options = buildOptions({ method: 'POST', body });

  return makeRequest(
    endpointUrl,
    `/api/v1/priceModelCompatibility?sellerType=${type}&sellerId=${id}`,
    options,
    'getting compatible price models'
  );
};

export const getListPriceStatuses = (skus, seller) => {
  const statusRequests = skus.map(sku => ({ sku, seller }));
  const body = JSON.stringify({ listPriceStatusRequests: statusRequests });

  const options = buildOptions({ method: 'POST', body });

  return makeRequest(endpointUrl, `/api/v1/listPrice:bulkStatus`, options, 'getting list price statuses');
};
