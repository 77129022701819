import { makeRequest, buildOptions } from './ServiceHelpers';
export const getRuleSet = referenceId => {
  const options = buildOptions();

  return makeRequest(
    `https://rule.products.cimpress.io/v1/ruleSets/`,
    `?referenceId=${referenceId}`,
    options,
    'getting rule set for the given sku'
  );
};
