import { combineReducers } from 'redux';
import relationships, {
  fetchRelationshipsAction,
  updateRelationshipAction,
  getRelationships,
  getRelationshipForBusiness,
  getRelationshipStatus,
  getRelationshipUpdatedStatus,
  getRelationshipsForHomePage
} from './fetchRelationships';

import createdRelationShip, {
  getCreatedRelationship,
  getRelationshipCreationStatus,
  createRelationshipsAction
} from './createRelationships';

import createdShippingAgreements, {
  getCreatedShippingAgreements,
  getShippingAgreementsCreationStatus,
  createShippingAgreementsAction
} from './createShippingAgreements';

import shippingAgreementTypes, {
  getShippingAgreementTypesStatus,
  getShippingAgreementTypes,
  fetchShippingAgreementTypesAction
} from './fetchShippingAgreementTypes';

import productList, {
  fetchProductAgreementsAction,
  updateProductVersionAction,
  getProductList,
  getProductListStatus,
  fetchProductAgreementAction,
  getProductListByKey
} from './fetchProductAgreements';

import priceLocks, {
  fetchPriceLocksAction,
  updatePriceLockAction,
  createPriceLockAction,
  deletePriceLockAction,
  getPriceLocks,
  getPriceLocksByKey,
  getPriceLocksStatus
} from './priceLock';

import productIntroductionDetail, {
  fetchProductIntroductionDetailAction,
  getProductIntroductionDetail,
  getProductIntroductionStatus
} from './fetchProductIntroductionDetail';

import productVersionsDetail, {
  fetchProductVersionsAction,
  getProductVersionsDetail,
  getProductVersionsStatus
} from './fetchProductVersions';

import createdProductAgreements, {
  getCreatedProductAgreements,
  getProductAgreementsCreationStatus,
  createProductAgreementsAction
} from './createProductAgreements';

import notifications, {
  fetchNotificationsAction,
  fetchNotificationDetailsAction,
  updateNotification,
  getNotifications,
  getNotificationsStatus
} from './fetchNotifications';

import shippingAgreements, {
  fetchShippingAgreementsAction,
  updateShippingAgreementAction,
  getShippingAgreementsPayload,
  getShippingAgreementsStatus
} from './fetchShippingAgreements';

// actions
export const actions = {
  fetchRelationshipsAction,
  updateRelationshipAction,
  createRelationshipsAction,
  fetchProductAgreementsAction,
  fetchProductAgreementAction,
  fetchProductIntroductionDetailAction,
  fetchProductVersionsAction,
  updateProductVersionAction,
  createProductAgreementsAction,
  createShippingAgreementsAction,
  fetchShippingAgreementTypesAction,
  fetchNotificationsAction,
  fetchShippingAgreementsAction,
  updateShippingAgreementAction,
  fetchNotificationDetailsAction,
  updateNotification,
  fetchPriceLocksAction,
  updatePriceLockAction,
  createPriceLockAction,
  deletePriceLockAction
};

// selectors
export const selectors = {
  getRelationships,
  getRelationshipForBusiness,
  getRelationshipStatus,
  getRelationshipUpdatedStatus,
  getRelationshipsForHomePage,
  getCreatedRelationship,
  getRelationshipCreationStatus,
  getProductList,
  getProductListByKey,
  getProductListStatus,
  getProductIntroductionDetail,
  getProductIntroductionStatus,
  getProductVersionsDetail,
  getProductVersionsStatus,
  getProductAgreementsCreationStatus,
  getCreatedProductAgreements,
  getCreatedShippingAgreements,
  getShippingAgreementsCreationStatus,
  getShippingAgreementTypesStatus,
  getShippingAgreementTypes,
  getNotifications,
  getNotificationsStatus,
  getShippingAgreementsPayload,
  getShippingAgreementsStatus,
  getPriceLocks,
  getPriceLocksByKey,
  getPriceLocksStatus
};

export default combineReducers({
  relationships,
  createdRelationShip,
  productList,
  productIntroductionDetail,
  productVersionsDetail,
  createdProductAgreements,
  createdShippingAgreements,
  shippingAgreementTypes,
  notifications,
  shippingAgreements,
  priceLocks
});
