import * as coamApi from '../../services/Coam';
import { createReducer } from '../utils';
import { AVAILABLE, SAVING, UNAVAILABLE } from '../../constants/entityStatus';
import { createApiErrorNotificationAction } from '../utils';
import { resource } from '../../constants/permissions';

export const getPermissions_REQUEST = 'getPermissions_REQUEST';
export const getPermissions_SUCCESS = 'getPermissions_SUCCESS';
export const getPermissions_FAILURE = 'getPermissions_FAILURE';

// Action
export const getPermissionsAction = () => {
  return {
    types: [getPermissions_REQUEST, getPermissions_SUCCESS, getPermissions_FAILURE],
    callAPI: async dispatch => {
      try {
        var accountsWithPermissions = await coamApi.fetchAccountWithPermissions();
        return createMapOfAccountsWithPermissions(accountsWithPermissions);
      } catch (e) {
        dispatch(createApiErrorNotificationAction(e));
      }
    }
  };
};

function createMapOfAccountsWithPermissions(accountsWithPermissions) {
  var accountPermissionMap = {};
  accountsWithPermissions.forEach(accountWithPermissions => {
    if (accountWithPermissions) {
      accountPermissionMap[accountWithPermissions.identifier] = accountWithPermissions.permissions;
    }
  });
  return accountPermissionMap;
}

// Reducer
export default createReducer(
  {},
  {
    [getPermissions_SUCCESS]: (state, action) => {
      const { payload } = action;
      return { ...state, payload, status: AVAILABLE };
    },
    [getPermissions_REQUEST]: (state, action) => {
      return { ...state, status: SAVING };
    },
    [getPermissions_FAILURE]: (state, action) => {
      return { ...state, status: UNAVAILABLE };
    }
  }
);

// Selector
export const getPermissionsPayload = state => {
  return state.identities.accountPermissions.payload;
};

export const isUserAdmin = state => {
  if (state.identities.accountPermissions.payload && state.identities.accountPermissions.payload[resource.STAR]) {
    return state.identities.accountPermissions.payload[resource.STAR].length === 4;
  }
  return false;
};

export const getPermissionsStatus = state => {
  return state.identities.accountPermissions.status;
};
