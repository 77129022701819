import React, { useState } from 'react';
import { css } from 'emotion';
import SettingsCard from './SettingsCard';
import NotificationsCard from './NotificationsCard';
import RelationshipTable from '../RelationshipTable';
import ErrorBoundary from '../../layouts/ErrorBoundary';

const cardsStyle = css({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  marginBottom: '30px',
  '.cardText': {
    marginBottom: '15px'
  },
  '.card-minimal > .card-header::after': {
    display: 'none'
  },
  '.card-minimal .card-header': {
    fontSize: '1.2em'
  }
});

function LandingPage(props) {
  const [manageAccountId, setManageAccountId] = useState('');
  return (
    <div className="container">
      <ErrorBoundary>
        <div className="landing-page">
          <div className="relationship">
            <RelationshipTable setManageAccountId={setManageAccountId} />
          </div>
          <div className="contract-card">
            <div className={cardsStyle}>
              <NotificationsCard manageAccountId={manageAccountId} />
              <br />
              <SettingsCard manageAccountId={manageAccountId} />
            </div>
          </div>
        </div>
      </ErrorBoundary>
    </div>
  );
}

export default LandingPage;
