import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  actions as productAgreementActions,
  selectors as productAgreementSelectors
} from '../../../reducers/productagreements';
import { selectors as identitySelectors } from '../../../reducers/identities/index';
import BuyerNotCimpressOwned from './BuyerNotCimpressOwned';
import NoRelationSection from './NoRelationSection';
import RelationshipSection from './RelationshipSection';
import Loading from '../../Loading';

const getRelationship = (relation, buyerAccountId) => {
  const businessRelation = relation.relationships.find(relationship => relationship.buyer.accountId === buyerAccountId);
  if (businessRelation) {
    const { priceLockinDuration, relationshipId, sunsetDuration } = businessRelation;
    return { priceLockinDuration, relationshipId, sunsetDuration };
  }
};

function BusinessRelationship(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [isRelationPresent, setIsRelationPresent] = useState(true);

  const { buyer, seller, relationships, relationshipDataKey } = props;

  let bodyContent = '';

  useEffect(
    () => {
      let isMounted = true;
      if (buyer.cimpressOwned && relationshipDataKey) {
        const businessIds = relationshipDataKey.split('|');
        if (relationships && relationships[relationshipDataKey]) {
          if (getRelationship(relationships[relationshipDataKey], buyer.accountId)) {
            isMounted && setIsRelationPresent(true);
          } else {
            isMounted && setIsRelationPresent(false);
          }
          isMounted && setIsLoading(false);
        } else {
          props.fetchRelationships(businessIds, businessIds[0]);
        }
      } else {
        setIsLoading(false);
      }

      return () => {
        isMounted = false;
      };
    },
    [relationshipDataKey, buyer.accountId, relationships]
  );

  if (isLoading) {
    bodyContent = <Loading />;
  } else if (!buyer.cimpressOwned) {
    bodyContent = <BuyerNotCimpressOwned businessName={buyer.name} />;
  } else if (!isRelationPresent) {
    bodyContent = <NoRelationSection buyer={buyer} seller={seller} relationshipDataKey={relationshipDataKey} />;
  } else {
    bodyContent = (
      <RelationshipSection
        buyer={buyer}
        seller={seller}
        relationshipDataKey={relationshipDataKey}
        tabIndex={props.tabIndex}
      />
    );
  }
  return <div className="tabMinHeight">{bodyContent}</div>;
}

const mapStateToProps = state => ({
  relationships: productAgreementSelectors.getRelationships(state),
  isAdmin: identitySelectors.isUserAdmin(state),
  userPermissions: identitySelectors.getPermissionsPayload(state)
});

const mapDispatchToProps = {
  fetchRelationships: productAgreementActions.fetchRelationshipsAction,
  updateRelationship: productAgreementActions.updateRelationshipAction
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BusinessRelationship);
