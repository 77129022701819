import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ProductIdsSelector from './ProductIdsSelector';
import {
  actions as createProductAgreementAction,
  selectors as productAgreementSelector
} from '../../../reducers/productagreements';
import { connect } from 'react-redux';
import { withSnackbar } from '../../snackbar/Snackbar';
import { Button, Modal } from '@cimpress/react-components';
import { SAVING } from '../../../constants/entityStatus';
import Loading from '../../Loading';
import { createProductAgreements_SUCCESS } from '../../../reducers/productagreements/createProductAgreements';

function CreateAgreement(props) {
  const { t } = useTranslation();
  const [selectedProductIds, setSelectedProductIds] = useState({});
  const onChangeAddProductIds = updatedSelectedProductIds => {
    setSelectedProductIds(updatedSelectedProductIds);
  };

  const {
    relationship: { buyerAccountId, buyerName, sellerAccountId, sellerName },
    isAddProductEnable,
    onCloseModal,
    updateProductList,
    createProductAgreements,
    productAgreementsCreationStatus
  } = props;

  const isSaving = productAgreementsCreationStatus === SAVING;
  const numberOfProductIdsAdded = Object.keys(selectedProductIds).length;

  const seller = { id: sellerAccountId, name: sellerName };
  const buyer = { id: buyerAccountId, name: buyerName };

  const createProductAgreement = async () => {
    const response = await createProductAgreements(seller, buyer, selectedProductIds);
    if (response.type === createProductAgreements_SUCCESS) {
      setSelectedProductIds({});
      updateProductList();
    }
  };
  const onClose = () => {
    setSelectedProductIds({});
    onCloseModal();
  };
  return (
    <Modal
      style={{ width: '61%' }}
      show={isAddProductEnable}
      footer={
        <div>
          <Button type="default" onClick={onClose}>
            {t('common:commonWords.close')}
          </Button>
          <Button type="primary" disabled={!numberOfProductIdsAdded} onClick={createProductAgreement}>
            {t('common:actions:createButtonText')}
          </Button>
        </div>
      }>
      <div>
        {isSaving && <Loading fullPage />}
        <ProductIdsSelector
          buyer={buyer}
          seller={seller}
          selectedProductIds={selectedProductIds}
          onChange={onChangeAddProductIds}
        />
      </div>
    </Modal>
  );
}

const mapStateToProps = state => ({
  createdProductAgreements: productAgreementSelector.getCreatedProductAgreements(state),
  productAgreementsCreationStatus: productAgreementSelector.getProductAgreementsCreationStatus(state)
});

const mapDispatchToProps = {
  createProductAgreements: createProductAgreementAction.createProductAgreementsAction
};

export default withSnackbar(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CreateAgreement)
);
