import * as productAgreementApi from '../../services/ProductAgreement';
import { createReducer } from '../utils';

import { AVAILABLE, LOADING, UNAVAILABLE } from '../../constants/entityStatus';

export const fetchShippingAgreementTypes_REQUEST = 'fetchShippingAgreementTypes_REQUEST';
export const fetchShippingAgreementTypes_SUCCESS = 'fetchShippingAgreementTypes_SUCCESS';
export const fetchShippingAgreementTypes_FAILURE = 'fetchShippingAgreementTypes_FAILURE';

const relationshipHooks = {
  onFailure: 'Unable to fetch shipping agreements types.'
};

// Action
export const fetchShippingAgreementTypesAction = () => {
  return {
    types: [
      fetchShippingAgreementTypes_REQUEST,
      fetchShippingAgreementTypes_SUCCESS,
      fetchShippingAgreementTypes_FAILURE
    ],
    callAPI: async () => {
      let shippingAgreementTypes = await productAgreementApi.getShippingAgreementTypes();
      return formateShippingAgreementTypes(shippingAgreementTypes);
    },
    hooks: relationshipHooks
  };
};

const formateShippingAgreementTypes = types => {
  const en = [];
  const de = [];
  const fr = [];
  const it = [];
  const nl = [];

  const name = 'shippingAgreementTypeNameIn';
  types.forEach(type => {
    const { shippingAgreementTypeId } = type;
    en.push({
      shippingAgreementTypeId: shippingAgreementTypeId,
      name: type[name + 'En']
    });
    de.push({
      shippingAgreementTypeId: shippingAgreementTypeId,
      name: type[name + 'De']
    });
    fr.push({
      shippingAgreementTypeId: shippingAgreementTypeId,
      name: type[name + 'Fr']
    });
    it.push({
      shippingAgreementTypeId: shippingAgreementTypeId,
      name: type[name + 'It']
    });
    nl.push({
      shippingAgreementTypeId: shippingAgreementTypeId,
      name: type[name + 'Nl']
    });
  });

  return { en, de, fr, it, nl };
};

// reducer
export default createReducer(
  {},
  {
    [fetchShippingAgreementTypes_REQUEST]: (state, action) => {
      return { ...state, status: LOADING };
    },
    [fetchShippingAgreementTypes_SUCCESS]: (state, action) => {
      const { payload } = action;
      return { ...state, payload, status: AVAILABLE };
    },
    [fetchShippingAgreementTypes_FAILURE]: (state, action) => {
      return { ...state, status: UNAVAILABLE };
    }
  }
);

// Selector
export const getShippingAgreementTypes = state => {
  return state.productAgreements.shippingAgreementTypes.payload;
};

export const getShippingAgreementTypesStatus = state => {
  return state.productAgreements.shippingAgreementTypes.status;
};
