import { combineReducers } from 'redux';
import pricingTerms, { getPricingTermsStatus, getPricingTerms, fetchPricingTermsAction } from './getPricingTerms';
import termRevisionItemBySku, { fetchTermRevisionItemBySku, getTermRevisionItemBySku } from './termRevisionItemBySku';
import { fetchTermRevisionPriceModels, getTermRevisionPriceModels } from './termRevisionPriceModels';
import priceModel, { getPriceModelAction, getPriceModelSelector } from './priceModels';

import identities from './identities1';
import { reducer as notificationsReducer } from 'reapop';
import terms from './terms';
import configurations from './configurations';
import termSelectReducer from './termselect';
import shippingPrice from './shippingprice';
import { reducer as formReducer } from 'redux-form';
import cache from './cache/cache';
import home from './home';

import fulfillers, {
  getFulfillerIdentityDataStatus,
  getFulfillerIdentityData,
  fetchFulfillerIdentityData
} from './getFulfillerIdentityData';

import merchants, {
  getMerchantConfigurationData,
  getMerchantConfigurationStatus,
  fetchMerchantConfigurationData
} from './getMerchantConfigurationData';

import createPricingTerm, { createPricingTermAction } from './createPricingTerm';

// actions
export const actions = {
  fetchPricingTermsAction,
  fetchTermRevisionItemBySku,
  fetchTermRevisionPriceModels,
  getPriceModelAction,
  fetchFulfillerIdentityData,
  fetchMerchantConfigurationData,
  createPricingTermAction
};

// selectors
export const selectors = {
  getFulfillerIdentityData,
  getFulfillerIdentityDataStatus,
  getMerchantConfigurationData,
  getMerchantConfigurationStatus,
  getPricingTerms,
  getPricingTermsStatus,
  getTermRevisionItemBySku,
  getTermRevisionPriceModels,
  getPriceModelSelector
};

export default combineReducers({
  pricingTerms,
  termRevisionItemBySku,
  priceModel,
  identities,
  terms,
  configurations,
  notifications: notificationsReducer(),
  termSelectReducer,
  shippingPrice,
  cache,
  home,
  form: formReducer,
  fulfillers,
  merchants,
  createPricingTerm
});
