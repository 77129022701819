import PropTypes from 'prop-types';
import { getDisplayStatus } from '../../../utilities/displayFunctions';
import TermStatus from '../../../constants/termStatus';
import React from 'react';
import moment from 'moment';
import { Trans } from 'react-i18next';

const StatusCell = ({ term, latestRevisionId }) => {
  const displayStatusObj = getDisplayStatus({
    contractRevision: term,
    hasAcceptorPermission: term.userHasAuthorizationFor.acceptor
  });

  let gracePeriodTimeComponent;

  const isLatest = !latestRevisionId || term.revisionId === latestRevisionId;

  if (term.status === TermStatus.PENDING && isLatest) {
    if (term.previousRevision && term.previousRevision.status === TermStatus.APPROVED) {
      // we should be displaying the grace period configuration from a previously approved term.
      const gracePeriodConfigurationToUse =
        term.previousRevision.additionalData && term.previousRevision.additionalData.gracePeriodConfiguration;

      if (gracePeriodConfigurationToUse) {
        const gracePeriodExpiresAt = moment
          .utc(term.createdAt)
          .add(gracePeriodConfigurationToUse.unit, gracePeriodConfigurationToUse.period);

        const now = moment();
        const gracePeriodExpired = gracePeriodExpiresAt.isBefore(now);

        if (!gracePeriodExpired) {
          gracePeriodTimeComponent = (
            <div>
              <small>
                <Trans i18nKey="common:contractStatus.gracePeriodAboutToExpire">
                  Will be auto-approved <b>{{ duration: gracePeriodExpiresAt.diff(now) }}</b>
                </Trans>
              </small>
            </div>
          );
        }
      }
    }
  }

  return (
    <div>
      {displayStatusObj.labelRender}
      {gracePeriodTimeComponent && gracePeriodTimeComponent}
    </div>
  );
};

StatusCell.propTypes = {
  term: PropTypes.object.isRequired,
  latestRevisionId: PropTypes.number
};

export default StatusCell;
