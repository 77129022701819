import { css } from 'react-emotion';
import { useTranslation } from 'react-i18next';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Select } from '@cimpress/react-components';
import { selectors as accountSelectors } from '../../reducers/account';

export const NEW = 'NEW';
export const EXISTING = 'EXISTING';

const selectStyle = css({
  width: '500px'
});

function SelectRelation(props) {
  const { t } = useTranslation();
  const { onChange, buyer, seller, isDisableRelationshipSelection, cimpressOwnedAcountsOptions } = props;

  const onChangeBuyer = selectedBuyer => {
    onChange({ buyer: selectedBuyer });
  };

  const onChangeSeller = selectedSeller => {
    onChange({ seller: selectedSeller });
  };

  return (
    <Fragment>
      <div>
        <h4>{t('createRelationships:title')}</h4>
        <div className={selectStyle}>
          <Select
            label={t('createRelationships:selectSeller')}
            value={seller}
            isDisabled={true}
            options={[]}
            onChange={onChangeSeller}
            required
          />
        </div>
        <div className={selectStyle}>
          <Select
            label={t('createRelationships:selectBuyer')}
            value={buyer}
            isDisabled={isDisableRelationshipSelection}
            options={cimpressOwnedAcountsOptions}
            onChange={onChangeBuyer}
            required
            style={{ marginBottom: '8px' }}
          />
        </div>
      </div>
    </Fragment>
  );
}

const mapStateToProps = state => ({
  cimpressOwnedAcountsOptions: accountSelectors.getCimpressOwnedAccountOptions(state)
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectRelation);
