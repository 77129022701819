// @flow
import { buildOptions, makeRequest } from './ServiceHelpers';

const endpointUrl = process.env.REACT_APP_CUSTOMIZR_URL;
const resourceKey = process.env.REACT_APP_CUSTOMIZR_RESOURCE_KEY;

export const fetchUserConfig = async () => {
  const options = buildOptions();

  const applicationConfigPromise = makeRequest(
    endpointUrl,
    `/v1/resources/${resourceKey}/settings`,
    options,
    'getting application configurations'
  );
  const mcpConfigPromise = makeRequest(
    endpointUrl,
    `/v1/resources/mcp-generic-ui-settings/settings`,
    options,
    'getting mcp configurations'
  );

  const applicationConfig = await applicationConfigPromise;
  const mcpConfig = await mcpConfigPromise;

  return { ...mcpConfig, ...applicationConfig };
};

export const updateUserConfig = userConfigSettings => {
  const options = buildOptions({ method: 'PUT', body: JSON.stringify(userConfigSettings) });

  return makeRequest(endpointUrl, `/v1/resources/${resourceKey}/settings`, options, 'updating user configuration');
};
