export const CIMTECH_ACCOUNT = 'g2Ez5VaoZWoqU22XqPjTLU';
export const PHOENIX_SQUAD_EMAILS = [
  'vbhatt@cimpress.com',
  'gpradan@cimpress.com',
  'Tanmay.Colaco@cimpress.com',
  'Kumod.Singh@cimpress.com',
  'skondusennappan@cimpress.com',
  'Bhanu.Balaji@cimpress.com'
];
export const PHOENIX_SQUAD_EMAIL_ID = 'phoenixsquad@cimpress.com';
