import { SnackbarType } from '../../components/snackbar/Snackbar';
import { getAccountIdsEnabledForV2 } from '../service/DataStorageService';
import { getPricingArchVersion } from '../service/PricingVersionInfo';
export const pricingArchOldVersion = 'v1';
export const getPricingVersionInfo = async (createSnackbar, accountId) => {
  try {
    const loadAccountIdsEnabledforV2 = await getAccountIdsEnabledForV2().catch(() =>
      createSnackbar('error occurred', SnackbarType.error, 8)
    );
    const promise = getPricingArchVersion(accountId);
    if (loadAccountIdsEnabledforV2.filter(each => each.accountId === accountId).length === 1) {
      return 'v2';
    }
    const response = await promise;
    if (!response) {
      createSnackbar(
        'Considering you are using v1 pricing arch as no information found in pricing arch',
        SnackbarType.SNACKBAR_INFO,
        8
      );
      return pricingArchOldVersion;
    } else {
      let pricingArchVersionIfno;
      if (response[0]) {
        pricingArchVersionIfno = response[0].arch;
      }
      if (pricingArchVersionIfno) {
        return pricingArchVersionIfno;
      } else {
        createSnackbar(
          'Considering you are using v1 pricing arch as no information found in pricing arch',
          SnackbarType.SNACKBAR_INFO,
          8
        );
        return pricingArchOldVersion;
      }
    }
  } catch (ex) {
    createSnackbar('Runtime error while fetching prcing arch. So treating V1', SnackbarType.SNACKBAR_INFO, 8);
    return pricingArchOldVersion;
  }
};
