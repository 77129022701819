import mapKeys from 'lodash/mapKeys';
import merge from 'lodash/merge';
import omit from 'lodash/omit';
import set from 'lodash/set';
import {
  ADD_CONTRACT_TERM,
  ADD_MULTIPLE_CONTRACT_TERMS,
  REMOVE_CONTRACT_TERM,
  REMOVE_MULTIPLE_CONTRACT_TERMS,
  RESET_CONTRACT_TERMS,
  UPDATE_CONTRACT_TYPE,
  UPDATE_CONTRACT_TERMS,
  UPDATE_CONTRACT_SKUS,
  UPDATE_REQUEST_STATUS,
  UPDATE_SELLER_ID,
  RESET_CONTRACT_SKUS,
  RESET_STATE,
  COMPLETE,
  NOT_MADE,
  TOGGLE_MULTISELECT
} from './actions';
import { TermType } from '../../../constants/termTypes';

const defaultState = {
  selectedTerms: {},
  termType: TermType.LIST_PRICE,
  requestStatus: NOT_MADE,
  selectedSkus: {},
  selectMultipleTerms: false,
  sellerId: '',
  updateStatusList: []
};

function addTerm(state, action) {
  const term = action.payload;
  const { termId, revisionId, seller } = term;
  const { selectedTerms } = state;
  const updatedTerms = set({ ...selectedTerms }, `${termId}${revisionId}`, term);
  return { ...state, selectedTerms: updatedTerms, sellerId: seller.id };
}

function addMultipleTerms(state, action) {
  const terms = action.payload;
  const seller = terms && terms[0] && terms[0].seller;
  const { selectedTerms } = state;
  const termsToAdd = mapKeys(terms, term => {
    const { termId, revisionId } = term;
    return `${termId}${revisionId}`;
  });
  const updatedTerms = merge({ ...selectedTerms }, termsToAdd);

  return { ...state, selectedTerms: updatedTerms, sellerId: seller.id };
}

function removeMultipleTerms(state, action) {
  const terms = action.payload;
  const { selectedTerms } = state;
  const termKeys = terms.map(term => {
    const { termId, revisionId } = term;
    return `${termId}${revisionId}`;
  });
  const updatedTerms = omit(selectedTerms, termKeys);
  return { ...state, selectedTerms: updatedTerms };
}

function removeTerm(state, action) {
  const term = action.payload;
  const { termId, revisionId } = term;
  const { selectedTerms } = state;
  const updatedTerms = omit(selectedTerms, `${termId}${revisionId}`);
  return { ...state, selectedTerms: updatedTerms };
}

function resetTerms(state) {
  return { ...state, selectedTerms: {} };
}

function updateType(state, action) {
  const termType = action.payload;
  return { ...state, termType };
}

function updateSkus(state, action) {
  const selectedSkus = action.payload;
  return { ...state, selectedSkus };
}

function updateContractTerms(state, action) {
  const updateStatusList = action.payload;
  const updateRequestStatus = COMPLETE;
  return { ...state, updateStatusList, updateRequestStatus };
}

function updateRequestStatus(state, action) {
  const updateRequestStatus = action.payload;
  return { ...state, updateRequestStatus };
}

function updateSellerId(state, action) {
  const sellerId = action.payload;
  return { ...state, sellerId };
}

function resetSkus(state) {
  return { ...state, selectedSkus: {} };
}

function resetState() {
  return defaultState;
}

function toggleMultiselect(state, action) {
  const { selectMultipleTerms } = state;
  return { ...state, selectMultipleTerms: !selectMultipleTerms };
}

function termSelectReducer(state = defaultState, action) {
  switch (action.type) {
    case ADD_CONTRACT_TERM:
      return addTerm(state, action);

    case ADD_MULTIPLE_CONTRACT_TERMS:
      return addMultipleTerms(state, action);

    case REMOVE_CONTRACT_TERM:
      return removeTerm(state, action);

    case REMOVE_MULTIPLE_CONTRACT_TERMS:
      return removeMultipleTerms(state, action);

    case RESET_CONTRACT_TERMS:
      return resetTerms(state);

    case UPDATE_CONTRACT_TYPE:
      return updateType(state, action);

    case UPDATE_CONTRACT_SKUS:
      return updateSkus(state, action);

    case UPDATE_CONTRACT_TERMS:
      return updateContractTerms(state, action);

    case UPDATE_REQUEST_STATUS:
      return updateRequestStatus(state, action);

    case UPDATE_SELLER_ID:
      return updateSellerId(state, action);

    case RESET_CONTRACT_SKUS:
      return resetSkus(state);

    case RESET_STATE:
      return resetState();

    case TOGGLE_MULTISELECT:
      return toggleMultiselect(state);

    default:
      return state;
  }
}

export default termSelectReducer;
