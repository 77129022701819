import { Select } from '@cimpress/react-components';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { getAllCountries } from '../../../../utilities/countryHelpers';

class CountrySelector extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    value: PropTypes.arrayOf(PropTypes.string).isRequired,
    onChange: PropTypes.func.isRequired
  };

  static defaultProps = {
    className: null
  };

  onChange = value => {
    const { onChange } = this.props;
    if (value) {
      onChange(value);
    } else {
      onChange([]);
    }
  };

  render() {
    const { t, className, disabled, value } = this.props;

    return (
      <Select
        isClearable={true}
        className={className}
        isDisabled={disabled}
        label={t('common:commonWords.countries')}
        isMulti
        options={getAllCountries()}
        value={value}
        onChange={this.onChange}
      />
    );
  }
}

export default withTranslation()(CountrySelector);
