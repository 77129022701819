import { notificationsHubAuthorizer } from '../../utils/auth';
import { AVAILABLE, UNAVAILABLE, LOADING, SAVING } from '../../constants/entityStatus';
import { createReducer } from '../utils';
import { createSelector } from 'reselect';

const CHECK_NOTIFICATION_AUTHORIZATION_REQUEST = 'CHECK_NOTIFICATION_AUTHORIZATION_REQUEST';
const CHECK_NOTIFICATION_AUTHORIZATION_FAILURE = 'CHECK_NOTIFICATION_AUTHORIZATION_FAILURE';
const CHECK_NOTIFICATION_AUTHORIZATION_SUCCESS = 'CHECK_NOTIFICATION_AUTHORIZATION_SUCCESS';

const AUTHORIZE_NOTIFICATION_REQUEST = 'AUTHORIZE_NOTIFICATION_REQUEST';
const AUTHORIZE_NOTIFICATION_FAILURE = 'AUTHORIZE_NOTIFICATION_FAILURE';
const AUTHORIZE_NOTIFICATION_SUCCESS = 'AUTHORIZE_NOTIFICATION_SUCCESS';

///Actions
const fetchNotificationAuthorization = {
  onFailure: 'Failed to fetch notification authorization.'
};

export const checkNotificationAuthorizationAction = () => {
  return {
    types: [
      CHECK_NOTIFICATION_AUTHORIZATION_REQUEST,
      CHECK_NOTIFICATION_AUTHORIZATION_SUCCESS,
      CHECK_NOTIFICATION_AUTHORIZATION_FAILURE
    ],
    callAPI: async dispatch => {
      return await notificationsHubAuthorizer.didUserAuthorize();
    },
    hooks: fetchNotificationAuthorization
  };
};

export const authorizeNotificationAction = returnUri => {
  return {
    types: [AUTHORIZE_NOTIFICATION_REQUEST, AUTHORIZE_NOTIFICATION_SUCCESS, AUTHORIZE_NOTIFICATION_FAILURE],
    callAPI: async dispatch => {
      return await notificationsHubAuthorizer.ensureAuthorized({ returnUri });
    },
    hooks: fetchNotificationAuthorization
  };
};

// reducer
export default createReducer(
  {},
  {
    [CHECK_NOTIFICATION_AUTHORIZATION_REQUEST]: (state, action) => {
      return { ...state, AUTHORIZATION_REQUEST: { status: LOADING } };
    },
    [CHECK_NOTIFICATION_AUTHORIZATION_SUCCESS]: (state, action) => {
      const { payload } = action;
      return { ...state, AUTHORIZATION_REQUEST: { isAuthorize: payload, status: AVAILABLE } };
    },
    [CHECK_NOTIFICATION_AUTHORIZATION_FAILURE]: (state, action) => {
      return { ...state, AUTHORIZATION_REQUEST: { status: UNAVAILABLE } };
    },
    [AUTHORIZE_NOTIFICATION_REQUEST]: (state, action) => {
      return { ...state, AUTHORIZE_NOTIFICATION: { status: SAVING } };
    },
    [AUTHORIZE_NOTIFICATION_SUCCESS]: (state, action) => {
      return { ...state, AUTHORIZE_NOTIFICATION: { isAuthorize: true, status: AVAILABLE } };
    },
    [AUTHORIZE_NOTIFICATION_FAILURE]: (state, action) => {
      return { ...state, AUTHORIZE_NOTIFICATION: { status: UNAVAILABLE } };
    }
  }
);

//Selectors

export const getIsCheckingAuthorization = state => {
  return (
    state.subscriptions.authorization.AUTHORIZATION_REQUEST &&
    state.subscriptions.authorization.AUTHORIZATION_REQUEST.status === LOADING
  );
};

export const getIsAuthorizing = state => {
  return (
    state.subscriptions.authorization.AUTHORIZE_NOTIFICATION &&
    state.subscriptions.authorization.AUTHORIZE_NOTIFICATION.status === SAVING
  );
};

export const getIsAuthLoading = createSelector(
  [getIsCheckingAuthorization, getIsAuthorizing],
  (isCheckingAuthorization, isAuthorizing) => {
    return isCheckingAuthorization || isAuthorizing || false;
  }
);

export const getIsAuthorized = state => {
  if (
    !state.subscriptions.authorization.AUTHORIZATION_REQUEST &&
    !state.subscriptions.authorization.AUTHORIZE_NOTIFICATION
  ) {
    return true;
  }

  return (
    (state.subscriptions.authorization.AUTHORIZATION_REQUEST &&
      state.subscriptions.authorization.AUTHORIZATION_REQUEST.isAuthorize) ||
    (state.subscriptions.authorization.AUTHORIZE_NOTIFICATION &&
      state.subscriptions.authorization.AUTHORIZE_NOTIFICATION.isAuthorize)
  );
};

export const getHasCheckingAuthorizationFailed = state => {
  return (
    state.subscriptions.authorization.AUTHORIZATION_REQUEST &&
    state.subscriptions.authorization.AUTHORIZATION_REQUEST.status === UNAVAILABLE
  );
};

export const getHasAuthorizationFailed = state => {
  return (
    state.subscriptions.authorization.AUTHORIZE_NOTIFICATION &&
    state.subscriptions.authorization.AUTHORIZE_NOTIFICATION.status === UNAVAILABLE
  );
};

export const getHasAuthError = createSelector(
  [getHasCheckingAuthorizationFailed, getHasAuthorizationFailed],
  (hasCheckingAuthorizationFailed, hasAuthorizationFailed) => {
    return hasCheckingAuthorizationFailed || hasAuthorizationFailed || false;
  }
);
