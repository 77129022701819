import { combineReducers } from 'redux';

import subscriptions, { fetchAllSubscriptions, getMerchantSubscriptions } from './fetchAllSubscriptions';

import unSubscribeToNotifications, {
  unsubscribeFromNotificationsAction,
  getUnSubscribeToNotificationsStatus
} from './unsubscribeFromNotifications';

import subscribeToNotifications, { subscribeToNotificationsAction } from './subscribeToNotifications';

// actions
export const actions = {
  fetchAllSubscriptions,
  unsubscribeFromNotificationsAction,
  subscribeToNotificationsAction
};

// selectors
export const selectors = {
  getMerchantSubscriptions,
  getUnSubscribeToNotificationsStatus
};

export default combineReducers({
  subscriptions,
  unSubscribeToNotifications,
  subscribeToNotifications
});
