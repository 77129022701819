import * as pricingTermApi from '../../../services/PricingTerm';
import { actions } from './index.v2';
export const updateTerm_REQUEST = 'updateTerm_REQUEST';
export const updateTerm_SUCCESS = 'updateTerm_SUCCESS';
export const updateTerm_FAILURE = 'updateTerm_FAILURE';

const notificationHooks = {
  onRequest: 'Updating...',
  onSuccess: 'Updated!',
  onFailure: 'Failed to update.'
};

// action creator
export const updateTermName = (termId, latestRevisionId, name) => {
  return {
    types: [updateTerm_REQUEST, updateTerm_SUCCESS, updateTerm_FAILURE],
    callAPI: dispatch =>
      pricingTermApi
        .patchAndUpdateName(termId, latestRevisionId, name)
        .then(res => {
          dispatch(actions.resetTermsCache);
          return res;
        })
        .catch(handleUpdateError),
    hooks: notificationHooks
  };
};

const updateTermModifier = (termId, latestRevisionId, modifiers) => {
  return {
    types: [updateTerm_REQUEST, updateTerm_SUCCESS, updateTerm_FAILURE],
    callAPI: dispatch =>
      pricingTermApi
        .patchAndUpdateModifiers(termId, latestRevisionId, modifiers)
        .then(res => {
          dispatch(actions.resetTermsCache);
          return res;
        })
        .catch(handleUpdateError),
    hooks: notificationHooks
  };
};

export const updateTermDiscountPercent = (termId, latestRevisionId, discountPercent) =>
  updateTermModifier(termId, latestRevisionId, { discountPercent });

export const updateTermMarkupPercent = (termId, latestRevisionId, markupPercent) =>
  updateTermModifier(termId, latestRevisionId, { markupPercent });

export const updateTermGracePeriod = (termId, latestRevisionId, gracePeriodDays) => {
  return {
    types: [updateTerm_REQUEST, updateTerm_SUCCESS, updateTerm_FAILURE],
    callAPI: () =>
      pricingTermApi.patchAndUpdateGracePeriod(termId, latestRevisionId, gracePeriodDays).catch(handleUpdateError),
    hooks: notificationHooks
  };
};

const handleUpdateError = err => {
  let errMsg = '';

  switch (err.statusCode) {
    case 403:
      errMsg = 'You do not have the correct permissions to update this contract.';
      break;
    case 409:
      errMsg = 'The contract has already been updated and therefore cannot be updated.';
      break;
    default:
      errMsg = 'Updating contract name was not successful.';
  }

  throw new Error(errMsg);
};
