import React, { PureComponent } from 'react';
import ErrorRobot from '../common/ErrorRobot';

class ErrorBoundary extends PureComponent {
  state = {
    error: null,
    info: null
  };

  componentDidCatch(error, info) {
    this.setState({ error, info });
  }

  render() {
    const { error } = this.state;
    const { children } = this.props;
    if (error) {
      return (
        <div className="card">
          <ErrorRobot />
        </div>
      );
    }
    return children;
  }
}

export default ErrorBoundary;
