import React from 'react';
import { connect } from 'react-redux';
import Editable from '../../popOverEditor/Editable';
import {
  actions as productAgreementActions,
  selectors as productAgreementSelectors
} from '../../../reducers/productagreements';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

function Sunset({ buyer, seller, isAllowEdit, relationship, updateRelationship, relationshipDataKey }) {
  const { t } = useTranslation();
  const { relationshipId } = relationship;
  const onSavePriceLockIn = async edit => {
    const { value } = edit;
    await updateRelationship(relationshipDataKey, relationshipId, value, undefined);
  };

  return (
    <Fragment>
      {isAllowEdit ? (
        <div>
          <b>{t('productAgreementsPage:productArchivingTitle')}</b>
          <br />
          <br />
          <div className="box">
            <Editable
              emptyValueText="0"
              beforeContent={t('productAgreementsPage:sunsetSectionOne', {
                seller: seller.name,
                buyer: buyer.name
              })}
              afterContent={t('productAgreementsPage:sunsetSectionTwo')}
              name="productArchiving"
              mode="popup"
              dataType="text"
              title="Enter duration"
              value={relationship.sunsetDuration}
              placement="left"
              showButtons={true}
              handleSubmit={onSavePriceLockIn}
            />
          </div>
        </div>
      ) : (
        <div className="box">
          <b>{t('productAgreementsPage:productArchivingTitle')}</b>
          <br />
          <br />
          {t('productAgreementsPage:sunsetSectionOne', {
            seller: seller.name,
            buyer: buyer.name
          })}
          <b>{relationship.sunsetDuration}</b>
          {t('productAgreementsPage:sunsetSectionTwo', { buyer: buyer.name })}
        </div>
      )}
    </Fragment>
  );
}

const mapStateToProps = (state, ownProps) => {
  const { buyer, relationshipDataKey } = ownProps;
  return {
    relationship: productAgreementSelectors.getRelationshipForBusiness(state, buyer.accountId, relationshipDataKey)
  };
};

const mapDispatchToProps = {
  updateRelationship: productAgreementActions.updateRelationshipAction
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Sunset);
