import { combineReducers } from 'redux';
import subscribeToNotifications, {
  subscribeToNotificationsAction,
  getSubscribeToNotificationsStatus
} from './subscribeToNotifications';

import unSubscribeToNotifications, {
  unsubscribeFromNotificationsAction,
  getUnSubscribeToNotificationsStatus
} from './unsubscribeFromNotifications';

import subscriptions, {
  getAllSubscriptions,
  getAllSubscriptionsStatus,
  fetchAllSubscriptions,
  getAvailableSubscriptions,
  getBuyersSubscriptions
} from './fetchAllSubscriptions';

import authorization, {
  getIsAuthLoading,
  getIsAuthorized,
  getHasAuthError,
  checkNotificationAuthorizationAction,
  authorizeNotificationAction
} from './authorization';

// actions
export const actions = {
  subscribeToNotificationsAction,
  fetchAllSubscriptions,
  unsubscribeFromNotificationsAction,
  checkNotificationAuthorizationAction,
  authorizeNotificationAction
};

// selectors
export const selectors = {
  getSubscribeToNotificationsStatus,
  getAllSubscriptionsStatus,
  getAllSubscriptions,
  getAvailableSubscriptions,
  getBuyersSubscriptions,
  getUnSubscribeToNotificationsStatus,
  getIsAuthLoading,
  getIsAuthorized,
  getHasAuthError
};

export default combineReducers({
  subscribeToNotifications,
  subscriptions,
  unSubscribeToNotifications,
  authorization
});
