import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Table, TextField, Drawer } from '@cimpress/react-components';
import { useTranslation } from 'react-i18next';
import { withSnackbar } from '../../snackbar/Snackbar';
import {
  actions as productAgreementActions,
  selectors as productAgreementSelectors
} from '../../../reducers/productagreements';
import { selectors as identitySelectors } from '../../../reducers/identities/index';
import ProductDetails from '../viewProductList/ProductDetails';
import PriceLock from './PriceLock';
import ProductVersion from './ProductVersion';
import { LOADING } from '../../../constants/entityStatus';
import { isManageProductAgreementPermission, isManageContractPermission } from '../../../utilities/checkPermission';
import CreateAgreement from '../createAgreement/CreateAgreement';
import { isEmpty } from 'lodash';
import { getRelationship } from '../../../utilities/commonHelper';
const pageLimit = 10;
const getInitialDrawerData = () => ({
  show: false,
  productAgreementId: '',
  productName: '',
  currentVersion: '',
  productId: ''
});
function ProductAgreementTable(props) {
  const { t } = useTranslation();
  const [searchedProductId, setSearchedProductId] = useState('');
  const [drawerData, setDrawerData] = useState(getInitialDrawerData);
  const [relationship, setRelationship] = useState({});
  const [pageIndex, setPageIndex] = useState(0);
  const [hasUserManageProductAgreementPermission, setHasUserManageProductAgreementPermission] = useState(false);
  const [doesUserHasManageContractPermission, setManageContractPermission] = useState(false);
  const [isAddProductEnable, setIsAddProductEnable] = useState(false);
  const { productLists, buyer, seller, businessIds, getProductListStatus, userPermissions, relationships } = props;
  const { productList, totalCount } = productLists;
  const sellerAccountId = seller.accountId;
  const buyerAccountId = buyer.accountId;
  const onCloseAddProductModal = () => setIsAddProductEnable(false);
  const onClickAddProducts = () => setIsAddProductEnable(true);
  useEffect(() => {
    props.fetchProductAgreements(sellerAccountId, buyerAccountId, [], [], 0, pageLimit);
  }, []);
  useEffect(
    () => {
      let isMount = true;
      const accountIds = businessIds.split('|');
      if (isMount && accountIds.length > 0) {
        isMount &&
          setHasUserManageProductAgreementPermission(
            isManageProductAgreementPermission(accountIds[0], userPermissions)
          );
        setManageContractPermission(isManageContractPermission(accountIds[0], props.userPermissions));
      }
      return () => {
        isMount = false;
      };
    },
    [businessIds]
  );
  useEffect(
    () => {
      let isMount = true;
      if (relationships && relationships[businessIds]) {
        const businessRelation = relationships[businessIds].relationships.find(
          relationship => relationship.buyer.accountId === buyerAccountId
        );
        if (businessRelation) {
          isMount && setRelationship(getRelationship(businessRelation));
        }
      }

      return () => {
        isMount = false;
      };
    },
    [relationships]
  );
  const onPageChange = selectedIndex => {
    const pageNumber = selectedIndex + 1;
    const offset = Math.floor(pageLimit * pageNumber) - pageLimit;
    props.fetchProductAgreements(sellerAccountId, buyerAccountId, [], [], offset, pageLimit);
    setPageIndex(selectedIndex);
  };
  const closeDrawer = () => setDrawerData(getInitialDrawerData());
  const onChangeSearch = async e => {
    if (isEmpty(e.target.value)) {
      props.fetchProductAgreements(sellerAccountId, buyerAccountId, [], [], 0, pageLimit);
      setSearchedProductId('');
    } else {
      setSearchedProductId(e.target.value);
    }
  };
  const onClickSearch = async e => {
    if (!isEmpty(searchedProductId)) {
      props.fetchProductAgreements(sellerAccountId, buyerAccountId, [searchedProductId], [], 0, pageLimit);
    }
  };
  const onUpdateProductAgreements = () => {
    setIsAddProductEnable(false);
    const pageNumber = pageIndex + 1;
    const offset = Math.floor(pageLimit * pageNumber) - pageLimit;
    props.fetchProductAgreements(sellerAccountId, buyerAccountId, [], [], offset, pageLimit);
  };
  const getDrawer = () => {
    return (
      <Drawer
        position={'left'}
        size={0.5}
        show={drawerData.show}
        onRequestHide={closeDrawer}
        header={t('productAgreementsPage:productInformation')}
        closeOnClickOutside={true}
        children={
          <ProductDetails
            id={drawerData.productAgreementId}
            productName={drawerData.productName}
            currentVersion={drawerData.currentVersion}
            productId={drawerData.productId}
            createSnackbar={false}
          />
        }
        footer={
          <div>
            <button onClick={closeDrawer} className="btn btn-primary">
              Close
            </button>
          </div>
        }
      />
    );
  };
  function renderProductIdLink({ original }) {
    const { productAgreementId, productId, productName, productServiceVersion } = original;
    return (
      <Link
        to={`/productAgreements/${businessIds}`}
        onClick={() =>
          setDrawerData({
            show: true,
            productAgreementId,
            productName,
            currentVersion: productServiceVersion,
            productId
          })
        }>
        {productId}
      </Link>
    );
  }
  const getColumns = () => {
    return [
      {
        id: 'productId',
        Header: t('productAgreementsPage:columnNames:productId'),
        sortable: false,
        accessor: t => t.productId,
        Cell: cellInfo => renderProductIdLink(cellInfo)
      },
      {
        id: 'product',
        Header: t('productAgreementsPage:columnNames:productName'),
        sortable: false,
        accessor: t => <div className="text-overflow">{t.productName}</div>
      },
      {
        id: 'versions',
        Header: t('productAgreementsPage:columnNames:versions'),
        accessor: t => t,
        sortable: false,
        Cell: cellInfo => {
          const { productVersions, productAgreementId } = cellInfo.original;
          if (productVersions && productVersions.length > 0) {
            return (
              <ProductVersion
                key={productAgreementId}
                agreement={cellInfo.original}
                buyerAccountId={buyerAccountId}
                sellerAccountId={sellerAccountId}
                hasUserManageProductAgreementPermission={hasUserManageProductAgreementPermission}
              />
            );
          }
          return '';
        }
      },
      {
        id: 'priceLock',
        Header: t('productAgreementsPage:columnNames:priceLock'),
        accessor: t => t,
        sortable: false,
        Cell: cellInfo => {
          const { productAgreementId } = cellInfo.original;
          return (
            <PriceLock
              key={productAgreementId}
              buyerAccountId={buyerAccountId}
              sellerAccountId={sellerAccountId}
              hasUserManageProductAgreementPermission={hasUserManageProductAgreementPermission}
              agreement={cellInfo.original}
            />
          );
        }
      }
    ];
  };
  const getCreateContractSection = () => {
    return (
      <Fragment>
        {' '}
        <div id="grid">
          <div id="areaA">
            <h3>
              <b>{t('productAgreementsPage:productsHeading')}</b>
            </h3>
          </div>
          <div id="areaB">
            <button
              type="button"
              className="btn btn-primary"
              onClick={onClickAddProducts}
              style={props.isAdmin || doesUserHasManageContractPermission ? { display: 'block' } : { display: 'none' }}>
              <div className="row" style={{ fontSize: '12px' }}>
                <i className="fas fa-plus" /> {t('productAgreementsPage:addProducts')}
              </div>
            </button>
          </div>
        </div>
        <br />
      </Fragment>
    );
  };
  const isLoading = getProductListStatus === LOADING;
  return (
    <div>
      {getCreateContractSection()}
      <TextField
        name="btnMid"
        placeholder={t('productAgreementsPage:productId')}
        value={searchedProductId}
        onChange={onChangeSearch}
        rightAddon={
          <button onClick={onClickSearch} className="btn btn-default">
            Search
          </button>
        }
      />
      <Table
        columns={getColumns()}
        data={productList}
        loading={isLoading}
        showPagination={true}
        page={pageIndex}
        pages={Math.ceil(totalCount / pageLimit)}
        pageSize={pageLimit}
        showPageSizeOptions={false}
        showPageJump={false}
        onPageChange={onPageChange}
        manual
        className={'productListing'}
      />
      {getDrawer()}
      <CreateAgreement
        onCloseModal={onCloseAddProductModal}
        updateProductList={onUpdateProductAgreements}
        isAddProductEnable={isAddProductEnable}
        relationship={relationship}
      />
    </div>
  );
}
const mapStateToProps = (state, ownProps) => {
  const { buyer, seller } = ownProps;
  const key = `${buyer.accountId}|${seller.accountId}`;
  return {
    isAdmin: identitySelectors.isUserAdmin(state),
    userPermissions: identitySelectors.getPermissionsPayload(state),
    productLists: productAgreementSelectors.getProductListByKey(state, key),
    relationships: productAgreementSelectors.getRelationships(state),
    getProductListStatus: productAgreementSelectors.getProductListStatus(state)
  };
};
const mapDispatchToProps = {
  fetchProductAgreements: productAgreementActions.fetchProductAgreementsAction
};
export default withSnackbar(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ProductAgreementTable)
);
