import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import BasicLayout from '../../layouts/BasicLayout';
import { PRICECHANGE_NOTIFICATION_TYPE } from '../../../constants/notification';
import { actions as notificationAction, selectors as notificationSelector } from '../../../reducers/productagreements';
import SunsetNotificationDetails from './SunsetNotificationDetails';
import PriceChangeNotificationDetails from './PriceChangeNotificationDetails';
import ErrorRobot from '../../common/ErrorRobot';
import { Snackbar, Breadcrumbs, BreadcrumbItem } from '@cimpress/react-components';
import { Link } from 'react-router-dom';

function NotificationDetails(props) {
  const { t } = useTranslation();
  const [notificationDetails, setNotificationDetails] = useState({});
  const [notificationType, setNotificationType] = useState('');
  const [snackbarState, setSnackbarState] = useState({
    show: false,
    bsStyle: 'info',
    delay: 3000
  });
  const [showRobot, setShowRobot] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  let { notificationId } = props.match.params;

  const manageAccountId = props.match.params.manageAccountId;

  useEffect(
    () => {
      const loadData = async () => {
        try {
          const fetchedNotificationDetails = await props.fetchNotificationDetails(notificationId);
          const notification = fetchedNotificationDetails.payload;
          setNotificationDetails(notification);
          setNotificationType(notification.notificationType);
          setShowRobot(false);
          setIsLoading(false);
        } catch (error) {
          setShowRobot(true);
        }
      };
      if (notificationId) {
        loadData();
      }
    },
    [notificationId]
  );

  async function onAcknowledge() {
    if (notificationDetails.isAck) {
      setSnackbarState({
        show: true,
        bsStyle: 'info'
      });
    } else {
      await props.updateNotification(notificationDetails.notificationId);
    }
  }

  const hideSnackbar = () => {
    setSnackbarState({
      show: false,
      bsStyle: 'info'
    });
  };

  const detailsPage = () => {
    if (notificationType === PRICECHANGE_NOTIFICATION_TYPE) {
      return (
        <PriceChangeNotificationDetails
          notificationDetails={notificationDetails}
          isLoading={isLoading}
          onAcknowledge={onAcknowledge}
          manageAccountId={manageAccountId}
        />
      );
    } else {
      return (
        <SunsetNotificationDetails
          notificationDetails={notificationDetails}
          isLoading={isLoading}
          onAcknowledge={onAcknowledge}
          manageAccountId={manageAccountId}
        />
      );
    }
  };

  return (
    <div>
      <div className="breadCrumbPadding">
        <Breadcrumbs>
          <BreadcrumbItem>
            <Link key={'/'} to={'/'}>
              {t('breadcrumbs:home')}
            </Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link key={`/notifications/${manageAccountId}`} to={`/notifications/${manageAccountId}`}>
              {t('breadcrumbs:notifications')}
            </Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>{t('breadcrumbs:notificationDetails')}</BreadcrumbItem>
        </Breadcrumbs>
      </div>
      <div className="titlePadding">
        <h1>{t('breadcrumbs:notificationDetails')}</h1>
      </div>

      <BasicLayout>
        {showRobot ? <ErrorRobot /> : detailsPage()}
        {
          <Snackbar
            show={snackbarState.show}
            bsStyle={snackbarState.bsStyle}
            delay={snackbarState.delay}
            onHideSnackbar={hideSnackbar}>
            {t('notificationDetails:notificationAlreadyAcknowledged')} {notificationDetails.acknowledgedBy}
          </Snackbar>
        }
      </BasicLayout>
    </div>
  );
}

const mapStateToProps = state => ({
  notificationsStatus: notificationSelector.getNotificationsStatus(state),
  notifications: notificationSelector.getNotifications(state)
});

const mapDispatchToProps = {
  fetchNotificationDetails: notificationAction.fetchNotificationDetailsAction,
  updateNotification: notificationAction.updateNotification
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationDetails);
