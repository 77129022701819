import React from 'react';
import { useTranslation } from 'react-i18next';
import SkuSelectorV2 from '@cimpress-technology/ff-react-components/lib/SkuSelectorV2';
import { auth } from '../../../utils/auth';
import { typeIsFulfiller } from '../../../reducers/identities/utils';
import omit from 'lodash/omit';

function ProductIdsSelector(props) {
  const { t, i18n } = useTranslation();

  const { selectedProductIds, seller, onChange } = props;

  const onAddProductId = productIdInfo => {
    onChange({ ...selectedProductIds, [productIdInfo.sku]: productIdInfo });
  };

  const onBulkAddProductId = ProductIdsToAdd => {
    onChange({ ...selectedProductIds, ...ProductIdsToAdd });
  };

  const onBulkRemoveProductId = ProductIdsToRemove => {
    onChange(omit(selectedProductIds, ProductIdsToRemove));
  };

  const onRemoveProductId = ProductIdInfo => {
    onChange(omit(selectedProductIds, ProductIdInfo.sku));
  };
  const initiator = false;
  return (
    <SkuSelectorV2
      addNonExistentSkus={false}
      locale={i18n.language}
      skuSearchSectionTitle={t('createContract:addProducts.searchAndAddProducts')}
      skuBinSectionTitle={t('createContract:addProducts.selectedProducts')}
      sellerFulfillerId={initiator && typeIsFulfiller(initiator.type) ? initiator.id : null}
      selectedSkus={selectedProductIds}
      onAddSku={onAddProductId}
      onRemoveSku={onRemoveProductId}
      onBulkAddSkus={onBulkAddProductId}
      onBulkRemoveSkus={onBulkRemoveProductId}
      accountIds={[seller.id]}
      unremoveableSkuList={[]}
      onGetAccessToken={auth.getAccessToken}
      marketable={false}
      skipLoadDescriptionsForBulkAddSkus={true}
    />
  );
}

export default ProductIdsSelector;
