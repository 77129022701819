import React from 'react';
import { css } from 'react-emotion';
import { useTranslation } from 'react-i18next';
import IconPictureSun from '@cimpress-technology/react-streamline-icons/lib/IconPictureSun';
import { toMonthDayYearDisplay } from '../../../utilities/dateHelper';
import { history } from '../../../utils/history';
import { SUNSET_NOTIFICATION_TYPE } from '../../../constants/notification';

const divStyle = css({
  display: 'flex',
  minHeight: '135px',
  padding: '5px'
});

const imageBox = css({
  flex: 1,
  padding: '15px'
});

const messageBox = css({
  flex: 6,
  padding: '10px'
});

const innerFlexBox = css({
  display: 'flex',
  flexDirection: 'column'
});

function Notification(props) {
  const { t } = useTranslation();

  const {
    notification: { notificationId, isAck, acknowledgedBy, notificationType, sunset, priceChange },
    manageAccountId
  } = props;

  const acknowledgedMessageStyle = isAck
    ? { cursor: 'pointer' }
    : { backgroundColor: 'rgb(241 253 255)', cursor: 'pointer' };

  const onClickNotification = () => {
    history.push(`/notifications/${manageAccountId}/${notificationId}`);
  };

  const notificationHeaderContent = () => {
    return notificationType === SUNSET_NOTIFICATION_TYPE
      ? sunset.productId
      : priceChange.products.length === 1
        ? priceChange.products[0].id
        : priceChange.products.length + ' products';
  };

  const formattedMessage = message => {
    const messages = message.split('{}');
    return (
      <div style={{ marginTop: '5px' }}>
        {`${messages[0]} ${toMonthDayYearDisplay(
          notificationType === SUNSET_NOTIFICATION_TYPE ? sunset.sunsetEndsOn : priceChange.effectiveDate
        )} ${messages[1]}`}{' '}
        <span style={{ color: '#0088a9' }}>
          {notificationType === SUNSET_NOTIFICATION_TYPE
            ? sunset.productId
            : priceChange.products.length === 1
              ? priceChange.products[0].id
              : priceChange.products.length + ' products'}
        </span>{' '}
        {` ${messages[2]}`}
      </div>
    );
  };

  return (
    <div className={divStyle} style={acknowledgedMessageStyle} onClick={onClickNotification}>
      <div className={imageBox}>
        <div className="card flex-containerImage" style={{ height: '100%' }}>
          <IconPictureSun color="grey" size="3x" />
        </div>
      </div>
      <div className={messageBox}>
        <div className={innerFlexBox}>
          <div style={{ flex: 1 }}>
            <h4>{t(`notifications:notificationItems.${notificationType}`) + notificationHeaderContent()}</h4>
          </div>
          <div>
            {formattedMessage(
              t(`notifications:notificationMessage.${notificationType}`),
              sunset ? sunset.productId : '',
              sunset ? sunset.sunsetEndsOn : ''
            )}
          </div>
          {isAck && (
            <div align="right" style={{ marginTop: '20px' }}>
              <b>{t(`notifications:acknowledgedBy`)}</b>
              {acknowledgedBy}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Notification;
