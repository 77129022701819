import React from 'react';
import { createDevTools } from 'redux-devtools';
import LogMonitor from 'redux-devtools-log-monitor';
import DockMonitor from 'redux-devtools-dock-monitor';

/**
 * Create the DevTools component and export it.
 */
export default createDevTools(
  <DockMonitor
    /**
     * Hide or show the dock with "alt-w".
     */
    toggleVisibilityKey="alt-w"
    /**
     * Change the position of the dock with "alt-q".
     */
    changePositionKey="alt-q"
    defaultIsVisible={false}>
    <LogMonitor theme="tomorrow" />
  </DockMonitor>
);
