import { SNACKBAR_ACTION, SnackbarType } from './Snackbar';

export const displayError = (msg, seconds = 4) => {
  return {
    type: SNACKBAR_ACTION.ERROR,
    msg: msg,
    seconds: seconds
  };
};

export const displayWarning = (msg, seconds = 4) => {
  return {
    type: SNACKBAR_ACTION.WARNING,
    msg: msg,
    seconds: seconds
  };
};

export const displaySuccess = (msg, seconds = 4) => {
  return {
    type: SNACKBAR_ACTION.SUCCESS,
    msg: msg,
    seconds: seconds
  };
};

export const displayInfo = (msg, seconds = 4) => {
  return {
    type: SNACKBAR_ACTION.INFO,
    msg: msg,
    seconds: seconds
  };
};

export const displaySnackbar = (msg, type, seconds = 4) => {
  return {
    type: SNACKBAR_ACTION.SNACKBAR,
    msg: msg,
    snackbarType: type || SnackbarType.info,
    seconds: seconds
  };
};
