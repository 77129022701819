import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { Card, Notifications } from '@cimpress/react-components';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { actions as notificationAction, selectors as notificationSelector } from '../../../reducers/productagreements';
import { AVAILABLE } from '../../../constants/entityStatus';
import countBy from 'lodash/countBy';
import Loading from '../../Loading';

const getUnAckNotificationsCount = notifications => {
  if (!notifications || notifications.length === 0) {
    return 0;
  }
  const unAckCount = countBy(notifications, 'isAck')['false'];
  return unAckCount ? unAckCount : 0;
};

const getFormattedMessage = (message, unAckNotificationsCount) => {
  const messages = message.split('{}');
  return (
    <span>
      {messages[0]} <b>{`${unAckNotificationsCount} ${messages[1]}`}</b>
      {messages[2]}
    </span>
  );
};

function NotificationsCard(props) {
  const { t } = useTranslation();

  const { notificationsStatus, notifications, manageAccountId } = props;
  const isLoading = notificationsStatus !== AVAILABLE;

  useEffect(
    () => {
      props.fetchNotifications([manageAccountId]);
    },
    [manageAccountId]
  );

  const unAckNotificationsCount = getUnAckNotificationsCount(notifications);
  const carHeader = (
    <span className="card-landing-header">
      <Notifications showNotificationsOnClick={false} numberUnread={unAckNotificationsCount} />
      <div className="card-header-title-with-icon">
        <h4>{t('breadcrumbs:notifications')}</h4>
      </div>
    </span>
  );
  return (
    <Card header={carHeader}>
      {isLoading ? (
        <Loading size="medium" />
      ) : (
        <Fragment>
          <div className="card-text">
            {getFormattedMessage(t('landingPage:notificationsCard:manageSettings'), unAckNotificationsCount)}
          </div>
          <div align="center" className="card-bottom-button">
            <Link to={`/notifications/${manageAccountId}`}>
              {t('landingPage:notificationsCard:manageNotifications')}
            </Link>
          </div>
        </Fragment>
      )}
    </Card>
  );
}

const mapStateToProps = state => ({
  notificationsStatus: notificationSelector.getNotificationsStatus(state),
  notifications: notificationSelector.getNotifications(state)
});

const mapDispatchToProps = {
  fetchNotifications: notificationAction.fetchNotificationsAction
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationsCard);
