import termRevisionItemBySku, { fetchTermRevisionItemBySku, getTermRevisionItemBySku } from './termRevisionItemBySku';
import { fetchTermRevisionPriceModels, getTermRevisionPriceModels } from './termRevisionPriceModels';
import {
  updateTermDiscountPercent,
  updateTermGracePeriod,
  updateTermMarkupPercent,
  updateTermName
} from './updateTerm';
import { fetchTermsForHomePageV2, resetTermsCache } from './fetchTermsForHomePage';

// actions
export const actions = {
  fetchTermRevisionItemBySku,
  fetchTermRevisionPriceModels,
  updateTermName,
  updateTermDiscountPercent,
  updateTermMarkupPercent,
  updateTermGracePeriod,
  fetchTermsForHomePageV2,
  resetTermsCache
};

// reducers
export default {
  termRevisionItemBySku
};

// selectors
export const selectors = {
  getTermRevisionItemBySku,
  getTermRevisionPriceModels
};
