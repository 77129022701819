import React from 'react';
import { useTranslation } from 'react-i18next';
import { PHOENIX_SQUAD_EMAIL_ID } from '../../constants/accounts';
import { Robot } from '@cimpress/react-components/lib/shapes/index';
import PropTypes from 'prop-types';

const ErrorRobot = ({ title, msg, includeContact }) => {
  const { t } = useTranslation();
  let titleRender = title;
  let msgRender = msg;

  if (typeof title === 'string') {
    titleRender = <h3>{title}</h3>;
  }

  if (typeof msg === 'string') {
    msgRender = <h5>{msg}</h5>;
  }

  return (
    <div className="error-container">
      {titleRender}
      <Robot bsStyle="danger" />
      {msgRender}
      {includeContact && (
        <i>
          <h5>{`${t('errorRobot:error')} ${PHOENIX_SQUAD_EMAIL_ID}.`}</h5>
        </i>
      )}
    </div>
  );
};

ErrorRobot.defaultProps = {
  includeContact: true
};

ErrorRobot.propTypes = {
  title: PropTypes.node,
  msg: PropTypes.node,
  includeContact: PropTypes.bool
};

export default ErrorRobot;
