import * as productAgreementApi from '../../services/ProductAgreement';
import { createReducer } from '../utils';
import { fetchAccountsAction } from '../account/fetchAccounts';
import keyBy from 'lodash/keyBy';
import groupBy from 'lodash/groupBy';
import get from 'lodash/get';

import { AVAILABLE, NOT_LOADED } from '../../constants/entityStatus';
export const fetchRelationshipsForHomePage_REQUEST = 'fetchRelationshipsForHomePage_REQUEST';
export const fetchRelationshipsForHomePage_SUCCESS = 'fetchRelationshipsForHomePage_SUCCESS';
export const fetchRelationshipsForHomePage_FAILURE = 'fetchRelationshipsForHomePage_FAILURE';
export const updateRelationship_REQUEST = 'updateRelationship_REQUEST';
export const updateRelationship_SUCCESS = 'updateRelationship_SUCCESS';
export const updateRelationship_FAILURE = 'updateRelationship_FAILURE';

const relationshipHooks = {
  onFailure: 'Unable to fetch relationships.'
};

const updateRelationshipHooks = {
  onRequest: 'Updating...',
  onSuccess: 'Updated!',
  onFailure: 'Failed to update.'
};

// Action
export const fetchRelationshipsAction = (businesses = [], managingAccountId = '') => {
  return {
    types: [
      fetchRelationshipsForHomePage_REQUEST,
      fetchRelationshipsForHomePage_SUCCESS,
      fetchRelationshipsForHomePage_FAILURE
    ],
    callAPI: async dispatch => {
      try {
        let relationships = await productAgreementApi.getRelationships(businesses);
        const accountMap = await getAccountMap(relationships, dispatch);

        // Manipulate data so it is easy to access
        relationships = addAccountAndKeys(relationships, accountMap, managingAccountId);
        relationships = groupBy(relationships, 'key');
        relationships = addDisplayNameAndBusinesses(relationships, accountMap);

        return relationships;
      } catch (e) {
        handleFetchRelationshipsError(e);
      }
    },
    hooks: relationshipHooks
  };
};

// Action
export const updateRelationshipAction = (relationshipDataKey, relationshipId, sunsetDuration, priceLockinDuration) => {
  return {
    types: [updateRelationship_REQUEST, updateRelationship_SUCCESS, updateRelationship_FAILURE],
    callAPI: async () => {
      try {
        let updatedRelationship = await productAgreementApi.putRelationship(
          relationshipId,
          sunsetDuration,
          priceLockinDuration
        );
        return {
          relationshipDataKey: relationshipDataKey,
          updatedRelationship: updatedRelationship
        };
      } catch (e) {
        handleUpdateRelationshipError(e);
      }
    },
    hooks: updateRelationshipHooks
  };
};

async function getAccountMap(relationships, dispatch) {
  const accountIds = new Set();

  relationships.forEach(relationship => {
    accountIds.add(relationship.buyer.accountId);
    accountIds.add(relationship.seller.accountId);
  });

  const responses = await dispatch(fetchAccountsAction(accountIds));
  const accounts = responses.payload.map(response => response.payload).filter(Boolean);

  return keyBy(accounts, 'accountId');
}

function addDisplayNameAndBusinesses(relationships, accountMap) {
  const getAccountIds = key => {
    return key.split('|');
  };

  const getDisplayName = (businessOne, businessTwo, accountMap) => {
    const businessOneName = accountMap[businessOne].name;
    const businessTwoName = accountMap[businessTwo].name;

    return `${businessOneName} and ${businessTwoName}`;
  };

  for (const [key, value] of Object.entries(relationships)) {
    const accountIds = getAccountIds(key);
    const businessOne = accountIds[0];
    const businessTwo = accountIds[1];

    // If we can see the account, user has permission
    const hasPermission = accountMap.hasOwnProperty(businessOne) && accountMap.hasOwnProperty(businessTwo);
    const display = hasPermission ? getDisplayName(businessOne, businessTwo, accountMap) : null;

    relationships[key] = {
      relationships: value,
      display,
      hasPermission,
      businessOne,
      businessTwo
    };
  }

  return relationships;
}

function addAccountAndKeys(relationships, accountMap, managingAccountId) {
  const createKey = (relationship, managingAccountId) => {
    let identities = [];
    if (relationship.buyer.accountId === managingAccountId) {
      identities = [relationship.buyer.accountId, relationship.seller.accountId];
    } else if (relationship.buyer.accountId === managingAccountId) {
      identities[0] = relationship.buyer.accountId;
      identities[1] = relationship.seller.accountId;
    } else {
      identities[0] = relationship.seller.accountId;
      identities[1] = relationship.buyer.accountId;
    }
    return identities.join('|');
  };

  return relationships.map(relationship => ({
    buyer: accountMap[relationship.buyer.accountId],
    seller: accountMap[relationship.seller.accountId],
    relationshipId: relationship.id,
    sunsetDuration: relationship.sunsetDuration,
    priceLockinDuration: relationship.priceLockinDuration,
    key: createKey(relationship, managingAccountId)
  }));
}

// reducer
export default createReducer(
  {},
  {
    [fetchRelationshipsForHomePage_SUCCESS]: (state, action) => {
      const { payload } = action;
      return { ...state, payload, status: AVAILABLE };
    },
    [fetchRelationshipsForHomePage_REQUEST]: (state, action) => {
      return { ...state, status: NOT_LOADED };
    },
    [updateRelationship_SUCCESS]: (state, action) => {
      const { payload } = action;
      let updatedPayload = { ...state.payload };
      updatedPayload[payload.relationshipDataKey].relationships.forEach(relationship => {
        if (relationship.relationshipId === payload.updatedRelationship.id) {
          relationship.sunsetDuration = payload.updatedRelationship.sunsetDuration;
          relationship.priceLockinDuration = payload.updatedRelationship.priceLockinDuration;
        }
      });
      return { ...state, payload: updatedPayload, updatedStatus: AVAILABLE };
    },
    [updateRelationship_REQUEST]: (state, action) => {
      return { ...state, updatedStatus: NOT_LOADED };
    }
  }
);

// Selector
export const getRelationships = state => {
  return state.productAgreements.relationships.payload;
};

// Selector
export const getRelationshipForBusiness = (state, buyerAccountId, relationshipDataKey) => {
  const relation = state.productAgreements.relationships.payload;
  if (relation && relation[relationshipDataKey]) {
    const businessRelation = relation[relationshipDataKey].relationships.find(
      relationship => relationship.buyer.accountId === buyerAccountId
    );
    if (businessRelation) {
      const { priceLockinDuration, relationshipId, sunsetDuration } = businessRelation;
      return { priceLockinDuration, relationshipId, sunsetDuration };
    }
  }
};

export const getRelationshipsForHomePage = state => {
  const relations = getRelationships(state);
  return relations && Object.values(relations);
};

export const getRelationshipStatus = state => {
  return state.productAgreements.relationships.status;
};

export const getRelationshipUpdatedStatus = state => {
  return state.productAgreements.relationships.updatedStatus;
};

const handleFetchRelationshipsError = err => {
  let errMsg = '';

  switch (err.statusCode) {
    case 403:
      errMsg = 'You do not have the correct permissions to fetch the relation.';
      break;
    case 422:
    case 400:
      errMsg = get(err, 'response.detail', 'Error in fetching relationships');
      break;
    default:
      errMsg = 'Fetching relationships was not successful.';
  }

  throw new Error(errMsg);
};

const handleUpdateRelationshipError = err => {
  let errMsg = '';

  switch (err.statusCode) {
    case 403:
      errMsg = 'You do not have the correct permissions to update the relation.';
      break;
    case 422:
    case 400:
      errMsg = get(err, 'response.detail', 'Error in updating the relationship');
      break;
    default:
      errMsg = 'Updating relationship was not successful.';
  }

  throw new Error(errMsg);
};
