import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import isEqual from 'lodash/isEqual';
import orderBy from 'lodash/orderBy';
import { css } from 'react-emotion';
import { Radio, RadioGroup, Tooltip, Button, Alert } from '@cimpress/react-components';
import { selectors as identitySelectors } from '../../../reducers/identities/index';
import { getCounterType } from '../../../reducers/identities/utils';
import { ALL, INDIVIDUAL, NONE } from '../../../constants/subscriptionConstants';
import { isManageContractPermission } from '../../../utilities/checkPermission';

const buttonStyle = css({
  marginLeft: '5px',
  textAlign: 'right'
});

const containerStyle = css({
  '.Select-menu-outer ': {
    position: 'relative'
  }
});
const getSubscribedParties = (subscribedParties, selectedCounterParties) => {
  if (!selectedCounterParties) {
    return subscribedParties;
  }

  if (subscribedParties.length === selectedCounterParties.length) {
    return selectedCounterParties;
  }
  return subscribedParties;
};

function Subscription(props) {
  const {
    defaultSelectedNotification,
    subscribedParties,
    identityType,
    // counterParties,
    notificationType,
    identityId,
    userAccount,
    isAdmin,
    manageAccountId
  } = props;

  const { t } = useTranslation();
  const [state, setState] = useState({
    selectedNotification: props.defaultSelectedNotification,
    selectedCounterParties: []
  });
  const [doesUserHasManageContractPermission, setManageContractPermission] = useState(false);

  useEffect(() => {
    var isManageContractPermissionForUser = isManageContractPermission(manageAccountId, props.userPermissions);
    setManageContractPermission(isManageContractPermissionForUser);
  }, []);

  useEffect(
    () => {
      setState(prevState => ({
        ...prevState,
        selectedNotification: defaultSelectedNotification,
        selectedCounterParties: getSubscribedParties(subscribedParties, selectedCounterParties)
      }));
    },
    [defaultSelectedNotification, subscribedParties]
  );

  const { selectedNotification, selectedCounterParties } = state;

  const onSave = () => {
    props.onSave(identityId, identityType, selectedNotification, selectedCounterParties.map(({ value }) => value));
  };
  const onCancel = () => {
    if (isSameSelection()) {
      return;
    }
    setState(prevState => ({
      ...prevState,
      selectedNotification: defaultSelectedNotification,
      selectedCounterParties: subscribedParties
    }));
  };
  const onChangeNotification = (e, value) => {
    setState(prevState => ({ ...prevState, selectedNotification: value }));
  };
  // const onSelect = selectedCounterParties => {
  //   setState(prevState => ({ ...prevState, selectedCounterParties }));
  // };

  const isSameSelection = () => {
    const { subscribedParties, defaultSelectedNotification } = props;
    const { selectedNotification, selectedCounterParties } = state;

    const isSameSelection = defaultSelectedNotification === selectedNotification;
    const hasSameSubscribedParties =
      !(isSameSelection && defaultSelectedNotification === INDIVIDUAL) ||
      isEqual(orderBy(subscribedParties, 'value'), orderBy(selectedCounterParties, 'value'));

    return isSameSelection && hasSameSubscribedParties;
  };

  const isUserCimpressOwnAccount = userAccount && userAccount.cimpressOwned;

  // const disableSelect = selectedNotification !== INDIVIDUAL;
  // const shownCounterParties = disableSelect ? [] : selectedCounterParties;
  const nothingSelected = selectedNotification === INDIVIDUAL && selectedCounterParties.length === 0;
  const sameAsBefore = isSameSelection();
  const disableSave = sameAsBefore || nothingSelected;

  const counterPartyType = getCounterType(identityType);
  const disableSaveTooltipMsg = sameAsBefore
    ? t('settings:subscriptions.subscriptionAccordion.noChangesWarning')
    : t('settings:subscriptions.subscriptionAccordion.noSelectionWarning', { context: counterPartyType });

  const saveButton = (
    <Button
      className={`btn btn-primary ${buttonStyle}`}
      disabled={disableSave}
      onClick={onSave}
      style={isAdmin || doesUserHasManageContractPermission ? {} : { display: 'none' }}>
      {t('common:commonWords.save')}
    </Button>
  );

  const cancelButton = (
    <Button
      className={`btn btn-default ${buttonStyle}`}
      onClick={onCancel}
      style={isAdmin || doesUserHasManageContractPermission ? {} : { display: 'none' }}>
      {t('common:commonWords.cancel')}
    </Button>
  );

  return (
    <div className={containerStyle}>
      {isUserCimpressOwnAccount ? (
        <Fragment>
          <RadioGroup name="counterParties" onChange={onChangeNotification} defaultSelected={selectedNotification}>
            <Radio
              label={t('settings:subscriptions.actions.subscribeToAll', {
                context: `${counterPartyType}_${notificationType}`
              })}
              value={ALL}
            />
            <Radio
              label={t('settings:subscriptions.actions.unsubscribeToAll', {
                context: `${counterPartyType}_${notificationType}`
              })}
              value={NONE}
            />
            {/* <Radio
              label={t('settings:subscriptions.actions.subscribeToIndividual', {
                context: `${counterPartyType}_${notificationType}`
              })}
              value={INDIVIDUAL}
            /> */}
          </RadioGroup>
          {/* <Select
            label={t('settings:subscriptions.selectLabel', { context: counterPartyType })}
            value={shownCounterParties}
            options={counterParties}
            isDisabled={disableSelect}
            onChange={onSelect}
            isMulti
          /> */}
          <div className={buttonStyle}>
            {disableSave ? <Tooltip contents={disableSaveTooltipMsg}>{saveButton}</Tooltip> : saveButton}
            {cancelButton}
          </div>
        </Fragment>
      ) : (
        <Alert
          type="info"
          message={t('settings:subscriptions.subscriptionNotAllowedForNonCimpressOwned')}
          dismissible={true}
        />
      )}
    </div>
  );
}

const mapStateToProps = state => ({
  isAdmin: identitySelectors.isUserAdmin(state),
  userPermissions: identitySelectors.getPermissionsPayload(state)
});
export default connect(mapStateToProps)(Subscription);
