import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { colors, Tooltip } from '@cimpress/react-components';
import { css } from 'react-emotion';

const defaultDisabledStyle = css`
  background-color: ${colors.alloy} !important;
  opacity: 0.75 !important;
  color: #697582 !important;
  border: 1px solid transparent !important;
  cursor: not-allowed !important;
}
`;

const linkStyle = css`
   {
    all: unset;
    color: ${colors.ocean.base};
    background-color: transparent;
    cursor: pointer;
  }

  &:active,
  &:hover {
    outline: 0;
    text-decoration: underline;
  }
`;

const linkDisabledStyle = css`
   {
    all: unset;
    color: ${colors.ocean.base};
    background-color: transparent;
    cursor: not-allowed;
  }

  &:active,
  &:hover {
    outline: 0;
    text-decoration: underline;
  }
`;

class Button extends PureComponent {
  static defaultProps = {
    disabled: false,
    disabledTooltipContents: null
  };

  static propTypes = {
    disabled: PropTypes.bool,
    disabledTooltipContents: PropTypes.node,
    bsStyle: PropTypes.oneOf(['default', 'link'])
  };

  state = {};

  render() {
    const {
      className,
      bsStyle,
      disabled,
      disabledStyle,
      disabledTooltipContents,
      onClick,
      children,
      ...rest
    } = this.props;

    let style = '';

    if (disabled) {
      style = disabledStyle || `btn ${defaultDisabledStyle}`;
    }

    if (bsStyle === 'link') {
      style = disabled ? disabledStyle || linkDisabledStyle : linkStyle;
    }

    if (className) {
      style = `${style} ${className}`;
    }

    const button = (
      <button className={style} onClick={disabled ? null : onClick} {...rest}>
        {children}
      </button>
    );

    return disabled && disabledTooltipContents ? (
      <Tooltip contents={disabledTooltipContents}>{button}</Tooltip>
    ) : (
      button
    );
  }
}

export default Button;
