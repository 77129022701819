import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Select } from '@cimpress/react-components';
import './OptionCard.css';
import { colors } from '@cimpress/react-components';
import { css } from 'react-emotion';

const errorStyle = css`
  color: ${colors.danger.base};
  text-align: center;
`;

class OptionCard extends PureComponent {
  static defaultProps = {
    textFieldLabel: '',
    selectLabel: ''
  };

  static propTypes = {
    icon: PropTypes.string,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired
      })
    ),
    optionsLabel: PropTypes.string,
    optionsActionMap: PropTypes.object.isRequired,
    onChangeOption: PropTypes.func.isRequired,
    selectedOption: PropTypes.string,
    description: PropTypes.string,
    footer: PropTypes.node,
    errorMessage: PropTypes.string
  };

  state = {
    selectedOption: null
  };

  render() {
    const {
      icon,
      options,
      optionsLabel,
      optionsActionMap,
      onChangeOption,
      selectedOption,
      description,
      className,
      footer,
      errorMessage
    } = this.props;

    const optionActions = optionsActionMap[selectedOption];
    const values = options.filter(each => each.value === selectedOption);

    return (
      <div className={`option-card ${className}`}>
        <div className="option-card__top">
          <div className="option-card__top__top">
            {icon && <div className="option-card__top__top__icon">{/* // <Icon name={icon} size="4x" /> */}</div>}
            <div className="option-card__top__top__right">
              <Select
                placeholder={optionsLabel}
                value={values.length > 0 ? values[0] : undefined}
                options={options}
                onChange={onChangeOption}
              />
              {optionActions && optionActions()}
            </div>
          </div>
          {description && <div className="option-card__top__description">{description}</div>}
        </div>
        <div className="option-card__bottom">{footer}</div>
        {errorMessage && <div className={errorStyle}>{errorMessage}</div>}
      </div>
    );
  }
}

export default OptionCard;
