import { combineReducers } from 'redux';

import selectedManageAccount, {
  setSelectedManageAccountAction,
  getSelectedManageAccountPayload,
  getSelectedManageAccountStatus
} from './selectedManageAccount';

// actions
export const actions = {
  setSelectedManageAccountAction
};

// selectors
export const selectors = {
  getSelectedManageAccountPayload,
  getSelectedManageAccountStatus
};

export default combineReducers({
  selectedManageAccount
});
