import * as pricingTermApi from '../../services/PricingTerm';
import { toCK } from '../utils';

export const fetchTermRevisionPriceModels_REQUEST = 'fetchTermRevisionPriceModels_REQUEST';
export const fetchTermRevisionPriceModels_SUCCESS = 'fetchTermRevisionPriceModels_SUCCESS';
export const fetchTermRevisionPriceModels_FAILURE = 'fetchTermRevisionPriceModels_FAILURE';

const cachePrefix = 'term-pricemodels:';

// action creator
export const fetchTermRevisionPriceModels = ({ termId, revisionId }) => {
  return {
    types: [
      fetchTermRevisionPriceModels_REQUEST,
      fetchTermRevisionPriceModels_SUCCESS,
      fetchTermRevisionPriceModels_FAILURE
    ],
    cacheKey: `${cachePrefix}${toCK(termId, revisionId)}`,
    callAPI: () => pricingTermApi.fetchTermRevisionPriceModels(termId, revisionId),
    hooks: null,
    extraPayload: {
      termId,
      revisionId
    }
  };
};

// selector
export const getTermRevisionPriceModels = (state, termId, revisionId) => {
  return state.contractV1.termRevisionItemBySku[toCK(termId, revisionId)];
};
