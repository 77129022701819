import React, { useState, Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Loading from '../../../components/Loading';
import { actions as contractV1Actions, selectors as contractV1Selectors } from '../../../reducers/contractV1';
import { NOT_LOADED, AVAILABLE } from '../../../constants/entityStatus';
import { Select, Toggle } from '@cimpress/react-components';
import { css } from 'react-emotion';
import ShippingContract from '../../../components/contracts/ShippingContract';
import { selectors as productAgreementSelectors } from '../../../reducers/productagreements';
import { getAccountIdsEnabledForShippingContract } from '../../service/DataStorageService';
import { SnackbarType, withSnackbar } from '../../../components/snackbar/Snackbar';
const selectStyle = css({
  width: '500px'
});

function SelectFulfillerMerchantStep(props) {
  const [fulfillerOptions, setFulfillerOptions] = useState([]);
  const [merchantOptions, setMerchantOptions] = useState([]);
  const [selectedShippingType, setSelectedShippingType] = useState(undefined);
  const [enableShippingContractAccountIds, setEnableShippingContractAccountIds] = useState([]);

  const { t } = useTranslation();
  const {
    sellerAccountId,
    buyerAccountId,
    fulfillers,
    fulfillersStatus,
    merchants,
    merchantsState,
    createContractData,
    setCreateContractData,
    sellerAccountName,
    buyerAccountName,
    enableShippingContractV1,
    onChangeOfShippingContractV1,
    createSnackbar
  } = props;

  useEffect(() => {
    props.fetchFulfillers();
    props.fetchMerchants();
    const loadShippingContractEnabledAccountIds = () => {
      getAccountIdsEnabledForShippingContract()
        .then(result => {
          setEnableShippingContractAccountIds(result);
        })
        .catch(() =>
          createSnackbar(t('common:contractV1Src.EnabledShippingContractV1ErrorMessage'), SnackbarType.error, 8)
        );
    };
    loadShippingContractEnabledAccountIds();
    const key = `${buyerAccountId}|${sellerAccountId}`;
    if (props.allShippingAgreements && props.allShippingAgreements[key]) {
      let shippingAgreement = props.allShippingAgreements[key][0];
      setSelectedShippingType(shippingAgreement.shippingAgreementTypeId);
    }
  }, []);

  useEffect(
    () => {
      if (fulfillersStatus === AVAILABLE && merchantsState === AVAILABLE) {
        var fulfillerOptions = [];
        var merchantOptions = [];
        if (fulfillers) {
          var fulfillersForOptions = fulfillers[sellerAccountId];
          if (fulfillersForOptions) {
            fulfillersForOptions.forEach(eachFulfillerDetail => {
              if (eachFulfillerDetail.archived !== false) {
                fulfillerOptions.push({
                  value: eachFulfillerDetail.fulfillerId,
                  label: eachFulfillerDetail.name,
                  id: eachFulfillerDetail.fulfillerId,
                  name: eachFulfillerDetail.name,
                  type: 'fulfillers'
                });
              }
            });
          }
          setFulfillerOptions(fulfillerOptions);
        }
        if (merchants) {
          var merchantsForOptions = merchants[buyerAccountId];
          if (merchantsForOptions) {
            merchantsForOptions.forEach(eachMerchantDetail => {
              if (eachMerchantDetail.active !== false) {
                merchantOptions.push({
                  value: eachMerchantDetail.merchantId,
                  label: eachMerchantDetail.displayName,
                  id: eachMerchantDetail.id,
                  name: eachMerchantDetail.name,
                  type: 'merchants'
                });
              }
            });
          }
          setMerchantOptions(merchantOptions);
        }
      }
    },
    [fulfillers, merchants]
  );

  const onChangeFulfiller = value => {
    var contractData = {
      initiator: value,
      acceptors: createContractData.acceptors,
      selectedSkus: {},
      productPricingTerm: {},
      shippingPricingTerm: {},
      createSuccessful: false
    };
    setCreateContractData(contractData);
  };

  const onChangeMerchant = value => {
    var contractData = {
      initiator: createContractData.initiator,
      acceptors: value,
      selectedSkus: {},
      productPricingTerm: {},
      shippingPricingTerm: {},
      createSuccessful: false
    };
    setCreateContractData(contractData);
  };

  const isLoading = fulfillersStatus === NOT_LOADED || merchantsState === NOT_LOADED;

  const renderContract = () => {
    const count = enableShippingContractAccountIds.filter(each => each.accountId === sellerAccountId).length;
    const contractV1Toggle =
      count > 0 ? (
        <div>
          <h5>{t('common:contractV1Src.DoYouWantToCreateShippingContractV1')}</h5>
          <Toggle on={enableShippingContractV1} onClick={onChangeOfShippingContractV1} />
        </div>
      ) : (
        <div />
      );
    const shippingAggreementV2 = enableShippingContractV1 ? (
      <div />
    ) : (
      <div>
        <h5>{t('createRelationships:shippingAgreementTitle')}</h5>
        <ShippingContract selectedShippingType={selectedShippingType} allowToEdit={false} onChange={() => {}} />
      </div>
    );
    return (
      <div>
        {contractV1Toggle}
        <div style={{ display: 'none' }}>{shippingAggreementV2}</div>
      </div>
    );
  };

  const getFulfillerMerchantSelectionPage = () => {
    return (
      <Fragment>
        <div>
          <h4>{t('createRelationships:title')}</h4>
          <div className={selectStyle}>
            <Select
              isDisabled
              isClearable
              label={t('createRelationships:selectSeller')}
              value={{ value: sellerAccountName, label: sellerAccountName }}
              onBlurResetsInput={false}
              menuPortalTarget={document.body}
            />
          </div>
          <br />
          <div className={selectStyle}>
            <Select
              label={'Who is the fulfiller'}
              value={createContractData.initiator}
              options={fulfillerOptions}
              onChange={onChangeFulfiller}
              required
            />
          </div>
          <br />
          <div className={selectStyle}>
            <Select
              isDisabled
              isClearable
              label={t('createRelationships:selectBuyer')}
              value={{ value: buyerAccountName, label: buyerAccountName }}
              onBlurResetsInput={false}
              menuPortalTarget={document.body}
              style={{ marginBottom: '8px' }}
            />
          </div>
          <br />
          <div className={selectStyle}>
            <Select
              isMulti
              label={'Who is the merchant'}
              value={createContractData.acceptors}
              options={merchantOptions}
              onChange={onChangeMerchant}
              required
              style={{ marginBottom: '8px' }}
            />
          </div>
          {renderContract()}
        </div>
      </Fragment>
    );
  };

  return <div>{isLoading ? <Loading /> : getFulfillerMerchantSelectionPage()}</div>;
}

const mapStateToProps = state => ({
  fulfillers: contractV1Selectors.getFulfillerIdentityData(state),
  fulfillersStatus: contractV1Selectors.getFulfillerIdentityDataStatus(state),
  merchants: contractV1Selectors.getMerchantConfigurationData(state),
  merchantsState: contractV1Selectors.getMerchantConfigurationStatus(state),
  allShippingAgreements: productAgreementSelectors.getShippingAgreementsPayload(state)
});

const mapDispatchToProps = {
  fetchFulfillers: contractV1Actions.fetchFulfillerIdentityData,
  fetchMerchants: contractV1Actions.fetchMerchantConfigurationData
};

export default withSnackbar(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SelectFulfillerMerchantStep)
);
