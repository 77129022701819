import * as priceLockApi from '../../services/priceLock';
import { createReducer } from '../utils';
import get from 'lodash/get';

import { AVAILABLE, LOADING, UNAVAILABLE } from '../../constants/entityStatus';
export const fetchPriceLocks_REQUEST = 'fetchPriceLocks_REQUEST';
export const fetchPriceLocks_SUCCESS = 'fetchPriceLocks_SUCCESS';
export const fetchPriceLocks_FAILURE = 'fetchPriceLocks_FAILURE';
export const updatePriceLock_REQUEST = 'updatePriceLock_REQUEST';
export const updatePriceLock_SUCCESS = 'updatePriceLock_SUCCESS';
export const updatePriceLock_FAILURE = 'updatePriceLock_FAILURE';
export const createPriceLock_REQUEST = 'createPriceLock_REQUEST';
export const createPriceLock_SUCCESS = 'createPriceLock_SUCCESS';
export const createPriceLock_FAILURE = 'createPriceLock_FAILURE';
export const deletePriceLock_REQUEST = 'deletePriceLock_REQUEST';
export const deletePriceLock_SUCCESS = 'deletePriceLock_SUCCESS';
export const deletePriceLock_FAILURE = 'deletePriceLock_FAILURE';

const fetchPriceLocksHooks = {
  onFailure: 'Failed to fetch price locks.'
};

const updatePriceLockHooks = {
  onRequest: 'Updating price lock...',
  onSuccess: 'Successfully updated the price lock',
  onFailure: 'Failed to update the price lock'
};

const createPriceLockHooks = {
  onRequest: 'Adding price lock data...',
  onSuccess: 'Successfully added the price lock',
  onFailure: 'Failed to add the price lock'
};

const deletePriceLockHooks = {
  onRequest: 'Deleting price lock data...',
  onSuccess: 'Successfully deleted the price lock',
  onFailure: 'Failed to delete the price lock'
};

export const maxInt32 = 2147483647;

// Action
export const fetchPriceLocksAction = (
  sellerAccountId,
  buyerAccountId,
  productIds = [],
  offset = 0,
  limit = maxInt32
) => {
  return {
    types: [fetchPriceLocks_REQUEST, fetchPriceLocks_SUCCESS, fetchPriceLocks_FAILURE],
    callAPI: async () => {
      try {
        const { priceLocks } = await priceLockApi.getPriceLocks(
          [sellerAccountId],
          [buyerAccountId],
          productIds,
          offset,
          limit
        );
        return createPriceLockData(priceLocks, buyerAccountId, sellerAccountId);
      } catch (e) {
        handleGetPriceLocksError(e);
      }
    },
    hooks: fetchPriceLocksHooks
  };
};

function createPriceLockData(priceLocks, buyerAccountId, sellerAccountId) {
  let priceLockData = [];
  priceLocks.forEach(priceLock => {
    priceLockData.push({
      priceLockId: priceLock.priceLockinId,
      productId: priceLock.productId,
      lockedUntilDate: priceLock.lockedUntil
    });
  });
  return {
    key: `${buyerAccountId}|${sellerAccountId}`,
    value: priceLockData
  };
}

// Action
export const updatePriceLockAction = (priceLockId, lockedUntilDate, productId, sellerAccountId, buyerAccountId) => {
  return {
    types: [updatePriceLock_REQUEST, updatePriceLock_SUCCESS, updatePriceLock_FAILURE],
    callAPI: async () => {
      try {
        await priceLockApi.updatePriceLock(priceLockId, lockedUntilDate);
        return {
          key: `${buyerAccountId}|${sellerAccountId}`,
          value: {
            lockedUntilDate: lockedUntilDate,
            productId: productId
          }
        };
      } catch (e) {
        handleUpdatePriceLockError(e);
      }
    },
    hooks: updatePriceLockHooks
  };
};

// Action
export const createPriceLockAction = (sellerAccountId, buyerAccountId, productId, lockedUntilDate) => {
  return {
    types: [createPriceLock_REQUEST, createPriceLock_SUCCESS, createPriceLock_FAILURE],
    callAPI: async () => {
      try {
        let priceLock = await priceLockApi.createPriceLock(sellerAccountId, buyerAccountId, productId, lockedUntilDate);
        return createPriceLockData([priceLock], buyerAccountId, sellerAccountId);
      } catch (e) {
        handleCreatePriceLockError(e);
      }
    },
    hooks: createPriceLockHooks
  };
};

// Action
export const deletePriceLockAction = (priceLockId, productId, sellerAccountId, buyerAccountId) => {
  return {
    types: [deletePriceLock_REQUEST, deletePriceLock_SUCCESS, deletePriceLock_FAILURE],
    callAPI: async () => {
      try {
        await priceLockApi.deletePriceLock(priceLockId);
        return {
          key: `${buyerAccountId}|${sellerAccountId}`,
          value: {
            productId: productId
          }
        };
      } catch (e) {
        handleDeletePriceLockError(e);
      }
    },
    hooks: deletePriceLockHooks
  };
};

// reducer
export default createReducer(
  {},
  {
    [updatePriceLock_REQUEST]: (state, action) => {
      return { ...state, status: LOADING };
    },
    [updatePriceLock_SUCCESS]: (state, action) => {
      const { payload } = action;
      let updatedPayload = state.payload;
      let updatedPriceLockData = updatedPayload[payload.key];
      let priceLockData = payload.value;
      let productId = priceLockData.productId;
      updatedPriceLockData.forEach(element => {
        if (element.productId === productId) {
          element.lockedUntilDate = priceLockData.lockedUntilDate;
        }
      });
      updatedPayload[payload.key] = updatedPriceLockData;
      return { ...state, payload: updatedPayload, status: AVAILABLE };
    },
    [updatePriceLock_FAILURE]: (state, action) => {
      return { ...state, status: UNAVAILABLE };
    },
    [updatePriceLock_REQUEST]: (state, action) => {
      return { ...state, status: LOADING };
    },
    [deletePriceLock_SUCCESS]: (state, action) => {
      const { payload } = action;
      let updatedPayload = state.payload;
      let updatedPriceLockData = updatedPayload[payload.key];
      let deletedProductId = payload.value.productId;
      updatedPriceLockData.forEach((element, elementIndex) => {
        if (element.productId === deletedProductId) {
          updatedPriceLockData.splice(elementIndex, 1);
        }
      });
      updatedPayload[payload.key] = updatedPriceLockData;
      return { ...state, payload: updatedPayload, status: AVAILABLE };
    },
    [deletePriceLock_REQUEST]: (state, action) => {
      return { ...state, status: UNAVAILABLE };
    },
    [deletePriceLock_FAILURE]: (state, action) => {
      return { ...state, status: LOADING };
    },
    [fetchPriceLocks_SUCCESS]: (state, action) => {
      const { payload } = action;
      let updatedPayload = state.payload ? state.payload : {};
      if (payload.value.length > 0) {
        if (updatedPayload[payload.key]) {
          payload.value.forEach(element => {
            updatedPayload[payload.key].push(element);
          });
        } else {
          updatedPayload[payload.key] = payload.value;
        }
      }
      return { ...state, payload: updatedPayload, status: AVAILABLE };
    },
    [fetchPriceLocks_FAILURE]: (state, action) => {
      return { ...state, status: UNAVAILABLE };
    },
    [createPriceLock_REQUEST]: (state, action) => {
      return { ...state, status: LOADING };
    },
    [createPriceLock_SUCCESS]: (state, action) => {
      const { payload } = action;
      let updatedPayload = state.payload ? state.payload : {};
      if (payload.value.length > 0) {
        updatedPayload[payload.key] = payload.value;
      }
      return { ...state, payload: updatedPayload, status: AVAILABLE };
    },
    [createPriceLock_FAILURE]: (state, action) => {
      return { ...state, status: UNAVAILABLE };
    }
  }
);

// Selector
export const getPriceLocks = state => {
  return state.productAgreements.priceLocks.payload;
};

export const getPriceLocksByKey = (state, key) => {
  if (state.productAgreements.priceLocks.payload) {
    return state.productAgreements.priceLocks.payload[key];
  }
};

export const getPriceLocksStatus = state => {
  return state.productAgreements.priceLocks.status;
};

const handleGetPriceLocksError = err => {
  let errMsg = '';

  switch (err.statusCode) {
    case 403:
      errMsg = 'You do not have the correct permissions to get the price locks.';
      break;
    case 422:
    case 400:
      errMsg = get(err, 'response.detail', 'Error in getting the price locks');
      break;
    default:
      errMsg = 'Getting the price locks was not successful.';
  }

  throw new Error(errMsg);
};

const handleUpdatePriceLockError = err => {
  let errMsg = '';

  switch (err.statusCode) {
    case 403:
      errMsg = 'You do not have the correct permissions to update the price lock.';
      break;
    case 422:
    case 400:
      errMsg = get(err, 'response.detail', 'Error in updating the price lock');
      break;
    default:
      errMsg = 'Updating the price lock was not successful.';
  }

  throw new Error(errMsg);
};

const handleCreatePriceLockError = err => {
  let errMsg = '';

  switch (err.statusCode) {
    case 403:
      errMsg = 'You do not have the correct permissions to add the price lock.';
      break;
    case 422:
    case 400:
      errMsg = get(err, 'response.detail', 'Error in adding the price lock');
      break;
    default:
      errMsg = 'Adding the price lock was not successful.';
  }

  throw new Error(errMsg);
};

const handleDeletePriceLockError = err => {
  let errMsg = '';

  switch (err.statusCode) {
    case 403:
      errMsg = 'You do not have the correct permissions to delete the price lock.';
      break;
    case 422:
    case 400:
      errMsg = get(err, 'response.detail', 'Error in deleting the price lock');
      break;
    default:
      errMsg = 'Deleting the price lock was not successful.';
  }

  throw new Error(errMsg);
};
