export function buildListQuery(param, values) {
  const query = `?${param}=`;

  values.map(value => {
    return query + value;
  });
  return values.join('');
}

export function buildQueryString(key, values) {
  if (values === []) {
    return '';
  }
  let query = '';
  values.forEach(value => {
    query = query + '&' + key + '=' + value;
  });
  return query;
}

export const maxInt32 = 2147483647;
