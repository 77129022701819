import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import AuthTokenExpired from '@cimpress-technology/react-auth-token-expired-modal';
import { withTranslation } from 'react-i18next';

import { auth } from '../../utils/auth';
import { actions as identitiesActions, selectors as identitiesSelectors } from '../../reducers/identities/index';
import { actions as configActions } from '../../reducers/configurations';
import ErrorRobot from '../common/ErrorRobot';
import Loading from '../Loading';
import { actions as identityContractV1Actions } from '../../reducers/contractV1/identities1';
import { actions as termsContractV1Actions } from '../../reducers/contractV1/terms';
import { actions as identityActions } from '../../reducers/contractV1';

class AuthedRoute extends Component {
  static propTypes = {
    // shouldLoadUserIdentities: PropTypes.bool.isRequired,
    userIdentitiesLoaded: PropTypes.bool.isRequired
    // userIdentitiesLoadFailed: PropTypes.bool.isRequired,
    // loadUserIdentities: PropTypes.func.isRequired
  };

  componentWillMount() {
    this.ensureLoggedInAndIdentitiesLoaded();
  }

  ensureLoggedInAndIdentitiesLoaded = () => {
    return auth
      .ensureAuthentication({ nextUri: window.location.pathname + window.location.search })
      .then(isAuthenticated => {
        if (isAuthenticated) {
          this.props.loadUserIdentities().then(() => {});
          this.props.loadAccountsPermissions();
          this.props.fetchUserConfig();
          this.props.loadUserIdentitiesContractV1().then(() => {
            this.props.fetchTermsForHomePage();
          });
          this.props.fetchFulfillerIdentityData();
          this.props.fetchMerchantConfigurationData();
        }
      });
  };

  authedRender = componentProps => {
    if (auth.isLoggedIn()) {
      if (this.props.userIdentitiesLoaded) {
        // if (!this.props.hasFulfillerIds && !this.props.hasMerchantIds) {
        //   return <ErrorRobot msg={this.props.t('errors:error_forbidden')} />;
        // }
        return React.createElement(this.props.component, componentProps);
      }
    }
    if (this.props.userIdentitiesLoadFailed) {
      return <ErrorRobot msg={this.props.t('errors:error_unknown')} />;
    }

    return <Loading />;
  };

  render() {
    const { component, ...routerProps } = this.props;
    return (
      <>
        <AuthTokenExpired authWrapper={auth} onLogin={this.ensureLoggedInAndIdentitiesLoaded} />
        <Route {...routerProps} render={props => this.authedRender(props)} />
      </>
    );
  }
}

const mapStateToProps = state => ({
  // shouldLoadUserIdentities: identitiesSelectors.shouldLoadUserIdentities(state),
  userIdentitiesLoaded: identitiesSelectors.getUserIdentitiesLoaded(state)
  // userIdentitiesLoadFailed: identitiesSelectors.getUserIdentitiesLoadFailed(state),
});

const mapDispatchToProps = {
  loadUserIdentities: identitiesActions.loadUserIdentities,
  loadAccountsPermissions: identitiesActions.getPermissionsAction,
  fetchUserConfig: configActions.fetchUserConfig,
  loadUserIdentitiesContractV1: identityContractV1Actions.loadUserIdentities,
  fetchTermsForHomePage: termsContractV1Actions.fetchTermsForHomePageV2,
  fetchFulfillerIdentityData: identityActions.fetchFulfillerIdentityData,
  fetchMerchantConfigurationData: identityActions.fetchMerchantConfigurationData
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(AuthedRoute));
