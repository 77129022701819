import React from 'react';
export const titlePadding = (t, businessOne, businessTwo, isSelf) => {
  return renderHeading(t, businessOne.name, businessTwo.name, isSelf);
};
const renderHeading = (t, businessOne, businessTwo, isSelf) => {
  if (isSelf) {
    return <div>{businessOne}</div>;
  }
  return <div>{t('productAgreementsPage:mainHeading', { businessOne: businessOne, businessTwo: businessTwo })}</div>;
};
