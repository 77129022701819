import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Loading from '../../Loading';
import { useTranslation } from 'react-i18next';
import { auth } from '../../../utils/auth';
import { getRuleSet } from '../../../services/SkuRuleSet';
import { getRequiredInputs } from '../../../services/PriceInput';
import PricingGrid from '@cimpress-technology/pricing-grid-ts';
import UnionSelector from '@cimpress-technology/union-selector';
import cloneDeep from 'lodash/cloneDeep';

const getInitialState = () => {
  return {
    skuRuleSet: {},
    productState: {},
    productPriceContractsInfoV2: {
      priceConfigurations: []
    },
    requiredAttributes: {},
    isLoading: true
  };
};

function PriceChangeNotificationPricingGrid(props) {
  const { t } = useTranslation();
  const { notificationDetails, sku } = props;
  const [state, setState] = useState(getInitialState);

  const { skuRuleSet, productState, productPriceContractsInfoV2, requiredAttributes, isLoading } = state;

  useEffect(
    () => {
      async function populateInitialData() {
        var skuRuleSets = await getRuleSet(sku);
        const authoritativeRuleSet = skuRuleSets.filter(each => each.authoritative);
        if (authoritativeRuleSet) {
          const skuRuleSet = authoritativeRuleSet[0];
          const requiredAttributes = await getRequiredInputs(sku);
          setState(prevState => ({
            ...prevState,
            skuRuleSet: skuRuleSet,
            productPriceContractsInfoV2: {
              resourceId: sku,
              priceConfigurations: [
                {
                  seller: notificationDetails.sellerAccountId,
                  buyer: notificationDetails.buyerAccountId,
                  priceDomain: 'product',
                  priceType: 'transferPrice',
                  title: t('notificationDetails:oldPrices'),
                  evaluationDate: notificationDetails.priceChange.effectiveDate
                },
                {
                  seller: notificationDetails.sellerAccountId,
                  buyer: notificationDetails.buyerAccountId,
                  priceDomain: 'product',
                  priceType: 'transferPrice',
                  title: t('notificationDetails:newPrices'),
                  evaluationDate: notificationDetails.priceChange.effectiveDate
                }
              ]
            },
            requiredAttributes: requiredAttributes,
            isLoading: false
          }));
        }
      }
      if (sku) {
        populateInitialData();
      }
    },
    [sku]
  );

  const onChange = productState => {
    const { sku } = props;
    const newProductState = {};
    if (productState[sku]) {
      newProductState[sku] = productState[sku];
    }
    const newProductStateValues = Object.values(newProductState);
    if (newProductStateValues && newProductStateValues.length > 0) {
      const { displayedAttributes } = newProductStateValues[0];
      setState(prevState => ({
        ...prevState,
        productState: newProductState,
        initialDisplayedAttributes: displayedAttributes
      }));
    } else {
      setState(prevState => ({ ...prevState, productState: newProductState, initialDisplayedAttributes: {} }));
    }
  };

  let skuData = {};
  if (productState && productState[sku] && productState[sku].selectedRow) {
    skuData = {};
    skuData[sku] = cloneDeep(productState[sku]) || {}; // use deep copy
  }

  return (
    <div>
      {isLoading &&
      !skuRuleSet &&
      !sku &&
      !productPriceContractsInfoV2 &&
      Object.keys(this.state.requiredAttributes).length === 0 &&
      this.state.requiredAttributes.constructor === Object ? (
        <Loading />
      ) : (
        <div>
          <div className={`col-xs-12 col-md-5`}>
            <UnionSelector
              ruleSet={skuRuleSet}
              showErrorModal
              authToken={auth.getAccessToken()}
              consumerId="price"
              showPivotConfig={false}
              onChange={onChange}
              showCompetitivePricing={false}
              requiredAttributes={requiredAttributes}
              competitorProducts={[]}
            />
          </div>
          <div className={`col-xs-12 col-md-7`}>
            <PricingGrid
              skuData={skuData}
              onGetAccessToken={auth.getAccessToken}
              isProduction={true}
              priceMode="total"
              parentService="contracts"
              widthOfGrid="100%"
              showTabbedView
              productPriceContractsInfoV2={productPriceContractsInfoV2}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default connect()(PriceChangeNotificationPricingGrid);
