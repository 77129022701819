export default {
  common: {
    contractV1Src: {
      DoYouWantToCreateShippingContractV1: 'Do you want to create a shipping contract?',
      EnabledShippingContractV1ErrorMessage: 'Error while fetching account ids to enable shipping contract.',
      NoAgreements:
        'The displayed contracts are created within the same business. So no agreements have been shown here.',
      PleaseContact: ' Please contact {{emailid}} if your business requires agreements.'
    },
    contractTypes: {
      listPrice: 'Catalog Price',
      specialPrice: 'Private Price',
      freeShipping: 'Free Shipping',
      shippingCost: 'Shipping Cost',
      shippingPrice: 'Shipping Price',
      listPriceWithDiscount: '$t(common:contractTypes.listPrice) with $t(common:commonWords.discount)',
      listPriceWithMarkup: '$t(common:contractTypes.listPrice) with $t(common:commonWords.markup)',
      specialPriceWithMarkup: '$t(common:contractTypes.specialPrice) with $t(common:commonWords.markup)',
      shippingCostWithDiscount: '$t(common:contractTypes.shippingCost) with $t(common:commonWords.discount)',
      shippingCostWithMarkup: '$t(common:contractTypes.shippingCost) with $t(common:commonWords.markup)',
      shippingPriceWithDiscount: '$t(common:contractTypes.shippingPrice) with $t(common:commonWords.discount)',
      shippingPriceWithMarkup: '$t(common:contractTypes.shippingPrice) with $t(common:commonWords.markup)',
      DoYouWantToCreateShippingContractV1: 'Do you want to create a shipping contract?'
    },
    contractStatus: {
      approved: 'Approved',
      pending: 'Pending',
      terminated: 'Terminated',
      rejected: 'Rejected',

      draft: 'Draft',
      effective: 'Effective',

      visibleInCatalog: 'Visible in Catalog',
      usingDeletedModel: 'Using Deleted Price Model',

      expiredAgo: '(Expired {{ duration, localizeDuration }})',
      isExpiring: '(Expiring {{ duration, localizeDuration }})',
      pending_yourResponse: 'Needs Review',
      pending_otherParty: 'Pending Approval',
      needsApproval: 'Need Approval',

      pending_priorTerm: 'Previously $t(common:contractStatus.pending)',
      approved_priorTerm: 'Previously $t(common:contractStatus.approved)',
      rejected_priorTerm: 'Previously $t(common:contractStatus.rejected)',
      terminated_priorTerm: 'Previously $t(common:contractStatus.terminated)',

      gracePeriodAboutToExpire: 'Will be auto-approved <1>{{ duration, localizeDuration }}</1>'
    },
    contract: {
      lastUpdatedXAgo: '{{ duration, localizeDuration }}',
      privateButDiscoverableTip:
        'The item prices on this private price contract are public and discoverable in the catalog. ' +
        'Create a catalog price contract in place of this private price contract.',
      deletedModelsTip:
        'This contract uses deleted price models, which could affect price updates. Please contact {{ email }} for assistance'
    },
    commonTerminology: {
      shippingPriceModel: 'Shipping Price Model',
      priceModel: 'Price Model',
      contractName: 'Contract Name',
      effectiveStartDate: 'Effective Start Date',
      effectiveEndDate: 'Effective End Date',
      discount: '{{ percentage }}% $t(common:commonWords.discount)',
      markup: '{{ percentage }}% $t(common:commonWords.markup)',
      name: 'Name',
      indefinite: 'Indefinite',
      revision: 'Revision',
      mcpSystem: 'the MCP system',
      gracePeriod: 'Grace Period',
      effectiveDates: 'effective Dates (in UTC)',
      lastUpdated: 'Last Updated',
      seller: 'Seller',
      seller_plural: 'Sellers',
      seller_oneOrMore: 'Seller(s)',
      buyer: 'Buyer',
      buyer_plural: 'Buyers',
      buyer_oneOrMore: 'Buyer(s)',
      search: 'Search',
      status: 'Status',
      status_plural: 'Statuses',
      status_oneOrMore: 'Status(es)',
      type: 'Type',
      contractType: 'Contract Type',
      contractType_plural: 'Contract Types',
      contractType_oneOrMore: 'Contract Type(s)',
      hasGracePeriod: 'Has Grace Period',
      actions: 'Actions',
      ProductId: 'Product Id',
      currentVersion: 'Current Version'
    },
    commonWords: {
      discount: 'Discount',
      markup: 'Markup',
      yesText: 'Yes',
      noText: 'No',
      onText: 'On',
      offText: 'Off',
      blank: '',
      next: 'Next',
      back: 'Back',
      prev: 'Prev',
      orText: 'or',
      save: 'Save',
      delete: 'Delete',
      cancel: 'Cancel',
      close: 'Close',
      countries: 'Countries',
      contracts: 'Contracts',
      authorize: 'Authorize',
      edit: 'Edit'
    },
    unsavedChanges: {
      message: 'You have an action in progress. Are you sure you want to navigate away?'
    },
    actions: {
      createNewContract: 'Create New Contract',
      createButtonText: 'Create'
    }
  },
  authorizationFlow: {
    errorTitle: 'Authorization Error',
    title: 'Before We Begin...',
    descriptionMessage1: 'Welcome to Contract Manager!',
    descriptionMessage2:
      'For first time users, in order to subscribe to terms and receive notifications, you must first be ',
    descriptionMessage3: 'authorized',
    descriptionMessage4: 'with the Notifications Hub service.',
    descriptionMessage5: 'You will be redirected to Marketplace Central when you click the',
    descriptionMessage6: 'button below.',
    authErrorMessage1: 'Something went wrong while authorizing for notifications.',
    authErrorMessage2: 'For support, please contact {{ FFS_EMAIL_ADDRESS }}.'
  },
  investigateSku: {
    investigate: 'Find Contracts with SKU',
    getDetails: 'Get Details',
    noContractsFound: "No contracts found with SKU '{{sku}}'",
    emptySearch: 'Search must not be empty',
    contractsBySku: {
      header: 'Contract Details for {{ sku }}',
      header2: 'Businesses with contracts for {{sku}}',
      selectAContract: 'Select a contract to view its details and pricing related to this SKU',
      viewContract: 'View Contract'
    }
  },
  appShell: {
    loudspeakerModal: {
      title: "What's new in Contract Manager?"
    }
  },
  breadcrumbs: {
    notifications: 'Notifications',
    home: 'Contracts',
    contracts: 'Contracts',
    configurations: 'Configurations',
    notFound: 'Not Found',
    bulkUpdate: 'Bulk Update',
    viewSku: '{{ sku }}',
    contractChanges: 'Contract Changes',
    createContracts: 'Create Contracts',
    viewContractRevision: 'View Contract Revision',
    createProductAgreements: 'Create Product Agreements',
    productAgreementDetails: 'Product Agreements Details',
    notificationDetails: 'Notification Details'
  },
  landingPage: {
    nameGreeting: 'Hi {{ name }}, ',
    welcome: 'Welcome to Contract Manager',
    setupContracts: 'Setup and manage contracts with businesses to help you buy and sell products.',
    todaysReport: "Today's Report",
    statCardTotalLabel: 'Total',
    createContractsCard: {
      header: 'Create and Manage Contracts',
      cardBody:
        'Setup and manage contracts with buyers and sellers. Add SKUs to contracts, define terms, discounts, and other conditions.',
      numberOfBuyerAndSellers:
        '<0>{{ numberOfBuyers }}</0> buyer contracts, <2>{{ numberOfSellers }}</2> seller contracts',
      browseAll: 'Browse All'
    },
    investigateSkusCard: {
      header: 'Investigate SKUs with Contracts',
      cardBody:
        'See contract details for specific SKUs like what contracts have the SKU, what are their terms, and current status.'
    },
    settingsCard: {
      header: 'Configurations',
      manageSettings: 'Manage various settings related to contracts:',
      manageSubscriptions: 'Manage contract notification subscriptions',
      manageAutoApproval: 'Define auto-approval for buyers and sellers',
      manageShippingContracts: 'Enable or disable shipping contracts',
      manageSubscriptionsAndApprovalsButton: 'Configure Subscriptions'
    },
    notificationsCard: {
      manageNotifications: 'See the notifications',
      manageSettings: 'You have {} unacknowledged notifications {} about price changes or product archiving.'
    },
    recentlyCreatedContractsCard: {
      header: 'Recently Created Contracts'
    },
    manageContractsFor: 'Manage contracts for:',
    contracts: 'Contracts'
  },
  homePage: {
    header: {
      title: 'My Contracts'
    },
    searchBarPlaceholder: 'Search by contract or by sku on contract',
    selectMultipleContracts: {
      label: 'Select Multiple Contracts:',
      contractSelection: {
        contractTypeLabel: 'Contract Type',
        selectSeller: 'Select Seller',
        addSKUsTo: 'Add SKUs to Selected Contract',
        addSKUsTo_plural: 'Add SKUs to {{count}} Selected Contracts'
      }
    }
  },
  widgets: {
    loadError: 'Something went wrong while loading this widget.',
    title: 'Widget: {{ title }}'
  },
  relationshipTable: {
    all: 'All',
    marketplace: 'B2B',
    fulfilmentNetwork: 'Fulfilment Network',
    searchTextFieldPlaceholder: 'Search by account id or name',
    ContractV1: { selfRelationship: 'Self' }
  },
  contractsTable: {
    effectiveContractPopover:
      'An effective pricing term is the term that is used to calculate when an order is placed on the MCP.',
    effectiveContractDetails:
      'This contract is effective for <1>{{ sku }}</1> between buyer <3>{{ buyer }}</3> and seller <5>{{ seller }}</5>',
    effectiveContractAdditionalDetails:
      'For a contract to be effective, it needs to be approved and take the <1>highest precedence</1> <2/>.',
    onlyOneEffectiveContractDetails:
      'There can only be one effective contract for a given sku, buyer and seller at a given time.'
  },
  tableCards: {
    allContracts: 'All Contracts',
    needsReview: 'Needs Review',
    pendingApproval: 'Pending Approval'
  },
  contractsFilter: {
    filterOffLabel: 'Additional Filters',
    filterOnLabel: 'Filtering on',
    clearFilterLabel: 'clear filter'
  },
  contractInfo: {
    author: 'Author',
    possibleCurrencies: 'Possible Currencies',
    infoSectionTitle: 'Contract Information',
    pricingSectionTitle: 'Pricing',
    partiesSectionTitle: 'Involved Parties',
    detailsSectionTitle: 'Details',
    excludedCountriesTitle: 'Excluded Countries',
    destinationCountriesTitle: 'Destination Countries',
    latestRevisionTitle: 'Latest Revision',
    noOfItemsTitle: 'Number of Items',
    remarksTitle: 'Remarks',
    allCountries: 'All Countries',
    noGracePeriod: 'No Grace Period',
    viewChanges: 'View Changes',
    viewAllCountries: 'View all {{ count }} countries',
    updatedBy: 'Updated by',
    priceModelsOnContract: 'Price Model on this contract',
    priceModelsOnContract_plural: 'Price Models on this contract',
    defaultNameForPriceModel: 'Undefined',
    statusChangedOn: 'Status changed on <1>{{ dateTime, localDateTime }}</1>',
    notTheLatestRevisionText:
      'This revision is read-only since this is not the latest revision. <1>Click here to view the latest revision</1>',
    readOnlyRevisionText:
      'This page is read-only since you are not viewing the latest revision. <1>Click here to view the latest revision</1>',
    gracePeriod: '{{ duration, localizeDurationSimple }}',
    gracePeriodDescription:
      'The grace period defines the number of days the acceptor has to review changes made to a contract before they approve or reject the updates. ' +
      "When the grace period expires, the contract will be automatically approved. When the grace period doesn't exist, the contract will not be automatically approved."
  },
  contractActions: {
    viewPricing: 'View Pricing',
    viewPricingToolTip: 'Click here to view more information about this term such as skus and pricing',
    publish: 'Publish to {{ otherParty }} for Approval',
    approve: 'Approve',
    approving: 'Approving',
    terminate: 'Terminate',
    terminateFinal: 'I understand. Terminate',
    terminated: 'Terminated!',
    goToTerminatedRevision: 'Go to terminated revision',
    sendForApproval: 'Send for Review and Approval',
    saveAndUpdate: 'Save and Update',
    validateUniqueMessage: 'The value must be different from the previous value.',
    requiredMessage: 'The value is required',
    editGracePeriodModal: {
      title: 'Edit Grace Period Days',
      validateRangeMessage: 'The values must be from 1 to 999.',
      checkboxLabel: 'No grace period'
    },
    editModifierModal: {
      title: 'Edit {{modifierType}} % amount'
    },
    editNameModal: {
      title: 'Edit Name'
    },
    editSkusModal: {
      successful: 'Successful',
      bulkAddSkusMessage: '{{numberOfSkusAdded}} skus(s) were added.',
      bulkAddDuplicateMessage: '{{duplicateSkus}} were skipped because they are already selected.',
      toolTipNoChangesMessage: 'There are no changes.',
      toolTipOneOrMoreChangesMessage: 'You need 1 or more items.',
      toolTipNotCompatibleMessage: 'The price model is not compatible with the selected skus.',
      newRevisionActionMessage:
        'A new revision of the contract will be created and the other party will be notified to take action.',
      noProductsAddedMessage: 'No products added',
      noProductsRemovedMessage: 'No products removed',
      productsAddedMessage: 'products added: {{ products}}',
      productsRemovedMessage: 'products removed: {{ products}}',
      noChangesToPricingMessage: 'No changes to pricing',
      priceModelChangedMessage: 'Price Model changed to "{{ priceModel }}',
      updating: 'Updating...',
      updated: 'Updated!',
      checkboxLabel: 'Change Price Model',
      title: 'Edit Items'
    }
  },
  contractChanges: {
    metaData: {}
  },
  createContract: {
    messages: {
      missingFieldsMessage: 'There are missing and/or invalid fields in the form',
      contractNameMissingMessage: 'Contract name is required.',
      modifierEmptyMessage: 'Modifier must not be empty.',
      endDateMissingMessage: 'End date must be set.',
      createUnsuccessfulMessage: 'Are you sure you want to leave the page?',
      onlyFulfillersAllowed: 'Currently only fulfillers are allowed to create contracts.',
      changingInitiatorWarning: 'Changing the initiator will reset your progress. Press OK to continue.',
      markupPercentageNotGreaterThan0Message: 'The markup percent must be greater than 0.',
      discountPercentageMustBeBetween0And100: 'The discount percent must be between 0 and 100.',
      modifierAmountNotValidMessage: 'The modifier amount is invalid.',
      setModifierHeader_discount: 'Set Discount Percentage',
      setModifierHeader_markup: 'Set Markup Percentage',
      modifierTextFieldLabel_discount: 'Discount Percentage (%)',
      modifierTextFieldLabel_markup: 'Markup Percentage (%)',
      requestPermissionsMessage: 'Please contact {{ email }} to gain access.'
    },
    chooseTypeModal: {
      modalTitle: 'What would you like to do?',
      buyerTypeTitle: 'I want to buy',
      sellerTypeTitle: 'I want to sell',
      sellerTypeDescription: 'I want to create a contract as a seller',
      buyerTypeDescription: 'I want to create a contract as a buyer'
    },
    termEntryForm: {
      noEndDate: 'No end date',
      notesToApproverLabel: 'Send notes to the approver',
      additionalInformationLabel: 'Extra information or remarks',
      contractNameLabel: 'Name your contract',
      contractDurationLabel: 'Set the duration of your contract',
      noCountrySelectedMessage: 'Please select at least one country.',
      chooseDestinationCountries: 'Choose destination countries where your contract is valid',
      allDestinationCountries: 'All destination countries',
      allDestinationCountriesExcept: 'All destination countries <1>except</1>',
      individualDestinationCountries: 'Individual destination countries'
    },
    validate: {
      buyerSelect: 'Please select a buyer',
      sellerSelect: 'Please select a seller',
      skuSelect: 'Please select at least one sku',
      contractName: 'Please enter a contract name',
      pricingContractTypeSelect: 'Please select a pricing contract type',
      creationTypeSelect: 'Please select a contract to update.'
    },
    getStarted: {
      sellerTitle: 'Get Started',
      buyerTitle: 'Select Parties',
      header: 'Select parties for your contracts',
      selectSeller: 'Select the seller',
      createRelationTitle: 'Create Relationships',
      createRelationButtonText: 'createRelationTitle',
      selectBuyer: 'Select the buyer',
      selectBuyingFrom: 'Select the seller to buy from',
      missingSellerMessage: 'Please choose the seller.',
      selectBuyers: 'Select the buyer(s) reviewing the contracts',
      missingBuyerMessage: 'Please choose the buyer(s)',
      addGracePeriod: 'Add grace period?',
      invalidGracePeriodSelection: 'The grace period should be greater than 0 days.',
      gracePeriodLabel: 'Days in grace period',
      lockinPeriodLabel: 'Days in price lock-in period',
      gracePeriodDescription:
        'If grace period exists, any updates to the contract will be automatically approved if reviewer does not respond within the grace period.'
    },
    selectExistingContract: {
      header: 'Select an existing contract to add the products to'
    },
    selectCreationTypeStep: {
      title: 'Choose New Or Existing',
      existingContractsWarning:
        'You have existing contract(s) with {{ name }}. You can choose to update one of these contracts or create a new contract.',
      header: 'Create a new contract or update existing contract'
    },
    addProducts: {
      title: 'Add Products',
      header: 'Select products to add to your contracts',
      searchAndAddProducts: 'Search and Add Products',
      selectedProducts: 'Selected Products',
      mustSelectProductsMessage: 'You must select products to add to your contract',
      disabledListPriceMessage: 'The selector is disabled because the SKU does not have catalog price set up',
      bulkListPriceErrorMessage:
        'The above SKUs could not be added because they do not have catalog price or exist on the platform. Please review them and try again.'
    },
    chooseNewOrExisting: {
      existingButtonTitle: 'Update existing contract',
      existingButtonDescription: 'Add skus to existing contract with {{name}}',
      newButtonDescription: 'Create new contract with {{name}}'
    },
    addContract: {
      title: 'Configure a catalog price contract for products'
    },
    pricingContract: {
      title: 'Product Price Contract',
      header: 'Configure a product price contract for products',
      description: 'Choose catalog price or private price for products and add optional discount or markup.',
      selectedPriceModel: 'Selected Price Model (Only One Available)',
      selectedPriceModel_plural: 'Selected Price Model',
      changePriceModel: 'Change Price Model',
      noPriceModelFoundMessage:
        'No price models found. Please go back and re-select your products or try searching again. For support, please contact {{ contactEmailAddress }}',
      selectAPriceModel: 'First, select a price model to apply to your skus',
      mustSelectAPriceModel: 'You must select your contract type and set up your product pricing contract',
      mustSelectShippingAPriceModel: 'You must select your contract type and set up your shipping contract'
    },
    shippingContract: {
      title: 'Shipping Contract',
      header: 'Configure a shipping price contract for products',
      description:
        'Base shipping price off shipping cost, pre-configured shipping price, or offer free shipping. Optionally, add discount or markup.',
      noShippingPriceModelsFoundMessage:
        'No shipping price model found. For support, please contact {{ contactEmailAddress }}',
      shippingPriceModelSelectionLabel: 'Selected Shipping Price Model'
    },
    result: {
      step_0: 'Notifications are sent to the accepting parties listed in the terms.',
      step_1: 'Approvers will read and approve your contract.',
      step_1_plural: 'Approvers will read and approve your contracts.',
      step_2: 'Once approved, the contract will become active.',
      step_2_plural: 'Once approved, the contracts will become active.',
      description: 'Your new contract has been created and sent for approval',
      description_plural: 'Your new contracts have been created and sent for approval',
      header: 'Success!',
      whatHappensNext: 'What happens next?',
      backToHomePage: 'Back to Home Page',
      createNewContract: 'Create New Contract',
      viewContract: 'View Contract',
      createNewContract_plural: 'Create New Contracts',
      duplicateContract: 'Duplicate This Contract',
      duplicateContract_plural: 'Duplicate These Contracts',
      creatingContractsMessage: 'We are creating the contracts for you',
      pleaseDoNotRefresh: 'Please do not refresh or close this page.',
      notAllSKUsHaveCatalogPricing: 'Not all skus have catalog prices set up. Please set them up and try again.',
      notHaveCorrectPermissions: 'You do not have the correct permissions to create this contract.',
      somethingWentWrong: 'Something went wrong.',
      contactInformation: 'Please contact {{ contactEmailAddress }} if you need assistance.',
      errorCreatingContractMessage:
        'Something went wrong while creating your contract. If the problem persists, please contact {{ contactEmailAddress }}.'
    },
    review: {
      title: 'Review',
      general: 'General',
      generalDetails: 'General Details for {{name}}',
      buyerHeader: 'For {{buyer}}',
      header: 'Review and finalize your Contract',
      header_plural: 'Review and finalize your Contracts',
      description: 'Review your contract and send for approval. When approved, your contract will become active.',
      description_plural:
        'Review your contracts and send for approval. When approved, your contracts will become active.',
      contractDetailsLabel: 'Contract Details',
      contractDetailsLabel_plural: 'Contracts Details',
      noContractBeingSetupMessage: 'You must have at least one contract set up.',
      productsInContract: 'Products in Contract',
      productsToAddContract: 'Products to add to Contract',
      productsInContract_plural: 'Products in Contracts'
    }
  },
  terminationWarnings: {
    header: 'If you continue...',
    warning_0: 'A new revision will be created with TERMINATED status',
    warning_1: 'No further changes and revisions can be made for this contract.',
    warning_1_0: '<0>This means a new contract has to be created in order to recreate this contract.</0>',
    warning_1_1:
      '<0>If this is a catalog price contract, catalog price updates will <1>not</1> impact this contract.</0>',
    warning_2: 'The contract will not be active between the two parties.',
    warning_2_0:
      '<0>If buyer A was buying from seller B, buyer A will <1>no longer be able to</1> buy with this contract.</0>',
    warning_3: 'This contract will no longer appear in the home page.',
    warning_4: 'This action is irreversible.'
  },
  errors: {
    error_general: 'Error',
    error_notFound: 'Not Found',
    error_create_permission: "You don't have permission to create contracts",
    error_forbidden: 'You do not have the permissions to enter this page.',
    error_unknown: 'Something went wrong while loading user identities.',
    withCommentAndStatusCode: 'Error: {{serviceComment}} ({{statusCode}})'
  },
  settings: {
    header: 'Configurations',
    subscriptions: {
      header_contractNotification: 'Contract Subscriptions',
      header_productNotification: 'Product Subscriptions',
      header2_contractNotification:
        'Manage your subscriptions to email notifications when product version archiving or price changes are triggered.',
      header2_productNotification: 'Manage your subscriptions to email notifications when a product is updated. ',
      header3: 'Select an identity, and choose which party you would like to subscribe to.',
      multipleRolesWarning_fulfillers:
        'If you have access to both sellers and buyers, only the subscriptions you make from the <1>seller</1> side will display on this page.',
      multipleRolesWarning_merchants:
        'If you have access to both sellers and buyers, only the subscriptions you make from the <1>buyer</1> side will display on this page.',
      fetchingMessage: 'Fetching your subscriptions...',
      fetchinErrorMessage: 'Error fetching your current subscriptions',
      searchBy_fulfillers: `Search by seller name`,
      searchBy_merchants: `Search by buyer name`,
      manageSettingsFor: 'Manage for',
      addSubscriptionButton_fulfillers: 'Configure multiple sellers',
      addSubscriptionButton_merchants: 'Configure multiple buyers',
      addSubscriptionTooltip_fulfillers: 'Configure subscriptions for multiple sellers at once',
      addSubscriptionTooltip_merchants: 'Configure subscriptions for multiple buyers at once',
      subscriptionNotAllowedForNonCimpressOwned: 'Only cimpress owned account user can subscribe as buyer',
      subscriptionAccordion: {
        notifiedOnAll_fulfillers: 'Notified on: <1>All Sellers</1>',
        notifiedOnAll_merchants: 'Notified on: <1>All Buyers</1>',
        notifiedOn_fulfillers: 'Notified on: <1>{{ count }} Seller</1>',
        notifiedOn_fulfillers_plural: 'Notified on: <1>{{ count }} Sellers</1>',
        notifiedOn_merchants: 'Notified on: <1>{{ count }} Buyer</1>',
        notifiedOn_merchants_plural: 'Notified on: <1>{{ count }} Buyers</1>',
        noActiveNotifications: 'No notifications active',
        noChangesWarning: 'You have not made any changes from your current configuration',
        noSelectionWarning_fulfillers: 'You have not selected any individual seller to subscribe to',
        noSelectionWarning_merchants: 'You have not selected any individual buyer to subscribe to'
      },
      actions: {
        subscribeToAll_sellers_contractNotification: 'Subscribe to all sellers',
        unsubscribeToAll_sellers_contractNotification: 'Unsubscribe from all sellers',
        subscribeToIndividual_sellers_contractNotification: 'Subscribe to individual sellers',
        subscribeToAll_buyers_contractNotification: 'Subscribe to all buyers',
        unsubscribeToAll_buyers_contractNotification: 'Unsubscribe from all buyers',
        subscribeToIndividual_buyers_contractNotification: 'Subscribe to individual buyers',

        subscribeToAll_fulfillers_contractNotification: 'Subscribe to all sellers',
        unsubscribeToAll_fulfillers_contractNotification: 'Unsubscribe from all sellers',
        subscribeToIndividual_fulfillers_contractNotification: 'Subscribe to individual sellers',
        subscribeToAll_merchants_contractNotification: 'Subscribe to all buyers',
        unsubscribeToAll_merchants_contractNotification: 'Unsubscribe from all buyers',
        subscribeToIndividual_merchants_contractNotification: 'Subscribe to individual buyers',
        subscribeToAll_fulfillers_productNotification: 'Subscribe to all products from all sellers',
        unsubscribeToAll_fulfillers_productNotification: 'Unsubscribe from all products from all sellers',
        subscribeToIndividual_fulfillers_productNotification: 'Subscribe to all products from individual sellers'
      },
      selectLabel_sellers: 'Select Seller',
      selectLabel_buyers: 'Select Buyer',
      selectLabel_fulfillers: 'Select Seller',
      selectLabel_merchants: 'Select Buyer',
      subscriptionModal: {
        selectIdentities_fulfillers: 'Select sellers to configure',
        selectIdentities_merchants: 'Select buyers to configure',
        selectAllIdentities_fulfillers: 'Select all sellers',
        selectAllIdentities_merchants: 'Select all buyers',
        firstStepModalTitle_fulfillers: 'Configure multiple of your sellers at once',
        firstStepModalTitle_merchants: 'Configure multiple of your buyers at once',
        secondStepModalTitle_fulfillers: 'Select subscription type of selected sellers',
        secondStepModalTitle_merchants: 'Select subscription type of selected buyers',
        infoMessage_fulfillers:
          'Your current subscriptions for individual sellers, if any, will be overridden by this change',
        infoMessage_merchants:
          'Your current subscriptions for individual buyers, if any, will be overridden by this change'
      }
    },
    autoApprovals: {
      header: 'Approvals',
      header2: 'Automatically approve contracts when',
      addButton: 'Add auto-approval',
      filterInputLabel: 'Filter by name',
      approvalNeeded: 'approval needed from',
      createdByLabel: 'created or modified by',
      fetchingMessage: 'Fetching auto approval settings...',
      select_fulfillers: 'Select Seller',
      select_fulfillers_plural: 'Select Seller(s)',
      select_merchants: 'Select Buyer',
      select_merchants_plural: 'Select Buyer(s)'
    },
    features: {
      header: 'Features',
      loading: {
        message: 'Fetching features...'
      },
      shippingContracts: {
        label: 'Enable Shipping Contracts'
      },
      terminatedContracts: {
        label: 'View Terminated Contracts'
      },
      languageSelection: {
        label: 'Language'
      }
    }
  },
  createRelationships: {
    title: 'Seller & Buyer',
    selectBuyer: 'Who is the buyer',
    selectSeller: 'Who is the Seller',
    createRelationTitle: 'Create Relationship',
    enterSunsetPeriod: 'Product Archiving',
    enterPriceLockin: 'Price Change',
    sunsetPeriodLabel: 'days',
    lockinPeriodLabel: 'days',
    createRelationButtonText: 'Create Relationship',
    buyerAndSellerAccountIdSameErrorMessage: 'Buyer and Seller can not be same in a relation.',
    agreementTitle: 'Your Agreements',
    agreementSubTitle: 'Products and Prices',
    agreementNegotiationTitle: 'How many days in advance you agree that you will notify the buyer for:',
    shippingAgreementTitle: 'Shipping',
    shippingAgreementOption1: 'Buyer pays the carrier directly',
    shippingAgreementOption2: 'Buyer pays the seller shipping costincurred during shipping as shipping price',
    shippingAgreementOption3: 'Buyer pays the seller a defined shipping price',
    shippingAgreementOption4: 'Free shipping',
    notificationTitle: 'Notification',
    notificationText: 'Let us know when the opposite changes the price or retires a product',
    notificationEmailText: 'Provide emails one per line or separated by comma',
    helptext: 'Provide emails one per line or separated by comma'
  },
  viewRelationships: {
    messages: {
      noVersionsFound: 'No version(s) present here.'
    }
  },
  createProductAgreements: {
    createProductAgreementsButtonText: 'Create'
  },
  productAgreementsPage: {
    mainHeading: '{{ businessOne }} and {{ businessTwo }}',
    productsHeading: 'Products',
    buyerSubHeading: 'Buying from {{ businessTwo }}',
    sellerSubHeading: 'Selling to {{ businessTwo }}',
    terms: 'Terms',
    productId: 'Product Id',
    priceLockinSectionOne: '{{ seller }} agrees to give {{ buyer }} atleast ',
    priceLockinSectionTwo: ' days prior notice before changing price',
    sunsetSectionOne: '{{ seller }} agrees to give {{ buyer }} atleast ',
    sunsetSectionTwo: ' days prior notice before archiving a product that {{ buyer }} is buying.',
    noRelationshipAsBuyer:
      '{{ businessOne }} has no relationship established to buy from {{ businessTwo }}. Please contact {{ businessTwo }} to set this up.',
    noRelationshipAsSeller: '{{ businessOne }} has no relationship established to sell to {{ businessTwo }}.',
    save: 'Save the changes',
    edit: 'Edit the value',
    cancel: 'Cancel editing',
    deletePriceLock: 'Delete price lock',
    deletePriceLockConfirmation: 'Are you sure you want to delete the price lock for {{ productId }}?',
    addProducts: 'Add products',
    search: 'Search',
    selectVersions: 'Select versions',
    view: 'View',
    columnNames: {
      productId: 'PRODUCT ID',
      productName: 'PRODUCT NAME',
      versions: 'VERSIONS',
      view: 'VIEW',
      priceLock: 'PRICE LOCK UNTIL'
    },
    versionDeleteConfirmation1: 'Do you want to remove the version {{ versions }}?',
    versionDeleteConfirmation2: 'This means: ',
    versionDeleteConfirmation3: ' You are not buying this version anymore.',
    versionDeleteConfirmation4: ' You are OK not to be notified when the version is archived.',
    priceChangeTitle: 'Price Change',
    productArchivingTitle: 'Product Archiving',
    shippingTitle: 'Shipping',
    contactForShippingAgreement: `Please contact {{ contactEmailAddress }} if you need assistance.`,
    productInformation: 'Product Information',
    buyerNotCimpressOwnedMessage: `{{accountName}} is not enabled as Buyer in the Platform!`,
    buyerNotCimpressOwnedMessageContactUs: `Please Contact Platform Support if {{accountName}} is buying in Platform.`
  },
  notificationDetails: {
    acknowledge: 'Acknowledge',
    contactSeller: 'Please contact the seller if you have any questions: Contact',
    isArchiving: 'is archiving',
    startingFrom: 'Starting from',
    noLongerAvailable: 'you are buying will no longer be available.',
    decidedToArchive: 'has decided to archive',
    unavailableToBuyFrom: 'and will no longer be available for you to buy starting from',
    nonRevocableAction: 'This is non-revocable action from',
    ensureChangeBefore: 'hence please ensure that you have made required changes, before',
    noDisruption: 'so that there is no disruption to your end-customers.',
    acknowledgedMessage: 'acknowledged this message on',
    for: 'for',
    ofThe: 'of',
    theVersion: 'the version',
    notificationAlreadyAcknowledged: 'Notification was already acknowledged by',
    priceUpdateEffectiveOn: 'Price Update is effective from',
    isUpdatingPricing: 'is updating pricing for',
    products: 'products',
    priceChangeAutomaticApply: `This price change will automatically come into effect on {{ date }}. Please contact the seller if you have any questions.`,
    asPerYourAgreement: `As per your agreement with {{ sellerName }} new pricing will apply for the below listed product(s) from {{ date }}, after mandatory {{ priceUpdateDays }} day(s) notification period.`,
    acknowledgementMessage: `{{ acknowledgedBy }} acknowledged this message on {{ acknowledgedAt }} for {{ buyerName }}`,
    oldPrices: 'Old prices',
    newPrices: 'New prices',
    versionDifferenceHeading: `Difference between sunset version {{ sunsetVersion }} and current/latest version`,
    versionDifferenceNote: `(changes will be highlighted in blue)`
  },
  notifications: {
    header: 'Notifications',
    searchByProductId: 'Product Id',
    startDate: 'Start Date',
    endDate: 'End Date',
    all: 'All',
    productArchiving: 'Product Archiving',
    priceChange: 'Price Change',
    today: 'Today',
    others: 'Others',
    acknowledgedBy: 'Acknowledged By:  ',
    fetchingMessage: 'Fetching your notifications...',
    notificationsNotAvailable: 'No notifications available',
    notificationItems: {
      PRICECHANGE: 'New price for ',
      SUNSET: 'Archiving a version of '
    },
    notificationMessage: {
      PRICECHANGE: 'From {} there will be new price for {}',
      SUNSET: 'Starting at {} the version of {} you are buying will become unavailable'
    }
  },
  errorRobot: {
    error: 'Please refresh the page to retry. If the problem persists, please contact'
  }
};
