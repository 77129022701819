import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Table, Select } from '@cimpress/react-components';
import TermStatus from '../../constants/termStatus';
import 'react-dropdown/style.css';
import { useTranslation } from 'react-i18next';
import AgreementTypeLabel from '../commonComponents/AgreementTypeLabel';
import StatusCell from '../../components/home/contractTable/StatusCell';
import DateTimeCell from '../../components/home/contractTable/DateTimeCell';
import ActionsCell from '../../components/home/contractTable/ActionsCell';
import { actions as termsContractV1Actions, selectors as termSelectors } from '../../reducers/contractV1/terms';
import { selectors as contractV1Selectors } from '../../reducers/contractV1';
import '../../styles/ViewTermsPage.css';
import {
  ApplyFilter,
  SELECT_ALL,
  getContractTypeOptions,
  getContractStatusTypeOptions,
  sortCaseInsensitive,
  getUniqueSellers,
  getUniqueBuyers
} from './PriceTermTableUtil';
import cloneDeep from 'lodash/cloneDeep';

function PricingTermTable(props) {
  const [priceTermsLocal, setPriceTermsLocal] = useState(null);
  const [filteredPriceTerms, setFilteredPriceTerms] = useState(null);
  const [uniqueFilterValues, setUniqueFilterValues] = useState({});
  const [isLoading, setIsLoading] = useState();
  const [searchCriteria, setSearchCriteria] = useState(null);

  useEffect(
    () => {
      if (props.isContractCreated) {
        props.fetchTermsForHomePage();
      }
    },
    [props.isContractCreated]
  );

  //following function being called on the price term changes.
  useEffect(() => load(), [
    props.pricingTerms,
    props.isPageLoading,
    props.fulfillers,
    props.merchants,
    props.sellerAccountId
  ]);

  //following function being called on the tab change
  useEffect(
    () => {
      setSearchCriteria(null);
      // No need to retain search criteria: because fulfiller in one tab - becomes merchant in another tab.
    },
    [props.sellerAccountId]
  );

  const load = () => {
    if (!props.isPageLoading && props.fulfillers && props.merchants) {
      setFilteredPriceTerms(null);
      const fulfillersBySellerAccountId = props.fulfillers.filter(each => each.accountId === props.sellerAccountId);
      const merchantsByBuyerAccountId = props.merchants.filter(each => each.accountId === props.buyerAccountId);
      const filteredFulfillers = props.pricingTerms.filter(term =>
        fulfillersBySellerAccountId.some(seller => seller.id.toLowerCase() === term.seller.id.toLowerCase())
      );
      const result = filteredFulfillers.filter(term =>
        merchantsByBuyerAccountId.some(merchant => merchant.id.toLowerCase() === term.buyer.id.toLowerCase())
      );
      setPriceTermsLocal(result);
      setIsLoading(props.isPageLoading);
      props.onContractsLoaded();
    }
  };

  useEffect(
    () => {
      if (!filteredPriceTerms) {
        return;
      }

      const sellers = getUniqueSellers(filteredPriceTerms);
      const buyers = getUniqueBuyers(filteredPriceTerms);
      const contractTypes = getContractTypeOptions();
      const statusTypes = getContractStatusTypeOptions(t);

      sellers.splice(0, 0, SELECT_ALL);
      buyers.splice(0, 0, SELECT_ALL);
      contractTypes.splice(0, 0, SELECT_ALL);
      statusTypes.splice(0, 0, SELECT_ALL);
      setUniqueFilterValues({ sellers, buyers, contractTypes, statusTypes });
    },
    [filteredPriceTerms]
  );

  const onChange = (value, accessor) => {
    setSearchCriteria({ ...searchCriteria, [accessor]: value });
  };

  useEffect(
    () => {
      setIsLoading(true);
      filterValues();
      setIsLoading(false);
    },
    [searchCriteria, priceTermsLocal]
  );

  const SelectorHeaderLatest = (distinctValues, accessor, headerName) => {
    const value = searchCriteria ? searchCriteria[accessor] : null;
    return (
      <div>
        <span>{headerName}</span>
        <Select
          isClearable={false}
          options={distinctValues}
          value={value ? value : SELECT_ALL}
          onChange={event => {
            onChange(event, accessor);
          }}
        />
      </div>
    );
  };

  const filterValues = async () => {
    if (searchCriteria) {
      const findingFiltered = [];
      const clondedPriceTermsLocal = cloneDeep(priceTermsLocal);
      clondedPriceTermsLocal.forEach(each => {
        each._visible = true;
        each._visible = ApplyFilter(searchCriteria, each);
        if (each._visible) {
          findingFiltered.push(each);
        }
      });
      setFilteredPriceTerms(findingFiltered);
    } else {
      setFilteredPriceTerms(priceTermsLocal);
    }
  };

  const updateStatus = val => {
    if (val) {
      setIsLoading(true);
      // Initiated the status change - Nothing to do
    } else {
      // Assuming status change is completed
      props.fetchTermsForHomePage();
    }
  };

  const getReference = () => `${props.businessIds}&&!!${props.currentTabIndex}`;

  const { t } = useTranslation();
  const columns = [
    {
      Header: 'Name',
      accessor: 'additionalData.name',
      Cell: row => {
        return (
          <a
            href={`${process.env.REACT_APP_CONTRACT_MANAGER_V1_URL}/${row.original.termId}/${
              row.original.revisionId
            }/${getReference()}`}>
            {row.original.additionalData.name}
          </a>
        );
      },
      onSort: sortCaseInsensitive
    },
    {
      Header: SelectorHeaderLatest(uniqueFilterValues.contractTypes, 'type', 'Contract Type'),
      accessor: 'type',
      Cell: row => {
        return <AgreementTypeLabel t={t} term={row.original} />;
      },
      sortable: false
    },
    {
      id: 'seller',
      Header: SelectorHeaderLatest(uniqueFilterValues.sellers, 'seller', 'Seller'),
      Cell: row => {
        return <span>{row.original.seller.name}</span>;
      },
      sortable: false
    },
    {
      id: 'buyer',
      Header: SelectorHeaderLatest(uniqueFilterValues.buyers, 'buyer', 'Buyer'),
      Cell: row => {
        return <span>{row.original.buyer.name}</span>;
      },
      sortable: false
    },
    {
      id: 'status',
      Header: SelectorHeaderLatest(uniqueFilterValues.statusTypes, 'status', 'Status'),
      accessor: t => t.status,
      Cell: props => (
        <div>
          <StatusCell term={props.original} />
          {props.original.previousRevision &&
            (props.original.status === TermStatus.PENDING ||
              (props.original.isEffective && props.original.status === TermStatus.REJECTED))}
        </div>
      ),
      sortable: false
    },
    {
      id: 'createdAt',
      Header: t('common:commonTerminology.lastUpdated'),
      accessor: 'createdAt',
      Cell: props => <DateTimeCell timestamp={props.value} />,
      onSort: sortCaseInsensitive
    },
    {
      id: 'actions',
      Header: t('common:commonTerminology.actions'),
      Cell: props => <ActionsCell term={props.original} businessIds={getReference()} updateStatus={updateStatus} />,
      onSort: sortCaseInsensitive
    },
    // hidden field to use just for filtering purpose
    {
      accessor: 'isEffective',
      show: false
    }
  ];
  return (
    <div>
      <div>
        <Table
          columns={columns}
          className={'pricingTermTable'}
          data={filteredPriceTerms ? filteredPriceTerms : []}
          loading={props.isPageLoading || isLoading}
          showPagination={true}
          defaultSorted={[
            {
              id: 'isEffective',
              desc: true
            },
            {
              id: 'createdAt',
              desc: true
            }
          ]}
        />
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  pricingTerms: termSelectors.getFilteredTermsForHomePage(state),
  allBuyers: termSelectors.getAllContractBuyers(state),
  isPageLoading: termSelectors.getIsTermsLoading(state),
  fulfillers: contractV1Selectors.getFulfillerIdentityData(state, 'filter'),
  merchants: contractV1Selectors.getMerchantConfigurationData(state, 'filter')
});
const mapDispatchToProps = {
  fetchTermsForHomePage: termsContractV1Actions.fetchTermsForHomePageV2
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PricingTermTable);
