import SettingsModal from '@cimpress-technology/react-platform-settings';
import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { actions as configActions, selectors as configSelectors } from '../../reducers/configurations';
import { auth } from '../../utils/auth';
import { withTranslation } from 'react-i18next';
import { SupportedLanguages } from '../../constants/appConstants';

export function LayoutSettings({
  userConfigSettings,
  updateUserConfig,
  fetchUserConfig,
  isFetchingUserConfigurations,
  i18n,
  t
}) {
  useEffect(() => {
    fetchUserConfig();
  }, []);

  const onSave = useCallback(async () => {
    await fetchUserConfig();
  });

  return (
    <SettingsModal
      authToken={auth.getAccessToken()}
      lang={i18n.language}
      supportedLanguages={SupportedLanguages}
      onSave={onSave}
    />
  );
}

const mapStateToProps = state => {
  return {
    userConfigSettings: configSelectors.getUserConfigSettings(state),
    isFetchingUserConfigurations: configSelectors.getIsFetchingUserConfigurations(state)
  };
};

const mapDispatchToProps = {
  updateUserConfig: configActions.updateUserConfig,
  fetchUserConfig: configActions.fetchUserConfig
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(LayoutSettings));
