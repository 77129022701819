import get from 'lodash/get';

export const createSearchFilters = (headers = []) => {
  const customHeaders = headers.map(header => {
    return (searchQuery, data) => {
      const keyValue = get(data, header);
      if (keyValue) {
        if (Array.isArray(keyValue)) {
          return keyValue.includes(searchQuery);
        }
        return keyValue.toLowerCase().includes(searchQuery.toLowerCase());
      }
      return false;
    };
  });

  return (searchQuery, data) => {
    if (!data) return data;
    return data.filter(item => {
      return customHeaders.reduce((passed, header) => {
        if (passed) return passed;
        return header(searchQuery, item);
      }, false);
    });
  };
};
