import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import OptionCard from '../shared/OptionCard/OptionCard';
import { TermTypeLabel, createFlowTermTypeHasModifier, getTermModifierType } from '../../../../constants/termTypes';
import moment from 'moment';
import BasicTermInfoForm from '../shared/BasicTermInfoForm';
import ModifiersInfoForm from '../shared/ModifiersInfoForm';
import SpecialPriceTermInfoForm from './SpecialPriceTermInfoForm';
import { DISCOUNT, MARKUP } from '../../../../constants/modifierType';
import isEmpty from 'lodash/isEmpty';

const emptyPricingTerm = {
  termType: undefined,
  // get the current date and change the timezone to UTC without actually doing a real conversion of time
  // and then just reset it to midnight so the end result should be the user's day in UTC midnight
  startDate: moment()
    .utcOffset(0)
    .startOf('day'),
  endDate: undefined,
  noEndDateChecked: true,
  termName: undefined,
  comment: undefined,
  modifierAmount: undefined,
  priceModel: {},
  hasOnlyOnePriceModel: false,
  priceModelSelected: false
};

class PricingTermCard extends PureComponent {
  static defaultProps = {
    isEditing: false
  };

  static propTypes = {
    onCreateTerm: PropTypes.func.isRequired,
    validateForm: PropTypes.func.isRequired,
    productPricingTerm: PropTypes.object,
    isEditing: PropTypes.bool
  };

  constructor(props) {
    super(props);

    this.state = isEmpty(props.productPricingTerm)
      ? emptyPricingTerm
      : {
          ...props.productPricingTerm
        };
  }

  componentDidMount() {
    const { validateForm, checkTypeSelected } = this.props;

    const termSelected = this.state.termType !== undefined;
    checkTypeSelected(termSelected);

    termSelected && validateForm(this.hasValidationErrors());
  }

  componentDidUpdate(prevProps, prevState) {
    // if user selected a term type for the first time
    if (prevState.termType === undefined && this.state.termType !== undefined) {
      this.props.checkTypeSelected(true);
    }
  }

  getTermInfo = () => {
    const { onCreateTerm, validateForm, checkTypeSelected } = this.props;

    const {
      termType,
      startDate,
      endDate,
      noEndDateChecked,
      termName,
      comment,
      modifierAmount,
      priceModel,
      hasOnlyOnePriceModel,
      priceModelSelected
    } = this.state;

    if (!termType) {
      checkTypeSelected(false);
      validateForm(false);
    } else {
      checkTypeSelected(true);
      validateForm(this.hasValidationErrors());
    }

    const termObject = !termType
      ? emptyPricingTerm
      : {
          termType,
          startDate,
          endDate,
          noEndDateChecked,
          termName,
          comment,
          modifierAmount,
          priceModel,
          hasOnlyOnePriceModel,
          priceModelSelected
        };

    onCreateTerm(termObject);
  };

  onChangeTermType = selectedTermType => {
    this.setState({ termType: selectedTermType ? selectedTermType.value : null }, () => this.getTermInfo());
  };

  onChangeEffectiveStartDate = startDate => {
    this.setState({ startDate }, () => this.getTermInfo());
  };

  onChangeEffectiveEndDate = endDate => {
    this.setState({ endDate }, () => this.getTermInfo());
  };

  onToggleNoEndDateChecked = () => {
    this.setState({ noEndDateChecked: !this.state.noEndDateChecked }, () => this.getTermInfo());
  };

  onChangeTermName = termName => {
    this.setState({ termName: termName && termName.target.value }, () => this.getTermInfo());
  };

  onChangeComment = comment => {
    this.setState({ comment: comment && comment.target.value }, () => this.getTermInfo());
  };

  onChangeModifierAmount = modifierAmount => {
    this.setState({ modifierAmount }, () => this.getTermInfo());
  };

  onSelectPriceModel = (id, revisionId, name) => {
    const priceModelSelected = this.isPriceModelSelected(id, revisionId, name);
    const priceModel = priceModelSelected ? { id, revisionId, name } : {};
    this.setState({ priceModel, priceModelSelected }, () => this.getTermInfo());
  };

  onChangePriceModel = () => {
    this.setState({ priceModelSelected: !this.state.priceModelSelected });
  };

  onOnlyOnePriceModelAvailable = (id, revisionId, name) => {
    const priceModelSelected = this.isPriceModelSelected(id, revisionId, name);
    const priceModel = priceModelSelected ? { id, revisionId, name } : {};
    const currentPriceModel = this.state.priceModel;

    // NOTE: when we set price model, we re-render the revision selector, where getAllRevisions
    // gets called, which then calls this function, which again sets the price model,
    // resulting in an infinite loop.
    // therefore, only set price model when newly selecting a price model or re-selecting
    // a different price model to prevent the revision selector from becoming rendered infinitely
    if (isEmpty(currentPriceModel) || currentPriceModel.id !== id) {
      this.setState({ priceModel, priceModelSelected, hasOnlyOnePriceModel: true }, () => this.getTermInfo());
    }
  };

  isPriceModelSelected = (id, revisionId, name) => {
    return (
      id !== null &&
      id !== undefined &&
      revisionId !== null &&
      revisionId !== undefined &&
      name !== null &&
      name !== undefined
    );
  };

  getTermNameValidationError = termName => {
    let error;

    if (!termName || termName.trim() === '') {
      error = this.props.t('createContract:messages.contractNameMissingMessage');
    }

    return error;
  };

  getModifierValidationError = (modifierType, modifierAmount) => {
    let error;

    const modifierAmountSet = modifierAmount && modifierAmount !== '';

    const modifierNumber = parseFloat(modifierAmount);

    // amount must be greater than 0 for both discount and markup
    // if the modifier type is a discount, the amount must be less than 100
    const isValidAmount = modifierNumber > 0 && (modifierType === MARKUP || modifierNumber < 100);

    const { t } = this.props;

    if (!modifierAmountSet) {
      error = t('createContract:messages.modifierEmptyMessage');
    }

    if (!isValidAmount) {
      error =
        modifierType === MARKUP
          ? t('createContract:messages.markupPercentageNotGreaterThan0Message')
          : modifierType === DISCOUNT
            ? t('createContract:messages.discountPercentageMustBeBetween0And100')
            : t('createContract:messages.modifierAmountNotValidMessage');
    }

    return error;
  };

  getEndDateValidationError = (endDate, noEndDateChecked) => {
    let error;

    if (!noEndDateChecked && !endDate) {
      error = this.props.t('createContract:messages.endDateMissingMessage');
    }

    return error;
  };

  getValidationErrorFns = {
    modifier: this.getModifierValidationError,
    termName: this.getTermNameValidationError,
    endDate: this.getEndDateValidationError
  };

  hasValidationErrors = () => {
    const { modifierAmount, termName, termType, noEndDateChecked, endDate, priceModel } = this.state;

    const specialPriceHasNoPriceModel =
      isEmpty(priceModel) &&
      (termType === TermTypeLabel.SPECIAL_PRICE || termType === TermTypeLabel.SPECIAL_PRICE_MARKUP);
    const hasTermNameErrors = this.getValidationErrorFns.termName(termName) !== undefined;
    const hasModifierErrors =
      createFlowTermTypeHasModifier(termType) &&
      this.getValidationErrorFns.modifier(getTermModifierType(termType), modifierAmount) !== undefined;
    const hasEndDateErrors = this.getValidationErrorFns.endDate(endDate, noEndDateChecked) !== undefined;

    return specialPriceHasNoPriceModel || hasTermNameErrors || hasModifierErrors || hasEndDateErrors;
  };

  render() {
    const { t, className, flow, ...rest } = this.props;
    const {
      priceModel,
      termType,
      startDate,
      endDate,
      noEndDateChecked,
      termName,
      comment,
      modifierAmount,
      priceModelSelected,
      hasOnlyOnePriceModel
    } = this.state;
    const termPricingTypeOptionsMap = {};

    termPricingTypeOptionsMap[TermTypeLabel.LIST_PRICE] = () => (
      <BasicTermInfoForm
        onChangeStartDate={this.onChangeEffectiveStartDate}
        startDate={startDate}
        onChangeEndDate={this.onChangeEffectiveEndDate}
        endDate={endDate}
        onToggleNoEndDateChecked={this.onToggleNoEndDateChecked}
        noEndDateChecked={noEndDateChecked}
        onChangeTermName={this.onChangeTermName}
        termName={termName}
        onChangeComment={this.onChangeComment}
        comment={comment}
        getValidationErrorFns={this.getValidationErrorFns}
      />
    );

    termPricingTypeOptionsMap[TermTypeLabel.LIST_PRICE_DISCOUNT] = () => (
      <ModifiersInfoForm
        onChangeStartDate={this.onChangeEffectiveStartDate}
        startDate={startDate}
        onChangeEndDate={this.onChangeEffectiveEndDate}
        endDate={endDate}
        onToggleNoEndDateChecked={this.onToggleNoEndDateChecked}
        noEndDateChecked={noEndDateChecked}
        onChangeTermName={this.onChangeTermName}
        termName={termName}
        onChangeComment={this.onChangeComment}
        comment={comment}
        onChangeModifierAmount={this.onChangeModifierAmount}
        modifierAmount={modifierAmount}
        getValidationErrorFns={this.getValidationErrorFns}
        modifierType={DISCOUNT}
      />
    );

    termPricingTypeOptionsMap[TermTypeLabel.LIST_PRICE_MARKUP] = () => (
      <ModifiersInfoForm
        onChangeStartDate={this.onChangeEffectiveStartDate}
        startDate={startDate}
        onChangeEndDate={this.onChangeEffectiveEndDate}
        endDate={endDate}
        onToggleNoEndDateChecked={this.onToggleNoEndDateChecked}
        noEndDateChecked={noEndDateChecked}
        onChangeTermName={this.onChangeTermName}
        termName={termName}
        onChangeComment={this.onChangeComment}
        comment={comment}
        onChangeModifierAmount={this.onChangeModifierAmount}
        modifierAmount={modifierAmount}
        getValidationErrorFns={this.getValidationErrorFns}
        modifierType={MARKUP}
      />
    );

    termPricingTypeOptionsMap[TermTypeLabel.SPECIAL_PRICE] = () => (
      <SpecialPriceTermInfoForm
        onChangeStartDate={this.onChangeEffectiveStartDate}
        startDate={startDate}
        onChangeEndDate={this.onChangeEffectiveEndDate}
        endDate={endDate}
        onToggleNoEndDateChecked={this.onToggleNoEndDateChecked}
        noEndDateChecked={noEndDateChecked}
        onChangeTermName={this.onChangeTermName}
        termName={termName}
        onChangeComment={this.onChangeComment}
        comment={comment}
        priceModelSelected={priceModelSelected}
        onSelectPriceModel={this.onSelectPriceModel}
        priceModel={priceModel ? priceModel : {}}
        getValidationErrorFns={this.getValidationErrorFns}
        onChangePriceModel={this.onChangePriceModel}
        hasOnlyOnePriceModel={hasOnlyOnePriceModel}
        onOnlyOnePriceModelAvailable={this.onOnlyOnePriceModelAvailable}
        skus={Object.keys(flow.selectedSkus)}
        sellerId={flow.initiator.id}
        sellerType={flow.initiator.type}
      />
    );

    termPricingTypeOptionsMap[TermTypeLabel.SPECIAL_PRICE_MARKUP] = () => (
      <SpecialPriceTermInfoForm
        onChangeStartDate={this.onChangeEffectiveStartDate}
        startDate={startDate}
        onChangeEndDate={this.onChangeEffectiveEndDate}
        endDate={endDate}
        onToggleNoEndDateChecked={this.onToggleNoEndDateChecked}
        noEndDateChecked={noEndDateChecked}
        onChangeTermName={this.onChangeTermName}
        termName={termName}
        onChangeComment={this.onChangeComment}
        comment={comment}
        priceModelSelected={priceModelSelected}
        onSelectPriceModel={this.onSelectPriceModel}
        priceModel={priceModel ? priceModel : {}}
        onChangePriceModel={this.onChangePriceModel}
        hasOnlyOnePriceModel={hasOnlyOnePriceModel}
        onOnlyOnePriceModelAvailable={this.onOnlyOnePriceModelAvailable}
        skus={Object.keys(flow.selectedSkus)}
        sellerId={flow.initiator.id}
        sellerType={flow.initiator.type}
        onChangeModifierAmount={this.onChangeModifierAmount}
        modifierAmount={modifierAmount}
        getValidationErrorFns={this.getValidationErrorFns}
        modifierType={MARKUP}
      />
    );

    const termPricingTypeLabels = [TermTypeLabel.LIST_PRICE, TermTypeLabel.SPECIAL_PRICE].map(type => ({
      value: type,
      label: t(type)
    }));

    return (
      <OptionCard
        className={className}
        options={termPricingTypeLabels}
        optionsLabel={t('common:commonTerminology.contractType')}
        optionsActionMap={termPricingTypeOptionsMap}
        selectedOption={termType}
        onChangeOption={this.onChangeTermType}
        {...rest}
      />
    );
  }
}

export default withTranslation()(PricingTermCard);
