import { AVAILABLE, UNAVAILABLE, LOADING } from '../../constants/entityStatus';
import * as subscriptionsApi from '../../services/Subscriptions';
import { createReducer } from '../utils';
import { parseSubscriptions } from './utils';
import { createSelector } from 'reselect';
import { SELLERS, BUYERS } from '../../constants/transactorType';

const FETCH_ALL_SUBSCRIPTIONS_REQUEST = 'FETCH_ALL_SUBSCRIPTIONS_REQUEST';
const FETCH_ALL_SUBSCRIPTIONS_FAILURE = 'FETCH_ALL_SUBSCRIPTIONS_FAILURE';
const FETCH_ALL_SUBSCRIPTIONS_SUCCESS = 'FETCH_ALL_SUBSCRIPTIONS_SUCCESS';

const fetchSubscriptionHooks = {
  onFailure: 'Failed to fetch subscriptions.'
};

// Action
export const fetchAllSubscriptions = resourceType => {
  return {
    types: [FETCH_ALL_SUBSCRIPTIONS_REQUEST, FETCH_ALL_SUBSCRIPTIONS_SUCCESS, FETCH_ALL_SUBSCRIPTIONS_FAILURE],
    callAPI: async dispatch => {
      const { subscriptions } = await subscriptionsApi.fetchAllSubscriptions(resourceType);
      return parseSubscriptions(subscriptions);
    },
    hooks: fetchSubscriptionHooks
  };
};

// reducer
export default createReducer(
  {},
  {
    [FETCH_ALL_SUBSCRIPTIONS_REQUEST]: (state, action) => {
      return { ...state, status: LOADING };
    },
    [FETCH_ALL_SUBSCRIPTIONS_SUCCESS]: (state, action) => {
      const { payload } = action;
      return { ...state, ...payload, status: AVAILABLE };
    },
    [FETCH_ALL_SUBSCRIPTIONS_FAILURE]: (state, action) => {
      return { ...state, status: UNAVAILABLE };
    }
  }
);

// Selector
export const getAllSubscriptions = state => {
  return state.subscriptions.subscriptions;
};

export const getBuyersSubscriptions = state => {
  return state.subscriptions.subscriptions.buyers;
};
export const getSellersSubscriptions = state => {
  return state.subscriptions.subscriptions.sellers;
};

export const getAvailableSubscriptions = createSelector(
  getSellersSubscriptions,
  getBuyersSubscriptions,
  (state, identityType) => ({ identityType }),
  (sellersSubscriptions, buyersSubscriptions, { identityType }) => {
    switch (identityType) {
      case SELLERS:
        return sellersSubscriptions;
      case BUYERS:
        return buyersSubscriptions;
      default:
        return [];
    }
  }
);

export const getAllSubscriptionsStatus = state => {
  return state.subscriptions.subscriptions.status;
};
