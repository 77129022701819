import filters, { applyFilters, clearFilters, getFilterLogicMapping, getAppliedFilters } from './filters';
import { combineReducers } from 'redux';

// actions
export const actions = {
  applyFilters,
  clearFilters
};

// reducers
export default combineReducers({
  filters
});

// selectors
export const selectors = {
  getAppliedFilters,
  getFilterLogicMapping
};
