import React, { useState, useEffect } from 'react';
import { Button } from '@cimpress/react-components';
import { connect } from 'react-redux';
import Loading from '../../Loading';
import { useTranslation } from 'react-i18next';
import { toMonthDayYearDisplay, toDateTimeString } from '../../../utilities/dateHelper';
import { selectors as identitySelectors } from '../../../reducers/identities/index';
import '../../../styles/NotificationDetailsPage.css';
import ItemPrices from './ItemPrices';
import { isManageContractPermission } from '../../../utilities/checkPermission';

function PriceChangeNotificationDetails(props) {
  const { t } = useTranslation();
  const [doesUserHasManageContractPermission, setManageContractPermission] = useState(false);
  const [products, setProducts] = useState([]);
  const { notificationDetails, isLoading, onAcknowledge, isAdmin, manageAccountId } = props;
  const onAcknowledgePriceChangeNotification = async () => await onAcknowledge();

  useEffect(() => {
    var isManageContractPermissionForUser = isManageContractPermission(manageAccountId, props.userPermissions);
    setManageContractPermission(isManageContractPermissionForUser);
    let productsToSet = [];
    notificationDetails.priceChange.products.forEach(eachProductDetail => {
      if (eachProductDetail.state !== 'cancelled') {
        productsToSet.push(eachProductDetail);
      }
    });
    setProducts(productsToSet);
  }, []);

  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="notificationDetailBackground">
          <div className="row">
            <div className="col-md-10">
              <h2>
                <b>
                  {t('notificationDetails:priceUpdateEffectiveOn')}{' '}
                  {toMonthDayYearDisplay(notificationDetails.priceChange.effectiveDate)}.&nbsp;
                  {notificationDetails.sellerName} {t('notificationDetails:isUpdatingPricing')}&nbsp;
                  {products.length > 1 ? products.length + ' ' + t('notificationDetails:products') : products[0].id}
                </b>
              </h2>
            </div>
            <div className="col-md-2">
              <div
                className="row acknowledge-button-style-notification-details"
                style={
                  (isAdmin || doesUserHasManageContractPermission) &&
                  !notificationDetails.isAck &&
                  notificationDetails.priceChange.reminderConfig
                    ? { display: 'block', paddingLeft: '30px', paddingTop: '15px' }
                    : { display: 'none' }
                }>
                <Button type="primary" onClick={onAcknowledgePriceChangeNotification}>
                  {t('notificationDetails:acknowledge')}
                </Button>
              </div>
            </div>
          </div>
          <br />
          <div>
            {t('notificationDetails:asPerYourAgreement', {
              sellerName: notificationDetails.sellerName,
              date: toMonthDayYearDisplay(notificationDetails.priceChange.effectiveDate),
              priceUpdateDays: notificationDetails.priceChange.priceChangeNotificationDuration
            })}
          </div>
          <div>
            {t('notificationDetails:priceChangeAutomaticApply', {
              date: toMonthDayYearDisplay(notificationDetails.priceChange.effectiveDate)
            })}
          </div>
          <br />
          <div>
            {notificationDetails.isAck && notificationDetails.priceChange.reminderConfig
              ? t('notificationDetails:acknowledgementMessage', {
                  buyerName: notificationDetails.buyerName,
                  acknowledgedAt: toDateTimeString(notificationDetails.acknowledgedAt),
                  acknowledgedBy: notificationDetails.acknowledgedBy
                })
              : ''}
          </div>
          <br />
          <br />
          <h5>Price changes</h5>
          <hr className="hrNotificationDetailPriceChange" />
          <div className="row">
            <ItemPrices notificationDetails={notificationDetails} />
          </div>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = state => ({
  isAdmin: identitySelectors.isUserAdmin(state),
  userPermissions: identitySelectors.getPermissionsPayload(state)
});

export default connect(mapStateToProps)(PriceChangeNotificationDetails);
