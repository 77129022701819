import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router-dom';

import { propsAreSame } from '../diffCheck';

import BreadcrumbsContext from './BreadcrumbsContext';

export class BreadcrumbsEntry extends React.Component {
  state = {
    path: [],
    prevProps: { match: {} }
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const prevProps = prevState.prevProps || {};

    const urlChanged = nextProps.match.url !== prevProps.match.url;

    // If any of the props are different, set the path
    if (!propsAreSame(prevProps, nextProps, ['href', 'path', 'title']) && urlChanged) {
      const { path, title } = nextProps;

      const href = nextProps.match.url;
      const newPath = [...path, { href, title }];

      return { path: newPath, prevProps: nextProps };
    }

    // If none of the props changed, don't update anything.
    return prevState;
  }

  render() {
    const { children } = this.props;
    const { path } = this.state;

    return <BreadcrumbsContext.Provider value={path}>{children}</BreadcrumbsContext.Provider>;
  }
}

BreadcrumbsEntry.propTypes = {
  children: PropTypes.node,
  match: PropTypes.shape({
    url: PropTypes.string.isRequired
  }).isRequired,
  path: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.object.isRequired,
      href: PropTypes.string.isRequired
    }).isRequired
  ).isRequired,
  title: PropTypes.object.isRequired
};

// This component needs to exist because BreadcrumbsEntry needs access to path as either props or
//   state, but won't have access to it if it exists on the context.
export class BreadcrumbsEntryWrapper extends React.Component {
  renderInner = path => {
    return <BreadcrumbsEntry path={path} {...this.props} />;
  };

  render() {
    return <BreadcrumbsContext.Consumer>{this.renderInner}</BreadcrumbsContext.Consumer>;
  }
}

export default withRouter(BreadcrumbsEntryWrapper);
