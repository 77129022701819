import map from 'lodash/map';
import keys from 'lodash/keys';
import filter from 'lodash/filter';
import sortBy from 'lodash/sortBy';
import i18n from 'i18next';
import isoCountries from 'i18n-iso-countries';
import isoCountriesEn from 'i18n-iso-countries/langs/en.json';
import isoCountriesFr from 'i18n-iso-countries/langs/fr.json';
import isoCountriesIt from 'i18n-iso-countries/langs/it.json';
import isoCountriesDe from 'i18n-iso-countries/langs/de.json';
import isoCountriesNl from 'i18n-iso-countries/langs/nl.json';

isoCountries.registerLocale(isoCountriesEn);
isoCountries.registerLocale(isoCountriesFr);
isoCountries.registerLocale(isoCountriesIt);
isoCountries.registerLocale(isoCountriesDe);
isoCountries.registerLocale(isoCountriesNl);

export function getAllCountries() {
  return sortBy(
    map(isoCountries.getNames(i18n.language), (country, countryCode) => ({
      label: country,
      value: countryCode
    })),
    'label'
  );
}

export function getAllCountryCodes() {
  return keys(isoCountries.getNames(i18n.language));
}

export function getExcludedCountries(includedCountryCodes) {
  const allCountryCodes = getAllCountryCodes();

  return filter(allCountryCodes, countryCode => {
    return !includedCountryCodes.includes(countryCode);
  });
}

export function filterOutExcludedCountries(excludedCountryCodes) {
  const allCountryCodes = getAllCountryCodes();

  return filter(allCountryCodes, countryCode => {
    return !excludedCountryCodes.includes(countryCode);
  });
}

export function getCountryName(countryCode) {
  return isoCountries.getName(countryCode, i18n.language);
}
