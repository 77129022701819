import React from 'react';
import { VariableSizeList as List } from 'react-window';

const ITEM_HEIGHT = 45;

function MenuList({ ...props }) {
  const children = React.Children.toArray(props.children);

  function getItemSize() {
    return ITEM_HEIGHT;
  }

  const totalHeight = ITEM_HEIGHT * children.length;

  return (
    <List
      height={Math.min(totalHeight, 200)}
      itemCount={children.length}
      itemSize={getItemSize}
      initialScrollOffset={0}>
      {({ index, style }) => (
        <div className="option-wrapper" style={style}>
          {children && children[index]}
        </div>
      )}
    </List>
  );
}

export default MenuList;
