// NOTE (brlee): This static file is used to map all old userSubs to canonical email ids.
// This data was generated on 2019/04/02 by collecting all createdBy's from our stage and production environment and calling COAM to get the user's canonical id.
const subToEmailMapping = {
  'waad|HqC5VwsL3Ac6srpuqjnMr6_-ugI6B9uy06pMKX4E2SA': 'Andrew.Carey@cimpress.com',
  'adfs|cwhitney@vistaprint.com': 'cwhitney@vistaprint.com',
  'waad|_bieQkTiO6QFMDgMeN4AXvpvpD_QbkSxIQftGo2jMjQ': 'bmcglynn@cimpress.com',
  'waad|nv1gvnBHbITAw1EIaeOQoXIXta8UOvz4gp6uqJYSxa0': 'abealo@cimpress.com',
  'ad|Tradeprint-AD|0c5d0217-602e-4bf3-b088-b961b33e2faf': 'sebdy@tradeprint.co.uk',
  'google-apps|jmcazaux@exagroup.biz': 'jmcazaux@exagroup.biz',
  'waad|He7pyBH5oPZcorrL_WrFtfVyoDGMeHOW3ERNLlMhznU': 'sramesh@cimpress.com',
  'auth0|5c3cf02763aff90b46ab0513': 'printbrothers-demo@cimpressdemo.com',
  'auth0|5c62f3965a2ca7579fe60404': 'lhicks@pcna.com',
  'waad|TaAMNdV8zwthEH8b2EizxAkb5Zw27TojU_D6AfV0Bqg': 'Pradnesh.Jogwar@cimpress.com',
  'auth0|5b7ec84c8c005c4d8a2f97cf': 'terry@sbpack.com',
  'adfs|sebdy@tradeprint.co.uk': 'sebdy@tradeprint.co.uk',
  'ad|BuildASign-AD|3e2604e8-dfe6-4906-89f5-f892081f27cd': 'jared.aubry@buildasign.com',
  'waad|hYeShjNhPutED9FkA5e6BP7T7jM57cZAtfy_UFhbVqk': 'herman.eikelboom@drukwerkdeal.nl',
  'adfs|EasyFlyerADFS|nicolas.besnard@easyflyer.fr': 'nicolas.besnard@easyflyer.fr',
  'samlp|CimpressSAMLP|brlee@cimpress.com': 'brlee@cimpress.com',
  'waad|ErSAPjDXtjWdOdPiAQOwW5IhFzlMn9W1NSi3mgaGZkQ': 'Bilal.Syed@cimpress.com',
  'samlp|CimpressSAMLP|abancila@cimpress.com': 'abancila@cimpress.com',
  'auth0|59a45429e9bf994d38ddf148': 'greg.bodhaine@rpiprint.com',
  'adfs|iwebster@tradeprint.co.uk': 'iwebster@tradeprint.co.uk',
  'waad|0QVE_y93Y61n56fDXXZDm3DY0y13Aw1H0PzZul7_uLs': 'dgoldman@cimpress.com',
  'ad|Druck-at|ed0b57bb-7585-4869-8f70-c9764c44e37c': 'Natasha.Patek@druck.at',
  'waad|QQfVbk7CRDPPQuWOSGTcti295JMbbtN7DDckyG2Qy-w': 'Carl.Dahl@cimpress.com',
  'waad|osw2PXYgn2MqlCiI5Wi0NmCbJxaUbmwu_5wfN0tkqZ4': 'FFMigration@cimpress.com',
  'waad|z_bVGmWTszjqL4yCHzAjIaxG3nbPhgbd4Xueigw_Jpw': 'patrick.rombouts@drukwerkdeal.nl',
  'adfs|tmagalhaes@cimpress.com': 'tmagalhaes@cimpress.com',
  'auth0|5a0c68019b835f650ae10984': 'ftsquadtest@gmail.com',
  'waad|bU7IaZVETsJCSj3yuhDTYM7tVQVsRTcHEeNVLFv3ASo': 'brlee@cimpress.com',
  'adfs|Catherine.Gropp@vistaprint.com': 'Catherine.Gropp@vistaprint.com',
  'adfs|gvilloria@cimpress.com': 'gvilloria@cimpress.com',
  'adfs|rbarbieri@vistaprint.com': 'rbarbieri@vistaprint.com',
  'waad|7S1ZnyXB5pdWozUxSVJE7fh6JqF_UWFD-lo6oSfRYMM': 'zmarois@cimpress.com',
  'waad|1CUcNeHj1rSJo4whsmt6eVE1Yzn35q4_Ubh-eF--KIU': 'wscerra@cimpress.com',
  'adfs|jdonohue@vistaprint.com': 'jdonohue@vistaprint.com',
  'google-apps|manuel.galvan@exagroup.biz': 'manuel.galvan@exagroup.biz',
  'google-apps|mariepierre@exagroup.biz': 'mariepierre@exagroup.biz',
  'waad|mm8qSK7xOzXmvGUHyrQ4jl_vxSksDMKye7iPe2AqQxo': 'kflannery@cimpress.com',
  'waad|B13t3vPMnXqfQY8Eu2VlPbu7bR-K5Y7aufebBlqye0E': 'vcuenagarcia@cimpress.com',
  'waad|yPXPwsYyyk95qMeZuzry3n4NewiYOrZkpXO5HmkcHDQ': 'ramalik@cimpress.com',
  'adfs|Giulia.Zannini@pixartprinting.com': 'Giulia.Zannini@pixartprinting.com',
  'waad|1zrI6w3mqX_aTt6wQDdtz2W5Gq_g32o0ddSU3l5dL-k': 'skondusennappan@cimpress.com',
  'adfs|gerhard.patek@druck.at': 'gerhard.patek@druck.at',
  'waad|ivXcnCAgUTAi0WwWoYTnjvgTJrSiylh4hYcBaZdlJZI': 'mariska.mediati@drukwerkdeal.nl',
  'adfs|cmengerink@cimpress.com': 'cmengerink@cimpress.com',
  'waad|p4lrZDsZ7f2nPmejpC_nRDLc7-1ObCiSgIrcHR6nv-s': 'vincent.bloos@drukwerkdeal.nl',
  'adfs|Fabio.Trigiante@pixartprinting.com': 'Fabio.Trigiante@pixartprinting.com',
  'auth0|5c46f6998806a16a8c99240a': 'marie.bernard@adesa.fr',
  'ad|Druck-at|5205e869-04d0-4d51-82e2-5fcb6439e675': 'michael.kristan@druck.at',
  'auth0|5c82d61659af6c06b07b9238': 'admin-marketplace-demo@cimpressdemo.com',
  'adfs|Mario.Rigato@pixartprinting.com': 'Mario.Rigato@pixartprinting.com',
  'waad|_xNDdkE93eUToYqxYQgB8ea2fcI68jdVmjbm86XDSL0': 'sflannery@cimpress.com',
  'adfs|dburt@cimpress.com': 'dburt@cimpress.com',
  'waad|LEwWSp9kp1Q_emmCavKFeyS28AEQ7nwnS2jhIGHfRRE': 'tgarside@cimpress.com',
  'auth0|5c3cfeae0d96bc7d1912182c': 'printbrothers-buyer@cimpressdemo.com',
  'auth0|5c4592c9136b2b7ea3d8ef8c': 'xikovafi@utooemail.com',
  'adfs|Diego.Venturini@pixartprinting.com': 'Diego.Venturini@pixartprinting.com',
  'waad|AVCvY4DvgL4hVubj48uKBpzQjMCLFKjU4d0fycqqgqM': 'amdeshmukh@cimpress.com',
  'adfs|anunez@vistaprint.com': 'anunez@vistaprint.com',
  'auth0|5c46f59214f74009ce13a438': 'jules.malard@adesa.fr',
  'waad|L7MfPqbInh77ku0pVjYt2q8eaH30JR0fgPnjxBXT194': 'Douglas.Salazar@cimpress.com',
  'ad|WIRmachenDRUCK-AD|3dd739f3-e64e-403d-b328-d6c24b0113f0': 'p.kooi@wir-machen-druck.de',
  'ad|Tradeprint-AD|8099b105-058f-4fc2-9841-850db05a5cea': 'iwebster@tradeprint.co.uk',
  'adfs|Andrew.Knowles@vistaprint.com': 'Andrew.Knowles@vistaprint.com',
  'adfs|sflannery@cimpress.com': 'sflannery@cimpress.com',
  'waad|VNR_g9iUy9DxHsnwuMWRSEW1gCSfaPQfPUrkWLoUe1o': 'abancila@cimpress.com',
  'waad|dNIbJiK1lQIqWp60uF5A-esI-zSUluA2IxD1YeSUldg': 'kawalsh@cimpress.com',
  'google-apps|julie@exagroup.biz': 'julie@exagroup.biz',
  'ad|WIRmachenDRUCK-AD|24286655-0453-49e0-a252-772e2107184f': 'j.hatton@wir-machen-druck.de',
  'adfs|skondusennappan@cimpress.com': 'skondusennappan@cimpress.com',
  'auth0|5c3cf151b124be356e9e0b3b': 'printbrothers-dryrun@cimpressdemo.com',
  'adfs|selee@cimpress.com': 'selee@cimpress.com',
  'google-apps|florian@exagroup.biz': 'florian@exagroup.biz',
  'ad|WIRmachenDRUCK-AD|38eba4b8-7ecf-449a-be70-e4bcbb6893fe': 'p.mueller@wir-machen-druck.de',
  'adfs|tbuylaere@cimpress.com': 'tbuylaere@cimpress.com',
  'auth0|5a9e30078e4f382911f76606': 'ludovic@adesa.fr',
  'waad|ivY5aB8cWnhwLw9rtQIujLBubqteXdee0LHUAQuSEGw': 'selee@cimpress.com',
  'ad|National-Pen-AD|3f8816ac-0d43-4c74-9865-c8945e580793': 'bonnie.shimrat@goldstarpens.com',
  'ad|BuildASign-AD|fb00f558-51ac-4423-8d81-022653f3fd30': 'steven.grey@buildasign.com',
  'adfs|mrees@vistaprint.com': 'mrees@vistaprint.com',
  'adfs|sharr@vistaprint.com': 'sharr@vistaprint.com',
  'adfs|kawalsh@cimpress.com': 'kawalsh@cimpress.com',
  'waad|GadGGd7AiDOpIjLech4eWht2OLmscuPbSV4Sp5tscKg': 'agodbole@cimpress.com',
  'waad|yGaSmAJ-8w0Nx8r8am4VAQrRe0h9_JMQblJaLl9hZ-s': 'tleung@cimpress.com',
  'adfs|kfarina@vistaprint.com': 'kfarina@vistaprint.com',
  'waad|IQyig1uhSra50t6PS8p6lKcBLHYwZCVCQMmpYH6G6Sw': 'FFMigration@cimpress.com',
  'waad|NVhKt1lSurK35zAtHrk44Ys-e2kpP0Ph_w3cvOowJUc': 'asalani@cimpress.com',
  'samlp|CimpressSAMLP|kawalsh@cimpress.com': 'kawalsh@cimpress.com',
  'waad|7Ar9XjKyvIEMTj79ThaHx4gI-T_diKAoeoPVVUAPisQ': 'zli@cimpress.com'
};

export default subToEmailMapping;
