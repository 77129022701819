import moment from 'moment/moment';
export function hasTermStarted(effectiveStartDate) {
  const diffDays = moment.duration(moment.utc().diff(moment.utc(effectiveStartDate)), 'days');
  return diffDays > 0;
}

export function isTermExpiring(effectiveEndDate) {
  const EXPIRING_DAYS = 14;
  const endDate = moment.utc(effectiveEndDate);

  return endDate.diff(moment.utc(), 'day') <= EXPIRING_DAYS;
}

export function isTermExpired(effectiveEndDate) {
  const diffDays = moment.duration(moment.utc().diff(moment.utc(effectiveEndDate)), 'days');
  return diffDays > 0;
}

export function getDateDifferenceFromToday(startDate) {
  const startDateMoment = moment.utc(startDate);
  return startDateMoment.diff(moment());
}

export function isEndOfTime(dateTime) {
  const endDateMoment = moment(dateTime);
  return endDateMoment.isSameOrAfter('9999-01-01');
}

export function dateDifferenceInDays(startDate, endDate) {
  const startDateMoment = moment.utc(startDate);
  const endDateMoment = moment.utc(endDate);

  return endDateMoment.diff(startDateMoment, 'days');
}

export function utcToLocalDateTime(utcDateTime) {
  const utcDateTimeMoment = moment.utc(utcDateTime);
  return utcDateTimeMoment.local().format('YYYY-MM-DD h:mm A Z');
}
