import { permissions } from '../constants/permissions';

export const isManageContractPermission = (accountId, userAccountsPermissions) => {
  if (userAccountsPermissions && accountId) {
    var permissionsUserHas = userAccountsPermissions[accountId];
    if (permissionsUserHas) {
      for (let index = 0; index < permissionsUserHas.length; index++) {
        const permissionUserHas = permissionsUserHas[index];

        if (permissionUserHas === permissions.MANAGE_CONTRACT) {
          return true;
        }
      }
    }
  }
  return false;
};

export const isManageProductAgreementPermission = (accountId, userAccountsPermissions) => {
  if (userAccountsPermissions && accountId) {
    var permissionsUserHas = userAccountsPermissions[accountId];
    if (permissionsUserHas) {
      for (let index = 0; index < permissionsUserHas.length; index++) {
        const permissionUserHas = permissionsUserHas[index];

        if (permissionUserHas === permissions.MANAGE_CONTRACT) {
          return true;
        }

        if (permissionUserHas === permissions.MANAGE_PRODUCT_AGREEMENT) {
          return true;
        }
      }
    }
  }
  return false;
};

export const isReadContractPermission = (accountId, userAccountsPermissions) => {
  if (userAccountsPermissions && accountId) {
    var permissionsUserHas = userAccountsPermissions[accountId];
    if (permissionsUserHas) {
      for (let index = 0; index < permissionsUserHas.length; index++) {
        const permissionUserHas = permissionsUserHas[index];

        if (permissionUserHas === permissions.MANAGE_CONTRACT) {
          return true;
        }

        if (permissionUserHas === permissions.MANAGE_PRODUCT_AGREEMENT) {
          return true;
        }

        if (permissionUserHas === permissions.READ_CONTRACT) {
          return true;
        }
      }
    }
  }
  return false;
};

export const isReadProductAgreementPermission = (accountId, userAccountsPermissions) => {
  if (userAccountsPermissions && accountId) {
    var permissionsUserHas = userAccountsPermissions[accountId];
    if (permissionsUserHas) {
      for (let index = 0; index < permissionsUserHas.length; index++) {
        const permissionUserHas = permissionsUserHas[index];

        if (permissionUserHas === permissions.MANAGE_CONTRACT) {
          return true;
        }

        if (permissionUserHas === permissions.MANAGE_PRODUCT_AGREEMENT) {
          return true;
        }

        if (permissionUserHas === permissions.READ_PRODUCT_AGREEMENT) {
          return true;
        }
      }
    }
  }
  return false;
};
