// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import Loudspeaker from '@cimpress-technology/react-loudspeaker';
import { withTranslation } from 'react-i18next';
import Shell from './components/application/Shell';
import { isProduction } from './utilities/processFunctions';
// import HomePage from './components/home/HomePage';
import NotFound from './components/application/NotFound';
import AuthedRoute from './components/application/AuthedRoute';
import { auth } from './utils/auth';
import FullStory from './components/analytics/fullstory';
import SettingsPage from './components/settings/SettingsPage';
// import ViewContractsPage from './components/contracts/viewTermRevision/ViewTermRevisionPage';
import theme from 'reapop-theme-wybo';
import MyNotificationsSystem from './components/application/notificationSystem';
import { connect } from 'react-redux';
import { selectors as notificationsSelectors } from './reducers/notifications';
// import BulkUpdateWizard from './components/bulkUpdate/BulkUpdateWizard';
// import ContractChangesPage from './components/contracts/contractChanges/ContractChangesPage';
// import ViewSkuPage from './components/contracts/viewSku/ViewSkuPage';
import LandingPage from './components/home/landingPage/LandingPage';
import ProductAgreements from './components/contracts/viewProductList2/ProductAgreements';
import CreateRelationships from './components/contracts/createRelationships/CreateRelationships';
import NotificationDetails from './components/contracts/viewNotificationDetails/NotificationDetails';
import Notifications from './components/contracts/viewNotifications/Notifications';

class App extends React.Component {
  static propTypes = {
    isLoadingNotifications: PropTypes.bool
  };

  componentDidMount() {
    if (this.props.isLoadingNotifications) {
      window.addEventListener('beforeunload', this.onUnload);
    }
  }

  onUnload = e => {
    const confirmationMessage = this.props.t('common:unsavedChanges.message');

    (e || window.event).returnValue = confirmationMessage; //Gecko + IE
    return confirmationMessage;
  };

  componentDidUpdate(prevProps) {
    const { isLoadingNotifications } = this.props;

    if (isLoadingNotifications !== prevProps.isLoadingNotifications) {
      if (isLoadingNotifications) {
        window.addEventListener('beforeunload', this.onUnload);
      } else {
        window.removeEventListener('beforeunload', this.onUnload);
      }
    }
  }

  render() {
    const { t } = this.props;
    let story = null;
    let profile = auth.getProfile();
    if (!isEmpty(profile)) {
      const { sub, name, email } = profile;
      story = <FullStory org="97CT5" name={name} email={email} sub={sub} isProduction={isProduction} />;
    }

    return (
      <div>
        {story}
        <MyNotificationsSystem theme={theme} />
        <Loudspeaker
          title={t('appShell:loudspeakerModal.title')}
          accessToken={auth.getAccessToken()}
          channelId={isProduction ? 'contractmanager-v2' : 'contractmanager-v2-stg'}
        />
        <Shell>
          <div>
            <Switch>
              <AuthedRoute exact path="/" component={LandingPage} />
              <AuthedRoute exact path="/(index.html)?" component={LandingPage} />
              <AuthedRoute path="/createContract" component={CreateRelationships} />
              <AuthedRoute exact path="/productAgreements/:businessIds/:tabIndex?" component={ProductAgreements} />
              <AuthedRoute
                exact
                path="/notifications/:manageAccountId/:notificationId"
                component={NotificationDetails}
              />
              <AuthedRoute exact path="/notifications/:manageAccountId" component={Notifications} />
              <AuthedRoute path="/configurations/:manageAccountId" component={SettingsPage} />
              <Route path="/*" component={NotFound} />
            </Switch>
          </div>
        </Shell>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isLoadingNotifications: notificationsSelectors.getHasLoadingNotifications(state)
});

export default connect(mapStateToProps)(withTranslation()(App));
