import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { NavTab, NavTabItem, Breadcrumbs, BreadcrumbItem } from '@cimpress/react-components';
import { Link } from 'react-router-dom';
import { BUYERS, SELLERS } from '../../constants/transactorType';
import BasicLayout from '../layouts/BasicLayout';
import SubscriptionSettings from './subscriptions/SubscriptionSettings';
import { CONTRACT_NOTIFICATION, PRODUCT_NOTIFICATION } from '../../constants/subscriptionConstants';

const CONTRACT_SUBSCRIPTIONS_TAB = 'CONTRACT_SUBSCRIPTIONS';
const PRODUCT_SUBSCRIPTIONS_TAB = 'PRODUCT_SUBSCRIPTIONS';
const AUTOAPPROVALS_TAB = 'AUTOAPPROVALS';

class SettingsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      identityType: BUYERS,
      identityId: undefined,
      selectedTab: CONTRACT_SUBSCRIPTIONS_TAB
    };
  }

  onFulfillerSelected = () => {
    this.setState({ identityType: SELLERS });
  };

  onMerchantSelected = () => {
    this.setState({ identityType: BUYERS });
  };

  onSelectIdentity = selection => {
    this.setState({ identityId: selection && (selection.value || selection.id) });
  };

  onContractSubscriptionsSelect = () => {
    this.setState({ selectedTab: CONTRACT_SUBSCRIPTIONS_TAB });
  };

  onProductSubscriptionsSelect = () => {
    this.setState({ selectedTab: PRODUCT_SUBSCRIPTIONS_TAB });
  };

  onAutoApprovalsSelect = () => {
    this.setState({ selectedTab: AUTOAPPROVALS_TAB });
  };

  render() {
    const { t } = this.props;
    const { identityType, selectedTab } = this.state;

    const manageAccountId = this.props.match.params.manageAccountId;

    let tabContent;
    switch (selectedTab) {
      case CONTRACT_SUBSCRIPTIONS_TAB:
        tabContent = (
          <SubscriptionSettings
            identityType={identityType}
            onFulfillerSelected={this.onFulfillerSelected}
            onMerchantSelected={this.onMerchantSelected}
            notificationType={CONTRACT_NOTIFICATION}
            manageAccountId={manageAccountId}
          />
        );
        break;
      case PRODUCT_SUBSCRIPTIONS_TAB:
        tabContent = (
          <SubscriptionSettings
            identityType={identityType}
            onFulfillerSelected={this.onFulfillerSelected}
            onMerchantSelected={this.onMerchantSelected}
            notificationType={PRODUCT_NOTIFICATION}
            manageAccountId={manageAccountId}
          />
        );
        break;
      default:
        break;
    }

    return (
      <div>
        <div className="breadCrumbPadding">
          <Breadcrumbs>
            <BreadcrumbItem>
              <Link key={'/'} to={'/'}>
                {t('breadcrumbs:home')}
              </Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>{t('breadcrumbs:configurations')}</BreadcrumbItem>
          </Breadcrumbs>
        </div>
        <div className="titlePadding">
          <h1>{t('settings:header')}</h1>
        </div>
        <BasicLayout>
          <div className="subscriptionBackground">
            <div className="navtab-container">
              <NavTab>
                <NavTabItem active={selectedTab === CONTRACT_SUBSCRIPTIONS_TAB}>
                  {/* I kept this is commented because in future we might use different type of subscription.
                 <Button onClick={this.onContractSubscriptionsSelect}>
                  {t('settings:subscriptions.header', { context: CONTRACT_NOTIFICATION })}
                </Button> */}
                </NavTabItem>
                {/* <NavTabItem active={selectedTab === PRODUCT_SUBSCRIPTIONS_TAB}>
                <Button onClick={this.onProductSubscriptionsSelect}>
                  {t('settings:subscriptions.header', { context: PRODUCT_NOTIFICATION })}
                </Button>
              </NavTabItem>
              <NavTabItem active={selectedTab === AUTOAPPROVALS_TAB}>
                <Button onClick={this.onAutoApprovalsSelect}>{t('settings:autoApprovals.header')}</Button>
              </NavTabItem> */}
              </NavTab>
            </div>
            <div>{tabContent}</div>
          </div>
        </BasicLayout>
      </div>
    );
  }
}

const mapStateToProps = state => ({});

export default connect(mapStateToProps)(withTranslation()(SettingsPage));
