import { createReducer } from '../utils';
import * as fulfillmentNetworkApi from '../../services/FulfillmentNetwork';
import compose from 'lodash/fp/compose';
import map from 'lodash/fp/map';

import groupBy from 'lodash/fp/groupBy';
import { AVAILABLE, NOT_LOADED, UNAVAILABLE } from '../../constants/entityStatus';

export const fetchNetwork_REQUEST = 'fetchNetwork_REQUEST';
export const fetchNetwork_SUCCESS = 'fetchNetwork_SUCCESS';
export const fetchNetwork_FAILURE = 'fetchNetwork_FAILURE';

const cacheKey = 'network';
const cacheInMs = 1800000; // 30 minutes

const networkHooks = {
  onFailure: 'Unable to fetch networks.'
};

// Action
export const fetchNetworkAction = () => {
  return {
    types: [fetchNetwork_REQUEST, fetchNetwork_SUCCESS, fetchNetwork_FAILURE],
    cacheTtlMs: cacheInMs,
    cacheKey: cacheKey,
    callAPI: async () => {
      return await fulfillmentNetworkApi.getRelations();
    },
    hooks: networkHooks
  };
};

// reducer
export default createReducer(
  {},
  {
    [fetchNetwork_SUCCESS]: (state, action) => {
      let { payload } = action;

      const network = compose(
        groupBy('managingBusiness'),
        map(rel => ({
          managingBusiness: rel.managingBusiness.accountId,
          thirdPartyFulfiller: rel.thirdPartyFulfiller.accountId
        }))
      )(payload);

      return { ...state, payload: network, status: AVAILABLE };
    },
    [fetchNetwork_REQUEST]: (state, action) => {
      return { ...state, status: NOT_LOADED };
    },
    [fetchNetwork_FAILURE]: (state, action) => {
      return { ...state, status: UNAVAILABLE };
    }
  }
);

// Selector
export const getNetwork = state => {
  return state.fulfillmentnetwork.network.payload;
};

export const getNetworkStatus = state => {
  return state.fulfillmentnetwork.network.status;
};
