import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  actions as productAgreementActions,
  selectors as productAgreementSelectors
} from '../../../reducers/productagreements';

import { actions as accountActions } from '../../../reducers/account';
import FilterOptions from './FilterOptions';
import { getSellerAccountMapOfUser } from '../../../utilities/commonHelper';

const getSellerAccountOptions = sellerAccountsMap => {
  function accountNameComparer(a, b) {
    if (a.label < b.label) {
      return -1;
    }
    if (a.label > b.label) {
      return 1;
    }
    return 0;
  }

  return Object.keys(sellerAccountsMap)
    .map(key => {
      const { accountId, name } = sellerAccountsMap[key];
      return { label: name, value: accountId };
    })
    .sort(accountNameComparer);
};
const getSubscribedParties = (sellerAccountsMap, subscriptions) => {
  if (!subscriptions || !sellerAccountsMap) return [];

  return subscriptions.map(counterPartyId => {
    return {
      label: sellerAccountsMap[counterPartyId] ? sellerAccountsMap[counterPartyId].name : '',
      value: counterPartyId
    };
  });
};

const getInitialState = () => {
  return {
    sellerAccountsMap: undefined,
    sellerAccountOptions: [],
    isFetchingRelations: true,
    subscribedParties: []
  };
};

function NotificationsFilter(props) {
  const [state, setState] = useState(getInitialState);

  const { relationships, identityType, contractSubscriptions, manageAccountId } = props;
  const { sellerAccountsMap, sellerAccountOptions, subscribedParties } = state;
  useEffect(() => {
    props.fetchRelationships([manageAccountId]);
  }, []);

  useEffect(
    () => {
      if (contractSubscriptions && sellerAccountsMap) {
        const subscribedParties = getSubscribedParties(sellerAccountsMap, contractSubscriptions);
        setState(prevState => {
          return { ...prevState, subscribedParties: subscribedParties };
        });
      }
    },
    [sellerAccountsMap, contractSubscriptions]
  );
  useEffect(
    () => {
      if (relationships) {
        const relations = Object.values(relationships);
        const sellerAccountsMap = getSellerAccountMapOfUser(relations, manageAccountId);
        const sellerAccountOptions = getSellerAccountOptions(sellerAccountsMap);
        setState(prevState => {
          return { ...prevState, sellerAccountsMap, sellerAccountOptions, isFetchingRelations: false };
        });
      }
    },
    [relationships]
  );

  return (
    <Fragment>
      <FilterOptions
        onSearch={props.onSearch}
        identityType={identityType}
        subscribedParties={subscribedParties}
        sellerAccountOptions={sellerAccountOptions}
      />
    </Fragment>
  );
}

const mapStateToProps = state => {
  return {
    relationships: productAgreementSelectors.getRelationships(state),
    relationshipsStatus: productAgreementSelectors.getRelationshipStatus(state)
  };
};

const mapDispatchToProps = {
  fetchRelationships: productAgreementActions.fetchRelationshipsAction,
  fetchAccountById: accountActions.fetchAccountAction
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationsFilter);
