import { get } from 'lodash';

import { makeRequest, buildOptions } from './ServiceHelpers';
import { buildListQuery, maxInt32 } from '../utilities/queryHelper';

const endpointUrl = process.env.REACT_APP_FULFILLMENT_NETWORK_URL;

export const getRelations = (managingBusinesses = [], thirdPartyFulfillers = []) => {
  const options = buildOptions({ hal: true });
  const managingBusinessQueries = buildListQuery('managingBusinessId', managingBusinesses);
  const thirdPartyFulfillerQueries = buildListQuery('thirdPartyFulfillerId', thirdPartyFulfillers);

  return makeRequest(
    endpointUrl,
    `/v1/relations?offset=0&limit=${maxInt32}${managingBusinessQueries}${thirdPartyFulfillerQueries}`,
    options,
    'getting relations'
  ).then(halResponse => {
    return get(halResponse, '_embedded.item');
  });
};
