import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { TextField } from '@cimpress/react-components';
import BasicTermInfoForm from './BasicTermInfoForm';

class ModifiersInfoForm extends Component {
  static defaultProps = {};

  static propTypes = {
    onChangeModifierAmount: PropTypes.func.isRequired,
    getValidationErrorFns: PropTypes.object.isRequired,
    modifierAmount: PropTypes.string,
    modifierType: PropTypes.string.isRequired
  };

  state = {
    modifierError: undefined
  };

  onChangeModifierAmount = e => {
    const { onChangeModifierAmount, getValidationErrorFns, modifierType } = this.props;

    onChangeModifierAmount(e.target.value);
    this.setState({ modifierError: e && getValidationErrorFns.modifier(modifierType, e.target.value) });
  };

  render() {
    const { t, modifierAmount, modifierType, ...rest } = this.props;
    const { modifierError } = this.state;
    return (
      <div>
        <h5>{t('createContract:messages.setModifierHeader', { context: modifierType })}</h5>
        <TextField
          bsStyle={modifierError && 'error'}
          label={t('createContract:messages.modifierTextFieldLabel', { context: modifierType })}
          value={modifierAmount}
          required
          onChange={this.onChangeModifierAmount}
          onBlur={this.onChangeModifierAmount}
          type="number"
          min="0"
          helpText={modifierError}
        />
        <hr />
        <BasicTermInfoForm {...rest} />
      </div>
    );
  }
}

export default withTranslation()(ModifiersInfoForm);
