import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import SelectedSkuBin from '@cimpress-technology/ff-react-components/lib/SkuSelector/SelectedSkuBin/SelectedSkuBin';
import PriceChangeNotificationPricingGrid from './PriceChangeNotificationPricingGrid';
import { css } from 'emotion';

const selectedSkuBinStyle = css`
  position: relative;
  right: 10px;
`;

function ItemPrices(props) {
  const { i18n } = useTranslation();
  const { notificationDetails } = props;
  const [state, setState] = useState({
    selectedSku: notificationDetails.priceChange.products[0]
      ? notificationDetails.priceChange.products[0].id
      : undefined
  });
  const { selectedSku } = state;

  const selectSku = skuInfo => {
    setState(prevState => ({ ...prevState, selectedSku: skuInfo.sku }));
  };

  const getItems = () => {
    let items = [];
    notificationDetails.priceChange.products.forEach(product => {
      if (product.state !== 'cancelled') {
        items.push({
          sku: product.id,
          name: product.name
        });
      }
    });
    return items;
  };

  const selectedSkuBin = (
    <div className={`col-xs-12 col-md-3 ${selectedSkuBinStyle}`}>
      <SelectedSkuBin
        style={{ height: 700 }}
        skus={getItems()}
        clickable
        isSkuClicked={sku => sku && selectedSku && sku === selectedSku}
        onAction={selectSku}
        locale={i18n.language}
      />
    </div>
  );

  const pricingGridWrapper = selectedSku && (
    <div className="col-xs-12 col-md-9">
      <PriceChangeNotificationPricingGrid notificationDetails={notificationDetails} sku={selectedSku} />
    </div>
  );

  return (
    <div>
      {selectedSkuBin}
      {pricingGridWrapper}
    </div>
  );
}

export default connect()(ItemPrices);
