import React, { useState, Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { TabCard } from '@cimpress/react-components';
import BusinessRelationship from './BusinessRelationship';
import BasicLayout from '../../layouts/BasicLayout';
import { titlePadding } from './ProductAgreementPageUtil';

function BusinessTab({ businessOne, businessTwo, isSelf, relationshipDataKey, routeTabIndex }) {
  const { t } = useTranslation();
  const [tabIndex, setTabIndex] = useState(0);
  const onTabSelection = (e, selectedKey) => {
    setTabIndex(selectedKey);
  };

  useEffect(
    () => {
      let isMount = false;
      if (isMount && routeTabIndex) {
        setTabIndex(routeTabIndex);
      }
      return () => {
        isMount = true;
      };
    },
    [routeTabIndex]
  );

  const tabs = [];
  const { name } = businessTwo;
  if (isSelf) {
    tabs.push({
      name: <div>{businessOne.name}</div>,
      block: (
        <BusinessRelationship
          key={0}
          isSelf
          buyer={businessOne}
          seller={businessOne}
          relationshipDataKey={relationshipDataKey}
          tabIndex={0}
        />
      ),
      href: '#'
    });
  } else {
    tabs.push({
      name: <div>{t('productAgreementsPage:buyerSubHeading', { businessTwo: name })}</div>,
      block: (
        <BusinessRelationship
          key={1}
          buyer={businessOne}
          seller={businessTwo}
          relationshipDataKey={relationshipDataKey}
          tabIndex={0}
        />
      ),
      href: '#'
    });
    tabs.push({
      name: t('productAgreementsPage:sellerSubHeading', { businessTwo: name }),
      block: (
        <BusinessRelationship
          key={2}
          buyer={businessTwo}
          seller={businessOne}
          relationshipDataKey={relationshipDataKey}
          tabIndex={1}
        />
      ),
      href: '#'
    });
  }
  return (
    <Fragment>
      <div className="titlePadding">
        <h1>{titlePadding(t, businessOne, businessTwo, isSelf)}</h1>
      </div>
      <BasicLayout>
        <TabCard tabs={tabs} selectedIndex={tabIndex} onSelect={onTabSelection} className="tabMinHeight" />;
      </BasicLayout>
    </Fragment>
  );
}

export default BusinessTab;
