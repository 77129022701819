import { createReducer } from '../utils';
import * as fulfillerIdentityApi from '../../contractV1_src/service/FulfillerIdentity';
import { AVAILABLE, NOT_LOADED, UNAVAILABLE } from '../../constants/entityStatus';

export const fetchIdentityData_REQUEST = 'fetchIdentityData_REQUEST';
export const fetchIdentityData_SUCCESS = 'fetchIdentityData_SUCCESS';
export const fetchIdentityData_FAILURE = 'fetchIdentityData_FAILURE';

const cacheKey = 'fulfillers';
const cacheInMs = 3600000; // 1 hour

const fetchFulfillerIdentityDataHooks = {
  onFailure: 'Unable to fetch fulfiller identity data.'
};

// Action
export const fetchFulfillerIdentityData = () => {
  return {
    types: [fetchIdentityData_REQUEST, fetchIdentityData_SUCCESS, fetchIdentityData_FAILURE],
    cacheTtlMs: cacheInMs,
    cacheKey: cacheKey,
    callAPI: async () => {
      const fulfillerIdentityApiData = await fulfillerIdentityApi.getFulfillers();
      return { create: createDataMap(fulfillerIdentityApiData), filter: createFulfillersMap(fulfillerIdentityApiData) };
    },
    hooks: fetchFulfillerIdentityDataHooks
  };
};

const createDataMap = fulfillerIdentityApiData => {
  var fulfillerIdentityDataMap = {};
  fulfillerIdentityApiData.forEach(fulfiller => {
    if (fulfiller.links.account && fulfiller.links.account.href) {
      var splitArray = fulfiller.links.account.href.split('/');
      var accountId = splitArray[6];
      if (accountId in fulfillerIdentityDataMap) {
        var fulfillersForAccount = fulfillerIdentityDataMap[accountId];
        fulfillersForAccount.push(fulfiller);
        fulfillerIdentityDataMap[accountId] = fulfillersForAccount;
      } else {
        fulfillerIdentityDataMap[accountId] = [fulfiller];
      }
    }
  });
  return fulfillerIdentityDataMap;
};

const createFulfillersMap = fulfillerIdentityApiData => {
  return fulfillerIdentityApiData.map(each => {
    return {
      accountId: each.links.account ? each.links.account.href.split('/').pop() : undefined,
      id: each.fulfillerId,
      label: each.name + ' (sellers)',
      name: each.name,
      type: 'merchants',
      value: each.links.account ? each.links.account.href.split('/').pop() : ' @sellers'
    };
  });
};

// Reducer
export default createReducer(
  {},
  {
    [fetchIdentityData_SUCCESS]: (state, action) => {
      let { payload } = action;
      return { ...state, payload, status: AVAILABLE };
    },
    [fetchIdentityData_REQUEST]: (state, action) => {
      return { ...state, status: NOT_LOADED };
    },
    [fetchIdentityData_FAILURE]: (state, action) => {
      return { ...state, status: UNAVAILABLE };
    }
  }
);

// Selectors
export const getFulfillerIdentityData = (state, type = 'create') => {
  if (type === 'filter') {
    return state.contractV1.fulfillers.payload.filter;
  } else {
    return state.contractV1.fulfillers.payload.create;
  }
};

export const getFulfillerIdentityDataStatus = state => {
  return state.contractV1.fulfillers.status;
};
