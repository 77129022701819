import { getDisplayAgreementType, getDisplayStatus } from '../../../utilities/displayFunctions';
import TermStatus from '../../../constants/termStatus';
import * as pricingTermApi from '../../../services/PricingTerm';
import * as productPriceApi from '../../../services/ProductPrice';
import { createApiErrorNotificationAction } from '../../utils';
import { createSelector } from 'reselect';
import { combineReducers } from 'redux';
import { selectors as identitySelectors } from '../identities1';
import { selectors as configurationSelectors } from '../configurations';
import compact from 'lodash/compact';
import get from 'lodash/get';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import isNil from 'lodash/isNil';
import sortBy from 'lodash/sortBy';
import find from 'lodash/find';
import groupBy from 'lodash/groupBy';
import values from 'lodash/values';
import maxBy from 'lodash/maxBy';
import memoizeOne from 'memoize-one';
import * as JsSearch from 'js-search';
import createCachedSelector from 're-reselect';
import { auth } from '../../../utils/auth';
import { MAX_INT } from '../../../constants/appConstants';
import { getSkuInfoMapWithSkuList } from '../../../services/SkuSearch';
import {
  createApiLoadNotificationAction,
  createNotificationButtons,
  createFinishApiLoadNotificationAction,
  toCK
} from '../../utils';
import { history } from '../../../utils/history';
import React from 'react';
import { CSVLink } from 'react-csv';
import { getTermType, TermType } from '../../../constants/termTypes';
import { typeIsFulfiller, typeIsMerchant } from '../../identities/utils';
import userSubToEmail from '../../../utilities/userSubHelper';
import termsV2Reducers, { actions as termsV2Actions, selectors as termsV2Selectors } from './index.v2';
import flow from 'lodash/flow';
import * as fp from 'lodash/fp';
import { isTermExpired } from '../../../utilities/dateFunctions';
import { fetchTermRevisionPriceModels_SUCCESS } from './termRevisionPriceModels';
import {
  fetchTermsForHomePage_FAILURE,
  fetchTermsForHomePage_REQUEST,
  fetchTermsForHomePage_SUCCESS
} from './fetchTermsForHomePage';
import { updateTerm_FAILURE, updateTerm_REQUEST, updateTerm_SUCCESS } from './updateTerm';

// - ACTION TYPES
const FETCH_TERM_REVISION_REQUEST = 'FETCH_TERM_REVISION_REQUEST';
const FETCH_TERM_REVISION_FAILURE = 'FETCH_TERM_REVISION_FAILURE';
const FETCH_TERM_REVISION_SUCCESS = 'FETCH_TERM_REVISION_SUCCESS';
const RESET_FETCH_TERM_REVISION_STATUS = 'FETCH_TERM_REVISION_STATUS';

const FETCH_ALL_TERM_REVISIONS_REQUEST = 'FETCH_ALL_TERM_REVISIONS_REQUEST';
const FETCH_ALL_TERM_REVISIONS_FAILURE = 'FETCH_ALL_TERM_REVISIONS_FAILURE';
const FETCH_ALL_TERM_REVISIONS_SUCCESS = 'FETCH_ALL_TERM_REVISIONS_SUCCESS';

const FETCH_TERM_REVISION_ITEMS_REQUEST = 'FETCH_TERM_REVISION_ITEMS_REQUEST';
const FETCH_TERM_REVISION_ITEMS_FAILURE = 'FETCH_TERM_REVISION_ITEMS_FAILURE';
const FETCH_TERM_REVISION_ITEMS_SUCCESS = 'FETCH_TERM_REVISION_ITEMS_SUCCESS';

const FETCH_TERMS_FOR_HOMEPAGE_REQUEST = 'FETCH_TERMS_FOR_HOMEPAGE_REQUEST';
const FETCH_TERMS_FOR_HOMEPAGE_FAILURE = 'FETCH_TERMS_FOR_HOMEPAGE_FAILURE';
const FETCH_TERMS_FOR_HOMEPAGE_SUCCESS = 'FETCH_TERMS_FOR_HOMEPAGE_SUCCESS';

const FETCH_EFFECTIVE_TERMS_BY_SKU_REQUEST = 'FETCH_EFFECTIVE_TERMS_BY_SKU_REQUEST';
const FETCH_EFFECTIVE_TERMS_BY_SKU_FAILURE = 'FETCH_EFFECTIVE_TERMS_BY_SKU_FAILURE';
const FETCH_EFFECTIVE_TERMS_BY_SKU_SUCCESS = 'FETCH_EFFECTIVE_TERMS_BY_SKU_SUCCESS';

const FETCH_TERMS_BY_SKU_REQUEST = 'FETCH_TERMS_BY_SKU_REQUEST';
const FETCH_TERMS_BY_SKU_FAILURE = 'FETCH_TERMS_BY_SKU_FAILURE';
const FETCH_TERMS_BY_SKU_SUCCESS = 'FETCH_TERMS_BY_SKU_SUCCESS';

const UPDATE_TERM_STATUS_REQUEST = 'UPDATE_TERM_STATUS_REQUEST';
const UPDATE_TERM_STATUS_FAILURE = 'UPDATE_TERM_STATUS_FAILURE';
const UPDATE_TERM_STATUS_SUCCESS = 'UPDATE_TERM_STATUS_SUCCESS';
const RESET_UPDATE_TERM_STATUS_STATUS = 'RESET_UPDATE_TERM_STATUS_STATUS';

const UPDATE_TERM_ITEMS_REQUEST = 'UPDATE_TERM_ITEMS_REQUEST';
const UPDATE_TERM_ITEMS_FAILURE = 'UPDATE_TERM_ITEMS_FAILURE';
const UPDATE_TERM_ITEMS_SUCCESS = 'UPDATE_TERM_ITEMS_SUCCESS';
const RESET_UPDATE_TERM_ITEMS_STATUS = 'RESET_UPDATE_TERM_ITEMS_STATUS';

const CREATE_PRICING_TERM_REQUEST = 'CREATE_PRICING_TERM_REQUEST';
const CREATE_PRICING_TERM_FAILURE = 'CREATE_PRICING_TERM_FAILURE';
const CREATE_PRICING_TERM_SUCCESS = 'CREATE_PRICING_TERM_SUCCESS';
const RESET_CREATE_PRICING_TERM_STATUS = 'RESET_CREATE_PRICING_TERM_STATUS';

const CREATE_SHIPPING_TERM_REQUEST = 'CREATE_SHIPPING_TERM_REQUEST';
const CREATE_SHIPPING_TERM_FAILURE = 'CREATE_SHIPPING_TERM_FAILURE';
const CREATE_SHIPPING_TERM_SUCCESS = 'CREATE_SHIPPING_TERM_SUCCESS';

const SET_HOMEPAGE_FILTER_TEXT = 'SET_HOMEPAGE_FILTER_TEXT';
const SKU_INFO_MAP_BATCH_RECEIVED = 'SKU_INFO_MAP_BATCH_RECEIVED';

const FETCH_PRICE_MODEL_REQUEST = 'FETCH_PRICE_MODEL_REQUEST';
const FETCH_PRICE_MODEL_FAILURE = 'FETCH_PRICE_MODEL_FAILURE';
const FETCH_PRICE_MODEL_SUCCESS = 'FETCH_PRICE_MODEL_SUCCESS';

const FETCH_PRICE_MODELS_REQUEST = 'FETCH_PRICE_MODELS_REQUEST';
const FETCH_PRICE_MODELS_FAILURE = 'FETCH_PRICE_MODELS_FAILURE';
const FETCH_PRICE_MODELS_SUCCESS = 'FETCH_PRICE_MODELS_SUCCESS';
const FETCH_PRICE_MODELS_FOR_TERM_ITEMS_SUCCESS = 'FETCH_PRICE_MODELS_FOR_TERM_ITEMS_SUCCESS';

const FETCH_SKU_RULESET_LOADING_FAILED = 'FETCH_SKU_RULESET_LOADING_FAILED';
const FETCH_SKU_RULESET_LOADING_SUCCESS = 'FETCH_SKU_RULESET_LOADING_SUCCESS';

// - ACTION CREATORS
const fetchTermRevisionRequest = () => ({ type: FETCH_TERM_REVISION_REQUEST });
const fetchTermRevisionFailure = payload => ({ type: FETCH_TERM_REVISION_FAILURE, payload });
const fetchTermRevisionSuccess = payload => ({ type: FETCH_TERM_REVISION_SUCCESS, payload });
const resetFetchTermRevisionStatus = () => ({ type: RESET_FETCH_TERM_REVISION_STATUS });

const fetchAllTermRevisionsRequest = () => ({ type: FETCH_ALL_TERM_REVISIONS_REQUEST });
const fetchAllTermRevisionsFailure = payload => ({ type: FETCH_ALL_TERM_REVISIONS_FAILURE, payload });
const fetchAllTermRevisionsSuccess = payload => ({ type: FETCH_ALL_TERM_REVISIONS_SUCCESS, payload });

const fetchTermRevisionItemsRequest = () => ({ type: FETCH_TERM_REVISION_ITEMS_REQUEST });
const fetchTermRevisionItemsFailure = payload => ({ type: FETCH_TERM_REVISION_ITEMS_FAILURE, payload });
const fetchTermRevisionItemsSuccess = payload => ({ type: FETCH_TERM_REVISION_ITEMS_SUCCESS, payload });

const fetchTermsForHomePageRequest = () => ({ type: FETCH_TERMS_FOR_HOMEPAGE_REQUEST });
const fetchTermsForHomePageFailure = payload => ({ type: FETCH_TERMS_FOR_HOMEPAGE_FAILURE, payload });
const fetchTermsForHomePageSuccess = payload => ({ type: FETCH_TERMS_FOR_HOMEPAGE_SUCCESS, payload });

const fetchEffectiveTermsBySkuRequest = () => ({ type: FETCH_EFFECTIVE_TERMS_BY_SKU_REQUEST });
const fetchEffectiveTermsBySkuFailure = payload => ({ type: FETCH_EFFECTIVE_TERMS_BY_SKU_FAILURE, payload });
const fetchEffectiveTermsBySkuSuccess = payload => ({ type: FETCH_EFFECTIVE_TERMS_BY_SKU_SUCCESS, payload });

const fetchTermsBySkuRequest = () => ({ type: FETCH_TERMS_BY_SKU_REQUEST });
const fetchTermsBySkuFailure = payload => ({ type: FETCH_TERMS_BY_SKU_FAILURE, payload });
const fetchTermsBySkuSuccess = payload => ({ type: FETCH_TERMS_BY_SKU_SUCCESS, payload });

const updateTermStatusRequest = () => ({ type: UPDATE_TERM_STATUS_REQUEST });
const updateTermStatusFailure = payload => ({ type: UPDATE_TERM_STATUS_FAILURE, payload });
const updateTermStatusSuccess = payload => ({ type: UPDATE_TERM_STATUS_SUCCESS, payload });
const resetUpdateTermStatusStatus = () => ({ type: RESET_UPDATE_TERM_STATUS_STATUS });

const createPricingTermRequest = () => ({ type: CREATE_PRICING_TERM_REQUEST });
const createPricingTermFailure = payload => ({ type: CREATE_PRICING_TERM_FAILURE, payload });
const createPricingTermSuccess = payload => ({ type: CREATE_PRICING_TERM_SUCCESS, payload });

const createShippingTermRequest = () => ({ type: CREATE_SHIPPING_TERM_REQUEST });
const createShippingTermFailure = payload => ({ type: CREATE_SHIPPING_TERM_FAILURE, payload });
const createShippingTermSuccess = payload => ({ type: CREATE_SHIPPING_TERM_SUCCESS, payload });

const updateTermItemsRequest = () => ({ type: UPDATE_TERM_ITEMS_REQUEST });
const updateTermItemsFailure = payload => ({ type: UPDATE_TERM_ITEMS_FAILURE, payload });
const updateTermItemsSuccess = payload => ({ type: UPDATE_TERM_ITEMS_SUCCESS, payload });
const resetUpdateTermItemsStatus = () => ({ type: RESET_UPDATE_TERM_ITEMS_STATUS });

const fetchPriceModelRequest = payload => ({ type: FETCH_PRICE_MODEL_REQUEST, payload });
const fetchPriceModelFailure = payload => ({ type: FETCH_PRICE_MODEL_FAILURE, payload });
const fetchPriceModelSuccess = payload => ({ type: FETCH_PRICE_MODEL_SUCCESS, payload });

const fetchPriceModelsRequest = payload => ({ type: FETCH_PRICE_MODELS_REQUEST, payload });
const fetchPriceModelsFailure = payload => ({ type: FETCH_PRICE_MODELS_FAILURE, payload });
const fetchPriceModelsSuccess = payload => ({ type: FETCH_PRICE_MODELS_SUCCESS, payload });
const fetchPriceModelsForTermItemsSuccess = payload => ({ type: FETCH_PRICE_MODELS_FOR_TERM_ITEMS_SUCCESS, payload });

const updateSkuRuleSetLoadingFailed = payload => ({ type: FETCH_SKU_RULESET_LOADING_FAILED, payload });
const updateSkuRuleSetLoadingSuccess = () => ({ type: FETCH_SKU_RULESET_LOADING_SUCCESS });

const fetchLatestTermRevision = termId => dispatch => {
  return dispatch(fetchTermRevision(termId, 'latest'));
};

const fetchTermRevision = (termId, revisionId) => (dispatch, getState) => {
  const state = getState();
  const cachedTermRevision = getTermRevision(state, termId, revisionId);
  if (revisionId !== 'latest' && cachedTermRevision) {
    dispatch(fetchTermRevisionSuccess(cachedTermRevision));
    return Promise.resolve(cachedTermRevision);
  }

  dispatch(fetchTermRevisionRequest());

  return pricingTermApi
    .fetchTermRevision(termId, revisionId)
    .then(response => dispatch(fetchTermRevisionSuccess(response)))
    .catch(err => {
      dispatch(fetchTermRevisionFailure(err));
      dispatch(createApiErrorNotificationAction(err));
    });
};

const fetchAllTermRevisions = termId => (dispatch, getState) => {
  dispatch(fetchAllTermRevisionsRequest());

  pricingTermApi
    .fetchAllTermRevisions(termId)
    .then(response => dispatch(fetchAllTermRevisionsSuccess(response)))
    .catch(err => {
      dispatch(fetchAllTermRevisionsFailure(err));
      dispatch(createApiErrorNotificationAction(err));
    });
};

const fetchTermRevisionItems = (termId, revisionId, fetchSkuInfo = true, pageSize = MAX_INT) => (
  dispatch,
  getState
) => {
  const state = getState();
  const cachedTermRevision = getTermRevisionItems(state, termId, revisionId);
  if (cachedTermRevision) {
    dispatch(fetchTermRevisionItemsSuccess(cachedTermRevision));
    return Promise.resolve(cachedTermRevision);
  }

  dispatch(fetchTermRevisionItemsRequest());

  return pricingTermApi
    .fetchTermRevisionItems(termId, revisionId, 0, pageSize)
    .then(response => {
      dispatch(fetchTermRevisionItemsSuccess({ termId, revisionId, items: response.items }));
      if (fetchSkuInfo) {
        dispatch(fetchTermRevisionItemsSkuInfo(response.items));
      }

      return response.items;
    })
    .catch(err => {
      dispatch(fetchTermRevisionItemsFailure(err));
      dispatch(createApiErrorNotificationAction(err));
      throw err;
    });
};

const fetchTermRevisionItemsSkuInfo = items => async (dispatch, getState) => {
  const state = getState();

  const skuInfoMap = getSkuInfoBySku(state);

  // filter out the skus that are already in cache to avoid fetching them again from mcp sku search service.
  const skusRequestedToFetch = items.map(i => i.sku);
  const skusInCache = new Set(Object.keys(skuInfoMap));
  const skusToFetch = skusRequestedToFetch.filter(sku => !skusInCache.has(sku));

  if (skusToFetch.length === 0) {
    return;
  }

  // DEV NOTE: lazyDispatch and flushQueueAndDispatch helper functions are used to batch actions before dispatching.
  // This is needed because in the case of fetching 30000 sku information, we make 60 calls to SkuSearch service (500 skus per call).
  // And if we dispatch an action after every call, then we will be re-rendering 60 times which causes lag, redux dev tools to crash, and too many unnecessary updates.
  // So I decided to batch these 60 call actions into groups of 10, so we create 6 actions instead.
  // This should be fine because the maximum amount of skus we have in a term around 30000.
  // We can make this more generic and move it into an utility function in the future.
  let lazyDispatchQueue = [];

  const lazyDispatch = skuMap => {
    if (lazyDispatchQueue.length >= 10) {
      flushQueueAndDispatch();
    } else {
      lazyDispatchQueue.push(skuMap);
    }
  };

  const flushQueueAndDispatch = () => {
    if (lazyDispatchQueue.length === 0) {
      return;
    }

    const batchedSkuMap = lazyDispatchQueue.reduce((combinedMap, o, i) => {
      return { ...combinedMap, ...o };
    }, {});

    // empty the lazy dispatch queue
    lazyDispatchQueue = [];

    dispatch({ type: SKU_INFO_MAP_BATCH_RECEIVED, payload: batchedSkuMap });
  };

  await getSkuInfoMapWithSkuList(
    skusToFetch,
    auth.getAccessToken,
    false,
    true,
    500,
    5,
    () => 'fetching sku info map',
    ({ skuMap }) => lazyDispatch(skuMap)
  );

  flushQueueAndDispatch();
};

const fetchTermsForHomePage = () => dispatch => {
  dispatch(fetchTermsForHomePageRequest());

  pricingTermApi
    .fetchTermsForHomePage()
    .then(response => dispatch(fetchTermsForHomePageSuccess(response)))
    .catch(err => {
      dispatch(fetchTermsForHomePageFailure(err));
      dispatch(createApiErrorNotificationAction(err));
    });
};

const fetchEffectiveTermsBySku = sku => (dispatch, getState) => {
  dispatch(fetchEffectiveTermsBySkuRequest());

  const state = getState();

  const lastFetchedTime = (state.contractV1.terms.effectiveTermsBySku = get(
    state,
    `terms.effectiveTermsBySku.${sku}.lastFetched`
  ));

  const currentTime = Date.now();

  // if it has been more than a minute since last fetched
  const cacheIsStale = !lastFetchedTime || (lastFetchedTime - currentTime) / 1000 > 60;
  if (cacheIsStale) {
    pricingTermApi
      .getEffectiveTerms({ sku })
      .then(response => dispatch(fetchEffectiveTermsBySkuSuccess({ sku, data: response, fetchedTime: Date.now() })))
      .catch(err => {
        dispatch(fetchEffectiveTermsBySkuFailure(err));
        dispatch(createApiErrorNotificationAction(err));
      });
  }
};

const fetchTermsBySku = sku => (dispatch, getState) => {
  dispatch(fetchTermsBySkuRequest());

  const state = getState();

  const lastFetchedTime = (state.contractV1.terms.CKsBySku = get(state, `terms.CKsBySku.${sku}.lastFetched`));

  const currentTime = Date.now();

  // if it has been more than a minute since last fetched
  const cacheIsStale = !lastFetchedTime || (lastFetchedTime - currentTime) / 1000 > 60;
  if (cacheIsStale) {
    return pricingTermApi
      .fetchTermsForHomePage([sku])
      .then(response => {
        dispatch(fetchTermsBySkuSuccess({ sku, data: response, fetchedTime: Date.now() }));
        return response;
      })
      .catch(err => {
        dispatch(fetchTermsBySkuFailure(err));
        dispatch(createApiErrorNotificationAction(err));
        throw err;
      });
  }

  return Promise.resolve();
};

const FETCH_TERMS_BY_BUYER_SELLER_REQUEST = 'FETCH_TERMS_BY_BUYER_SELLER_REQUEST';
const FETCH_TERMS_BY_BUYER_SELLER_SUCCESS = 'FETCH_TERMS_BY_BUYER_SELLER_SUCCESS';
const FETCH_TERMS_BY_BUYER_SELLER_FAILURE = 'FETCH_TERMS_BY_BUYER_SELLER_FAILURE';

// TODO: Fetching and filtering down for buyer flow's use case. Make it more generic when needed.
const fetchTermsByBuyerSeller = (buyer, seller) => {
  if (!buyer || !seller || !buyer.id || !buyer.type || !seller.id || !seller.type) {
    throw new Error('Buyer and seller are required with id and type fields');
  }
  return {
    types: [
      FETCH_TERMS_BY_BUYER_SELLER_REQUEST,
      FETCH_TERMS_BY_BUYER_SELLER_SUCCESS,
      FETCH_TERMS_BY_BUYER_SELLER_FAILURE
    ],
    callAPI: () =>
      pricingTermApi.fetchTermsForHomePage(null, buyer, seller).then(res =>
        flow(
          fp.filter(
            term =>
              term.status !== TermStatus.TERMINATED &&
              term.status !== TermStatus.REJECTED &&
              term.type === TermType.LIST_PRICE &&
              !isTermExpired(term.effectiveEndDate)
          ),
          fp.orderBy(['termId', 'revisionId'], ['asc', 'desc']),
          fp.uniqBy('termId')
        )(res)
      ),
    hooks: null,
    extraPayload: {
      buyer,
      seller
    }
  };
};

const setHomePageFilterText = text => (dispatch, getState) => {
  dispatch({ type: SET_HOMEPAGE_FILTER_TEXT, payload: text });

  if (text && !getIsHomePageLoading(getState())) {
    dispatch(fetchEffectiveTermsBySku(text));
    dispatch(fetchTermsBySku(text));
  }
};

const updateTermStatus = (termId, revisionId, toStatus, noNotificationDispatch = false) => (dispatch, getState) => {
  const notificationDispatch = noNotificationDispatch ? () => {} : dispatch;

  dispatch(updateTermStatusRequest());
  let updatingNotification = notificationDispatch(
    createApiLoadNotificationAction('Updating Contract', 'The contract is getting updated...')
  );

  return pricingTermApi
    .updateTermStatus(termId, revisionId, toStatus)
    .then(response => {
      dispatch(updateTermStatusSuccess(response));
      dispatch(termsV2Actions.resetTermsCache);
      const buttons = createNotificationButtons('View', () =>
        history.push(`/${response.termId}/${response.revisionId}`)
      );
      notificationDispatch(
        createFinishApiLoadNotificationAction({
          title: 'Contract Updated',
          notificationHandle: updatingNotification,
          message: 'The contract has been updated.',
          isSuccess: true,
          successButtons: buttons
        })
      );
      return response;
    })
    .catch(err => {
      // NOTE (brlee): If I find myself doing this kind of error checking too much in the action layer, to reduce the complexity and increase flexibility, I want to think about moving this logic elsewhere.
      // Perhaps create another layer on top of `makeResponse` that does the error plumbing for us?
      // Also I want to do things like notification chaining where you can pass in a notification handle to another function like update status, that handle will be used to display the new notification rather than creating new ones
      // E.g. a notification flow where it goes... Term items patched => term status updated => another step done => everything finished
      let errorMsg = '';
      const statusCode = err && err.statusCode;
      const buttons = createNotificationButtons('View', () => history.push(`/${termId}/${revisionId}`));

      switch (statusCode) {
        case 400:
          const parsedError = get(err, 'response.extensions.innerDetails.validationErrors[0]');
          if (parsedError && parsedError.message === 'Not all skus have list prices set up.') {
            const csvData = parsedError.value.split(',').map(sku => [sku]);

            const downloadCsvLink = (
              <CSVLink data={csvData} filename={`SKUs_missing_list_price_${new Date().toISOString()}.csv`}>
                click here
              </CSVLink>
            );

            errorMsg = (
              <span>
                {parsedError.message} Please {downloadCsvLink} to review the SKUs that are missing catalog price.
              </span>
            );
          }
          break;
        case 403:
          errorMsg = 'You do not have the correct permissions to update this contract.';
          break;
        case 409:
          errorMsg = 'The contract has already been updated and therefore cannot be updated.';
          break;
        default:
          errorMsg = "Updating contract's status was not successful.";
          break;
      }

      dispatch(updateTermStatusFailure(err));
      notificationDispatch(
        createFinishApiLoadNotificationAction({
          notificationHandle: updatingNotification,
          title: 'Contract Not Updated',
          message: errorMsg,
          isSuccess: false,
          notSuccessButtons: buttons,
          errObject: err
        })
      );
      throw err;
    });
};

const updateTermItems = (termId, revisionId, skus, priceModel = null) => dispatch => {
  dispatch(updateTermItemsRequest());
  let patchNotificationHandle = dispatch(
    createApiLoadNotificationAction('Saving Changes', 'The changes are being saved...')
  );

  let patchListPriceTermSucceeded = false;

  const patchApiPromise =
    priceModel && priceModel.id
      ? pricingTermApi.patchSpecialAndShippingPriceTermItems(termId, skus, priceModel)
      : pricingTermApi.patchListPriceTermItems(termId, skus);

  return (
    patchApiPromise
      .then(patchResponse => {
        patchListPriceTermSucceeded = true;
        dispatch(
          createFinishApiLoadNotificationAction({
            notificationHandle: patchNotificationHandle,
            title: 'Change Saved',
            message: 'The changes have been saved.',
            isSuccess: true
          })
        );
        return (
          dispatch(updateTermStatus(termId, revisionId, TermStatus.PENDING))
            // patch successful AND update term status successful
            .then(statusResponse => dispatch(updateTermItemsSuccess({ patchResponse, statusResponse })))
            // patch successful BUT update term status unsuccessful
            .catch(err => {
              dispatch(updateTermItemsFailure({ patchResponse, statusResponse: err }));
              throw err;
            })
        );
      })
      // patch unsuccessful
      .catch(err => {
        dispatch(updateTermItemsFailure(err));

        const statusCode = err.statusCode;
        const errorMsg =
          statusCode === 403
            ? `You do not have the correct permissions to edit this term.`
            : 'Something went wrong while updating the items.';

        // the exception for updateTermStatus could be caught here so only update when this exception is for patch.
        if (!patchListPriceTermSucceeded) {
          dispatch(
            createFinishApiLoadNotificationAction({
              notificationHandle: patchNotificationHandle,
              title: 'Change Not Saved',
              message: errorMsg,
              isSuccess: false,
              successButtons: null,
              errObject: err
            })
          );
        }

        throw err;
      })
  );
};

const createPricingTerm = ({
  pricingTermInfo,
  selectedSkus,
  initiator,
  acceptor,
  buyer,
  seller,
  toPendingStatus,
  gracePeriodApprovalDays
}) => dispatch => {
  dispatch(createPricingTermRequest());

  let creatingNotification = dispatch(
    createApiLoadNotificationAction('Creating Contract', 'The contract is getting created...')
  );

  const type = getTermType(pricingTermInfo.termType);
  const createPricingTerm =
    type === TermType.LIST_PRICE
      ? pricingTermApi.createListPriceTerm
      : type === TermType.SPECIAL_PRICE
        ? pricingTermApi.createSpecialPriceTerm
        : null;

  if (!createPricingTerm) {
    return;
  }

  return createPricingTerm({
    pricingTermInfo,
    selectedSkus,
    initiator,
    acceptor,
    buyer,
    seller,
    toPendingStatus,
    gracePeriodApprovalDays
  })
    .then(response => {
      dispatch(createPricingTermSuccess(response));
      dispatch(
        createFinishApiLoadNotificationAction({
          title: 'Contract Created',
          notificationHandle: creatingNotification,
          message: 'The contract has been created.',
          isSuccess: true
        })
      );
      return response;
    })
    .catch(err => {
      let errorMsg = '';
      const statusCode = err && err.statusCode;

      switch (statusCode) {
        case 400:
          const parsedError = get(err, 'response.extensions.innerDetails.validationErrors[0]');
          if (parsedError && parsedError.message === 'Not all skus have list prices set up.') {
            const csvData = parsedError.value.split(',').map(sku => [sku]);

            const downloadCsvLink = (
              <CSVLink data={csvData} filename={`SKUs_missing_list_price_${new Date().toISOString()}.csv`}>
                click here
              </CSVLink>
            );

            errorMsg = (
              <span>
                {parsedError.message} Please {downloadCsvLink} to review the SKUs that are missing catalog price.
              </span>
            );
          }
          break;
        case 403:
          errorMsg = 'You do not have the correct permissions to create this contract.';
          break;
        default:
          errorMsg = 'Creating contract was not successful.';
          break;
      }

      dispatch(createPricingTermFailure(err));
      dispatch(
        createFinishApiLoadNotificationAction({
          notificationHandle: creatingNotification,
          title: 'Contract Not Created',
          message: errorMsg,
          isSuccess: false,
          errObject: err
        })
      );
      throw err;
    });
};

const createShippingTerm = ({
  shippingTermInfo,
  selectedSkus,
  initiator,
  acceptor,
  buyer,
  seller,
  toPendingStatus,
  gracePeriodApprovalDays
}) => dispatch => {
  dispatch(createShippingTermRequest());

  let creatingNotification = dispatch(
    createApiLoadNotificationAction('Creating Contract', 'The contract is getting created...')
  );

  const type = getTermType(shippingTermInfo.termType);

  let createShippingTerm;
  switch (type) {
    case TermType.FREE_SHIPPING:
      createShippingTerm = pricingTermApi.createFreeShippingTerm;
      break;
    case TermType.SHIPPING_COST:
      createShippingTerm = pricingTermApi.createShippingCostTerm;
      break;
    case TermType.SHIPPING_PRICE:
      createShippingTerm = pricingTermApi.createShippingPriceTerm;
      break;
    default:
      break;
  }

  if (!createShippingTerm) {
    return;
  }

  return createShippingTerm({
    shippingTermInfo,
    selectedSkus,
    initiator,
    acceptor,
    buyer,
    seller,
    toPendingStatus,
    gracePeriodApprovalDays
  })
    .then(response => {
      dispatch(createShippingTermSuccess(response));
      dispatch(
        createFinishApiLoadNotificationAction({
          title: 'Contract Created',
          notificationHandle: creatingNotification,
          message: 'The contract has been created.',
          isSuccess: true
        })
      );
      return response;
    })
    .catch(err => {
      let errorMsg = '';
      const statusCode = err && err.statusCode;

      switch (statusCode) {
        case 403:
          errorMsg = 'You do not have the correct permissions to create this contract.';
          break;
        default:
          errorMsg = 'Creating contract was not successful.';
          break;
      }

      dispatch(createShippingTermFailure(err));
      dispatch(
        createFinishApiLoadNotificationAction({
          notificationHandle: creatingNotification,
          title: 'Contract Not Created',
          message: errorMsg,
          isSuccess: false,
          errObject: err
        })
      );
      throw err;
    });
};

const fetchPriceModel = (id, revisionId, noDispatch = false) => (dispatch, getState) => {
  // replace the dispatch function to an empty function if noDispatch is true.
  dispatch = noDispatch ? () => {} : dispatch;

  if (isNil(id) || isNil(revisionId)) {
    return Promise.reject('id and revision id cannot be null.');
  }

  const state = getState();
  const cachedPriceModel = getPriceModel(state, id, revisionId);
  if (cachedPriceModel) {
    return Promise.resolve(cachedPriceModel);
  }

  dispatch(fetchPriceModelRequest({ id, revisionId }));

  return productPriceApi
    .getPriceModelRevisionV3(id, revisionId)
    .then(pmResponse => {
      const consumablePriceModel = asConsumablePriceModel(pmResponse);
      dispatch(fetchPriceModelSuccess(consumablePriceModel));
      return consumablePriceModel;
    })
    .catch(err => {
      dispatch(fetchPriceModelFailure(err));
      dispatch(createApiErrorNotificationAction(err));
      throw err;
    });
};

const fetchPriceModelsForTermItems = (termItems, termId, revisionId) => (dispatch, getState) => {
  const termItemsList = termItems && Object.values(termItems);
  if (!termItemsList || !termItemsList.length) {
    return;
  }

  const priceModels = termItemsList
    .filter(({ priceModel }) => !isNil(priceModel) && !isNil(priceModel.id) && !isNil(priceModel.revisionId))
    .map(({ priceModel }) => ({
      id: priceModel.id,
      revisionId: priceModel.revisionId
    }));

  return dispatch(fetchPriceModels(priceModels))
    .then(response => dispatch(fetchPriceModelsForTermItemsSuccess({ response, termId, revisionId })))
    .catch(err => {
      // dispatch(createApiErrorNotificationAction(err));
      // mock the price model responses so we can still display something on the UI.
      const emptyPriceModelResponses = uniqBy(priceModels, pm => pm.id + pm.revisionId).map(pm => ({
        id: pm.id,
        revisionId: pm.revisionId,
        loadFailed: true
      }));
      dispatch(fetchPriceModelsForTermItemsSuccess({ response: emptyPriceModelResponses, termId, revisionId }));
      throw err;
    });
};

const fetchPriceModels = priceModels => (dispatch, getState) => {
  const uniquePriceModels = uniqBy(priceModels, pm => pm.id + pm.revisionId);

  const state = getState();
  const cachedPriceModelCKs = getPriceModelByCK(state);

  const priceModelsToFetch = uniquePriceModels.filter(pm => isNil(cachedPriceModelCKs[pm]));
  const priceModelsInCache = uniquePriceModels.filter(pm => !isNil(cachedPriceModelCKs[pm]));

  if (priceModelsToFetch.length === 0) {
    return Promise.resolve(priceModelsInCache);
  }

  dispatch(fetchPriceModelsRequest({ uniquePriceModels }));
  const priceModelFetchPromises = priceModelsToFetch.map(({ id, revisionId }) =>
    dispatch(fetchPriceModel(id, revisionId, true))
  );
  return Promise.all(priceModelFetchPromises)
    .then(pms => {
      const consumablePriceModels = pms.map(pm => asConsumablePriceModel(pm));
      dispatch(fetchPriceModelsSuccess(consumablePriceModels));
      return [...priceModelsInCache, ...consumablePriceModels];
    })
    .catch(err => {
      dispatch(fetchPriceModelsFailure(err));
      throw err;
    });
};

const setSkuRuleSetLoadingStatus = (skuInfo, isError, error) => dispatch => {
  isError
    ? dispatch(updateSkuRuleSetLoadingFailed({ isError, error, skuInfo }))
    : dispatch(updateSkuRuleSetLoadingSuccess({ isError: false }));
};

// helper function
// convert the raw price model api response to a more consumable format.
const asConsumablePriceModel = pm => {
  const { id, revisionId, name, description, currency, currencyOverride, seller, author, created } = pm;

  // already consumable so return the pm back
  if (pm.isConsumableForUI) {
    return pm;
  }

  const overrideCountries = [];
  const possibleCurrenciesSet = new Set([currency]);

  // flatten the override variables to a list of objects of countryCode and currency.
  if (
    currencyOverride &&
    currencyOverride.attributeClass === 'destination' &&
    currencyOverride.attributeKey === 'country'
  ) {
    for (let prv of currencyOverride.priceResultVariables) {
      possibleCurrenciesSet.add(prv.currency);
      for (let j in prv.attributeValues) {
        const countryCode = prv.attributeValues[j];
        overrideCountries.push({ countryCode, currency: prv.currency });
      }
    }
  }

  return {
    id,
    revisionId,
    name,
    description,
    currency,
    seller,
    // TODO: this seems like too much info; include if needed.
    // skus,
    author,
    created,
    overrideCountries,
    possibleCurrencies: [...possibleCurrenciesSet],
    isConsumableForUI: true
  };
};

export const actions = {
  fetchTermRevision,
  fetchLatestTermRevision,
  fetchAllTermRevisions,
  resetFetchTermRevisionStatus,
  fetchTermRevisionItems,
  fetchTermsForHomePage,
  setHomePageFilterText,
  updateTermStatus,
  resetUpdateTermStatusStatus,
  updateTermItems,
  resetUpdateTermItemsStatus,
  createPricingTerm,
  createShippingTerm,
  fetchPriceModel,
  fetchPriceModels,
  fetchPriceModelsForTermItems,
  fetchTermsByBuyerSeller,
  fetchTermsBySku,
  fetchEffectiveTermsBySku,
  setSkuRuleSetLoadingStatus,
  ...termsV2Actions
};

// - REDUCERS
function revisionIdsByTermId(state = {}, action) {
  switch (action.type) {
    case FETCH_TERMS_FOR_HOMEPAGE_SUCCESS:
    case fetchTermsForHomePage_SUCCESS:
    case FETCH_ALL_TERM_REVISIONS_SUCCESS:
    case FETCH_TERMS_BY_BUYER_SELLER_SUCCESS:
    case FETCH_TERMS_BY_SKU_SUCCESS:
      const terms = action.type === FETCH_TERMS_BY_SKU_SUCCESS ? action.payload.data : action.payload;
      const mergedRevisionIdsByTermId = terms.reduce((map, term) => {
        const currentRevisionIds = map[term.termId] || [];
        map[term.termId] = uniq([...currentRevisionIds, term.revisionId]);
        return map;
      }, state);

      return mergedRevisionIdsByTermId;

    case UPDATE_TERM_STATUS_SUCCESS:
    case updateTerm_SUCCESS:
    case FETCH_TERM_REVISION_SUCCESS:
    case CREATE_PRICING_TERM_SUCCESS:
    case CREATE_SHIPPING_TERM_SUCCESS:
      const term = action.payload;
      const currentRevisionIds = state[term.termId] || [];
      const newRevisionIds = uniq([...currentRevisionIds, term.revisionId]);
      return { ...state, [term.termId]: newRevisionIds };
    default:
      return state;
  }
}

function revisionsByCK(state = {}, action) {
  switch (action.type) {
    case FETCH_TERMS_FOR_HOMEPAGE_SUCCESS:
    case fetchTermsForHomePage_SUCCESS:
    case FETCH_ALL_TERM_REVISIONS_SUCCESS:
    case FETCH_TERMS_BY_BUYER_SELLER_SUCCESS:
    case FETCH_TERMS_BY_SKU_SUCCESS:
      const terms = action.type === FETCH_TERMS_BY_SKU_SUCCESS ? action.payload.data : action.payload;
      const revisionsByCK = terms.reduce((map, term) => {
        const CK = toCK(term.termId, term.revisionId);
        map[CK] = term;
        return map;
      }, {});

      // merge the two; override any keys with the result we got.
      return { ...state, ...revisionsByCK };
    case UPDATE_TERM_STATUS_SUCCESS:
    case updateTerm_SUCCESS:
    case FETCH_TERM_REVISION_SUCCESS:
    case CREATE_PRICING_TERM_SUCCESS:
    case CREATE_SHIPPING_TERM_SUCCESS:
      const CK = toCK(action.payload.termId, action.payload.revisionId);
      return { ...state, [CK]: action.payload };
    default:
      return state;
  }
}

function revisionItemsByCK(state = {}, action) {
  switch (action.type) {
    case FETCH_TERM_REVISION_ITEMS_SUCCESS:
      const { items, termId, revisionId } = action.payload;
      const CK = toCK(termId, revisionId);
      return { ...state, [CK]: items };
    default:
      return state;
  }
}

function CKsForHomePage(state = [], action) {
  switch (action.type) {
    case FETCH_TERMS_FOR_HOMEPAGE_SUCCESS:
    case fetchTermsForHomePage_SUCCESS:
      const terms = action.payload;
      return terms.map(term => toCK(term.termId, term.revisionId));
    default:
      return state;
  }
}

function skuInfoBySku(state = {}, action) {
  switch (action.type) {
    case SKU_INFO_MAP_BATCH_RECEIVED:
      const skuInfoBySku = action.payload;
      return { ...state, ...skuInfoBySku };
    default:
      return state;
  }
}

function isLoading(state = {}, action) {
  switch (action.type) {
    case FETCH_TERM_REVISION_REQUEST:
      return { ...state, FETCH_TERM_REVISION: true };
    case FETCH_TERM_REVISION_FAILURE:
    case FETCH_TERM_REVISION_SUCCESS:
    case RESET_FETCH_TERM_REVISION_STATUS:
      return { ...state, FETCH_TERM_REVISION: false };
    case FETCH_TERM_REVISION_ITEMS_REQUEST:
      return { ...state, FETCH_TERM_REVISION_ITEMS: true };
    case FETCH_TERM_REVISION_ITEMS_FAILURE:
    case FETCH_TERM_REVISION_ITEMS_SUCCESS:
      return { ...state, FETCH_TERM_REVISION_ITEMS: false };
    case FETCH_TERMS_FOR_HOMEPAGE_REQUEST:
    case fetchTermsForHomePage_REQUEST:
      return { ...state, FETCH_TERMS_FOR_HOMEPAGE: true };
    case FETCH_TERMS_FOR_HOMEPAGE_FAILURE:
    case fetchTermsForHomePage_FAILURE:
    case FETCH_TERMS_FOR_HOMEPAGE_SUCCESS:
    case fetchTermsForHomePage_SUCCESS:
      return { ...state, FETCH_TERMS_FOR_HOMEPAGE: false };
    case FETCH_EFFECTIVE_TERMS_BY_SKU_REQUEST:
      return { ...state, FETCH_EFFECTIVE_TERMS_BY_SKU: true };
    case FETCH_EFFECTIVE_TERMS_BY_SKU_FAILURE:
    case FETCH_EFFECTIVE_TERMS_BY_SKU_SUCCESS:
      return { ...state, FETCH_EFFECTIVE_TERMS_BY_SKU: false };
    case FETCH_TERMS_BY_SKU_REQUEST:
      return { ...state, FETCH_TERMS_BY_SKU: true };
    case FETCH_TERMS_BY_SKU_FAILURE:
    case FETCH_TERMS_BY_SKU_SUCCESS:
      return { ...state, FETCH_TERMS_BY_SKU: false };
    case UPDATE_TERM_STATUS_REQUEST:
    case updateTerm_REQUEST:
      return { ...state, UPDATE_TERM_STATUS: true };
    case UPDATE_TERM_STATUS_FAILURE:
    case updateTerm_FAILURE:
    case UPDATE_TERM_STATUS_SUCCESS:
    case updateTerm_SUCCESS:
    case RESET_UPDATE_TERM_STATUS_STATUS:
      return { ...state, UPDATE_TERM_STATUS: false };
    case UPDATE_TERM_ITEMS_REQUEST:
      return { ...state, UPDATE_TERM_ITEMS: true };
    case UPDATE_TERM_ITEMS_FAILURE:
    case UPDATE_TERM_ITEMS_SUCCESS:
    case RESET_UPDATE_TERM_ITEMS_STATUS:
      return { ...state, UPDATE_TERM_ITEMS: false };
    case CREATE_PRICING_TERM_REQUEST:
      return { ...state, CREATE_PRICE_TERM: true };
    case CREATE_PRICING_TERM_FAILURE:
    case CREATE_PRICING_TERM_SUCCESS:
    case RESET_CREATE_PRICING_TERM_STATUS:
      return { ...state, CREATE_PRICE_TERM: false };
    case CREATE_SHIPPING_TERM_REQUEST:
      return { ...state, CREATE_SHIPPING_TERM: true };
    case CREATE_SHIPPING_TERM_FAILURE:
    case CREATE_SHIPPING_TERM_SUCCESS:
      return { ...state, CREATE_SHIPPING_TERM: false };
    case FETCH_ALL_TERM_REVISIONS_REQUEST:
      return { ...state, FETCH_ALL_TERM_REVISIONS: true };
    case FETCH_ALL_TERM_REVISIONS_FAILURE:
    case FETCH_ALL_TERM_REVISIONS_SUCCESS:
      return { ...state, FETCH_ALL_TERM_REVISIONS: false };
    default:
      return state;
  }
}

function isLoaded(state = {}, action) {
  switch (action.type) {
    case FETCH_TERM_REVISION_REQUEST:
    case FETCH_TERM_REVISION_FAILURE:
    case RESET_FETCH_TERM_REVISION_STATUS:
      return { ...state, FETCH_TERM_REVISION: false };
    case FETCH_TERM_REVISION_SUCCESS:
      return { ...state, FETCH_TERM_REVISION: true };
    case FETCH_TERM_REVISION_ITEMS_REQUEST:
    case FETCH_TERM_REVISION_ITEMS_FAILURE:
      return { ...state, FETCH_TERM_REVISION_ITEMS: false };
    case FETCH_TERM_REVISION_ITEMS_SUCCESS:
      return { ...state, FETCH_TERM_REVISION_ITEMS: true };
    case FETCH_TERMS_FOR_HOMEPAGE_REQUEST:
    case fetchTermsForHomePage_REQUEST:
    case FETCH_TERMS_FOR_HOMEPAGE_FAILURE:
    case fetchTermsForHomePage_FAILURE:
      return { ...state, FETCH_TERMS_FOR_HOMEPAGE: false };
    case FETCH_TERMS_FOR_HOMEPAGE_SUCCESS:
    case fetchTermsForHomePage_SUCCESS:
      return { ...state, FETCH_TERMS_FOR_HOMEPAGE: true };
    case FETCH_EFFECTIVE_TERMS_BY_SKU_REQUEST:
    case FETCH_EFFECTIVE_TERMS_BY_SKU_FAILURE:
      return { ...state, FETCH_EFFECTIVE_TERMS_BY_SKU: false };
    case FETCH_EFFECTIVE_TERMS_BY_SKU_SUCCESS:
      return { ...state, FETCH_EFFECTIVE_TERMS_BY_SKU: true };
    case FETCH_TERMS_BY_SKU_REQUEST:
    case FETCH_TERMS_BY_SKU_FAILURE:
      return { ...state, FETCH_TERMS_BY_SKU: false };
    case FETCH_TERMS_BY_SKU_SUCCESS:
      return { ...state, FETCH_TERMS_BY_SKU: true };
    case UPDATE_TERM_STATUS_REQUEST:
    case updateTerm_REQUEST:
    case UPDATE_TERM_STATUS_FAILURE:
    case updateTerm_FAILURE:
    case RESET_UPDATE_TERM_STATUS_STATUS:
      return { ...state, UPDATE_TERM_STATUS: false };
    case UPDATE_TERM_STATUS_SUCCESS:
    case updateTerm_SUCCESS:
      return { ...state, UPDATE_TERM_STATUS: true };
    case UPDATE_TERM_ITEMS_REQUEST:
    case UPDATE_TERM_ITEMS_FAILURE:
    case RESET_UPDATE_TERM_ITEMS_STATUS:
      return { ...state, UPDATE_TERM_ITEMS: false };
    case UPDATE_TERM_ITEMS_SUCCESS:
      return { ...state, UPDATE_TERM_ITEMS: true };
    case CREATE_PRICING_TERM_REQUEST:
    case CREATE_PRICING_TERM_FAILURE:
    case RESET_CREATE_PRICING_TERM_STATUS:
      return { ...state, CREATE_PRICE_TERM: false };
    case CREATE_PRICING_TERM_SUCCESS:
      return { ...state, CREATE_PRICE_TERM: true };
    case CREATE_SHIPPING_TERM_REQUEST:
    case CREATE_SHIPPING_TERM_FAILURE:
    case CREATE_SHIPPING_TERM_SUCCESS:
      return { ...state, CREATE_SHIPPING_TERM: true };
    case FETCH_ALL_TERM_REVISIONS_REQUEST:
    case FETCH_ALL_TERM_REVISIONS_FAILURE:
      return { ...state, FETCH_ALL_TERM_REVISIONS: false };
    case FETCH_ALL_TERM_REVISIONS_SUCCESS:
      return { ...state, FETCH_ALL_TERM_REVISIONS: true };
    default:
      return state;
  }
}

function hasError(state = {}, action) {
  switch (action.type) {
    case FETCH_TERM_REVISION_REQUEST:
    case FETCH_TERM_REVISION_SUCCESS:
    case RESET_FETCH_TERM_REVISION_STATUS:
      return { ...state, FETCH_TERM_REVISION: false };
    case FETCH_TERM_REVISION_FAILURE:
      return { ...state, FETCH_TERM_REVISION: true };
    case FETCH_TERM_REVISION_ITEMS_REQUEST:
    case FETCH_TERM_REVISION_ITEMS_SUCCESS:
      return { ...state, FETCH_TERM_REVISION_ITEMS: false };
    case FETCH_TERM_REVISION_ITEMS_FAILURE:
      return { ...state, FETCH_TERM_REVISION_ITEMS: true };
    case FETCH_TERMS_FOR_HOMEPAGE_REQUEST:
    case fetchTermsForHomePage_REQUEST:
    case FETCH_TERMS_FOR_HOMEPAGE_SUCCESS:
    case fetchTermsForHomePage_SUCCESS:
      return { ...state, FETCH_TERMS_FOR_HOMEPAGE: false };
    case FETCH_TERMS_FOR_HOMEPAGE_FAILURE:
    case fetchTermsForHomePage_FAILURE:
      return { ...state, FETCH_TERMS_FOR_HOMEPAGE: true };
    case FETCH_EFFECTIVE_TERMS_BY_SKU_REQUEST:
    case FETCH_EFFECTIVE_TERMS_BY_SKU_SUCCESS:
      return { ...state, FETCH_EFFECTIVE_TERMS_BY_SKU: false };
    case FETCH_EFFECTIVE_TERMS_BY_SKU_FAILURE:
      return { ...state, FETCH_EFFECTIVE_TERMS_BY_SKU: true };
    case FETCH_TERMS_BY_SKU_REQUEST:
    case FETCH_TERMS_BY_SKU_SUCCESS:
      return { ...state, FETCH_TERMS_BY_SKU: false };
    case FETCH_TERMS_BY_SKU_FAILURE:
      return { ...state, FETCH_TERMS_BY_SKU: true };
    case UPDATE_TERM_STATUS_REQUEST:
    case updateTerm_REQUEST:
    case UPDATE_TERM_STATUS_SUCCESS:
    case updateTerm_SUCCESS:
    case RESET_UPDATE_TERM_STATUS_STATUS:
      return { ...state, UPDATE_TERM_STATUS: false };
    case UPDATE_TERM_STATUS_FAILURE:
    case updateTerm_FAILURE:
      return { ...state, UPDATE_TERM_STATUS: true };
    case UPDATE_TERM_ITEMS_REQUEST:
    case UPDATE_TERM_ITEMS_SUCCESS:
    case RESET_UPDATE_TERM_ITEMS_STATUS:
      return { ...state, UPDATE_TERM_ITEMS: false };
    case UPDATE_TERM_ITEMS_FAILURE:
      return { ...state, UPDATE_TERM_ITEMS: true };
    case CREATE_PRICING_TERM_REQUEST:
    case CREATE_PRICING_TERM_SUCCESS:
    case RESET_CREATE_PRICING_TERM_STATUS:
      return { ...state, CREATE_PRICE_TERM: false };
    case CREATE_PRICING_TERM_FAILURE:
      return { ...state, CREATE_PRICE_TERM: true };
    case CREATE_SHIPPING_TERM_REQUEST:
    case CREATE_SHIPPING_TERM_SUCCESS:
    case CREATE_SHIPPING_TERM_FAILURE:
      return { ...state, CREATE_SHIPPING_TERM: true };
    case FETCH_ALL_TERM_REVISIONS_REQUEST:
    case FETCH_ALL_TERM_REVISIONS_SUCCESS:
      return { ...state, FETCH_ALL_TERM_REVISIONS: false };
    case FETCH_ALL_TERM_REVISIONS_FAILURE:
      return { ...state, FETCH_ALL_TERM_REVISIONS: true };
    default:
      return state;
  }
}

function skuRuleSetLoadingStatus(state = {}, action) {
  switch (action.type) {
    case FETCH_SKU_RULESET_LOADING_SUCCESS:
      return { ...state, FETCH_SKU_RULESET_LOADING_STATUS: action.payload };
    case FETCH_SKU_RULESET_LOADING_FAILED:
      return { ...state, FETCH_SKU_RULESET_LOADING_STATUS: action.payload };
    default:
      return state;
  }
}

function effectiveTermsBySku(state = {}, action) {
  switch (action.type) {
    case FETCH_EFFECTIVE_TERMS_BY_SKU_REQUEST:
    case FETCH_EFFECTIVE_TERMS_BY_SKU_FAILURE:
      return {};
    case FETCH_EFFECTIVE_TERMS_BY_SKU_SUCCESS:
      return {
        ...state,
        [action.payload.sku]: {
          CKs: action.payload.data.map(t => toCK(t.termId, t.revisionId)),
          lastFetched: action.payload.fetchedTime
        }
      };
    default:
      return state;
  }
}

function CKsBySku(state = {}, action) {
  switch (action.type) {
    case FETCH_TERMS_BY_SKU_REQUEST:
    case FETCH_TERMS_BY_SKU_FAILURE:
      return {};
    case FETCH_TERMS_BY_SKU_SUCCESS:
      return {
        ...state,
        [action.payload.sku]: {
          CKs: action.payload.data.map(t => toCK(t.termId, t.revisionId)),
          lastFetched: action.payload.fetchedTime
        }
      };
    default:
      return state;
  }
}

// TODO: currently this reducer will only work with FETCH_TERMS_BY_BUYER_SELLER action.
// This means if there is a use case where this gets consumed elsewhere without calling out to the action, the data will be empty.
// This reducer is here purely for caching the buyer seller fetch call.
// If grabbing terms by buyer and seller is needed without the action call, and instead with the existing terms (i.e. `termsByCK`),
// we should create a selector that dynamically generates the buyer seller mapping.
function CKsByBuyerSellerCK(state = {}, action) {
  switch (action.type) {
    case FETCH_TERMS_BY_BUYER_SELLER_SUCCESS:
      const { buyer, seller } = action.extraPayload;
      const buyerSellerCK = toCK(buyer.type, buyer.id, seller.type, seller.id);
      return {
        ...state,
        [buyerSellerCK]: {
          CKs: action.payload.map(t => toCK(t.termId, t.revisionId)),
          lastFetched: action.time
        }
      };
    default:
      return state;
  }
}

function homepageFilterText(state = '', action) {
  switch (action.type) {
    case SET_HOMEPAGE_FILTER_TEXT:
      return action.payload;
    default:
      return state;
  }
}

function newTermCKs(state = [], action) {
  switch (action.type) {
    case UPDATE_TERM_STATUS_SUCCESS:
    case updateTerm_SUCCESS:
    case CREATE_PRICING_TERM_SUCCESS:
    case CREATE_SHIPPING_TERM_SUCCESS:
      const { termId, revisionId } = action.payload;
      return [...state, toCK(termId, revisionId)];
    default:
      return state;
  }
}

function priceModelByCK(state = {}, action) {
  switch (action.type) {
    case FETCH_PRICE_MODEL_SUCCESS:
      const CK = toCK(action.payload.id, action.payload.revisionId);
      return { ...state, [CK]: action.payload };
    case FETCH_PRICE_MODELS_SUCCESS:
      const ckMapping = action.payload.reduce((map, pm) => {
        const CK = toCK(pm.id, pm.revisionId);
        return { ...map, [CK]: pm };
      }, {});
      return { ...state, ...ckMapping };
    default:
      return state;
  }
}

function priceModelCKsByTermCK(state = {}, action) {
  switch (action.type) {
    case FETCH_PRICE_MODELS_FOR_TERM_ITEMS_SUCCESS: {
      const { termId, revisionId, response } = action.payload;
      const termCK = toCK(termId, revisionId);
      const pmCKs = response.map(res => toCK(res.id, res.revisionId));
      return { ...state, [termCK]: pmCKs };
    }
    case fetchTermRevisionPriceModels_SUCCESS: {
      const { termId, revisionId } = action.extraPayload;
      const termCK = toCK(termId, revisionId);
      const pmCKs = action.payload.priceModels.map(res => toCK(res.id, res.revisionId));
      return { ...state, [termCK]: pmCKs };
    }
    default:
      return state;
  }
}

const termsReducer = combineReducers({
  revisionIdsByTermId,
  revisionsByCK,
  revisionItemsByCK,
  CKsForHomePage,
  isLoading,
  isLoaded,
  hasError,
  effectiveTermsBySku,
  CKsBySku,
  homepageFilterText,
  skuInfoBySku,
  newTermCKs,
  priceModelByCK,
  priceModelCKsByTermCK,
  CKsByBuyerSellerCK,
  skuRuleSetLoadingStatus,
  ...termsV2Reducers
});
export default termsReducer;

// - SELECTORS

const fromCK = CK => {
  const delimiterIdx = CK.indexOf('@');
  return { id: CK.substring(0, delimiterIdx), revisionId: CK.substring(delimiterIdx + 1) };
};

const getTermRevisionsByCK = state => {
  return state.contractV1.terms.revisionsByCK; // { 1: {...}, 2: {...}}
};

const getRevisionItemsByCK = state => {
  return state.contractV1.terms.revisionItemsByCK;
};

const getRevisionIdsByTermId = state => state.contractV1.terms.revisionIdsByTermId;

const getLatestRevisionByTermId = createCachedSelector(
  getRevisionIdsByTermId,
  getTermRevisionsByCK,
  (revisionIdsByTermId, termRevisionsByCK) => {
    return Object.keys(revisionIdsByTermId).reduce((map, termId) => {
      const latestRevisionId = Math.max(...revisionIdsByTermId[termId]);
      map[termId] = termRevisionsByCK[toCK(termId, latestRevisionId)];
      return map;
    }, {});
  }
)((state, termId) => termId);

const getAllRevisions = createCachedSelector(
  getRevisionIdsByTermId,
  getTermRevisionsByCK,
  (state, termId) => termId,
  (revisionIdsByTermId, termRevisionsByCK, termId) => {
    const revisionIds = revisionIdsByTermId[termId] || [];
    revisionIds.sort((a, b) => a - b);
    return revisionIds.map(revisionId => termRevisionsByCK[toCK(termId, revisionId)]).filter(tr => !!tr);
  }
)((state, termId) => termId);

const getTermRevision = (state, termId, revisionId) => {
  const ck = toCK(termId, revisionId);
  return state.contractV1.terms.revisionsByCK[ck]; // {...}
};

const getTermRevisionItems = (state, termId, revisionId) => {
  const ck = toCK(termId, revisionId);
  return state.contractV1.terms.revisionItemsByCK[ck]; // [...]
};

const getEffectiveTermCKsBySku = state => state.contractV1.terms.effectiveTermsBySku || {};

const getSkuInfoBySku = state => state.contractV1.terms.skuInfoBySku;

const getEffectiveTermsBySku = createSelector(
  [getTermRevisionsByCK, getEffectiveTermCKsBySku],
  (termRevisionMap, effectiveTermCKsBySku) => {
    return Object.keys(effectiveTermCKsBySku).reduce((map, sku) => {
      map[sku] = effectiveTermCKsBySku[sku].CKs.map(ck => termRevisionMap[ck]);
      return map;
    }, {});
  }
);

const getCKsForHomePage = state => state.contractV1.terms.CKsForHomePage;
const getHomePageFilterText = state => state.contractV1.terms.homepageFilterText;

const getIsTermsLoading = state => state.contractV1.terms.isLoading.FETCH_TERMS_FOR_HOMEPAGE;
const getIsHomePageLoading = createSelector(
  state => state.contractV1.terms.isLoading.FETCH_TERMS_FOR_HOMEPAGE,
  state => state.contractV1.terms.isLoading.FETCH_EFFECTIVE_TERMS_BY_SKU,
  state => state.contractV1.terms.isLoading.FETCH_TERMS_BY_SKU,
  (isHomePageFetching, isEffectiveTermsFetching, isTermsFetching) => {
    return isHomePageFetching || isEffectiveTermsFetching || isTermsFetching || false;
  }
);

const getIsViewTermRevisionPageLoading = state => state.contractV1.terms.isLoading.FETCH_TERM_REVISION;
const getIsTermRevisionItemsLoading = state => state.contractV1.terms.isLoading.FETCH_TERM_REVISION_ITEMS;
const getHasViewTermRevisionPageLoadingFailed = state => state.contractV1.terms.hasError.FETCH_TERM_REVISION;
const getHasTermRevisionItemsLoadingFailed = state => state.contractV1.terms.hasError.FETCH_TERM_REVISION_ITEMS;

const getIsTermCreating = state => state.contractV1.terms.isLoading.CREATE_PRICE_TERM;
const getIsTermCreated = state => state.contractV1.terms.isLoaded.CREATE_PRICE_TERM;

const getIsTermStatusUpdating = state => state.contractV1.terms.isLoading.UPDATE_TERM_STATUS;
const getIsTermStatusUpdated = state => state.contractV1.terms.isLoaded.UPDATE_TERM_STATUS;
const getHasTermStatusUpdateFailed = state => state.contractV1.terms.hasError.UPDATE_TERM_STATUS;

const getIsTermItemsUpdating = state => state.contractV1.terms.isLoading.UPDATE_TERM_ITEMS;
const getIsTermItemsUpdated = state => state.contractV1.terms.isLoaded.UPDATE_TERM_ITEMS;
const getHasTermItemsUpdateFailed = state => state.contractV1.terms.hasError.UPDATE_TERM_ITEMS;

const getIsSkuPageLoading = state =>
  state.contractV1.terms.isLoading.FETCH_TERMS_BY_SKU || state.contractV1.terms.isLoading.FETCH_EFFECTIVE_TERMS_BY_SKU;
const getIsSkuPageLoaded = state =>
  state.contractV1.terms.isLoaded.FETCH_TERMS_BY_SKU && state.contractV1.terms.isLoaded.FETCH_EFFECTIVE_TERMS_BY_SKU;
const getIsSkuPageFailed = state =>
  state.contractV1.terms.hasError.FETCH_TERMS_BY_SKU || state.contractV1.terms.hasError.FETCH_EFFECTIVE_TERMS_BY_SKU;

const getUserPermissionsByCK = createSelector(
  [getTermRevisionsByCK, identitySelectors.getAuthorizedFulfillerIds, identitySelectors.getAuthorizedMerchantIds],
  (termRevisionMap, authorizedFulfillerIds, authorizedMerchantIds) => {
    const allTermRevisionCKs = Object.keys(termRevisionMap);
    const permissionsByCK = allTermRevisionCKs.reduce((map, ck) => {
      const term = termRevisionMap[ck];
      const { type: initiatorType, id: initiatorId } = term.initiator;
      const { type: acceptorType, id: acceptorId } = term.acceptor;
      const { type: buyerType, id: buyerId } = term.buyer;
      const { type: sellerType, id: sellerId } = term.seller;
      const initiator = typeIsFulfiller(initiatorType)
        ? authorizedFulfillerIds.includes(initiatorId)
        : typeIsMerchant(initiatorType)
          ? authorizedMerchantIds.includes(initiatorId)
          : false;
      const acceptor = typeIsFulfiller(acceptorType)
        ? authorizedFulfillerIds.includes(acceptorId)
        : typeIsMerchant(acceptorType)
          ? authorizedMerchantIds.includes(acceptorId)
          : false;
      const buyer = typeIsFulfiller(buyerType)
        ? authorizedFulfillerIds.includes(buyerId)
        : typeIsMerchant(buyerType)
          ? authorizedMerchantIds.includes(buyerId)
          : false;
      const seller = typeIsFulfiller(sellerType)
        ? authorizedFulfillerIds.includes(sellerId)
        : typeIsMerchant(sellerType)
          ? authorizedMerchantIds.includes(sellerId)
          : false;

      map[ck] = { initiator, acceptor, buyer, seller };

      return map;
    }, {});

    return permissionsByCK;
  }
);

const getTermRevisionsWithMoreInfoByCKs = createSelector(
  [
    getTermRevisionsByCK,
    identitySelectors.getFulfillersById,
    identitySelectors.getMerchantsById,
    getUserPermissionsByCK
  ],
  (termRevisionMap, fulfillers, merchants, userPermissionsByCK) => {
    // helper functions
    const getNameByTypeId = (type, id) => {
      let name = id;
      if (typeIsFulfiller(type) && fulfillers[id]) {
        name = fulfillers[id].name;
      }
      if (typeIsMerchant(type) && merchants[id]) {
        name = merchants[id].name;
      }

      return name;
    };

    const addMoreInfoToTerm = term => {
      const userHasAuthorizationFor = userPermissionsByCK[toCK(term.termId, term.revisionId)];
      const { acceptor: hasAcceptorPermission } = userHasAuthorizationFor;

      term.displayAgreementType = getDisplayAgreementType({ ...term, userHasAuthorizationFor });
      term.displayStatus = getDisplayStatus({
        contractRevision: term,
        hasAcceptorPermission
      }).displayStatus;
      term.buyer.name = getNameByTypeId(term.buyer.type, term.buyer.id);
      term.seller.name = getNameByTypeId(term.seller.type, term.seller.id);
      term.initiator.name = getNameByTypeId(term.initiator.type, term.initiator.id);
      term.acceptor.name = getNameByTypeId(term.acceptor.type, term.acceptor.id);
      term.userHasAuthorizationFor = userHasAuthorizationFor;
      term.createdByEmail = userSubToEmail(term.createdBy);
      return term;
    };

    const keyByCKs = (map, term) => {
      map[toCK(term.termId, term.revisionId)] = term;
      return map;
    };

    const allTermRevisions = Object.values(termRevisionMap);

    return allTermRevisions.map(addMoreInfoToTerm).reduce(keyByCKs, {});
  }
);

const getTermCKsBySku = state => state.contractV1.terms.CKsBySku;

const getTermsBySku = createSelector(
  [getTermRevisionsWithMoreInfoByCKs, getTermCKsBySku],
  (termRevisionMap, termCKsBySku) => {
    return Object.keys(termCKsBySku).reduce((map, sku) => {
      map[sku] = termCKsBySku[sku].CKs.map(ck => termRevisionMap[ck]);
      return map;
    }, {});
  }
);

const getTermsWithEffectiveFlagBySkuThenBuyer = createSelector(
  [getTermsBySku, getEffectiveTermsBySku, configurationSelectors.getShowTerminated, getLatestRevisionByTermId],
  (termsBySku, effectiveTermsBySku, showTerminated, latestRevisionByTermId) => {
    return Object.keys(termsBySku).reduce((map, sku) => {
      const terms = termsBySku[sku];
      const effectiveTerms = effectiveTermsBySku[sku];

      const termsWithEffectiveFlag = terms.map(
        term =>
          find(
            effectiveTerms,
            effectiveTerm => effectiveTerm.termId === term.termId && effectiveTerm.revisionId === term.revisionId
          )
            ? { ...term, isEffective: true }
            : { ...term, isEffective: false }
      );

      const groupedTerms = groupBy(termsWithEffectiveFlag, term => term.termId);

      // Grab only the latest revision and if any in the group was effective, mark it as effective
      const latestTerms = compact(
        values(groupedTerms).map(termGroup => {
          const latestRevisionWithSku = maxBy(termGroup, term => term.revisionId);

          // this is different from latestRevisionWithSku in that it could be a later revision without the sku on the term.
          const latestRevision = latestRevisionByTermId[latestRevisionWithSku.termId];
          const isLatestRevision = latestRevisionWithSku.revisionId === latestRevision.revisionId;
          const effectiveTermRevision = find(termGroup, term => term.isEffective);
          const isTermEffective = !!effectiveTermRevision;

          // filter out terms that are not latest and not effective.
          if (!isLatestRevision && !isTermEffective) {
            return null;
          }

          latestRevisionWithSku.isTermEffective = isTermEffective;
          latestRevisionWithSku.effectiveTermRevision = effectiveTermRevision;
          latestRevisionWithSku.latestRevision = latestRevision;
          latestRevisionWithSku.isLatestRevision = isLatestRevision;
          return latestRevisionWithSku;
        })
      );

      map[sku] = latestTerms
        .filter(term => term.status !== TermStatus.TERMINATED || showTerminated)
        .reduce((buyerMap, curr) => {
          buyerMap[curr.buyer.id]
            ? buyerMap[curr.buyer.id].terms.push(curr)
            : (buyerMap[curr.buyer.id] = { terms: [curr], name: curr.buyer.name });

          return buyerMap;
        }, {});

      return map;
    }, {});
  }
);

const getTermsByBuyers = createCachedSelector(
  getTermsWithEffectiveFlagBySkuThenBuyer,
  (state, sku) => sku,
  (termsBySkuThenBuyer, sku) => termsBySkuThenBuyer[sku]
)((state, sku) => sku);

const getTermRevisionWithMoreInfo = createCachedSelector(
  getTermRevisionsWithMoreInfoByCKs,
  (state, termId) => termId,
  (state, termId, revisionId) => revisionId,
  (termRevisionWithMoreInfoMap, termId, revisionId) => {
    const ck = toCK(termId, revisionId);
    return termRevisionWithMoreInfoMap[ck]; // {...}
  }
)((state, termId, revisionId) => toCK(termId, revisionId));

const getTermCKsByBuyerSellerCK = state => state.contractV1.terms.CKsByBuyerSellerCK;

const getTermRevisionsByBuyerSeller = createCachedSelector(
  getTermCKsByBuyerSellerCK,
  getTermRevisionsWithMoreInfoByCKs,
  (state, buyer) => buyer,
  (state, buyer, seller) => seller,
  (buyerSellerMap, termRevisionWithMoreInfoMap, buyer, seller) => {
    const buyerSellerCK = toCK(buyer.type, buyer.id, seller.type, seller.id);

    const obj = buyerSellerMap[buyerSellerCK] || {};
    const termCKs = obj.CKs || [];

    return termCKs.map(ck => termRevisionWithMoreInfoMap[ck]);
  }
)((state, buyer, seller) => toCK(buyer.type, buyer.id, seller.type, seller.id));

const getTermRevisionItemsLoaded = createCachedSelector(
  getRevisionItemsByCK,
  (state, termId) => termId,
  (state, termId, revisionId) => revisionId,
  (revisionItemsMap, termId, revisionId) => {
    const ck = toCK(termId, revisionId);
    // NOTE: This method is used to check if individual items calls were successful. It uses the fact that if the term item was not loaded, the items will be undefined, but if it was loaded (even without items), then it will be {}.
    const items = revisionItemsMap[ck];
    return !!items;
  }
)((state, termId, revisionId) => toCK(termId, revisionId));

const getTermRevisionItemsWithSkuInfo = createCachedSelector(
  getSkuInfoBySku,
  getRevisionItemsByCK,
  (state, termId) => termId,
  (state, termId, revisionId) => revisionId,
  (skuInfoMap, revisionItemsMap, termId, revisionId) => {
    const ck = toCK(termId, revisionId);
    const items = revisionItemsMap[ck] || [];
    return items.reduce((map, item) => {
      map[item.sku] = { ...item, ...skuInfoMap[item.sku] };
      return map;
    }, {});
  }
)((state, termId, revisionId) => toCK(termId, revisionId));

/*
TODO: This will select from the revision items array. No need for this because of getTermRevisionItemBySku method atm. If look up into the array is needed, uncomment this code.
const getItemByTermRevisionAndSku = createCachedSelector(
  getRevisionItemsByCK,
  (state, termId) => termId,
  (state, termId, revisionId) => revisionId,
  (state, termId, revisionId, sku) => sku,
  (revisionItemsMap, termId, revisionId, sku) => {
    const ck = toCK(termId, revisionId);
    const items = revisionItemsMap[ck] || [];
    return items.find(i => i.sku === sku);
  }
)((state, termId, revisionId, sku) => toCK(termId, revisionId, sku));*/

const getJsSearchInstance = memoizeOne(terms => {
  const jsSearch = new JsSearch.Search('termId');
  jsSearch.addIndex('displayAgreementType');
  jsSearch.addIndex('displayStatus');
  jsSearch.addIndex(['additionalData', 'name']);
  jsSearch.addIndex('status');
  jsSearch.addIndex(['buyer', 'name']);
  jsSearch.addIndex(['seller', 'name']);
  jsSearch.addDocuments(terms);

  return jsSearch;
});

const getNewTermCKs = state => state.contractV1.terms.newTermCKs;

const getNewTerms = createSelector([getNewTermCKs, getTermRevisionsByCK], (newTermCKs, byCK) =>
  newTermCKs.map(ck => byCK[ck])
);

const getTermsForHomePage = createSelector(
  [getTermRevisionsWithMoreInfoByCKs, getCKsForHomePage, getNewTermCKs, configurationSelectors.getShowTerminated],
  (termRevisionWithMoreInfoMap, CKsForHomePage, newTermCKs, showTerminated) => {
    const ckToTerm = ck => {
      const term = termRevisionWithMoreInfoMap[ck];
      if (newTermCKs.includes(ck)) {
        term.isNew = true;
      }

      return term;
    };

    const filterFn = term => showTerminated || term.status !== TermStatus.TERMINATED;

    // TODO: Make this support the case when there are more than 2 revision for a termid. This can happen if the user requests to see stale revision. This is currently not supported directly via the UI, but if the user visits it by modifying the URL, this can happen.
    // This shouldn't actually be an issue as long as we are grabbing CKsForHomePage everytime we are in the home page. It will become an issue as soon as we stop doing that and go off of the cache only.
    const combineTermRevisionsByCK = (map, t) => {
      if (map[t.termId]) {
        // if the revision id in the map is greater, set previousRevision value to the smaller revision id term.
        map[t.termId] =
          t.revisionId < map[t.termId].revisionId
            ? {
                ...map[t.termId],
                previousRevision: t
              }
            : { ...t, previousRevision: map[t.termId] };
      } else {
        map[t.termId] = { ...t };
      }
      return map;
    };

    return Object.values(
      CKsForHomePage.map(ckToTerm)
        .filter(filterFn)
        .reduce(combineTermRevisionsByCK, {})
    );
  }
);

const getFilteredTermsForHomePage = createSelector(
  [getTermsForHomePage, getHomePageFilterText, getIsHomePageLoading, getTermsBySku, getEffectiveTermsBySku],
  (termsForHomePage, filterText, isLoading, CKsBySku, effectiveTermsBySku) => {
    if (isLoading) {
      return [];
    }
    if (!filterText) {
      return termsForHomePage;
    }
    const jsSearch = getJsSearchInstance(termsForHomePage);

    // contains filtered results based on term's metadata (buyer, seller, status, etc.)
    const jsSearchResult = jsSearch.search(filterText) || [];

    // contains all term ids that includes the sku `filterText`
    const termIdsContainingFilterSkuSet = new Set((CKsBySku[filterText] || []).map(t => t.termId));

    // contains all effective terms that includes the sku `filterText`
    const effectiveTermsForFilterSku = effectiveTermsBySku[filterText] || [];

    const skusResult = termsForHomePage
      .filter(x => termIdsContainingFilterSkuSet.has(x.termId))
      .map(
        x =>
          effectiveTermsForFilterSku.filter(
            ({ termId, revisionId }) =>
              (x.termId === termId && x.revisionId === revisionId) ||
              (x.previousRevision &&
                x.previousRevision.termId === termId &&
                x.previousRevision.revisionId === revisionId)
          ).length
            ? { ...x, isEffective: true, effectiveSku: filterText }
            : x
      );

    const searchTermsWithoutSkuSearchResult = jsSearchResult.filter(
      x => !skusResult.includes(t => t.termId === x.termId)
    );

    return [...skusResult, ...searchTermsWithoutSkuSearchResult];
  }
);

const getPriceModelByCK = state => state.contractV1.terms.priceModelByCK;
const getPriceModelCKsByTermCK = state => state.contractV1.terms.priceModelCKsByTermCK;

const getPriceModel = (state, id, revisionId) => {
  const ck = toCK(id, revisionId);
  return getPriceModelByCK(state)[ck]; // {...}
};

const getAllContractBuyers = createSelector([getTermsForHomePage], termsForHomePage => {
  const buyers = Object.values(
    termsForHomePage.reduce((dict, term) => {
      dict[toCK(term.buyer.id, term.buyer.type)] = {
        ...term.buyer,
        label: `${term.buyer.name} (${term.buyer.type})`,
        value: toCK(term.buyer.id, term.buyer.type)
      };
      return dict;
    }, {})
  );

  return sortBy(buyers, b => b.name.toLowerCase());
});

const getAllContractSellers = createSelector([getTermsForHomePage], termsForHomePage => {
  const sellers = Object.values(
    termsForHomePage.reduce((dict, term) => {
      dict[toCK(term.seller.id, term.seller.type)] = {
        ...term.seller,
        label: `${term.seller.name} (${term.seller.type})`,
        value: toCK(term.seller.id, term.seller.type)
      };
      return dict;
    }, {})
  );

  return sortBy(sellers, s => s.name.toLowerCase());
});

const getPriceModelsForTerm = createCachedSelector(
  getPriceModelByCK,
  getPriceModelCKsByTermCK,
  (state, termId) => termId,
  (state, termId, revisionId) => revisionId,
  (priceModelInfoMap, priceModelCKsByTermCK, termId, revisionId) => {
    const termCK = toCK(termId, revisionId);
    const priceModelCKs = priceModelCKsByTermCK[termCK] || [];
    const priceModels = priceModelCKs.map(pmck => priceModelInfoMap[pmck] || { ...fromCK(pmck) });
    return priceModels; // [...]
  }
)((state, termId, revisionId) => toCK(termId, revisionId));

const getSkuRuleSetLoadingStatus = state => {
  return state.contractV1.terms.skuRuleSetLoadingStatus.FETCH_SKU_RULESET_LOADING_STATUS;
};

const getRuleSetLoadingStatus = terms => (dispatch, getState) => {
  if (terms && terms.skuRuleSetLoadingStatus) return terms.skuRuleSetLoadingStatus.FETCH_SKU_RULESET_LOADING_STATUS;
  else return null;
};

export const selectors = {
  getTermRevisionWithMoreInfo,
  getTermsForHomePage,
  getFilteredTermsForHomePage,
  getEffectiveTermsBySku,
  getHomePageFilterText,
  getIsTermsLoading,
  getIsHomePageLoading,
  getIsViewTermRevisionPageLoading,
  getIsTermRevisionItemsLoading,
  getHasViewTermRevisionPageLoadingFailed,
  getTermRevisionItemsWithSkuInfo,
  getHasTermRevisionItemsLoadingFailed,
  getIsTermCreating,
  getIsTermCreated,
  getIsTermStatusUpdating,
  getIsTermStatusUpdated,
  getHasTermStatusUpdateFailed,
  getIsTermItemsUpdating,
  getIsTermItemsUpdated,
  getHasTermItemsUpdateFailed,
  getNewTerms,
  getPriceModel,
  getPriceModelsForTerm,
  getAllRevisions,
  getTermRevisionItemsLoaded,
  getAllContractBuyers,
  getAllContractSellers,
  getTermRevisionsByBuyerSeller,
  getTermsByBuyers,
  getIsSkuPageLoading,
  getIsSkuPageLoaded,
  getIsSkuPageFailed,
  getSkuRuleSetLoadingStatus,
  getRuleSetLoadingStatus,
  ...termsV2Selectors
};
