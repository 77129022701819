//@flow
import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import storageSession from 'redux-persist/lib/storage/session';
import { persistReducer, persistStore } from 'redux-persist';
import { routerMiddleware } from 'react-router-redux';

import { history } from '../utils/history';
import DevTools from '../utils/devTools';
import rootReducer from '../reducers/index';
import callAPIMiddleware from './middlewares/callAPI';

const persistConfig = {
  key: 'root',
  storage: storageSession,
  // Whitelist the properties we want persisted
  whitelist: ['userIdentity']
};

const reducer = persistReducer(persistConfig, rootReducer);

const middlewares = [routerMiddleware(history), thunk, callAPIMiddleware];
const middlewareEnhancer = applyMiddleware(...middlewares);

const storeEnhancers = [];
storeEnhancers.push(middlewareEnhancer);
const isDev = process.env.NODE_ENV !== 'production';

if (isDev) {
  // allow devs to use their own plugged in browser redux dev tool instead of the builtin component
  const devToolsEnhancer = window.__REDUX_DEVTOOLS_EXTENSION__
    ? window.__REDUX_DEVTOOLS_EXTENSION__()
    : DevTools.instrument();
  storeEnhancers.push(devToolsEnhancer);
}

const store = createStore(reducer, {}, compose(...storeEnhancers));
const persistor = persistStore(store);

export { store, persistor };
