import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Tooltip, shapes } from '@cimpress/react-components';
import './SkuCard.css';

export const SkuInfoShape = PropTypes.shape({
  description: PropTypes.string,
  fulfiller: PropTypes.shape({
    fulfillerId: PropTypes.string,
    name: PropTypes.string
  }),
  name: PropTypes.string,
  sceneUrl: PropTypes.string,
  sku: PropTypes.string.isRequired,
  status: PropTypes.string
});

// TODO: This component should really be moved out to component library. Don't use this one though, use the one that's already in the library since that should be more up to date.
class SkuCard extends PureComponent {
  static defaultProps = {
    draggable: false,
    checkable: false,
    hasAction: false
  };

  constructor(props) {
    super(props);
    if (props.skuInfo.sceneUrl) {
      this.state = {
        imgLoading: true,
        imgLoaded: false
      };
    } else {
      this.state = {
        imgLoading: false,
        imgLoaded: false
      };
    }
  }

  static propTypes = {
    skuInfo: SkuInfoShape.isRequired,
    className: PropTypes.string,

    draggable: PropTypes.bool,

    checkable: PropTypes.bool,
    isChecked: PropTypes.bool,
    onChangeChecked: PropTypes.func,

    hasAction: PropTypes.bool,
    onAction: PropTypes.func,

    disabled: PropTypes.bool,
    disabledTooltipMsg: PropTypes.string
  };

  onChangeChecked = () => {
    const { onChangeChecked, skuInfo } = this.props;
    return onChangeChecked && onChangeChecked(skuInfo);
  };

  onImgLoad = () => {
    this.setState({ imgLoading: false, imgLoaded: true });
  };

  render() {
    const {
      skuInfo,
      className,
      draggable,
      checkable,
      isChecked,
      hasAction,
      onAction,
      disabled,
      disabledTooltipMsg
    } = this.props;

    const { imgLoaded, imgLoading } = this.state;

    const innerContent = (
      <React.Fragment>
        {draggable && <div className="sku-card__drag">{/* <Icon name="drag_drop_l" /> */}</div>}
        {checkable && (
          <div className="sku-card__pre-action">
            <Checkbox
              className="sku-card__pre-action__checkbox"
              checked={isChecked}
              onChange={!disabled ? this.onChangeChecked : null}
            />
          </div>
        )}
        <div className="sku-card__info">
          <div className="sku-card__info__top">
            <span className="sku-card__info__top__sku h5">{skuInfo.sku}</span>
            {skuInfo.name && <span className="sku-card__info__top__name h5">{skuInfo.name}</span>}
          </div>
          {skuInfo.description && <div className="sku-card__info__bottom">{skuInfo.description}</div>}
        </div>

        <div className="sku-card__img">
          {skuInfo.sceneUrl && imgLoading && !imgLoaded ? (
            <div className="sku-card__shape-container">
              <shapes.Spinner size={20} />
            </div>
          ) : null}
          <img src={skuInfo.sceneUrl} alt="" width="50px" height="50px" onLoad={this.onImgLoad} />
        </div>
        {hasAction && (
          <div className="sku-card__post-action">
            <button
              className={`sku-card__post-action__link sku-card__post-action__link${
                onAction && !disabled ? '--clickable' : '--disabled'
              }`}
              onClick={onAction && !disabled ? () => onAction(skuInfo) : null}>
              {/* <Icon name={actionIcon} /> */}
            </button>
          </div>
        )}
      </React.Fragment>
    );

    const render = disabled ? (
      <Tooltip
        containerClassName={`sku-card ${disabled ? 'disabled' : ''} ${className ? className : ''}`}
        contents={disabledTooltipMsg}>
        {innerContent}
      </Tooltip>
    ) : (
      <div className={`sku-card ${disabled ? 'disabled' : ''} ${className ? className : ''}`}>{innerContent}</div>
    );

    return render;
  }
}

export default SkuCard;
