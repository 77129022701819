import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { css } from 'react-emotion';
import { PriceModelRevisionSelector } from '@cimpress-technology/ff-react-components';
import { auth } from '../../../../utils/auth';
import BasicTermInfoForm from '../shared/BasicTermInfoForm';
import Button from '../../../common/Button';
import { FFS_EMAIL_ADDRESS } from '../../../../constants/appConstants';
import { isProduction } from '../../../../utilities/processFunctions';
import { TextField } from '@cimpress/react-components';

const formSectionStyle = css`
  margin-bottom: 35px;
`;

const priceModelDisplayStyle = css`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`;

const priceModelSelectorStyle = css`
  max-height: 500px;
`;

class SpecialPriceTermInfoForm extends PureComponent {
  static defaultProps = {};

  static propTypes = {
    priceModel: PropTypes.object.isRequired,
    priceModelSelected: PropTypes.bool.isRequired,
    onSelectPriceModel: PropTypes.func.isRequired,
    skus: PropTypes.arrayOf(PropTypes.string).isRequired,
    sellerId: PropTypes.string.isRequired,
    sellerType: PropTypes.string.isRequired,
    hasOnlyOnePriceModel: PropTypes.bool.isRequired,
    onOnlyOnePriceModelAvailable: PropTypes.func.isRequired,
    onChangePriceModel: PropTypes.func.isRequired,
    onChangeModifierAmount: PropTypes.func,
    getValidationErrorFns: PropTypes.object,
    modifierAmount: PropTypes.string,
    modifierType: PropTypes.string
  };

  state = {
    modifierError: undefined
  };

  onChangePriceModel = () => {
    this.props.onChangePriceModel();
  };

  onGetAllRevisions = priceModels => {
    const hasOnlyOnePriceModel = priceModels && priceModels.length === 1;

    if (hasOnlyOnePriceModel) {
      const pm = priceModels[0];
      this.props.onOnlyOnePriceModelAvailable(pm.id, pm.revisionId, pm.name);
    }
  };

  onChangeModifierAmount = e => {
    const { onChangeModifierAmount, getValidationErrorFns, modifierType } = this.props;

    onChangeModifierAmount(e.target.value);
    this.setState({ modifierError: e && getValidationErrorFns.modifier(modifierType, e.target.value) });
  };

  render() {
    const {
      t,
      priceModel,
      hasOnlyOnePriceModel,
      priceModelSelected,
      onSelectPriceModel,
      modifierAmount,
      modifierType,
      skus,
      sellerId,
      sellerType,
      ...rest
    } = this.props;

    const { modifierError } = this.state;

    const modifierInputField = modifierType && (
      <div>
        <h5>{t('createContract:messages.setModifierHeader', { context: modifierType })}</h5>
        <TextField
          bsStyle={modifierError && 'error'}
          label={t('createContract:messages.modifierTextFieldLabel', { context: modifierType })}
          value={modifierAmount}
          required
          onChange={this.onChangeModifierAmount}
          onBlur={this.onChangeModifierAmount}
          type="number"
          min="0"
          helpText={modifierError}
        />
        <hr />
      </div>
    );

    const formInfo = (
      <div>
        {modifierInputField}
        <BasicTermInfoForm {...rest} />
        <div className={formSectionStyle}>
          <h5>
            {t('createContract:pricingContract.selectedPriceModel', { context: hasOnlyOnePriceModel ? '' : 'plural' })}
          </h5>
          <div className={priceModelDisplayStyle}>
            <span>{priceModel.name}</span>
            {hasOnlyOnePriceModel ? null : (
              <Button className="btn btn-link" onClick={this.onChangePriceModel}>
                {t('createContract:pricingContract.changePriceModel')}
              </Button>
            )}
          </div>
        </div>
      </div>
    );

    const errMsg = t('createContract:pricingContract.noPriceModelFoundMessage', {
      contactEmailAddress: FFS_EMAIL_ADDRESS
    });

    const priceModelSection = (
      <div className={formSectionStyle}>
        <h5>{t('createContract:pricingContract.selectAPriceModel')}</h5>
        <br />
        <PriceModelRevisionSelector
          className={priceModelSelectorStyle}
          isProduction={isProduction}
          priceModelId={priceModel.id}
          revisionId={priceModel.revisionId}
          sellerId={sellerId}
          sellerType={sellerType}
          skus={skus}
          onGetAccessToken={auth.getAccessToken}
          onGetAllRevisions={this.onGetAllRevisions}
          onChange={onSelectPriceModel}
          filterArchivedPriceModels={true}
          filterListPriceModels={true}
          errorMessage={errMsg}
        />
      </div>
    );

    return <div>{priceModelSelected ? formInfo : priceModelSection}</div>;
  }
}

export default withTranslation()(SpecialPriceTermInfoForm);
