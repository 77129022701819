import { AVAILABLE, SAVING, UNAVAILABLE } from '../../../constants/entityStatus';
import { getResourceTypes } from '../../../contractV1_src/constants/subscriptionConstants';
import * as subscriptionsApi from '../../../contractV1_src/service/Subscriptions';
import { createReducer } from '../../utils';
import { fetchAllSubscriptions } from './fetchAllSubscriptions';

const unSubscribeNotificationHooks = {
  onFailure: 'Something went wrong while unsubscribing from notifications.'
};

const NOTIFICATIONS_UNSUBSCRIBE_REQUEST = 'NOTIFICATIONS_UNSUBSCRIBE_REQUEST';
const NOTIFICATIONS_UNSUBSCRIBE_FAILURE = 'NOTIFICATIONS_UNSUBSCRIBE_FAILURE';
const NOTIFICATIONS_UNSUBSCRIBE_SUCCESS = 'NOTIFICATIONS_UNSUBSCRIBE_SUCCESS';

export const unsubscribeFromNotificationsAction = (subscriptionIds, notificationType) => {
  return {
    types: [NOTIFICATIONS_UNSUBSCRIBE_REQUEST, NOTIFICATIONS_UNSUBSCRIBE_SUCCESS, NOTIFICATIONS_UNSUBSCRIBE_FAILURE],
    callAPI: async dispatch => {
      const response = await subscriptionsApi.unsubscribe(subscriptionIds, notificationType);
      const resourceTypes = getResourceTypes(notificationType);
      resourceTypes.forEach(resourceType => {
        dispatch(fetchAllSubscriptions(resourceType));
      });
      return response;
    },
    hooks: unSubscribeNotificationHooks
  };
};

// reducer
export default createReducer(
  {},
  {
    [NOTIFICATIONS_UNSUBSCRIBE_REQUEST]: (state, action) => {
      return { ...state, status: SAVING };
    },
    [NOTIFICATIONS_UNSUBSCRIBE_SUCCESS]: (state, action) => {
      return { ...state, status: AVAILABLE };
    },
    [NOTIFICATIONS_UNSUBSCRIBE_FAILURE]: (state, action) => {
      return { ...state, status: UNAVAILABLE };
    }
  }
);

export const getUnSubscribeToNotificationsStatus = state => {
  return state.subscriptions.unSubscribeToNotifications.status;
};
