import { combineReducers } from 'redux';
import { createSelector } from 'reselect';
import * as ENTITY_STATUS from '../../constants/entityStatus';
import * as coamApi from '../../services/Coam';
import { createApiErrorNotificationAction } from '../utils';
import accountPermissions, {
  getPermissionsAction,
  getPermissionsPayload,
  getPermissionsStatus,
  isUserAdmin
} from './getPermissions';

const FETCH_ACCOUNT_PERMISSIONS_REQUEST = 'FETCH_ACCOUNT_PERMISSIONS_REQUEST';
const FETCH_ACCOUNT_PERMISSIONS_SUCCESS = 'FETCH_ACCOUNT_PERMISSIONS_SUCCESS';
const FETCH_ACCOUNT_PERMISSIONS_FAILURE = 'FETCH_ACCOUNT_PERMISSIONS_FAILURE';

const fetchAccountPermissionsRequest = () => ({ type: FETCH_ACCOUNT_PERMISSIONS_REQUEST });
const fetchAccountPermissionsSuccess = payload => ({ type: FETCH_ACCOUNT_PERMISSIONS_SUCCESS, payload });
const fetchAccountPermissionsFailure = payload => ({ type: FETCH_ACCOUNT_PERMISSIONS_FAILURE, payload });

const fetchAccountPermissions = () => async dispatch => {
  dispatch(fetchAccountPermissionsRequest());

  coamApi
    .fetchAccountPermissions()
    .then(res => dispatch(fetchAccountPermissionsSuccess(res)))
    .catch(err => {
      dispatch(fetchAccountPermissionsFailure(err));
      dispatch(createApiErrorNotificationAction(err));
    });
};

/**
 * Action creator used to load merchant/fulfiller identities and identities that the user has permission to.
 * @returns {Function}
 */
const loadUserIdentities = () => async dispatch => {
  const fetchAccountPermissionsPromise = dispatch(fetchAccountPermissions());
  return Promise.all([fetchAccountPermissionsPromise]);
};

// action creators to expose
export const actions = {
  getPermissionsAction,
  loadUserIdentities
};

function accounts(state = {}, action) {
  switch (action.type) {
    case FETCH_ACCOUNT_PERMISSIONS_SUCCESS:
      return {
        ...state,
        payload: action.payload,
        fetchAccountStatus: ENTITY_STATUS.AVAILABLE
      };
    case FETCH_ACCOUNT_PERMISSIONS_REQUEST:
      return {
        ...state,
        fetchAccountStatus: ENTITY_STATUS.LOADING
      };

    case FETCH_ACCOUNT_PERMISSIONS_FAILURE:
      return {
        ...state,
        fetchAccountStatus: ENTITY_STATUS.AVAILABLE
      };
    default:
      return state;
  }
}

const identitiesReducer = combineReducers({ accounts, accountPermissions });
export default identitiesReducer;

const getAccountPermission = state => state.identities.accounts;

const isAdmin = state => {
  return state.identities.accounts.payload.find(e => e === 'admin:contract-manager') !== undefined;
};

const isReadOnly = state => {
  return state.identities.accounts.payload.length === 0;
};

const getLoadUserIdentitiesStatus = state => state.identities.accounts.fetchAccountStatus;

const getUserIdentitiesLoaded = createSelector(
  getLoadUserIdentitiesStatus,
  status => status === ENTITY_STATUS.AVAILABLE
);

// selectors to expose
export const selectors = {
  getUserIdentitiesLoaded,
  getAccountPermission,
  isReadOnly,
  isAdmin,
  getPermissionsPayload,
  getPermissionsStatus,
  isUserAdmin
};
