import * as productIntroductionApi from '../../services/ProductIntroductionService';
import { createReducer } from '../utils';
import get from 'lodash/get';

import { AVAILABLE, NOT_LOADED, UNAVAILABLE } from '../../constants/entityStatus';
export const fetchProductIntroductionDetail_REQUEST = 'fetchProductIntroductionDetail_REQUEST';
export const fetchProductIntroductionDetail_SUCCESS = 'fetchProductIntroductionDetail_SUCCESS';
export const fetchProductIntroductionDetail_FAILURE = 'fetchProductIntroductionDetail_FAILURE';

export const fetchProductByProductIdAndVersion_REQUEST = 'fetchProductByProductIdAndVersion_REQUEST';
export const fetchProductByProductIdAndVersion_SUCCESS = 'fetchProductByProductIdAndVersion_SUCCESS';
export const fetchProductByProductIdAndVersion_FAILURE = 'fetchProductByProductIdAndVersion_FAILURE';

export const fetchProductsByProductIdAndVersion_REQUEST = 'fetchProductsByProductIdAndVersion_REQUEST';
export const fetchProductsByProductIdAndVersion_SUCCESS = 'fetchProductsByProductIdAndVersion_SUCCESS';
export const fetchProductsByProductIdAndVersion_FAILURE = 'fetchProductsByProductIdAndVersion_FAILURE';

// Action
export const fetchProductIntroductionDetailAction = productIds => {
  return {
    types: [
      fetchProductIntroductionDetail_REQUEST,
      fetchProductIntroductionDetail_SUCCESS,
      fetchProductIntroductionDetail_FAILURE
    ],
    callAPI: async () => {
      let listOfProductId = Array.from(productIds);
      let requestedProductIdsCount = 50;
      try {
        if (listOfProductId.length < requestedProductIdsCount) {
          let response = await productIntroductionApi.getProducts(listOfProductId);
          return response;
        } else {
          let listsOfProductIds = [];
          let finalResponse = [];
          for (var i = 0; i < listOfProductId.length; i++) {
            if (i % requestedProductIdsCount === 0) {
              listsOfProductIds.push([]);
            }
            listsOfProductIds[Math.floor(i / requestedProductIdsCount)].push(listOfProductId[i]);
          }
          let listsOfResponses = await Promise.all(
            [...listsOfProductIds].map(listOfProductId => productIntroductionApi.getProducts(listOfProductId))
          );
          listsOfResponses.forEach(responses => {
            responses.forEach(response => {
              finalResponse.push(response);
            });
          });
          return finalResponse;
        }
      } catch (err) {
        const errorResponse = get(err, 'response.detail', 'Error from product introduction service');
        console.error(errorResponse);
        throw new Error(errorResponse);
      }
    }
  };
};

// Action
export const fetchProductByProductIdAndVersionAction = (productId, version) => {
  return {
    types: [
      fetchProductByProductIdAndVersion_REQUEST,
      fetchProductByProductIdAndVersion_SUCCESS,
      fetchProductByProductIdAndVersion_FAILURE
    ],
    callAPI: async () =>
      await productIntroductionApi
        .getProductByProductIdAndVersion(productId, version)
        .then(res => {
          const { name, productId, version } = res;
          return { name, productId, version };
        })
        .catch(err => {
          const errorResponse = get(err, 'response.detail', 'Error getting product');
          console.error(errorResponse);
          return null;
        })
  };
};

// Action
export const fetchProductsByProductIdAndVersionAction = products => {
  return {
    types: [
      fetchProductsByProductIdAndVersion_REQUEST,
      fetchProductsByProductIdAndVersion_SUCCESS,
      fetchProductsByProductIdAndVersion_FAILURE
    ],
    callAPI: async dispatch =>
      await Promise.all(
        [...products].map(({ productId, version }) =>
          dispatch(fetchProductByProductIdAndVersionAction(productId, version))
        )
      )
  };
};

// Reducer
export default createReducer(
  {},
  {
    [fetchProductByProductIdAndVersion_SUCCESS]: (state, action) => {
      const { payload } = action;
      if (!payload) {
        return state;
      }
      const { productId, version } = payload;
      return { ...state, [`${productId}|${version}`]: payload };
    },
    [fetchProductIntroductionDetail_SUCCESS]: (state, action) => {
      const { payload } = action;
      return { ...state, payload, status: AVAILABLE };
    },

    [fetchProductIntroductionDetail_REQUEST]: (state, action) => {
      return { ...state, status: NOT_LOADED };
    },
    [fetchProductIntroductionDetail_FAILURE]: (state, action) => {
      return { ...state, status: UNAVAILABLE };
    }
  }
);

// Selector
export const getProductIntroductionDetail = state => {
  return state.productAgreements.productIntroductionDetail.payload;
};

export const getProductIntroductionStatus = state => {
  return state.productAgreements.productIntroductionDetail.status;
};
