import { toCK } from '../utils';

const STORE_CACHE = 'STORE_CACHE';
const REMOVE_CACHE = 'REMOVE_CACHE';
const DEFAULT_TTL_MS = 60000;

const addCache = ({ thingToCache, cacheKey, ttlMs = DEFAULT_TTL_MS }) => {
  cacheKey = getStringCacheKey(cacheKey);

  if (typeof ttlMs !== 'number') {
    throw new Error('Expected ttlMs to be a number.');
  }

  return { type: STORE_CACHE, payload: { thingToCache, cacheKey, ttlMs } };
};

const removeCache = ({ cacheKey }) => {
  cacheKey = getStringCacheKey(cacheKey);

  return { type: REMOVE_CACHE, payload: { cacheKey } };
};

export const cacheActions = {
  addCache,
  removeCache
};

// cache reducer
export default (state = {}, { type, payload }) => {
  if (type === STORE_CACHE) {
    const { cacheKey, thingToCache, ttlMs } = payload;

    return { ...state, [cacheKey]: { cache: thingToCache, cachedUntil: Date.now() + ttlMs } };
  }

  if (type === REMOVE_CACHE) {
    const { cacheKey } = payload;

    return { ...state, [cacheKey]: undefined };
  }

  // not STORE_CACHE type; ignore.
  return state;
};

const getStringCacheKey = cacheKey => {
  if (typeof cacheKey !== 'string' && !Array.isArray(cacheKey)) {
    throw new Error('Expected cacheKey to be a string or an array.');
  }

  if (Array.isArray(cacheKey)) {
    if (!cacheKey.every(key => typeof key === 'string')) {
      throw new Error('Expected cacheKey to be an array of strings.');
    } else {
      return toCK(...cacheKey);
    }
  }

  return cacheKey;
};

const getCache = (state, ...cacheKey) => {
  const { cache } = state.cache[getStringCacheKey(cacheKey)] || {};
  return cache;
};

const isCacheExpired = (state, ...cacheKey) => {
  const { cachedUntil = 0 } = state.cache[getStringCacheKey(cacheKey)] || {};
  return Date.now() > cachedUntil;
};

const getNonExpiredCache = (state, ...cacheKey) => {
  if (isCacheExpired(state, getStringCacheKey(cacheKey))) {
    return null;
  }

  return getCache(state, getStringCacheKey(cacheKey));
};

export const cacheSelectors = {
  getCache,
  isCacheExpired,
  getNonExpiredCache
};
