import { createReducer } from '../utils';
import { fetchTerms } from '../../contractV1_src/service/PricingTermService';

import { AVAILABLE, NOT_LOADED, UNAVAILABLE } from '../../constants/entityStatus';

export const fetchPricingTerms_REQUEST = 'fetchPricingTerms_REQUEST';
export const fetchPricingTerms_SUCCESS = 'fetchPricingTerms_SUCCESS';
export const fetchPricingTerms_FAILURE = 'fetchPricingTerms_FAILURE';

const pricingtermsHook = {
  onFailure: 'Unable to fetch pricing terms(contracts).'
};

// Action
export const fetchPricingTermsAction = (sku, buyer, seller) => {
  return {
    types: [fetchPricingTerms_REQUEST, fetchPricingTerms_SUCCESS, fetchPricingTerms_FAILURE],
    callAPI: async () => {
      return await fetchTerms(sku, buyer, seller); // fetching all contracts.
    },
    hooks: pricingtermsHook
  };
};

// reducer
export default createReducer(
  {},
  {
    [fetchPricingTerms_SUCCESS]: (state, action) => {
      let { payload } = action;
      return { ...state, payload, status: AVAILABLE };
    },
    [fetchPricingTerms_REQUEST]: state => {
      return { ...state, status: NOT_LOADED };
    },
    [fetchPricingTerms_FAILURE]: state => {
      return { ...state, status: UNAVAILABLE };
    }
  }
);

// Selector
export const getPricingTerms = state => {
  return state.contractV1.pricingTerms.payload;
};

export const getPricingTermsStatus = state => {
  return state.contractV1.pricingTerms.status;
};
