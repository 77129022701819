// @flow
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import AppHeader from './AppHeader';

export default class Shell extends Component {
  static propTypes = {
    children: PropTypes.object.isRequired
  };

  render() {
    return (
      <div className="main">
        <AppHeader />
        <div className="main__content">{this.props.children}</div>
      </div>
    );
  }
}
