import React from 'react';
import { useTranslation } from 'react-i18next';
import { Robot } from '@cimpress/react-components/lib/shapes/index';
export default function BuyerNotCimpressOwned({ businessName }) {
  const { t } = useTranslation();

  return (
    <div className="error-container">
      <center>
        {t('productAgreementsPage:buyerNotCimpressOwnedMessage', { accountName: businessName })}
        <Robot bsStyle="danger" />
        {t('productAgreementsPage:buyerNotCimpressOwnedMessageContactUs', { accountName: businessName })}
      </center>
    </div>
  );
}
