import { useTranslation } from 'react-i18next';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Radio, RadioGroup } from '@cimpress/react-components';
import { LOADING } from '../../constants/entityStatus';
import Loading from '../Loading';
import { css } from 'emotion';

import {
  actions as shippingAgreementTypeAction,
  selectors as shippingAgreementTypeSelector
} from '../../reducers/productagreements';
import { useEffect } from 'react';
import { useState } from 'react';

export const NEW = 'NEW';
export const EXISTING = 'EXISTING';
const radioButtonFontCss = css({
  fontSize: '13px'
});

function ShippingContract(props) {
  const {
    i18n: { language }
  } = useTranslation();
  const [shippingAgreementTypes, setShippingAgreementTypes] = useState([]);
  const { getShippingAgreementTypes, getShippingAgreementTypesStatus, selectedShippingType, allowToEdit } = props;
  useEffect(() => {
    props.fetchShippingAgreementTypes();
  }, []);

  useEffect(
    () => {
      if (getShippingAgreementTypes && language) {
        setShippingAgreementTypes(getShippingAgreementTypes[language]);
      }
    },
    [getShippingAgreementTypes, language]
  );

  const onChangeShippingType = (e, value) => props.onChange(value);
  const isLoading = getShippingAgreementTypesStatus === LOADING;
  return (
    <Fragment>
      {isLoading ? (
        <Loading size="medium" />
      ) : (
        <RadioGroup
          className={radioButtonFontCss}
          name="shippingContract"
          onChange={onChangeShippingType}
          defaultSelected={parseInt(selectedShippingType)}>
          {shippingAgreementTypes.map(({ name, shippingAgreementTypeId }) => {
            if (!allowToEdit) {
              if (parseInt(selectedShippingType) === shippingAgreementTypeId) {
                return <Radio key={shippingAgreementTypeId} label={name} value={shippingAgreementTypeId} />;
              } else {
                return <Radio key={shippingAgreementTypeId} label={name} value={shippingAgreementTypeId} disabled />;
              }
            } else {
              return <Radio key={shippingAgreementTypeId} label={name} value={shippingAgreementTypeId} />;
            }
          })}
        </RadioGroup>
      )}
    </Fragment>
  );
}

const mapStateToProps = state => ({
  getShippingAgreementTypesStatus: shippingAgreementTypeSelector.getShippingAgreementTypesStatus(state),
  getShippingAgreementTypes: shippingAgreementTypeSelector.getShippingAgreementTypes(state)
});

const mapDispatchToProps = {
  fetchShippingAgreementTypes: shippingAgreementTypeAction.fetchShippingAgreementTypesAction
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShippingContract);
