import { cobalt } from '@cimpress/react-components/lib/colors';
import { getDateDifferenceFromToday, hasTermStarted, isTermExpired, isTermExpiring } from './dateFunctions';
import TermStatus, { DisplayTermStatus } from '../constants/termStatus';
import i18n from '../i18n/i18n';
import moment from 'moment/moment';
import { TermType } from '../constants/termTypes';
import React from 'react';
import { Label } from '@cimpress/react-components';

const t = i18n.t.bind(i18n);

export function getDisplayDate(timestamp) {
  const momentTime = moment(timestamp, moment.ISO_8601);

  if (!momentTime.isValid()) {
    return '';
  } else if (momentTime.isSame('9999-12-31', 'day')) {
    return t('common:commonTerminology.indefinite');
  } else {
    // Default format is date with no timestamp
    return momentTime.locale(i18n.language).format('ll');
  }
}

export function getLongDateFormat() {
  return moment.localeData().longDateFormat('L');
}

export function getTranslationKeyByContractType(termType) {
  switch (termType) {
    case TermType.LIST_PRICE:
      return 'common:contractTypes.listPrice';
    case TermType.SPECIAL_PRICE:
      return 'common:contractTypes.specialPrice';
    case TermType.FREE_SHIPPING:
      return 'common:contractTypes.freeShipping';
    case TermType.SHIPPING_COST:
      return 'common:contractTypes.shippingCost';
    case TermType.SHIPPING_PRICE:
      return 'common:contractTypes.shippingPrice';
    default:
      return 'common:contractTypes.unknown';
  }
}

export function getLabelForContractType(termType) {
  return t(`${getTranslationKeyByContractType(termType)}`);
}

export function getDisplayAgreementType({ type, modifiers, userHasAuthorizationFor }) {
  const { seller: hasSellerPermission } = userHasAuthorizationFor;
  let description = getLabelForContractType(type);

  if (modifiers && (modifiers.discountPercent !== null && modifiers.discountPercent !== undefined)) {
    description += ` ${t('common:commonTerminology.discount', { percentage: modifiers.discountPercent })}`;
  }
  if (hasSellerPermission && modifiers && (modifiers.markupPercent !== null && modifiers.markupPercent !== undefined)) {
    description += ` ${t('common:commonTerminology.markup', { percentage: modifiers.markupPercent })}`;
  }
  return description;
}

export function getDisplayStatus({ contractRevision, hasAcceptorPermission, isPriorTerm = false }) {
  const termIsExpired = isTermExpired(contractRevision.effectiveEndDate);
  const termIsExpiring = isTermExpiring(contractRevision.effectiveEndDate);
  const termHasStarted = hasTermStarted(contractRevision.effectiveStartDate);

  const translatedMsgKeySuffix = isPriorTerm ? '_priorTerm' : '';
  const draftMsg = t(`common:contractStatus.draft${translatedMsgKeySuffix}`);
  const rejectedMsg = t(`common:contractStatus.rejected${translatedMsgKeySuffix}`);
  const terminatedMsg = t(`common:contractStatus.terminated${translatedMsgKeySuffix}`);
  const approvedMsg = t(`common:contractStatus.approved${translatedMsgKeySuffix}`);
  const pendingMsgKeySuffix = isPriorTerm ? '_priorTerm' : hasAcceptorPermission ? '_yourResponse' : '_otherParty';
  const pendingMsg = t(`common:contractStatus.pending${pendingMsgKeySuffix}`);
  const expiresWhen = getDateDifferenceFromToday(contractRevision.effectiveEndDate);
  const expiredMsg = t('common:contractStatus.expiredAgo', { duration: expiresWhen });
  const isExpiringMsg = t('common:contractStatus.isExpiring', { duration: expiresWhen });

  const expirationMsg = termIsExpired ? expiredMsg : termIsExpiring ? isExpiringMsg : '';

  const statusToDisplayObj = {
    [TermStatus.DRAFT]: {
      displayStatus: DisplayTermStatus.DRAFT,
      labelRender: <Label text={draftMsg} type="default" />
    },
    [TermStatus.PENDING]: {
      displayStatus: hasAcceptorPermission
        ? DisplayTermStatus.AWAITING_YOUR_RESPONSE
        : DisplayTermStatus.PENDING_APPROVAL,
      labelRender: <Label text={pendingMsg} type={hasAcceptorPermission ? 'warning' : 'info'} />
    },
    [TermStatus.REJECTED]: {
      displayStatus: DisplayTermStatus.REJECTED,
      labelRender: <Label text={rejectedMsg} type="danger" />
    },
    [TermStatus.APPROVED]: {
      displayStatus: DisplayTermStatus.APPROVED,
      labelRender: (
        <Label
          text={`${approvedMsg} ${expirationMsg}`}
          type={!termHasStarted ? 'primary' : termIsExpired ? 'default' : termIsExpiring ? 'warning' : 'success'}
        />
      )
    },
    [TermStatus.TERMINATED]: {
      displayStatus: DisplayTermStatus.TERMINATED,
      labelRender: <Label text={terminatedMsg} type="danger" />
    }
  };

  return (
    statusToDisplayObj[contractRevision.status] || {
      displayStatus: contractRevision.status,
      color: cobalt.darkest
    }
  );
}
