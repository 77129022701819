import React from 'react';
import Agreements from './Agreements';
import { Card } from '@cimpress/react-components';
import ProductListing from './ProductListing';

function RelationshipSection({ buyer, seller, relationshipDataKey, tabIndex }) {
  return (
    <div>
      <div className="grid-container1">
        <div>
          <ProductListing buyer={buyer} seller={seller} businessIds={relationshipDataKey} tabIndex={tabIndex} />
        </div>
        <div>
          <Card header="Agreements">
            <Agreements buyer={buyer} seller={seller} relationshipDataKey={relationshipDataKey} />
          </Card>
        </div>
      </div>
    </div>
  );
}

export default RelationshipSection;
