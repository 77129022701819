// @flow
import { buildOptions, makeRequest } from '../../services/ServiceHelpers';
import _ from 'lodash';
import { getTermModifierType } from '../../constants/termTypes';
import moment from 'moment';
import * as qs from 'qs';
import { ALL } from '../../components/contracts/createContract/SetupShippingPricingTermStep/ShippingTermCard';

const endpointUrl = process.env.REACT_APP_PRICING_TERM_URL;

export const fetchTerms = async (skus = null, buyer, seller) => {
  const body = {};

  if (skus !== null) {
    body.skus = skus;
  }

  if (buyer && buyer.id && buyer.type) {
    body.buyerId = buyer.id;
    body.buyerType = buyer.type;
  }

  if (seller && seller.id && seller.type) {
    body.sellerId = seller.id;
    body.sellerType = seller.type;
  }

  const options = buildOptions({ method: 'POST', hal: true, body: JSON.stringify(body) });

  const reposne = await makeRequest(
    endpointUrl,
    `/api/v0/terms:search`,
    options,
    'getting pricing terms for home page'
  );
  const halTerms = _.get(reposne, '_embedded.self');
  return halTerms.map(formatTermRevisionHalResponse);
};

export const getEffectiveTerms = ({ seller, buyer, sku }) => {
  const body = {
    skus: [sku],
    queryDate: moment.utc()
  };

  if (buyer) {
    body.buyers = [buyer];
  }

  if (seller) {
    body.seller = seller;
  }

  const options = buildOptions({ method: 'POST', body: JSON.stringify(body) });

  return makeRequest(endpointUrl, `/api/v0/effectiveterms`, options, 'getting effective terms').then(response => {
    return response;
  });
};

export const fetchTermRevisionItems = (termId, revisionId, pageIndex = 0, pageSize = 10) => {
  const options = buildOptions({ hal: true });

  const query = {
    pageIndex: pageIndex,
    pageSize: pageSize
  };

  return makeRequest(
    endpointUrl,
    `/api/v0/terms/${termId}/revisions/${revisionId}/items?${qs.stringify(query)}`,
    options,
    `getting pricing term items for ${termId} (revision ${revisionId})`
  ).then(response => {
    const lastPageLink = _.get(response, '_links.last.href');
    const qsRegex = /[^?]*$/;
    const lastPageQueryStrings = qsRegex.exec(lastPageLink)[0];
    const parsedQueryStrings = qs.parse(lastPageQueryStrings);
    const lastPageIndex = Number.parseInt(parsedQueryStrings.pageIndex, 10);
    const items = _.get(response, '_embedded.self');
    const totalCount = response.totalCount;

    return { items, lastPageIndex, totalCount };
  });
};

export const fetchTermRevisionItemBySku = (termId, revisionId, sku) => {
  const options = buildOptions({ hal: true });

  return makeRequest(
    endpointUrl,
    `/api/v0/terms/${termId}/revisions/${revisionId}/items/${sku}`,
    options,
    `getting pricing term items for ${termId} (revision ${revisionId})`
  );
};

export const fetchLatestTermRevision = termId => {
  return fetchTermRevision(termId, 'latest');
};

export const fetchTermRevision = (termId, revisionId) => {
  const options = buildOptions({ hal: true });

  return makeRequest(
    endpointUrl,
    `/api/v0/terms/${termId}/revisions/${revisionId}`,
    options,
    `getting pricing term for ${termId} (revision ${revisionId})`
  ).then(formatTermRevisionHalResponse);
};

export const fetchAllTermRevisions = termId => {
  const options = buildOptions({ hal: true });

  return makeRequest(
    endpointUrl,
    `/api/v0/terms/${termId}/revisions`,
    options,
    `getting all pricing term revisions for ${termId}`
  ).then(halResponse => {
    const halTerms = _.get(halResponse, '_embedded.self');
    return halTerms.map(formatTermRevisionHalResponse);
  });
};

export const updateTermStatus = (termId, revisionId, newStatus) => {
  const options = buildOptions({ method: 'PUT', hal: true });
  return makeRequest(
    endpointUrl,
    `/api/v0/terms/${termId}/revisions/${revisionId}/status/${newStatus}`,
    options,
    "updating pricing term's status"
  ).then(formatTermRevisionHalResponse);
};

export const fetchTermRevisionPriceModels = (termId, revisionId) => {
  const options = buildOptions({ method: 'GET' });
  return makeRequest(
    endpointUrl,
    `/api/v0/terms/${termId}/revisions/${revisionId}/pricemodels`,
    options,
    'getting price models on term '
  );
};

export const createListPriceTerm = ({
  pricingTermInfo,
  selectedSkus,
  initiator,
  acceptor,
  buyer,
  seller,
  toPendingStatus,
  gracePeriodApprovalDays
}) => {
  const skus = Object.keys(selectedSkus);

  const additionalData = {
    name: pricingTermInfo.termName,
    comment: pricingTermInfo.comment
  };

  const effectiveStartDate = formatDate(pricingTermInfo.startDate);

  const body = {
    skus,
    initiator,
    acceptor,
    buyer,
    seller,
    effectiveStartDate,
    additionalData,
    toPendingStatus
  };

  if (gracePeriodApprovalDays) {
    body.gracePeriodApprovalDays = gracePeriodApprovalDays;
  }

  if (!pricingTermInfo.noEndDateChecked) {
    body.effectiveEndDate = formatDate(pricingTermInfo.endDate);
  }

  const modifierType = getTermModifierType(pricingTermInfo.termType);

  if (modifierType) {
    const modifierField = `${modifierType}Percent`;
    body.modifiers = {
      [modifierField]: pricingTermInfo.modifierAmount
    };
  }

  const options = buildOptions({ method: 'POST', body: JSON.stringify(body) });
  return makeRequest(endpointUrl, `/api/v0/listPriceTerms`, options, 'creating catalog price term');
};

export const createSpecialPriceTerm = ({
  pricingTermInfo,
  selectedSkus,
  initiator,
  acceptor,
  buyer,
  seller,
  toPendingStatus,
  gracePeriodApprovalDays
}) => {
  // NOTE: right now we map all skus to one price model, potentially to be refactored in the future
  const items = _.map(selectedSkus, s => ({ sku: s.sku, priceModel: pricingTermInfo.priceModel }));
  const additionalData = {
    name: pricingTermInfo.termName,
    comment: pricingTermInfo.comment
  };

  const effectiveStartDate = formatDate(pricingTermInfo.startDate);
  const body = {
    items,
    initiator,
    acceptor,
    buyer,
    seller,
    effectiveStartDate,
    additionalData,
    toPendingStatus
  };

  if (gracePeriodApprovalDays) {
    body.gracePeriodApprovalDays = gracePeriodApprovalDays;
  }

  if (!pricingTermInfo.noEndDateChecked) {
    body.effectiveEndDate = formatDate(pricingTermInfo.endDate);
  }

  const modifierType = getTermModifierType(pricingTermInfo.termType);

  if (modifierType) {
    const modifierField = `${modifierType}Percent`;
    body.modifiers = {
      [modifierField]: pricingTermInfo.modifierAmount
    };
  }

  const options = buildOptions({ method: 'POST', body: JSON.stringify(body) });
  return makeRequest(endpointUrl, `/api/v0/specialPriceTerms`, options, 'creating private price term');
};

export const createFreeShippingTerm = ({
  shippingTermInfo,
  selectedSkus,
  initiator,
  acceptor,
  buyer,
  seller,
  toPendingStatus,
  gracePeriodApprovalDays
}) => {
  const skus = Object.keys(selectedSkus);
  const additionalData = {
    name: shippingTermInfo.termName,
    comment: shippingTermInfo.comment
  };

  const effectiveStartDate = formatDate(shippingTermInfo.startDate);
  const body = {
    skus,
    initiator,
    acceptor,
    buyer,
    seller,
    effectiveStartDate,
    additionalData,
    toPendingStatus
  };

  if (gracePeriodApprovalDays) {
    body.gracePeriodApprovalDays = gracePeriodApprovalDays;
  }

  if (!shippingTermInfo.noEndDateChecked) {
    body.effectiveEndDate = formatDate(shippingTermInfo.endDate);
  }

  if (shippingTermInfo.selectedCountryType !== ALL) {
    body.destinationCountries = shippingTermInfo.countries;
  }

  const options = buildOptions({ method: 'POST', body: JSON.stringify(body) });
  return makeRequest(endpointUrl, `/api/v0/freeShippingTerms`, options, 'creating free shipping term');
};

export const createShippingCostTerm = ({
  shippingTermInfo,
  selectedSkus,
  initiator,
  acceptor,
  buyer,
  seller,
  toPendingStatus,
  gracePeriodApprovalDays
}) => {
  const skus = Object.keys(selectedSkus);
  const additionalData = {
    name: shippingTermInfo.termName,
    comment: shippingTermInfo.comment
  };

  const effectiveStartDate = formatDate(shippingTermInfo.startDate);

  const body = {
    skus,
    initiator,
    acceptor,
    buyer,
    seller,
    effectiveStartDate,
    additionalData,
    toPendingStatus
  };

  if (gracePeriodApprovalDays) {
    body.gracePeriodApprovalDays = gracePeriodApprovalDays;
  }

  if (!shippingTermInfo.noEndDateChecked) {
    body.effectiveEndDate = formatDate(shippingTermInfo.endDate);
  }

  if (shippingTermInfo.selectedCountryType !== ALL) {
    body.destinationCountries = shippingTermInfo.countries;
  }

  const modifierType = getTermModifierType(shippingTermInfo.termType);

  if (modifierType) {
    const modifierField = `${modifierType}Percent`;
    body.modifiers = {
      [modifierField]: shippingTermInfo.modifierAmount
    };
  }

  const options = buildOptions({ method: 'POST', body: JSON.stringify(body) });
  return makeRequest(endpointUrl, `/api/v0/shippingCostTerms`, options, 'creating shipping cost term');
};

export const createShippingPriceTerm = ({
  shippingTermInfo,
  selectedSkus,
  initiator,
  acceptor,
  buyer,
  seller,
  toPendingStatus
}) => {
  const items = _.map(selectedSkus, s => ({ sku: s.sku, priceModel: shippingTermInfo.shippingPriceModel }));

  const additionalData = {
    name: shippingTermInfo.termName,
    comment: shippingTermInfo.comment
  };

  const effectiveStartDate = formatDate(shippingTermInfo.startDate);

  const body = {
    items,
    initiator,
    acceptor,
    buyer,
    seller,
    effectiveStartDate,
    additionalData,
    toPendingStatus
  };

  if (!shippingTermInfo.noEndDateChecked) {
    body.effectiveEndDate = formatDate(shippingTermInfo.endDate);
  }

  if (shippingTermInfo.selectedCountryType !== ALL) {
    body.destinationCountries = shippingTermInfo.countries;
  }

  const modifierType = getTermModifierType(shippingTermInfo.termType);
  if (modifierType) {
    const modifierField = `${modifierType}Percent`;
    body.modifiers = {
      [modifierField]: shippingTermInfo.modifierAmount
    };
  }

  const options = buildOptions({ method: 'POST', body: JSON.stringify(body) });
  return makeRequest(endpointUrl, `/api/v0/shippingPriceTerms`, options, 'creating shipping price term');
};

export const patchAndUpdateName = (termId, latestRevisionId, name) => {
  const patchOperations = [
    {
      value: name,
      path: '/additionalData/name',
      op: 'add'
    }
  ];

  return patchAndUpdatePriceTerm(termId, latestRevisionId, patchOperations);
};

export const patchAndUpdateGracePeriod = (termId, latestRevisionId, days) => {
  const patchOperations = [
    {
      value: days
        ? {
            period: days,
            unit: 'days',
            statusAfterGracePeriodExpires: 'approved'
          }
        : null,
      path: '/AdditionalData/graceperiodconfiguration',
      op: 'add'
    }
  ];

  return patchAndUpdatePriceTerm(termId, latestRevisionId, patchOperations);
};

export const patchAndUpdateModifiers = (termId, latestRevisionId, modifiers) => {
  const patchOperations = [
    {
      value: modifiers,
      path: '/modifiers',
      op: 'replace'
    }
  ];

  return patchAndUpdatePriceTerm(termId, latestRevisionId, patchOperations);
};

export const patchListPriceTermItems = (termId, skus) => {
  const patchOperations = [
    {
      value: skus.map(s => ({ sku: s })),
      path: 'items',
      op: 'replace'
    }
  ];

  return patchPriceTerm(termId, patchOperations);
};

export const patchSpecialAndShippingPriceTermItems = (termId, skus, priceModel) => {
  if (!priceModel || !priceModel.id) {
    throw new Error('Price model is not valid');
  }
  const patchOperations = [
    {
      value: skus.map(s => ({ sku: s, priceModel: { id: priceModel.id, revisionId: priceModel.revisionId } })),
      path: 'items',
      op: 'replace'
    }
  ];

  return patchPriceTerm(termId, patchOperations);
};

const patchPriceTerm = (termId, patchOperations) => {
  const options = buildOptions({ method: 'PATCH', body: JSON.stringify(patchOperations) });
  return makeRequest(endpointUrl, `/api/v0/terms/${termId}`, options, 'patching price term');
};

const patchAndUpdatePriceTerm = (termId, latestRevisionId, patchOperations) => {
  const options = buildOptions({
    method: 'POST',
    body: JSON.stringify({ latestRevisionId, patchPriceTermRequest: patchOperations })
  });
  return makeRequest(endpointUrl, `/api/v0/terms/${termId}/revisions`, options, 'patching price term');
};

const formatDate = date => {
  return moment.utc(date).format('YYYY-MM-DD');
};

const formatTermRevisionHalResponse = halResponse => {
  return { ...halResponse, additionalData: halResponse.additionalData || {} };
};
